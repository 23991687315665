/*
Documentation

this file renders the right hand sidebar on all automations
here we can change that automation type and updated individual
tasks within the automation

*/

import React, { Component } from 'react';
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import { compileAppointmentDateInputs, decodeAppointmentDateInputs } from 'utils/time';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import TypeWait from './TypeWait'
import TypeEmail from './TypeEmail'
import TypeText from './TypeText'
import TypeStartEnd from './TypeStartEnd';

class AutoSidebar extends Component {

    state = {
        task: {}
    };

    onChange = (o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})

    setTask = (task) => {

        let wait_time = 0;
        wait_time = wait_time + task.days * 86400;
        wait_time = wait_time + task.hours * 3600;
        wait_time = wait_time + task.minutes * 60;

        const compiledDate = compileAppointmentDateInputs(task)

        this.props.setTask({
            ...task,
            wait_time,
            appointments_start: compiledDate.success ? compiledDate.data.appointments_start : undefined,
            appointments_end: compiledDate.success ? compiledDate.data.appointments_end : undefined,
        }, true)

    }

    decodeWaitTime = (task) => {

        const time = task.wait_time;

        if(!time) return { days: 0, hours: 0, minutes: 0 }

        let days = Math.floor(time / 86400)
        let hours = Math.floor((time - (days * 86400)) / 3600)
        let minutes = Math.floor((time - (days * 86400) - (hours * 3600)) / 60)

        return { days, hours, minutes }

    }

    componentWillReceiveProps = (nextProps) => {

        const { automation_tasks } = this.props

        if(nextProps.selected_task !== this.props.selected_task && automation_tasks && automation_tasks.length) {

            let found_task = automation_tasks.find(task => task._id === nextProps.selected_task)

            if(found_task) {

                found_task = Object.assign({}, found_task)

                const foundDivision = this.props.divisions.find(d => d._id === this.props.automation.division);

                if(foundDivision && foundDivision.sending_phone_number && !found_task.sending_phone_number) {
                    found_task.sending_phone_number = foundDivision.sending_phone_number;
                }

                if(found_task.template_email && found_task.template_email._id) {
                    found_task.template_email = found_task.template_email._id
                }

                if(found_task.template_email && found_task.template_email._id) {
                    found_task.template_email = found_task.template_email._id
                }

                if(found_task.template_text && found_task.template_text._id) {
                    found_task.template_text = found_task.template_text._id
                }

                if(found_task.template_appointment && found_task.template_appointment._id) {
                    found_task.template_appointment = found_task.template_appointment._id
                }

                if(found_task.automation_start && found_task.automation_start._id) {
                    found_task.automation_start = found_task.automation_start._id
                }

                if(found_task.automation_end && found_task.automation_end._id) {
                    found_task.automation_end = found_task.automation_end._id
                }

                const time = this.decodeWaitTime(found_task)

                if(found_task.appointments_start && found_task.appointments_end) {
                    const dates = decodeAppointmentDateInputs(found_task)

                    found_task.date =  dates.date
                    found_task.time_start =  dates.time_start
                    found_task.time_end =  dates.time_end
                }

                found_task.days = time.days;
                found_task.hours = time.hours;
                found_task.minutes = time.minutes;

            }

            this.setState({task: found_task ? found_task : {}})

        }

    }

    render() {

        const { task } = this.state
        const { showSidebar, toggleSidebar, onRemoveTask, automation_id } = this.props

        return (

            <div className={showSidebar ? "auto-sidebar open" : "auto-sidebar"}>

                <div className="blackout" onClick={() => toggleSidebar(false)} />

                <div className={showSidebar ? "wrapper open" : "wrapper"}>

                    <ul>

                        <li>
                            <button onClick={() => onRemoveTask(task.order)} className="btn btn-danger"><i className="fas fa-trash mr-2" /> Delete</button>
                            <span onClick={() => toggleSidebar(false)} className="close"><i className="fas fa-times" /> </span>
                        </li>

                        <li>
                            <StandardFormGroup
                                obj={task}
                                objName='task'
                                onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                type="select"
                                field="type"
                                title="Automation Type"
                            >
                                <option value="wait">Wait</option>
                                <option value="email">Send Email</option>
                                <option value="text">Send Text</option>
                                <option value="start">Start An Automation</option>
                                <option value="end">End An Automation</option>
                            </StandardFormGroup>
                        </li>

                        <TypeWait
                            task={task}
                            onChange={this.onChange}
                        />

                        <TypeEmail
                            task={task}
                            onChange={this.onChange}
                        />

                        <TypeText
                            task={task}
                            onChange={this.onChange}
                        />

                        <TypeStartEnd
                            task={task}
                            onChange={this.onChange}
                            automation_id={automation_id}
                        />

                        <li>
                            <button onClick={() => this.setTask(this.state.task)} className="btn btn-success btn-block">Set Automation</button>
                        </li>

                    </ul>

                </div>

            </div>

        )

    }

}

AutoSidebar.propTypes = {
    automation_tasks    : PropTypes.array.isRequired,
    showSidebar         : PropTypes.bool.isRequired,
    toggleSidebar       : PropTypes.func.isRequired,
    setTask             : PropTypes.func.isRequired,
    onRemoveTask        : PropTypes.func.isRequired,
    automation_id       : PropTypes.string.isRequired,
    selected_task       : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}


const mapStateToProps = state => {
    return {
        divisions: state.divisions.divisions,
    };
};

export default connect(mapStateToProps, '')(AutoSidebar);
