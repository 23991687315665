import React, { memo, useCallback } from 'react';
import { useDrop } from 'react-dnd';
import Question from './Question';
import update from 'immutability-helper';

const QuestionList = ({allQuestions, nestedLevel, parentId, parentTree, setMasterQuestions, onSelect, onDragEnd, onDragStart, onQuestionAdded, page}) => {

    // add the current parent to the tree
    const newParentTree = [...parentTree]
    if(parentId) newParentTree.push(parentId);

    // set values to accept in the drag and drop
    const acceptNested = nestedLevel + '-' +  parentId

    // returns a question in the list by id
    const findQuestion = useCallback((id) => {
        const question = allQuestions.filter((c) => `${c.id}` === id)[0];
        return { question, index: allQuestions.indexOf(question) };
    }, [allQuestions]);

    // when drag stops send event to parent
    const onEnd = useCallback(() => {
        onDragEnd(allQuestions, newParentTree)
    }, [allQuestions, newParentTree])

    // when drag starts send event to parent
    const onStart = useCallback(() => {
        onDragStart(allQuestions, newParentTree)
    }, [allQuestions, newParentTree])

    // when question is added send it to the parent
    const questionAdded = useCallback(() => {
        onQuestionAdded(allQuestions, newParentTree)
    }, [allQuestions, newParentTree])
    
    // when a branch is added we create a new question and 
    // send it to the parent. This simulates a new branch
    const onAddBranch = useCallback((question) => {
        const tree = [...newParentTree]
        tree.push(question.id);
        onQuestionAdded([], tree)
    }, [allQuestions, newParentTree])
    
    // moves a questions order by drag and drop
    const moveQuestion = useCallback((id, atIndex) => {
        const { question, index } = findQuestion(id);

        const newQuestions = update(allQuestions, {
            $splice: [
                [index, 1],
                [atIndex, 0, question],
            ],
        });

        setMasterQuestions(newQuestions, newParentTree)
    }, [findQuestion, allQuestions, setMasterQuestions]);

    const [, drop] = useDrop({ accept: acceptNested });

    const nextNest = (parseInt(nestedLevel) + 1).toString()

    return (
        <div ref={drop} className="question-wrapper bg-white mb-4">
            {allQuestions.map((question) => (
                question.page !== page ? null :
                <Question 
                    key={question.id} 
                    id={`${question.id}`} 
                    description={question.description} 
                    question={question} 
                    moveQuestion={moveQuestion} 
                    onEnd={onEnd} 
                    onStart={onStart} 
                    acceptNested={acceptNested}
                    findQuestion={findQuestion}
                    onAddBranch={onAddBranch}
                    onSelect={() => onSelect(question, allQuestions, newParentTree)}
                >
                    {question.questions.length ? (
                        <div className={`nested-level nested-level-${nextNest}`}>
                            <QuestionList 
                                nestedLevel={nextNest} 
                                allQuestions={question.questions} 
                                parentId={question.id} 
                                parentTree={newParentTree}
                                setMasterQuestions={setMasterQuestions}
                                onSelect={onSelect}
                                onDragEnd={onDragEnd}
                                onDragStart={onDragStart}
                                onQuestionAdded={onQuestionAdded}
                                page={page}
                            /> 
                        </div>
                    ): null}
                </Question>
            ))}

            <button className="btn btn-sm btn-block btn-outline-warning mt-4" onClick={() => questionAdded(newParentTree, nestedLevel)}>
                <i className="fas fa-plus " /> Add Question
            </button>
        </div>       
    )
};

export default memo(QuestionList)