import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container } from "reactstrap";
import Actions from './Actions';
import Fields from './Fields';
import Tasks from './Tasks';
import ModalConfirmation from './ModalConfirmation';

const EmailParsersMain = ({parser, setParser, onChange, onConfirmSave, showModal, toggleModal, onSave}) => {

    return (

        <div className="archk-email-parser-main">
            <Container fluid>
                
                <DashHeaderOpen
                    title={<span><i className="fas fa-magic mr-2 text-success " /> Automations</span>}
                    breadcrumb_1="Email Parsers"
                    breadcrumb_2={parser && parser._id ? parser.name : "Create"}
                    actionComponent={(
                        <>
                        <Link to="/automations/email_parsers" className="btn btn-warning">
                            <i className="fas fa-arrow-left mr-2 " />Back
                        </Link>
                        <button onClick={onConfirmSave} className="btn btn-success">
                            <i className="fas fa-save mr-2 " />Save
                        </button>
                        </>
                    )}
                />

                <Fields 
                    parser={parser}
                    setParser={setParser}
                    onChange={onChange}
                />

                <Actions 
                    parser={parser}
                    setParser={setParser}
                    onChange={onChange}
                />
                
                <Tasks 
                    parser={parser}
                    setParser={setParser}
                    onChange={onChange}
                />

                <ModalConfirmation 
                    parser={parser}
                    toggleModal={toggleModal}
                    showModal={showModal}
                    onSave={onSave}
                />

            </Container>
        </div>

    );
}

const mapStateToProps = state => {
    return {
        company: state.companies.company,
        email_parsers: state.email_parsers.email_parsers,
    };
};

export default connect(mapStateToProps, '')(EmailParsersMain);
