/*
Documentation

this file has one function, to render a modal that takes
1 input (name) to create a new step on the workflow

*/

import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from "reactstrap";
import _workflow_steps from '_functions/workflow_steps'
import { toggleStandardLoader } from 'store/functions/system/system'

const required_form_fields = [
    'name',
]

class FlowSidebarStepModal extends Component {

    state = {
        automations: [],
        step: this.props.step || {},
    };

    onSave = async () => {

        //copy state
        let newState = Object.assign({}, this.state.step);
        //assume we have no errors
        let errors = 0;

        required_form_fields.forEach((field) => {
            //check each required field
            if(newState[field]) {
                newState[field + "State"] = 'valid';
            } else {
                newState[field + "State"] = 'invalid';
                errors++
            }

        })

        this.setState({ step: newState });

        if(!errors) {

            toggleStandardLoader(true)

            const action = await _workflow_steps.create({
                name        : newState.name,
                workflow    : this.props.workflow._id,
                automations : []
            })

            toggleStandardLoader(false)

            this.props.onStepSave(action)
            this.props.toggle()

        }

    }

    listenForEnter = (e) => {

        if (e.key === 'Enter') {

            const submitButtom = document.getElementById('step-modal-submit')
            if(submitButtom) submitButtom.click();

        }

    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({step: nextProps.step, noSteps: false})
    }

    componentDidUpdate = (prevProps) => {

        if(!prevProps.showModal && this.props.showModal) {

            setTimeout(() => {

                const input = document.getElementById('step-modal-input')
                if(input) input.focus()

            }, 500)

            document.addEventListener('keydown', this.listenForEnter)

        } else if(prevProps.showModal && !this.props.showModal) {

            document.removeEventListener('keydown', this.listenForEnter)

        }

    }

    render() {

        const {  step } = this.state
        const { toggle, showModal } = this.props

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={toggle}
                size="lg"
            >

                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        {step._id ? (
                            <span>Edit Step: {step.name}</span>
                        ) : 'Create Step'}
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggle}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">
                    <StandardFormGroup
                        inputId="step-modal-input"
                        obj={step}
                        objName='step'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        type="text"
                        field="name"
                        title="Name"
                    />
                </div>

                <div className="modal-footer text-right">
                    <button id="step-modal-submit" onClick={this.onSave} className="btn btn-success">Create Step</button>
                </div>

            </Modal>

        )

    }

}

FlowSidebarStepModal.propTypes = {
    step            : PropTypes.object.isRequired,
    workflow        : PropTypes.object.isRequired,
    showModal       : PropTypes.bool.isRequired,
    toggle          : PropTypes.func.isRequired,
    onStepSave      : PropTypes.func.isRequired,
}

export default FlowSidebarStepModal
