import React, { useState } from 'react';
import { Container } from 'reactstrap';
import Query from './Query';
import Results from './Results';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';

import moment from 'moment'
import { connect } from 'react-redux';

const AppointmentsBreakdown = ({SETTINGS}) => {

    const [ state, setData ] = useState({
        data: null,
        error: false,
        loading: true,
        queryInfo: {}
    })

    const onStateChange = (obj) => setData({...state, ...obj});

    const { data, queryInfo, loading } = state;

    return (

        <Container fluid>

            <DashHeaderOpen
                title={<span><i className="fas fa-headset mr-2 text-success " />Analytics</span>}
                breadcrumb_1="Appointments"
                breadcrumb_2="Breakdown"
            />

            <div className="alert alert-warning">
                <i className="fas fa-info mr-3 " />
                Appointment breakdown analytics will take effect starting{' '}
                {moment.unix(SETTINGS.changes.appointment_outcomes).format('MM/DD/YYYY')}{' '}
                after the recent change to call outcomes.
            </div>

            <Query 
                setData={onStateChange}
            />

            <Results 
                data={data}
                loading={loading}
                queryInfo={queryInfo}
            />

        </Container>

    )

}


const mapStateToProps = state => {
    return {
        SETTINGS: state.system.SETTINGS,
    };
};

export default connect(mapStateToProps, '')(AppointmentsBreakdown);   
