import React from 'react'

const ColorCircle = (props) => {

    if(!props.color) return null

    return (
        <span style={{background: props.color}} className="color-circle"></span>
    );

}

export default ColorCircle;
