/*
Documentation

compiles a query object that can be accepted by mongo DB as a filter

*/

export default (filters) => {

    let compiledFilters = [];

    filters.forEach(filter => {

        filter = Object.assign({}, filter)

        // delete the properties we are not trying to query
        delete filter.__filter_name
        delete filter.__filter_timestamp

        let queryObject = {}
        const keys = Object.keys(filter);

        keys.forEach(key => {

            const value = filter[key];

            if(value) {

                // set if looking for value between
                if(value.toString().includes('{between}')) {

                    try {

                        // if value is number try to parse the number
                        if(value.toString().includes('{number}'))  {

                            queryObject[key] = {
                                $gt: parseInt(value.split(' ')[0]),
                                $lt: parseInt(value.split(' ')[1].split('{between}')[0]),
                            }

                        } else {

                            queryObject[key] = {
                                $gt: value.split(' ')[0],
                                $lt: value.split(' ')[1].split('{between}')[0],
                            }

                        }



                    } catch(e) {

                        console.log(e)

                    }

                    return

                }

                // set if looking for a value in an array
                // can be expanded to take an array
                if(value.toString().includes('{in}')) {

                    try {
                        const array = value.split('{in}')

                        let inArray = []

                        let toPush = array[0].split(':')

                        toPush.forEach(x => inArray.push(x))

                        queryObject[key] = { $in: inArray}
                    } catch(e) {
                        console.log(`A filter value was given to be parsed aa an in value but was formatted incorrectly. It should take the format of "xxx:yyy:zzz{in}". Key was "${key}" Value was "${value}"`)
                    }

                    return;

                }

                 // set if looking for a value in an array
                // can be expanded to take an array
                if(value.toString().includes('{and}')) {

                    try {

                        const array = value.split('{and}')
                        let inArray = []

                        let toPush = array[0].split(':')
                        toPush.forEach(x => inArray.push(x))

                        queryObject[key] = { $and: inArray}

                    } catch(e) {
                        console.log(`A filter value was given to be parsed aa an in value but was formatted incorrectly. It should take the format of "xxx:yyy:zzz{in}". Key was "${key}" Value was "${value}"`)
                    }

                    return;

                }

                 // set if looking for greater than or equal value
                 if(value.toString().includes('{gte}')) {

                    // if value is number try to parse the number
                    if(value.toString().includes('{number}')) return queryObject[key] = { $gte: parseInt(value.split('{gte}')[0])}

                    return queryObject[key] = { $gte: value.split('{gte}')[0]}
                }

                // set if looking for greater than value
                if(value.toString().includes('{gt}')) {

                    // if value is number try to parse the number
                    if(value.toString().includes('{number}')) return queryObject[key] = { $gt: parseInt(value.split('{gt}')[0])}

                    return queryObject[key] = { $gt: value.split('{gt}')[0]}
                }

                // set if looking for less than or equal value
                if(value.toString().includes('{lte}')) {

                    // if value is number try to parse the number
                    if(value.toString().includes('{number}')) return queryObject[key] = { $lte: parseInt(value.split('{lte}')[0])}
                    return queryObject[key] = { $lte: value.split('{lte}')[0]}
                }

                // set if looking for less than value
                if(value.toString().includes('{lt}')) {

                    // if value is number try to parse the number
                    if(value.toString().includes('{number}')) return queryObject[key] = { $lt: parseInt(value.split('{lte}')[0])}
                    return queryObject[key] = { $lt: value.split('{lt}')[0]}
                }

                // set if looking for less than or equal value
                if(value.toString().includes('{exists}')) {
                    return queryObject[key] = { $exists: JSON.parse(value.split('{exists}')[0].toLowerCase())}
                }

                  // set if looking for less than or equal value
                  if(value.toString().includes('{bool}')) {
                    return queryObject[key] = value.includes('true') ? true : value.includes('false') ? false : undefined
                }

                if(value.toString().includes('{number}')) {

                    // split out the {number}
                    const string = value.split('{number}')[0]
                    queryObject[key] = parseFloat(string)

                    if(isNaN(queryObject[key])) {
                        console.log(`A filter value was given to be parsed as a number but was not a true number. Key was "${key}" | Value was "${value}"`)
                        queryObject[key] = undefined
                    }

                    return;

                }

                queryObject[key] = value

            } // end if we have a value

        }) // end for each key

        compiledFilters.push(queryObject)

    }) // end for each filter

    return compiledFilters

}
