/*
Documentation

This file is responsible for associating users to an appointment template

*/

import PropTypes from 'prop-types'
import React from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import renderName from 'utils/renderName';
import UserSearchInput from 'components/system/migrated/UserSearchInput';

class TemplatesCrudUsers extends React.Component {

    render() {

        const { template_appointment, addUser, removeUser } = this.props
        const assigned_to = template_appointment.assigned_to

        if(template_appointment.show !== 'user') return <div></div>

        return (

            <Card style={{boxShadow: 'none'}}>

                <CardHeader>
                    <CardTitle className="mb-0">Template Users</CardTitle>
                    <p className="text-sm mb-0">
                        Default users will be automatically assigned to any appointments created with this template. To assign them search for them below.
                    </p>
                </CardHeader>

                <CardBody>

                    <UserSearchInput
                        value={ {} }
                        onSelect={(user) => addUser(user)}
                    />

                    <hr />

                    {assigned_to && assigned_to.length ? (
                        <>
                            <p className="text-sm">The following users will be assigned to any appointments created when using this template:</p>

                            {assigned_to.map(a => (
                                <Row key={a._id}>
                                    <Col sm={10} className="align-self-center text-capitalize">
                                        {renderName(a)}
                                    </Col>
                                    <Col sm={2} className="align-self-center text-right">
                                        <i onClick={() => removeUser(a._id)} className="fas fa-trash text-danger cursor-pointer" />
                                    </Col>
                                </Row>
                            ))}
                        </>
                    ) : (
                        <p className="text-sm text-danger mb-0 font-weight-bold">This template is not currently assigned any default users.</p>
                    )}

                </CardBody>
            </Card>

        );
    }
}

TemplatesCrudUsers.propTypes = {
    template_appointment    : PropTypes.object.isRequired,
    addUser                 : PropTypes.func.isRequired,
    removeUser              : PropTypes.func.isRequired,
}

export default TemplatesCrudUsers
