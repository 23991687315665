import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardTitle, FormGroup, Input, CardFooter, CardBody, Row, Col, Badge } from "reactstrap";
import { connect } from 'react-redux';
import EmailValidator from 'email-validator';
import renderName from 'utils/renderName';
import { capitalize } from 'utils/text';
import role_types from '_settings/role_types'

const actionState = {
    name: 'Unsaved Action',
    type: 'email',
    body: '',

    to: [],
    toText: '',
    toType: 'role',

    appointment_userType: 'role',
    appointment_users: [],
    appointment_template: '',
    appointment_time: '',
    
    case_alert_value: '',
    case_alert_notify_contact: 'no',

    timezone: 'America/New_York',
}

const day = 86400;

const EmailParserActions = ({parser, setParser, users, template_appointments}) => {

    const [ actionIndex, setActionIndex ] = useState(0);
    const [ actionToEdit, setActionToEdit ] = useState({});
    const [ emailValidationErr, setEmailValidationErr ] = useState(false);

    const setActionToState = (action, actionIndex) => {
        setActionToEdit(action);
        setActionIndex(actionIndex);
    }

    const addAction = () => {
        const newState = Object.assign({}, parser);
        const newAction = Object.assign({}, {...actionState, to: [], appointment_users: []});

        const actionLength = parser.actions.length;
        parser.actions.push(newAction);

        setParser(newState);
        setActionToState(newAction, actionLength)
    }

    const removeAction = (actionIndex) => {
        const newState = Object.assign({}, parser);
        const actions = newState.actions;

        actions.splice(actionIndex, 1);

        if(actions.length === 0) actions.push(Object.assign({}, actionState))

        newState.actions = actions;

        setParser(newState);
        setActionToState(actions[0], 0)
    }

    const renderActionRow = (action, index) => {

        const isActive = index === actionIndex;
        const unsaved = action.name === 'Unsaved Action';

        return (
            <tr key={index + action.name} className={isActive ? 'bg-secondary' : ''}>
                <td style={{width: '70%', wordBreak: 'break-all', whiteSpace: 'pre-line'}}>
                    {isActive ? (
                        unsaved ? (
                            <i className="fas fa-save text-warning mr-2 " /> 
                        ) : (
                            <i className="fas fa-check text-success mr-2 " /> 
                        )
                    ): null }
                    <span className="cursor-pointer" onClick={() => setActionToState(action, index)}>
                        {action.name}
                    </span>
                </td>
                <td className="text-right">
                    <i onClick={() => setActionToState(action, index)} className="fas fa-edit text-success mr-2 cursor-pointer" />
                    <i onClick={() => removeAction(index)} className="fas fa-trash text-danger cursor-pointer " />
                </td>
            </tr>
        )

    }

    const onChange = (e, stateName) => {

        const value = e.target.value;
        const newState = Object.assign({}, actionToEdit);

        newState[stateName] = value;

        if (value === "") {
            newState[stateName + "State"] = "invalid";
        } else {
            newState[stateName + "State"] = "valid";
        }

        if(stateName === 'toType' && value === 'contact') {
            onToChange('contact');
        }

        setActionToEdit(newState);

    }

    const onToChange = (value) => {

        if(!value) return;
        value = value.toLowerCase();
        const newState = Object.assign({}, actionToEdit);

        if(newState.to.includes(value)) {
            newState.to = newState.to.filter(to => to !== value);
        } else {
            newState.to.push(value);
            newState.toText = '';
        }

        setActionToEdit(newState);

    }

    const onAppointmentUserChange = (value) => {

        if(!value) return;
        value = value.toLowerCase();
        const newState = Object.assign({}, actionToEdit);

        if(newState.appointment_users.includes(value)) {
            newState.appointment_users = newState.appointment_users.filter(appointment_user => appointment_user !== value);
        } else {
            newState.appointment_users.push(value);
        }

        setActionToEdit(newState);

    }

     //on keypress of enter simulate the form being submitted for better UI
	const _handleKeyDown = (e) => {
		if (e.key === 'Enter') {

            const value = actionToEdit.toText;

            if(!EmailValidator.validate(value)) {
                setEmailValidationErr(`${value} is not a valid email address.`)
                return setTimeout(() => setEmailValidationErr(false), 2000)
            }

            onToChange(actionToEdit.toText, true);

		}
	}

    const renderBadge = (to) => {

        let content = to;

        const foundUser = users.find(user => user._id === to);

        if(foundUser) content = capitalize(renderName(foundUser));

        return (
            <Badge key={to} color="success" className="mb-3">
                {content} 
                <i onClick={() => onToChange(to)} className="fas fa-trash ml-2 text-danger cursor-pointer " />
            </Badge>
        )
    }

    const isValid = () => {
        const state = Object.assign({}, actionToEdit);

        let errors = 0;

        const setError = (stateName) => {
            errors++;
            state[stateName + 'State'] = 'invalid'
        }

        if(!state.name || state.name === 'Unsaved Action') setError('name');

        if(state.type === 'case alert') {
            if(!state.case_alert_value) setError('case_alert_value');
            if(!state.case_alert_notify_contact) setError('case_alert_notify_contact');
        } else if(state.type === 'email') {
            if(!state.body) setError('body');
            if(!state.subject) setError('subject');
            if(!state.to.length) setError('toType');
        } else if(state.type === 'text') {
            if(!state.body) setError('body');
            if(!state.to.length) setError('toType');
        } else {
            if(!state.appointment_time) setError('appointment_time');
            if(!state.appointment_template) setError('appointment_template');
            if(!state.timezone) setError('timezone');
        }
    
        if(errors) {
            setActionToEdit(state);
            return false;
        }

        return true;
    }


    const saveAction = (action, index) => {
        const actions = [...parser.actions];
        actions[index] = action;

        setParser({...parser, actions});
    }
    
    const onSaveAction = () => {
        if(isValid()) saveAction(actionToEdit, actionIndex)
    }

    useEffect(() => {

        let actionToSet = parser.actions[0];

        if(!actionToSet) {
            actionToSet = Object.assign({}, actionState);
            setParser({...Object.assign({}, parser), actions: [Object.assign({}, actionState)]});
        }

        setActionToState(actionToSet, 0);

    }, [])


    return (

        <Card>

            <CardHeader>
                <CardTitle className="mb-0">Create Actions</CardTitle> 
            </CardHeader>

            <CardBody>

                <Row>
                    
                    <Col md={4} className="border-right">
                        <Row className="mb-3">
                            <Col xs={6} className="align-self-center">
                                <FormGroup className="mb-0">
                                    <label className="form-control-label mb-0">All Actions</label>
                                </FormGroup>
                            </Col>
                            <Col xs={6} className="align-self-center text-right">
                                <button className="btn btn-outline-success" onClick={addAction}><i className="fas fa-plus mr-2 " /> Add</button>
                            </Col>
                        </Row>

                        <div className="table-responsive">
                            <table className="table table-bordered" style={{tableLayout: 'fixed'}}>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th className="text-right" style={{width: 75}}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {parser.actions.map((action, index) => renderActionRow(action, index))}
                                </tbody>
                            </table>
                        </div>

                    </Col>

                    <Col md={8}>
                        
                        <Row>

                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Name*</label>
                                    <Input 
                                        type="text"
                                        value={actionToEdit.name || ''}
                                        onChange={(e) => onChange(e, 'name')}
                                        valid={ actionToEdit.nameState === "valid" }
                                        invalid={ actionToEdit.nameState === "invalid" }
                                    />
                                </FormGroup>
                            </Col>

                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Type*</label>
                                    <Input 
                                        type="select"
                                        value={actionToEdit.type || ''}
                                        onChange={(e) => onChange(e, 'type')}
                                        valid={ actionToEdit.typeState === "valid" }
                                        invalid={ actionToEdit.typeState === "invalid" }
                                    >
                                        <option value="email">Email</option>
                                        <option value="text">Text</option>
                                        <option value="appointment">Appointment</option>
                                        <option value="case alert">Case Alert</option>
                                    </Input>
                                </FormGroup>
                            </Col>

                        </Row>

                        {actionToEdit.type === 'case alert' ? (

                            <div>

                                <FormGroup className="border-top border-top">
                                    <label className="form-control-label">Case Alert Text*</label>

                                    <Input 
                                        type="textarea"
                                        value={actionToEdit.case_alert_value || ''}
                                        onChange={(e) => onChange(e, 'case_alert_value')}
                                        valid={ actionToEdit.case_alert_valueState === "valid" }
                                        invalid={ actionToEdit.case_alert_valueState === "invalid" }
                                    />

                                </FormGroup>

                                <FormGroup className="border-top border-top">
                                    <label className="form-control-label">Notify Client?*</label>

                                    <Input 
                                        type="select"
                                        value={actionToEdit.case_alert_notify_contact || ''}
                                        onChange={(e) => onChange(e, 'case_alert_notify_contact')}
                                        valid={ actionToEdit.case_alert_notify_contactState === "valid" }
                                        invalid={ actionToEdit.case_alert_notify_contactState === "invalid" }
                                    >
                                        <option value="no">No</option>
                                        <option value="yes">Yes</option>
                                    </Input>

                                </FormGroup>
                            </div>
                        
                       ) : actionToEdit.type === 'appointment' ? (
                            <div>

                                <FormGroup className="border-top border-top">
                                    <label className="form-control-label">Appointment Template*</label>

                                    <Input 
                                        type="select"
                                        value={actionToEdit.appointment_template || ''}
                                        onChange={(e) => onChange(e, 'appointment_template')}
                                        valid={ actionToEdit.appointment_templateState === "valid" }
                                        invalid={ actionToEdit.appointment_templateState === "invalid" }
                                    >
                                         <option value="null"></option>
                                        {template_appointments.map(appointment => (
                                            <option value={appointment._id} key={appointment._id}>{appointment.name}</option>
                                        ))}
                                    </Input>

                                </FormGroup>

                                <FormGroup>
                                    <label className="form-control-label">Appointment Timezone*</label>
                                    <Input 
                                        type="select"
                                        value={actionToEdit.timezone || ''}
                                        onChange={(e) => onChange(e, 'timezone')}
                                        valid={ actionToEdit.timezoneState === "valid" }
                                        invalid={ actionToEdit.timezoneState === "invalid" }
                                    >
                                         <option value="America/New_York">America/New_York (EST)</option>
                                        <option value="America/Chicago">(America/Chicago (CST)</option>
                                        <option value="America/Los_Angeles">America/Los_Angeles (PST)</option>
                                    </Input>
                                </FormGroup>


                                <FormGroup className="border-top border-top">
                                    <label className="form-control-label">Appointment Time*</label>

                                    <Input 
                                        type="select"
                                        value={actionToEdit.appointment_time || ''}
                                        onChange={(e) => onChange(e, 'appointment_time')}
                                        valid={ actionToEdit.appointment_timeState === "valid" }
                                        invalid={ actionToEdit.appointment_timeState === "invalid" }
                                    >
                                        <option value="null"></option>
                                        <option disabled>From Intelli Parse Dates</option>
                                        {parser.fields.map(field => (
                                            field.type === 'date' ? (
                                                <React.Fragment key={field.name}>
                                                    <option value={field.name + '|' + 0}>On {field.name}</option>
                                                    
                                                    <option value={field.name + '|' + day * 1}>1 Day After {field.name}</option>
                                                    <option value={field.name + '|' + day * 2}>2 Days After {field.name}</option>
                                                    <option value={field.name + '|' + day * 3}>3 Days After {field.name}</option>
                                                    <option value={field.name + '|' + day * 4}>4 Days After {field.name}</option>
                                                    <option value={field.name + '|' + day * 5}>5 Days After {field.name}</option>
                                                    <option value={field.name + '|' + day * 6}>6 Days After {field.name}</option>
                                                    <option value={field.name + '|' + day * 7}>7 Days After {field.name}</option>
                                                    <option value={field.name + '|' + day * 10}>10 Days After {field.name}</option>
                                                    <option value={field.name + '|' + day * 14}>14 Days After {field.name}</option>
                                                    <option value={field.name + '|' + day * 21}>21 Days After {field.name}</option>
                                                    <option value={field.name + '|' + day * 28}>28 Days After {field.name}</option>
                                                    <option value={field.name + '|' + day * 30}>30 Days After {field.name}</option>
                                                    <option value={field.name + '|' + day * 45}>45 Days After {field.name}</option>
                                                    <option value={field.name + '|' + day * 60}>60 Days After {field.name}</option>
                                                    <option value={field.name + '|' + day * 90}>90 Days After {field.name}</option>
                                                    <option value={field.name + '|' + day * 180}>180 Days After {field.name}</option>
                                                    <option value={field.name + '|' + day * 365}>365 Days After {field.name}</option>

                                                    <option value={field.name + '|' + day * -1}>1 Day Before {field.name}</option>
                                                    <option value={field.name + '|' + day * -2}>2 Days Before {field.name}</option>
                                                    <option value={field.name + '|' + day * -3}>3 Days Before {field.name}</option>
                                                    <option value={field.name + '|' + day * -4}>4 Days Before {field.name}</option>
                                                    <option value={field.name + '|' + day * -5}>5 Days Before {field.name}</option>
                                                    <option value={field.name + '|' + day * -6}>6 Days Before {field.name}</option>
                                                    <option value={field.name + '|' + day * -7}>7 Days Before {field.name}</option>
                                                    <option value={field.name + '|' + day * -10}>10 Days Before {field.name}</option>
                                                    <option value={field.name + '|' + day * -14}>14 Days Before {field.name}</option>
                                                    <option value={field.name + '|' + day * -21}>21 Days Before {field.name}</option>
                                                    <option value={field.name + '|' + day * -28}>28 Days Before {field.name}</option>
                                                    <option value={field.name + '|' + day * -30}>30 Days Before {field.name}</option>
                                                    <option value={field.name + '|' + day * -45}>45 Days Before {field.name}</option>
                                                    <option value={field.name + '|' + day * -60}>60 Days Before {field.name}</option>
                                                    <option value={field.name + '|' + day * -90}>90 Days Before {field.name}</option>
                                                    <option value={field.name + '|' + day * -180}>180 Days Before {field.name}</option>
                                                    <option value={field.name + '|' + day * -365}>365 Days Before {field.name}</option>
                                                </React.Fragment>
                                            ) : null
                                        ))}
                                        <option disabled>Dates Email Received</option>
                                        <option value="0" >Immediately On Parse</option>
                                        <option value={day * 1}>1 Day Later</option>
                                        <option value={day * 2}>2 Days Later</option>
                                        <option value={day * 3}>3 Days Later</option>
                                        <option value={day * 4}>4 Days Later</option>
                                        <option value={day * 5}>5 Days Later</option>
                                        <option value={day * 6}>6 Days Later</option>
                                        <option value={day * 7}>7 Days Later</option>
                                        <option value={day * 10}>10 Days Later</option>
                                        <option value={day * 14}>14 Days Later</option>
                                        <option value={day * 21}>21 Days Later</option>
                                        <option value={day * 28}>28 Days Later</option>
                                        <option value={day * 30}>30 Days Later</option>
                                        <option value={day * 60}>60 Days Later</option>
                                        <option value={day * 90}>90 Days Later</option>
                                        <option value={day * 180}>180 Days Later</option>
                                        <option value={day * 365}>365 Days Later</option>

                                    </Input>

                                </FormGroup>

                                <FormGroup className="border-top border-top">
                                    <label className="form-control-label">Assigned Users</label>

                                    <div>{actionToEdit.appointment_users && actionToEdit.appointment_users.map(appointment_user => renderBadge(appointment_user))}</div>

                                    <Input 
                                        type="select"
                                        value={actionToEdit.appointment_userType || ''}
                                        onChange={(e) => onChange(e, 'appointment_userType')}
                                        valid={ actionToEdit.appointment_userTypeState === "valid" }
                                        invalid={ actionToEdit.appointment_userTypeState === "invalid" }
                                    >
                                        <option value="role">Case Role</option>
                                        <option value="user">User</option>
                                    </Input>

                                </FormGroup>

                                <FormGroup >

                                    {!actionToEdit.appointment_userType || actionToEdit.appointment_userType === "role" ? (
                                        <label className="form-control-label">Select Case Role</label>
                                    ) : actionToEdit.appointment_userType === "user" ? (
                                        <label className="form-control-label"> Select User</label>
                                    ) : ''}

                                    {!actionToEdit.appointment_userType || actionToEdit.appointment_userType === "role" ? (
                                        <Input 
                                            type="select"
                                            value={actionToEdit.toText || ''}
                                            onChange={(e) => onAppointmentUserChange(e.target.value, 'toText')}
                                            valid={ actionToEdit.toTextState === "valid" }
                                            invalid={ actionToEdit.toTextState === "invalid" }
                                        >
                                            <option></option>
                                            {role_types.map((role, i) => (
                                                <option key={i} value={`case:${role}`}>{role}</option>
                                            ))}
                                        </Input>
                                    ) : actionToEdit.appointment_userType === "user" ? (
                                        <Input 
                                            type="select"
                                            value={actionToEdit.toText || ''}
                                            onChange={(e) => onAppointmentUserChange(e.target.value, 'toText')}
                                            valid={ actionToEdit.toTextState === "valid" }
                                            invalid={ actionToEdit.toTextState === "invalid" }
                                        >
                                            <option></option>
                                            {users.map(user => (
                                                <option value={user._id}>{renderName(user)}</option>
                                            ))}
                                        </Input>
                                    ) : null}

                                    {emailValidationErr ? <p className="text-danger font-weight-bold">{emailValidationErr}</p> : null}

                                </FormGroup>

                            </div>
                        ) : (
                            <div >

                                <FormGroup className="border-top border-top">
                                    <label className="form-control-label">To*</label>

                                    <div>{actionToEdit.to && actionToEdit.to.map(to => renderBadge(to))}</div>

                                    <Input 
                                        type="select"
                                        value={actionToEdit.toType || ''}
                                        onChange={(e) => onChange(e, 'toType')}
                                        valid={ actionToEdit.toTypeState === "valid" }
                                        invalid={ actionToEdit.toTypeState === "invalid" }
                                    >
                                        <option value="email">Email</option>
                                        <option value="role">Case Role</option>
                                        <option value="user">User</option>
                                        <option value="contact">Contact</option>
                                    </Input>

                                </FormGroup>

                                <FormGroup >

                                    {actionToEdit.toType === "email" ? (
                                        <label className="form-control-label">Add Email Address</label>
                                    ) : actionToEdit.toType === "role" ? (
                                        <label className="form-control-label">Select Case Role</label>
                                    ) : actionToEdit.toType === "user" ? (
                                        <label className="form-control-label"> Select User</label>
                                    ) : ''}

                                    {actionToEdit.toType === "email" ? (
                                        <Input 
                                            type="text"
                                            value={actionToEdit.toText || ''}
                                            onChange={(e) => onChange(e, 'toText')}
                                            valid={ actionToEdit.toTextState === "valid" }
                                            invalid={ actionToEdit.toTextState === "invalid" }
                                            onKeyDown={_handleKeyDown}
                                        />
                                    ) : actionToEdit.toType === "role" ? (
                                        <Input 
                                            type="select"
                                            value={actionToEdit.toText || ''}
                                            onChange={(e) => onToChange(e.target.value, 'toText')}
                                            valid={ actionToEdit.toTextState === "valid" }
                                            invalid={ actionToEdit.toTextState === "invalid" }
                                        >
                                            <option></option>
                                            {role_types.map((role, i) => (
                                                <option key={i} value={`case:${role}`}>{role}</option>
                                            ))}
                                        </Input>
                                    ) : actionToEdit.toType === "user" ? (
                                        <Input 
                                            type="select"
                                            value={actionToEdit.toText || ''}
                                            onChange={(e) => onToChange(e.target.value, 'toText')}
                                            valid={ actionToEdit.toTextState === "valid" }
                                            invalid={ actionToEdit.toTextState === "invalid" }
                                        >
                                            <option></option>
                                            {users.map(user => (
                                                <option value={user._id}>{renderName(user)}</option>
                                            ))}
                                        </Input>
                                    ) : null}

                                    {emailValidationErr ? <p className="text-danger font-weight-bold">{emailValidationErr}</p> : null}

                                </FormGroup>

                                {actionToEdit.type === 'email' ? (

                                <FormGroup className="border-top">
                                    <label className="form-control-label">Subject*</label>
                                    <Input 
                                        type="text"
                                        value={actionToEdit.subject || ''}
                                        onChange={(e) => onChange(e, 'subject')}
                                        valid={ actionToEdit.subjectState === "valid" }
                                        invalid={ actionToEdit.subjectState === "invalid" }
                                    />
                                </FormGroup>
                                ) : null}

                                <FormGroup className="border-top">
                                    <label className="form-control-label">{actionToEdit.type === 'email' ? 'Email Body' : 'Text Body'}*</label>
                                    <Input 
                                        type="textarea"
                                        value={actionToEdit.body || ''}
                                        onChange={(e) => onChange(e, 'body')}
                                        valid={ actionToEdit.bodyState === "valid" }
                                        invalid={ actionToEdit.bodyState === "invalid" }
                                        style={{minHeight: 200}}
                                    />
                                </FormGroup>

                            </div>
                        )}
                
                    </Col>

                </Row>

            </CardBody>

            <CardFooter className="text-right">
                <button onClick={onSaveAction} className="btn btn-success"><i className="fas fa-save mr-2 " /> Save Action</button>
            </CardFooter>
            
        </Card>

    );
}

const mapStateToProps = state => {
    return {
    	users: state.users.users,
    	template_appointments: state.template_appointments.template_appointments,
    };
};

export default connect(mapStateToProps, '')(EmailParserActions);