import apiWorker from '../apiWorker'

const template_emails = {

    find        : (query)       => apiWorker('get',     `/api/v1/automations/template_emails/find${query}`),
    create      : (data)        => apiWorker('post',    `/api/v1/automations/template_emails/create`, data),
    update      : (_id, data)   => apiWorker('patch',   `/api/v1/automations/template_emails/update/${_id}`, data),
    generate    : (data)        => apiWorker('post',   `/api/v1/automations/templates/generate`, data),

}

export default template_emails;