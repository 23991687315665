import ReactSelect from 'components/functional/inputs/ReactSelect';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Card, CardBody, FormGroup, CardFooter } from 'reactstrap';

class RecommendedNextStep extends Component {

    render() {

        const { selectOptions, step, onRecommendedStepChange } = this.props

        const value = step.recommended_next_step ? selectOptions.find(o => o.value === step.recommended_next_step) : {};

        if(!step || (!step.can_move_to_steps)) return <div />

        const allSteps = step.can_move_to_steps.concat(step.can_jump_to_steps)
        const options = selectOptions.filter(o => allSteps.includes(o.value))

        return (

            <div>

                <h4>Flow</h4>

                <Card>
                    <CardBody>

                        <FormGroup>
                            <label className="form-control-label">Recommended Next Step</label>
                            {!options.length ? (
                                <p className="text-sm text-warning font-weight-bold mb-0">* This step does not have any steps it can move to.</p>
                            ) : null}
                            <ReactSelect
                                value={value}
                                onChange={onRecommendedStepChange}
                                options={options}
                            />
                        </FormGroup>

                    </CardBody>

                    {value.value ? (
                        <CardFooter className="text-right">
                            <button onClick={() => onRecommendedStepChange(null)} className="btn btn-warning">Remove</button>
                        </CardFooter>
                    ) : null}

                </Card>

            </div>

        )

    }

}

RecommendedNextStep.propTypes = {
    selectOptions             : PropTypes.array.isRequired,
    onRecommendedStepChange   : PropTypes.func.isRequired,
    step                      : PropTypes.object,
}

export default RecommendedNextStep;
