import React from 'react';
import ArchkInArray from './inputs/ArchkInArray';
import ArchkSelectSimple from './inputs/ArchkSelectSimple';
import ArchkString from './inputs/ArchkString';
import ArchkNumber from './inputs/ArchkNumber';
import ArchkNull from './inputs/ArchkNull';
import ArchkTruthy from './inputs/ArchkTruthy';
import ArchkDatePicker from './inputs/ArchkDatePicker';
import ArchkDates from './inputs/ArchkDates';
import ArchkGroupings from './inputs/ArchkGroupings';
import ArchkGreaterThan0 from './inputs/ArchkGreaterThan0';
import PropTypes from 'prop-types'

const Filter = ({inputs, onChange, isPrimary}) => inputs.map((obj, i) => {

    switch (obj.type) {
        case 'ArchkSelectSimple' : return <ArchkSelectSimple key={i} {...obj} onChange={(val) => onChange(obj, val)} />
        case 'ArchkString'       : return <ArchkString       key={i} {...obj} onChange={(val) => onChange(obj, val)} />
        case 'ArchkNumber'       : return <ArchkNumber       key={i} {...obj} onChange={(val) => onChange(obj, val)} />
        case 'ArchkInArray'      : return <ArchkInArray      key={i} {...obj} onChange={(val) => onChange(obj, val)} />
        case 'ArchkNull'         : return <ArchkNull         key={i} {...obj} onChange={(val) => onChange(obj, val)} />
        case 'ArchkTruthy'       : return <ArchkTruthy       key={i} {...obj} onChange={(val) => onChange(obj, val)} />
        case 'ArchkDates'        : return <ArchkDates        key={i} {...obj} onChange={(val) => onChange(obj, val)} isPrimary={obj.isPrimary} />
        case 'ArchkDatePicker'   : return <ArchkDatePicker   key={i} {...obj} onChange={(val) => onChange(obj, val)} />
        case 'ArchkGroupings'    : return <ArchkGroupings    key={i} {...obj} onChange={(val) => onChange(obj, val)} />
        case 'ArchkGreaterThan0' : return <ArchkGreaterThan0 key={i} {...obj} onChange={(val) => onChange(obj, val)} />
        default: console.log('invalid type passed via Graph Component: ' + obj.type); return null;
    }

})

Filter.propTypes = {
    inputs: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default Filter;
