/*
Documentation

This file allows for creating or updating of an office document

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import States from 'components/markup/inputs/States';
import React from "react";
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { CardFooter, Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import _offices from '_functions/offices';
import { toggleAlertBS } from 'store/functions/system/system'
import Circle from 'components/markup/loading/Circle'

const required_form_fields = [
    'name',
]

class OfficesView extends React.Component {

    state = {
        office: null,
        canSave: true,
    }

    onSave = async () => {

        let newState = Object.assign({}, this.state.office);
        let errors = 0;

        required_form_fields.forEach((field) => {
            //check each required field
            if(newState[field]) {
                newState[field + "State"] = 'valid';
            } else {
                newState[field + "State"] = 'invalid';
                errors++
            }

        })

        this.setState({ coofficeurt: newState, canSave: false });

        if(!errors) {

            let action;

            if(newState._id) {
                action = await _offices.update(newState._id, newState)
            } else {
                action = await _offices.create(newState)
            }

            if(action.success) {

                toggleAlertBS(false, 'Office Saved Successfully.')
                this.setState({office: action.data})

            }

        }

        this.setState({canSave: true})

    }

    componentDidMount = async () => {


        const office_id = this.props.match.params._id

        if(office_id !== 'new') {

            const foundOffice = await _offices.findById(office_id)

            if(foundOffice.success) {
                this.setState({office: foundOffice.data})
            } else {
                this.setState({shouldRedirect: '/core/locations/offices/all'})
            }

        } else {

            this.setState({office: {}})

        }



    }

    render() {

        if(this.state.shouldRedirect) { return <Redirect to={this.state.shouldRedirect} /> }

        const office = this.state.office
        if(!office) return <div className="py-6"><Circle /></div>

        return (
            <>

            <Container fluid>

                <Helmet>
                    <title>Office View</title>
                    <meta name="description" content="Court View" />
                </Helmet>

                 <DashHeaderOpen
                    title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
                    breadcrumb_1="Offices"
                    breadcrumb_2={office.name}
                    actionComponent={(
                        <Link to="/core/locations/offices" className="btn btn-outline-warning"><i className="fas fa-arrow-left" /> Back</Link>
                    )}
                />

                <Card  className="card-color card-warning">

                    <CardHeader>
                        <h3 className="mb-0">View Office</h3>
                    </CardHeader>

                    <CardBody>

                        <StandardFormGroup
                            obj={office}
                            objName="office"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                            field="name"
                            title="Name *"
                        />

                        <StandardFormGroup
                            obj={office}
                            objName="office"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                            field="email"
                            title="Email"
                        />

                        <StandardFormGroup
                            obj={office}
                            objName="office"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                            field="phone"
                            title="Phone"
                            type="tel"
                        />

                        <hr />

                        <Row>
                            <Col md="6">
                                <StandardFormGroup
                                    obj={office}
                                    objName="office"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="address_line_1"
                                    title="Address Line 1 "
                                />
                            </Col>
                            <Col md="6">
                                <StandardFormGroup
                                    obj={office}
                                    objName="office"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="address_line_2"
                                    title="Address Line 2 "
                                />
                            </Col>
                        </Row>

                        <Row>

                            <Col lg="4">
                                <StandardFormGroup
                                    obj={office}
                                    objName="office"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="city"
                                    title="City"
                                />
                            </Col>

                            <Col lg="4">
                                <StandardFormGroup
                                    obj={office}
                                    objName="office"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="state"
                                    title="State"
                                    type="select"
                                >
                                    <option value="">Select State</option>
                                    <States />
                                </StandardFormGroup>
                            </Col>

                            <Col lg="4">
                                <StandardFormGroup
                                    obj={office}
                                    objName="office"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="postal_code"
                                    title="Postal code "
                                />
                            </Col>

                        </Row>

                    </CardBody>

                    <CardFooter className="my-4 text-right">
                        <button className="btn btn-success" onClick={this.onSave} disabled={this.state.canSave ? false : true} >
                            <i className="fas fa-save mr-2" /> Save Office
                        </button>
                    </CardFooter>

                </Card>

            </Container>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        offices: state.offices.offices
    };
};

export default connect(mapStateToProps, '')(OfficesView);
