import React from 'react';
import { Card, CardBody, CardHeader, CardTitle, Row, Col } from 'reactstrap';

const EmailSendersList = ({activeSender, senders, onSetActiveSender, onAddSender}) => (
    <Card className="card-color card-primary list-hover" >

        <CardHeader>
            <Row>
                <Col lg={6} className="align-self-center">
                    <CardTitle className="mb-0">Senders</CardTitle>
                </Col>
                <Col lg={6} className="align-self-center text-right" onClick={onAddSender}>
                    <button className="btn btn-outline-success">
                        <i className="fas fa-plus mr-2 " /> Add
                    </button>
                </Col>
            </Row>
        </CardHeader>

        <CardBody className="">
            <ul className="mb-0">

                {senders && senders.length ? senders.map((sender, i) => (
                    <li key={sender._id ? sender._id : i} onClick={() => onSetActiveSender(sender)} className={activeSender._id === sender._id ? 'active' : null}>
                        <p className="text-sm font-weight-bold mb-0">{sender.name}</p>
                        <p className="text-sm mb-0">{sender.email}</p>
                    </li>
                )) : (
                    <li>
                        <p className="text-sm text-warning font-weight-bold mb-0">No email senders have been created</p>
                    </li>
                )}

            </ul>
        </CardBody>

    </Card>
)

export default EmailSendersList