import React, { useState, useCallback, useEffect } from 'react';

import { Card, CardHeader, Col, Container, Row, CardTitle } from 'reactstrap';

import { Link } from 'react-router-dom';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import Circle from 'components/markup/loading/Circle';

import _analytics from '_functions/analytics';

import moment from 'moment';

import HiredLogs from './HiredLogs';
import OverviewBoxes from './OverviewBoxes';

import UserCallMetrics from './UserCallMetrics';

import { formatMoney } from 'utils/currency';

const MasterReport = () => {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [start, setStart] = useState(moment().startOf('day').toDate());
    const [err, setErr] = useState(null);
    
    // const [caseTypes, setCaseTypes] = useState({});
    // const [hiredTypes, setHiredTypes] = useState({});
    // const [callLogTypes, setCallLogTypes] = useState({});
    // const [leadSourceTypes, setLeadSourceTypes] = useState({});

    // const onSetHiredTypes = useCallback((logs) => {
    //     let outcomes = {};
    //     logs.forEach(_case => {
    //         if(_case.workflow) {
    //             if(outcomes[_case.workflow.type]) {
    //                 outcomes[_case.workflow.type] += 1;
    //             } else {
    //                 outcomes[_case.workflow.type] = 1;
    //             }
    //         }
          
    //     })

    //     setHiredTypes(outcomes)
    // }, [])
   

    // const onSetCallLogs = useCallback((callLogs) => {
    //     let outcomes = {};
    //     callLogs.forEach(callLog => {
    //         if(callLog.user) {
    //             if(outcomes[callLog.user]) {
    //                 outcomes[callLog.user] += 1;
    //             } else {
    //                 outcomes[callLog.user] = 1;
    //             }
    //         }
    //     })

    //     // sort by most call first
    //     let userArray = []
    //     let finalObj = {}
    //     Object.keys(outcomes).forEach((a, b) => {
    //         if(outcomes[a] < outcomes[b]) {
    //             userArray.push(a)
    //         } else {
    //             userArray.unshift(a)
    //         }
    //     })

    //     userArray.forEach((user) => {
    //         finalObj[user] = outcomes[user]
    //     })
    //     setCallLogTypes(finalObj);
    // }, [])

    // const onSetLeadSourceTypes = useCallback((lead_sources) => {
    //     let outcomes = {};
    //     lead_sources.forEach(source => {
    //         if(source.status) {
    //             if(outcomes[source.status]) {
    //                 outcomes[source.status] += 1;
    //             } else {
    //                 outcomes[source.status] = 1;
    //             }
    //         }
          
    //     })

    //     setLeadSourceTypes(outcomes)
    // }, [])

    const fetchData = useCallback(async (hideLoad) => {

        const startDate = moment(start).format('X')
        const endDate = moment(start).add(1, 'days').format('X')

        if(!hideLoad) setLoading(true)
        const data = await _analytics.master.getReport(`?start=${startDate}&end=${endDate}`);
        if(!hideLoad) setLoading(false);

        if(data.data) {
            setData(data.data);
        } else {
            setErr(true)
        }
    }, [start])


    useEffect(() => {
        fetchData()

        const refreshInterval = setInterval(() => {
            fetchData(true);
        }, 30 * 1000)
        return () => {
            clearInterval(refreshInterval)
        }
    }, [fetchData])

    if(err) return (
        <div className="alert alert-danger">Something's not right, please try again.</div>
    )

    if(!data) return <div className="py-6"><Circle /></div>
    if(loading) return <div className="py-6"><Circle /></div>

    return (

        <Container className="collections" fluid>

            <DashHeaderOpen
                title={<span><i className="fas fa-dollar-sign mr-2 text-success " /> Master</span>}
                breadcrumb_1="Reports"
                breadcrumb_2="View"
                actionComponent={(
                    <Link to="/analytics/compare" className="btn btn-outline-success">
                        <i className="fas fa-chart-pie mr-2 " /> Compare Users
                    </Link>
                )}
            />

         
            <Row>

                <Col lg={6}>

                    <Card>
                        <CardHeader>
                            <CardTitle className="mb-0">Data (Guaranteed)</CardTitle>       
                        </CardHeader>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Field</th>
                                        <th className="text-right">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Total Cases / Signed</td>
                                        <td className="text-right">
                                            {data.cases.total} / {data.cases.totalSigned}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Close Rate</td>
                                        <td className="text-right">{data.cases.closeRate}</td>
                                    </tr>
                                    <tr>
                                        <td>Emailed</td>
                                        <td className="text-right">
                                            {((data.cases.emailed / data.cases.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                            <span style={{display: 'inline-block', width: 30}}>
                                            {data.cases.emailed}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Now Show</td>
                                        <td className="text-right">
                                            {((data.cases.noShow / data.cases.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                            <span style={{display: 'inline-block', width: 30}}>
                                            {data.cases.noShow}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Call Scheduled</td>
                                        <td className="text-right">
                                            {((data.cases.callScheduled / data.cases.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                            <span style={{display: 'inline-block', width: 30}}>
                                            {data.cases.callScheduled}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Call Conducted</td>
                                        <td className="text-right">
                                            {((data.cases.callConducted / data.cases.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                            <span style={{display: 'inline-block', width: 30}}>
                                            {data.cases.callConducted}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Sent Retainer</td>
                                        <td className="text-right">
                                            {((data.cases.sentRetainer / data.cases.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                            <span style={{display: 'inline-block', width: 30}}>
                                            {data.cases.sentRetainer}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Signed Retainer</td>
                                        <td className="text-right">
                                            {((data.cases.signedRetainer / data.cases.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                            <span style={{display: 'inline-block', width: 30}}>
                                            {data.cases.signedRetainer}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Submitted</td>
                                        <td className="text-right">
                                            {((data.cases.submitted / data.cases.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                            <span style={{display: 'inline-block', width: 30}}>
                                            {data.cases.submitted}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Signature</td>
                                        <td className="text-right">
                                            {((data.cases.signature / data.cases.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                            <span style={{display: 'inline-block', width: 30}}>
                                            {data.cases.signature}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>IRS</td>
                                        <td className="text-right">
                                            {((data.cases.irs / data.cases.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                            <span style={{display: 'inline-block', width: 30}}>
                                            {data.cases.irs}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Payment</td>
                                        <td className="text-right">
                                            {((data.cases.payment / data.cases.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                            <span style={{display: 'inline-block', width: 30}}>
                                            {data.cases.payment}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Dead</td>
                                        <td className="text-right">
                                            {((data.cases.dead / data.cases.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                            <span style={{display: 'inline-block', width: 30}}>
                                            {data.cases.dead}
                                            </span>
                                        </td>
                                    </tr>

                                   
                                </tbody>
                            </table>
                        </div>
                      
                    </Card>

                </Col>

                <Col lg={6}>

                <Card>
                        <CardHeader>
                            <CardTitle className="mb-0">Aggregations (Guaranteed)</CardTitle>       
                        </CardHeader>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Field</th>
                                        <th className="text-right">Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Current Net-Net Fees</td>
                                        {/* <td className="text-right">{formatMoney(data.cases.netFees)}</td> */}
                                        <td className="text-right">{formatMoney(data.guaranteed.netFees)}</td>
                                    </tr>
                                    <tr>
                                        <td>Anticipated Net-Net Fees</td>
                                        <td className="text-right">{formatMoney(data.guaranteed.anticipatedNetFees)}</td>
                                    </tr>
                                    <tr>
                                        <td>Avg Net Fee Per Case</td>
                                        <td className="text-right">{formatMoney(data.guaranteed.avgNetPerCase)}</td>
                                    </tr>
                                    <tr>
                                        <td>Avg ERTC</td>
                                        <td className="text-right">{formatMoney(data.guaranteed.avgERTC)}</td>
                                    </tr>
                                    <tr>
                                        <td>Acceptable Price / Lead</td>
                                        <td className="text-right">{formatMoney(data.guaranteed.acceptablePricePerLead)}</td>
                                    </tr>
                                    <tr>
                                        <td>Marketing Fees</td>
                                        <td className="text-right">{formatMoney(data.guaranteed.calculatedTotalFeeMarketing)}</td>
                                    </tr>
                                    <tr>
                                        <td>Gross Fees</td>
                                        {/* <td className="text-right">{formatMoney(data.guaranteed.calculatedTotalFeeBiz)}</td> */}
                                        <td className="text-right">{formatMoney(data.guaranteed.calculatedTotalFeeBiz)}</td>
                                    </tr>
                                    <tr>
                                        <td>Anticipated Gross Fees</td>
                                        <td className="text-right">{formatMoney(data.guaranteed.anticipatedGrossFees)}</td>
                                    </tr>
                                    <tr>
                                        <td>Gross Margin</td>
                                        <td className="text-right">{data.guaranteed.grossMargin}</td>
                                    </tr>
                                    <tr>
                                        <td>Avg Marketing Fee / Case</td>
                                        <td className="text-right">{formatMoney(data.guaranteed.avgMarketingPerCase)}</td>
                                    </tr>
                                    <tr>
                                        <td>Anticipated Marketing Fees</td>
                                        <td className="text-right">{formatMoney(data.guaranteed.anticipatedMarketingFees)}</td>
                                    </tr>
                                    <tr>
                                        <td>Avg Gross Fee</td>
                                        <td className="text-right">{formatMoney(data.guaranteed.avgGrossFee)}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className="text-right text-white">-</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Card>

                </Col>

            </Row>

            <Row>

<Col lg={6}>

    <Card>
        <CardHeader>
            <CardTitle className="mb-0">Data (All Steps)</CardTitle>       
        </CardHeader>
        <div className="table-responsive">
            <table className="table">
                <thead>
                    <tr>
                        <th>Field</th>
                        <th className="text-right">Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Total Cases / Signed</td>
                        <td className="text-right">
                            {data.cases.total} / {data.cases.totalSigned}
                        </td>
                    </tr>
                    <tr>
                        <td>Close Rate</td>
                        <td className="text-right">{data.cases.closeRate}</td>
                    </tr>
                    <tr>
                        <td>Emailed</td>
                        <td className="text-right">
                            {((data.cases.emailed / data.cases.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                            <span style={{display: 'inline-block', width: 30}}>
                            {data.cases.emailed}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>Now Show</td>
                        <td className="text-right">
                            {((data.cases.noShow / data.cases.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                            <span style={{display: 'inline-block', width: 30}}>
                            {data.cases.noShow}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>Call Scheduled</td>
                        <td className="text-right">
                            {((data.cases.callScheduled / data.cases.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                            <span style={{display: 'inline-block', width: 30}}>
                            {data.cases.callScheduled}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>Call Conducted</td>
                        <td className="text-right">
                            {((data.cases.callConducted / data.cases.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                            <span style={{display: 'inline-block', width: 30}}>
                            {data.cases.callConducted}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>Sent Retainer</td>
                        <td className="text-right">
                            {((data.cases.sentRetainer / data.cases.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                            <span style={{display: 'inline-block', width: 30}}>
                            {data.cases.sentRetainer}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>Signed Retainer</td>
                        <td className="text-right">
                            {((data.cases.signedRetainer / data.cases.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                            <span style={{display: 'inline-block', width: 30}}>
                            {data.cases.signedRetainer}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>Submitted</td>
                        <td className="text-right">
                            {((data.cases.submitted / data.cases.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                            <span style={{display: 'inline-block', width: 30}}>
                            {data.cases.submitted}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>Signature</td>
                        <td className="text-right">
                            {((data.cases.signature / data.cases.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                            <span style={{display: 'inline-block', width: 30}}>
                            {data.cases.signature}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>IRS</td>
                        <td className="text-right">
                            {((data.cases.irs / data.cases.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                            <span style={{display: 'inline-block', width: 30}}>
                            {data.cases.irs}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>Payment</td>
                        <td className="text-right">
                            {((data.cases.payment / data.cases.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                            <span style={{display: 'inline-block', width: 30}}>
                            {data.cases.payment}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>Dead</td>
                        <td className="text-right">
                            {((data.cases.dead / data.cases.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                            <span style={{display: 'inline-block', width: 30}}>
                            {data.cases.dead}
                            </span>
                        </td>
                    </tr>

                   
                </tbody>
            </table>
        </div>
      
    </Card>

</Col>

<Col lg={6}>

<Card>
        <CardHeader>
            <CardTitle className="mb-0">Aggregations (All Steps)</CardTitle>       
        </CardHeader>
        <div className="table-responsive">
            <table className="table">
                <thead>
                    <tr>
                        <th>Field</th>
                        <th className="text-right">Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Current Net-Net Fees</td>
                        {/* <td className="text-right">{formatMoney(data.cases.netFees)}</td> */}
                        <td className="text-right">{formatMoney(data.cases.netFees)}</td>
                    </tr>
                    <tr>
                        <td>Anticipated Net-Net Fees</td>
                        <td className="text-right">{formatMoney(data.cases.anticipatedNetFees)}</td>
                    </tr>
                    <tr>
                        <td>Avg Net Fee Per Case</td>
                        <td className="text-right">{formatMoney(data.cases.avgNetPerCase)}</td>
                    </tr>
                    <tr>
                        <td>Avg ERTC</td>
                        <td className="text-right">{formatMoney(data.cases.avgERTC)}</td>
                    </tr>
                    <tr>
                        <td>Acceptable Price / Lead</td>
                        <td className="text-right">{formatMoney(data.cases.acceptablePricePerLead)}</td>
                    </tr>
                    <tr>
                        <td>Marketing Fees</td>
                        <td className="text-right">{formatMoney(data.cases.calculatedTotalFeeMarketing)}</td>
                    </tr>
                    <tr>
                        <td>Gross Fees</td>
                        {/* <td className="text-right">{formatMoney(data.cases.calculatedTotalFeeBiz)}</td> */}
                        <td className="text-right">{formatMoney(data.cases.calculatedTotalFeeBiz)}</td>
                    </tr>
                    <tr>
                        <td>Anticipated Gross Fees</td>
                        <td className="text-right">{formatMoney(data.cases.anticipatedGrossFees)}</td>
                    </tr>
                    <tr>
                        <td>Gross Margin</td>
                        <td className="text-right">{data.cases.grossMargin}</td>
                    </tr>
                    <tr>
                        <td>Avg Marketing Fee / Case</td>
                        <td className="text-right">{formatMoney(data.cases.avgMarketingPerCase)}</td>
                    </tr>
                    <tr>
                        <td>Anticipated Marketing Fees</td>
                        <td className="text-right">{formatMoney(data.cases.anticipatedMarketingFees)}</td>
                    </tr>
                    <tr>
                        <td>Avg Gross Fee</td>
                        <td className="text-right">{formatMoney(data.cases.avgGrossFee)}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td className="text-right text-white">-</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </Card>

</Col>

</Row>
            

            <OverviewBoxes 
                        data={data}
                    />
        </Container>

    )

}

export default MasterReport;