/*
Documentation

*** make sure to declare all action types in actions.js one folder level back

*/

import * as actionTypes from '../../actions';

const initialState = {
    users: [],
    images: {},
    total: 0,
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.SET_USERS:

            return {
                ...state,
                users: action.payload.data,
                total: action.payload.total_documents,
            }

        case actionTypes.SET_USER_IMAGE:

            return {
                ...state,
                images: {
                    ...state.images,
                    ...action.payload
                }
            }

        default: return state;

    }
}



export default reducer;
