import { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Row, Col, Card, CardHeader, CardBody, CardTitle, CardFooter, Container } from 'reactstrap';

import _flows from '_functions/flows'
import _flow_tasks from '_functions/flow_tasks'

import Circle from 'components/markup/loading/Circle'

import { secondsToTime } from 'utils/time'


import AddTask from './AddTask'
import DeleteTask from './DeleteTask'
import Update from './Update'

const FlowsEdit = ({match, template_emails, template_texts}) => {

    const [flow_id] = useState(match.params._id)
    const [flow, setFlow] = useState(null)
    const [flows, setFlows] = useState([]);
    const [tasks, setTasks] = useState(null)
    const [movesTo, setMovesTo] = useState(undefined)
    const [movesFrom, setMovesFrom] = useState(undefined)
    const [showModalAdd, setShowModalAdd] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalUpdate, setShowModalUpdate] = useState(false);
    const [taskToDelete, setTaskToDelete] = useState(false);

    const getName = useCallback(task => {
        let found;
        if(task.type === 'start' || task.type === 'end') {
            found = flows.find(f => f._id === task.id)
        } else if(task.type === 'email') {
            found = template_emails.find(e => e._id === task.id)
        } else if(task.type === 'text') {
            found = template_texts.find(t => t._id === task.id)
        }
        return found ? found.name : 'Item Not Found'

    }, [flows, template_emails, template_texts])

    const renderTasks = useCallback((_tasks) => {

        const markup = [];
        const task = _tasks.find(t => !t.moves_to);
        if(!task && _tasks.length) return alert('An internal error occurred contact support. Code FL1')
        if(!task) return (
            <div className="py-3 text-center">
                <i 
                    onClick={() => {
                        setMovesFrom(undefined)
                        setMovesTo(undefined)
                        setShowModalAdd(true)
                    }}
                    className="cursor-pointer fas fa-plus-circle text-success display-1" 
                />
            </div>
        )

        let firstTask = null

        const pushToMarkup = (task => {
            const lastTask = tasks.find(t => t.moves_to === task._id)

            markup.unshift((
                <div>
                    <Card>
                        <CardHeader className="text-capitalize">
                            <Row>
                                <Col xs={6} className="col-auto">
                                    {task.type === 'email' ? (
                                        <i className="fas fa-envelope text-success " />
                                    ) : task.type === 'text' ? (
                                        <i className="fas fa-mobile text-info " />
                                    ) : task.type === 'start' ? (
                                        <i className="fas fa-check text-success " />
                                    ) : task.type === 'end' ? (
                                        <i className="fas fa-times text-danger " />
                                    ) : task.type === 'wait' ? (
                                        <i className="fas fa-clock text-purple " />
                                    ) : ''}
                                    <span className="ml-3">{task.type}</span>
                                </Col>
                                <Col xs={6} className="text-right col-auto">
                                    <button className="btn btn-danger" onClick={() => {
                                        setTaskToDelete(task)
                                        setShowModalDelete(true)
                                    }}>
                                        <i className="fas fa-trash mr-2 " /> Delete
                                    </button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            {task.type === 'email' ? (
                                <div>{getName(task)}</div>
                            ) : task.type === 'text' ? (
                                <div>Send Text -  {getName(task)}</div>
                            ) : task.type === 'start' ? (
                                <div>Start Flow -  {getName(task)}</div>
                            ) : task.type === 'text' ? (
                                <div>End Flow -  {getName(task)}</div>
                            ) : task.type === 'wait' ? (
                                <div>Wait {secondsToTime(task.delay)}</div>
                            ) : ''}

                            {task._id}
                        </CardBody>
                    </Card>

                    <div className="py-3 text-center">
                        <i 
                            onClick={() => {
                                console.log(lastTask)
                                setMovesFrom(task._id)
                                setMovesTo(task.moves_to ? task.moves_to : undefined)
                                setShowModalAdd(true)
                            }}
                            className="cursor-pointer fas fa-plus-circle text-success display-1" 
                        />
                    </div>
                </div>
            ))

            if(lastTask) {
                pushToMarkup(lastTask)
            } else {
                firstTask = task;
            }
        })

        pushToMarkup(task)

        if(firstTask) {
            markup.unshift((
                <div className="py-3 text-center">
                    <i 
                        onClick={() => {
                            setMovesFrom(undefined)
                            setMovesTo(firstTask._id)
                            setShowModalAdd(true)
                        }}
                        className="cursor-pointer fas fa-plus-circle text-success display-1" 
                    />
                </div>
            ))
        }

        return markup

    }, [getName, tasks])
    
    const fetchFlows = useCallback(async () => {
        const data = await _flows.find();
        if(data.data) {
            const found = data.data.find(f => f._id === flow_id);
            setFlows(data.data)
            setFlow(found ? found : 'not found')
        }
    }, [flow_id])

    const fetchTasks = useCallback(async () => {
        const data = await _flow_tasks.find(flow_id);
        if(data.data) setTasks(data.data)
    }, [flow_id])

    useEffect(() => {
        fetchFlows();
        fetchTasks();
    }, [fetchFlows, fetchTasks])

    if(!flows || !tasks || !flow) return <div className="py-6"><Circle /></div>
    if(flow === 'not found') return <Redirect to="/automations/flows/all" />

    return (
        <div>

            <Container className="pt-4" fluid>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col md={8} className="align-self-center">
                                <CardTitle className="mb-0">
                                    {flow.active ? <span className="text-success">ACTIVE: </span> : <span className="text-warning">INACTIVE: </span>}
                                    {flow.name} - {tasks.length} Tasks
                                </CardTitle>
                            </Col>
                            <Col md={4} className="align-self-center text-right">
                                <button className="btn btn-outline-success" onClick={() => setShowModalUpdate(true)}>Update</button>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col>
                                Emails: {tasks.filter(t => t.type === 'email').length}
                            </Col>
                            <Col>
                                Texts: {tasks.filter(t => t.type === 'texts').length}
                            </Col>
                            <Col>
                                Start Flow: {tasks.filter(t => t.type === 'start').length}
                            </Col>
                            <Col>
                                End Flow: {tasks.filter(t => t.type === 'end').length}
                            </Col>
                            <Col>
                                Wait: {tasks.filter(t => t.type === 'wait').length}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>

            <Container style={{width: 500}}>
                {renderTasks(tasks)}
            </Container>

            <AddTask 
                showModal={showModalAdd}
                toggleModal={() => setShowModalAdd(false)}
                fetchTasks={fetchTasks}
                flow_id={flow_id}
                movesFrom={movesFrom}
                movesTo={movesTo}
                flows={flows}
            />
            <DeleteTask 
                showModal={showModalDelete}
                toggleModal={() => setShowModalDelete(false)}
                taskToDelete={taskToDelete}
                tasks={tasks}
                getName={getName}
                fetchTasks={fetchTasks}
            />
            <Update 
                showModal={showModalUpdate}
                toggleModal={() => setShowModalUpdate(false)}
                flow={flow}
                setFlow={setFlow}
            />

        </div>
    )

}


const mapStateToProps = state => {
    return {
        template_emails: state.template_emails.template_emails,
        template_texts: state.template_texts.template_texts,
    };
};

export default connect(mapStateToProps, '')(FlowsEdit);;