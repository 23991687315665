import PropTypes from 'prop-types';

import React from "react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

import { toggleStandardLoader } from 'store/functions/system/system';
import DragAndDrop from 'components/functional/uploads/DragAndDrop';

class WordDocsView extends React.Component {

    render() {

        const { onFileAdded, documentData, shouldFireUploads, onUpload } = this.props

        return (


            <Card style={{display: documentData ? 'none' : 'block', height: 'auto', width: 720, margin: 'auto'}} className="z-depth-3">

                <CardHeader className="bg-secondary">
                    <CardTitle className="mb-0">Upload Word Document</CardTitle>
                </CardHeader>

                <CardBody className="text-center py-6">
                    <DragAndDrop
                        // only allow word documents
                        acceptedFiles="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        uploadOne={true}
                        zone_id="documents_1"
                        url={`/api/v1/core/documents/create`}
                        hidePreview={true}
                        onFileAdded={onFileAdded}
                        shouldShowButton={false}
                        shouldFireUploads={shouldFireUploads}
                        onUpload={(a, b) => { onUpload(a, b) }}
                        onError={(e) => toggleStandardLoader(false)}
                        defaultValue={`Template Word Doc`}
                        extraData={{bucket: `/template word docs`}}
                    />
                </CardBody>

            </Card>


        );
    }
}

WordDocsView.propTypes = {
    onFileAdded         : PropTypes.func.isRequired,
    onUpload            : PropTypes.func.isRequired,
    shouldFireUploads   : PropTypes.bool.isRequired,
    documentData        : PropTypes.object,

}

export default WordDocsView
