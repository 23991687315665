const state = {

    question: {
        type: 'text',
        answers: [],
        questions: [],
        description: '',
        hint: '',
        placeholder: '',
        required: true,
        map_field: true,
        alert_on_answer: '',
        tag_on_answer: {}
    },

    questionnaire: {
        name              : '',
        active            : false,
        create_pdf        : false,
        send_emails_to    : [],
    }

}

export default state;