import { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux';

import { Modal, FormGroup, Input } from "reactstrap";
import { toggleAlertBS } from 'store/functions/system/system';

import { toggleStandardLoader } from 'store/functions/system/system';

import _flow_tasks from '_functions/flow_tasks'

// flow        : { type: Schema.Types.ObjectId, ref: 'flows', required: true },
// moves_to    : { type: Schema.Types.ObjectId, ref: 'flow_tasks', default: null },
// type        : { type: String, required: true },
// delay       : { type: Number },
// id          : { type: Schema.Types.ObjectId },

const minute = 60;
const hour = minute * 60;
const day = 86400;

let _id = 1

const FlowsEditAddTask = ({showModal, toggleModal, fetchTasks, flow_id, flows, template_emails, template_texts, movesTo, movesFrom}) => {

    const [type, setType] = useState('email');
    const [delay, setDelay] = useState(0);
    const [id, setId] = useState('');
    const [errs, setErrs] = useState('');
    console.log(movesTo)
    console.log(movesFrom)

    const onSave = useCallback(async (state) => {

        const errs = [];

        if(!type) errs.push('type');
        if(type === 'wait' && !delay) errs.push(delay);
        if(type !== 'wait' && !id) errs.push(id);

        setErrs(errs);

        if(errs.length) return;

        toggleStandardLoader(true);
        const data = await _flow_tasks.create({
            flow: flow_id, 
            id: type === 'wait' ? undefined : id, 
            delay: type === 'wait' ? parseInt(delay): undefined, 
            type, 
            moves_to: movesTo,
            moves_from: movesFrom
        })
        toggleStandardLoader(false);

        if(data.success) {
            fetchTasks()
            toggleModal();
        } else {
            toggleAlertBS(true, 'Please try again.')
        }


    }, [type, delay, id, fetchTasks, movesTo, toggleModal, flow_id, movesFrom])

    useEffect(() => {
        if(!showModal) {
            setType('email');
            setDelay(0);
            setId('');
        }
    }, [showModal])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >

            <div className="modal-header">
                <h5 className="modal-title">Create A Task</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">

                <FormGroup>
                    <label className="form-control-label">Task Type*</label>
                    <Input 
                        type="select"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        invalid={errs.includes('type')}
                    >
                        <option value="email">Send Email</option>
                        <option value="text">Send Text</option>
                        <option value="start">Start a Flow</option>
                        <option value="end">End a Flow</option>
                        <option value="wait">Wait</option>
                    </Input>
                </FormGroup>
                
                {type === 'email' ? (
                    <FormGroup>
                        <label className="form-control-label">Email To Send*</label>
                        <Input 
                            type="select"
                            value={id}
                            onChange={(e) => setId(e.target.value === 'false' ? '' : e.target.value)}
                            invalid={errs.includes('id')}
                        >
                            <option value="false"></option>
                            {template_emails.map(email => (
                                <option key={email._id} value={email._id}>{email.name}</option>
                            ))}
                        </Input>
                    </FormGroup>
                ) : type === 'text' ? (
                    <FormGroup>
                        <label className="form-control-label">Text To Send*</label>
                        <Input 
                            type="select"
                            value={id}
                            onChange={(e) => setId(e.target.value === 'false' ? '' : e.target.value)}
                            invalid={errs.includes('id')}
                        >
                            <option value="false"></option>
                            {template_texts.map(text => (
                                <option key={text._id} value={text._id}>{text.name}</option>
                            ))}
                        </Input>
                    </FormGroup>
                ) : type === 'start' || type === 'end' ? (
                    <FormGroup>
                        <label className="form-control-label">{type === 'start' ? 'Flow To Start' : 'Flow To End'}*</label>
                        <Input 
                            type="select"
                            value={id}
                            onChange={(e) => setId(e.target.value === 'false' ? '' : e.target.value)}
                            invalid={errs.includes('id')}
                        >
                            <option value="false"></option>
                            {flows.map(flow => (
                                flow._id === flow_id ? '' : 
                                <option key={flow._id} value={flow._id}>{flow.name}</option>
                            ))}
                        </Input>
                    </FormGroup>
                ) : type === 'wait' ? (
                    <FormGroup>
                        <label className="form-control-label">Wait Time</label>
                        <Input 
                            type="select"
                            value={delay}
                            onChange={(e) => setDelay(e.target.value === 'false' ? '' : e.target.value)}
                            invalid={errs.includes('delay')}
                        >
                            <option value="false"></option>
                            <option value={minute}>1 Minute</option>
                            <option value={minute * 5}>5 Minutes</option>
                            <option value={minute * 10}>10 Minutes</option>
                            <option value={minute * 15}>15 Minutes</option>
                            <option value={minute * 20}>20 Minutes</option>
                            <option value={minute * 30}>30 Minutes</option>
                            <option value={minute * 45}>45 Minutes</option>
                            <option value={hour * 1}>1 Hour</option>
                            <option value={hour * 1.5}>1.5 Hours</option>
                            <option value={hour * 2}>2 Hours</option>
                            <option value={hour * 3}>3 Hours</option>
                            <option value={hour * 4}>4 Hours</option>
                            <option value={hour * 5}>5 Hours</option>
                            <option value={hour * 6}>6 Hours</option>
                            <option value={hour * 7}>7 Hours</option>
                            <option value={hour * 8}>8 Hours</option>
                            <option value={hour * 16}>16 Hours</option>
                            <option value={day * 1}>1 Day</option>
                            <option value={day * 2}>2 Days</option>
                            <option value={day * 3}>3 Days</option>
                            <option value={day * 4}>4 Days</option>
                            <option value={day * 5}>5 Days</option>
                            <option value={day * 6}>6 Days</option>
                            <option value={day * 7}>7 Days</option>
                            <option value={day * 10}>10 Days</option>
                            <option value={day * 14}>14 Days</option>
                            <option value={day * 21}>21 Days</option>
                            <option value={day * 28}>28 Days</option>
                            <option value={day * 30}>30 Days</option>
                            <option value={day * 45}>45 Days</option>
                            <option value={day * 60}>60 Days</option>
                            <option value={day * 75}>75 Days</option>
                            <option value={day * 90}>90 Days</option>
                            <option value={day * 120}>120 Days</option>
                            <option value={day * 150}>150 Days</option>
                            <option value={day * 180}>180 Days</option>
                            <option value={day * 210}>210 Days</option>
                            <option value={day * 240}>240 Days</option>
                            <option value={day * 270}>270 Days</option>
                            <option value={day * 300}>300 Days</option>
                            <option value={day * 365}>365 Days</option>

                        </Input>
                    </FormGroup>
                ) : ''}

            </div>

            <div className="modal-footer text-right">
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            </div>

        </Modal>

    )

}

const mapStateToProps = state => {
    return {
        template_emails: state.template_emails.template_emails,
        template_texts: state.template_texts.template_texts,
    };
};

export default connect(mapStateToProps, '')(FlowsEditAddTask);