import { connect } from 'react-redux';
import React, {useEffect} from 'react';

import moment from 'moment';
import renderName from 'utils/renderName';
import { formatPhone } from 'utils/text'

import _analytics from '_functions/analytics'
import NameCircle from 'components/markup/misc/NameCircle';

import QueryCreator from './QueryCreator';

import { UncontrolledCollapse } from 'reactstrap'

import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import { Modal, FormGroup, Input } from "reactstrap";

import { toggleStandardLoader } from 'store/functions/system/system';

import ModalToggler from 'components/functional/modals/Toggler'

const HistoryModal = ({showModal, toggleModal, row}) => {
    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">Call History</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">

                <div>
                    <div>{renderName(row)}</div>
                    <div>Email: {row.email ? row.email : '-'}</div>
                    <div>Phone: {row.phone ? formatPhone(row.phone) : '-'}</div>
                </div>

            {!row.history.length ? (
                <p className="mb-0">No call history has been recorded yet for this lead.</p>
            ) : row.history.map(h => (
                <div className="bg-secondary rounded mb-2 border p-3" style={{whiteSpace: 'pre-line'}}>
                    {console.log(h)}
                    <div>Called By: {h.call_by}</div>
                    <div>Disposition: 
                        {
                            h.disposition === 'A' ? 'Answering Machine' :
                            h.disposition === 'BADNUM' ? 'Bad Number' :
                            h.disposition === 'FORMFS' ? 'Form Fill Submission' :
                            h.disposition === 'HANGUP' ? 'Hang up' :
                            h.disposition === 'HUMARK' ? 'Hang up - Mark' :
                            h.disposition === 'HURON' ? 'Hang up - Ron' :
                            h.disposition === 'NOCXOL' ? 'No client on line' :
                            h.disposition === 'NOTIME' ? 'No Time' :
                            h.disposition === 'NTMARK' ? 'No Time - Mark' :
                            h.disposition === 'NTRON' ? 'No Time - Ron' :
                            h.disposition === 'NI' ? 'Not Interested' :
                            h.disposition === 'NIMARK' ? 'Not Interested - Mark' :
                            h.disposition === 'NIRON' ? 'Not Interested - Ron' :
                            h.disposition === 'DQ' ? 'Not Qualified' :
                            h.disposition === 'SPANIS' ? 'Spanish Only' :
                            h.disposition === 'DNC' ? 'Do Not Qualify' :
                            h.disposition === 'B' ? 'Busy' :
                            h.disposition === 'CALLBK' ? 'Call Back' :
                            h.disposition === 'DAIR' ? 'Dead Air' :
                            h.disposition === 'DC' ? 'Disconnected Number' :
                            h.disposition === 'STATIC' ? 'Static' :
                            h.disposition === 'ERERTC' ? 'Already Approved for ERTC' :
                            h.disposition === 'GATEKP' ? 'Gatekeeper' :
                            h.disposition
                        }
                    </div>
                    <div>Notes: {h.notes}</div>
                    <div>{moment.unix(h.date).format('MMM Do, YYYY h: mm A')}</div>
                </div>   
            ))} 
            </div>

            <div className="modal-footer text-right">
                <button className="btn btn-success" onClick={toggleModal}>
                    Close
                </button>
            </div>

        </Modal>
    )

}

const QueryContacts = ({viewing_user}) => {

    useEffect(() => {
        document.body.classList.add('analytics-sidebar-small')
        return () => {
            document.body.classList.remove('analytics-sidebar-small')
        }

    }, [])

    return (
        <QueryCreator 
            title="Leads Report"
            searchPlaceholder="Name, Email, Phone, ID"
            runQuery={async (query, cb) => new Promise (async resolve => {

                query.filter.lead_source = viewing_user.lead_source

                const result = await _analytics.query.leads(query)
                return resolve(result.data)
            })}
            inputs={[
                { type: 'ArchkDates', field: 'created_at', label: '', },
            ]}
            columns={[
                {
                    dataField: "given_name",
                    text: "Name",
                    formatter: (cell, row) => (
                        <div>
                            <NameCircle width={27} contact={row} style={{marginRight: 5}} />{' '}
                            {renderName(row)}   
                        </div>
                    ),
                },
                {
                    dataField: "company_name",
                    text: "Company Name",
                    formatter: (cell, row) => (
                        <div>
                            {row.company_name}
                        </div>
                    ),
                },
                {
                    dataField: "company_size",
                    text: "Company Size",
                    formatter: (cell, row) => (
                        <div>
                            {row.company_size}
                        </div>
                    ),
                },
                {
                    dataField: "email",
                    text: "Contact Info",
                    formatter: (cell, row) => {
        
                       if(row.email) {
                            return <div key={row._id}><i className="text-muted fas fa-envelope mr-2" /> {row.email}</div>
                       }

                       return '-'
        
                    }
                },
                {
                    dataField: "Phone",
                    text: "Contact Info",
                    formatter: (cell, row) => {
        
                        if(row.phone) {
                            return (
                                <div>
                                    <span 
                                        key={row._id + row.phone}
                                        className={row.blacklisted ? 'text-danger cursor-pointer' : 'cursor-pointer'}
                                    >
                                        <i   className={row.blacklisted ? 'text-danger fas fa-exclamation-triangle mr-3' : 'text-muted fas fa-phone mr-3'} /> 
                                        {formatPhone(row.phone)}
                                    </span>
                                </div>
                            )
                        }
                        return '-'
                    }
                },
                {
                    dataField: "history",
                    text: "Contact History",
                    formatter: (cell, row) => {
                        if(row.history && row.history.length) {
                            return (
                                <ModalToggler component={HistoryModal} row={row}>
                                         <span className="cursor-pointer">Contact Attempts: {row.history.length} <i className="fas fa-caret-down " /></span>
                                </ModalToggler>
                            )
                        }
                        return '-'
                    }
                },
                {
                    dataField: "created_at",
                    text: "Created",
                    headerStyle: { textAlign: 'right' },
                    formatter: (cell, row) => {
        
                        const date = moment.unix(row.created_at); 
        
                        return (
                            <div className="text-right">
                                <div>{date.format('MMM Do, YYYY h:mm A')}</div>
                            </div>
                        )
                    },
                },
            ]}
        
        />
    )

}


const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(QueryContacts);