/*
Documentation

this file renders the table showing all created tasks for the step being show in the step drawer
Tasks can then be updated or removed through the modal this file toggles in './TaskModal'

*/

import Circle from 'components/markup/loading/Circle';
import React, { Component } from 'react';
import { Card, UncontrolledTooltip } from 'reactstrap';
import _workflow_tasks from '_functions/workflow_tasks';
import TaskModal from './TaskModal';
import PropTypes from 'prop-types'

class StepDrawerTasks extends Component {

    state = {
        step: {},
        tasks: null,
        task: {},
        showModal: false,

    };

    toggleModal = (task) => this.setState({showModal: !this.state.showModal, task: task ? task : {}})

    onTaskDeleted = (task) => {

        let tasks = [...this.state.tasks]
        tasks = tasks.filter(t => t._id !== task._id);

        this.setState({tasks})

    }

    onTaskUpdated = (task) => {

        let tasks = [...this.state.tasks]
        tasks[tasks.findIndex(t =>  t._id === task._id)] = task

        this.setState({tasks})

    }

    onTaskCreated = (task) => {

        let tasks = [...this.state.tasks]
        tasks.push(task);

        this.setState({tasks})

    }

    componentWillReceiveProps = async (nextProps) => {

        const currentStepId = this.state.step ? this.state.step._id : null;
        const nextStepId = nextProps.step ? nextProps.step._id : null;

        if(nextStepId && nextStepId !== currentStepId) {

            this.setState({step: Object.assign({}, nextProps.step)})

            const foundTasks = await _workflow_tasks.findByWorkflowStep(nextProps.step._id)
            if(foundTasks.success) this.setState({tasks: foundTasks.data})

        }

    }

    render() {

        const { workflow, templateAppointmentOptions, templateDocOptions } = this.props
        const { step, task, tasks, showModal } = this.state

        return (

            <div>

                <h4><span id="tooltip-step-tasks">Step Tasks <i className="fas fa-info-circle text-info-original" /></span></h4>
                <UncontrolledTooltip
                    delay={0}
                    placement="bottom"
                    target="tooltip-step-tasks"
                >
                    NOTE: Tasks are saved to a step immediately when they are added, removed, or changed unlike other step properties.
                </UncontrolledTooltip>

                <Card>

                    {tasks ? (
                        <div className="table-responsive">
                            <table className="table">

                                <thead>
                                    <tr>
                                        <th>Tasks</th>
                                        <th>Required</th>
                                        <th>Persistent</th>
                                        <th>Automations</th>
                                        <th className="text-right">Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {tasks.length ? tasks.map(task => (
                                        <tr key={task._id}>
                                            <td style={{whiteSpace: 'pre-line'}}>
                                                {task.type === 'party association' ? 'Assign Party: ' : null}
                                                {task.name}
                                            </td>
                                            <td className="text-center">
                                                {task.required ? <i className="fas fa-check text-success " /> : <i className="fas fa-times text-danger" />}
                                            </td>
                                            <td className="text-center">
                                                {task.persistent ? <i className="fas fa-check text-success " /> : <i className="fas fa-times text-danger" />}
                                            </td>
                                            <td className="text-center">
                                                {task.automations && task.automations.length ? <i className="fas fa-check text-success " /> : <i className="fas fa-times text-danger" />}
                                            </td>
                                            <td className="text-right">
                                                <button style={{width: 90}} onClick={() => this.toggleModal(task)}  className="btn btn-success"><i className="fas fa-edit mr-2" />Edit</button>
                                            </td>
                                        </tr>
                                    )) : (
                                        <tr>
                                            <td>No Tasks Added</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td onClick={() => this.toggleModal()} className="text-right">
                                            <button style={{width: 90}} className="btn btn-outline-success"><i className="fas fa-edit mr-2" /> Add</button>
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    ) : (
                        <Circle />
                    )}

                </Card>

                <TaskModal
                    workflow={workflow}
                    task={task}
                    showModal={showModal}
                    toggle={this.toggleModal}
                    step_id={step._id}
                    onTaskDeleted={this.onTaskDeleted}
                    onTaskCreated={this.onTaskCreated}
                    onTaskUpdated={this.onTaskUpdated}
                    templateAppointmentOptions={templateAppointmentOptions}
                    templateDocOptions={templateDocOptions}
                />

            </div>

        )

    }

}

StepDrawerTasks.propTypes = {
    workflow                      : PropTypes.object.isRequired,
    step                          : PropTypes.object.isRequired,
    templateAppointmentOptions    : PropTypes.array.isRequired
}

export default StepDrawerTasks;
