import Circle from 'components/markup/loading/Circle';
import { useCallback, useEffect, useState } from "react";
import { Card, CardHeader, CardTitle, Container } from "reactstrap";


import moment from 'moment';

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';

import A from 'components/markup/links/A';
import keys from 'keys';
import _analytics from '_functions/analytics';

import renderName from 'utils/renderName';
import { formatPhone } from 'utils/text';


const Monitor = () => {

    const [data, setData] = useState(null)

    const fetchData = useCallback(async () => {
        const data = await _analytics.user()
        console.log(data);
        if(data.data) setData(data.data)
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    useEffect(() => {
        const interval = setInterval(() => {
            fetchData()
        }, 10000)

        return () => {
            clearInterval(interval);
        }
    })

    if(!data) return <Circle />

    return (
        <Container fluid>

            <DashHeaderOpen
                title={<span><i className="fas fa-headset mr-2 text-success " />Analytics</span>}
                breadcrumb_1="Monitoring"
                breadcrumb_2="Overview"
                actionComponent={(
                    <span><i className="fas fa-info-circle text-info-original mr-2 " /> This page refreshes every 10 seconds</span>
                )}
            />

            <Card className="card-color card-primary">

                <CardHeader>
                    <CardTitle className="mb-0">Clients Not Logged In ({data.length})</CardTitle>
                    <p className="text-sm mb-0">This shows all cases in the <b className="font-weight-bold text-dark text-underline">Retainer Signed - Awaiting Documents</b> step where we have not been able to mark the the client has logged into their portal. These clients need to be followed up with to walk them through uploading their documents.</p>
                </CardHeader>

                {/* <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <td>Case</td>
                                <td>Contact</td>
                                <td>Retainer Signed</td>
                                <td className="text-right">View</td>
                            </tr>
                        </thead>
                        <tbody>
                            {data.length ? data.map(d => (
                                <tr key={d._id}>
                                       <td>
                                            <div className="text-capitalize"><A href={`${keys.APP_URL}/dashboard/cases/view/${d._id}`}>{d.name}</A></div>
                                            <div className="text-capitalize"><A href={`${keys.APP_URL}/dashboard/cases/view/${d._id}`}>{d.company_dba}</A></div>
                                        </td>
                                        <td>
                                            {d.contact ? (
                                                <div>
                                                    <div className="text-capitalize"><A href={`${keys.APP_URL}/dashboard/cases/view/${d._id}`}>{renderName(d.contact)}</A></div>
                                                    <div>{d.contact.phone ? formatPhone(d.contact.phone) : ''}</div>
                                                    <div>{d.contact.email ? d.contact.email : ''}</div>
                                                </div>
                                            ) : 'No Contact Found'}
                                        </td>
                                        <td>{moment.unix(d.retainer_signed_at).format('MM/DD/YYYY h:mm A')}</td>
                                        <td className="text-right">
                                            <A className="btn btn-success" href={`${keys.APP_URL}/dashboard/cases/view/${d._id}`}>View Case</A>
                                        </td>
                                </tr>
                            )) : (
                                <tr>
                                    <td>No Cases Found</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>             */}

            </Card>

        </Container>
    )
}

export default Monitor;
