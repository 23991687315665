import EmailValidator from 'email-validator';
import React, { useState } from "react";
import { Badge, CardHeader, CardTitle, FormGroup, Input } from "reactstrap";

const EmailParsersIdentifiersEmail = ({parser, setParser, onChange, minCardBodyHeight}) => {

    const [ err, setErr ] = useState(false);

    // if bool is true we try to add the email, if false we try to remove the email
    const onFromEmailChange = (value, bool) => {

        const newState = Object.assign({}, parser);

        let from_emails = [...parser.from_emails];

        if(bool) {
            if(!from_emails.includes(value)) {
                from_emails.push(value);
            }
        } else {
            from_emails = from_emails.filter(e => e !== value);
        }

        setParser({
            ...newState,
            from_emailsState: 'valid',
            from_emails,
            from_emails_text: ''
        })

    }

    //on keypress of enter simulate the form being submitted for better UI
	const _handleKeyDown = (e) => {
		if (e.key === 'Enter') {

            const value = parser.from_emails_text;

            if(!EmailValidator.validate(value)) {
                setErr(`${value} is not a valid email address.`)
                return setTimeout(() => setErr(false), 2000)
            }

            onFromEmailChange(parser.from_emails_text, true);

		}
	}

    return (

        <>


                <CardHeader className="text-uppercase">
                    <CardTitle className="mb-0 float-left">Email Identifiers</CardTitle>
                </CardHeader>
                
               <CardHeader>
               <FormGroup>
                    <label className="form-control-label">Email Parser Name*</label>
                    <Input 
                        type="textarea"
                        value={parser.name || ''}
                        onChange={(e) => onChange(e, 'name')}
                        valid={ parser.nameState === "valid" }
                        invalid={ parser.nameState === "invalid" }
                    />
                </FormGroup>
                <FormGroup>
                    <label className="form-control-label">Email Subject Includes*</label>
                    <Input 
                        type="textarea"
                        value={parser.subject_includes || ''}
                        onChange={(e) => onChange(e, 'subject_includes')}
                        valid={ parser.subject_includesState === "valid" }
                        invalid={ parser.subject_includesState === "invalid" }
                    />
                </FormGroup>

                <FormGroup>
                    <label className="form-control-label">Sent From Email(s)*</label>
                    {/* <p className="text-sm mb-2 mt--2 text-muted">Type and press enter to add an email address.</p> */}
                    <div className={parser.from_emails.length ? "my-3" : ''}>
                        {parser.from_emails.map(email => (
                            <Badge key={email} color="success" className="d-block mb-2 text-left ml-0">
                                {email} 
                                <i 
                                    className="fas fa-trash ml-2 text-danger cursor-pointer float-right" 
                                    onClick={() => onFromEmailChange(email, false)} 
                                />
                            </Badge>
                        ))}
                    </div>
                    <Input 
                        type="text"
                        value={parser.from_emails_text || ''}
                        onChange={(e) => onChange(e, 'from_emails_text')}
                        valid={ parser.from_emailsState === "valid" }
                        invalid={ parser.from_emailsState === "invalid" }
                        onKeyDown={_handleKeyDown}
                    />

                    {err ? <p className="text-sm font-weight-bold text-danger mb-0">{err}</p> : null}
                </FormGroup>
               </CardHeader>


        </>

    );
}

export default EmailParsersIdentifiersEmail
