const role_types = [

    'Attorney',
    // 'Attorney 2',
    // 'Attorney 3',
    // 'Attorney 4',

    // 'Paralegal',
    // 'Paralegal 2',
    // 'Paralegal 3',
    // 'Paralegal 4',

    'Onboarding CL',
    // 'Mini-Hydra CL',
    // 'Document Collector',

    // 'Judge',

]

export default role_types;