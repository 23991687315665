/*
Documentation

this page shows all users within the system

*/

import NotificationDelete from 'components/functional/notifications/Delete';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';

import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardTitle, Container } from "reactstrap";


const { SearchBar } = Search;

class Users extends React.Component {

    state = {
        toDelete: null
    }

    columns = [
        {
            dataField: "name",
            text: "Name",
            formatter: (cell, row) => (
                <Link to={`/core/documents/word/${row._id}`}>
                    {row.name}
                </Link>
            )
        },
        {
            dataField: "fields",
            text: "Custom Fields",
            formatter: (cell, row) => row.fields.length
        },
        {
            dataField: "x",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <Link to={`/core/documents/word/${row._id}`}><i className="fas fa-edit text-success mr-2"></i></Link>
                        <i onClick={() => this.setState({toDelete: row})} className="fas fa-trash text-danger"></i>
                    </div>
                )
            }
        },
    ]

    pagination = paginationFactory({
        page: 1,
        sizePerPage: 25,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: true,
        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        value={currSizePerPage}
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    render() {

        const template_word_docs = this.props.template_word_docs;
        const toDelete = this.state.toDelete;

        return (
            <>

            {toDelete && (
                <NotificationDelete
                    deletionURL={`/api/v1/core/template_word_docs/delete/${toDelete._id}`}
                    confirmBtnText="Delete Template"
                    title={`Delete ${toDelete.name}`}
                    text="Deleting this document prevent it from every being used again."
                    onClose={() => this.setState({toDelete: null})}
                    onSuccess={() => {} }
                />
            )}

            <Container fluid>

                <Helmet>
                    <title>Template Word Docs</title>
                    <meta name="description" content="Template Word Docs" />
                </Helmet>

                <DashHeaderOpen
                    title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
                    breadcrumb_1="Template Word Docs"
                    breadcrumb_2="All"
                    actionComponent={(
                        <Link to="/core/documents/word/new" className="btn btn-success"><i className="fas fa-plus mr-2 " />Add New</Link>
                    )}
                />

                <Card className="card-color card-primary">

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">All Template Word Docs</CardTitle>
                    </CardHeader>

                    {template_word_docs.length ? (

                        <ToolkitProvider
                            data={template_word_docs}
                            keyField="_id"
                            columns={this.columns}
                            search
                        >
                            {props => (
                                <>
                                 <div
                                    id="datatable-basic_filter"
                                    className="dataTables_filter px-4 pb pt-3 pb-2"
                                    style={{width: '100%'}}
                                >
                                    <label>
                                        Search:
                                        <SearchBar
                                            className="form-control-sm"
                                            placeholder="Name, Email, Phone"
                                            {...props.searchProps}
                                        />
                                    </label>
                                </div>
                                <div className=" table-responsive table-vertical-align">
                                    <BootstrapTable
                                        pagination={this.pagination}
                                        {...props.baseProps}
                                        bootstrap4={true}
                                        bordered={false}
                                    />
                                </div>

                                </>
                            )}
                        </ToolkitProvider>

                    ) : (

                        <CardBody>
                            <Link to="/core/documents/word/new">
                                No template word documents have been created yet.
                                <b className="text-warning"> Click here to create one.</b>
                            </Link>
                        </CardBody>

                    )}
                </Card>

            </Container>

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        template_word_docs: state.template_word_docs.template_word_docs
    };
};

export default connect(mapStateToProps, '')(Users);

