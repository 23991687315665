import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import Circle from 'components/markup/loading/Circle';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Container, FormGroup, Input } from "reactstrap";
import { toggleAlertBS } from 'store/functions/system/system';
import _divisions from '_functions/divisions';

const linkAllDivisions = '/core/divisions/all'

const DivisionsUpdate = ({match}) => {

    const [division, setDivision] = useState(null);
    const [redirect, setRedirect] = useState(null);
    const [name, setName] = useState('');
    const [active, setActive] = useState(null);
    const [sendingPhoneNumber, setSendingPhoneNumber] = useState('');

    const [err, setErr] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const onSave = async () => {

        if(!name) return setErr('The name field cannot be blank');

        setIsSaving(true);

        const updated = await _divisions.update(division._id, { 
            name, 
            active,
            sending_phone_number: sendingPhoneNumber,
        });

        if(updated.success) {
            toggleAlertBS(false, 'Division updated successfully');
            setRedirect(linkAllDivisions)
        } else {
            setIsSaving(false);
            if(updated.message) {
                setErr(updated.message[0])
            } else {
                toggleAlertBS(true, 'Something went wrong updating this division');
            }
        }

    }

    const fetchDivision = async () => {

        const foundDivision = await _divisions.findById(match.params.division_id);

        if(foundDivision.data) {
            setDivision(foundDivision.data);
            setName(foundDivision.data.name)
            setActive(foundDivision.data.active)
            setSendingPhoneNumber(foundDivision.data.sending_phone_number || '')
        } else {
            setRedirect(linkAllDivisions);
        }

    }

    useEffect(() => {
        fetchDivision()
    }, [])

    if(redirect) return <Redirect to={linkAllDivisions} />
    if(!division) return <Circle />

    return (

        <Container fluid>

            <Helmet>
                <title>{`Divisions`}</title>
                <meta name="description" content="Divisions" />
            </Helmet>

            <DashHeaderOpen
                title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
                breadcrumb_1="Divisions"
                breadcrumb_2="View"
                actionComponent={(
                    <Link to={linkAllDivisions} className="btn btn-outline-warning">
                        <i className="fas fa-arrow-left mr-2 " />Back
                    </Link>
                )}
            />

            {!active && (
                <div className="alert alert-warning">This division is not active and will not be able to be assigned to workflows, templates, etc.</div>
            )}

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">Division {division.name}</CardTitle>
                </CardHeader>

                <CardHeader>

                    <FormGroup>
                        <label className="form-control-label">* Division Name</label>
                        <Input 
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </FormGroup>

                    <FormGroup>
                        <label className="form-control-label">* Active Status</label>
                        <Input 
                            type="select"
                            value={active ? 'yes' : 'no'}
                            onChange={(e) => setActive(e.target.value === 'yes' ? true : false)}
                        >
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </Input>
                    </FormGroup>

                </CardHeader>

                <CardHeader>

                    <FormGroup>
                        <label className="form-control-label">Default Automation Phone Number</label>
                        <Input 
                            type="text"
                            value={sendingPhoneNumber}
                            onChange={(e) => setSendingPhoneNumber(e.target.value)}
                        />
                    </FormGroup>

                </CardHeader>

                {err && (
                    <CardBody className="bg-secondary">
                        <p className="text-sm mb-0 font-weight-bold text-danger">* {err}</p>
                    </CardBody>
                )}

                <CardFooter className="text-right">
                    <button disabled={isSaving} onClick={onSave} className="btn btn-success"><i className="fas fa-save mr-2 " /> Save</button>
                </CardFooter>

                
            </Card>

        </Container>

    )

}

export default DivisionsUpdate