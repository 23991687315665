import apiWorker from '../apiWorker'

const case_alert_templates = {

    create    : (data)      =>  apiWorker('post',  `/api/v1/core/case_alert_templates/create`, data),
    delete    : (_id, data) =>  apiWorker('delete',  `/api/v1/core/case_alert_templates/delete/${_id}`, data),
    find      : ()          =>  apiWorker('get',  `/api/v1/core/case_alert_templates/find`),

}

export default case_alert_templates;