/*
Documentation

This file is responsible for associating a questionnaire the appointment template
*/

import PropTypes from 'prop-types'
import React from "react";
import { Card, CardBody, CardHeader, CardTitle, Row, Col } from "reactstrap";

import QuestionnaireSearchInput from 'components/system/migrated/QuestionnaireSearchInput'

class TemplatesCrudQuestionnaireSelect extends React.Component {

    render() {

        const { template_appointment, onInputChange } = this.props
        const questionnaire = template_appointment.questionnaire

        return (

            <Card style={{boxShadow: 'none', borderLeft: 'none'}}>

                <CardHeader className="bg-secondary">
                    <CardTitle className="mb-0 float-left">Questionnaire Selection</CardTitle>
                </CardHeader>

                <CardBody>

                    <QuestionnaireSearchInput
                        value={questionnaire ? questionnaire : {}}
                        onSelect={(questionnaire) => onInputChange(questionnaire)}
                    />

                    {questionnaire && questionnaire._id ? (
                        <>
                            <p className="text-sm">The following questionnaire will be assigned to any appointments created when using this template:</p>

                            <Row>
                                <Col sm={10} className="align-self-center">
                                    {questionnaire.name}
                                </Col>
                                <Col sm={2} className="align-self-center text-right">
                                    <button className="btn btn-danger mb-3" onClick={() => onInputChange()}>
                                        <i className="fas fa-trash cursor-pointer" /> Remove
                                    </button>
                                </Col>
                            </Row>

                        </>
                    ) : null }

                </CardBody>

            </Card>

        );
    }
}

TemplatesCrudQuestionnaireSelect.propTypes = {
    template_appointment    : PropTypes.object.isRequired,
    onInputChange           : PropTypes.func.isRequired,
    onSave                  : PropTypes.func.isRequired,
}

export default TemplatesCrudQuestionnaireSelect
