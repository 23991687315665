/*
Documentation

This file allows a user to assign/remove missing documents to a workflow

*/

import PropTypes from 'prop-types'
import Circle from 'components/markup/loading/Circle';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardFooter, CardHeader, CardTitle, Col, Row } from "reactstrap";
import { toggleAlertBS } from 'store/functions/system/system';
import _missing_docs from '_functions/missing_docs';
import _workflows from '_functions/workflows';

class Documents extends Component {

    state = {
        workflow: this.props.workflow,
        missing_docs: null,
        missing_docs_on_workflow: this.props.workflow.missing_docs,
        missing_docs_not_on_workflow: [],
    }

    // this function takes all workflow docs and splits them as ones on the workflow and ones not
    splitMissingDocs = () => {

        let missing_docs                   = this.state.missing_docs
        let missing_docs_on_workflow       = this.state.missing_docs_on_workflow ? this.state.missing_docs_on_workflow : []
        let missing_docs_not_on_workflow   = []

        if(missing_docs && missing_docs.length) {

            missing_docs.forEach(t => {

                if(!missing_docs_on_workflow.find(tdow => tdow._id === t._id)) {
                    missing_docs_not_on_workflow.push(t)
                }

            })

        }

        return {
            missing_docs_not_on_workflow,
            missing_docs_on_workflow
        }

    }

    // add a workflow to list of workflows on the case
    onAdd = (missing_doc) => {

        const missing_docs_on_workflow = [...this.state.missing_docs_on_workflow]
        missing_docs_on_workflow.push(missing_doc)

        this.setState({missing_docs_on_workflow})

    }

    // remove a workflow to list of workflows on the case
    onRemove = (missing_doc_id) => {

        let missing_docs_on_workflow = [...this.state.missing_docs_on_workflow]
        missing_docs_on_workflow = missing_docs_on_workflow.filter(t => t._id !== missing_doc_id)

        this.setState({missing_docs_on_workflow})

    }

    onSave = async () => {

        const missing_docs = [];
        const data = {};

        this.state.missing_docs_on_workflow.forEach(t => missing_docs.push(t._id))
        data.missing_docs = missing_docs

        const updated = await _workflows.update(this.props.workflow._id, {...data, type: this.props.workflow.type})


        if(updated.success) {
            toggleAlertBS(false, 'Workflow Updated Successfully.')
        } else {
            toggleAlertBS(true, 'An error occurred updating this workflow.')
        }

    }

    //when we mount fetch all template docs
    componentDidMount = async () => {

        const missing_docs = await _missing_docs.find(`?sort=name__asc`)

        if(missing_docs.success) {
            this.setState({missing_docs: missing_docs.data})
        }

    }

    render() {

        const {  missing_docs  } = this.state
        const { missing_docs_on_workflow, missing_docs_not_on_workflow } = this.splitMissingDocs()

        return (

            <Card className="card-color card-warning">

                <CardHeader>
                    <CardTitle className="mb-0">Missing Docs For Workflow</CardTitle>
                </CardHeader>

                {missing_docs ? (

                    missing_docs_on_workflow.length ?  (
                        <ul className="pl-0 hover-click mb-0">

                            {missing_docs_on_workflow.map(t => (
                                <li
                                    key={t._id}
                                    className="px-3 py-2 list-style-none"
                                    style={{borderBottom: 'solid 1px #eee'}}
                                    onClick={() => this.onRemove(t._id)}
                                >
                                    <Row>
                                        <Col xs={10} onClick={() => this.setState({missing_doc_to_edit: t})}>
                                            <i className="pl-1 fas fa-file-upload mr-2 text-success" />
                                            {t.name}
                                        </Col>
                                        <Col xs={2} className="text-right">
                                            <i className="fas fa-trash text-danger" />
                                        </Col>
                                    </Row>
                                </li>
                            ))}

                        </ul>
                    ) : (
                        <p className="px-3 py-2 text-sm mb-0">No missing documents have been added to this workflow.</p>
                    )

                ) : (
                    <Circle />
                )}

                <CardHeader>
                    <CardTitle className="mb-0">All Missing Docs</CardTitle>
                </CardHeader>

                {missing_docs ? (

                    missing_docs_not_on_workflow.length ?  (
                        <ul className="pl-0 hover-click mb-0">

                            {missing_docs_not_on_workflow.map(t => (
                                <li
                                    key={t._id}
                                    className="px-3 py-2 list-style-none"
                                    style={{borderBottom: 'solid 1px #eee'}}
                                    onClick={() => this.onAdd(t)}
                                >
                                    <Row>
                                        <Col xs={10} onClick={() => this.setState({missing_doc_to_edit: t})}>
                                            <i className="pl-1 fas fa-file-upload mr-2 text-warning" />
                                            {t.name}
                                        </Col>
                                        <Col xs={2} className="text-right">
                                            <i className="fas fa-plus text-success" />
                                        </Col>
                                    </Row>
                                </li>
                            ))}

                        </ul>
                    ) : (
                        <p className="px-3 py-2 text-sm mb-0">No Missing Docs are able to be assigned to this workflow.</p>
                    )

                ) : (
                    <Circle />
                )}


                <CardFooter className="text-right">
                    <button className="btn btn-success" onClick={this.onSave}>Save Missing Docs </button>
                </CardFooter>

            </Card>

        )

    }

}

const mapStateToProps = state => {
    return {
        missing_docs: state.missing_docs,
    };
};

Documents.propTypes = {
    workflow: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, '')(Documents);
