import PropTypes from 'prop-types';
import React, { Component } from 'react';

class AutoEmail extends Component {

    render() {

        const { task, selected_task, toggleSidebar } = this.props;
        const title = task.template_email ? task.template_email.name : <span className="text-danger"><i className="fas fa-exclamation-triangle mr-2" /> Email Template Not Set</span>

        return (

            <div className={selected_task === task._id ? "selected auto" : "auto"}>
                <div className="wrapper" onClick={() => toggleSidebar(true, task._id)}>
                    <div className="title">
                        <b><i className="fas fa-envelope mr-3 text-info-original" /> Send Email: </b>
                        {title}
                    </div>
                </div>
            </div>

        )

    }

}

AutoEmail.propTypes = {
    task            : PropTypes.object.isRequired,
    toggleSidebar   : PropTypes.func.isRequired,
    selected_task   : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default AutoEmail;
