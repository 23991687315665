import PropTypes from 'prop-types';
import React from "react";
import { SketchPicker } from 'react-color';
import { Card, CardBody, CardFooter, CardHeader, CardTitle } from "reactstrap";
import { toggleAlertBS } from 'store/functions/system/system';
import _users from '_functions/users';

class General extends React.Component {

    state = {
        error: null
    }

    onUpdateColor = async () => {

        const color = this.props.color

        const updated = await _users.update(this.props.user_id,  { color })

        if(updated.success) {
            toggleAlertBS(false, 'User color updated successfully.')
        }

    }

    render() {

        const { color, onColorChange } = this.props;

        return (
            <>

               <Card style={{background: color}}>

                    <CardHeader >
                        <CardTitle className="mb-0">User Color</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <SketchPicker
                            style={{width: '100%'}}
                            color={ color }
                            onChangeComplete={ onColorChange }
                        />
                   </CardBody>

                    <CardFooter className="text-right">
                        <button className="btn btn-success"  onClick={this.onUpdateColor}>Update User Color</button>
                    </CardFooter>

               </Card>

            </>
        );
    }
}

General.propTypes = {
    user_id: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    onColorChange: PropTypes.func.isRequired,
}

export default General
