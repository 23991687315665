// findUnmovedStepsByAppointments

import Dots from 'components/markup/loading/Dots';
import moment from 'moment';
import React, { Component } from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Card, CardBody, CardHeader, CardTitle, Container, FormGroup, Input, Row, Col } from 'reactstrap';
import renderName from 'utils/renderName';
import _analytics from '_functions/analytics';
import { toggleStandardLoader } from 'store/functions/system/system'
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import ImageRender from 'components/functional/images/ImageRenderer'
import keys from 'keys';


class HiredToday extends Component {


    state = {
        data: null,
        start: moment().format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
        loading: true
    };


    columns = [
        {
            dataField: "contact",
            text: "Contact",
            filterValue: (cell, row) => renderName(row.contact),
            formatter: (cell, row) => {

                const contact = row.case && row.case.contact ? row.case.contact : null;

                if(!contact) {
                    return 'Contact Not Found'
                }

                return (
                    <a href={`${keys.APP_URL}/dashboard/contacts/view/${contact._id}`} target="_blank" rel="noopener noreferrer">
                        <div className="text-capitalize font-weight-bold text-dark">{renderName(contact)}</div>
                        {contact.phone ? <div>{}</div> : null}
                        {contact.email ? <div>{contact.email}</div> : null}
                    </a>
                )

            }

        },
        {
            dataField: "case",
            text: "Case Name",
            formatter: (cell, row) => {

                const _case = row.case ? row.case : null;
                
                if(!_case) return 'Case Not Found';

                return (
                    <a href={`${keys.APP_URL}/dashboard/cases/view/${_case._id}?nav=3&doc=${row.document}`} target="_blank" rel="noopener noreferrer">
                        <div className="text-capitalize font-weight-bold text-dark">{_case.name}</div>
                    </a>
                )

            }

        },
        {
            dataField: "name",
            text: "Doc Name",
            formatter: (cell, row) => {
                
                if(!row.document_signed) return '-';

                return (
                    <ImageRender 
                        doc={row.document_signed}
                    />
                )


            }

        },
        {
            dataField: "status",
            text: "Status",
            formatter: (cell, row) => <span className="text-capitalize">{row.status}</span>
        },
        {
            dataField: "finalized_on",
            text: "Finalize On",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => (
                <div className="text-right">
                    {moment.unix(row.finalized_on).format('MM/DD/YYYY h:mm A')}
                </div>
            )
        },

    ]

    pagination = paginationFactory({
        page: 1,
        sizePerPage: 25,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: true,
        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        value={currSizePerPage}
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    onQuery = async () => {

        const { start, end } = this.state;
        if(!start || !end) return;

        const startDate = moment(start).startOf('day').format('X');
        const endDate = moment(end).endOf('day').format('X');

        toggleStandardLoader(true);

        const data = await _analytics.documents.signed(`?start=${startDate}&end=${endDate}`);

        toggleStandardLoader(false);

        if(data.data) {
            this.setState({data: data.data, loading: false, noData: false})
        } else {
            this.setState({noData: start, data: null})
        }

    }

    componentDidMount = async () => {

        const startDate = moment().startOf('day').format('X');
        const endDate = moment().endOf('day').format('X');

        const data = await _analytics.documents.signed(`?start=${startDate}&end=${endDate}`);

        if(data.data) this.setState({data: data.data, loading: false})

    }

    render() {

        const { data, loading, start, end } = this.state

        if(loading) return (
            <div className="py-6 text-center"><h2>Loading Documents Signed Today<Dots /></h2></div>
        )

        return (

            <>
            <Container fluid>

                <DashHeaderOpen
                    title={<span><i className="fas fa-headset mr-2 text-success " />Analytics</span>}
                    breadcrumb_1="Documents"
                    breadcrumb_2="Signed"
                />

                <Card>

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">Select Time Frame</CardTitle>
                    </CardHeader>

                    <CardHeader>

                        <Row>

                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Start</label>
                                    <Input 
                                        type="date"
                                        value={start || ''}
                                        onChange={(e) => this.setState({start: e.target.value})}
                                    />
                                </FormGroup>
                            </Col>

                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">End</label>
                                    <Input 
                                        type="date"
                                        value={end || ''}
                                        onChange={(e) => this.setState({end: e.target.value})}
                                    />
                                </FormGroup>
                            </Col>

                        </Row>

                      
                        <hr />
                        <div className="text-right">
                            <button className="btn btn-success" onClick={this.onQuery}>Get Stats</button>
                        </div>
                    </CardHeader>

                </Card>


                <Card>

                    <CardHeader>
                            <Row>
                                
                                <Col lg={6}>
                                    <CardTitle className="mb-0 float-left">Signed In Range</CardTitle>

                                </Col>

                                <Col lg={6} className="text-right">
                                    <b className="text-warning">{moment(start).format('MM/DD/YYYY')} -  {moment(end).format('MM/DD/YYYY')}</b>
                                </Col>
                                
                            </Row>
                    </CardHeader>

                    {data.length ? (

                        <ToolkitProvider
                            data={data}
                            keyField="_id"
                            columns={this.columns}
                            search
                        >
                            {props => (
                                <div className=" table-responsive table-vertical-align" style={{overflow: 'visible'}}>
                                    <BootstrapTable
                                        pagination={this.pagination}
                                        {...props.baseProps}
                                        bootstrap4={true}
                                        bordered={false}
                                    />
                                </div>
                            )}
                        </ToolkitProvider>

                    ) : (

                        <CardBody>
                            There is no data to show.
                        </CardBody>

                    )}
                </Card>

            </Container>
            </>

        )

    }

}



export default HiredToday;