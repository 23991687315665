/*
Documentation

this page shows an actual automation and lets it be
updated with the tree of assigned actions

*/


import React from 'react';
import { connect } from 'react-redux';

import { Redirect } from 'react-router-dom'
import Circle from 'components/markup/loading/Circle';

import AutoMain from './components/AutoMain';
import AutoSidebar from './components/AutoSidebar/index';
import AutoFooter from './components/AutoFooter';

import AutoAppointment from './components/AutoTypes/AutoAppointment';
import AutoEmail from './components/AutoTypes/AutoEmail';
import AutoText from './components/AutoTypes/AutoText';
import AutoWait from './components/AutoTypes/AutoWait';
import AutoNew from './components/AutoTypes/AutoNew';
import AutoStart from './components/AutoTypes/AutoStart';
import AutoEnd from './components/AutoTypes/AutoEnd';

import { toggleAlertBS } from 'store/functions/system/system'
import _automations from '_functions/automations'

class AutomationsView extends React.Component {

    state = {
        automation: null,
        showSidebar: false,
        selected_task: null,
        removed_tasks: [],
        automation_tasks: []
    }

    renderMap = (task) => {

        const selected_task = this.state.selected_task

        if(task.type === 'email') {

            return (
                <AutoEmail
                    toggleSidebar={this.toggleSidebar}
                    selected_task={selected_task}
                    key={task._id}
                    task={task}
                />
            )

        } else if(task.type === 'text') {

            return (
                <AutoText
                    toggleSidebar={this.toggleSidebar}
                    selected_task={selected_task}
                    key={task._id}
                    task={task}
                />
            )

        } else if(task.type === 'appointment') {

            return (
                <AutoAppointment
                    toggleSidebar={this.toggleSidebar}
                    selected_task={selected_task}
                    key={task._id}
                    task={task}
                />
            )

        } else if(task.type === 'wait') {

           const wait_time = this.getWaitTime(task)

            return (
                <AutoWait
                    toggleSidebar={this.toggleSidebar}
                    selected_task={selected_task}
                    key={task._id}
                    wait_time={wait_time}
                    task={task}
                    order={task.order}
                    onAddTask={this.onAddTask}
                />
            )

        }  else if(task.type === 'start') {

            return (
                <AutoStart
                    toggleSidebar={this.toggleSidebar}
                    selected_task={selected_task}
                    key={task._id}
                    task={task}
                    order={task.order}
                    onAddTask={this.onAddTask}
                />
            )

        } else if(task.type === 'end') {

            return (
                <AutoEnd
                    toggleSidebar={this.toggleSidebar}
                    selected_task={selected_task}
                    key={task._id}
                    task={task}
                    order={task.order}
                    onAddTask={this.onAddTask}
                />
            )

        } else {

            return (
                <AutoNew
                    toggleSidebar={this.toggleSidebar}
                    selected_task={selected_task}
                    task={task}
                    key={task._id}
                />
            )

        }

    }

    toggleSidebar = (showSidebar, selected_task) => {

        let shouldShowSidebar = showSidebar ? showSidebar : !this.state.showSidebar

        
        if(shouldShowSidebar) {            
            document.body.classList.add('noScroll')
        } else {
            document.body.classList.remove('noScroll')
        }

        this.setState({showSidebar: shouldShowSidebar, selected_task})

    }

    getWaitTime = (task) => {

        const time = task.wait_time;

        if(!time) return <span className="text-danger"><i className="fas fa-exclamation-triangle mr-2" /> Wait Time Not Set</span>

        let days = Math.floor(time / 86400)
        let hours = Math.floor((time - (days * 86400)) / 3600)
        let minutes = Math.floor((time - (days * 86400) - (hours * 3600)) / 60)

        return `${days} Days - ${hours} Hours - ${minutes} Minutes`

    }

    setTask = (task, closeSidebar) => {

        let tasks = [...this.state.automation_tasks];

        if(task.template_email) {
            let email = this.props.template_emails.find(e => e._id === task.template_email)
            if(email) task.template_email = email
        }

        if(task.template_appointment) {
            let appointment = this.props.template_appointments.find(e => e._id === task.template_appointment)
            if(appointment) task.template_appointment = appointment
        }

        if(task.template_text) {
            let text = this.props.template_texts.find(e => e._id === task.template_text)
            if(text) task.template_text = text
        }

        if(task.automation_start) {
            let automation = this.props.automations.find(e => e._id === task.automation_start)
            if(automation) task.automation_start = automation
        }

        if(task.automation_end) {
            let automation = this.props.automations.find(e => e._id === task.automation_end)
            if(automation) task.automation_end = automation
        }

        tasks[tasks.findIndex(t => t._id === task._id)] = task


        if(closeSidebar) this.toggleSidebar(false)

        this.setState({automation_tasks: tasks})

    }

    onAddTask = (order) => {

        const automation_tasks = [...this.state.automation_tasks]

        // increment the order
        automation_tasks.forEach(task => {
            if(task.order >= order) {
                task.order = task.order + 1
            }
        })

        // set a fake timestamp id and push to tasks
        const _id = Math.floor(new Date() / 1);

        const foundDivision = this.props.divisions.find(d => d._id === this.state.automation.division)

        automation_tasks.push({ 
            _id, 
            order, 
            type: 'email',
            reply_to_email_address: foundDivision && foundDivision.reply_to_email_address ? foundDivision.reply_to_email_address : '',
            sending_email_address: foundDivision && foundDivision.sending_email_address ? foundDivision.sending_email_address : '',
            sending_phone_number: foundDivision && foundDivision.sending_phone_number ? foundDivision.sending_phone_number : '',
        })

        automation_tasks.sort((a, b) => a.order < b.order ? -1 : 1)

        this.setState({automation_tasks}, () => {
            this.toggleSidebar(true, _id)

        })

    }

    onRemoveTask = (order) => {

        let automation_tasks = [...this.state.automation_tasks]
        let removed_tasks = [...this.state.removed_tasks]

        automation_tasks = automation_tasks.filter(task => {
            const task_to_remove = task.order !== order

            if(!task_to_remove) {

                // for some reason a mongo id if parsed is an integer equals 5. if we have the id take it out of the automations
                if(!Number.isInteger(task._id)) removed_tasks.push(task._id)

            }

            return task_to_remove
        })

        automation_tasks.forEach(task => {
            if(task.order >= order) task.order = task.order - 1
        })

        automation_tasks.sort((a, b) => a.order < b.order ? -1 : 1)

        this.toggleSidebar(false)
        this.setState({automation_tasks, removed_tasks})

    }

    // if saving an automation, all contacts in their automations_assigned will move back to step 1
    onSave = async () => {

        const { automation } = Object.assign({}, this.state)
        let automation_tasks = [...this.state.automation_tasks]

        const { removed_tasks } = this.state;

        if(!automation.name) {
            return this.setState({automation: {...automation, nameState: 'invalid'}})
        } else {
            this.setState({automation: {...automation, nameState: 'valid'}})
        }


        if(!automation.division || automation.division === 'false') {
            return this.setState({automation: {...automation, divisionState: 'invalid'}})
        } else {
            this.setState({automation: {...automation, divisionState: 'valid'}})
        }

        let automation_save;

        if(automation._id) {
            automation_save = await _automations.update(automation._id, automation)
        } else {
            automation_save = await _automations.create(automation)
        }

        if(automation_save.success && automation_save.data) {

            const automation_id = automation_save.data._id

            removed_tasks.forEach(async task => {
                _automations.tasks.delete(task)
            })

            if(automation_tasks && automation_tasks.length ) {

                let counter = 0

                automation_tasks.forEach(async task => {

                    this.state.removed_tasks.includes(task._id)

                    // for some reason a mongo id if parsed is an integer equals 5. if we have the id we are updating it
                    if(Number.isInteger(task._id)) {

                        _automations.tasks.create({
                            ...task,
                            _id: undefined,
                            automation              : automation_id,
                            template_appointment    : task.template_appointment     ? task.template_appointment._id : undefined,
                            template_email          : task.template_email           ? task.template_email._id : undefined,
                            template_text           : task.template_text            ? task.template_text._id : undefined,
                            automation_start        : task.automation_start         ? task.automation_start._id : undefined,
                            automation_end          : task.automation_end           ? task.automation_end._id : undefined,
                            email_sender            : task.email_sender             ? task.email_sender : undefined,
                            sending_phone_number    : task.sending_phone_number     ? task.sending_phone_number : undefined,
                        })

                    } else {

                        _automations.tasks.update(task._id, {
                            ...task,
                            template_appointment    : task.template_appointment     ? task.template_appointment._id : undefined,
                            template_email          : task.template_email           ? task.template_email._id : undefined,
                            template_text           : task.template_text            ? task.template_text._id : undefined,
                            automation_start        : task.automation_start         ? task.automation_start._id : undefined,
                            automation_end          : task.automation_end           ? task.automation_end._id : undefined,
                            email_sender            : task.email_sender             ? task.email_sender : undefined,
                            sending_phone_number    : task.sending_phone_number     ? task.sending_phone_number : undefined,
                        })                        

                    }

                    counter++

                    if(counter === automation_tasks.length) {
                        _automations.assigned.reset(automation_id)
                    }

                })

            }

            toggleAlertBS(false, 'Automation updated successfully')

        }

    }

    componentDidMount = async () => {

        const automation_id = this.props.match.params._id;

        if(automation_id === 'new') {
            return this.setState({automation: {}})
        }

        const foundAutomation = this.props.automations.find(a => a._id === automation_id);
        if(!foundAutomation) return this.setState({shouldRedirect: '/automations/automations'});

        const automation_tasks = await _automations.tasks.all(`?filter=automation__${automation_id}&sort=order__asc`);

        this.setState({
            automation: foundAutomation, 
            automation_tasks: automation_tasks.data ? automation_tasks.data: [] 
        })

    }

    render() {

        const { automation_tasks, showSidebar, selected_task, automation } = this.state
        if(!automation_tasks || !automation) return <div className="py-6"><Circle/></div>

        if(this.state.shouldRedirect) return <Redirect to={this.state.shouldRedirect} />

        return (
            <div className="automations-layout">

                <AutoMain
                    onAddTask={this.onAddTask}
                    automation_tasks={automation_tasks}
                    showSidebar={showSidebar}
                    automation={automation}
                >

                    {automation_tasks && automation_tasks.length ? (
                        automation_tasks.map(task => this.renderMap(task))
                    ) : null}

                    <AutoSidebar
                        showSidebar={showSidebar}
                        toggleSidebar={this.toggleSidebar}
                        automation_tasks={automation_tasks}
                        selected_task={selected_task}
                        setTask={this.setTask}
                        automation_id={this.props.match.params._id}
                        onRemoveTask={this.onRemoveTask}
                        automation={automation}
                    />

                </AutoMain>

                <AutoFooter
                    automation_tasks={automation_tasks}
                    automation={automation}
                    onNameChange={(name) => this.setState({automation: {...Object.assign({}, this.state.automation), name}})}
                    onDivisionChange={(division) => this.setState({automation: {...Object.assign({}, this.state.automation), division}})}
                    onSave={this.onSave}
                />

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        template_appointments: state.template_appointments.template_appointments,
        template_texts: state.template_texts.template_texts,
        template_emails: state.template_emails.template_emails,
        automations: state.automations.automations,
        divisions: state.divisions.divisions,
    };
};

export default connect(mapStateToProps, '')(AutomationsView);
