/*
Documentation

This page shows all appointment templates within the system
Appointment templates are stored in redux so no db call is needed here

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, Container } from "reactstrap";
import { toggleAlertBS } from 'store/functions/system/system';

import admin_func from '_functions/admin_func';


const Admin = () => {

    const sendEmail = async () => {
        const sent = await admin_func.documentEmail()
        if(sent.success) return toggleAlertBS(false, 'Document Email Queued For Processing')
        toggleAlertBS(true, 'Document email failed to send')
    }
    
    const sendScottNumbers = async () => {
        const sent = await admin_func.scottEmail()
        if(sent.success) return toggleAlertBS(false, 'CSV queued to email to scott')
        toggleAlertBS(true, 'Scotts numbers email failed to queue correctly')
    }

    return (

        <Container fluid>

        <Helmet>
            <title>Admin</title>
            <meta name="description" content="Appointment Templates" />
        </Helmet>

        <DashHeaderOpen
            title={<span><i className="fas fa-database mr-2 text-success " /> Admin</span>}
            breadcrumb_1="Appointment Templates"
            breadcrumb_2="All"
        />

        <Card>

            <CardHeader>
                <CardTitle className="mb-0">Actions</CardTitle>
            </CardHeader>

            <CardBody>

                <button onClick={sendEmail} className='btn btn-success'>Send Document Email</button>
                <button onClick={sendScottNumbers} className='btn btn-success'>Send Numbers To Scott</button>

            </CardBody>


        </Card>

    </Container>

    )
}

const mapStateToProps = state => {
    return {
        divisions: state.divisions.divisions,
        template_appointments: state.template_appointments
    };
};

export default connect(mapStateToProps, '')(Admin);
