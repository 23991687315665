import ReactSelect from 'components/functional/inputs/ReactSelect';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

class AutoSidebarTypeEmail extends Component {

    state = {
        task: {}
    };

    componentDidMount = () => {

        const { automations } = this.props
        const options = []

        automations.forEach(email => {
            options.push({value: email._id, label: email.name})
        })

        this.setState({options})

    }

    render() {

        const { task, onChange, automations } = this.props
        const { options } = this.state

        if(task.type !== 'start' && task.type !== 'end') return null

        const type = task.type === 'start' ? 'automation_start' : 'automation_end';

        const foundAutomation = automations.find(e => e._id === task[type])

        return (

            <li>

                <ReactSelect
                    value={foundAutomation ? {value: foundAutomation._id, label: foundAutomation.name} : {}}
                    onChange={values => onChange('task', type, values.value)}
                    options={options}
                />

            </li>

        )

    }

}

AutoSidebarTypeEmail.propTypes = {
    task    : PropTypes.object.isRequired,
    onChange         : PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        automations: state.automations.automations,
    };
};

export default connect(mapStateToProps, '')(AutoSidebarTypeEmail);
