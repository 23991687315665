import apiWorker from '../apiWorker'
import fileDownloader from 'js-file-download';
import Axios from 'axios';
import keys from 'keys';

import { toggleAlertBS } from 'store/functions/system/system';

const analytics = {

    leadSourceValue: (query) =>  apiWorker('get', `/api/v1/analytics/bySource`),
    monitor: (query) =>  apiWorker('get', `/api/v1/analytics/monitor`),
    monitorEmails: (query) =>  apiWorker('get', `/api/v1/analytics/monitor/emails`),
    monitorClientLogins: (query) =>  apiWorker('get', `/api/v1/analytics/monitor/client_logins`),
    user: (query) =>  apiWorker('get', `/api/v1/analytics/monitor/user`),
    queryCalls: (data) =>  apiWorker('post', `/api/v1/analytics/query/calls`, data),

    master: {
        getReport: (query) =>  apiWorker('get', `/api/v1/analytics/master${query}`),
    },

    analytic_alerts: {
        find: (query) =>  apiWorker('get', `/api/v1/analytics/analytic_alerts/find${query}`),
    },

    cases: {
        findUnmovedStepsByAppointments    : (data) =>  apiWorker('post', `/api/v1/analytics/custom_reports/cases/moved`, data),
        syncCaseAppointments              : (data) =>  apiWorker('post', `/api/v1/analytics/custom_reports/cases/sync_case_appointments`, data),
        deleteSyncCaseAppointments        : (_id) =>  apiWorker('delete', `/api/v1/analytics/custom_reports/cases/sync_case_appointments/${_id}`),
        syncCaseStepTimes                 : (data) =>  apiWorker('post', `/api/v1/analytics/custom_reports/cases/sync_case_step_times`, data),
        deleteSyncCaseStepTimes           : (_id) =>  apiWorker('delete', `/api/v1/analytics/custom_reports/cases/sync_case_step_times/${_id}`),
        byStep                            : (data) =>  apiWorker('post', `/api/v1/analytics/custom_reports/cases/byStep`, data),
        stepOverview                      : (data) =>  apiWorker('post', `/api/v1/analytics/custom_reports/cases/stepOverview`, data),
        text                              : (data) =>  apiWorker('post', `/api/v1/analytics/custom_reports/cases/byStep/text`, data),
        email                             : (data) =>  apiWorker('post', `/api/v1/analytics/custom_reports/cases/byStep/email`, data),
        filingTimes                       : (data) =>  apiWorker('post', `/api/v1/analytics/custom_reports/cases/filingTimes`, data),
        overview                          : (data) =>  apiWorker('post', `/api/v1/analytics/custom_reports/overview`, data),
    },

    contacts: {
        hired: (query) =>  apiWorker('get', `/api/v1/analytics/custom_reports/contacts/hired${query}`),
    },
    
    documents: {
        signed: (query) =>  apiWorker('get', `/api/v1/analytics/custom_reports/documents/template_docs_sent/signed${query}`),
    },
    
    users: {
        statsToday: (data) =>  apiWorker('post', `/api/v1/analytics/custom_reports/users/stats_today`, data),
        allStatsToday: (data) =>  apiWorker('post', `/api/v1/analytics/custom_reports/users/all_stats_today`, data),
        callLogs: (user_id) =>  apiWorker('post', `/api/v1/analytics/custom_reports/users/call_logs/${user_id}`),
        allStats: () =>  apiWorker('get', `/api/v1/analytics/custom_reports/user_status`),
    },

    lead_source_entries: {
        findBySource: (lead_source_id, query) =>  apiWorker('get', `/api/v1/analytics/lead_source_entries/source/${lead_source_id}${query ? query : '?'}`),
    },

    call_center: {
        callLogsIncoming: (query) =>  apiWorker('get', `/api/v1/analytics/custom_reports/call_center/call_logs_incoming${query}`, null, true),
        callLogsOutgoing: (query) =>  apiWorker('get', `/api/v1/analytics/custom_reports/call_center/call_logs_outgoing${query}`, null, true),
    },

    events: {
        // always hide errors on analytic events
        create: (data)      =>  apiWorker('post', `/api/v1/analytics/analytic_events/create`, data, true),
        find: (query)       =>  apiWorker('get', `/api/v1/analytics/analytic_events/find${query}`,),
        findUser: (query)   =>  apiWorker('get', `/api/v1/analytics/analytic_events/find_user${query}`,),
        days30: (query)   =>  apiWorker('get', `/api/v1/analytics/analytic_events/days30${query}`,),
    },

    hired_logs: {
        search: (query) =>  apiWorker('get', `/api/v1/analytics/hired_logs/search${query}`, null, true),
    },
    
    step_logs: {
        search: (query) =>  apiWorker('get', `/api/v1/analytics/step_logs/search${query}`, null, true),
    },

    appointments: {
        breakdown: (query) =>  apiWorker('get', `/api/v1/breakdown/custom_reports/appointments/breakdown${query}`,),
        byStep: (query) =>  apiWorker('get', `/api/v1/analytics/custom_reports/appointments/by_step${query}`,),
    },

    /////
    query: {
        consultations: (data) =>  apiWorker('post', `/api/v1/analytics/query/consultations`, data, true),
        leads: (data) =>  apiWorker('post', `/api/v1/analytics/query/leads`, data, true),
        contacts: (data) =>  apiWorker('post', `/api/v1/analytics/query/contacts`, data, true),
        cases: (data) =>  apiWorker('post', `/api/v1/analytics/query/cases`, data, true),
        templateDocsSent: (data) =>  apiWorker('post', `/api/v1/analytics/query/template_docs_sent`, data, true),
        // caseReports: (data) =>  apiWorker('post', `/api/v1/analytics/query/cases/reports`, data, true),
        appointments: (data) =>  apiWorker('post', `/api/v1/analytics/query/appointments`, data, true),
        callsUnder90: (data) =>  apiWorker('post', `/api/v1/analytics/query/callsUnder90`, data, true),
        finishedAppointments: (data) =>  apiWorker('post', `/api/v1/analytics/query/finished_appointments`, data, true),
        leadSourceEntries: (data) =>  apiWorker('post', `/api/v1/analytics/query/leadSourceEntries`, data, true),

        caseReports : async (data)  => {
            try {
    
                toggleAlertBS('info', `Your download has started and should show up shortly.`)

                const result = await Axios({
                    method: 'post',
                    url: keys.API_URL + `/api/v1/analytics/query/cases/reports`,
                    responseType:'arraybuffer',
                    headers: {
                        authorization: `Bearer ${keys.SYSTEM_API_KEY}`
                    },
                    withCredentials: true,
                    data
                })
                fileDownloader(result.data, 'Report.pdf') 
            
            } catch(e) {
            
                toggleAlertBS(true, `Please try again.`)
            
            }
        }
    }

}

export default analytics;