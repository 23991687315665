/*
Documentation

this file will render a table of all users on a call
it also gives whoever is viewing the page the ability
to listen in on the call or merge in as a coach only heard
by the staff member

*/

import PropTypes from 'prop-types';
import moment from 'moment';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { connect } from 'react-redux';

import { Card, CardHeader,  CardTitle, Row, Col, UncontrolledTooltip, CardBody } from "reactstrap";
import AvatarImage from 'components/functional/images/AvatarImage';
import { formatPhone } from 'utils/text'

import renderName from 'utils/renderName'
import { architeckCall } from 'database';
import { toggleAlertBS } from 'store/functions/system/system';

import * as privileges          from '_functions/users/privileges'
import keys from 'keys';

import A from 'components/markup/links/A';
class CallCenterAdminOnCall extends React.Component {

    state = {
        users: null,
        startOfToday: moment.unix(Math.floor(new Date() / 1000)).startOf('day').valueOf() / 1000,
    }

    columns = [
        {
            dataField: "given_name",
            text: "Call Data",
            filterValue: (cell, row) => row.given_name + ' ' + row.family_name,
            formatter: (cell, row) => (
                    <Row>
                        <Col style={{maxWidth: 60, paddingLeft: 0, paddingRight: 0}} className="text-center align-self-center">
                            <AvatarImage style={{width: 45, height: 45}} src={row.avatar} className="mr-2 ml--3 z-depth-1" />
                            <div className="text-center mt-3 ml--3">
                                {row.call_contact ? (
                                    <> 
                                    <i
                                        id={`tooltip-coach-${row._id}`}
                                        onClick={() => this.mergeAsCoach(row)}
                                        className="cursor-pointer fas fa-user-secret text-warning "
                                    />
                                    <UncontrolledTooltip delay={0} placement="top" target={`tooltip-coach-${row._id}`}>
                                        Join this call as a coach, only the staff member can hear you.
                                    </UncontrolledTooltip>

                                    <i
                                        id={`tooltip-muted-${row._id}`}
                                        onClick={() => this.mergeAsMuted(row.call_contact._id)}
                                        className="cursor-pointer fas fa-microphone-slash text-danger ml-3"
                                    />
                                    <UncontrolledTooltip delay={0} placement="top" target={`tooltip-muted-${row._id}`}>
                                        Audit this call, no one will be able to hear you.
                                    </UncontrolledTooltip>

                                    </>
                                ) : null}
                            </div>
                        </Col>
                        <Col className="col-auto align-self-center" style={{borderLeft: 'solid 1px #eee'}}>

                            <div className="text-capitalize font-weight-bold">

                                {privileges.canModerateStaff() ? (
                                    <A  to={`${keys.APP_URL}/dashboard/users/view/${row._id}`} >
                                        {renderName(row)}
                                    </A>
                                ) : (
                                    renderName(row)
                                )}
                              
                            </div>

                            <div className="text-capitalize">
                                <i className="fas fa-user mr-2 text-success" />
                                {row.call_contact ? renderName(row.call_contact) : '-'}
                                {row.call_contact && row.call_contact.phone ? <span> - {formatPhone(row.call_contact.phone)}</span> : 'null'}
                            </div>

                            {row.call_appointment ? (
                                <div>
                                    <i className="fas fa-calendar mr-2 text-warning" />

                                    {row.call_appointment ? (
                                        <span className="text-capitalize">
                                            {row.call_appointment.name}{' '}
                                            {row.call_appointment.in_person ? '(Office)' : '(Phone)'}
                                        </span>
                                    ) : '-'}
                                </div>
                            ) : null}

                            <div><i className="fas fa-clock mr-2 text-danger" /> {this.renderDate(row.call_start)}</div>

                        </Col>
                    </Row>
                
            )
        },

    ]

    renderDate = (date) => {

        if(!date) return '-'

        if(date > this.state.startOfToday) {
            return moment.unix(date).format('h:mm A')
        } else {
            return moment.unix(date).format('MM/DD/YYYY - h:mm A')
        }

    }

    // TODO: this should look either for a phone number or for browser dialing
    mergeAsCoach = async (user) => {

        toggleAlertBS('info', `Merging you in as a coach for ${renderName(user)}`)

        architeckCall({
            shouldNotLogAndShowError: true,
            method: 'post',
            url: '/api/v1/call_center/actions/calls/create',
            data: {
                user: this.props.viewing_user._id,
                contact: user.call_contact._id,
                isTransfer: true,
                isCoaching: user.call_phone_default === 'browser' ? user._id : user.phone,
                isAdminAudit: true
            }

        })

    }

    mergeAsMuted = async (contact_id) => {

        toggleAlertBS('info', 'Merging you in as an auditor.')

        architeckCall({
            shouldNotLogAndShowError: true,
            method: 'post',
            url: '/api/v1/call_center/actions/calls/create',
            data: {
                user: this.props.viewing_user._id,
                contact: contact_id,
                isTransfer: true,
                isMuted: true,
                isAdminAudit: true
            }

        })

    }

    render() {

        let { onCallUsers } = this.props;

        if(!onCallUsers)  return <div />

        return (

            <Card className="card-color card-success">

                <CardHeader>
                    <CardTitle className="mb-0">Staff Members On Call <span className="text-success">({onCallUsers.length})</span></CardTitle>
                </CardHeader>

                {onCallUsers.length ? (
                    <ToolkitProvider
                        data={onCallUsers}
                        keyField="_id"
                        columns={this.columns}
                        search
                    >
                        {props => (
                            <>

                                <div className=" table-responsive table-vertical-align table-no-margin">
                                    <BootstrapTable
                                        {...props.baseProps}
                                        bootstrap4={true}
                                        bordered={false}
                                    />
                                </div>
                            </>
                        )}
                    </ToolkitProvider>
                ) : (
                    <CardBody>
                        <p className="text-sm mb-0"><i className="fas fa-times text-danger mr-2" />There are no users currently on a call.</p>
                    </CardBody>
                )}

            </Card>

        );
    }
}


const mapStateToProps = state => {
    return {
        users: state.users.users,
        viewing_user: state.auth.viewing_user
    };
};

CallCenterAdminOnCall.propTypes = {
    onCallUsers: PropTypes.array,
}

export default connect(mapStateToProps, '')(CallCenterAdminOnCall);

