import React, { useState } from 'react';
import { FormGroup, Input } from 'reactstrap';

const InputAnswers = ({answers, onChange, hasErr}) =>  {

    const [answerText, setAnswerText] = useState('');

    // on enter try to add an answer
    const _handleKeyDown = (e) => {
		if (e.key === 'Enter') onAddAnswer()
	}

    // adds an anwers
    const onAddAnswer = () => {
        // validate answer is not blank and does not already exist
        if(!answerText) return;
        if(answers.includes(answerText)) return;

        // add the answer and fire the onChange event
        const newAnswers = [...answers];
        newAnswers.push(answerText);

        setAnswerText('')
        onChange(newAnswers)
    }

    // remove an answer from state and fire the onChange vent
    const onRemoveAnswer = (answer) => {
        let newAnswers = [...answers];
        newAnswers = newAnswers.filter(a => a !== answer);

        onChange(newAnswers)
    }

    return (
        <div className="bg-secondary p-3">
             <FormGroup>
                <label className="form-control-label">Question Answers (Press Enter To Add)</label>
                <Input 
                    onKeyDown={_handleKeyDown}
                    type="text"
                    value={answerText}
                    onChange={(e) => setAnswerText(e.target.value)}
                    invalid={hasErr && answers.length < 2}
                    valid={answers.length >= 2}
                />
            </FormGroup>

            <div className="table-responsive">
                <table className="table mb-0">
                    <thead>
                        <tr>
                            <th>Possible Answers</th>
                            <th className="text-right" style={{width: 100}}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {answers.length ? answers.map((answer, i) => (
                            <tr key={i}>
                                <td>{answer}</td>
                                <td className="text-right">
                                    <i className="fas fa-trash text-danger cursor-pointer " onClick={() => onRemoveAnswer(answer)} />
                                </td>
                            </tr>
                        )) : (
                            <tr>
                                <td>No Answers Given</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
};

export default React.memo(InputAnswers);

