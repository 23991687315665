/*
Documentation

This page handles updating a companies profile
this needs to be updated to handle billing information in the future

TODO: this page needs authentication

*/

import keys from 'keys';
import React from "react";
import { connect } from 'react-redux';
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, FormGroup, Input } from "reactstrap";
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';
import validator from 'utils/validator';
import _companies from '_functions/companies';




const required_fields = [

]

class CompanyOutlook extends React.Component {

    state = {
        company: Object.assign({}, this.props.company),
        emailToAdd: '',
        hiddenEmailToAdd: null,
        microsoftEmailToAdd: '',
        canSave: true
    }

    onSave = async () => {

        toggleStandardLoader(true)

        const validateFields = validator.requiredFields('company', required_fields, this)


        const newState = validateFields.state

        if(validateFields.success) {

            const updated = await _companies.update(this.props.company._id, newState)

            if(updated.success) {
                toggleAlertBS(false, `Your profile has been updated successfully.`)
            }

        }

        toggleStandardLoader(false)

    }

    onInputChange = (e, stateField) => {
        validator.onInputChange(e.target.value, 'company', stateField, this)
    }

    onAddEmail = (isHidden) => {

        let { company, emailToAdd, hiddenEmailToAdd } = this.state

        if(!emailToAdd) return

        let stateName = isHidden ? 'hidden_emails' : 'authorized_emails'
        let stateValue = company && company[stateName] ? company[stateName] : [];

        const toAdd = !isHidden ?  emailToAdd.toLowerCase() : hiddenEmailToAdd.toLowerCase()

        if(!stateValue.includes(toAdd)) {
            stateValue.push(toAdd)
        }

        company[stateName] = stateValue;
        this.setState({company, toAdd: null})

    }

    onRemoveEmail = (emailAddress, isHidden) => {

        const company = this.state.company

         let stateName = isHidden ? 'hidden_emails' : 'authorized_emails'
        let stateValue = company && company[stateName] ? company[stateName] : [];


        stateValue = stateValue.filter(a => a !== emailAddress)

        company[stateName] = stateValue;
        this.setState({company})

    }

    onAddMicrosoftEmail = (isHidden) => {

        let { company, microsoftEmailToAdd } = this.state

        if(!microsoftEmailToAdd) return

        const values = company.microsoft_email_addresses ? company.microsoft_email_addresses : []

        if(!values.includes(microsoftEmailToAdd)) {
            values.push(microsoftEmailToAdd)
        }

        company.microsoft_email_addresses = values;
        this.setState({company, microsoftEmailToAdd: null})

    }

    onRemoveMicrosoftEmail = (emailAddress, isHidden) => {

        const company = this.state.company

        let values = company.microsoft_email_addresses ? company.microsoft_email_addresses : []
        values = values.filter(a => a !== emailAddress);

        company.microsoft_email_addresses = values;
        this.setState({company})

    }


    render() {

        const { company } = this.state

        return (
            <>

                {/* <Card>

                    <CardHeader>
                        <CardTitle className="mb-0">Outlook Settings</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <FormGroup>
                            <label className="form-control-label" htmlFor="microsoft_email_address" >
                                Microsoft Email Address
                            </label>
                            <Input
                                id="microsoft_email_address"
                                value={company.microsoft_email_address || ''}
                                placeholder="Default Email Address"
                                type="text"
                                valid={ company.microsoft_email_addressState === "valid" }
                                invalid={ company.microsoft_email_addressState === "invalid" }
                                onChange={e => this.onInputChange(e, "microsoft_email_address") }
                            />
                            <div className="valid-feedback">Looks good!</div>
                        </FormGroup>
                    </CardBody>

                </Card> */}

                <Card>

                    <CardHeader>
                        <CardTitle className="mb-0">Shared Email Addresses</CardTitle>
                    </CardHeader>

                    <div className="table-responsive">
                        <table className="table">

                            <thead>
                                <tr>
                                    <th>Shared Emails</th>
                                    <th className="text-right">Actions</th>
                                </tr>
                            </thead>

                            <tbody>

                                {company.microsoft_email_addresses && company.microsoft_email_addresses.length ? company.microsoft_email_addresses.map(email => (
                                    <tr key={email}>
                                        <td>{email}</td>
                                        <td onClick={() => this.onRemoveMicrosoftEmail(email)} className="text-right" >
                                            <i className="fas fa-times text-danger cursor-pointer" />
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td>No emails have been set as shared</td>
                                        <td></td>
                                    </tr>
                                )}

                            </tbody>

                        </table>
                    </div>

                    <CardFooter>

                        <FormGroup>
                            <label className="form-control-label" htmlFor="emailToAdd" >Add Shared Email Address</label>
                            <p className="text-sm text-warning font-weight-bold mb-0">
                                <i className="fas fa-exclamation-triangle mr-2" />
                                Make sure to check each email address is written correctly and is a part of the microsoft account you have authorized for this application. These addresses can be viewed and sent by anyone.
                            </p>
                            <p className="text-sm mb-">Note that it can take up to 24 hours for changes to the above email addresses to take effect.</p>
                            <Input
                                id="microsoftEmailToAdd"
                                value={this.state.microsoftEmailToAdd || ''}
                                placeholder="Email Address"
                                type="text"
                                valid={ company.microsoftEmailToAddState === "valid" }
                                invalid={ company.microsoftEmailToAddState === "invalid" }
                                onChange={e => this.setState({microsoftEmailToAdd: e.target.value})}
                            />
                            <div className="valid-feedback">Looks good!</div>
                        </FormGroup>

                    </CardFooter>

                    <CardFooter>
                        <button onClick={this.onAddMicrosoftEmail} className="btn btn-outline-success">Add Email</button>
                    </CardFooter>

                </Card>

                <Card>

                    <CardHeader>
                        <CardTitle className="mb-0">Authorized Email Addresses</CardTitle>
                    </CardHeader>

                    <div className="table-responsive">
                        <table className="table">

                            <thead>
                                <tr>
                                    <th>Authorized Emails</th>
                                    <th className="text-right">Actions</th>
                                </tr>
                            </thead>

                            <tbody>

                                {company.authorized_emails && company.authorized_emails.length ? company.authorized_emails.map(email => (
                                    <tr key={email}>
                                        <td>{email}</td>
                                        <td onClick={() => this.onRemoveEmail(email, false)} className="text-right" >
                                            <i className="fas fa-times text-danger cursor-pointer" />
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td>No emails have been authorized</td>
                                        <td></td>
                                    </tr>
                                )}

                            </tbody>

                        </table>
                    </div>

                    <CardFooter>

                        <FormGroup>
                            <label className="form-control-label" htmlFor="emailToAdd" >Add Authorized Email Address</label>
                            <p className="text-sm text-warning font-weight-bold mb-0">
                                <i className="fas fa-exclamation-triangle mr-2" />
                                Make sure to check each email address is written correctly and is a part of the microsoft account you have authorized for this application.
                            </p>
                            <p className="text-sm mb-">Note that it can take up to 24 hours for changes to the above email addresses to take effect.</p>
                            <Input
                                id="emailToAdd"
                                value={this.state.emailToAdd || ''}
                                placeholder="Email Address"
                                type="text"
                                valid={ company.emailToAddState === "valid" }
                                invalid={ company.emailToAddState === "invalid" }
                                onChange={e => this.setState({emailToAdd: e.target.value})}
                            />
                            <div className="valid-feedback">Looks good!</div>
                        </FormGroup>

                    </CardFooter>

                    <CardFooter>
                        <button onClick={() => this.onAddEmail(false)} className="btn btn-outline-success">Add Email</button>
                    </CardFooter>

                </Card>

                {/* <Card>

                    <CardHeader>
                        <CardTitle className="mb-0">HIdden Email Addresses</CardTitle>
                    </CardHeader>

                    <div className="table-responsive">
                        <table className="table">

                            <thead>
                                <tr>
                                    <th>Hidden Email</th>
                                    <th className="text-right">Actions</th>
                                </tr>
                            </thead>

                            <tbody>

                                {company.hidden_emails && company.hidden_emails.length ? company.hidden_emails.map(email => (
                                    <tr key={email}>
                                        <td>{email}</td>
                                        <td onClick={() => this.onRemoveEmail(email, true)} className="text-right" >
                                            <i className="fas fa-times text-danger cursor-pointer" />
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td>No emails have been marked as hidden</td>
                                        <td></td>
                                    </tr>
                                )}

                            </tbody>

                        </table>
                    </div>

                    <CardFooter>

                        <FormGroup>
                            <label className="form-control-label" htmlFor="hiddenEmailToAdd" >Add Hidden Email Address</label>
                            <p className="text-sm mb-0">
                                Hidden email address are used to keep admin emails secret. They work by hiding all emails that the system doesn't recognize. If you email a contact a contact email will be created, if not nothing will happen.
                            </p>
                            <Input
                                id="hiddenEmailToAdd"
                                value={this.state.hiddenEmailToAdd || ''}
                                placeholder="Email Address"
                                type="text"
                                valid={ company.hiddenEmailToAddState === "valid" }
                                invalid={ company.hiddenEmailToAddState === "invalid" }
                                onChange={e => this.setState({hiddenEmailToAdd: e.target.value})}
                            />
                            <div className="valid-feedback">Looks good!</div>
                        </FormGroup>

                    </CardFooter>

                    <CardFooter>
                        <button onClick={() => this.onAddEmail(true)} className="btn btn-outline-success">Add Email</button>
                    </CardFooter>

                </Card> */}

                <Card>

                    <CardHeader>
                        <CardTitle>Email Verification</CardTitle>
                    </CardHeader>

                    <CardBody>
                    <p className="text-sm">
                        {company.microsoft_tenant_id ? (
                        <span><i className="fas fa-check text-success mr-2" />You have already authenticated your email address. If you wish to change your company's authenticated email change it on this page then re-authenticate via the button below!.</span>
                        ) : 'To enable a default company email click the button below to verify your email address with Microsoft Office 365'}
                    </p>

                    <p className="text-sm font-weight-bold text-danger">
                        <i className="fas fa-exclamation-triangle mr-2" /> The email you authenticate with Microsoft must exactly match the microsoft company email address on this page!!
                    </p>
                    </CardBody>

                    <CardFooter>
                        <a href={`https://login.microsoftonline.com/common/adminconsent?client_id=${keys.MICROSOFT_CLIENT_ID}&state=company_${company._id}&redirect_uri=${keys.API_URL}/api/v1/core/microsoft/auth`} className="btn btn-success btn-block">
                            <i className="fas fa-exclamation mr-2" />
                            Authenticate Email
                        </a>
                    </CardFooter>

                </Card>

                <div className="text-right">
                    <Button
                        color="success"
                        onClick={this.onSave}
                        disabled={this.state.canSave ? false : true}
                    >
                        Save Company
                    </Button>
                </div>

            </>

        );
    }
}

const mapStateToProps = state => {
    return {
        company: state.companies.company,
    };
};

export default connect(mapStateToProps, '')(CompanyOutlook);
