import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, CardTitle, Row, Col, FormGroup, Input } from 'reactstrap';

import A from 'components/markup/links/A';
import renderName from 'utils/renderName';
import { formatPhone } from 'utils/text';

import moment from 'moment';

import CountUp from 'components/markup/time/CountUp'

import keys from 'keys';

const CallQueue = ({data, divisions}) => {

    const [startOfDay] = useState(parseInt(moment().startOf('day').format('X')))
    const [now] = useState(Math.floor(new Date() / 1000))
    const [filter, setFilter] = useState(null);

    const passedFilter = useCallback((queue, _filter) => {
        if(!_filter) return true;
        if(_filter === 10) return queue.priority <= 10;
        return queue.priority === _filter;
    }, [])

    const queues = filter ? data.queue.callQueues.filter(q => passedFilter(q, filter)) : data.queue.callQueues;
    const total = data.queue.newContacts + data.queue.business + data.queue.appointments + data.queue.callQueue

    return (
        <Card style={{height: 'calc(100vh - 298px)', overflow: 'auto'}}>

            <CardHeader>
                <CardTitle className="mb-0"> </CardTitle>

                <Row>
                    <Col lg={6} className="align-self-center">
                        <CardTitle className="mb-0">Call Queue ({total} <small><i className="fas fa-arrow-right text-info-original text-sm mx-1 " /></small> {total + data.queue.predicted})</CardTitle>
                    </Col>
                    <Col lg={6} className="align-self-center text-right">
                        <FormGroup className="mb-0">
                            <Input 
                                size="sm"
                                type="select"
                                value={filter ? filter.toString() : 'Sort'}
                                onChange={(e) => setFilter(e.target.value === 'Sort' ? null : parseInt(e.target.value))}
                            >
                                <option value="Sort">Select Sorting</option>
                                <option value="10">New</option>
                                <option value="20">Business</option>
                                <option value="30">Appointments</option>
                                <option value="40">Queue</option>
                             
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
            </CardHeader>

            <CardHeader>
                <Row>
                    <Col xs={2}>
                        <small className={`cursor-pointer ${!filter ? 'text-success' : null}`} onClick={() => setFilter(null)}>
                            All: {total}
                        </small>
                    </Col>
                    <Col xs={10}>

                        <Row>
                            <Col xs={3} className="text-right">
                                <small className={`cursor-pointer ${filter === 10 ? 'text-success' : null}`} onClick={() => setFilter(10)}>
                                    New: {data.queue.newContacts}
                                </small>
                            </Col>
                            <Col xs={3} className="text-right">
                                <small className={`cursor-pointer ${filter === 20 ? 'text-success' : null}`} onClick={() => setFilter(20)}>
                                    Bus: {data.queue.business}
                                </small>
                            </Col>
                            <Col xs={3} className="text-right">
                                <small className={`cursor-pointer ${filter === 30 ? 'text-success' : null}`} onClick={() => setFilter(30)}>
                                    Appts: {data.queue.appointments}
                                </small>
                            </Col>
                            <Col xs={3} className="text-right">
                                <small className={`cursor-pointer ${filter === 40 ? 'text-success' : null}`} onClick={() => setFilter(40)}>
                                    Queue: {data.queue.callQueue}
                                </small>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </CardHeader>
            <div className="table-responsive">
                <table className="table">
                    <tbody>
                        {queues.length ? queues.map(queue => {
                            const matchedDivision = divisions.find(d => d._id === queue.division)
                            const division = matchedDivision ? (
                                <div><i className="fas fa-divide mr-1 text-info-original " /> {matchedDivision.name}</div>
                            ) : null;
                            return (

                                <tr key={queue._id}>
                                    <td>
                                        {queue.contact ? (
                                            <A className="text-capitalize" to={`${keys.APP_URL}/dashboard/contacts/view/${queue.contact._id}`}>
                                                {queue.contact.given_name || queue.contact.family_name ?  (
                                                    <small>
                                                        {queue.contact.phone ? (
                                                            <i className="fas fa-mobile text-success mr-2" />
                                                        ) : (
                                                            <>
                                                                <i className="fas fa-exclamation-triangle text-danger mr-1" />
                                                                <i className="fas fa-mobile text-danger mr-2" />
                                                            </>
                                                        )}
                                                        {renderName(queue.contact)}
                                                    </small>
                                                ) : (
                                                    <small>
                                                        <i className="fas fa-mobile mr-2 text-warning" />
                                                        {formatPhone(queue.contact.phone)}
                                                    </small>
                                                )}
                                            </A>
                                        ) : 'NOT FOUND'}
                                        <div className="ml--2"> 
                                            <small>Priority: {queue.priority} - {queue.contact ? queue.contact.lead_source_name : '-'}</small>
                                        </div>
                                    </td>

                                    <td>
                                        {queue.appointment ? (
                                            <A className="text-capitalize" to={`${keys.APP_URL}/dashboard/appointments/view/${queue.appointment._id}`}> 
                                                <div><small>{queue.appointment.name}</small> </div>
                                                <small>{division}</small>
                                            </A>
                                        ) : (
                                            <div>
                                                <div className="text-capitalize"><small>{queue.type}</small></div>
                                                <small>{division}</small>
                                        </div>
                                        )}
                                    </td>
                                    <td className="text-right">
                                        <div className={queue.date < startOfDay ? 'text-danger font-weight-bold' : queue.date < now ? 'text-warning font-weight-bold' : 'null'}>
                                            <div className="mb--2"><small>{moment.unix(queue.date).format('M/DD/YYYY')}</small></div>
                                            <div className="mb--2"><small>{moment.unix(queue.date).format('h:mm A')}</small></div>
                                            <div><small><CountUp timestamp={queue.date} /></small></div>
                                        </div>
                                    </td>
                                </tr>
                            )
                        }) : (
                            <tr>
                                <td>{filter ? 'No entries to show for the current filter' : 'No call entries found.'}</td>
                                <td></td>

                                <td></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </Card>
    )
}


const mapStateToProps = state => {
    return {
        divisions : state.divisions.divisions,
    };
};

export default connect(mapStateToProps, '')(CallQueue);