import React, { Component } from 'react';
import { Input, FormGroup, Badge } from 'reactstrap';
import PropTypes from 'prop-types';

class ArchkInArray extends Component {

    state = {
        filter: null,
        isSelected: false,
        lastSelectedText: null,
        selected: []
    };

    onChange = (e) => {

        this.setState({ filter: e.target.value, isSelected: false })

        if(this.props.onInputChange) {
            this.props.onInputChange(e.target.value)
        }

    }

    pushStringToProps = (selected) => {

        let string = null

        selected.forEach(s => {

            string = string ? string + ':' + s.value : s.value

        })

        if(string) {

            if(this.props.type === 'and') {
                string = string + '{and}'
            } else {
                string = string + '{in}'
            }

        }

        this.props.onSelect(string)


    }

    onSelect = (data) => {

        let selected = [...this.state.selected]

        if(!selected.find(s => s.value === data.value)) {
            selected.push(data)

            this.setState({filter: null, isSelected: true, lastSelectedText: data.text, selected})

            this.pushStringToProps(selected)

        }

    }

    onRemove = (value) => {

        let selected = [...this.state.selected]
        selected = selected.filter(s => s.value !== value)

        this.pushStringToProps(selected)

        this.setState({selected})

    }

    onFocus = () => {

        // show answers on focus
        try {
            this.refs.answers.style.display = 'block'
        } catch(e) {
            console.log(e)
        }

    }

    onBlur = () => {

        // set timeout so click event has time to fire
       setTimeout(() => {

            if(!this.state.isSelected && this.state.lastSelectedText !== this.state.filter) {
                this.setState({filter: null})
            }


            // hide answer on blur
            try {
                this.refs.answers.style.display = 'none'
            } catch(e) {
                console.log(e)
            }

        }, 250)

    }

    formatString = (string) => {

        return string.toLowerCase().trim()
    }

    filterData = (data) => {

        if(data && data.length) {

            const filter = this.state.filter;

            if(!filter) return data;

            return data.filter(d => this.formatString(d.text).includes(this.formatString(filter)))


        }

        return []

    }

    render() {

        const data = this.filterData(this.props.data);
        const { label } = this.props
        const { selected } = this.state

        return (

            <FormGroup>

                {label ? ( <label className="form-control-label mb-0">{label}</label> ) : null}

                <div className="archk-select">
                    <Input
                        value={this.state.filter || ''}
                        onChange={this.onChange}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        type="text"
                    />

                    <ul ref="answers" className="data">
                        {data.map((d, i) => (
                            <li key={i} onClick={() => this.onSelect(d)}  >{d.text}</li>
                        ))}
                    </ul>

                </div>

                <div className="mt-3">
                    {selected.map((s, i) => (
                        <Badge onClick={() => this.onRemove(s.value)} key={i} color="success" className={i === 0 ? "text-sm cursor-pointer mb-2 mr-2" : "text-sm cursor-pointer mb-2 ml-0 mr-2"}>
                            {s.text} <i className="fas fa-times ml-2" />
                        </Badge>
                    ))}
                </div>

            </FormGroup>

        )

    }

}

ArchkInArray.propTypes = {

    onSelect: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,

    type: PropTypes.oneOf(['in', 'and']),
    onInputChange: PropTypes.func,

}

export default ArchkInArray;
