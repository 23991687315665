import apiWorker from '../apiWorker'

const template_appointments = {

    update:   (appointment_id, data)  =>  apiWorker('patch',  `/api/v1/core/template_appointments/update/${appointment_id}`, data),
    delete:   (appointment_id)        =>  apiWorker('delete', `/api/v1/core/template_appointments/delete/${appointment_id}`),
    create:   (data)                  =>  apiWorker('post',   `/api/v1/core/template_appointments/create`, data),

    find:     (query)                 =>  apiWorker('get', `/api/v1/core/template_appointments/find${query}`),

}

export default template_appointments;