
// findUnmovedStepsByAppointments

import AvatarImage from 'components/functional/images/AvatarImage';
import moment from 'moment';
import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import renderName from 'utils/renderName';
import { secondsToTime } from 'utils/time';

const Metrics = ({data, getEventName, user}) => {

    if(data[0] && data[0].metrics && data[0].metrics[0] && data[0].metrics[0].events) {
        return (
            <Card>
                <CardHeader className="text-capitalize">
                    <AvatarImage src={user.avatar} className="mr-2" />{renderName(user)}
                </CardHeader>
                <div className="table-responsive">
                    <table className="table table-striped table-sticky-first-col">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Events</th>
                                {data[0].metrics[0].events.map((event, i) => (
                                    <th key={i} style={{height: 160, whiteSpace: 'nowrap', position: 'relative' }}>
                                        <div style={{transform: 'translate(25px, 51px) rotate(300deg)', width: 10, maxWidth: 10, position: 'relative', top: -40, left: -25 }}>
                                            <span className="border-bottom">{getEventName(event.event)}</span>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map(d => {
                                return d.metrics.map((metric, i) => {

                                    if(metric.user !== user._id) return null
                                
                                    return (
                                        <tr key={i}>
        
                                            <td className="text-capitalize" style={{position: 'sticky', left: 0, background: '#fff'}} >
                                                {moment.unix(d.date).format('MM/DD/YYYY')}
                                            </td>
                                            <td>
                                                {metric.total_events} <span className="text-muted">({metric.total_percent_events}%)</span>
                                            </td>
                                            {metric.events.map((event, x) => {

        
                                                let value = event.quantity;
        
                                                if(event.event === 'FIRST_EVENT' || event.event === 'LAST_EVENT') {
                                                    const time = parseInt(event.value) ? parseInt(event.value) : null;
                                                    value = time ? moment.unix(time).format('h:mm A') : '-'
                                                } else if(
                                                    event.event === 'AVERAGE_TIME_BETWEEN_EVENTS' ||
                                                    event.event === 'CALL_TIME_TOTAL' ||
                                                    event.event === 'CALL_TIME_AVERAGE' ||
                                                    event.event === 'CALL_TIME_AVERAGE_BETWEEN' ||
                                                    event.event === 'NOTE_AVERAGE_BETWEEN'
                                                ) {
                                                    const seconds = parseInt(event.value) ? parseInt(event.value) : null;
                                                    value = seconds ? secondsToTime(seconds) : '-'
                                                } else if(event.event === 'NOTE_AVERAGE_LENGTH') {
                                                    value = event.value
                                                } 
                                                return (
                                                    <td key={x}>
                                                        {value} {event.daily_percent ? <span className="text-muted">({event.daily_percent}%)</span> : null}       
                                                    </td>
                                                )
                                            })}
        
                                        </tr>
                                    )
                                })

                            })}
                        </tbody>
                    </table>
                </div>
            </Card>
        )
    }

    return (
        <Card>
            <CardBody>
                <p className="text-sm mb-0">There are no events to show</p>
            </CardBody>
        </Card>
    )

}

export default Metrics