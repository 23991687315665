import * as actionTypes from 'store/actions';
import store from 'store';
import { io } from 'sockets';
import * as socketEvents from 'sockets/events';
import { add, remove, update } from 'store/functions/_crud';
import _analytics_data_types from '_functions/analytics_data_types';

export const setAnalyticsDataTypes = async() => {
    return new Promise( async (resolve) => {

        setListener()
        //fetch the first 100 data_types
        const data_types = await _analytics_data_types.find(`?sort=collection_name__asc|key__asc`)

        if(data_types.success) {

            store.dispatch({
                type: actionTypes.SET_ANALYTICS_DATA_TYPES,
                payload: data_types
            });

            resolve({success: true});

        } else {

            resolve({success: false, message: data_types.message});

        }

    })

}

const setListener = () => {

    io.on(socketEvents.analytics_data_types_added, async (data) => {
        add(data.data, 'SET_ANALYTICS_DATA_TYPES', ['analytics_data_types', 'analytics_data_types']);
    })

    io.on(socketEvents.analytics_data_types_deleted, async (data) => {
        remove(data.data._id, 'SET_ANALYTICS_DATA_TYPES', ['analytics_data_types', 'analytics_data_types']);
    })

    io.on(socketEvents.analytics_data_types_updated, async (data) => {
        update(data.data, 'SET_ANALYTICS_DATA_TYPES', ['analytics_data_types', 'analytics_data_types']);
    })

}
