import ReactSelect from 'components/functional/inputs/ReactSelect';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Card, CardBody, FormGroup, CardFooter } from 'reactstrap';

class RequiresAppointment extends Component {

    render() {

        const { templateAppointmentOptions, step, onRequiredAppointmentChange } = this.props

        const value = step.requires_appointment ? templateAppointmentOptions.find(o => o.value === step.requires_appointment) : {};

        if(!step || (!step.can_move_to_steps)) return <div />

        return (

            <div>

                <h4>Requires Appointment</h4>

                <Card>
                    <CardBody>

                        <FormGroup>
                            <label className="form-control-label">If set all cases on this step that do not have the appointment below will be compiled into a report</label>
                            {!templateAppointmentOptions.length ? (
                                <p className="text-sm text-warning font-weight-bold mb-0">* There are no template appointments created..</p>
                            ) : null}
                            <ReactSelect
                                value={value}
                                onChange={onRequiredAppointmentChange}
                                options={templateAppointmentOptions}
                            />
                        </FormGroup>

                    </CardBody>

                    {value.value ? (
                        <CardFooter className="text-right">
                            <button onClick={() => onRequiredAppointmentChange(null)} className="btn btn-warning">Remove</button>
                        </CardFooter>
                    ) : null}

                </Card>

            </div>

        )

    }

}

RequiresAppointment.propTypes = {
    selectOptions             : PropTypes.array.isRequired,
    onRequiredAppointmentChange   : PropTypes.func.isRequired,
    step                      : PropTypes.object,
}

export default RequiresAppointment;
