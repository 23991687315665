import React, { useEffect, useState } from "react";
import {  CardHeader, CardTitle, FormGroup, Input } from "reactstrap";
import TagsUpdater from 'components/system/migrated/TagsUpdater';
import fetchAllWorkflowSteps from '_functions/workflow_steps/fetchAll';
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';

const EmailParserOptions = ({parser, setParser, onChange}) => {

    const [ stepData, setStepData ] = useState([]);

    // load company data into the state
    useEffect(() => {

        async function fetchData() {

            const data = await fetchAllWorkflowSteps();
            return setStepData(data);

        }

        fetchData();
        
    }, [])


    const onTagChange = (tagArray) => {

        const newState = Object.assign({}, parser);
        newState.tags = tagArray;

        setParser(newState);
    }

    return (

        <>
            <CardHeader className="text-uppercase">
                <CardTitle className="mb-0 float-left">Options</CardTitle>
            </CardHeader>

            <CardHeader>

                <FormGroup>
                    <label className="form-control-label">Add Contact Tag</label>

                    <TagsUpdater
                        keyValue="parser_tags"
                        current_tags={parser.tags ? parser.tags : []}
                        onChange={onTagChange}
                        hideSaveButton={true}
                    />
                </FormGroup>

                <FormGroup>
                    <label className="form-control-label">Download Documents From This Email Into Contacts File</label>
                    <Input 
                        type="select"
                        value={parser.download_documents || ''}
                        onChange={(e) => onChange(e, 'download_documents')}
                        valid={ parser.download_documentsState === "valid" }
                        invalid={ parser.download_documentsState === "invalid" }
                    >
                        <option value="no">No</option>
                        <option value="yes">Yes</option>
                    </Input>
                </FormGroup>

                <FormGroup>
                    <label className="form-control-label">Move Case To Step</label>
                    <Input 
                        type="select"
                        value={parser.move_to_step || ''}
                        onChange={(e) => onChange(e, 'move_to_step')}
                        valid={ parser.move_to_stepState === "valid" }
                        invalid={ parser.move_to_stepState === "invalid" }
                    >
                        <option value="false"></option>
                        {stepData.length ? stepData.map(data => (
                            <React.Fragment key={data.workflow._id}>
                            <option  disabled>WORKFLOW: {data.workflow.type}</option>
                            {data.steps.map(step => (
                                <option key={step._id} value={step._id}>{step.name}</option>
                            ))}
                            </React.Fragment>
                        )): (
                            <option>You have no steps to assign a move to.</option>
                        )}
                    </Input>
                </FormGroup>

                <FormGroup>
                    <label className="form-control-label">Send Failed Logs To Email</label>
                    <Input 
                        type="text"
                        value={parser.failed_email_address || ''}
                        onChange={(e) => onChange(e, 'failed_email_address')}
                        valid={ parser.failed_email_addressState === "valid" }
                        invalid={ parser.failed_email_addressState === "invalid" }
                    />
                </FormGroup>

                <StandardFormGroup
                    obj={parser}
                    objName="parser"
                    onChange={(o, f, v) => onChange({target: { value: v}}, 'attach_email')}
                    type="select"
                    field="attach_email"
                    formatter={(e) => e === true || e === "true" ? "true" : "false"}
                    title={(<>Attach Email Body</>)}
                >
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                </StandardFormGroup>
                

            </CardHeader>
        </>
            
    );
}

export default EmailParserOptions
