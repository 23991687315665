/*
Documentation

example usage:

stateFlows is an array of populated flows
onChange should update the master object list of flows

keyValue is anything that is unique on the page, cant have two
of these components with the same key value

<AssignAutomations
    keyValue={tag._id}
    onChange={(values) => this.setState({flows: values.flow_ids})}
    stateFlows={tag.flows ? tag.flows : []}
/>

*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactSelect from 'components/functional/inputs/ReactSelect';
import { FormGroup } from 'reactstrap';

class AssignAutomations extends Component {

    state = {
        flow_ids: [],
        flows: []
    }

    setOptions = () => {

        const selectOptions = []
        let allFLows = this.props.flows

        if(!allFLows) return

        allFLows = [...allFLows]
        allFLows.forEach(flow => {

            // dont add in values used in the dropdown
            if(!this.state.flow_ids.includes(flow._id)) {
                selectOptions.push({label:  flow.name, value: flow._id})
            }

        })

        this.setState({selectOptions})

    }

    setFlowIds = (stateFlows) => {

        let flow_ids = [];

        if(stateFlows && stateFlows.length) {
            stateFlows.forEach(flow => {
                flow_ids.push(flow._id);
            })
        }

        this.setState({flow_ids})

        return flow_ids

    }

    onRemoveFlow = (flow_id) => {

        let stateFlows = this.state.stateFlows ? this.state.stateFlows : [];

        stateFlows = stateFlows.filter(flow => flow._id !== flow_id)

        const flow_ids = this.setFlowIds(stateFlows)

        this.props.onChange({stateFlows, flow_ids})

        this.setState({stateFlows}, this.setOptions)

    }

    onAddFlow = (flow_id) => {

        let stateFlows = this.state.stateFlows ? this.state.stateFlows : [];

        // dont push duplicates
        if(!stateFlows.find(flow => flow._id === flow_id)) {

            let foundAutomation = this.props.flows.find(flow => flow._id === flow_id)

            if(foundAutomation) stateFlows.push({_id: foundAutomation._id, name: foundAutomation.name})

            const flow_ids = this.setFlowIds(stateFlows)

            this.props.onChange({stateFlows, flow_ids})

            this.setState({stateFlows}, this.setOptions)

        }

    }

    componentWillReceiveProps = (nextProps) => {

        if(nextProps.keyValue !== this.props.keyValue) {

            const stateFlows = nextProps.stateFlows;

            if(stateFlows && stateFlows.length) {

                const flow_ids = this.setFlowIds(stateFlows)

                this.props.onChange({stateFlows, flow_ids})
                this.setState({stateFlows, flow_ids}, this.setOptions)

            } else {

                this.setState({stateFlows: [], flow_ids: []}, this.setOptions)

            }

        }

    }

    componentDidMount = () => {

        const stateFlows = this.props.stateFlows;

        if(stateFlows && stateFlows.length) {

            const flow_ids = this.setFlowIds(stateFlows)

            this.props.onChange({stateFlows, flow_ids})
            this.setState({stateFlows, flow_ids}, this.setOptions)

        } else {

            this.setState({stateFlows: [], flow_ids: []}, this.setOptions)

        }

    }


    render() {

        const {  title, descriptionEmpty } = this.props
        const { stateFlows, selectOptions } = this.state

        return (

            <>

                <FormGroup>
                    <label className="form-control-label">{title ? title : 'Flows'} </label>
                    <ReactSelect
                        value={{}}
                        onChange={(values) => this.onAddFlow(values.value)}
                        options={selectOptions}
                    />
                </FormGroup>

                {stateFlows && stateFlows.length ? stateFlows.map(flow => (
                    <p className="text-sm p-2 px-3 bg-primary text-white border mb-0" key={flow._id}>
                        <i onClick={() => this.onRemoveFlow(flow._id)} className="fas fa-trash mr-2 cursor-pointer text-danger" />
                        {flow.name}
                    </p>
                )) : (
                    <p className="text-sm font-weight-bold">{descriptionEmpty ? descriptionEmpty : `No flows have been set for this task.`}</p>
                )}

            </>

        )

    }

}

AssignAutomations.propTypes = {
    keyValue            : PropTypes.string,
    flows         : PropTypes.array,
    onChange            : PropTypes.func.isRequired,
    title               : PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    descriptionEmpty    : PropTypes.string,
}


export default AssignAutomations;