import React, { Component } from 'react';
import { Col, Row, FormGroup, Input } from 'reactstrap';
import ArchkInArray from '../inputs/ArchkInArray';
import ArchkSelect from '../inputs/ArchkSelect';
import ArchkString from '../inputs/ArchkString';
import ArchkNumber from '../inputs/ArchkNumber';
import ArchkYesNo from '../inputs/ArchkYesNo';
import ArchkNull from '../inputs/ArchkNull';
import ArchkTruthy from '../inputs/ArchkTruthy';
import ArchkDatePicker from '../inputs/ArchkDatePicker';
import PropTypes from 'prop-types'

class SearchFilter extends Component {


    state = {

    };

    // either return the object field or prefixed with custom_data. for easy querying of the database custom_data fields
    getField = (obj) => obj.isCustomData ? 'custom_data.' +obj.field : obj.field

    renderArchkDatePicker = (obj, i) => (
        <ArchkDatePicker
            key={i}
            label={obj.label}
            onChange={(value) => this.props.onChange(this.getField(obj), value)}
        />
    )
    renderArchkNull = (obj, i) => (
        <ArchkNull
            key={i}
            label={obj.label}
            onChange={(value) => this.props.onChange(this.getField(obj), value)}
        />
    )

    renderArchkYesNo = (obj, i) => (
        <ArchkYesNo
            key={i}
            label={obj.label}
            onChange={(value) => this.props.onChange(this.getField(obj), value)}
        />
    )

    renderArchkInArray = (obj, i) => (
        <ArchkInArray
            key={i}
            label={obj.label}
            onSelect={(value) => this.props.onChange(this.getField(obj), value)}
            query={obj.query ? obj.query : 'in'}
            data={obj.data}
        />
    )

    renderArchkSelect = (obj, i) => (
        <ArchkSelect
            key={i}
            label={obj.label}
            onSelect={(value) => this.props.onChange(this.getField(obj), value)}
            data={obj.data}
            filter={this.props.filter}
            field={this.getField(obj)}
        />
    )

    renderArchkString = (obj, i) => (
        <ArchkString
            key={i}
            label={obj.label}
            onChange={(value) => this.props.onChange(this.getField(obj), value)}
        />
    )

    renderArchkNumber = (obj, i) => (
        <ArchkNumber
            isCustomData={obj.isCustomData}
            key={i}
            label={obj.label}
            onChange={(value) => this.props.onChange(this.getField(obj), value)}
        />
    )
    renderArchkTruthy = (obj, i) => (
        <ArchkTruthy
            isCustomData={obj.isCustomData}
            key={i}
            label={obj.label}
            type="boolean"
            onChange={(value) => this.props.onChange(this.getField(obj), value)}
        />
    )

    renderInputs = (objects) => {

        return objects.map((obj, i) => {

            switch (obj.type) {
                case 'ArchkSelect'      : return this.renderArchkSelect(obj, i)
                case 'ArchkString'      : return this.renderArchkString(obj, i)
                case 'ArchkNumber'      : return this.renderArchkNumber(obj, i)
                case 'ArchkInArray'     : return this.renderArchkInArray(obj, i)
                case 'ArchkNull'        : return this.renderArchkNull(obj, i)
                case 'ArchkTruthy'      : return this.renderArchkTruthy(obj, i)
                case 'ArchkYesNo'       : return this.renderArchkYesNo(obj, i)
                case 'ArchkDatePicker'  : return this.renderArchkDatePicker(obj, i)

                default: console.log('invalid type passed via Graph Component: ' + obj.type); return null;
            }

        })

    }

    render() {

        const { show, filter, onChange, onRemoveFilter, inputs, showOneFilter } = this.props

        return (

            <div style={{display: show ? 'block' : 'none'}}>

                {!showOneFilter ? (
                <Row>
                    <Col xs={12} className="column filter-name">

                        <FormGroup className="mb-0">

                            <label className="form-control-label">Filter Name (Use this to name your query for easy reference)</label>
                            <Input
                                value={filter.__filter_name || ''}
                                onChange={(e) => onChange('__filter_name', e.target.value)}

                            />

                            <div className="text-right mt-3">
                                <button onClick={onRemoveFilter} className="btn btn-danger mb-0">Remove Filter <i className="fas fa-trash ml-2" /></button>
                            </div>

                        </FormGroup>
                    </Col>
                </Row>
                ) : null}

                <Row>

                    <Col lg={3} className="column">
                        {inputs[0] && inputs[0].length ? this.renderInputs(inputs[0]) : null}
                    </Col>

                    <Col lg={3} className="column">
                        {inputs[1] && inputs[1].length ? this.renderInputs(inputs[1]) : null}
                    </Col>

                    <Col lg={3} className="column">
                        {inputs[2] && inputs[2].length ? this.renderInputs(inputs[2]) : null}
                    </Col>

                    <Col lg={3} className="column">
                        {inputs[3] && inputs[3].length ? this.renderInputs(inputs[3]) : null}
                    </Col>

                </Row>

            </div>

        )

    }

}



SearchFilter.propTypes = {

    inputs: PropTypes.array.isRequired,
    showOneFilter: PropTypes.bool.isRequired,

    onRemoveFilter: PropTypes.func,
    onChange: PropTypes.func,
    filter: PropTypes.object,
    show: PropTypes.bool,

}

export default SearchFilter;
