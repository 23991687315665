import apiWorker from '../apiWorker'

const groupings = {

    create:   (data)                 =>  apiWorker('post',   `/api/v1/analytics/groupings/create`, data),
    update:   (lead_source_id, data) =>  apiWorker('patch',  `/api/v1/analytics/groupings/update/${lead_source_id}`, data),
    delete:   (lead_source_id,)      =>  apiWorker('delete', `/api/v1/analytics/groupings/delete/${lead_source_id}`),
    find:     ()                     =>  apiWorker('get',    `/api/v1/analytics/groupings/find`),
    findById: (_id)                  =>  apiWorker('get',    `/api/v1/analytics/groupings/findById/${_id}`),

}

export default groupings;