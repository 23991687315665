/*
Documentation

This component shows the given_name, family_name, email, and phone fields for updating and creating a user

*/

import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import React from "react";
import { Col, Row } from "reactstrap";
import PropTypes from 'prop-types';

class General extends React.Component {

    render() {

        const user = this.props.user;

        return (
            <>

                <Row>

                    <Col lg="6">
                        <StandardFormGroup
                            obj={user}
                            objName="user"
                            onChange={(o, f, v) => this.props.onChange(f, v)}
                            field="given_name"
                            title="First Name *"
                        />
                    </Col>

                    <Col lg="6">
                        <StandardFormGroup
                            obj={user}
                            objName="user"
                            onChange={(o, f, v) => this.props.onChange(f, v)}
                            field="family_name"
                            title="Last Name *"
                        />
                    </Col>

                </Row>

                <StandardFormGroup
                    obj={user}
                    objName="user"
                    onChange={(o, f, v) => this.props.onChange(f, v)}
                    field="email"
                    title="Email *"
                />

                <StandardFormGroup
                    obj={user}
                    objName="user"
                    onChange={(o, f, v) => this.props.onChange(f, v)}
                    field="phone"
                    title="Phone"
                    type="tel"
                />
                
                <StandardFormGroup
                    obj={user}
                    objName='user'
                    onChange={(o, f, v) => this.props.onChange(f, v)}
                    type="number"
                    field="default_percent"
                    title={`Default Percent: ${!Number.isNaN(parseFloat(user.default_percent)) ? (parseFloat(user.default_percent) * 100).toFixed(2) + '%' : '0%'}`}    
                />
            </>
        );
    }
}

General.propTypes = {
    user        : PropTypes.object.isRequired,
    onChange    : PropTypes.func.isRequired,
}

export default General
