
import NotificationDelete from 'components/functional/notifications/Delete';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React, { useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Card, CardHeader, CardTitle, Container } from "reactstrap";

import keys from 'keys';

const TemplateDocsAll = ({template_docs}) => {

    const [templateToDelete, setTemplateToDelete] = useState(null);

    return (

        <Container fluid>

            <Helmet>
                <title>{`Template Docs`}</title>
                <meta name="description" content="Template Docs" />
            </Helmet>

            {templateToDelete && (
                <NotificationDelete
                    deletionURL={`/api/v1/core/template_docs/delete/${templateToDelete._id}`}
                    confirmBtnText="Delete Contact"
                    title={<span className="text-capitalize">{`Delete ${templateToDelete.name}`}</span>}
                    text="Deleting this document will prevent any one from sending it for signing in the future."
                    onClose={() => setTemplateToDelete(null) }
                    onSuccess={() => {}}
                />
            )}

            <DashHeaderOpen
                icon="fas fa-home"
                icon_link="/dashboard"
                title={<span>Template Docs</span>} breadcrumb_1="All"
                actionComponent={(
                    <a target="_blank" rel="noopener noreferrer" href={`${keys.APP_URL}/dashboard/template_docs/new`} className="btn btn-success"><i className="fas fa-plus mr-2 " />Create New</a>
                )}
            />

            <Card className="card-color card-primary">

                <CardHeader>
                    <CardTitle className="mb-0 float-left">All Documents</CardTitle>
                </CardHeader>

                <div className="table-responsive">
                    <table className="table">

                        <thead>
                            <tr>
                                <th>Name</th>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            {template_docs.length ?  template_docs.map(t => (
                                <tr key={t._id}>
                                    <td>
                                        <a target="_blank" rel="noopener noreferrer" href={`${keys.APP_URL}/dashboard/template_docs/${t._id}`}>{t.name}</a>
                                    </td>
                                    <td className="text-right">
                                        <a target="_blank" rel="noopener noreferrer" href={`${keys.APP_URL}/dashboard/template_docs/${t._id}`}>
                                            <i className="fas fa-edit text-success mr-2 cursor-pointer" />
                                        </a>
                                        <i onClick={() => setTemplateToDelete(t)} className="fas fa-trash text-danger cursor-pointer" />
                                    </td>
                                </tr>
                            )) : (
                                <tr>
                                    <td>No Template Docs have been created.</td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>

                    </table>
                </div>

            </Card>
            
        </Container>

    );
}

const mapStateToProps = state => {
    return {
        template_docs: state.template_docs.template_docs,
    };
};

export default connect(mapStateToProps, '')(TemplateDocsAll);
