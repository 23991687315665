import Circle from 'components/markup/loading/Circle';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import { secondsToTime } from 'utils/time';
import AvatarImage from 'components/functional/images/AvatarImage';
import renderName from 'utils/renderName';
import Pie from '../../../_components/charts/Pie';
import Bar from '../../../_components/charts/Bar';


const AppointmentsBreakdownResults = ({data, loading, queryInfo, groupings, SETTINGS}) => {

    const getDelinquent = (obj) => {
        if(obj.total_delinquent_appointments && obj.total_delinquent_seconds) {
            return secondsToTime(obj.total_delinquent_seconds / obj.total_delinquent_appointments)
        }

        return 0;
    }

    const getGroupingName = (grouping_id) => {
        if(!grouping_id) return null;
        const grouping = groupings.find(g => g._id === grouping_id);
        return grouping ? grouping.name : null;
    }

    const { start, grouping, user } = queryInfo
    const end = moment(start).endOf('day').format('MM/DD/YYYY h:mm A');

    if(!data) return <div />;
    if(loading) return <Circle />

    const groupingName = getGroupingName(grouping);

    const ranges = [
        { field: 'today', title: `Start: ${moment(start).format('MM/DD/YYYY h:mm A')} -> ${end}`},
        { field: 'week', title: `Previous 7 Days - ${moment(start).subtract(7, 'days').format('MM/DD/YYYY h:mm A')} -> Today`},
        { field: 'month', title: `Previous 30 Days - ${moment(start).subtract(30, 'days').format('MM/DD/YYYY h:mm A')} -> Today`},
        { field: 'quarter', title: `Previous Quarter - ${moment(start).subtract(3, 'months').format('MM/DD/YYYY h:mm A')} -> Today`},
        { field: 'year', title: `Previous 365 Days - ${moment(start).subtract(365, 'days').format('MM/DD/YYYY h:mm A')} -> Today`},
    ]

    const createGraph = (range) => {

        const obj = {
            "labels": [
                "Manually Completed",
            ],
            "datasets": [
                {
                    "label": "Times Ocurred",
                    "data": [
                        data[range.field].outcome_manual,
                    ],
                    "backgroundColor": [
                        '#053A53',
                        '#5603ad',
                        '#8965e0',
                        '#f3a4b5',
                        '#f5365c',
                        '#fb6340',
                        '#ffd600',
                        '#000000'
                    ]
                }
            ]
        }
        
        SETTINGS.call_center.outcomes.forEach(outcome => {
            obj.labels.push(outcome.replace('Client Answered - ', '').replace('No Hire - ', ''))
            obj.datasets[0].data.push(data[range.field]['outcome_' +  outcome.replace(/ /g, '_').toLowerCase()]);
        })

        return obj
    }
    
    return (

        <>

        <Card className="mb-0">

          
            <CardHeader className="bg-dark">
                <CardTitle className="mb-0 text-muted">

                <div>
                    <span style={styles.identifier}>Type: </span>
                    <span className="text-white">{groupingName ? groupingName : 'All'}</span>
                </div>

                <div>
                    <span style={styles.identifier}>User: </span>
                    {(
                        <span className="text-white text-capitalize">
                           { user._id ? (
                            <>
                                <span className="text-white">{renderName(user)}</span>
                                <AvatarImage src={user.avatar} className="ml-2" />
                            </>
                            ) : 'All'}
                        </span>
                    )}
                </div>
                </CardTitle>
            </CardHeader>

        </Card>


        <Row>
            {ranges.map((range, i) => (
                <Col key={i} lg={12} className="mb-3">

                    <div className="z-depth-1">

                    <CardHeader className="bg-white border-left border-right">
                        <CardTitle className="mb-0 text-center">{range.title}</CardTitle>
                    </CardHeader>

                    <CardHeader className={'border-left border-right'}>

                        <Row>
                            <Col lg={3} xl={4} className="align-self-center ">
                                <p className="mb-0">Total</p>
                                <h4 className="">{data[range.field].total_appointments}</h4>
                            </Col>
                            <Col lg={3} xl={4} className="align-self-center text-center">
                                <p className="mb-0 text-center">Late Start</p>
                                <h4 className="">{data[range.field].total_delinquent_appointments}</h4>
                            </Col>
                            <Col lg={6} xl={4} className="align-self-center text-right">
                                <p className="mb-0 text-right">Avg Delinquency</p>
                                <h4 className="">{getDelinquent(data[range.field])}</h4>
                            </Col>
                        </Row>

                    </CardHeader>

                    <Row>
                        
                        <Col lg={6} className="pr-0 border-right">
                            <Pie
                                className="mb-0 z-depth-0"
                                styles={{borderRadius: 0, border: 'none', boxShadow: 'none'}}
                                title={'Outcome Breakdown'}
                                data={createGraph(range)}
                            />
                        </Col>

                        <Col lg={6} className="pl-0">
                            <Bar
                                className="mb-0 z-depth-0"
                                styles={{borderRadius: 0, border: 'none', boxShadow: 'none'}}
                                title={'Outcome Breakdown'}
                                data={createGraph(range, true)}
                            />
                        </Col>
                    </Row>

                    </div>


                </Col>

            ))}
        </Row>

        </>

    )
}

const styles = {
    identifier: {
        display: 'inline-block',
        width: 60
    }
}

const mapStateToProps = state => {
    return {
        SETTINGS: state.system.SETTINGS,
        groupings: state.groupings.groupings.filter(g => g.collection_name === 'template_appointments')
    };
};

export default connect(mapStateToProps, '')(AppointmentsBreakdownResults);   