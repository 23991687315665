/*

note that show modal prop is an object of the lead source
we use it to pre-fill values when opening the modal

*/

import React from "react";

import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import PropTypes from 'prop-types';

import { Modal, UncontrolledTooltip } from "reactstrap";
import { toggleStandardLoader } from 'store/functions/system/system';
import validator from 'utils/validator';

import _lead_sources from '_functions/lead_sources';
import { validatePhoneNumber } from 'utils/validation';
import { connect } from 'react-redux';
import { toggleAlertBS } from 'store/functions/system/system';
import _brokers from '_functions/brokers';

const required_fields = ['name', 'source' ]

class UpdateSourceModal extends React.Component {

    state = {
        lead_source: {},
        brokers: []
    }

    componentWillReceiveProps = (nextProps) => {

        if(!this.props.showModal && nextProps.showModal) {
            const source = this.props.lead_sources.find(s => s._id === this.props.lead_source)
            this.setState({lead_source: JSON.parse(JSON.stringify(source))})
        }

    }

    onSave = async () => {
        
        this.setState({duplicateError: false})

        const validateFields = validator.requiredFields('lead_source', required_fields, this)
        const newState = validateFields.state
        
        if(newState.division === 'false') {
            return this.setState({lead_source: {...newState, divisionState: 'invalid'}})
        }

        let formattedPhone = validatePhoneNumber(newState.source)
        if(formattedPhone) newState.source = formattedPhone

        if(this.props.lead_sources.some(ls => ls.source && ls.active && ls._id !== newState._id && ls.source.toLowerCase() === newState.source.toLowerCase())) {
            return this.setState({duplicateError: true})
        }

        let default_percent = 0;

        const percentIsValid = (percent) => {
            return percent !== undefined && percent !== null && percent !== '' ? true : false
        }

        if(!percentIsValid(newState.default_percent)) return toggleAlertBS('info', 'Make sure that the default percent is a number between 0 and 1. Example: 25% = .25');

        default_percent = parseFloat(newState.default_percent)

        if(validateFields.success) {

            toggleStandardLoader(true)

            const updated = await _lead_sources.update(newState._id, {
                ...newState,
                broker: !newState.broker || newState.broker === 'false' ? null : newState.broker,
                amount_spent: !newState.amount_spent ? 0 : newState.amount_spent,
                default_percent
            })
            
            if(updated.success) {
                toggleAlertBS(false, 'Source Updated Successfully')
                this.props.onSuccess()
                this.props.toggleModal()
                this.setState({lead_source: {}})
            }

            toggleStandardLoader(false)

        }

    }

    componentDidMount = async () => {
        const { data } = await _brokers.find();
        this.setState({brokers: data ? data : []})
    }

    render() {

        const { showModal, toggleModal, divisions } = this.props;
        const { lead_source, duplicateError, brokers } = this.state

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal ? true : false }
                toggle={() => toggleModal()}
                size="md"
            >
                <div className="modal-header">
                    <h5 className="modal-title">Update Lead Source</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => toggleModal()}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">

                    {duplicateError ? 
                        <div className="alert alert-danger">An active lead source currently exists with the same source.</div> 
                    : null}

                    {lead_source.name === 'Other' ? (
                        <>
                        <p className="text-sm font-weight-bold"><i className="text-danger mr-2 fas fa-exclamation-triangle " /> Note that the "Other" lead source is the default lead source within the system. You may not delete it, change it's name, or mark it as inactive.</p>
                        <hr className="my-2"/>
                        </>
                    ) : null}

                    {lead_source.name !== 'Other' ? (
                    <StandardFormGroup
                        obj={lead_source}
                        objName="lead_source"
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`fState`]: v ? 'valid' : 'invalid'}})}
                        field="name"
                        title="Name"
                    />
                    ): null}

                    <StandardFormGroup
                        obj={lead_source}
                        objName='lead_source'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`fState`]: v ? 'valid' : 'invalid'}})}
                        type="select"
                        field="division"
                        title="Source Division"
                    >
                        <option value="false"></option>
                        {divisions.map(division => (
                            <option value={division._id} key={division._id}>{division.name}</option>
                        ))}
                    </StandardFormGroup>
                   
                   
                    <StandardFormGroup
                        obj={lead_source}
                        objName='lead_source'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`fState`]: v ? 'valid' : 'invalid'}})}
                        type="select"
                        field="broker"
                        title="Associated Broker"
                    >
                        <option value="false"></option>
                        {brokers.map(broker => (
                            <option value={broker._id} key={broker._id}>{broker.name}</option>
                        ))}
                    </StandardFormGroup>

                    <StandardFormGroup
                        obj={lead_source}
                        objName='lead_source'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`fState`]: v ? 'valid' : 'invalid'}})}
                        type="select"
                        field="call_center"
                        title="Associated Call Center"
                    >
                        <option value="false"></option>
                        {this.props.call_centers.map(call_center => (
                            <option value={call_center._id} key={call_center._id}>{call_center.name}</option>
                        ))}
                    </StandardFormGroup>

                    <StandardFormGroup
                        obj={lead_source}
                        objName='lead_source'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        type="number"
                        field="default_percent"
                        title={`Default Percent: ${!Number.isNaN(parseFloat(lead_source.default_percent)) ? (parseFloat(lead_source.default_percent) * 100).toFixed(2) + '%' : '0%'}`}    
                    />

                    {/* <StandardFormGroup
                        obj={lead_source}
                        objName="lead_source"
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`fState`]: v ? 'valid' : 'invalid'}})}
                        field="amount_spent"
                        title={(
                            <span>
                                Amount Spent
                                <i id="tooltipAmountSpent" className="fas fa-info-circle text-info-original ml-2" />
                                <UncontrolledTooltip delay={0} placement="top" target="tooltipAmountSpent">
                                    This is the amount spent on this lead source to calculate it's profitability.
                                </UncontrolledTooltip>

                            </span>
                        )}
                        type="number"
                    /> */}

                    {lead_source.name !== 'Other' ? (
                    <StandardFormGroup
                        obj={lead_source}
                        objName="lead_source"
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`fState`]: v ? 'valid' : 'invalid'}})}
                        field="source"
                        title={(
                            <span>
                                Lead Source
                                <i id="tooltipLeadSource" className="fas fa-info-circle text-info-original ml-2" />
                                <UncontrolledTooltip delay={0} placement="top" target="tooltipLeadSource">
                                    This is usually a phone number or a web page URL.
                                </UncontrolledTooltip>

                            </span>
                        )}                    
                    />
                    ) : null}

                    {/* {lead_source.name !== 'Other' ? (
                    <StandardFormGroup
                        obj={lead_source}
                        objName="lead_source"
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`fState`]: v ? 'valid' : 'invalid'}})}
                        field="lead_cost"
                        title={(
                            <span>
                                Cost Per Lead
                                <i id="tooltipLeadCost" className="fas fa-info-circle text-info-original ml-2" />
                                <UncontrolledTooltip delay={0} placement="top" target="tooltipLeadCost">
                                    This is the amount spent on every lead that gets put into this group.
                                </UncontrolledTooltip>

                            </span>
                        )}                    
                    />
                    ) : null} */}

                    {lead_source.name !== 'Other' ? (
                        <StandardFormGroup
                            obj={lead_source}
                            objName="lead_source"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`fState`]: v ? 'valid' : 'invalid'}})}
                            field="active"
                            formatter={e => e === true || e === 'true' ? 'true' : 'false'}
                            type="select"
                            title={"Is Active"}
                        >
                            <option value="true" >Yes</option>
                            <option value="false" >No</option>
                        </StandardFormGroup>
                    ) : null}

                    <hr />

                    <StandardFormGroup
                        obj={lead_source}
                        objName='lead_source'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`fState`]: v ? 'valid' : 'invalid'}})}
                        type="text"
                        field="ident_source_id"
                        title="Ident Source Id"
                    />
                    <StandardFormGroup
                        obj={lead_source}
                        objName='lead_source'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`fState`]: v ? 'valid' : 'invalid'}})}
                        type="text"
                        field="ident_vendor_code"
                        title="Ident Vendor ID Code"
                    />
                    <StandardFormGroup
                        obj={lead_source}
                        objName='lead_source'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`fState`]: v ? 'valid' : 'invalid'}})}
                        type="text"
                        field="ident_list_id"
                        title="Ident List Id"
                    />
                    
                    <StandardFormGroup
                        obj={lead_source}
                        objName='lead_source'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`fState`]: v ? 'valid' : 'invalid'}})}
                        type="select"
                        field="post_to_ident"
                        description="When a lead is created we will post them over to iDent to get called right away with the 3 fields above. All must be set and correctly configured for the post to work and the this setting set to yes (Generally used for API posted leads into us)"
                        title="Send Created Leads To Ident"
                    >
                        <option value="no">No</option>
                        <option value="yes">Yes</option>
                    </StandardFormGroup>
                    

                </div>

                <div className="modal-footer text-right">
                    <button className="btn btn-success" onClick={this.onSave}>
                        <i className="fas fa-save mr-2" />
                        Update Source
                    </button>
                </div>

            </Modal>

        );
    }
}

UpdateSourceModal.propTypes = {
    onSuccess     : PropTypes.func.isRequired,
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.object,
}


const mapStateToProps = state => {
    return {
        lead_sources: state.lead_sources.lead_sources,
        call_centers: state.call_centers.call_centers,
        divisions: state.divisions.divisions,
    };
};

export default connect(mapStateToProps, '')(UpdateSourceModal);