import apiWorker from '../apiWorker'


const offices = {

    create:     (data)              => apiWorker('post',   `/api/v1/core/offices/create/`, data),
    update:     (office_id, data)   => apiWorker('patch',  `/api/v1/core/offices/update/${office_id}`, data),
    delete:     (office_id)         => apiWorker('delete', `/api/v1/core/offices/delete/${office_id}`),

    find:       (query)             => apiWorker('get',    `/api/v1/core/offices/find${query}`),
    findById:   (office_id)         => apiWorker('get',    `/api/v1/core/offices/find/${office_id}`),


}

export default offices;