import React from "react";
import { Card } from "reactstrap";
import Active from './Active';
import IdentifiersContact from './IdentifiersContact';
import IdentifiersEmail from './IdentifiersEmail';
import Options from './Options';

const EmailParserSidebar = ({parser, setParser, onChange}) => {

    return (

        <div className="archk-email-parser-sidebar">
            <Card>

                <Active 
                    parser={parser}
                    onChange={onChange}
                />
                <IdentifiersEmail 
                    parser={parser}
                    setParser={setParser}
                    onChange={onChange}
                />

                <IdentifiersContact 
                    parser={parser}
                    onChange={onChange}
                />

                <Options 
                    parser={parser}
                    setParser={setParser}
                    onChange={onChange}
                />
            </Card>

        </div>

    );
}

export default EmailParserSidebar