import apiWorker from '../apiWorker'

const missing_docs = {

    find:    (query)         => apiWorker('get',  `/api/v1/core/missing_docs/find${query}`),
    create:  (data)          => apiWorker('post',  `/api/v1/core/missing_docs/create`, data),
    update:  (doc_id, data)  => apiWorker('patch', `/api/v1/core/missing_docs/update/${doc_id}`, data),
    delete:  (doc_id)        => apiWorker('delete', `/api/v1/core/missing_docs/delete/${doc_id}`),

}

export default missing_docs;