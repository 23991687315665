/*
Documentation

*/

import Circle from 'components/markup/loading/Circle';
import React from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Card, CardHeader, CardTitle, Container } from "reactstrap";
import { formatMoney } from 'utils/currency';
import { secondsToTime } from 'utils/time';
import _lead_sources from '_functions/lead_sources';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import { Link } from 'react-router-dom';

class LeadSourcesComparison extends React.Component {

    state = {
        
    }

    fetchLeadSource = async () => {

        const sources = this.props.lead_sources
        let allData = [];
        let counter = 0

        let sourcesPositive = []
        let sourcesNegative = []

        let totals = {
            leads           : 0,
            not_contacted   : 0,
            hire_later      : 0,
            hired           : 0,
            potential       : 0,
            rejected_client : 0,
            rejected_us     : 0,
            profit          : 0,
            spent           : 0,
            staff_cost      : 0,
        }

        let totalsPositive = {
            leads           : 0,
            not_contacted   : 0,
            hire_later      : 0,
            hired           : 0,
            potential       : 0,
            rejected_client : 0,
            rejected_us     : 0,
            profit          : 0,
            spent           : 0,
            staff_cost      : 0,
        }

        let totalsNegative = {
            leads           : 0,
            not_contacted   : 0,
            hire_later      : 0,
            hired           : 0,
            potential       : 0,
            rejected_client : 0,
            rejected_us     : 0,
            profit          : 0,
            spent           : 0,
            staff_cost      : 0,
        }

        sources.forEach(async (source, i) => {

            const data = await _lead_sources.analyze({ lead_source: source._id })
            if(data.success) allData[i] = data.data

            counter++
            if(counter === sources.length) {

                allData.forEach(source => {
                    const a = source.analysis;

                    if(a.profit < 0) {

                        totalsNegative.not_contacted    += a.total_not_contacted;
                        totalsNegative.leads            += a.total_leads;
                        totalsNegative.hire_later       += a.total_hire_later;
                        totalsNegative.hired            += a.total_hired;
                        totalsNegative.potential        += a.total_potential;
                        totalsNegative.rejected_client  += a.total_rejected_client;
                        totalsNegative.rejected_us      += a.total_rejected_us;
                        totalsNegative.profit           += a.profit;
                        totalsNegative.spent            += a.amount_spent;
                        totalsNegative.staff_cost       += a.total_staff_cost;

                        sourcesNegative.push(source)

                    } else {

                        totalsPositive.not_contacted    += a.total_not_contacted;
                        totalsPositive.leads            += a.total_leads;
                        totalsPositive.hire_later       += a.total_hire_later;
                        totalsPositive.hired            += a.total_hired;
                        totalsPositive.potential        += a.total_potential;
                        totalsPositive.rejected_client  += a.total_rejected_client;
                        totalsPositive.rejected_us      += a.total_rejected_us;
                        totalsPositive.profit           += a.profit;
                        totalsPositive.spent            += a.amount_spent;
                        totalsPositive.staff_cost       += a.total_staff_cost;

                        sourcesPositive.push(source)

                    }

                    totals.not_contacted    += a.total_not_contacted;
                    totals.leads            += a.total_leads;
                    totals.hire_later       += a.total_hire_later;
                    totals.hired            += a.total_hired;
                    totals.potential        += a.total_potential;
                    totals.rejected_client  += a.total_rejected_client;
                    totals.rejected_us      += a.total_rejected_us;
                    totals.profit           += a.profit;
                    totals.spent            += a.amount_spent;
                    totals.staff_cost       += a.total_staff_cost;

                })

                sourcesPositive.sort((a,b) => a.analysis.profit <= b.analysis.profit ? 1 : -1)
                sourcesNegative.sort((a,b) => a.analysis.profit <= b.analysis.profit ? -1 : 1)

                this.setState({
                    sources: allData,
                    totals,
                    sourcesPositive,
                    sourcesNegative,
                    totalsPositive,
                    totalsNegative,


                })
    
            } 

        })

    }

    
  
    componentDidMount = async () => this.fetchLeadSource()

    render() {

        const { sources, totals, sourcesPositive, sourcesNegative, totalsPositive, totalsNegative,} = this.state;

        if(!sources) return <div className="py-6"><Circle /></div>
        
        return (
            <>

            <Container fluid>

                <Helmet>
                    <title>{`Lead Source`}</title>
                    <meta name="description" content="Contacts" />
                </Helmet>

                <DashHeaderOpen
                    title={<span><i className="fas fa-headset mr-2 text-success " />Analytics</span>}
                    breadcrumb_1="Lead Sources"
                    breadcrumb_2="Comparison"
                    actionComponent={(
                        <Link to="/analytics/lead_sources" className="btn btn-outline-warning"><i className="fas fa-arrow-left mr-2" /> Back</Link>
                    )}
                />

                <Card className="bg-secondary">

                    <CardHeader className="bg-dark text-white">
                        <CardTitle className="text-white">Profit And Loss Comparison</CardTitle>
                    </CardHeader>

                    <div className="table-responsive bg-white" style={{borderTop: 'solid 5px #eee'}}>
                        <table className="table">
                            
                            <thead>
                                <tr>
                                    <th style={{width: '40%'}}>Lead Sources With Profit</th>
                                    <th style={{width: '15%'}}>Amount Spent</th>
                                    <th style={{width: '15%'}}>Staff Cost</th>
                                    <th style={{width: '15%'}}>Total Profit</th>
                                    <th style={{width: '15%'}} className="text-right">% of total gain</th>
                                </tr>
                            </thead>

                            <tbody className="bg-secondary">
                                {sourcesPositive.map(lead_source => {

                                    const a = lead_source.analysis
                                    const percent = a.profit && totalsPositive.profit ? 
                                        ((a.profit / totalsPositive.profit) * 100).toFixed(2) : 
                                        '0.00'

                                    return (
                                        <tr key={a._id}>
                                            <td>{a.name}</td>
                                            <td>{formatMoney(a.amount_spent)}</td>
                                            <td>{formatMoney(a.total_staff_cost)}</td>
                                            <td>{formatMoney(a.profit)}</td>
                                            <td className="text-right">
                                                <span className="text-success font-weight-bold">{percent}%</span>
                                            </td>
                                        </tr>
                                    )
                                })}

                                <tr className="text-dark font-weight-bold bg-white">
                                    <td>Total</td>
                                    <td className="text-danger">{formatMoney(totalsPositive.spent)}</td>
                                    <td className="text-danger">{formatMoney(totalsPositive.staff_cost)}</td>
                                    <td className="text-success">{formatMoney(totalsPositive.profit)}</td>
                                    <td className="text-right font-weight-bold text-success">100%</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>

                    <div className="table-responsive bg-white" style={{borderTop: 'solid 5px #eee'}}>
                        <table className="table">
                            
                            <thead>
                                <tr>
                                    <th style={{width: '40%'}}>Lead Sources With Loss</th>
                                    <th style={{width: '15%'}}>Amount Spent</th>
                                    <th style={{width: '15%'}}>Staff Cost</th>
                                    <th style={{width: '15%'}}>Total Profit</th>
                                    <th style={{width: '60%'}} className="text-right">% of total loss</th>
                                </tr>
                            </thead>

                            <tbody className="bg-secondary">
                                {sourcesNegative.map(lead_source => {

                                    const a = lead_source.analysis
                                    const percent = ((a.profit / totalsNegative.profit) * 100).toFixed(2)

                                    return (
                                        <tr key={a._id}>
                                            <td>{a.name}</td>
                                            <td>{formatMoney(a.amount_spent)}</td>
                                            <td>{formatMoney(a.total_staff_cost)}</td>
                                            <td>{formatMoney(a.profit)}</td>
                                            <td className="text-right">
                                                <span className="text-danger font-weight-bold">-{percent}%</span>
                                            </td>
                                        </tr>
                                    )
                                })}
                                <tr className="text-dark font-weight-bold bg-white">
                                    <td>Total</td>
                                    <td className="text-danger">{formatMoney(totalsNegative.spent)}</td>
                                    <td className="text-danger">{formatMoney(totalsNegative.staff_cost)}</td>
                                    <td className="text-danger">{formatMoney(totalsNegative.profit)}</td>
                                    <td className="text-right font-weight-bold text-danger">100%</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>

                </Card>

                <Card className="bg-secondary my-4">

                    <CardHeader className="bg-dark text-white">
                        <CardTitle className="text-white">Lead Quality Comparison</CardTitle>
                    </CardHeader>

                    <div className="table-responsive bg-white" style={{borderTop: 'solid 5px #eee'}}>
                        <table className="table">
                            
                            <thead>
                                <tr>
                                    <th style={{width: '50%'}}>Not Contacted</th>
                                    <th style={{width: '15%'}}>Total Leads</th>
                                    <th style={{width: '20%'}}>AVG Time Till Contacted</th>
                                    <th style={{width: '15%'}} className="text-right">% of lead type</th>
                                </tr>
                            </thead>

                            <tbody className="bg-secondary">
                                {sources.map(lead_source => {

                                    const a = lead_source.analysis
                                    const percent = a.total_not_contacted && totals.not_contacted ? 
                                        ((a.total_not_contacted / totals.not_contacted) * 100).toFixed(2) : 
                                        '0.00'

                                    return (
                                        <tr key={a._id}>
                                            <td>{a.name}</td>
                                            <td>{a.total_not_contacted}</td>
                                            <td>
                                                {a.avg_time_till_contacted ? 
                                                    secondsToTime(a.avg_time_till_contacted) : 
                                                    'N/A'}
                                            </td>
                                            <td className="text-right">
                                                <span className="font-weight-bold">{percent}%</span>
                                            </td>
                                        </tr>
                                    )
                                })}

                                <tr className="text-dark font-weight-bold bg-white">
                                    <td>Total</td>
                                    <td>{totals.not_contacted}</td>
                                    <td>-</td>
                                    <td className="text-right font-weight-bold">{totals.not_contacted ? '100%' : 'N/A'}</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>

                    <div className="table-responsive bg-white" style={{borderTop: 'solid 5px #eee'}}>
                        <table className="table">
                            
                            <thead>
                                <tr>
                                    <th style={{width: '50%'}}>Potential</th>
                                    <th style={{width: '15%'}}>Total Leads</th>
                                    <th style={{width: '20%'}}>AVG Time Till Set</th>
                                    <th style={{width: '15%'}} className="text-right">% of lead type</th>
                                </tr>
                            </thead>

                            <tbody className="bg-secondary">
                                {sources.map(lead_source => {

                                    const a = lead_source.analysis
                                    const percent = a.total_potential && totals.potential ? 
                                        ((a.total_potential / totals.potential) * 100).toFixed(2) : 
                                        '0.00'

                                    return (
                                        <tr key={a._id}>
                                            <td>{a.name}</td>
                                            <td>{a.total_potential}</td>
                                            <td>
                                                {a.avg_time_till_potential ? 
                                                    secondsToTime(a.avg_time_till_potential) : 
                                                    'N/A'}
                                            </td>
                                            <td className="text-right">
                                                <span className="font-weight-bold">{percent}%</span>
                                            </td>
                                        </tr>
                                    )
                                })}

                                <tr className="text-dark font-weight-bold bg-white">
                                    <td>Total</td>
                                    <td>{totals.potential}</td>
                                    <td>-</td>
                                    <td className="text-right font-weight-bold">{totals.potential ? '100%' : 'N/A'}</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>

                    <div className="table-responsive bg-white" style={{borderTop: 'solid 5px #eee'}}>
                        <table className="table">
                            
                            <thead>
                                <tr>
                                    <th style={{width: '50%'}}>Hire Later</th>
                                    <th style={{width: '15%'}}>Total Leads</th>
                                    <th style={{width: '20%'}}>AVG Time Till Set</th>
                                    <th style={{width: '15%'}} className="text-right">% of lead type</th>
                                </tr>
                            </thead>

                            <tbody className="bg-secondary">
                                {sources.map(lead_source => {

                                    const a = lead_source.analysis
                                    const percent = a.total_hire_later && totals.hire_later ? 
                                        ((a.total_hire_later / totals.hire_later) * 100).toFixed(2) : 
                                        '0.00'

                                    return (
                                        <tr key={a._id}>
                                            <td>{a.name}</td>
                                            <td>{a.total_hire_later}</td>
                                            <td>
                                                {a.avg_time_till_hire_later ? 
                                                    secondsToTime(a.avg_time_till_hire_later) : 
                                                    'N/A'}
                                            </td>
                                            <td className="text-right">
                                                <span className="font-weight-bold">{percent}%</span>
                                            </td>
                                        </tr>
                                    )
                                })}

                                <tr className="text-dark font-weight-bold bg-white">
                                    <td>Total</td>
                                    <td>{totals.hire_later}</td>
                                    <td>-</td>
                                    <td className="text-right font-weight-bold">{totals.hire_later ? '100%' : 'N/A'}</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>

                    <div className="table-responsive bg-white" style={{borderTop: 'solid 5px #eee'}}>
                        <table className="table">
                            
                            <thead>
                                <tr>
                                    <th style={{width: '50%'}}>Hired</th>
                                    <th style={{width: '15%'}}>Total Leads</th>
                                    <th style={{width: '20%'}}>AVG Time Till Set</th>
                                    <th style={{width: '15%'}} className="text-right">% of lead type</th>
                                </tr>
                            </thead>

                            <tbody className="bg-secondary">
                                {sources.map(lead_source => {

                                    const a = lead_source.analysis
                                    const percent = a.total_hired && totals.hired ? 
                                        ((a.total_hired / totals.hired) * 100).toFixed(2) : 
                                        '0.00'

                                    return (
                                        <tr key={a._id}>
                                            <td>{a.name}</td>
                                            <td>{a.total_hired}</td>
                                            <td>
                                                {a.avg_time_till_hired ? 
                                                    secondsToTime(a.avg_time_till_hired) : 
                                                    'N/A'}
                                            </td>
                                            <td className="text-right">
                                                <span className="font-weight-bold">{percent}%</span>
                                            </td>
                                        </tr>
                                    )
                                })}

                                <tr className="text-dark font-weight-bold bg-white">
                                    <td>Total</td>
                                    <td>{totals.hired}</td>
                                    <td>-</td>
                                    <td className="text-right font-weight-bold">{totals.hired ? '100%' : 'N/A'}</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>

                    <div className="table-responsive bg-white" style={{borderTop: 'solid 5px #eee'}}>
                        <table className="table">
                            
                            <thead>
                                <tr>
                                    <th style={{width: '50%'}}>Rejected Us</th>
                                    <th style={{width: '15%'}}>Total Leads</th>
                                    <th style={{width: '20%'}}>AVG Time Till Set</th>
                                    <th style={{width: '15%'}} className="text-right">% of lead type</th>
                                </tr>
                            </thead>

                            <tbody className="bg-secondary">
                                {sources.map(lead_source => {

                                    const a = lead_source.analysis
                                    const percent = a.total_rejected_us && totals.rejected_us ? 
                                        ((a.total_rejected_us / totals.rejected_us) * 100).toFixed(2) : 
                                        '0.00'

                                    return (
                                        <tr key={a._id}>
                                            <td>{a.name}</td>
                                            <td>{a.total_rejected_us}</td>
                                            <td>
                                                {a.avg_time_till_rejected_us ? 
                                                    secondsToTime(a.avg_time_till_rejected_us) : 
                                                    'N/A'}
                                            </td>
                                            <td className="text-right">
                                                <span className="font-weight-bold">{percent}%</span>
                                            </td>
                                        </tr>
                                    )
                                })}

                                <tr className="text-dark font-weight-bold bg-white">
                                    <td>Total</td>
                                    <td>{totals.rejected_us}</td>
                                    <td>-</td>
                                    <td className="text-right font-weight-bold">{totals.rejected_us ? '100%' : 'N/A'}</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>

                    <div className="table-responsive bg-white" style={{borderTop: 'solid 5px #eee'}}>
                        <table className="table">
                            
                            <thead>
                                <tr>
                                    <th style={{width: '50%'}}>Rejected Client</th>
                                    <th style={{width: '15%'}}>Total Leads</th>
                                    <th style={{width: '20%'}}>AVG Time Till Set</th>
                                    <th style={{width: '15%'}} className="text-right">% of lead type</th>
                                </tr>
                            </thead>

                            <tbody className="bg-secondary">
                                {sources.map(lead_source => {

                                    const a = lead_source.analysis
                                    const percent = a.total_rejected_client && totals.rejected_client ? 
                                        ((a.total_rejected_client / totals.rejected_client) * 100).toFixed(2) : 
                                        '0.00'

                                    return (
                                        <tr key={a._id}>
                                            <td>{a.name}</td>
                                            <td>{a.total_rejected_client}</td>
                                            <td>
                                                {a.avg_time_till_rejected_client ? 
                                                    secondsToTime(a.avg_time_till_rejected_client) : 
                                                    'N/A'}
                                            </td>
                                            <td className="text-right">
                                                <span className="font-weight-bold">{percent}%</span>
                                            </td>
                                        </tr>
                                    )
                                })}

                                <tr className="text-dark font-weight-bold bg-white">
                                    <td>Total</td>
                                    <td>{totals.rejected_client}</td>
                                    <td>-</td>
                                    <td className="text-right font-weight-bold">{totals.rejected_client ? '100%' : 'N/A'}</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>

               
                </Card>

        

            </Container>

            </>
        );
    }
}


const mapStateToProps = state => {
    return {
        lead_sources            : state.lead_sources.lead_sources,
    };
};

export default connect(mapStateToProps, '')(LeadSourcesComparison);
