import store from 'store';
import * as privileges from '_settings/privileges'

const findPrivilege = (privilege_name, viewing_user = store.getState().auth.viewing_user) => {

    if(viewing_user) {

        const priv = viewing_user.privileges;
        if(priv) return priv.includes(privileges[privilege_name]) ? true : false

    }

    return false;

}

export const canViewAdminDashboard            = () => findPrivilege(privileges.can_view_admin_dashboard);
export const canUpdateContacts                = () => findPrivilege(privileges.can_update_contacts);
export const canDeleteContacts                = () => findPrivilege(privileges.can_delete_contacts);
export const canModerateAppointments          = () => findPrivilege(privileges.can_moderate_appointments);
export const canModerateDivisions             = () => findPrivilege(privileges.can_moderate_divisions);
export const canModerateCaseTemplates         = () => findPrivilege(privileges.can_moderate_case_templates);
export const canModerateCaseAlertTemplates    = () => findPrivilege(privileges.can_moderate_case_alert_templates);
export const canModerateWorkflows             = () => findPrivilege(privileges.can_moderate_workflows);
export const canModerateLocations             = () => findPrivilege(privileges.can_moderate_locations);
export const canViewCases                     = () => findPrivilege(privileges.can_view_cases);
export const canDeleteCases                   = () => findPrivilege(privileges.can_delete_cases);
export const canUpdateCases                   = () => findPrivilege(privileges.can_update_cases);
export const canModerateStaff                 = () => findPrivilege(privileges.can_moderate_staff);
export const canModerateTemplateDocs          = () => findPrivilege(privileges.can_moderate_template_docs);
export const developer                        = () => findPrivilege(privileges.developer);
export const canModerateArchives              = () => findPrivilege(privileges.can_moderate_archives);
export const canModerateOffices               = () => findPrivilege(privileges.can_moderate_offices);
export const canScreenWatch                   = () => findPrivilege(privileges.can_screen_watch);
export const canModerateAutomations           = () => findPrivilege(privileges.can_moderate_automations);
export const canModerateAnalytics             = () => findPrivilege(privileges.can_moderate_analytics);
export const canModerateAppointmentTemplates  = () => findPrivilege(privileges.can_moderate_appointment_templates);
export const canModerateMissingDocs           = () => findPrivilege(privileges.can_moderate_missing_docs);
export const canModerateUnfinishedTasks       = () => findPrivilege(privileges.can_moderate_all_unfinished_tasks);
export const canModerateTags                  = () => findPrivilege(privileges.can_moderate_tags);
export const canModerateForms                 = () => findPrivilege(privileges.can_moderate_forms);
export const canModerateCompanySettings       = () => findPrivilege(privileges.can_moderate_company_settings);
export const canUserAllEmailAddresses         = () => findPrivilege(privileges.can_view_all_email_addresses);
export const canViewAdminCommunication        = () => findPrivilege(privileges.can_view_admin_communication);
export const canViewAdminCallCenter           = () => findPrivilege(privileges.can_view_admin_call_center);
export const callsNewContacts                 = () => findPrivilege(privileges.calls_new_contacts);
export const callsBusiness                    = () => findPrivilege(privileges.calls_business);
export const callAppointments                 = () => findPrivilege(privileges.calls_appointments);
export const callsContacts                    = () => findPrivilege(privileges.calls_contacts);
export const canCallOnMobile                  = () => findPrivilege(privileges.can_call_on_mobile);
export const canJumpSteps                     = () => findPrivilege(privileges.can_jump_steps);

export const canGiveAttorneyConsultations     = () => findPrivilege(privileges.can_give_attorney_consultation);
export const canConsult1To10                  = () => findPrivilege(privileges.can_consult_1_10);
export const canConsult11To20                 = () => findPrivilege(privileges.can_consult_11_20);
export const canConsult21To30                 = () => findPrivilege(privileges.can_consult_21_30);
export const canConsult31To50                 = () => findPrivilege(privileges.can_consult_31_50);
export const canConsult51To100                = () => findPrivilege(privileges.can_consult_51_100);
export const canConsult100Plus                = () => findPrivilege(privileges.can_consult_100_plus);
export const notGeneralConsultant                = () => findPrivilege(privileges.not_general_consultant);

export const canSeeLeadSources                = () => findPrivilege(privileges.can_see_lead_sources);
export const canEditCaseDetails                = () => findPrivilege(privileges.can_edit_case_details);
export const canEditCasePayments                = () => findPrivilege(privileges.can_edit_case_payments);
export const canViewSources                = () => findPrivilege(privileges.can_view_sources);

export const vanViewAllCases                = () => findPrivilege(privileges.can_view_all_cases);
