/*
Documentation

This components renders the routing when updating a workflow
it shows options to 1) update roles 2) update the workflow 3) update workflow documents 4) update settings

*/

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardFooter, CardTitle, Col, Row } from "reactstrap";
import PropTypes from 'prop-types'
import _workflows from '_functions/workflows'
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';

class Router extends Component {

    state = {
        shouldRedirect: false
    }

    onCopy = async () => {

        toggleStandardLoader(true)

        const copied = await _workflows.copy(this.props.workflow._id)
        if(copied.success) {
            toggleAlertBS(false, 'Workflow Copied Successfully')
            this.setState({shouldRedirect: '/core/workflows/all'})
        }

        toggleStandardLoader(false)

    }

    render() {

        const { workflow } = this.props

        if(this.state.shouldRedirect) return <Redirect to={this.state.shouldRedirect} />

        return (

        <>

            <Card className="card-color card-warning">

                <CardHeader>
                    <CardTitle className="mb-0 mt-2 float-left">Workflow: {workflow.type}</CardTitle>
                </CardHeader>

                <CardBody>

                    <p className="mb-0 text-sm">
                        <span className="text-muted" style={{display: 'inline-block', width: 130}}>
                            Total User Roles:
                        </span>
                        {workflow.roles.length}
                    </p>

                    <p className="mb-0 text-sm">
                        <span className="text-muted" style={{display: 'inline-block', width: 130}}>
                            Status:
                        </span>
                        {workflow.active ? <b className="text-success">Active</b> : <b className="text-danger">Inactive</b>}
                    </p>

                </CardBody>

                <CardFooter className="text-right">
                    <button onClick={this.onCopy} className="btn btn-outline-warning">Copy Workflow</button>
                </CardFooter>

            </Card>

            <Row>

                <Col  md={3}>
                    <Card
                        id="worfklow-router-roles"
                        onClick={() => this.props.setView('roles')}
                        className="cursor-pointer text-center bg-gradient-success"
                    >

                        <CardHeader className="bg-transparent">
                            <CardTitle className="text-white">Workflow</CardTitle>
                        </CardHeader>

                        <CardBody>
                            <i className="text-white display-1 fas fa-users" />
                            <h2 className="text-white">Roles</h2>
                        </CardBody>

                    </Card>
                </Col>

                <Col  md={3}>
                    <Card
                        id="worfklow-router-automations"
                        onClick={() => this.props.setView('automations')}
                        className="cursor-pointer text-center bg-gradient-primary"
                    >

                        <CardHeader className="bg-transparent">
                            <CardTitle className="text-white">Workflow</CardTitle>
                        </CardHeader>

                        <CardBody>
                            <i className="text-white display-1 fas fa-route" />
                            <h2 className="text-white">Steps/Tasks</h2>
                        </CardBody>

                    </Card>
                </Col>

                <Col  md={3}>
                    <Card
                        id="worfklow-router-documents"
                        className="cursor-pointer text-center bg-gradient-danger"
                        onClick={() => this.props.setView('documents')}
                    >

                        <CardHeader className="bg-transparent">
                            <CardTitle className="text-white">Workflow</CardTitle>
                        </CardHeader>

                        <CardBody>
                            <i className="text-white display-1 fas fa-file" />
                            <h2 className="text-white">Documents</h2>
                        </CardBody>

                    </Card>
                </Col>

                <Col  md={3}>
                    <Card
                        id="worfklow-router-settings"
                        className="cursor-pointer text-center bg-gradient-warning"
                        onClick={() => this.props.setView('settings')}
                    >

                        <CardHeader className="bg-transparent">
                            <CardTitle className="text-white">Workflow</CardTitle>
                        </CardHeader>

                        <CardBody>
                            <i className="text-white display-1 fas fa-cogs" />
                            <h2 className="text-white">Settings</h2>
                        </CardBody>

                    </Card>
                </Col>

            </Row>

        </>

        )

    }

}

Router.propTypes = {
    workflow: PropTypes.object.isRequired,
    setView: PropTypes.func.isRequired,
}

export default Router
