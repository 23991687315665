/*
Documentation

not sure if this page is actually in use.

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Container, Card, CardHeader, CardTitle,  CardBody} from 'reactstrap';

import Circle from 'components/markup/loading/Circle';
import renderName from 'utils/renderName'
import paginationFactory from "react-bootstrap-table2-paginator";
import _automations from '_functions/automations';
import moment from 'moment';

const { SearchBar } = Search;

class AutomationsContact extends React.Component {

    state = {
        showAddModal: false,
        automations_assigned: null,
        automation: null
    }

    columns = [
        {
            dataField: "name",
            text: "Name",
            formatter: (cell, row) => <span className="text-capitalize font-weight-bold">{renderName(row.contact)}</span>
        },
        {
            dataField: "automation",
            text: "Automation",
            formatter: (cell, row) => row.automation ? row.automation.name : 'Not Found'
        },
        {
            dataField: "next_run_date",
            text: "Next Run Date",
            formatter: (cell, row) => moment.unix(row.next_run_date).format('MMM Do, YYYY - h:mm A')
        },
        {
            dataField: "created_at",
            text: "Next Sequence Task",
            formatter: (cell, row) => row.task_to_run ? row.task_to_run.order : 'N/A'
        },
        {
            dataField: "_id",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <i className="fas fa-trash text-danger mr-3 cursor-pointer" onClick={() => this.onRemove(row)} />
                    </div>
                )
            }
        },
    ]

    pagination = paginationFactory({
        page: 1,
        sizePerPage: 25,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: true,
        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        value={currSizePerPage}
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    toggleAddModal = () => this.setState({showAddModal: !this.state.showAddModal, hasContact: false})

    onRemove = async (automation_assigned) => {

        await _automations.assigned.delete(automation_assigned._id)
        this.fetchData()

    }

    onClear = async () => {

        const { automations_assigned } = this.state

        let counter = 0
        if(automations_assigned && automations_assigned.length ) {

            automations_assigned.forEach(async automation => {

                await _automations.assigned.delete(automation._id)

                counter++
                if(counter === automations_assigned.length) this.fetchData()


            })

        }

    }

    fetchData = async () => {

        const contact_id = this.props.match.params._id

        const automations_assigned = await _automations.assigned.all(`?filter=contact__${contact_id}&show_all=true&populate=automation&sort=next_run_date__asc`)
        if(automations_assigned.success) this.setState({automations_assigned: automations_assigned.data})

    }

    componentDidMount = async () => this.fetchData()

    render() {

        const { automations_assigned } = this.state

        return (
        <>

            <Helmet>
                <title>{`Contacts In Automation`}</title>
                <meta name="description" content="Tags" />
            </Helmet>

            <Container fluid >

                <DashHeaderOpen
                    title={<span>Contacts In Automation</span>}
                    actionComponent={(
                        <>
                        <button onClick={this.onClear} className="btn btn-danger">
                            <i className="fas fa-exclamation-triangle mr-2" /> Cancel Contact Automations
                        </button>

                        </>
                    )}
                />

                <Card className="my-5">

                    <CardHeader>
                        <CardTitle className="mb-0">Contact Automations</CardTitle>
                    </CardHeader>

                    {automations_assigned ? (
                        <ToolkitProvider
                            data={automations_assigned}
                            keyField="_id"
                            columns={this.columns}
                            search
                        >
                            {props => (
                                <>
                                <div
                                    id="datatable-basic_filter"
                                    className="dataTables_filter px-4 pb pt-3 pb-2"
                                    style={{width: '100%'}}
                                >
                                    <label>
                                        Search:
                                        <SearchBar
                                            className="form-control-sm"
                                            placeholder="Name, Email, Phone"
                                            {...props.searchProps}
                                        />
                                    </label>
                                </div>
                                <div className=" table-responsive table-vertical-align table-not-fixed">
                                    <BootstrapTable
                                        pagination={this.pagination}
                                        {...props.baseProps}
                                        bootstrap4={true}
                                        bordered={false}
                                    />
                                </div>
                                </>
                            )}
                        </ToolkitProvider>
                    ) : (
                        <CardBody>
                            <Circle />
                        </CardBody>
                    )}

                </Card>

            </Container>

            </>
        );
    }
}

const mapStateToProps = state => {

    return {
        automations: state.automations.automations,
    };
};

export default connect(mapStateToProps, '')(AutomationsContact);
