import { useCallback, useState } from 'react'
import { connect } from 'react-redux';

import { Modal, FormGroup, Input } from "reactstrap";
import { toggleAlertBS } from 'store/functions/system/system';

import { toggleStandardLoader } from 'store/functions/system/system';

import _flow_tasks from '_functions/flow_tasks'
import { capitalize } from 'utils/text'

const FlowsEditAddTask = ({showModal, toggleModal, fetchTasks, tasks, taskToDelete, getName}) => {

    const [migrateTo, setMigrateTo] = useState('false')

    const onSave = useCallback(async (state) => {

        toggleStandardLoader(true);
        const data = await _flow_tasks.delete(taskToDelete._id, {
            migrate_to: migrateTo && migrateTo !== 'false' ? migrateTo : undefined
        })
        toggleStandardLoader(false);

        if(data.success) {
            fetchTasks()
            toggleModal();
        } else {
            toggleAlertBS(true, 'Please try again.')
        }


    }, [fetchTasks, migrateTo, taskToDelete._id, toggleModal])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >

            <div className="modal-header">
                <h5 className="modal-title">Create A Task</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body bg-secondary">
                <p className="text-sm">Are you sure you wish to delete the following task:</p>
                <p className="text-sm text-capitalize mb-0 font-weight-bold">Type: {taskToDelete.type}</p>
                <p className="text-sm mb-0">{getName(taskToDelete)}</p>
            </div>
          
            <div className="modal-body border-top">
                <FormGroup>
                    <label className="form-control-label">If you wish you may move any running flows currently assigned to this task to another task within this automation. If you chose not to move any currently assigned flows, all assigned flows on this task will be deleted.</label>

                    <Input
                        type="select"
                        value={migrateTo}
                        onChange={(e) => setMigrateTo(e.target.value)}
                    >
                        <option value="false">DO NOTE MOVE ASSIGNED FLOWS</option>
                        {tasks.map(t => (
                            t._id === taskToDelete._id ? null :
                            <option value={t._id} key={t._id}>Move To: {capitalize(t.type)} - {getName(t)}</option>
                        ))}
                    </Input>
                </FormGroup>
            </div>

            <div className="modal-footer text-right">
                <button className="btn btn-neutral" onClick={toggleModal}>
                    <i className="fas fa-arrow-left mr-2" />
                    Back
                </button>
                <button className="btn btn-danger" onClick={onSave}>
                    <i className="fas fa-delete mr-2" />
                    Delete
                </button>
            </div>

        </Modal>

    )

}

const mapStateToProps = state => {
    return {
        template_emails: state.template_emails.template_emails,
        template_texts: state.template_texts.template_texts,
    };
};

export default connect(mapStateToProps, '')(FlowsEditAddTask);