/*
Documentation

This file updates a case_court

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import States from 'components/markup/inputs/States';
import React from "react";
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Row, CardFooter } from "reactstrap";
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import _location_courts from '_functions/location_courts';
import { toggleAlertBS } from 'store/functions/system/system'

const required_form_fields = [
    'name',
]

class CourtsView extends React.Component {

    state = {
        location_court: {},
        canSave: true,
    }

    getCourt = () => {

        const location_courts = this.props.location_courts.location_courts

        if(location_courts && location_courts.length ) {

            // if we found the court to updated else redirect to all location_courts
            let location_court = location_courts.find(c => c._id === this.props.match.params._id)
            if(location_court) return this.setState({location_court})

        }

        this.setState({shouldRedirect: '/core/locations/courts/all'})

    }

    onUpdate = async () => {

        let newState = Object.assign({}, this.state.location_court);
        let errors = 0;

        required_form_fields.forEach((field) => {
            //check each required field
            if(newState[field]) {
                newState[field + "State"] = 'valid';
            } else {
                newState[field + "State"] = 'invalid';
                errors++
            }

        })

        this.setState({ location_court: newState, canSave: false });

        if(!errors) {

            // update the court and show success message if it suceeded
            const updated = await _location_courts.update(this.props.match.params._id, newState)
            if(updated.success) toggleAlertBS(false, 'Court Updated Successfully.')

        }

        this.setState({canSave: true})

    }

    componentDidMount = () => this.getCourt()

    render() {

        if(!this.state.location_court) { return <div> </div> }
        if(this.state.shouldRedirect) { return <Redirect to={this.state.shouldRedirect} /> }

        const location_court = this.state.location_court

        return (
            <>

            <Container fluid>

                <Helmet>
                    <title>Court View</title>
                    <meta name="description" content="Court View" />
                </Helmet>

                <DashHeaderOpen
                    title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
                    breadcrumb_1="Courts"
                    breadcrumb_2={this.state.location_court.name}
                    actionComponent={(
                        <Link to="/core/locations/courts" className="btn btn-outline-warning"><i className="fas fa-arrow-left" /> Back</Link>
                    )}
                />

                <Card  className="card-color card-warning">

                    <CardHeader>
                        <h3 className="mb-0">View Court</h3>
                    </CardHeader>

                    <CardBody>

                        <StandardFormGroup
                            obj={location_court}
                            objName="location_court"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                            field="name"
                            title="Name *"
                        />

                        <Row>

                            <Col lg="6">
                                <StandardFormGroup
                                    obj={location_court}
                                    objName="location_court"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="email"
                                    title="Email"
                                />
                            </Col>

                            <Col lg="6">
                                <StandardFormGroup
                                    obj={location_court}
                                    objName="location_court"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="phone"
                                    title="Phone"
                                    type="tel"
                                />
                            </Col>

                        </Row>

                        <StandardFormGroup
                            obj={location_court}
                            objName="location_court"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                            field="fax_number"
                            title="Fax Number"
                            type="tel"
                        />

                        <hr />

                        <Row>
                            <Col md="6">
                                <StandardFormGroup
                                    obj={location_court}
                                    objName="location_court"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="address_line_1"
                                    title="Address Line 1 "
                                />
                            </Col>
                            <Col md="6">
                                <StandardFormGroup
                                    obj={location_court}
                                    objName="location_court"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="address_line_2"
                                    title="Address Line 2 "
                                />
                            </Col>
                        </Row>

                        <Row>

                            <Col lg="4">
                                <StandardFormGroup
                                    obj={location_court}
                                    objName="location_court"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="city"
                                    title="City"
                                />
                            </Col>

                            <Col lg="4">
                                <StandardFormGroup
                                    obj={location_court}
                                    objName="location_court"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="state"
                                    title="State"
                                    type="select"
                                >
                                    <option value="">Select State</option>
                                    <States />
                                </StandardFormGroup>
                            </Col>

                            <Col lg="4">
                                <StandardFormGroup
                                    obj={location_court}
                                    objName="location_court"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="postal_code"
                                    title="Postal code "
                                />
                            </Col>

                        </Row>

                    </CardBody>

                    <CardFooter className="text-right">
                        <Button
                            color="success"
                            onClick={this.onUpdate}
                            disabled={this.state.canSave ? false : true}
                        >
                            Update Court
                        </Button>
                    </CardFooter>

                </Card>

            </Container>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        location_courts: state.location_courts
    };
};

export default connect(mapStateToProps, '')(CourtsView);
