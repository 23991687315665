/*
Documentation

*/

import React from "react";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Card, CardHeader, CardBody, CardTitle, CardFooter, FormGroup  } from "reactstrap";
import Circle from 'components/markup/loading/Circle'

import UpdateSourceModal from './UpdateSourceModal'
import { formatMoney } from 'utils/currency';

import _lead_sources from '_functions/lead_sources'
import DatePicker from 'react-datepicker';

import moment from 'moment'

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import { Link } from 'react-router-dom'
import keys from 'keys';
import { CopyToClipboard } from "react-copy-to-clipboard";

import ViewContacts from './ViewContacts'
import ViewLeads from './ViewLeads'
import ViewCases from './ViewCases'
import ViewConsultations from './ViewConsultations'
import ViewSigning from './ViewSigning'

import Pie from '../../_components/charts/Pie'
import Line from '../../_components/charts/Line'

class LeadSourcesView extends React.Component {

    state = {
        data          : null,
        lead_source   : null,
        showModalLeads   : null,
        showModalCases   : null,
        showModalContacts: false,
        showModalConsultations: false,
        showModalSigning: false,
        query         : {
            start: parseInt(moment().startOf('month').format('X')),
            end: parseInt(moment().endOf('day').format('X')),
            dateStart: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            dateEnd: new Date(),
        },
    }
    fetchColorsAndBackground = () => {

        const background = [];
        const data = [];
        const labels = [];
        const cases = this.state.data.cases

        const d = [
            { background: '#9b51e0', value: cases.emailed, label: `Emailed (${((cases.emailed / cases.total) * 100).toFixed(2)}%)` },
            { background: '#f3a4b5', value: cases.callScheduled, label: `Call Scheduled (${((cases.callScheduled / cases.total) * 100).toFixed(2)}%)`, },
            { background: '#0693e3', value: cases.callConducted, label:  `Call Conducted (${((cases.callConducted / cases.total) * 100).toFixed(2)}%)`, },
            { background: '#5603ad', value: cases.sentRetainer, label: `Sent Retainer (${((cases.sentRetainer / cases.total) * 100).toFixed(2)}%)`, },
            { background: '#FF8D00', value: cases.signedRetainer, label: `Signed Retainer (${((cases.signedRetainer / cases.total) * 100).toFixed(2)}%)`, },
            { background: '#fcb900', value: cases.submitted, label: `Submitted (${((cases.submitted / cases.total) * 100).toFixed(2)}%)`, },
            { background: '#A7C81B', value: cases.signature, label: `Signature (${((cases.signature / cases.total) * 100).toFixed(2)}%)`, },
            { background: '#7bdcb5', value: cases.irs, label: `IRS (${((cases.irs / cases.total) * 100).toFixed(2)}%)`, },
            { background: '#cf2e2e', value: cases.dead, label: `Dead (${((cases.dead / cases.total) * 100).toFixed(2)}%)`, },
        ]
        d.sort((a, b) => a.value <= b.value ? -1 : 1)

        d.forEach(v => {
            background.push(v.background)
            data.push(v.value)
            labels.push(v.label)
        })

        return { background, data, labels }
    }
    
    fetchLeadSource = async () => {
        this.setState({loading: true})
        const data = await _lead_sources.bizHead({
            start: this.state.query.start,
            end: this.state.query.end,
            lead_source: this.props.match.params.lead_source_id
        })
        this.setState({loading: false})
        if(data.success) this.setState({data: data.data})
    }

    componentDidMount = async () => this.fetchLeadSource()

    toggleModalContacts = () => this.setState({showModalContacts: !this.state.showModalContacts})
    toggleModalLeads = () => this.setState({showModalLeads: !this.state.showModalLeads})
    toggleModalCases = () => this.setState({showModalCases: !this.state.showModalCases})
    toggleModalConsultations = () => this.setState({showModalConsultations: !this.state.showModalConsultations})
    toggleModalSigning = () => this.setState({showModalSigning: !this.state.showModalSigning})
    toggleModal = (lead_source) => this.setState({lead_source})

    render() {

        const { data, query, loading } = this.state;
        const source = this.props.lead_sources.find(s => s._id === this.props.match.params.lead_source_id)

        if(!data || loading) return <div className="py-6"><Circle /></div>

        const hasQuery = Object.keys(query).length
        const bookLink = `${keys.BOOK_URL}?l=${this.props.match.params.lead_source_id}`

        const casesSigned = data.cases.totalSigned ? data.cases.totalSigned : 0
        const casesTotal = data.cases.total ? data.cases.total : 0

        let breakdown = [
            data.cases.emailed,
            data.cases.callScheduled,
            data.cases.callConducted,
            data.cases.sentRetainer,
            data.cases.signedRetainer,
            data.cases.submitted,
            data.cases.signature,
            data.cases.irs,
            data.cases.dead
        ]

        breakdown = this.fetchColorsAndBackground()

        return (
            <>

            <Container fluid>

                <Helmet>
                    <title>{`Lead Source`}</title>
                    <meta name="description" content="Contacts" />
                </Helmet>

                <DashHeaderOpen
                    title={<span><i className="fas fa-headset mr-2 text-success " />Analytics</span>}
                    breadcrumb_1="Lead Sources"
                    // breadcrumb_2={name}
                    actionComponent={(
                        <Link to="/analytics/lead_sources" className="btn btn-outline-warning"><i className="fas fa-arrow-left mr-2" /> Back</Link>
                    )}
                />


                <div className="alert alert-info">
                    Lead Source Booking Link: {bookLink} 
                    <CopyToClipboard
                        text={bookLink}
                        onCopy={() => {
                            this.setState({ copiedText: true }, () => {
                                setTimeout(() => { this.setState({ copiedText: false }) }, 1000)
                            })
                        }}
                    >
                        <i className="fas fa-clipboard text-white ml-3 cursor-pointer" />
                    </CopyToClipboard>
                    {this.state.copiedText ? ' COPIED!' : ''}
                </div>

                <Row>
                    <Col lg={6}>
                        <Card className="">

                            <CardHeader>
                                <Row>
                                    <Col md={8} className="align-self-center">
                                        <CardTitle className="mb-0">Lead Source: {source ? source.name : 'Unknown Lead Source'}</CardTitle>
                                    </Col>
                                    <Col md={4} className="align-self-center text-right">
                                        <button onClick={this.toggleModal} className="btn btn-outline-info">Update Source</button>
                                    </Col>
                                </Row>
                            </CardHeader>

                            <CardBody className="py-4">

                                <Row>

                                    <Col lg={6}>
                                        <div className="date-picker-wrapper">
                                            <FormGroup>
                                                <h4>Query Start Date</h4>
                                                <DatePicker
                                                    selected={query.dateStart ? query.dateStart : undefined}
                                                    onChange={date => {
                                                        this.setState({ 
                                                            query: {
                                                                ...Object.assign({}, query), 
                                                                start: parseInt( moment(date).startOf('day').format('X')),
                                                                dateStart: date
                                                            } 
                                                        })
                                                    }}
                                                />
                                            </FormGroup>
                                        </div>
                                    </Col>

                                    <Col lg={6}>
                                        <FormGroup>
                                            <h4>Query End Date</h4>
                                            <DatePicker
                                                selected={query.dateEnd ? query.dateEnd : undefined}
                                                onChange={date => {
                                                    this.setState({ 
                                                        query: {
                                                            ...Object.assign({}, query), 
                                                            end: parseInt( moment(date).endOf('day').format('X')),
                                                            dateEnd: date
                                                        } 
                                                    })
                                                }}                                    
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                            </CardBody>

                            <CardFooter className=" py-4">
                                <Row>
                                    <Col md={10}>
                                        <p className="text-sm mb-0"><i className="fas fa-info-circle text-info-original " /> It is recommended to run queries at 1 month intervals or less.</p>
                                    </Col>
                                    <Col md={2  } className="text-right">
                                        <button onClick={this.fetchLeadSource} disabled={!hasQuery} className="btn btn-success">Run</button>
                                    </Col>
                                </Row>

                            </CardFooter>

                        </Card>
                    </Col>
                    <Col lg={6}>
                         <Row>
                            <Col md={4}>
                                <Card className="text-center" onClick={() => this.toggleModalLeads()}>
                                    <CardHeader className="py-4">
                                        <CardTitle className="mb-0">Leads</CardTitle>
                                    </CardHeader>
                                    <CardFooter className="py-3">
                                        {data.leads}
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col md={4}>
                                <Card className="text-center" onClick={() => this.toggleModalContacts()}>
                                    <CardHeader className="py-4">
                                        <CardTitle className="mb-0">Contacts</CardTitle>
                                    </CardHeader>
                                    <CardFooter className="py-3">
                                        {data.contacts}
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col md={4}>
                                <Card className="text-center" onClick={() => this.toggleModalCases()}>
                                    <CardHeader className="py-4">
                                        <CardTitle className="mb-0">Cases</CardTitle>
                                    </CardHeader>
                                    <CardFooter className="py-3">
                                        {data.cases.total ? data.cases.total : 0}
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col md={6}>
                                <Card className="text-center" onClick={() => this.toggleModalConsultations()}>
                                    <CardHeader className="py-4">
                                        <CardTitle className="mb-0">Consultations</CardTitle>
                                    </CardHeader>
                                    <CardFooter className="py-3">
                                        {data.consultations.total}
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col md={6}>
                                <Card className="text-center" onClick={() => this.toggleModalSigning()}>
                                    <CardHeader className="py-4">
                                        <CardTitle className="mb-0">Signatures Sent</CardTitle>
                                    </CardHeader>
                                    <CardFooter className="py-3">
                                        {data.signing.outcomes.total ? data.signing.outcomes.total : 0}
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col md={4}>
                        <Pie
                            title={`Signed Cases (${((casesSigned /casesTotal) * 100).toFixed(2)}%)`}
                            data={{
                                labels: ['Signed', 'Not Signed'],
                                datasets: [
                                    { 
                                        backgroundColor: ['#aeca3a', '#cf2e2e'],
                                        data: [casesSigned, casesTotal - casesSigned]
                                    },
                                    
                                ]
                            }}
                            description="The total number of cases from this lead source where the case is on a step in the following categories: Signed Retainer, Submitted, With IRS, Awaiting Signature / all cases not in these categories"
                            />
                    </Col>
                    <Col md={4}>
                        <Pie
                            title={`Signed Docs (${((data.signing.outcomes.signed / data.signing.outcomes.total) * 100).toFixed(2)}%)`}
                            data={{
                                labels: ['Signed', 'Unsigned'],
                                datasets: [
                                    { 
                                        backgroundColor: ['#aeca3a', '#cf2e2e'],
                                        data: [data.signing.outcomes.signed, data.signing.outcomes.total - data.signing.outcomes.signed]
                                    },
                                    
                                ]
                            }}
                            description="The total number of documents sent out for signature that have been signed / all document that have been sent out for signature from this lead source"

                        />
                    </Col>
                    <Col md={4}>
                        <Pie
                            title={`Consultations (${((data.consultations.retainerSent / data.consultations.total) * 100).toFixed(2)}%)`}
                            data={{
                                labels: ['Sent Retainer', 'All'],
                                datasets: [
                                    { 
                                        backgroundColor: ['#aeca3a', '#cf2e2e'],
                                        data: [data.consultations.retainerSent, data.consultations.total - data.consultations.retainerSent]
                                    },
                                    
                                ]
                            }}
                            description="The total number of consultations that have resulted in the disposition of Sent Retainer / the total number of consultations for this lead source"

                        />
                    </Col>
                  
                    <Col md={6}>
                        <Line
                            title={`Cases`}      
                            classNames="chart-time-series"                
                            data={data.caseTimeSeries}
                            description="The total number of cases that have been created under this lead source"

                        />
                    </Col>
                    <Col md={6}>
                        <Line
                            title={`Consultations`}      
                            classNames="chart-time-series"                
                            data={data.consultationTimeSeries}
                            description="The total number of consultations that have been created under this lead source"

                        />
                    </Col>

                    <Col md={12}>
                        <Pie
                            title={`Step Breakdown`}      
                            classNames="chart-large"                
                            data={{
                                labels: breakdown.labels,
                                datasets: [
                                    { 

                                        backgroundColor: breakdown.background,
                                        data: breakdown.data
                                    },
                                    
                                ]
                            }}
                            description="Where all cases in this time period currently sit (based on current step) for this lead source"

                        />
                    </Col>

                </Row>

               

                <Row>

                    <Col lg={6}>

                        <Card>
                            <CardHeader>
                                <CardTitle className="mb-0">Data</CardTitle>       
                            </CardHeader>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th className="text-right">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Total Cases</td>
                                            <td className="text-right">
                                                {data.cases.total}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Total Signed</td>
                                            <td className="text-right">{data.cases.totalSigned}</td>
                                        </tr>
                                        <tr>
                                            <td>Close Rate</td>
                                            <td className="text-right">{data.cases.closeRate}</td>
                                        </tr>
                                        <tr>
                                            <td>Emailed</td>
                                            <td className="text-right">
                                                {((data.cases.emailed / data.cases.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                                <span style={{display: 'inline-block', width: 30}}>
                                                {data.cases.emailed}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Call Scheduled</td>
                                            <td className="text-right">
                                                {((data.cases.callScheduled / data.cases.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                                <span style={{display: 'inline-block', width: 30}}>
                                                {data.cases.callScheduled}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Call Conducted</td>
                                            <td className="text-right">
                                                {((data.cases.callConducted / data.cases.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                                <span style={{display: 'inline-block', width: 30}}>
                                                {data.cases.callConducted}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Sent Retainer</td>
                                            <td className="text-right">
                                                {((data.cases.sentRetainer / data.cases.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                                <span style={{display: 'inline-block', width: 30}}>
                                                {data.cases.sentRetainer}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Signed Retainer</td>
                                            <td className="text-right">
                                                {((data.cases.signedRetainer / data.cases.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                                <span style={{display: 'inline-block', width: 30}}>
                                                {data.cases.signedRetainer}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Submitted</td>
                                            <td className="text-right">
                                                {((data.cases.submitted / data.cases.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                                <span style={{display: 'inline-block', width: 30}}>
                                                {data.cases.submitted}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Signature</td>
                                            <td className="text-right">
                                                {((data.cases.signature / data.cases.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                                <span style={{display: 'inline-block', width: 30}}>
                                                {data.cases.signature}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>IRS</td>
                                            <td className="text-right">
                                                {((data.cases.irs / data.cases.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                                <span style={{display: 'inline-block', width: 30}}>
                                                {data.cases.irs}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Dead</td>
                                            <td className="text-right">
                                                {((data.cases.dead / data.cases.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                                <span style={{display: 'inline-block', width: 30}}>
                                                {data.cases.dead}
                                                </span>
                                            </td>
                                        </tr>

                                    
                                    </tbody>
                                </table>
                            </div>
                        
                        </Card>

                    </Col>

                    <Col lg={6}>

                        <Card>
                            <CardHeader>
                                <CardTitle className="mb-0">Aggregations</CardTitle>       
                            </CardHeader>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th className="text-right">Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Current Net-Net Fees</td>
                                            <td className="text-right">{formatMoney(data.cases.netFees)}</td>
                                        </tr>
                                        <tr>
                                            <td>Anticipated Net-Net Fees</td>
                                            <td className="text-right">{formatMoney(data.cases.anticipatedNetFees)}</td>
                                        </tr>
                                        <tr>
                                            <td>Avg Net Fee Per Case</td>
                                            <td className="text-right">{formatMoney(data.cases.avgNetPerCase)}</td>
                                        </tr>
                                        <tr>
                                            <td>Avg ERTC</td>
                                            <td className="text-right">{formatMoney(data.cases.avgERTC)}</td>
                                        </tr>
                                        <tr>
                                            <td>Acceptable Price / Lead</td>
                                            <td className="text-right">{formatMoney(data.cases.acceptablePricePerLead)}</td>
                                        </tr>
                                        <tr>
                                            <td>Marketing Fees</td>
                                            <td className="text-right">{formatMoney(data.cases.calculatedTotalFeeMarketing)}</td>
                                        </tr>
                                        <tr>
                                            <td>Gross Fees</td>
                                            <td className="text-right">{formatMoney(data.cases.calculatedTotalFeeBiz)}</td>
                                        </tr>
                                        <tr>
                                            <td>Anticipated Gross Fees</td>
                                            <td className="text-right">{formatMoney(data.cases.anticipatedGrossFees)}</td>
                                        </tr>
                                        <tr>
                                            <td>Gross Margin</td>
                                            <td className="text-right">{data.cases.grossMargin}</td>
                                        </tr>
                                        <tr>
                                            <td>Avg Marketing Fee / Case</td>
                                            <td className="text-right">{formatMoney(data.cases.avgMarketingPerCase)}</td>
                                        </tr>
                                        <tr>
                                            <td>Anticipated Marketing Fees</td>
                                            <td className="text-right">{formatMoney(data.cases.anticipatedMarketingFees)}</td>
                                        </tr>
                                        <tr>
                                            <td>Avg Gross Fee</td>
                                            <td className="text-right">{formatMoney(data.cases.avgGrossFee)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Card>

                    </Col>

                </Row>

                <Card>
                    <CardHeader>
                        <CardTitle className="mb-0">
                            Create Leads Via API
                        </CardTitle>
                    </CardHeader>
                    <CardBody>
                        {this.state.showAPI ? (
<div> 
<pre>
{`POST ${keys.API_URL}/api/v1/core/leads

data: {

    // required
    api_key                   : "${source.api_key}",
    api_secret                : "${source.api_secret}",

    // optional
    "given_name"              : "John",
    "family_name"             : "Maher",
    
    "email"                   : "systems@bizheadlaw.com",
    "emails_all"              : "systems@bizheadlaw.com,systems_2@bizheadlaw.com",
    "phone"                   : "+19738975091",
    "phone_2"                 : "+19738975092",
    
    "company_name"            : "Johns Account Firm",
    "company_industry"        : "accounting",
    "company_type"            : "Corporation",
    
    "company_address"         : "100 Manhattan Lane",
    "company_city"            : "Manhattan",
    "company_state"           : "New York",
    "company_zip"             : "12345",

    "company_linkedin_url"    : "https://linkedin.com",
    "company_facebook_url"    : "https://facebook.com",
    "company_twitter_url"     : "https://twitter.com",
    "company_website"         : "https://website.com",
    
    "ppp_approval_date"       : "10/19/2022",
    "ppp_forgiveness_date"    : "10/19/2022",
    "ppp_approval_amount"     : "1000.00",
    "ppp_loan_number"         : "abc123",
    "ppp_lender"              : "Acme",
    "ppp_forgiveness_amount"  : "1000.00",
    
    "jobs_reported"           : "3",
    "job_title"               : "CEO"
}`}
</pre>                               
</div>
                        ) : (
                            <button onClick={() => this.setState({showAPI: true})} className="btn btn-success">Click To Show</button>
                        )}
                    </CardBody>
                </Card>

                <Card>
                    <CardHeader>
                        <CardTitle className="mb-0">
                            Show Embed Instructions
                        </CardTitle>
                    </CardHeader>
                    <CardBody>
                        {this.state.showEmbed ? (
<div> 
<pre>
{`
<iframe 
    src="${bookLink}" 
    style="border: solid 1px #ddd; width: 380px; max-width: 100%; height: 600px; border-radius: 6px;"
/>

To change the main color add in the param &color={hex color code} 
** note do not include the # in the url

To change the master color theme from dark to light include &theme=light

To change the text that says "Book A Call With Biz Head Law" include &title=Book a call now

Full Example using all parameters

<iframe 
    src="${bookLink}&color=cf2e2e&theme=light&title=Book a call with my company now" 
    style="border: solid 1px #ddd; width: 380px; max-width: 100%; height: 600px; border-radius: 6px;"
/>
`}
</pre>                               
</div>
                        ) : (
                            <button onClick={() => this.setState({showEmbed: true})} className="btn btn-success">Click To Show</button>
                        )}
                    </CardBody>
                </Card>



                <UpdateSourceModal 
                    showModal={this.state.lead_source}
                    toggleModal={() => this.toggleModal(null)}
                    onSuccess={this.fetchLeadSource}
                    lead_source={this.props.match.params.lead_source_id}
                />

                <ViewContacts 
                    showModal={this.state.showModalContacts}
                    toggleModal={this.toggleModalContacts}
                    contacts={data.entries.lead_source_entries}
                />
                <ViewLeads 
                    showModal={this.state.showModalLeads}
                    toggleModal={this.toggleModalLeads}
                    leads={data.entries.leads}
                />
                <ViewCases
                    showModal={this.state.showModalCases}
                    toggleModal={this.toggleModalCases}
                    cases={data.entries.cases}
                />
                <ViewConsultations
                    showModal={this.state.showModalConsultations}
                    toggleModal={this.toggleModalConsultations}
                    consultations={data.entries.consultations}
                />
                <ViewSigning
                    showModal={this.state.showModalSigning}
                    toggleModal={this.toggleModalSigning}
                    docs={data.signing.docs}
                />


            </Container>


            

            </>
        );
    }
}


const mapStateToProps = state => {
    return {
        lead_sources : state.lead_sources.lead_sources,
    };
};

export default connect(mapStateToProps, '')(LeadSourcesView);
