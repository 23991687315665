import Circle from 'components/markup/loading/Circle';
import moment from 'moment';
import React from 'react';
import { Card, CardHeader, CardTitle, Row, Col } from 'reactstrap';
import renderName from 'utils/renderName';
import { capitalize } from 'utils/text';
import Bar from '../../../_components/charts/Bar';

const AppointmentsByStepResults = ({data, loading, queryInfo}) => {

    const { start } = queryInfo
    const end = 'Now';

    if(!data) return <div />;
    if(loading) return <Circle />

    const ranges = [
        { field: 'today', title: `Start: ${moment(start).format('M/DD h:mm A')} -> ${end}`},
        { field: 'week', title: `Previous 7 Days - ${moment(start).subtract(7, 'days').format('M/DD')} -> Now`},
        { field: 'month', title: `Previous 30 Days - ${moment(start).subtract(30, 'days').format('M/DD')} -> Now`},
        { field: 'quarter', title: `Previous Quarter - ${moment(start).subtract(3, 'months').format('M/DD')} -> Now`},
        { field: 'year', title: `Previous 365 Days - ${moment(start).subtract(365, 'days').format('M/DD/YYYY')} -> Now`},
    ]

    const createGraph = (range, isBar) => {

        const labels = ['Average'];

        const allData = [data[range.field].average]

        if(data[range.field].average_user_0 !== undefined) {
            labels.push(capitalize(renderName(queryInfo.user_1)))
            allData.push(data[range.field].average_user_0)
        }
        if(data[range.field].average_user_1 !== undefined) {
            labels.push(capitalize(renderName(queryInfo.user_2)))
            allData.push(data[range.field].average_user_1)
        }
        if(data[range.field].average_user_2 !== undefined) {
            labels.push(capitalize(renderName(queryInfo.user_3)))
            allData.push(data[range.field].average_user_2)
        }
        
        return {
            "labels": labels,
            "datasets": [
              {
                "label": "Appointments Till Step Move",
                "data": allData,
                "backgroundColor": [
                    '#053A53',
                    '#A7C81B',
                    '#ff3709',
                    '#f3a4b5',
                    '#f5365c',
                    '#fb6340',
                    '#ffd600',
                    '#000000'
                ]
              }
            ]
          }
    }
    
    return (

        <Row>
            {ranges.map((range, i) => (
                <Col key={i} xl={i === 4 ? 12 : 6} lg={12}>
                    <Card >

                        <CardHeader className="bg-white border-left border-right">
                            <CardTitle className="mb-0 text-center">{range.title}</CardTitle>
                        </CardHeader>

                        <Bar
                            className="mb-0 z-depth-0"
                            styles={{borderRadius: 0, border: 'none', boxShadow: 'none'}}
                            title={'Average Appointments Till Step Change'}
                            data={createGraph(range, true)}
                        />

                    </Card>
                </Col>
            ))}
        </Row>

    )
}

export default AppointmentsByStepResults;