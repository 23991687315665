/*
Documentation

This page shows a table of all forms created within the system

*/

import NotificationDelete from 'components/functional/notifications/Delete';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Container, CardTitle } from "reactstrap";

const { SearchBar } = Search;

class FormsAll extends React.Component {

    state = {}

    columns = [
        {
            dataField: "name",
            text: "Name",
            formatter: (cell, row) => (
                <Link to={`/core/forms/view/${row._id}`}> {row.name} </Link>
            )
        },
        {
            dataField: "x",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <Link to={`/core/forms/view/${row._id}`}><i className="fas fa-edit text-success mr-2"></i></Link>
                        <i onClick={() => this.setState({formToDelete: row})} className="fas fa-trash text-danger"></i>
                    </div>
                )
            }
        },
    ]

    pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: false,
        withFirstAndLast: false,
        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => false
    });

    render() {

        const forms = this.props.forms;
        const formLength = this.props.forms.forms.length;
        const formToDelete = this.state.formToDelete;

        return (
            <>

            {formToDelete && (
                <NotificationDelete
                    deletionURL={`/api/v1/core/forms/delete/${formToDelete._id}`}
                    confirmBtnText="Delete Form"
                    title={`Delete ${formToDelete.name}`}
                    text="Deleting this form will not affect any appointments or pages it was already assigned to. You will, however, no longer be able to assign actions to this form."
                    onClose={() => this.setState({formToDelete: null})}
                    onSuccess={() => {} }
                />
            )}

            <Container fluid>

                <Helmet>
                    <title>{`Forms (${formLength}) `}</title>
                    <meta name="description" content="Forms" />
                </Helmet>

                <DashHeaderOpen
                    title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
                    breadcrumb_1="Forms"
                    breadcrumb_2="All"
                    actionComponent={(
                        <Link  to="/core/forms/view/new" className="btn btn-success"><i className="fas fa-plus mr-2 " />Add New</Link>
                    )}
                />

                <Card>

                    <CardHeader>
                        <CardTitle className="mb-0">All forms</CardTitle>
                    </CardHeader>

                    {forms.total ? (

                        <ToolkitProvider
                            data={forms.forms}
                            keyField="_id"
                            columns={this.columns}
                            search
                        >
                            {props => (
                                <>
                                    <div className="dataTables_filter px-4 pb pt-3 pb-2" >
                                        <label>
                                            Search:
                                            <SearchBar
                                                className="form-control-sm"
                                                placeholder="Name, Email, Phone"
                                                {...props.searchProps}
                                            />
                                        </label>
                                    </div>

                                    <div className=" table-responsive table-vertical-align">
                                        <BootstrapTable
                                            pagination={this.pagination}
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            bordered={false}
                                        />
                                    </div>
                                </>
                            )}
                        </ToolkitProvider>

                    ) : (

                        <CardBody>

                            No forms have been created yet.

                            <Link to="/core/forms/view/new">
                                <b className="text-warning"> Click here to create one.</b>
                            </Link>

                        </CardBody>

                    )}
                </Card>

            </Container>

            </>
        );
    }
}

const mapStateToProps = state => {

    return {
        forms: state.forms
    };
};

export default connect(mapStateToProps, '')(FormsAll);

