import moment from 'moment';
import React, { useState, useCallback, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { FormGroup, Input } from 'reactstrap';

const day = 86400

const ArchkDates = ({onChange, label, defaultValue}) => {

    const [range, setRange] = useState('day');
    const [date, setDate] = useState('');
    const [dateEnd, setDateEnd] = useState('');

    const onSetRange = useCallback((range) => {
        setRange(range);
        setDate('')
        setDateEnd('')
    }, [])

    useEffect(() => {
        if(defaultValue && !date) setDate(defaultValue)
    }, [defaultValue, date])

    const renderDateRange = () => {

        if(range === 'day') return (
            <FormGroup className="pl-4">
                <label className="form-control-label">Select Day</label>
                <div className="date-picker-wrapper">
                    <DatePicker
                        withPortal={ true }
                        portalId="portal-root"
                        popperPlacement="bottom" 
                        selected={date}
                        onChange={date => {
                            const start = parseInt(moment(date).startOf('day').format('X'))
                            onChange({$gte: start, $lt: start + day})
                            setDate(date);
                        }}
                    />
                </div>
            </FormGroup>
        )
        
        if (range === 'range') return (
            <div className="pl-4">
                 <FormGroup>
                     <label className="form-control-label">Select Start</label>
                     <div className="date-picker-wrapper">
                         <DatePicker
                            withPortal={ true }
                            portalId="portal-root"
                            popperPlacement='bottom'
                            selected={date}
                            onChange={date => {
                                const start = parseInt(moment(date).startOf('day').format('X'))
                                const end = dateEnd ? parseInt(moment(dateEnd).startOf('day').format('X')) : start + day
                                onChange({$gte: start, $lt: end})
                                setDate(date);
                            }}
                         />
                     </div>
                 </FormGroup>

                 <FormGroup>
                     <label className="form-control-label">Select End</label>
                     <div className="date-picker-wrapper">
                         <DatePicker
                            withPortal={ true }
                            portalId="portal-root"
                            popperPlacement='bottom'
                            selected={dateEnd}
                            onChange={dateEnd => {
                                const end = parseInt(moment(dateEnd).endOf('day').format('X'))
                                const start = date ? parseInt(moment(date).startOf('day').format('X')) : end - day
                                onChange({$gte: start, $lt: end})
                                setDateEnd(dateEnd);
                            }}
                         />
                     </div>
                 </FormGroup>

            </div>
        )
        
        return (
            <FormGroup className="pl-4">
                <label className="form-control-label">Select Time Frame</label>

                <Input
                    type="select"
                    onChange={(e) => {

                        const end = parseInt(moment().startOf('day').format('X'))

                        let start;

                        switch (e.target.value) {
                            case "Last 24 Hours": start = end - (day * 1);  break;
                            case "Last 48 Hours": start = end - (day * 2);  break;
                            case "Last 3 Days": start = end - (day * 3);  break;
                            case "Last 7 Days": start = end - (day * 7);  break;
                            case "Last 14 Days": start = end - (day * 14);  break;
                            case "Last 21 Days": start = end - (day * 21);  break;
                            case "Last 30 Days": start = end - (day * 30);  break;
                            case "Last 60 Days": start = end - (day * 60);  break;
                            case "Last 90 Days": start = end - (day * 90);  break;
                            case "Last 120 Days": start = end - (day * 120);  break;
                            case "Last 180 Days": start = end - (day * 180);  break;

                            default: break;
                        }

                        onChange({$gte: start, $lt: end})
                    }}
                >
                    <option></option>
                    <option value="Last 24 Hours">Last 24 Hours</option>
                    <option value="Last 48 Hours">Last 48 Hours</option>
                    <option value="Last 3 Days">Last 3 Days</option>
                    <option value="Last 7 Days">Last 7 Days</option>
                    <option value="Last 14 Days">Last 14 Days</option>
                    <option value="Last 21 Days">Last 21 Days</option>
                    <option value="Last 30 Days">Last 30 Days</option>
                    <option value="Last 60 Days">Last 60 Days</option>
                    <option value="Last 90 Days">Last 90 Days</option>
                    <option value="Last 120 Days">Last 120 Days</option>
                    <option value="Last 180 Days">Last 180 Days</option>
                </Input>

            </FormGroup>
        )

    }

    const formatRange = () => {

        if(range === 'day' && date) {
            return (
                <p className="text-sm mb-2">
                    <i className="fas fa-calendar mr-2 text-warning " />
                    {moment(date).format('MMM Do')}
                </p>
            )
        } else if(range === 'range' && date && dateEnd) {
            return (
                <p className="text-sm mb-2">
                    <i className="fas fa-calendar mr-2 text-warning " />
                    {moment(date).format('MMM Do')}{' - '}
                    {moment(dateEnd).format('MMM Do')}
                </p>

            )
        }

    }

    return (

        <div>

            {formatRange()}
            <FormGroup>
                <label className="form-control-label">{label ? label : 'Date Range'}</label>
                <Input
                    type="select"
                    value={range }
                    onChange={(e) => onSetRange(e.target.value)}
                >
                    <option value="day">Day</option>
                    <option value="range">Range</option>
                    <option value="interval">Time Group</option>
                </Input>

            </FormGroup>
            {renderDateRange()}
        </div>

    )

}

export default ArchkDates;
