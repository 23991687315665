import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import NotificationDelete from 'components/functional/notifications/Delete';
import { toggleStandardLoader } from 'store/functions/system/system'

import _email_senders from '_functions/email_senders';

import Edit from './Edit';
import List from './List';

const requiredFields = [
    'email',
    'reply_to',
    'name',
    'company_name',
    'address_line_1',
    'city',
    'state',
    'postal_code',
    'phone',
    'website'
]

const EmailSenders = ({email_senders}) => {

    const [senders, setSenders] = useState(email_senders)
    const [activeSender, setActiveSender] = useState({});
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [senderToDelete, setSenderToDelete] = useState(null);
    const [err, setErr] = useState(false);

    const onSetActiveSender = (sender) => {
        setUnsavedChanges(false);
        setActiveSender(sender);
        setErr(false);
    }

    const onInputChange = (field, value) => {
        if(!unsavedChanges) setUnsavedChanges(true)
        
        const newState = JSON.parse(JSON.stringify(activeSender));
        newState[field] = value;
        setActiveSender(newState)
    }

    const onSave = async () => {


        let fieldErr = false;

        requiredFields.forEach(field => {
            if(field === 'state' && activeSender[field] === 'false') fieldErr = true;
            if(!activeSender[field]) fieldErr = true;
        })

        if(fieldErr) return setErr(`Please fill out all required sender fields.`)
        setErr(false);

        const newState = JSON.parse(JSON.stringify(senders));

        toggleStandardLoader(true)

        let action;
        if(activeSender._id) {
            action = await _email_senders.update(activeSender._id, activeSender) 
        } else {
            action = await _email_senders.create(activeSender)
        }
        
        toggleStandardLoader(false)

        if(!action.success) return setErr(action.message);

        const foundIndex = newState.findIndex(sender => sender._id === activeSender._id);

        if(foundIndex !== -1) {
            newState[foundIndex] = action.data;
        } else {
            newState.push(action.data);
        }

        onSetActiveSender(action.data);
        setSenders(newState);

    }

    const onDeleteSuccess = (data) => {
        let newState = JSON.parse(JSON.stringify(senders));
        newState = newState.filter(sender => sender._id !== data.data._id)
        setSenders(newState);
        onSetActiveSender(newState[0] ? newState[0] : {name: 'NEW SENDER'});

    }

    const onDeleteSender = () => {
        // set delete notification
        if(activeSender._id) {
            setSenderToDelete(activeSender)
        } else {
            let newState = JSON.parse(JSON.stringify(senders));
            newState = newState.filter(sender => sender.name !== 'NEW SENDER')
            setSenders(newState);
        }
    }

    const onAddSender = () => {
        const newState = JSON.parse(JSON.stringify(senders));
        newState.push({name: 'NEW SENDER'});
        onSetActiveSender({name: 'NEW SENDER'});
        setSenders(newState);
    }

    useEffect(() => {
        if(email_senders[0]) {
            setActiveSender(email_senders[0]);
        }
    }, [])


    return (

        <Container fluid>

            <Helmet>
                <title>{`Email Senders`}</title>
                <meta name="description" content="All" />
            </Helmet>

            <DashHeaderOpen
                title={<span><i className="fas fa-magic mr-2 text-success " /> Automations</span>}
                breadcrumb_1="Email Senders"
                breadcrumb_2="All"
            />

            {senderToDelete && (
                <NotificationDelete
                    deletionURL={`/api/v1/automations/email_senders/${senderToDelete._id}`}
                    confirmBtnText="Delete Sender"
                    title={<span className="text-capitalize">{`Delete Template: ${senderToDelete.name}`}</span>}
                    text="Deleting the sender will remove it from all outgoing emails. Existing automations may need to be reassigned a sender."
                    onClose={() => setSenderToDelete(null)}
                    onSuccess={onDeleteSuccess}
                />
            )}

            <Row>

                <Col lg={4}>
                    <List 
                        activeSender={activeSender}
                        senders={senders}
                        onSetActiveSender={onSetActiveSender}
                        onSave={onSave}
                        onAddSender={onAddSender}
                    />
                </Col>
                <Col lg={8}>
                    <Edit 
                        err={err}
                        unsavedChanges={unsavedChanges}
                        onInputChange={onInputChange}
                        activeSender={activeSender}
                        onSave={onSave}
                        onDeleteSender={onDeleteSender}
                    />
                </Col>

            </Row>

        </Container>

    );

}

const mapStateToProps = state => {
    return {
        email_senders: state.email_senders.email_senders,
    };
};

export default connect(mapStateToProps, '')(EmailSenders);
