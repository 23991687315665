/*
Documentation

This file creates a court

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import States from 'components/markup/inputs/States';
import React from "react";

import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Row, CardTitle, CardFooter  } from "reactstrap";
import { Link } from 'react-router-dom'
import _location_courts from '_functions/location_courts'
import { toggleAlertBS } from 'store/functions/system/system'

const required_form_fields = [
    'name',
]

class CourtsCreate extends React.Component {

    state = {
        location_court: {},
        canSave: true,
    }

    onSave = async () => {

        let newState = Object.assign({}, this.state.location_court);
        let errors = 0;

        required_form_fields.forEach((field) => {
            //check each required field
            if(newState[field]) {
                newState[field + "State"] = 'valid';
            } else {
                newState[field + "State"] = 'invalid';
                errors++
            }

        })

        this.setState({ location_court: newState, canSave: false });

        if(!errors) {

            const created = await _location_courts.create(newState)

            //if court was created successfully, clear court data and show success message for 2 seconds
            if(created.success) {

                this.setState({ location_court: {} });
                toggleAlertBS(false, 'Court Created Successfully.')

            }

        }

        this.setState({canSave: true})

    }

    render() {

        if(this.state.shouldRedirect) { return <Redirect to={this.state.shouldRedirect} /> }
        const location_court = this.state.location_court

        return (
            <>

            <Container fluid>

                <Helmet>
                    <title>Court Create</title>
                    <meta name="description" content="Cases Create" />
                </Helmet>

                <DashHeaderOpen
                    title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
                    breadcrumb_1="Courts"
                    breadcrumb_2="Create"
                    actionComponent={(
                        <Link to="/core/locations/courts" className="btn btn-outline-warning"><i className="fas fa-arrow-left" /> Back</Link>
                    )}
                />

                <Card  className="card-color card-success">

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">Create A Court</CardTitle>
                    </CardHeader>

                    <CardBody>

                        <StandardFormGroup
                            obj={location_court}
                            objName="location_court"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                            field="name"
                            title="Name *"
                        />

                        <Row>

                            <Col lg="6">
                                <StandardFormGroup
                                    obj={location_court}
                                    objName="location_court"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="email"
                                    title="Email"
                                />
                            </Col>

                            <Col lg="6">
                                <StandardFormGroup
                                    obj={location_court}
                                    objName="location_court"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="phone"
                                    title="Phone"
                                    type="tel"
                                />
                            </Col>

                        </Row>

                         <StandardFormGroup
                            obj={location_court}
                            objName="location_court"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                            field="fax_number"
                            title="Fax Number"
                            type="tel"
                        />

                        <hr />

                        <Row>
                            <Col md="6">
                                <StandardFormGroup
                                    obj={location_court}
                                    objName="location_court"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="address_line_1"
                                    title="Address Line 1 "
                                />
                            </Col>
                            <Col md="6">
                                <StandardFormGroup
                                    obj={location_court}
                                    objName="location_court"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="address_line_2"
                                    title="Address Line 2 "
                                />
                            </Col>
                        </Row>

                        <Row>

                            <Col lg="4">
                                <StandardFormGroup
                                    obj={location_court}
                                    objName="location_court"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="city"
                                    title="City"
                                />
                            </Col>

                            <Col lg="4">
                                <StandardFormGroup
                                    obj={location_court}
                                    objName="location_court"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="state"
                                    title="State"
                                    type="select"
                                >
                                    <option value="">Select State</option>
                                    <States />
                                </StandardFormGroup>
                            </Col>

                            <Col lg="4">
                                <StandardFormGroup
                                    obj={location_court}
                                    objName="location_court"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="postal_code"
                                    title="Postal code "
                                />
                            </Col>

                        </Row>

                    </CardBody>

                    <CardFooter className="text-right">
                        {this.state.createdSuccessfully ? (
                            <Button
                                color="success"
                            >
                            Successfully Created
                            </Button>
                        ) : (
                            <Button
                                color="success"
                                onClick={this.onSave}
                                disabled={this.state.canSave ? false : true}
                            >
                            Create Court
                            </Button>
                        )}
                    </CardFooter>

                </Card>

            </Container>
            </>
        );
    }
}

export default CourtsCreate
