/*
Documentation

This file shows all created workflows anf lets add more via a modal

*/

import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import NotificationDelete from 'components/functional/notifications/Delete';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Modal, Row } from "reactstrap";
import { getUrlParameter } from 'utils/urls'
import moment from 'moment';
import * as privileges from '_functions/users/privileges'
import _workflows from '_functions/workflows';

const required_form_fields = [
    'type',
    'division'
]

class WorkflowsAll extends React.Component {

    state = {
        workflow: {},
        showModal: false,
        canSave: true,
        has_made: false
    }

    onSave = async () => {

        this.setState({canSave: false, error_message: true})
        //copy state
        let newState = Object.assign({}, this.state.workflow);
        //assume we have no errors
        let errors = 0;

        required_form_fields.forEach((field) => {
            //check each required field
            if(newState[field]) {
                newState[field + "State"] = 'valid';
            } else {
                newState[field + "State"] = 'invalid';
                errors++
            }
            
            if(field === 'division' && newState[field] === 'false') {
                newState[field + "State"] = 'invalid';
                errors++
            }

        })

        this.setState({ workflow: newState });

        //make sure a workflow does not already exist with the type given
        const found_flow = this.props.workflows.workflows.find(w => newState.type &&  w.type.toLowerCase() === newState.type.toLowerCase());

        if(found_flow) {
            return this.setState({
                error_message: `A workflow already exists with the name: ${newState.type}`,
                canSave: true
            })
        }

        if(!errors) {
            _workflows.create(newState)
            this.setState({showModal: false, workflow: {}, has_made: true})
        }

        this.setState({canSave: true})

    }

    componentWillReceiveProps = () => {
        if(getUrlParameter('create') === 'true' && !this.state.has_made) {
            this.setState({showModal: true})
        }
    }

    componentDidMount = () => {

        // if url = ?create=true open the modal as the component is mounting
        if(getUrlParameter('create') === 'true') {
            this.setState({showModal: true})
        }

    }

    render() {

        const { divisions } = this.props;
        const { workflows } = this.props.workflows;
        const { workflow, workflowToDelete } = this.state

        return (
            <>

            <Container fluid>

                <Helmet>
                    <title>{`Workflows (${workflows.length}) `}</title>
                    <meta name="description" content="Workflows" />
                </Helmet>

                
                <DashHeaderOpen
                    title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
                    breadcrumb_1="Workflows"
                    breadcrumb_2="all"
                />

                <Row>

                    {privileges.canModerateWorkflows() && (
                        <Col lg={4} md={6}>
                            <Card className="bg-success">
                                <CardHeader className="bg-transparent">
                                    <CardTitle className="mb-0 mt-2 text-white float-left">Workflow</CardTitle>
                                </CardHeader>

                                <CardBody>
                                    <h2 className="text-capitalize text-white">Create New Workflow</h2>
                                    <p className="text-muted text-sm mb-0 text-white">Add a new workflow and then create and assign steps/tasks to to fulfilled.</p>
                                </CardBody>

                                <CardFooter className="bg-transparent">
                                    <div className="text-right">
                                        <button onClick={()=> this.setState({showModal: true})} className="btn btn-neutral">Create Workflow</button>
                                    </div>
                                </CardFooter>

                            </Card>
                        </Col>
                    )}

                   {workflows.length ? workflows.map(w => (
                        <Col lg={4} md={6} key={w._id}>
                            <Card className={w.active ? "card-color card-primary" : "card-color card-warning"}>

                                <CardHeader>
                                    <Row>
                                        <Col xs={8} className="align-self-center">
                                            <CardTitle className="mb-0 mt-2">
                                            {w.active ? <span className="text-success">Active Workflow</span> : <span className="text-warning">Inactive Workflow</span>}
                                            </CardTitle>
                                        </Col>

                                        {privileges.canModerateWorkflows() && (
                                            <Col xs={4} className="align-self-center text-right">
                                                <i onClick={() => this.setState({workflowToDelete: w})} className="fas fa-trash text-danger"></i>
                                            </Col>
                                        )}

                                    </Row>
                                </CardHeader>

                                <CardBody>
                                    <h2 className="text-capitalize">{w.type}</h2>
                                    <p className="text-muted text-sm mb-0">Created On: {moment.unix(w.created_at).format('MM/DD/YYYY')}</p>
                                </CardBody>

                                <CardFooter>
                                    <div className="text-right">
                                        <Link to={`/core/workflows/view/${w._id}`}  className="btn btn-success">View Workflow</Link>
                                    </div>
                                </CardFooter>

                            </Card>
                        </Col>
                    )) : null }
                   </Row>

            </Container>

            <Modal
                className="modal-dialog-centered"
                isOpen={this.state.showModal}
                toggle={() => this.setState({showModal: false})}
                size="md"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Create New Workflow</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.setState({showModal:false})}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">

                    <StandardFormGroup
                        obj={workflow}
                        objName='workflow'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        type="text"
                        field="type"
                        title="Workflow Name"
                    />

                    <StandardFormGroup
                        obj={workflow}
                        objName='workflow'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        type="select"
                        field="division"
                        title="Workflow Division"
                    >
                        <option value="false"></option>
                        {divisions.map(division => (
                            <option value={division._id} key={division._id}>{division.name}</option>
                        ))}
                    </StandardFormGroup>

                    {this.state.error_message ? (
                        <p className="mb-0 text-sm font-weight-bold text-danger">{this.state.error_message}</p>
                    ) : null}

                </div>

                <div className="modal-footer">
                    <button  disabled={!this.state.canSave}  onClick={this.onSave}  className="btn  btn-success">
                        Create Workflow
                    </button>
                </div>

            </Modal>

            {workflowToDelete && (
                <NotificationDelete
                    deletionURL={`/api/v1/core/workflows/delete/${workflowToDelete._id}`}
                    confirmBtnText="Delete Workflow"
                    title={`Delete Workflow: ${workflowToDelete.type}`}
                    text="Deleting this workflow will remove all steps and tasks associated with it. Proceed With Caution!"
                    onClose={() => this.setState({workflowToDelete: null})}
                />
            )}

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        workflows: state.workflows,
        divisions: state.divisions.divisions,
    };
};

export default connect(mapStateToProps, '')(WorkflowsAll);

