import moment from 'moment';
import React, { Component } from 'react';
import paginationFactory from "react-bootstrap-table2-paginator";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardTitle, Col, Container, Row } from 'reactstrap';
import renderName from 'utils/renderName';
import { formatPhone } from 'utils/text';
import _groupings from '_functions/groupings';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';

class GroupingsAll extends Component {

    state = {
        loading: false,
        groupings: null,
    };

    columns = [
        {
            dataField: "contact",
            text: "contact",
            filterValue: (cell, row) => row.contact.given_name + ' ' + row.contact.family_name,
            formatter: (cell, row) => (
                <div>
                    <div className="text-capitalize font-weight-bold text-dark">{renderName(row.contact)}</div>
                    {row.contact.phone ? <div>{formatPhone(row.contact.phone)}</div> : null}
                    {row.contact.email ? <div>{row.contact.email}</div> : null}
                </div>
            )

        },
        {
            dataField: "case",
            text: "Case",
            formatter: (cell, row) => (
                <div>
                    <div className="text-capitalize font-weight-bold text-dark">{row.case.name}</div>
                    <div>Step: {row.case.step}</div>
                    <div>Moved On: {row.case.step_moved_date ? moment.unix(row.case.step_moved_date).format('MM/DD/YYYY h:mm A') : '-'}</div>
                </div>
            )
        },
        {
            dataField: "finished_appointment",
            text: "Appointment",
            formatter: (cell, row) => (
                <div>
                    <div className="text-capitalize font-weight-bold text-dark">{row.finished_appointment.name}</div>
                    <div>Scheduled Date: {moment.unix(row.finished_appointment.date).format('MM/DD/YYYY h:mm A')}</div>
                    <div>Finished Date: {moment.unix(row.finished_appointment.finished_on).format('MM/DD/YYYY h:mm A')}</div>
                </div>
            )
        },

    ]

    pagination = paginationFactory({
        page: 1,
        sizePerPage: 25,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: true,
        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        value={currSizePerPage}
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    onDelete = async (grouping) => {

        const deleted = await _groupings.delete(grouping._id);

        if(deleted.success) {

            let groupings = [...this.state.groupings];
            groupings = groupings.filter(g => g._id !== grouping._id);

            this.setState({groupings})

        }

    }

    componentDidMount = async () => {
        
        const data = await _groupings.find();
        this.setState({groupings: data.data, loading: true})

    }

    render() {

        const { groupings } = this.state;

        if(!groupings) return <div />

        return (

            <Container fluid>

                <DashHeaderOpen
                    title={<span><i className="fas fa-headset mr-2 text-success " />Analytics</span>}
                    breadcrumb_1="Groupings"
                    breadcrumb_2="All"
                />

                <Card className="card-color card-primary">

                    <CardHeader>
                        <Row>
                            <Col xs={6}>
                                <CardTitle className="mb-0 float-left">Appointment Template Groupings</CardTitle>
                            </Col>
                            <Col xs={6} className="text-right">
                                <Link to="/analytics/groupings/view/new/template_appointments" className="btn btn-success">Create</Link>
                            </Col>
                        </Row>
                    </CardHeader>

                    <div className="table-responsive">
                        <table className="table">

                            <thead>
                                <tr>
                                    <th>Grouping</th>
                                    <th>Type</th>
                                    <th className="text-right">Actions</th>
                                </tr>
                            </thead>

                            <tbody>
                                {groupings.length ? groupings.map(grouping => (
                                    <tr key={grouping._id}>
                                        <td>{grouping.name}</td>
                                        <td className="text-capitalize">{grouping.collection_name.replace(/_/g, ' ')}</td>
                                        <td className="text-right">
                                            <Link 
                                                to={`/analytics/groupings/view/${grouping._id}/${grouping.collection_name}`} 
                                                className="btn btn-success"
                                            >
                                                Update
                                            </Link>
                                            <button onClick={() => this.onDelete(grouping)} className="btn btn-outline-danger">Delete</button>
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td>No groupings to show.</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>

                        </table>
                    </div>

                </Card>

            </Container>

        )

    }

}


const mapStateToProps = state => {
    return {
        template_appointments: state.template_appointments.template_appointments
    };
};

export default connect(mapStateToProps, '')(GroupingsAll);
