/*
Documentation

load all styles, assets, here
App is wrapped in a store provider

*/


import React from "react";
import ReactDOM from "react-dom";

import "react-datepicker/dist/react-datepicker.css";
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "assets/argon/vendor/fullcalendar/dist/fullcalendar.min.css";
import "assets/argon/vendor/sweetalert2/dist/sweetalert2.min.css";
import 'react-quill/dist/quill.snow.css';
import 'moment-timezone';

import "assets/scss/index.scss";

import App from './App'

import { Provider } from 'react-redux';
import store from 'store';

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root")
);

