import React from 'react';
import { Container } from 'reactstrap';
import Dots from '../loading/Dots'

const ErrorUnknown = () => (
    <Container fluid className="py-3">
        <div className="alert alert-danger">
            <i className="fas fa-exclamation-triangle mr-2 " /> 
            Somethings not right, please try again in a few minutes
            <Dots />
        </div>
    </Container>
)

export default ErrorUnknown;