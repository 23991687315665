import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import { Helmet } from 'react-helmet';
import { Container } from "reactstrap";

import ContactsAll from 'components/system/migrated/ContactsAll';

const CommunicationTextOptOut = () => (
    <>

        <Container fluid>

            <Helmet>
                <title>{`Lead Source`}</title>
                <meta name="description" content="Contacts" />
            </Helmet>

            <DashHeaderOpen
                title={<span><i className="fas fa-headset mr-2 text-success " /> Communication</span>}
                breadcrumb_1="Opt Outs"
                breadcrumb_2="Text"
            />

        </Container>


        <ContactsAll 
            hideHeader={true} 
            cardTitle="All Contacts Opting Out Of Texts"
            opt_out_text={true}
        />

    </>
)

export default CommunicationTextOptOut
