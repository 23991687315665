import { connect } from 'react-redux';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import Circle from 'components/markup/loading/Circle';
import React, { useState, useCallback, useEffect } from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col, Container, Row, FormGroup, CardFooter, Input } from 'reactstrap';
import { secondsToTime } from 'utils/time'
import moment from 'moment';
import DatePicker from 'react-datepicker';

import _analytics from '_functions/analytics';
import ArchkInArray from '../query/QueryCreator/Filter/inputs/ArchkInArray'


const FilingTimes = ({divisions, offices}) => {

    const [chapter, setChapter] = useState('false');
    const [division, setDivision] = useState(null);
    const [data, setData] = useState(null);
    const [start, setStart] = useState();
    const [end, setEnd] = useState();
    const [err, setErr] = useState(null);
    const [selectedOffices, setSelectedOffices] = useState([]);

    const fetchData = useCallback(async () => {

        let startDate = parseInt(moment().startOf('month').format('X'));
        let endDate = Math.floor(new Date() / 1000)

        if(start) startDate = parseInt(moment(start).format('X'))
        if(end) endDate = parseInt(moment(end).format('X'))

        const data = await _analytics.cases.filingTimes({
            start: startDate,
            end: endDate,
            chapter: chapter && chapter !== 'false' ? chapter : undefined,
            division: division && division !== 'false' ? division : undefined,
            offices: selectedOffices,
        });
        if(data.data) {
            setData(data.data);
        } else {
            setErr(true)
        }
    }, [start, end, chapter, division, selectedOffices])


    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(err) return (
        <div className="alert alert-danger">Something's not right, please try again.</div>
    )

    if(!data) return <div className="py-6"><Circle /></div>

    return (

        <Container className="FilingTimes" fluid>

            <DashHeaderOpen
                title={<span><i className="fas fa-dollar-sign mr-2 text-success " /> Payments</span>}
                breadcrumb_1="FilingTimes"
                breadcrumb_2="View"
            />

            <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Select Dates</CardTitle>
                </CardHeader>

                <CardBody>
                    <Row className="mb-0">
                        <Col lg={6}>
                            <FormGroup>
                                <label className="form-control-label">Select Start</label>
                                <div className="date-picker-wrapper">
                                    <DatePicker
                                        selected={start ? start : undefined}
                                        onChange={date => setStart(date)}
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                        <Col lg={6}>
                            <FormGroup>
                                <label className="form-control-label">Select End</label>
                                <div className="date-picker-wrapper">
                                    <DatePicker
                                        selected={end ? end : undefined}
                                        onChange={date => setEnd(date)}
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>

                    <FormGroup>
                        <label className="form-control-label">Division</label>
                        <Input 
                            type="select"
                            value={division || 'false'}
                            onChange={(e) => setDivision(e.target.value)}
                        >
                            <option value="false">All Divisions (No Filter)</option>
                            {divisions.map((division, i) => (
                                <option key={i} value={division._id}>{division.name}</option>
                            ))}
                        </Input>
                    </FormGroup>

                    <FormGroup>
                        <label className="form-control-label">Chapter</label>
                        <Input 
                            type="select"
                            value={chapter}
                            onChange={(e) => setChapter(e.target.value)}
                        >
                            <option value="false">All Chapters (No Filter)</option>
                            <option value="Chapter 7 Full">Chapter 7 Upfront</option>
                            <option value="Chapter 7 Emergency">Chapter 7 Zero Down</option>
                            <option value="Chapter 13">Chapter 13</option>
                        </Input>
                    </FormGroup>
                   
                    <ArchkInArray 
                        label="Office"
                        data={offices.map(o => { return {value: o._id, text: o.name} })}
                        onChange={offices => setSelectedOffices(offices ? offices.$in : [])}
                    />

                </CardBody>

                <CardFooter>
                    <p className="text-sm mb-0">** Dates default to the start and end of the current month.</p>
                </CardFooter>

            </Card>

            <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Offices</CardTitle>
                </CardHeader>
               
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th style={{width: 150}}>Metric</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Total Cases</td>
                                <td>{data.total_cases}</td>
                            </tr>
                            <tr>
                                <td>Total Cases Retained</td>
                                <td>{data.total_cases_committed}</td>
                            </tr>
                            <tr>
                                <td>Total Cases Filed</td>
                                <td>{data.total_cases_filed}</td>
                            </tr>
                            <tr>
                                <td>Avg Time To Retained</td>
                                <td>{secondsToTime(data.average_commitment_time)}</td>
                            </tr>
                            <tr>
                                <td>Avg Time To File From Retention</td>
                                <td>{secondsToTime(data.average_time_to_file)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </Card>

        </Container>

    )

}


const mapStateToProps = state => {
    return {
        divisions: state.divisions.divisions,
        offices: state.offices.offices,
    };
};

export default connect(mapStateToProps, '')(FilingTimes);   