/*
Documentation

this page holds all the routes for the Analytics Layout

*/

//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
// Sources
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

import HighLevel                  from '../pages/high_level';
import SourcesCases                  from '../pages/sources/query/Cases';

import SourcesRouter                  from '../pages/sources/query/Router';
import SourcesLeads                  from '../pages/sources/query/Leads';
import SourcesConsultations           from '../pages/sources/query/Consultations';
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
// Developers
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

import DevLogs                  from '../pages/developers/Logs';
import DevTickets               from '../pages/developers/Tickets';
import DevRequests              from '../pages/developers/Requests';
import DevRouter                from '../pages/developers/Router';


//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
// AUTOMATIONS
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

import EmailSenders               from '../pages/automations/email_senders';

import EmailParsersAll            from '../pages/automations/email_parsers/All';
import EmailParsersParser         from '../pages/automations/email_parsers/Parser';
import EmailParsersLogs           from '../pages/automations/email_parsers/Logs';

import AutomationsAll             from '../pages/automations/automations/All';
import AutomationsView            from '../pages/automations/automations/View';
import AutomationsAssigned        from '../pages/automations/automations/Assigned';
import AutomationsContact         from '../pages/automations/automations/Contact';

import TemplateEmailsAll          from '../pages/automations/template_emails/All';
import TemplateEmailsView         from '../pages/automations/template_emails/View';

import TemplateTextsAll           from '../pages/automations/template_texts/All';
import TemplateTextsView          from '../pages/automations/template_texts/View';

import GlobalSettings             from '../pages/automations/global_settings';

import Flows             from '../pages/flows/All';
import FlowsEdit             from '../pages/flows/Edit';

//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
// COMMUNICATION
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

import CommunicationEmailOptOut   from '../pages/communication/EmailOptOut';
import CommunicationTextOptOut    from '../pages/communication/TextOptOut';
import CommunicationBlacklisted   from '../pages/communication/Blacklisted';
import CommunicationCallLogsIncoming from '../pages/communication/CallLogsIncoming';
import CommunicationCallLogsOutgoing from '../pages/communication/CallLogsOutgoing';
import CommunicationAdmin from '../pages/communication/CallCenterAdmin';
import CommunicationOverview from '../pages/communication/Overview';

import CommunicationPhoneNumbers from '../pages/communication/PhoneNumbers';

//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
// ANALYTICS
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////


import AffiliatesAll                  from '../pages/analytics/affiliates/All';
import AffiliatesCreate                  from '../pages/analytics/affiliates/Create';
import AffiliatesUpdate                  from '../pages/analytics/affiliates/Update';


import AnalyticsMonitorOverview            from '../pages/analytics/monitor/Overview';
import AnalyticsMonitorEmails            from '../pages/analytics/monitor/Emails';
import AnalyticsMonitorClientLogins            from '../pages/analytics/monitor/ClientLogins';
import AnalyticsMonitorUsers            from '../pages/analytics/monitor/Users';

import AnalyticsDirectory            from '../pages/analytics/directory';
import AnalyticsMaster            from '../pages/analytics/master';
import DataTypesUpdate            from '../pages/analytics/_data_types/Update';
import DataTypesRouter            from '../pages/analytics/_data_types/Router';

import LeadSourceValue            from '../pages/analytics/lead_sources/Value';

import LiveQueue            from '../pages/analytics/queue';

import AnalyticsCompare            from '../pages/analytics/compare';
import AnalyticsCompareConsultations            from '../pages/analytics/compare/Consultations';

import UsersCallLogs              from '../pages/analytics/users/CallLogs';
import UsersStatsToday            from '../pages/analytics/users/StatsToday';
import UsersAllStatsToday         from '../pages/analytics/users/AllStatsToday';
import UsersEvents                from '../pages/analytics/users/Events';
import UsersEventsByUser          from '../pages/analytics/users/EventsByUser';
import UsersHiredLogs             from '../pages/analytics/users/HiredLogs';
import UsersStepLogs             from '../pages/analytics/users/StepLogs';

import GroupingsAll               from '../pages/analytics/groupings/All';
import GroupingsView              from '../pages/analytics/groupings/View';


import Alerts                     from '../pages/analytics/alerts';

import AppointmentsBreakdown      from '../pages/analytics/appointments/Breakdown/';
import AppointmentsByStep         from '../pages/analytics/appointments/ByStep/';
import AppointmentReports         from '../pages/analytics/query/Appointments';
import CallsUnder90Reports        from '../pages/analytics/query/CallsUnder90';
import LeadSourceEntriesReports   from '../pages/analytics/query/LeadSourceEntries';
import FinishedAppointmentReports from '../pages/analytics/query/Finished';

import QueryCalls from '../pages/analytics/query/Calls';
import QueryConsultations from '../pages/analytics/query/Consultations';
import CreateConsultations from '../pages/analytics/consultations/Create';

import QueryTemplateDocsSent from '../pages/analytics/query/TemplateDocsSent';
import QueryTemplateDocsSentOverview from '../pages/analytics/signing/Overview';
import SigningIdent from '../pages/analytics/signing/ident';

import LeadSourcesAll             from '../pages/analytics/lead_sources/All';
import LeadSourcesView            from '../pages/analytics/lead_sources/View';
import LeadSourcesComparison      from '../pages/analytics/lead_sources/Comparison';
import LeadSourcesAnalysis      from '../pages/analytics/lead_sources/Analysis';



import ContactSubmissionsAll      from '../pages/analytics/contact_submissions/All';

import ContactsGraphs             from '../pages/analytics/contacts/Graphs';
import ContactsHired              from '../pages/analytics/contacts/Hired';
// import ContactsReports            from '../pages/analytics/contacts/Reports';
import ContactsReports            from '../pages/analytics/query/Contacts';

import CasesTrust                 from '../pages/analytics/cases/Trust';
import CasesGraphs                from '../pages/analytics/cases/Graphs';
import CasesGraphsNew                from '../pages/analytics/cases/GraphsNew';
// import CasesReports               from '../pages/analytics/cases/Reports';
import CasesReports            from '../pages/analytics/query/Cases';
import CasesFilingTimes            from '../pages/analytics/cases/FilingTimes';

import CasesAppointmentsByCase    from '../pages/analytics/cases/AppointmentsByCase';
import CasesSyncCaseAppointments  from '../pages/analytics/cases/SyncCaseAppointments';
import CasesSyncStepTimes  from '../pages/analytics/cases/SyncStepTimes';
import CasesByStep            from '../pages/analytics/cases/ByStep';
import CasesStepOverview            from '../pages/analytics/cases/StepOverview';


import DocumentsSigned            from '../pages/analytics/documents/Signed';
import DocumentsPending            from '../pages/analytics/documents/Pending';

//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
// CORE
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

import CoreAdmin                from '../pages/core/admin';

import Archives                 from '../pages/core/archives/index'

import BulkUpdateCaseUsers      from '../pages/core/bulk_update/CaseUsers'

import Company                  from '../pages/core/company/'

import AppointmentTemplatesView from '../pages/core/appointments/TemplatesView'
import AppointmentTemplatesAll  from '../pages/core/appointments/TemplatesAll'

import DivisionsAll             from '../pages/core/divisions/All'
import DivisionsUpdate          from '../pages/core/divisions/Update'

import UsersAll                 from '../pages/core/users/All';
import UsersCreate              from '../pages/core/users/Create';
import UsersView                from '../pages/core/users/View';
import UsersTimes                from '../pages/core/users/Times';

import CaseAlertTemplatesAll    from '../pages/core/case_alert_templates/All'

import FormsCrud                from '../pages/core/forms/FormsCrud'
import FormsAll                 from '../pages/core/forms/All'

import WorkflowsAll             from '../pages/core/workflows/All';
import WorkflowsView            from '../pages/core/workflows/View/index';

import MissingDocsAll           from '../pages/core/missing_docs/All';

import TemplateWordDocsView     from '../pages/core/template_word_docs/View'
import TemplateWordDocsAll      from '../pages/core/template_word_docs/All'

import DocumentFoldersAll       from '../pages/core/document_folders/All';
import TemplateDocsAll          from '../pages/core/template_docs/All';

import OfficesAll               from '../pages/core/offices/All'
import OfficesView              from '../pages/core/offices/View'

import Courts                   from '../pages/core/courts/All';
import CourtsCreate             from '../pages/core/courts/Create';
import CourtsView               from '../pages/core/courts/View';

import TagsAll                  from '../pages/core/tags/All';
import CallCentersAll                  from '../pages/core/call_centers/All';
import BrokersAll                  from '../pages/core/brokers/All';

import QuestionnaireEdit      from '../pages/core/questionnaires/Edit';
import QuestionnaireAll      from '../pages/core/questionnaires/All';

import WikisAll      from '../pages/core/wikis/All';
import WikisView      from '../pages/core/wikis/View';

//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
// MISC
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
import Unauthorized               from 'components/system/migrated/Unauthorized'

import * as privileges            from '_functions/users/privileges'


const routes = () => {

    return[

        ///////////////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////
        // Developers
        ///////////////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////

        /***********************
        Developers
        ************************/
        {
            path: "/sources/router",
            component: privileges.canViewSources() ? SourcesRouter : Unauthorized,
        },
        {
            path: "/sources/cases",
            component: privileges.canViewSources() ? SourcesCases : Unauthorized,
        },
        {
            path: "/sources/leads",
            component: privileges.canViewSources() ? SourcesLeads : Unauthorized,
        },
        {
            path: "/sources/consultations",
            component: privileges.canViewSources() ? SourcesConsultations : Unauthorized,
        },
        /***********************
        Developers
        ************************/
        {
            path: "/developers/router",
            component: privileges.developer() ? DevRouter : Unauthorized,
        },
        {
            path: "/developers/logs",
            component: privileges.developer() ? DevLogs : Unauthorized,
        },
        {
            path: "/developers/tickets",
            component: privileges.developer() ? DevTickets : Unauthorized,
        },
        {
            path: "/developers/requests",
            component: privileges.developer() ? DevRequests : Unauthorized,
        },

        ///////////////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////
        // ANALYTICS
        ///////////////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////

        /***********************
        // Alerts
        ************************/
        {
            path: "/analytics/alerts",
            component: privileges.canModerateAnalytics() ? Alerts : Unauthorized,
        },

        /***********************
        // Consultations
        ************************/
        {
            path: "/analytics/consultations/query",
            component: privileges.canModerateAnalytics() ? QueryConsultations : Unauthorized,
        },
        {
            path: "/analytics/calls/query",
            component: privileges.canModerateAnalytics() ? QueryCalls : Unauthorized,
        },
        {
            path: "/analytics/consultations/create",
            component: privileges.canModerateAnalytics() ? CreateConsultations : Unauthorized,
        },
        {
            path: "/analytics/consultations/compare",
            component: privileges.canModerateAnalytics() ? AnalyticsCompareConsultations : Unauthorized,
        },
        {
            path: "/analytics/live_queue",
            component: privileges.canModerateAnalytics() ? LiveQueue : Unauthorized,
        },
        {
            path: "/analytics/signing_docs/reports",
            component: privileges.canModerateAnalytics() ? QueryTemplateDocsSent : Unauthorized,
        },
        {
            path: "/analytics/signing_docs/overview",
            component: privileges.canModerateAnalytics() ? QueryTemplateDocsSentOverview : Unauthorized,
        },
        {
            path: "/analytics/signing_docs/custom/ident",
            component: privileges.canModerateAnalytics() ? SigningIdent : Unauthorized,
        },
        {
            path: "/analytics/lead_source_value",
            component: privileges.canModerateAnalytics() ? LeadSourceValue : Unauthorized,
        },
       
        /***********************
        // Appointments
        ************************/
        {
            path: "/analytics/appointments/finished/query",
            component: privileges.canModerateAnalytics() ? FinishedAppointmentReports : Unauthorized,
        },
        {
            path: "/analytics/appointments/query",
            component: privileges.canModerateAnalytics() ? AppointmentReports : Unauthorized,
        },
        {
            path: "/analytics/calls_under_90/query",
            component: privileges.canModerateAnalytics() ? CallsUnder90Reports : Unauthorized,
        },
        {
            path: "/analytics/lead_source_entries/query",
            component: privileges.canModerateAnalytics() ? LeadSourceEntriesReports : Unauthorized,
        },
        {
            path: "/analytics/appointments/breakdown",
            component: privileges.canModerateAnalytics() ? AppointmentsBreakdown : Unauthorized,
        },
        {
            path: "/analytics/appointments/by_step",
            component: privileges.canModerateAnalytics() ? AppointmentsByStep : Unauthorized,
        },

        /***********************
        // Data Types
        ************************/
        {
            path: "/analytics/lead_sources",
            component: privileges.canModerateAnalytics() ? LeadSourcesAll : Unauthorized,
        },
        {
            path: "/analytics/lead_sources/view/:lead_source_id",
            component: privileges.canModerateAnalytics() ? LeadSourcesView : Unauthorized,
        },
        {
            path: "/analytics/lead_sources/comparison",
            component: privileges.canModerateAnalytics() ? LeadSourcesComparison : Unauthorized,
        },
        {
            path: "/analytics/lead_source_analysis",
            component: privileges.canModerateAnalytics() ? LeadSourcesAnalysis : Unauthorized,
        },

        /***********************
        // Data Types
        ************************/
        {
            path: "/analytics/directory",
            component: privileges.canModerateAnalytics() ? AnalyticsDirectory : Unauthorized,
        },
        /***********************
        // Affiliates
        ************************/
        {
            path: "/analytics/affiliates",
            component: privileges.canModerateAnalytics() ? AffiliatesAll : Unauthorized,
        },
        {
            path: "/analytics/affiliates/create",
            component: privileges.canModerateAnalytics() ? AffiliatesCreate : Unauthorized,
        },
        {
            path: "/analytics/affiliates/:_id",
            component: privileges.canModerateAnalytics() ? AffiliatesUpdate : Unauthorized,
        },

        /***********************
        // Data Types
        ************************/
        {
            path: "/analytics/master",
            component: privileges.canModerateAnalytics() ? AnalyticsMaster : Unauthorized,
        },
        {
            path: "/analytics/monitor/overview",
            component: privileges.canModerateAnalytics() ? AnalyticsMonitorOverview : Unauthorized,
        },
        {
            path: "/analytics/monitor/emails",
            component: privileges.canModerateAnalytics() ? AnalyticsMonitorEmails : Unauthorized,
        },
        {
            path: "/analytics/monitor/client_logins",
            component: privileges.canModerateAnalytics() ? AnalyticsMonitorClientLogins : Unauthorized,
        },
        {
            path: "/analytics/monitor/users",
            component: privileges.canModerateAnalytics() ? AnalyticsMonitorUsers : Unauthorized,
        },
        /***********************
        // Data Types
        ************************/
        {
            path: "/analytics/data_types/router",
            component: privileges.canModerateAnalytics() ? DataTypesRouter : Unauthorized,
        },

        {
            path: "/analytics/:collection/data_types/update",
            component: privileges.canModerateAnalytics() ? DataTypesUpdate : Unauthorized,
        },

        /***********************
        // DOCUMENTS
        ************************/
        {
            path: "/analytics/documents/signed",
            component: privileges.canModerateAnalytics() ? DocumentsSigned : Unauthorized,
        },
        {
            path: "/analytics/documents/pending",
            component: privileges.canModerateAnalytics() ? DocumentsPending : Unauthorized,
        },

        /***********************
        // Contact Submissions
        ************************/
        {
            path: "/analytics/contact_submissions/all",
            component: privileges.canModerateAnalytics() ? ContactSubmissionsAll : Unauthorized,
        },

        /***********************
        // Contacts
        ************************/
        {
            path: "/analytics/contacts/graphs",
            component: privileges.canModerateAnalytics() ? ContactsGraphs : Unauthorized,
        },
        {
            path: "/analytics/contacts/reports",
            component: privileges.canModerateAnalytics() ? ContactsReports : Unauthorized,
        },
        {
            path: "/analytics/contacts/hired",
            component: privileges.canModerateAnalytics() ? ContactsHired : Unauthorized,
        },

        /***********************
        // Cases
        ************************/
        {
            path: "/analytics/cases/trust",
            component: privileges.canModerateAnalytics() ? CasesTrust : Unauthorized,
        },
        {
            path: "/analytics/cases/graphs",
            component: privileges.canModerateAnalytics() ? CasesGraphs : Unauthorized,
        },
        {
            path: "/analytics/cases/overview",
            component: privileges.canModerateAnalytics() ? CasesGraphsNew : Unauthorized,
        },
        {
            path: "/analytics/cases/reports",
            component: privileges.canModerateAnalytics() ? CasesReports : Unauthorized,
        },
        {
            path: "/analytics/cases/filing_times",
            component: privileges.canModerateAnalytics() ? CasesFilingTimes : Unauthorized,
        },

        {
            path: "/analytics/cases/appointments_by_case",
            component: privileges.canModerateAnalytics() ? CasesAppointmentsByCase : Unauthorized,
        },
        {
            path: "/analytics/cases/sync_case_appointment",
            component: privileges.canModerateAnalytics() ? CasesSyncCaseAppointments : Unauthorized,
        },
        {
            path: "/analytics/cases/sync_step_times",
            component: privileges.canModerateAnalytics() ? CasesSyncStepTimes : Unauthorized,
        },
        {
            path: "/analytics/cases/by_step",
            component: privileges.canModerateAnalytics() ? CasesByStep : Unauthorized,
        },
        {
            path: "/analytics/cases/step_overview",
            component: privileges.canModerateAnalytics() ? CasesStepOverview : Unauthorized,
        },

        /***********************
        // Compare
        ************************/

        {
            path: "/analytics/compare",
            component: privileges.canModerateAnalytics() ? AnalyticsCompare : Unauthorized,
        },
      
        /***********************
        // Users
        ************************/

        {
            path: "/analytics/users/stats_today",
            component: privileges.canModerateAnalytics() ? UsersStatsToday : Unauthorized,
        },
        {
            path: "/analytics/users/call_logs",
            component: privileges.canModerateAnalytics() ? UsersCallLogs : Unauthorized,
        },
        {
            path: "/analytics/users/all_stats_today",
            component: privileges.canModerateAnalytics() ? UsersAllStatsToday : Unauthorized,
        },
        {
            path: "/analytics/users/events",
            component: privileges.canModerateAnalytics() ? UsersEvents : Unauthorized,
        },
        {
            path: "/analytics/users/events_by_user",
            component: privileges.canModerateAnalytics() ? UsersEventsByUser : Unauthorized,
        },
        {
            path: "/analytics/users/hired_logs",
            component: privileges.canModerateAnalytics() ? UsersHiredLogs : Unauthorized,
        },
        {
            path: "/analytics/users/step_logs",
            component: privileges.canModerateAnalytics() ? UsersStepLogs : Unauthorized,
        },

        /***********************
        // Groupings
        ************************/
        {
            path: "/analytics/groupings/all",
            component: privileges.canModerateAnalytics() ? GroupingsAll : Unauthorized,
        },
        {
            path: "/analytics/groupings/view/:grouping_id/:type",
            component: privileges.canModerateAnalytics() ? GroupingsView : Unauthorized,
        },

        ///////////////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////
        // AUTOMATIONS
        ///////////////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////

        /***********************
        // automations
        ************************/
        {
            path: "/automations/automations",
            component: privileges.canModerateAutomations() ? AutomationsAll : Unauthorized,

        },
        {
            path: "/automations/automations/view/:_id",
            component: privileges.canModerateAutomations() ? AutomationsView : Unauthorized,

        },
        {
            path: "/automations/automations/assigned/:_id",
            component: privileges.canModerateAutomations() ? AutomationsAssigned : Unauthorized,

        },
        {
            path: "/automations/automations/contact/:_id",
            component: privileges.canModerateAutomations() ? AutomationsContact : Unauthorized,

        },

        /***********************
        // template emails
        ************************/
        {
            path: "/automations/template_emails",
            component: privileges.canModerateAutomations() ? TemplateEmailsAll : Unauthorized,
        },
        {
            path: "/automations/template_emails/view/:_id",
            component: privileges.canModerateAutomations() ? TemplateEmailsView : Unauthorized,
        },

        /***********************
        // template texts
        ************************/
        {
            path: "/automations/template_texts",
            component: privileges.canModerateAutomations() ? TemplateTextsAll : Unauthorized,
        },
        {
            path: "/automations/template_texts/view/:_id",
            component: privileges.canModerateAutomations() ? TemplateTextsView : Unauthorized,
        },

        /***********************
        // appointment templates
        ************************/
        {
            path: "/automations/global_settings",
            component: privileges.canModerateAutomations() ? GlobalSettings : Unauthorized,
        },
        /***********************
        // appointment templates
        ************************/
        {
            path: "/automations/flows/all",
            component: privileges.canModerateAutomations() ? Flows : Unauthorized,
        },
        {
            path: "/automations/flows/:_id",
            component: privileges.canModerateAutomations() ? FlowsEdit : Unauthorized,
        },

        /***********************
        // email senders
        ************************/
        {
            path: "/automations/email_senders",
            component: privileges.canModerateAutomations() ? EmailSenders : Unauthorized,
        },
        /***********************
        // automations
        ************************/
        {
            path: "/automations/email_parsers",
            component: privileges.canModerateAutomations() ? EmailParsersAll : Unauthorized,
        },
        {
            path: "/automations/email_parsers",
            component: privileges.canModerateAutomations() ? EmailParsersAll : Unauthorized,
        },
        {
            path: "/automations/email_parsers/logs",
            component: privileges.canModerateAutomations() ? EmailParsersLogs : Unauthorized,
        },

        {
            path: "/automations/email_parsers/view/:email_parser_id",
            component: privileges.canModerateAutomations() ? EmailParsersParser : Unauthorized,
        },

        ///////////////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////
        // Communication
        ///////////////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////

        /***********************
        // Communication
        ************************/
        {
            path: "/communication/call_center_admin",
            component: privileges.canViewAdminCommunication() ? CommunicationAdmin : Unauthorized,
        },
        {
            path: "/communication/overview",
            component: privileges.canViewAdminCommunication() ? CommunicationOverview : Unauthorized,
        },
        {
            path: "/communication/call_logs_incoming",
            component: privileges.canViewAdminCommunication() ? CommunicationCallLogsIncoming : Unauthorized,
        },
        {
            path: "/communication/call_logs_outgoing",
            component: privileges.canViewAdminCommunication() ? CommunicationCallLogsOutgoing : Unauthorized,
        },
        {
            path: "/communication/text_opt_out",
            component: privileges.canViewAdminCommunication() ? CommunicationTextOptOut : Unauthorized,
        },
        {
            path: "/communication/email_opt_out",
            component: privileges.canViewAdminCommunication() ? CommunicationEmailOptOut : Unauthorized,
        },
        {
            path: "/communication/blacklisted",
            component: privileges.canViewAdminCommunication() ? CommunicationBlacklisted : Unauthorized,
        },
        
        {
            path: "/communication/phone_numbers",
            component: privileges.canViewAdminCommunication() ? CommunicationPhoneNumbers : Unauthorized,
        },

        ///////////////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////
        // CORE
        ///////////////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////

        /***********************
        Archives
        ************************/
        {
            path: "/core/admin",
            component: CoreAdmin,
        },
        /***********************
        Archives
        ************************/
        {
            path: "/core/archives",
            component: privileges.canModerateArchives() ? Archives : Unauthorized,
        },
        /***********************
        BULK UPDATE
        ************************/
        {
            path: "/core/bulk_update/case_users",
            component: privileges.canModerateStaff() ? BulkUpdateCaseUsers : Unauthorized,
        },

        /***********************
        // Users
        ************************/
        {
            path: "/core/users/all",
            component: privileges.canModerateStaff() ? UsersAll : Unauthorized,
        },
        {
            path: "/core/users/view/:_id",
            component: privileges.canModerateStaff() ? UsersView : Unauthorized,
        },
        {
            path: "/core/users/create",
            component: privileges.canModerateStaff() ? UsersCreate : Unauthorized,
        },
        {
            path: "/core/users/times",
            component: privileges.canModerateStaff() ? UsersTimes : Unauthorized,
        },

        /***********************
        // Case Alert Templates
        ************************/
        {
            path: "/core/cases/alert_templates/all",
            component: privileges.canModerateCaseTemplates() ? CaseAlertTemplatesAll : Unauthorized,
        },

        /***********************
        Company
        ************************/
        {
            path: "/core/company",
            component: privileges.canModerateCompanySettings() ? Company : Unauthorized,
        },
        /***********************
        Appointment Templates
        ************************/
        {
            path: "/core/appointments/templates/:_id",
            component: privileges.canModerateAppointmentTemplates() ? AppointmentTemplatesView : Unauthorized,
        },

        {
            path: "/core/appointments/templates",
            component: privileges.canModerateAppointmentTemplates() ? AppointmentTemplatesAll : Unauthorized,
        },
        /***********************
        Divisions
        ************************/
        {
            path: "/core/divisions/all",
            component: privileges.canModerateDivisions() ? DivisionsAll : Unauthorized,
        },
        {
            path: "/core/divisions/view/:division_id",
            component: privileges.canModerateDivisions() ? DivisionsUpdate : Unauthorized,
        },

        /***********************
        Forms
        ************************/
        {
            path: "/core/forms/view/:_id",
            component: privileges.canModerateForms() ?  FormsCrud : Unauthorized,
        },
        {
            path: "/core/forms/all",
            component: privileges.canModerateForms() ?  FormsAll : Unauthorized,
        },

        /***********************
        WORKFLOWS
        ************************/
        {
            path: "/core/workflows/all",
            component: privileges.canModerateWorkflows() ? WorkflowsAll : Unauthorized,
        },
        {
            path: "/core/workflows/view/:_id",
            component: privileges.canModerateWorkflows() ? WorkflowsView : Unauthorized,
        },

        /***********************
        Missing Docs
        ************************/
        {
            path: "/core/documents/missing",
            component: privileges.canModerateMissingDocs() ? MissingDocsAll : Unauthorized,
        },

        /***********************
        TEMPLATE WORD DOCS
        ************************/
        {
            path: "/core/documents/word",
            component: privileges.canModerateTemplateDocs() ? TemplateWordDocsAll : Unauthorized,
        },
        {
            path: "/core/documents/word/:_id",
            component: privileges.canModerateTemplateDocs() ? TemplateWordDocsView : Unauthorized,
        },


        /***********************
        DOCUMENT FOLDERS
        ************************/
        {
            path: "/core/documents/folders",
            component: privileges.canModerateTemplateDocs() ? DocumentFoldersAll : Unauthorized
        },

        /***********************
        TEMPLATE DOCS
        ************************/
        {
            path: "/core/documents/signing",
            component: privileges.canModerateTemplateDocs() ? TemplateDocsAll : Unauthorized
        },

        /***********************
        Offices
        ************************/
        {
            path: "/core/locations/offices",
            component: privileges.canModerateOffices() ? OfficesAll : Unauthorized,
        },
        {
            path: "/core/locations/offices/view/:_id",
            component: privileges.canModerateOffices() ? OfficesView : Unauthorized,
        },

        /***********************
        COURTS
        ************************/
        {
            path: "/core/locations/courts/create",
            component: privileges.canModerateLocations() ? CourtsCreate : Unauthorized,
        },
        {
            path: "/core/locations/courts/view/:_id",
            component: privileges.canModerateLocations() ? CourtsView : Unauthorized,
        },
        {
            path: "/core/locations/courts",
            component: privileges.canModerateLocations() ? Courts : Unauthorized,
        },

        /***********************
        Tags
        ************************/
        {
            path: "/core/tags/all",
            component: privileges.canModerateTags() ?  TagsAll : Unauthorized,
        },
        {
            path: "/core/call_centers/all",
            component: privileges.canModerateTags() ?  CallCentersAll : Unauthorized,
        },
        {
            path: "/core/brokers/all",
            component: privileges.canModerateTags() ?  BrokersAll : Unauthorized,
        },

        /***********************
        Questionnaires Numbers
        ************************/
        {
            path: "/core/questionnaires/:_id",
            component: privileges.canModerateForms() ?  QuestionnaireEdit : Unauthorized,
        },
        {
            path: "/core/questionnaires",
            component: privileges.canModerateForms() ?  QuestionnaireAll : Unauthorized,
        },
        /***********************
        Wikis
        ************************/
        {
            path: "/core/wikis",
            component: privileges.canModerateForms() ?  WikisAll : Unauthorized,
        },
        {
            path: "/core/wikis/:_id",
            component: privileges.canModerateForms() ?  WikisView : Unauthorized,
        },
        {
            path: "/analytics/high_level",
            component: privileges.canModerateForms() ?  HighLevel : Unauthorized,
        },
        {
            path: "/high_level",
            component: HighLevel,
        },

    ]

}

export default routes;