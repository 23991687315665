import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import Circle from 'components/markup/loading/Circle';
import moment from 'moment';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import { Card, CardHeader, CardTitle, Container, Input, CardBody, Row, Col } from "reactstrap";
import * as _ from 'underscore';
import _analytics from '_functions/analytics';

import NotificationDelete from 'components/functional/notifications/Delete';

import keys from 'keys';

class SyncCaseAppointments extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            page: 1,
            sizePerPage: 25,
            total_documents: 0,
            data: null,
        }
       this.queryTableDataThrottled = _.throttle(this.queryTableData, 750)
    }

    columns = [
        {
            dataField: "case",
            text: "Case Name",
            formatter: (cell, row) => (
                <div className="text-capitalize">
                    <a className="text-capitalize" href={`${keys.APP_URL}/dashboard/cases/view/${row.case}`} target="_blank" rel="noopener noreferrer">
                        {row.case_name}
                    </a>

                </div>
            ),
        },
        {
            dataField: "workflow_step",
            text: "Current STep",
            formatter: (cell, row) => row.workflow_step_name,
        },
        {
            dataField: "template_appointment",
            text: "Missing Appointment",
            formatter: (cell, row) => row.template_appointment_name,
        },
        {
            dataField: "current_step_start",
            text: "Step Last Moved",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => <div className="text-right">{moment.unix(row.current_step_start).format("MM/DD/YYYY h:mm A")}</div>,
        },
        {
            dataField: "current_step_start",
            text: "Step Last Moved",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => (
                <div className="text-right">
                    <i className="fas fa-trash text-danger " onClick={() => this.setState({syncToDelete: row})} />
                </div>
            )
        },

    ]

    onTableChange = async (type, newState) => {

        const { searchText } = this.state

        if(type === 'search') {

            this.queryTableDataThrottled(searchText, true)


        } else {

            this.setState({ page: newState.page, sizePerPage: newState.sizePerPage }, () => {
                this.queryTableDataThrottled(searchText)
            })

        }

    }

    getQueryParams = (search, skip) => {

        return {

            search          : search,
            skip            : skip,
            limit           : this.state.sizePerPage,
        }


    }

    queryTableData = async (searchText, setPage1) => {

        this.setState({loading: true});

        let page = this.state.page
        if(setPage1) {
            this.setState({page: 1})
            page = 1
        }

        const skip = (page - 1) * this.state.sizePerPage;

        const data = await _analytics.cases.syncCaseAppointments(this.getQueryParams(searchText, skip))
        this.setState({ data: data.data, total_documents: data.total_documents, loading: false })

    }


    componentDidMount = async () => {

        const data = await _analytics.cases.syncCaseAppointments(this.getQueryParams(undefined, 0))
        this.setState({data: data.data, total_documents: data.total_documents})

    }

    render() {

        const { data, loading, syncToDelete } = this.state;
        const { cardTitle } = this.props;

        return (
            <>

            {syncToDelete && (
                <NotificationDelete
                    deletionURL={`/api/v1/analytics/custom_reports/cases/sync_case_appointments/${syncToDelete._id}`}
                    confirmBtnText="Delete Contact"
                    title={<span className="text-capitalize">{`Delete ${syncToDelete.case_name}`}</span>}
                    text="This will remove the entry from the table"
                    onClose={() => { this.setState({syncToDelete: null}) }}
                    onSuccess={() => this.setState({data: this.state.data.filter(d => d._id !== syncToDelete._id)})}
                />
            )}
            

            <Container fluid>

                <DashHeaderOpen         
                    title={<span><i className="fas fa-chart-pie mr-2 text-success " />Analytics</span>}
                    breadcrumb_1="Cases"
                    breadcrumb_2="Appointment Sync"
                />

                <Helmet>
                    <title>{`Case Appointment Synce`}</title>
                    <meta name="description" content="Case Appointment Synce" />
                </Helmet>

                <Card>
                    <CardBody>
                        <p className="text-sm mb-0"><i className="fas fa-info mr-2 text-info-original " />Case and appointment sync occurs every 5 minutes. Ex: 6:00PM, 6:05PM, 6:10PM, etc.</p>
                    </CardBody>
                </Card>

                <Card className="card-color card-primary">

                    <CardHeader>
                        <Row>
                            <Col lg={6} className="align-self-center">
                                <CardTitle className="mb-0 float-left">
                                    {cardTitle ? cardTitle : 'Sync Data'}
                                </CardTitle>
                            </Col>
                            <Col lg={6} className="align-self-center text-right">
                                <button onClick={() => this.queryTableData(undefined, true)} className="btn btn-success">
                                    <i className="fas fa-sync mr-2" />Refresh Results
                                </button>
                            </Col>
                        </Row>
                    </CardHeader>

                    {data ? (
                            <ToolkitProvider
                                data={data.length ? data : []}
                                keyField="_id"
                                columns={this.columns}
                                search
                            >
                                {props => (
                                    <>
                                        <div className="dataTables_filter px-4 pb pt-3 pb-2 position-relative" >
                                            <label>
                                                Search:
                                                <Input
                                                    className="form-control-sm"
                                                    value={this.state.searchText}
                                                    placeholder="Step Name"
                                                    style={{width: 190}}
                                                    onChange={(e) => {
                                                        this.setState({searchText: e.target.value})
                                                        this.queryTableDataThrottled(e.target.value, true)
                                                    }}
                                                />
                                                 {loading ? (<Circle style={{display: 'inline-block', top: 1, position: 'absolute'}} /> ) : null}

                                            </label>
                                        </div>

                                        <div className="table-not-fixed table-responsive table-vertical-align">
                                            <BootstrapTable
                                                pagination={paginationFactory({
                                                    totalSize: this.state.total_documents,
                                                    page: this.state.page,
                                                    sizePerPage: this.state.sizePerPage,
                                                    alwaysShowAllBtns: true,
                                                    showTotal: true,
                                                    withFirstAndLast: true,
                                                    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                                                        <div className="dataTables_length" id="datatable-basic_length">
                                                            <label>
                                                                Show{" "}
                                                                {
                                                                <select
                                                                    value={currSizePerPage}
                                                                    name="datatable-basic_length"
                                                                    aria-controls="datatable-basic"
                                                                    className="form-control form-control-sm"
                                                                    onChange={e => onSizePerPageChange(e.target.value)}
                                                                >
                                                                    <option value="10">10</option>
                                                                    <option value="25">25</option>
                                                                    <option value="50">50</option>
                                                                    <option value="100">100</option>
                                                                </select>
                                                                }{" "}
                                                                entries.
                                                            </label>
                                                        </div>
                                                    )
                                                })}
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                bordered={false}
                                                remote={{
                                                    search: true,
                                                    pagination: true,
                                                    sort: false,
                                                    cellEdit: false
                                                }}
                                                onTableChange={this.onTableChange}
                                                rowClasses={(row, rowIndex) => {
                                                    if(row.blacklisted) {
                                                        return 'bg-secondary' 
                                                    }
                                                    return '';
                                                }}
                                            />
                                        </div>
                                    </>
                                )}
                            </ToolkitProvider>
                    ) : (
                       <Circle />
                    )}

                </Card>

            </Container>

            </>
        );
    }
}

export default SyncCaseAppointments
