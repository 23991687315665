import React, { useState } from 'react';

const ButtonDelete = ({onDelete}) =>  {

    const [confirmed, setConfirmed] = useState(false);

    const onConfirm = () => {
        setConfirmed(true);
        setTimeout(() => {
            setConfirmed(false)
        }, 2000)
    }

    if(confirmed) return (
        <button className="btn btn-danger" onClick={onDelete}>
            Are You Sure?
        </button>
    )

    return (
        <button className="btn btn-outline-danger" onClick={onConfirm}>
            <i className="fas fa-trash mr-2 " /> Delete
        </button>
    )
};

export default React.memo(ButtonDelete);

