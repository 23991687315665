import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardTitle, FormGroup, Input, CardFooter, CardBody, Row, Col } from "reactstrap";

const fieldState = {
    type: 'date',
    name: 'Unsaved Field',
    regex: '',
    before: '',
    after: '',
}

const EmailParserFields = ({parser, setParser}) => {

    const [ fieldIndex, setFieldIndex ] = useState(0);
    const [ fieldToEdit, setFieldToEdit ] = useState({});

    

    const setFieldToState = (field, fieldIndex) => {
        setFieldToEdit(field);
        setFieldIndex(fieldIndex);
    }

    const addField = () => {

        const newState = Object.assign({}, parser);
        const newField = fieldState;
        const fieldLength = parser.fields.length;
        parser.fields.push(newField);

        setParser(newState);
        setFieldToState(newField, fieldLength)

    }

    const removeField = (fieldIndex) => {

        const newState = Object.assign({}, parser);
        const fields = newState.fields;

        fields.splice(fieldIndex, 1);

        if(fields.length === 0) fields.push(Object.assign({}, fieldState))

        newState.fields = fields;

        setParser(newState);
        setFieldToState(fields[0], 0)

    }

   
    const saveField = (field, index) => {

        const fields = [...parser.fields];
        fields[index] = field;

        setParser({...parser, fields});

    }

    const renderFieldRow = (field, index) => {

        const isActive = index === fieldIndex;
        const unsaved = field.name === 'Unsaved Field';

        return (
            <tr key={index + field.name} className={isActive ? 'bg-secondary' : ''}>
                <td style={{width: '70%', wordBreak: 'break-all', whiteSpace: 'pre-line'}}>
                    {isActive ? (
                        unsaved ? (
                            <i className="fas fa-save text-warning mr-2 " /> 
                        ) : (
                            <i className="fas fa-check text-success mr-2 " /> 
                        )
                    ): null }
                    <span className="cursor-pointer" onClick={() => setFieldToState(field, index)}>
                        {field.name}
                    </span>
                </td>
                <td className="text-right">
                    <i onClick={() => setFieldToState(field, index)} className="fas fa-edit text-success mr-2 cursor-pointer" />
                    <i onClick={() => removeField(index)} className="fas fa-trash text-danger cursor-pointer " />
                </td>
            </tr>
        )

    }

    const onChange = (e, stateName) => {

        const value = e.target.value;
        const newState = Object.assign({}, fieldToEdit);

        newState[stateName] = value;

        if (value === "") {
            newState[stateName + "State"] = "invalid";
        } else {
            newState[stateName + "State"] = "valid";
        }

        setFieldToEdit(newState);

    }


    const isValid = () => {

        const state = Object.assign({}, fieldToEdit);

        let errors = 0;

        const setError = (stateName) => {
            errors++;
            state[stateName + 'State'] = 'invalid'
        }

        if(!state.name || state.name === 'Unsaved Field') setError('name');
        if(!state.before) setError('before');
        if(!state.after) setError('after');
    
        if(errors) {
            setFieldToEdit(state);
            return false;
        }

        return true;

    }

    const onSaveField = () => {

        if(isValid()) {
            saveField(fieldToEdit, fieldIndex)
        }

    }

    useEffect(() => {

        let fieldToSet = parser.fields[0];

        if(!fieldToSet) {
            fieldToSet = fieldState;
            setParser({...Object.assign({}, parser), fields: [Object.assign({}, fieldState)]});
        }

        setFieldToState(fieldToSet, 0);

    }, [])

    return (

        <Card>

            <CardHeader>
                <CardTitle className="mb-0">Intelli Parse</CardTitle> 
            </CardHeader>

            <CardBody>

                <Row>
                    
                    <Col md={4} className="border-right">
                        <Row className="mb-3">
                            <Col xs={6} className="align-self-center">
                                <FormGroup className="mb-0">
                                    <label className="form-control-label mb-0">All Fields</label>
                                </FormGroup>
                            </Col>
                            <Col xs={6} className="align-self-center text-right">
                                <button className="btn btn-outline-success" onClick={addField}><i className="fas fa-plus mr-2 " /> Add</button>
                            </Col>
                        </Row>

                        <div className="table-responsive">
                            <table className="table table-bordered" style={{tableLayout: 'fixed'}}>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th className="text-right" style={{width: 75}}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {parser.fields.map((field, index) => renderFieldRow(field, index))}
                                </tbody>
                            </table>
                        </div>

                    </Col>

                    <Col md={8}>
                        
                        <Row>

                            <Col lg={4}>
                                <FormGroup>
                                    <label className="form-control-label">Name*</label>
                                    <Input 
                                        type="text"
                                        value={fieldToEdit.name || ''}
                                        onChange={(e) => onChange(e, 'name')}
                                        valid={ fieldToEdit.nameState === "valid" }
                                        invalid={ fieldToEdit.nameState === "invalid" }
                                    />
                                </FormGroup>
                            </Col>

                            <Col lg={4}>
                                <FormGroup>
                                    <label className="form-control-label">Type*</label>
                                    <Input 
                                        type="select"
                                        value={fieldToEdit.type || ''}
                                        onChange={(e) => onChange(e, 'type')}
                                        valid={ fieldToEdit.typeState === "valid" }
                                        invalid={ fieldToEdit.typeState === "invalid" }
                                    >
                                        <option value="date">Date</option>
                                        <option value="text">Text</option>
                                    </Input>
                                </FormGroup>
                            </Col>

                            <Col lg={4}>
                                <FormGroup>
                                    <label className="form-control-label">
                                        Use Regex 
                                        <span className="text-danger"><i className="fas fa-exclamation-triangle ml-3 mr-1" /></span>
                                    </label>
                                    <Input 
                                        type="text"
                                        value={fieldToEdit.field_regex || ''}
                                        onChange={(e) => onChange(e, 'field_regex')}
                                        valid={ fieldToEdit.field_regexState === "valid" }
                                        invalid={ fieldToEdit.field_regexState === "invalid" }
                                    />
                                </FormGroup>
                            </Col>

                        </Row>

                        <Row>

                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Text <b className="font-weight-bold text-underline">BEFORE</b>*</label>
                                    <Input 
                                        type="text"
                                        value={fieldToEdit.before || ''}
                                        onChange={(e) => onChange(e, 'before')}
                                        valid={ fieldToEdit.beforeState === "valid" }
                                        invalid={ fieldToEdit.beforeState === "invalid" }
                                    />
                                </FormGroup>
                            </Col>

                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Text <b className="font-weight-bold text-underline">AFTER</b>*</label>
                                    <Input 
                                        type="text"
                                        value={fieldToEdit.after || ''}
                                        onChange={(e) => onChange(e, 'after')}
                                        valid={ fieldToEdit.afterState === "valid" }
                                        invalid={ fieldToEdit.afterState === "invalid" }
                                    />
                                </FormGroup>
                            </Col>

                        </Row>

                    </Col>

                </Row>

            </CardBody>

            <CardFooter className="text-right">
                <button onClick={onSaveField} className="btn btn-success"><i className="fas fa-save mr-2 " /> Save Field</button>
            </CardFooter>
            
            
        </Card>


     
        
    );
}

export default EmailParserFields
