/*
Documentation

This is the controller that allows for updating of a question.
Displays as a modal

*/

import PropTypes from 'prop-types'
import React from "react";
import { FormGroup, Input, Modal } from "reactstrap";

class TemplatesCrudController extends React.Component {

    state = {
        hasBranch: null,
        hasBranchInRow: null
    }

    renderBranchAbility = () => {

        const { question } = this.props;

        let canCreateBranch = false;

        if(question.type === 'yes/no') {

            canCreateBranch = true

        }

        const shouldDisable = canCreateBranch && this.state.hasBranchInRow && !question.children
        const showWarning = question.create_branch === 'no' && question.children

        const markup = (
            <>

            <FormGroup>
                <label className="form-control-label">Create Form Branch</label>
                <p className="text-sm mb-2">If the answer to this question is no, should the person answering be asked a different question set next?</p>
                <Input
                    disabled={shouldDisable}
                    value={question.create_branch ? question.create_branch :  this.state.hasBranch ? 'yes' : 'no'}
                    type="select"
                    onChange={(e) => this.props.onQuestionInputChange(e, 'create_branch')}
                >
                    <option></option>
                    <option value="no">No</option>
                    <option value="yes">Yes</option>
                </Input>
            </FormGroup>

            {shouldDisable ? (
                <div className="alert alert-warning">
                    There can only be one branched question per row, however, you may still keep these question as a yes / no type.
                </div>
            ) : null}

            {showWarning ? (
                <div className="alert alert-danger">
                    <i className="fas fa-exclamation-triangle" />
                    Changing this field to "No" will delete all questions stemming from this branch. Proceed With Caution.
                </div>
            ) : null}

            </>
        )

        return canCreateBranch ? markup : null

    }

    updateQuestion = () => this.props.updateQuestion()

    componentWillReceiveProps = (nextProps) => {

        const currentBranch = this.props.findBranch(this.props.questions, this.props.depth)
        let hasBranchInRow = false

        if(currentBranch.branch) {

            // check for nested children
            if(currentBranch.branch.children) {
                hasBranchInRow = currentBranch.branch.children.find(q => q.children)
            }

            //check for immediate children
            else {
                hasBranchInRow = currentBranch.branch.find(q => q.children)
            }
        }

        // the the component whether or not the current question is a branch or if the current questions row already has a branch
        this.setState({
            hasBranch: nextProps.question.children ? true : false,
            hasBranchInRow: hasBranchInRow ? true : false
        })

    }

    render() {

        const { question, showController, toggleController } = this.props;

        return (
            <>

            <Modal
                className="modal-dialog-centered"
                isOpen={showController}
                toggle={() => toggleController()}
                size="ms"
                fade={false}
                id="modal-invoice"
            >

                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Create Invoice</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => toggleController()}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">

                 <FormGroup>
                        <label className="form-control-label">What Type Of Question Is This?</label>
                        <Input
                            value={question.type || ''}
                            type="select"
                            onChange={(e) => this.props.onQuestionInputChange(e, 'type')}
                        >
                            <option value="text">Text</option>
                            <option value="textarea">Large Text</option>
                            <option value="number">Number</option>
                            <option value="yes/no">Yes / No</option>
                        </Input>
                    </FormGroup>

                    <FormGroup>
                        <label className="form-control-label">What is the Question To Be Asked?</label>
                        <p className="text-sm mb-2">Ex: Have you filed bankruptcy before?</p>
                        <Input
                            style={{height: 75}}
                            value={question.text || ''}
                            type="textarea"
                            onChange={(e) => this.props.onQuestionInputChange(e, 'text')}
                        />
                    </FormGroup>

                    <FormGroup>
                        <label className="form-control-label">Question Hints.</label>
                        <p className="text-sm mb-2">Hints will be shown to staff members and contacts when filling out this form. They should answer commonly asked questions your firm has related to this question.</p>
                        <Input
                            style={{height: 75}}
                            value={question.hint || ''}
                            type="textarea"
                            onChange={(e) => this.props.onQuestionInputChange(e, 'hint')}
                        />
                    </FormGroup>

                    {this.renderBranchAbility()}


                </div>

                <div className="modal-footer text-right">
                    <button className="btn btn-success" onClick={this.updateQuestion}>Update Question</button>
                </div>

            </Modal>

            </>
        );
    }
}


TemplatesCrudController.propTypes = {
    question:               PropTypes.object.isRequired,
    showController:         PropTypes.bool.isRequired,

    questions:              PropTypes.array.isRequired,

    findBranch:             PropTypes.func.isRequired,
    toggleController:       PropTypes.func.isRequired,
    onQuestionInputChange:  PropTypes.func.isRequired,
    updateQuestion:         PropTypes.func.isRequired,

    depth:                  PropTypes.number,
    index:                  PropTypes.number,

}

export default TemplatesCrudController
