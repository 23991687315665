/*
Documentation

this page renders the views to update a workflows settings
right now there are only two options on this page
1) the workflow name
2) if the workflow is active -> this means a user can select it when creating a case

*/

import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardFooter, CardTitle } from "reactstrap";
import PropTypes from 'prop-types'
import _workflows from '_functions/workflows';
import { toggleAlertBS } from 'store/functions/system/system'
import validator from 'utils/validator';
import { connect } from 'react-redux';

import QuestionnaireSearchInput from 'components/system/migrated/QuestionnaireSearchInput'

const required_fields = [
    'type',
    'active',
]

class Settings extends Component {

    state = {
        workflow: this.props.workflow
    }

    onSave = async () => {

        this.setState({ noRoles: false, noSteps: false, noDivision: false})

        let errors = 0
        const validateFields = validator.requiredFields('workflow', required_fields, this)
        const newState = validateFields.state

        // send a warning if no roles have been created
        if(!newState.roles.length && newState.active === 'true') { this.setState({noRoles: true}); errors++; }

        // send a warning if no steps have been created
        if(!newState.steps.length && newState.active === 'true') { this.setState({noSteps: true}); errors++; }
        
        if(newState.division === 'false') { this.setState({noDivision: true}); errors++; }

        if(validateFields.success && !errors) {

            const updated = await _workflows.update(this.props.workflow._id, {
                type: newState.type,
                active: newState.active === 'true' || newState.active === true ? true : false,
                division: newState.division,
                questionnaire: newState.questionnaire,
            })

            toggleAlertBS(false, updated.success ? 'Workflow Updated Successfully.' : 'An error occurred updating this workflow.')

        }

    }


    render() {

        const workflow = this.state.workflow
        const { divisions, questionnaires } = this.props;
        
        const foundQuestionnaire = workflow.questionnaire ? questionnaires.find(q => q._id === workflow.questionnaire) : null;

        return (

        <>

            <Card className="card-color card-warning">

                <CardHeader>
                    <CardTitle className="mb-0 mt-2 float-left">Workflow: {workflow.type}</CardTitle>
                </CardHeader>

                <CardBody>
                    <p className="text-sm mb-0">This view controls the <b>overall settings</b> for the the workflow: "<b>{workflow.type}</b>"</p>
                </CardBody>

            </Card>

            {this.state.noRoles ? (
                <div className="alert alert-danger">You must create at least 1 role for this workflow before setting it to active.</div>
            ) : null}

            {this.state.noSteps ? (
                <div className="alert alert-danger">You must create at least 1 step for this workflow before setting it to active.</div>
            ) : null}

            {this.state.noDivision ? (
                <div className="alert alert-danger">You must assign a division to this workflow.</div>
            ) : null}

            <Card className="card-color card-warning">

                <CardHeader>
                    <CardTitle className="mb-0">Workflow Settings</CardTitle>
                </CardHeader>

                <CardBody>

                    <StandardFormGroup
                        obj={workflow}
                        objName="workflow"
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        formGroupClasses="w-50"
                        type="text"
                        field="type"
                        title="Name"
                    />

                    <StandardFormGroup
                        obj={workflow}
                        objName='workflow'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        formGroupClasses="w-50"
                        type="select"
                        field="division"
                        title="Workflow Division"
                    >
                        <option value="false"></option>
                        {divisions.map(division => (
                            <option value={division._id} key={division._id}>{division.name}</option>
                        ))}
                    </StandardFormGroup>

                    <StandardFormGroup
                        obj={workflow}
                        objName="workflow"
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], active: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        formGroupClasses="w-50"
                        type="select"
                        field="active"
                        title="Active"
                        description="If a workflow is active staff members may use it to create cases. For a workflow to be active it must have at least 1 step and 1 assigned role."
                        formatter={(value => {

                            if(value === true) { return 'true' } else if(value === false) {return 'false' }
                            return value

                        })}
                    >
                        <option value={false}>No</option>
                        <option value={true}>Yes</option>
                    </StandardFormGroup>

                    <QuestionnaireSearchInput
                        value={workflow.questionnaire ? questionnaires.find(q => q._id === workflow.questionnaire) : {}}
                        onSelect={(questionnaire) => this.setState({workflow: { ...workflow, questionnaire: questionnaire._id }})}
                        titleFormatter={() => 'Select Questionnaire'}
                    />
                    {foundQuestionnaire ? (
                        <p className="text-sm mb-0 mt-0">
                            Selected:{' '}
                            <b className="text-info-original">{foundQuestionnaire.name}</b>{' '}
                            <i onClick={() => this.setState({workflow: { ...workflow, questionnaire: null }})} className="fas fa-trash text-danger cursor-pointer ml-2 " /> 
                        </p>
                    ) : null }
                    

                </CardBody>

                <CardFooter className="text-right">
                    <button className="btn btn-success" onClick={this.onSave}>
                        <i className="fas fa-save mr-2" /> Save
                    </button>
                </CardFooter>

            </Card>


        </>

        )

    }

}


Settings.propTypes = {
    workflow: PropTypes.object.isRequired,
}


const mapStateToProps = state => {
    return {
        divisions: state.divisions.divisions,
        questionnaires: state.questionnaires.questionnaires,
    };
};

export default connect(mapStateToProps, '')(Settings);
