/*
Documentation

This file renders the left sidebar on the flow view
Here is renders all steps in alphabetical order, allows them to be updated on click
and also allows new steps to be added

*/

import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';

import PropTypes from 'prop-types'
import StepModal from './StepModal';

class FlowSidebar extends Component {

    state = {
        stepToEdit: {},
        showModal: false
    };

    toggle = (step) => this.setState({showModal: !this.state.showModal, stepToEdit: step ? step: {}})

    setSidebarHeight = () => {

        const height = window.innerHeight;
        let sidebarHeight = height - 48;

        document.getElementById('sidebar').style.height = sidebarHeight + 'px'

    }

    componentWillUnmount = () => {

        window.removeEventListener('resize', this.setSidebarHeight)

    }

    componentDidMount = () => {

        this.setSidebarHeight()
        window.addEventListener('resize', this.setSidebarHeight)

    }

    render() {

        const { allSteps, onStepSave, workflow, toggleSidebarDrawer } = this.props;
        const { stepToEdit, showModal } = this.state

        return (

            <div id="sidebar" className="sidebar">
                <Card>

                    <CardHeader className="bg-secondary">
                        <CardTitle className="mb-0">
                            Steps
                            <button onClick={() => this.toggle({})} className="btn btn-outline-success float-right">Add New</button>
                        </CardTitle>
                    </CardHeader>

                    <CardBody >
                        <ul>
                            {allSteps.map(step => (
                                <li key={step._id} onClick={() => toggleSidebarDrawer(step)}>
                                    <div className="step">{step.name}</div>
                                    <div className="icons text-sm">
                                        <span className={step.automations && step.automations.length ? 'text-success font-weight-bold' : ''}><i className="fas fa-route" /></span>
                                        <span className={step.set_client_status ? 'text-success font-weight-bold' : ''}><i className="fas fa-user" /></span>
                                        <span className={step.tags && step.tags.length ? 'text-success font-weight-bold' : ''}><i className="fas fa-tags" /></span>
                                        <span className={step.call_center_options && step.call_center_options.length ? 'text-success font-weight-bold' : ''}><i className="fas fa-headphones" /></span>
                                        <span className={step.tasks && step.tasks.length ? 'text-success font-weight-bold' : ''}><i className="fas fa-tasks" /></span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </CardBody>

                </Card>

                <StepModal
                    workflow={workflow}
                    onStepSave={onStepSave}
                    step={stepToEdit}
                    showModal={showModal}
                    toggle={this.toggle}
                />

            </div>

        )

    }

}

FlowSidebar.propTypes = {
    toggleSidebarDrawer : PropTypes.func.isRequired,
    workflow            : PropTypes.object.isRequired,
    onStepSave          : PropTypes.func.isRequired,
    allSteps            : PropTypes.array.isRequired,
}

export default FlowSidebar;
