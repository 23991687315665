import React from 'react';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, FormGroup, Input, Row } from 'reactstrap';
import States from 'components/markup/inputs/States';

const EmailSendersEdit = ({unsavedChanges, onInputChange, activeSender, onSave, err, onDeleteSender}) => (

    <>
    {unsavedChanges ? (
        <div className="alert alert-warning">This sender has unsaved changes</div>
    ) : null}

    <Card>

        <CardHeader>
            <Row>
                <Col lg={6} className="align-self-center">
                    <CardTitle className="mb-0">Edit Sender</CardTitle>
                </Col>
                <Col lg={6} className="align-self-center text-right" onClick={onDeleteSender}>
                    <button className="btn btn-outline-danger">
                        <i className="fas fa-plus mr-2 " /> Remove
                    </button>
                </Col>
            </Row>
        </CardHeader>

        <CardBody>

            <Row>
                <Col lg={6}>
                    <FormGroup>
                        <label className="form-control-label">Sender Name*</label>
                        <Input 
                            type="text"
                            value={activeSender.name || ''}
                            onChange={(e) => onInputChange('name', e.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col lg={6}>
                    <FormGroup>
                        <label className="form-control-label">Company Name*</label>
                        <Input 
                            type="text"
                            value={activeSender.company_name || ''}
                            onChange={(e) => onInputChange('company_name', e.target.value)}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col lg={6}>
                    <FormGroup>
                        <label className="form-control-label">Email Address*</label>
                        <Input 
                            type="text"
                            value={activeSender.email || ''}
                            onChange={(e) => onInputChange('email', e.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col lg={6}>
                    <FormGroup>
                        <label className="form-control-label">Reply To Email Address*</label>
                        <Input 
                            type="text"
                            value={activeSender.reply_to || ''}
                            onChange={(e) => onInputChange('reply_to', e.target.value)}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <hr />


            <Row>
                <Col lg={6}>
                    <FormGroup>
                        <label className="form-control-label">Phone Number*</label>
                        <Input 
                            type="text"
                            value={activeSender.phone || ''}
                            onChange={(e) => onInputChange('phone', e.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col lg={6}>
                    <FormGroup>
                        <label className="form-control-label">Website*</label>
                        <Input 
                            type="text"
                            value={activeSender.website || ''}
                            onChange={(e) => onInputChange('website', e.target.value)}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <hr />

            <Row>
                <Col lg={6}>
                    <FormGroup>
                        <label className="form-control-label">Address Line 1*</label>
                        <Input 
                            type="text"
                            value={activeSender.address_line_1 || ''}
                            onChange={(e) => onInputChange('address_line_1', e.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col lg={6}>
                    <FormGroup>
                        <label className="form-control-label">Address Line 2</label>
                        <Input 
                            type="text"
                            value={activeSender.address_line_2 || ''}
                            onChange={(e) => onInputChange('address_line_2', e.target.value)}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col lg={4}>
                    <FormGroup>
                        <label className="form-control-label">City*</label>
                        <Input 
                            type="text"
                            value={activeSender.city || ''}
                            onChange={(e) => onInputChange('city', e.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col lg={4}>
                    <FormGroup>
                        <label className="form-control-label">State*</label>
                        <Input 
                            type="select"
                            value={activeSender.state || ''}
                            onChange={(e) => onInputChange('state', e.target.value)}
                        >
                            <option value="false"></option>
                            <States />
                        </Input>
                    </FormGroup>
                </Col>
                <Col lg={4}>
                    <FormGroup>
                        <label className="form-control-label">Zip Code*</label>
                        <Input 
                            type="text"
                            value={activeSender.postal_code || ''}
                            onChange={(e) => onInputChange('postal_code', e.target.value)}
                        />
                    </FormGroup>
                </Col>
            </Row>

        </CardBody>

        {err ? (
            <CardFooter>
                {Array.isArray(err) ? (
                    err.map((e, i) => <p key={i} className="text-danger font-weight-bold mb-0">{e}</p>)
                ) : (
                    <p className="text-danger font-weight-bold mb-0">{err}</p>
                )}
            </CardFooter>
        ) : null}

        <CardFooter className="text-right">
            <button className="btn btn-success" onClick={onSave}>
                <i className="fas fa-save mr-2 " /> Save
            </button>
        </CardFooter>

    
    </Card>
    </>

)
export default EmailSendersEdit;