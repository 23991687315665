import ErrorUnknown from 'components/markup/errors/Unknown.js';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import Circle from 'components/markup/loading/Circle';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Card, CardBody, CardHeader, CardTitle, Container } from "reactstrap";
import _phone_numbers from '_functions/phone_numbers';
import ModalCreate from './ModalCreate';
import NotificationDelete from 'components/functional/notifications/Delete';

const PhoneNumbers = () => {

    const [phone_numbers, setPhoneNumbers] = useState(null);
    const [err, setErr] = useState(false);
    const [numberToDelete, setNumberToDelete] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const onToggleModal = () => setShowModal(!showModal);

    const onSuccess = () => {
        onToggleModal();
        fetchPhoneNumbers()
    }

    const fetchPhoneNumbers = async () => {

        const phone_numbers = await _phone_numbers.find();
        if(phone_numbers.data) {
            setPhoneNumbers(phone_numbers.data)
        } else {
            setErr(true);
        }

    }

    useEffect(() => {
        fetchPhoneNumbers();
    }, [])

    if(err) return <ErrorUnknown />
    if(!phone_numbers) return <Circle />

    return (

        <Container>

        {numberToDelete && (
            <NotificationDelete
                deletionURL={`/api/v1/call_center/phone_numbers/${numberToDelete._id}`}
                confirmBtnText="Delete Contact"
                title={<span className="text-capitalize">{`Delete ${numberToDelete.number}`}</span>}
                text="Deleting this number will remove it from associating a division to an incoming phone call. It can be added in later"
                onClose={() => setNumberToDelete(false)}
                onSuccess={fetchPhoneNumbers}
            />
        )}

            <Helmet>
                <title>{`Phone Numbers (${phone_numbers.length}) `}</title>
                <meta name="description" content="Phone Numbers" />
            </Helmet>

            <DashHeaderOpen
                title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
                breadcrumb_1="Phone Numbers"
                breadcrumb_2="All"
                actionComponent={(
                    <button onClick={onToggleModal} className="btn btn-success">
                        <i className="fas fa-plus mr-2 " />Add New
                    </button>
                )}
            />

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">All Phone Numbers</CardTitle>
                </CardHeader>

                {phone_numbers.length ? (

                    <div className="table-responsive">
                        <table className="table">
                            
                            <thead>
                                <tr>
                                    <th>Division</th>
                                    <th>Number</th>
                                    <th>Questionnaire</th>
                                    <th className="text-right">Edit</th>
                                </tr>
                            </thead>

                            <tbody>
                                {phone_numbers.map(number => (
                                    <tr key={number._id}>
                                        <td>{number.number}</td>
                                        <td>{number.division ? number.division.name : '-'}</td>
                                        <td>{number.questionnaire ? number.questionnaire.name : '-'}</td>
                                        <td className="text-right">
                                            <i onClick={() => setNumberToDelete(number)} className="fas fa-trash text-danger cursor-pointer " />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>

                        </table>
                    </div>

                ) : (
                    <CardBody>
                        <p className="text-sm mb-0">
                            No phone numbers have been created yet.{' '}
                            <b className="text-warning font-weight-bold cursor-pointer" onClick={onToggleModal}> 
                                Click here to create one.
                            </b>
                        </p>
                    </CardBody>
                )}

            </Card>

            <ModalCreate 
                showModal={showModal}
                toggleModal={onToggleModal}
                onSuccess={onSuccess}
            />

        </Container>

    )

}

export default PhoneNumbers