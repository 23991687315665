// findUnmovedStepsByAppointments
import { connect } from 'react-redux';
import Dots from 'components/markup/loading/Dots';
import moment from 'moment';
import React, { Component } from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Card, CardBody, CardHeader, CardTitle, Container, FormGroup, Input, Row, Col } from 'reactstrap';
import renderName from 'utils/renderName';
import { formatPhone } from 'utils/text';
import _analytics from '_functions/analytics';
import { toggleStandardLoader } from 'store/functions/system/system'
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';

import keys from 'keys';

class HiredToday extends Component {


    state = {
        data: null,
        start: moment().format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
        loading: true
    };


    columns = [
        {
            dataField: "contact",
            text: "Contact",
            filterValue: (cell, row) => renderName(row.contact),
            formatter: (cell, row) => (
                <a href={`${keys.APP_URL}/dashboard/contacts/view/${row.contact._id}`} target="_blank" rel="noopener noreferrer">
                    <div className="text-capitalize font-weight-bold text-dark">{renderName(row.contact)}</div>
                    {row.contact.phone ? <div>{}</div> : null}
                    {row.contact.email ? <div>{row.contact.email}</div> : null}
                </a>
            )

        },
        {
            dataField: "lead_source",
            text: "First Lead Source",
            formatter: (cell, row) => (
                row.lead_source ? row.lead_source.name : 'not found'
            )

        },
        {
            dataField: "status_marked_by",
            text: "Last Status Marked By",
            formatter: (cell, row) => (
                <span className="text-capitalize">{row.status_marked_by ? renderName(row.status_marked_by) : 'not found'}</span>
            )

        },
        {
            dataField: "phone",
            text: "Phone",
            formatter: (cell, row) => (
                row.contact && row.contact.phone ? formatPhone(row.contact.phone) : '-'
            )
        },
        {
            dataField: "email",
            text: "Email",
            formatter: (cell, row) => (
                row.contact && row.contact.email ? row.contact.email : '-'
            )
        },
        {
            dataField: "status",
            text: "Status",
        },

    ]

    pagination = paginationFactory({
        page: 1,
        sizePerPage: 25,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: true,
        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        value={currSizePerPage}
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    onQuery = async () => {

        const { start, end, division } = this.state;
        if(!start || !end) return;

        const startDate = moment(start).startOf('day').format('X');
        const endDate = moment(end).endOf('day').format('X');

        toggleStandardLoader(true);

        let filter = `?start=${startDate}&end=${endDate}`;

        if(division && division !== 'false') {
            filter += `&division=${division}`
        }

        const data = await _analytics.contacts.hired(filter);
        toggleStandardLoader(false);

        if(data.data) {
            this.setState({data: data.data, loading: false, noData: false})
        } else {
            this.setState({noData: start, data: null})
        }

    }

    componentDidMount = async () => {

        const startDate = moment().startOf('day').format('X');
        const endDate = moment().endOf('day').format('X');

        const data = await _analytics.contacts.hired(`?start=${startDate}&end=${endDate}`);
        if(data.data) this.setState({data: data.data, loading: false})

    }

    render() {

        const { data, loading, start, end, division } = this.state
        const { divisions } = this.props;

        if(loading) return (
            <div className="py-6 text-center"><h2>Loading Contacts hired Today<Dots /></h2></div>
        )

        return (

            <>
            <Container fluid>

                <Container fluid>
                    <DashHeaderOpen
                        title={<span><i className="fas fa-headset mr-2 text-success " />Analytics</span>}
                        breadcrumb_1="Contacts"
                        breadcrumb_2="Hires"
                    />
                </Container>

                <Card className="card-color card-primary">

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">Select Time Frame</CardTitle>
                    </CardHeader>

                    <CardHeader>


                        <FormGroup>
                            <label className="form-control-label">Division</label>
                            <Input 
                                type="select"
                                value={division || 'false'}
                                onChange={(e) => this.setState({division: e.target.value})}
                            >
                                <option value="false">All Divisions (No Filter)</option>
                                {divisions.map((division, i) => (
                                    <option key={i} value={division._id}>{division.name}</option>
                                ))}
                            </Input>
                        </FormGroup>

                        <Row>

                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Start</label>
                                    <Input 
                                        type="date"
                                        value={start || ''}
                                        onChange={(e) => this.setState({start: e.target.value})}
                                    />
                                </FormGroup>
                            </Col>

                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">End</label>
                                    <Input 
                                        type="date"
                                        value={end || ''}
                                        onChange={(e) => this.setState({end: e.target.value})}
                                    />
                                </FormGroup>
                            </Col>

                        </Row>

                      
                        <hr />
                        <div className="text-right">
                            <button className="btn btn-success" onClick={this.onQuery}>Get Stats</button>
                        </div>
                    </CardHeader>

                </Card>


                <Card className="card-color card-primary">

                    <CardHeader>
                            <Row>
                                
                                <Col lg={6}>
                                    <CardTitle className="mb-0 float-left">Hired In Range</CardTitle>

                                </Col>

                                <Col lg={6} className="text-right">
                                    <b className="text-warning">{moment(start).format('MM/DD/YYYY')} -  {moment(end).format('MM/DD/YYYY')}</b>
                                </Col>
                                
                            </Row>
                    </CardHeader>

                    {data.length ? (

                        <ToolkitProvider
                            data={data}
                            keyField="_id"
                            columns={this.columns}
                            search
                        >
                            {props => (
                                <div className=" table-responsive table-vertical-align">
                                    <BootstrapTable
                                        pagination={this.pagination}
                                        {...props.baseProps}
                                        bootstrap4={true}
                                        bordered={false}
                                    />
                                </div>
                            )}
                        </ToolkitProvider>

                    ) : (

                        <CardBody>
                            There is no data to show.
                        </CardBody>

                    )}
                </Card>

            </Container>
            </>

        )

    }

}

const mapStateToProps = state => {
    return {
        divisions: state.divisions.divisions,
    };
};

export default connect(mapStateToProps, '')(HiredToday);   