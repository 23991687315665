/*
Documentation

this page shows all automations within the system as well as gives a search bar
to search by a contact and see the automations assigned to that contact

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Container, Card, CardHeader, CardTitle, Modal, Badge } from 'reactstrap';
import NotificationDelete from 'components/functional/notifications/Delete';
import ContactSearch from 'components/system/migrated/ContactSearch';
import paginationFactory from "react-bootstrap-table2-paginator";

const { SearchBar } = Search;

class AutomationsAll extends React.Component {

    state = {
        automations: null,
        automation_to_delete: null,
        showSearchModal: false,
    }

    columns = [
        {
            dataField: "name",
            text: "Name",
            headerStyle: {width: 150},
            formatter: (cell, row) => <Link to={`/automations/automations/view/${row._id}`} className="text-capitalize font-weight-bold">{row.name}</Link>
        },
        {
            dataField: "running",
            text: "Running",
            headerStyle: {width: 150},
            formatter: (cell, row) => (
                row.running ? (
                    <Badge color="success" className="text-sm"><i className="fas fa-check mr-2" /> Running</Badge>
                ) : (
                    <Badge color="danger" className="text-sm"><i className="fas fa-times mr-2" /> Stopped</Badge>
                )
            )
        },

        {
            dataField: "_id",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <i onClick={() => this.setState({automation_to_delete: row})} className="fas fa-trash text-danger mr-3 cursor-pointer" />
                        <Link to={`/automations/automations/assigned/${row._id}`}><i className="fas fa-users text-primary mr-3 cursor-pointer" /></Link>
                        <Link to={`/automations/automations/view/${row._id}`}><i className="fas fa-edit text-success mr-2 cursor-pointer"></i></Link>
                    </div>
                )
            }
        },
    ]

    pagination = paginationFactory({
        page: 1,
        sizePerPage: 25,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: true,
        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        value={currSizePerPage}
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    toggleSearchModal = () => this.setState({showSearchModal: !this.state.showSearchModal})

    onSelect = (contact) => {

        this.setState({shouldRedirect: `/automations/automations/contact/${contact._id}`}, () => {
            this.toggleSearchModal()
        })

    }

    render() {

        const { automation_to_delete, showSearchModal } = this.state;
        const automations = this.props.automations

        if(this.state.shouldRedirect) return <Redirect to={this.state.shouldRedirect} />

        return (
        <>

            <Helmet>
                <title>{`Automations`}</title>
                <meta name="description" content="Tags" />
            </Helmet>

            {automation_to_delete && (
                <NotificationDelete
                    deletionURL={`/api/v1/automations/automations/delete/${automation_to_delete._id}`}
                    confirmBtnText="Delete Automation"
                    title={<span className="text-capitalize">{`Delete Automation: ${automation_to_delete.name}`}</span>}
                    text="Deleting this automation will immediately stop all actions from happening. Contacts assigned to this automation will not finish it's sequence."
                    onClose={() => this.setState({automation_to_delete: null})}
                    onSuccess={(object) => { } }
                />
            )}

            <Container fluid>

                <DashHeaderOpen
                    title={<span><i className="fas fa-magic mr-2 text-success " /> Automations</span>}
                    breadcrumb_1="Automations"
                    breadcrumb_2="All"
                    actionComponent={(
                        <>
                        <button onClick={this.toggleSearchModal} className="btn btn-outline-success">
                            <i className="fas fa-user mr-2" /> Search Contacts
                        </button>
                        <Link to="/automations/automations/view/new" className="btn btn-success">
                            <i className="fas fa-plus mr-2" /> Create
                        </Link>
                        </>
                    )}
                />

                <Card>

                    <CardHeader>
                        <CardTitle className="mb-0">All Automations</CardTitle>
                    </CardHeader>

                    <ToolkitProvider
                        data={automations}
                        keyField="_id"
                        columns={this.columns}
                        search
                    >
                        {props => (
                            <>
                            <div
                                id="datatable-basic_filter"
                                className="dataTables_filter px-4 pb pt-3 pb-2"
                                style={{width: '100%'}}
                            >
                                <label>
                                    Search:
                                    <SearchBar
                                        className="form-control-sm"
                                        placeholder="Case Name"
                                        {...props.searchProps}
                                    />
                                </label>
                            </div>
                            <div className=" table-responsive table-vertical-align table-not-fixed">
                                <BootstrapTable
                                    pagination={this.pagination}
                                    {...props.baseProps}
                                    bootstrap4={true}
                                    bordered={false}
                                />
                            </div>
                            </>
                        )}
                    </ToolkitProvider>

                </Card>

            </Container>

            <Modal
                className="modal-dialog-centered"
                isOpen={showSearchModal}
                toggle={() => this.toggleSearchModal()}
                size="md"
            >
                <div className="modal-header">

                    <h5 className="modal-title" id="exampleModalLabel">Find Contact</h5>

                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.toggleSearchModal()}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>

                </div>

                <div className="modal-body">
                    <ContactSearch onSelect={this.onSelect} />
                </div>

            </Modal>

            </>
        );
    }
}

const mapStateToProps = state => {

    return {
        automations: state.automations.automations,
    };
};

export default connect(mapStateToProps, '')(AutomationsAll);
