import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React, { useState } from "react";
import { Helmet } from 'react-helmet';
import { Card, Container, CardHeader, CardTitle, CardBody, CardFooter } from "reactstrap";
import _contacts from '_functions/contacts';

import ContactsAll from 'components/system/migrated/ContactsAll';
import ContactSearchInput from 'components/system/migrated/ContactSearchInput';
import ConfirmationModal from 'components/functional/modals/Confirmation.js';

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

import renderName from 'utils/renderName'
import { formatPhone } from 'utils/text'

const CommunicationBlackListed = () => {

    const [ showModal, setShowModal ] = useState(false);
    const [ view, setView ] = useState('all');
    const [ contact, setContact ] = useState({});

    const onConfirmation = async () => {

        if(!contact._id) return;
        if(!contact.phone) return toggleAlertBS('info', 'This contact does not have a phone number and cannot be added to the blacklist.');

        toggleStandardLoader(true);

        const updated = await _contacts.update(contact._id, {blacklisted: true});

        if(updated.success) {

            setView('all')
            setContact({});
    
            toggleStandardLoader(false);
            toggleAlertBS(false, 'Contact Successfully Added To The Blacklist');

        } else {

            toggleAlertBS(true, 'An error occurred adding this contact to the blacklist.');

        }

    }

    if(view === 'all') {
        return (
           <div>

                <Helmet>
                    <title>{`Blacklist`}</title>
                    <meta name="description" content="Blacklist" />
                </Helmet>

                <Container fluid >
                    <DashHeaderOpen
                        title={<span><i className="fas fa-headset mr-2 text-success " /> Communication</span>}
                        breadcrumb_1="Blacklist"
                        breadcrumb_2="View"
                        actionComponent={(
                            <button onClick={() => setView('add')} className="btn btn-success">
                                <i className="fas fa-plus mr-2 " /> Add To Blacklist
                            </button>
                        )}
                    />
                    
                </Container>

                <ContactsAll 
                    hideHeader={true} 
                    cardTitle="All Black Listed Contacts"
                    blacklisted={true}
                />
           </div>

        )
    } else {

        return (
            <div>

                <Container fluid >

                    <DashHeaderOpen
                        title={<span><i className="fas fa-headset mr-2 text-success " /> Communication</span>}
                        breadcrumb_1="Blacklist"
                        breadcrumb_2="Add"
                        actionComponent={(
                            <button onClick={() => setView('all')} className="btn btn-outline-warning">
                                <i className="fas fa-arrow-left mr-2 " /> Back
                            </button>
                        )}
                    />

                    <Card>
                        <CardHeader>
                            <CardTitle className="mb-0">Add To Blacklist</CardTitle>
                        </CardHeader>

                        <CardBody>
                            <ContactSearchInput 
                                value={contact}
                                onSelect={(contact) => setContact(contact)}
                            />
                        </CardBody>

                        <CardFooter className="text-right">
                            <button onClick={() => setShowModal(true)} className="btn btn-warning" disabled={contact._id ? false : true}>
                                Add Contact
                            </button>
                        </CardFooter>

                    </Card>

                    <ConfirmationModal
                        showModal={showModal}
                        toggleModal={() => setShowModal(!showModal)}
                        title="Add To Blacklist"
                        body={(
                            <span>
                                Are you sure you wish to add contact{' '}
                                <b className="text-dark font-weight-bold text-capitalize">{renderName(contact)}</b>{' '}
                                at the number{' '}
                                <b className="text-dark font-weight-bold text-capitalize">{formatPhone(contact.phone)}</b>{' '}
                                to the communication blacklist?
                            </span>
                        )}
                        onConfirmation={onConfirmation}
                    />
                                    
                </Container>

            </div>
        )

    }

}
export default CommunicationBlackListed
