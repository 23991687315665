import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input } from 'reactstrap';

import moment from 'moment';
import renderName from 'utils/renderName';
// import { formatMoney } from 'utils/currency'

import _analytics from '_functions/analytics'

import QueryCreator from './QueryCreator';

import { getUrlParameter } from 'utils/urls'
import { formatMoney } from 'utils/currency';

import ModalConsults from './ModalConsults'
import ModalToggler from 'components/functional/modals/Toggler'

const QueryPayments = ({lead_sources, viewing_user, brokers, call_centers, users}) => {

    const type = getUrlParameter('type') || 'created_at'

    const getSourceName = useCallback((s) => {
        if(!s) return '-'
        const found = lead_sources.find(source => source._id === s);
        return found ? found.name === 'Other' ? '' : <div>{found.name}</div> : '-'
    }, [lead_sources])

    useEffect(() => {
        document.body.classList.add('analytics-sidebar-small')
        return () => {
            document.body.classList.remove('analytics-sidebar-small')
        }

    }, [])

    if(!viewing_user.lead_source) return <div className="alert alert-warning text-center m-6 mt-8">Account Configuration Not Set Up, Contact Support.</div>

    return (
        <QueryCreator 
            title="Case Reports"
            searchPlaceholder="Name or ID"
            actionComponent={(
                <FormGroup className="mb-0">
                    <Input
                        value={type}
                        onChange={e => window.location.href = `/sources/cases?type=${e.target.value}`}
                        style={{maxWidth: 300, marginLeft: 'auto'}}
                        type="select"
                    >
                        <option value="created_at">Sort By Creation Date</option>
                        <option value="email_sent_at">Email Sent At</option>
                        <option value="call_scheduled_at">Call Scheduled At</option>
                        <option value="services_agreement_sent_at">Services Agreement Sent At</option>
                        <option value="retainer_signed_at">Retainer Signed At</option>
                        <option value="documents_submitted_at">Documents Submitted At</option>
                        <option value="report_sent_for_signature_at">Report Sent For Signature At</option>
                        <option value="submitted_to_irs_at">Submitted To IRS At</option>
                        <option value="payment_received_at">Payment Received At</option>
                    </Input>

                </FormGroup>
            )}
             
            runQuery={async (query, cb, download) => new Promise (async resolve => {

                if(!query.hasRun && type === 'email_sent_at') {
                    query.filter.email_sent_at = {
                        $gt: 0
                    }
                }
                if(!query.hasRun && type === 'call_scheduled_at') {
                    query.filter.call_scheduled_at = {
                        $gt: 0
                    }
                }
                if(!query.hasRun && type === 'services_agreement_sent_at') {
                    query.filter.services_agreement_sent_at = {
                        $gt: 0
                    }
                }
                if(!query.hasRun && type === 'retainer_signed_at') {
                    query.filter.retainer_signed_at = {
                        $gt: 0
                    }
                }
                if(!query.hasRun && type === 'documents_submitted_at') {
                    query.filter.documents_submitted_at = {
                        $gt: 0
                    }
                }
                if(!query.hasRun && type === 'report_sent_for_signature_at') {
                    query.filter.report_sent_for_signature_at = {
                        $gt: 0
                    }
                }
                if(!query.hasRun && type === 'submitted_to_irs_at') {
                    query.filter.submitted_to_irs_at = {
                        $gt: 0
                    }
                }
                if(!query.hasRun && type === 'payment_received_at') {
                    query.filter.payment_received_at = {
                        $gt: 0
                    }
                }

                if(!query.hasRun) {
                    query.filter.deleted = false;
                }

                query.filter.data_provider = viewing_user.lead_source

                query.sort = type;
                if(download) {

                    _analytics.query.caseReports(query)
                    return resolve(null)
                } else {
                    const result = await _analytics.query.cases(query)
                    return resolve(result.data)
                }
               
            })}
            inputs={[
                { type: 'ArchkDates', field: type, label: '', rangeTypes: [
                    {name: 'Creation Date', value: 'created_at'},
                    {name: 'Email Sent At', value: 'email_sent_at'},
                    {name: 'Call Scheduled At', value: 'call_scheduled_at'},
                    {name: 'Services Agreement Sent At', value: 'services_agreement_sent_at'},
                    {name: 'Retainer Signed At', value: 'retainer_signed_at'},
                    {name: 'Documents Submitted At', value: 'documents_submitted_at'},
                    {name: 'Report Sent For Signature At', value: 'report_sent_for_signature_at'},
                    {name: 'Submitted To IRS At', value: 'submitted_to_irs_at'},
                    {name: 'Payment Received At', value: 'payment_received_at'},
                ] },

                {
                    type: 'ArchkSelectSimple', field: 'original_current_step', label: 'Step Category',
                    data: [
                        { value: 'emailed', text: 'Emailed' },
                        { value: 'callScheduled', text: 'Call Scheduled' },
                        { value: 'callConducted', text: 'Call Conducted' },
                        { value: 'sentRetainer', text: 'Sent Retainer' },
                        { value: 'signedRetainer', text: 'Signed Retainer' },
                        { value: 'submitted', text: 'Submitted' },
                        { value: 'signature', text: 'Signature' },
                        { value: 'irs', text: 'IRS' },
                        { value: 'dead', text: 'Dead' },
                        { value: 'noShow', text: 'No Show' },
                    ]
                },


                // { type: 'ArchkNumber',  field: 'current_step_start', label: 'Step Start (UNIX Time)', },
                { type: 'ArchkNumber',  field: 'document_percentage', label: 'Document Percentage', },
                { type: 'ArchkNumber',  field: 'company_size', label: 'Company Size', },
                { type: 'ArchkNumber',  field: 'total_ertc', label: 'Total ERTC', },
                // { type: 'ArchkNull',  field: 'calculated_total_fee', label: 'Total Fees', },


    //             "last_note_preview": 1,
    // "last_viewed": 1,
    // "total_ertc": 1,
               
            ]}
            columns={[
                {
                    dataField: "case name",
                    text: "Name",
                    formatter: (cell, row) => (
                        <div>
                            {row.name} 
                            <div className="text-capitalize">{row.last_note_preview}</div>
                        </div>
                    ),
                },
                {
                    dataField: "contact",
                    text: "Contact",
                    formatter: (cell, row) => (
                        <div>
                            {row.contact ? (
                                renderName(row.contact)
                            ) : 'NOT FOUND'}
                        </div>
                    ),
                },
                {
                    dataField: "current_step",
                    text: "Step",
                    formatter: (cell, row) => {

                        const step = row.finished_by ? 'FINISHED' : row.current_step ? row.current_step.name : 'No Assigned Step';
                        return (
                            <div>
                                <div className="font-weight-bold">{step}</div>
                                <div><b className="text-dark">Started:</b> {moment.unix(row.current_step_start).format('M/DD/YYYY - h:mm A')}</div>
                            </div>

                        )

                    }
                },
                {
                    dataField: "document_percentage",
                    text: "Doc % & ERTC",
                    formatter: (cell, row) => (
                        <div className="font-weight-bold">
                            {row.document_percentage ? (
                                <span className={row.document_percentage >= 100 ? 'text-success' : row.document_percentage >= 0 ? 'text-info-original' : 'text-warning'}>
                                    {row.document_percentage.toFixed(2)}%
                                </span>
                            )
                            :  <span className="text-danger">0.00%</span>}
                            <div>{row.total_ertc ? formatMoney(row.total_ertc) : '$0.00'}</div>
                        </div>
                    )
                },
                // {
                //     dataField: "lead_sources",
                //     text: "Sources",
                //     formatter: (cell, row) => {
                //         return row.lead_sources ? row.lead_sources.map(source => (
                //             source === viewing_user.lead_source ? getSourceName(source) : ''
                //         )) : '-'
                //     }
                // },
                {
                    dataField: "consultations",
                    text: "created_at",
                    formatter: (cell, row) => (
                        <ModalToggler contact={row.contact} component={ModalConsults}>
                             <button className="btn btn-info" style={{height: 40}}>Check Consults</button>
                        </ModalToggler>
                    )
                },
                {
                    dataField: "updated_at",
                    text: "Size",
                    formatter: (cell, row) => row.company_size ? row.company_size : row.company_size_estimated
                },
                {
                    dataField: "_id",
                    // {name: 'Creation Date', value: 'created_at'},
                    // {name: 'Email Sent At', value: 'email_sent_at'},
                    // {name: 'Call Scheduled At', value: 'call_scheduled_at'},
                    // {name: 'Services Agreement Sent At', value: 'services_agreement_sent_at'},
                    // {name: 'Retainer Signed At', value: 'retainer_signed_at'},
                    // {name: 'Documents Submitted At', value: 'documents_submitted_at'},
                    // {name: 'Report Sent For Signature At', value: 'report_sent_for_signature_at'},
                    // {name: 'Submitted To IRS At', value: 'submitted_to_irs_at'},
                    // {name: 'Payment Received At', value: 'payment_received_at'},
                    text: 
                        type === 'email_sent_at' ? "Email Sent At" : 
                        type === 'call_scheduled_at' ? "Call Scheduled At" : 
                        type === 'services_agreement_sent_at' ? "Services Agreement Sent At" : 
                        type === 'retainer_signed_at' ? "Retainer Signed At" : 
                        type === 'documents_submitted_at' ? "Documents Submitted At" : 
                        type === 'report_sent_for_signature_at' ? "Report Sent For Signature At" : 
                        type === 'submitted_to_irs_at' ? "Submitted To IRS At" : 
                        type === 'payment_received_at' ? "Payment Received At" : 
                        "Created At",
                    headerStyle: {textAlign: 'right'},

                    formatter: (cell, row) => (
                        <div>
                            <div className="text-right">{moment.unix(row.created_at).format('MMM Do, YYYY')}</div>
                        </div>
                    )
                },

            ]}
        
        />
    )

}



const mapStateToProps = state => {
    return {
        users: state.users.users,
        brokers: state.brokers.brokers,
        call_centers: state.call_centers.call_centers,
        offices: state.offices.offices,
        workflows: state.workflows.workflows,
        divisions: state.divisions.divisions,
        tags: state.tags.tags,
        lead_sources: state.lead_sources.lead_sources,
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(QueryPayments);