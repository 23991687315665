/*
Documentation

This file is confusing but seems to work without error
!! Do not touch this file unless it gives an error


*/


import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';

import _workflow_steps from '_functions/workflow_steps';
import PropTypes from 'prop-types'

import InsertStep from './InsertStep';
import AnalysisModal from './AnalysisModal';

const maxUndoLoop = 30

class FlowTree extends Component {

    // we either have a can_move_to_steps which works as normal or a jump to steps, we cannot combine both
    state = {
        showAnalysis: false,
        showModalInsertStep: false,
        steps: [],
        treeMarkup: null

    };

    toggleAnalysis = () => this.setState({showAnalysis: !this.state.showAnalysis})

    onUndo = () => {

        let historyObject       = {}
        let stepHistoryObject   = {}
        let i                   = 0

        while (i < maxUndoLoop) {

            i++
            historyObject['history' + i] = this.state['history' + (i + 1)] ? Object.assign({}, this.state['history' + (i + 1)]) : null
            stepHistoryObject['stepHistory' + i] = this.state['stepHistory' + (i + 1)] ? [...this.state['stepHistory' + (i + 1)]] : null

        }

        this.setState({
            treeMarkup    : Object.assign({}, this.state.history1),
            steps         : [...this.state.stepHistory1],
            ...historyObject,
            ...stepHistoryObject
        }, this.props.resetHovers)

    }

    // this is called when a step is removed in the tree but not deleted
    removeStep = (step_id, parent) => {

        const steps = [...this.state.steps]

        let foundStep

        if(!parent) {

            // steps = steps.filter(s => s._id !== step_id)
            foundStep = Object.assign({}, steps.find(s => s._id === step_id))
            foundStep.is_first = false;

        } else {

            // remove the step from all moving and jumping array
            foundStep = Object.assign({}, steps.find(s => s._id === parent))
            foundStep.can_move_to_steps = foundStep.can_move_to_steps.filter(s => s !== step_id)

            if(foundStep.can_jump_to_steps && foundStep.can_jump_to_steps.length) {
                foundStep.can_jump_to_steps = foundStep.can_jump_to_steps.filter(s => s !== step_id)
            } else {
                foundStep.can_jump_to_steps = []
            }

        }

        steps[steps.findIndex(step => step._id === foundStep._id)] = foundStep

        let stepHistoryObject   = {}
        let i                   = 0

        while (i < maxUndoLoop) {

            i++
            stepHistoryObject['stepHistory' + (i + 1)] = this.state['stepHistory' + i] ? [...this.state['stepHistory' + i]] : null

        }

        this.setState({
            steps,
            stepHistory1: [...this.state.steps],
            ...stepHistoryObject
        }, this.renderTree)

    }

    // this should fire when a step is deleted from the database
    onDeleteStep = (deletedStep) => {

        if(!deletedStep) return

        let steps = [...this.state.steps]

        // remove the step from all moving and jumping array
        steps.forEach(step => {

            step.can_move_to_steps = step.can_move_to_steps.filter(s => s !== deletedStep._id )

            if(step.can_jump_to_steps && step.can_jump_to_steps.length) {
                step.can_jump_to_steps = step.can_jump_to_steps.filter(s => s !== deletedStep._id)
            } else {
                step.can_jump_to_steps = []
            }

        })

        let i = 0

        let stepHistoryObject   = {}
        let historyObject       = {}

        // remove history when deleting a step to prevent possible errors
        while (i < maxUndoLoop) {

            i++
            stepHistoryObject['stepHistory' + i] = null
            historyObject['historyObject' + i] = null

        }

        this.setState({
            steps,
            ...stepHistoryObject,
            ...historyObject,
        }, this.renderTree)

    }

    onSaveWorkflow = async () => {

        const { steps } = this.state;

        let compiledSteps = [];

        steps.forEach(step => {
            compiledSteps.push({
                _id: step._id,
                can_move_to_steps: step.can_move_to_steps,
                can_jump_to_steps: step.can_jump_to_steps,
                name: step.name,
                is_first: step.is_first ? true : false,
                hide_on_start: step.hide_on_start ? true : false,
                start_on_step: step.start_on_step ? true : false,
            })
        })

        toggleStandardLoader(true)

        const saved = await _workflow_steps.saveTree(compiledSteps)

        if(saved.success) {
            toggleAlertBS(false, `Workflow Saved Successfully`)
        } else {
            toggleAlertBS(false, `An Error Occurred Saving This Workflow`)
        }

        toggleStandardLoader(false)

    }

    toggleModalInsertStep = (parent) => this.setState({showModalInsertStep: !this.state.showModalInsertStep, parent})

    // when a step is selected to be inserted into the tree
    onInsertStepSelect = (values, type) => {

        const steps = [...this.state.steps];
        // let foundStep = Object.assign({}, steps.find(s => s._id === values.value))
        let foundStep = steps.find(s => s._id === values.value)

        if(foundStep) foundStep = { ...foundStep }

        if(values.value === this.state.parent) {
            console.log('values is parent')
            return // cannot move step to itself
        }

        if(this.state.parent) {

            if(!foundStep) {

                const allSteps = [...this.props.allSteps]

                const stepToPush = Object.assign({}, allSteps.find(s => s._id === values.value))
                steps.push(stepToPush)
            }

            let newStep = {...steps.find(s => s._id === this.state.parent)}

            if(!newStep) {
                console.log('step not found')
                console.log(steps)
                console.log(this.state.parent)
                console.log(values.value)
            }

            if(type === 'move') {

                if(newStep.can_move_to_steps.includes(values.value)) {

                    console.log('adding duplicate')

                } else {

                    let can_move_to_steps = [...newStep.can_move_to_steps]
                    can_move_to_steps.push(values.value)
                    newStep.can_move_to_steps = can_move_to_steps

                    steps[steps.findIndex(el => el._id === newStep._id)] = newStep

                }

            } else {

                if(!newStep.can_jump_to_steps) newStep.can_jump_to_steps = []
                if(newStep.can_jump_to_steps.includes(values.value)) {

                    console.log('adding duplicate')

                } else {

                    let can_jump_to_steps = [...newStep.can_jump_to_steps]
                    can_jump_to_steps.push(values.value)
                    newStep.can_jump_to_steps = can_jump_to_steps

                    steps[steps.findIndex(el => el._id === newStep._id)] = newStep

                }

            }

        } else {

            if(!foundStep) {

                steps.push({
                    _id: values.value,
                    name: values.label,
                    can_move_to_steps: [],
                    can_jump_to_steps: [],
                    is_first: true,
                })

            } else {

                foundStep.is_first = true
                steps[steps.findIndex(el => el._id === foundStep._id)] = foundStep

            }

        }

        let stepHistoryObject   = {}
        let i                   = 0


        while (i < maxUndoLoop) {

            i++

            stepHistoryObject['stepHistory' + (i + 1)] = this.state['stepHistory' + i] ? [...this.state['stepHistory' + i]] : null

        }

        this.setState({
            steps,
            stepHistory1: [...this.state.steps],
            ...stepHistoryObject
        }, this.renderTree)


        this.setState({steps}, this.renderTree)

    }

    onLiHover = (e) => {

        const dataTreeValues = e.target.getAttribute('data-tree')
        const dataTreeContent = document.getElementById('data-tree-content')
        const dataTree = document.getElementById('data-tree')

        if(dataTreeValues) {

            dataTreeContent.innerText = dataTreeValues
            dataTree.style.display = 'block'

        } else {

            dataTree.style.display = 'none'

        }

    }

    buildLI = (step, dataTree = '', parent = null) => {

        if(typeof step === 'string') {

            step =this.state.steps.find(s => s._id === step)
            if(!step)  step = this.props.allSteps.find(s => s._id === step)

        }

        let inPreviousStep = dataTree.includes(step.name)

        dataTree = !dataTree ? step.name : dataTree + ' -> ' + step.name

        const identifier = step._id + parent

        let canMoveTo = step.can_move_to_steps && step.can_move_to_steps.length && !inPreviousStep ? true : false
        let canJumpTo = step.can_jump_to_steps && step.can_jump_to_steps.length ? true : false

        return (
            <li key={identifier} className={canMoveTo || canJumpTo ? "flow-li canCollapse" : "flow-li"} data-tree={dataTree} data-id={step._id}>

                {canMoveTo || canJumpTo ? <span onClick={(e) => {
                    const parent = e.target.parentNode;

                    if(parent.style.overflow !== 'visible') {
                        parent.style.height = 'auto'
                        parent.style.overflow = 'visible';
                        parent.style.background = 'inherit';
                        parent.style.color = 'inherit';

                    } else {
                        parent.style.height = '25px';
                        parent.style.overflow = 'hidden';
                        parent.style.background = '#ddd'
                        parent.style.color = 'black'


                    }
                }} className="drop-toggler" /> : null}

                <span className="cursor-pointer" onClick={() => this.props.toggleSidebarDrawer(step)}>{step.name}</span>
                <i className="fas fa-trash text-danger ml-3 text-sm " onClick={() => this.removeStep(step._id, parent)} />

                {canMoveTo || canJumpTo ? (

                     <ul id={identifier} className="ml-0  flow-ul">

                        {canJumpTo ? step.can_jump_to_steps.map(jumpStep => {

                            if(typeof jumpStep === 'string') {

                                jumpStep =this.state.steps.find(s => s._id === jumpStep)
                                if(!jumpStep)  jumpStep = this.props.allSteps.find(s => s._id === jumpStep)

                            }

                            return (
                                <li key={jumpStep._id + step._id} className="flow-li is-jump" data-tree={dataTree + ' -> ' + jumpStep.name} data-id={jumpStep._id}>
                                    <i className="fas fa-running mr-2" />
                                    <span className="cursor-pointer" onClick={() => this.props.toggleSidebarDrawer(jumpStep)}>{jumpStep.name}</span>
                                    <i className="fas fa-trash text-danger ml-3 text-sm " onClick={() => this.removeStep(jumpStep._id, step._id)} />
                                </li>
                            )
                        }): null}

                        {canMoveTo ? step.can_move_to_steps.map(s => this.buildLI(s, dataTree, step._id)) : null}

                        <li className="add-step">
                            <button onClick={() => this.toggleModalInsertStep(step._id)} className="btn btn-sm btn-success">Insert New</button>
                        </li>

                    </ul>

                ) : (
                    <i onClick={() => this.toggleModalInsertStep(step._id)} className="cursor-pointer fas fa-code-branch text-warning ml-3" />
                )}

            </li>
        )

    }

    renderTree = () => {

        const steps = [...this.state.steps];

        let markup = []

        steps.forEach(step => { if(step.is_first) markup.push(this.buildLI(step)) })

        const treeMarkup = (
            <ul id="flow-base" className="ml-0 ul-first flow-ul" >
                {markup}
                <li className="add-step">
                    <button onClick={() => this.toggleModalInsertStep()} className="btn btn-sm btn-success">
                        <i className="fas fa-plus mr-1" />
                        Insert
                    </button>
                </li>
            </ul>
        )

        let historyObject = {}
        let i = 0

        while (i < maxUndoLoop) {
            i++
            historyObject['history' + (i + 1)] = this.state['history' + i] ? Object.assign({}, this.state['history' + i]) : null
        }

        this.setState({
            treeMarkup,
            history1: this.state.treeMarkup ? this.state.treeMarkup : null,
            ...historyObject
        }, this.props.resetHovers)

    }

    componentWillReceiveProps = (nextProps) => {

        const d1 = this.props.deletedStep
        const d2 = nextProps.deletedStep

        if(d1._id !== d2._id) this.onDeleteStep(d2)

        this.setState({steps: [...nextProps.allSteps]}, () => {
            this.renderTree()
        })

    }

    componentDidMount = () => {
        this.setState({steps: [...this.props.steps]}, this.renderTree)
    }

    render() {

        const { workflow, allSteps, setView, selectOptions } = this.props
        const { showModalInsertStep, treeMarkup, history1, stepHistory1, showAnalysis} = this.state

        return (

            <>

            <div className="flow">

                <Card className="mb-0">

                    <CardHeader>
                        <CardTitle className="mb-0">
                            Workflow: <b>{workflow.type}</b>

                            <button onClick={this.onSaveWorkflow} className="btn btn-success mx-2 float-right mb-2">
                                <i className="fas fa-save mr-2" /> Save Flow
                            </button>

                            <button onClick={this.toggleAnalysis} className="btn btn-outline-warning mx-2 float-right mb-2" >
                                Analyze
                            </button>
                            <button onClick={() => setView('router')} className="btn btn-outline-neutral mx-2 float-right mb-2" >
                                <i className="fas fa-arrow-left mr-2" /> Workflow
                            </button>
                            {history1 && stepHistory1 ? (
                                <button onClick={this.onUndo} className="btn btn-outline-danger mx-2 float-right mb-2">
                                    <i className="fas fa-undo mr-2" /> Undo
                                </button>
                            ) : null }

                        </CardTitle>
                    </CardHeader>

                    <CardBody>

                        <div id="data-tree" className="navigation z-depth-3" style={{display: 'none'}}>
                            <div id="data-tree-content" className="content text-sm"></div>
                        </div>

                        {treeMarkup ? treeMarkup : null}

                    </CardBody>

                </Card>

            </div>

            {allSteps && (
                <InsertStep
                    showModal={showModalInsertStep}
                    toggle={this.toggleModalInsertStep}
                    allSteps={allSteps}
                    onSelect={this.onInsertStepSelect}
                    selectOptions={selectOptions}
                />
            )}

            <AnalysisModal
                workflow_id={workflow._id}
                showAnalysis={showAnalysis}
                toggleAnalysis={this.toggleAnalysis}
            />

            </>

        )

    }

}

FlowTree.propTypes = {
    deletedStep           : PropTypes.object.isRequired,
    allSteps              : PropTypes.array.isRequired,
    steps                 : PropTypes.array.isRequired,
    workflow              : PropTypes.object.isRequired,
    resetHovers           : PropTypes.func.isRequired,
    setView               : PropTypes.func.isRequired,
    toggleSidebarDrawer   : PropTypes.func.isRequired,
    selectOptions         : PropTypes.array,
}

export default FlowTree;
