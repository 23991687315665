import apiWorker from '../apiWorker'

const analytics = {
    find              : ()          =>  apiWorker('get', `/api/affiliates`),
    create            : (data)      =>  apiWorker('post', `/api/affiliates`, data),
    update            : (_id, data) =>  apiWorker('patch', `/api/affiliates/${_id}`, data),
    updatePassword    : (_id, data) =>  apiWorker('patch', `/api/affiliates/${_id}/password`, data),
    delete            : (_id)       =>  apiWorker('delete', `/api/affiliates/${_id}`),
}

export default analytics;