import apiWorker from '../apiWorker'

const email_parsers = {
    find:       (query)                 => apiWorker('get',  `/api/v1/core/email_parsers/find${query}`),
    findById:   (email_parser_id)       => apiWorker('get',  `/api/v1/core/email_parsers/${email_parser_id}`),
    create:     (data)                  => apiWorker('post',  `/api/v1/core/email_parsers/create`, data),
    update:     (email_parser_id, data) => apiWorker('patch', `/api/v1/core/email_parsers/update/${email_parser_id}`, data),
    delete:     (email_parser_id)       => apiWorker('delete', `/api/v1/core/email_parsers/delete/${email_parser_id}`),
}

export default email_parsers;