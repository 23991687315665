/*
Documentation

This file shows the address fields when creating or or updating a user

*/

import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import States from 'components/markup/inputs/States';
import Countries from 'components/markup/inputs/Countries';
import React from "react";
import { FormGroup, Input } from "reactstrap";
import { connect } from 'react-redux';

const LeadSource = ({user, lead_sources, onChange}) => (
    <div>
        <FormGroup>
            <Input 
                type="select"
                value={user.lead_source}
                onChange={(e) => onChange('lead_source', e.target.value)}
            >
                <option value="false"></option>
                {lead_sources.map(source => (
                    <option key={source._id} value={source._id}>{source.name}</option>
                ))}
            </Input>
        </FormGroup>
    </div>
)
const mapStateToProps = state => {
    return {
        lead_sources: state.lead_sources.lead_sources,
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(LeadSource);