/*
Documentation

this file renders the right hand sidebar on all automations
here we can change that automation type and updated individual
tasks within the automation

*/

import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class AutoSidebarTypeWait extends Component {

    render() {

        const { task, onChange } = this.props

        if(task.type !== 'wait') return null

        return (

            <li>
                <StandardFormGroup
                    obj={task}
                    objName='task'
                    onChange={onChange}
                    type="number"
                    field="days"
                    formGroupClasses="label-block"
                    title="Days"
                />
                <StandardFormGroup
                    obj={task}
                    objName='task'
                    onChange={onChange}
                    type="number"
                    field="hours"
                    formGroupClasses="label-block"
                    title="Hours"
                />
                <StandardFormGroup
                    obj={task}
                    objName='task'
                    onChange={onChange}
                    type="number"
                    field="minutes"
                    formGroupClasses="label-block"
                    title="Minutes"
                />
            </li>

        )

    }

}

AutoSidebarTypeWait.propTypes = {
    task    : PropTypes.object.isRequired,
    onChange         : PropTypes.func.isRequired,
}


export default AutoSidebarTypeWait
