const HighLevel = () => (


        <iframe 
        title="test"
        src="https://crm.bizheadlaw.com/agency_dashboard"
        style={{
            position: 'fixed',
            left: '50px',
            top: '53px',
            outline: 'none',
            bottom: 53,
            color: 'red',
            bottom: '59px',
            right: 0,
            height: 'calc(100% - 53px)',
            width: 'calc(100% - 51px)',
            zIndex: 999999999,
            border: 'none',
        }}
    >
    </iframe>

)
export default HighLevel