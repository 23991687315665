import PropTypes from 'prop-types';

import React, { useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Row, Col, FormGroup, Input, CardFooter } from "reactstrap";

import InfoModal from './Info'

const FieldSet = ({index, onAdd, onRemove, field, isNew}) => {

    const [text, setText ] = useState(field.text || '');
    const [prefill, setPrefill ] = useState(field.prefill || '');
    const [required, setRequired ] = useState(field.required !== undefined ? field.required :  true);

    React.useEffect(() => {
        setText(field.text || '');
        setPrefill(field.prefill || '');
        setRequired(field.required !== undefined ? field.required :  true);
    }, [field.modified])

    const onSave = () => {

        if(!text) return;

        onAdd(index, { text, prefill, required })
        setText('')
        setPrefill('')

    }

    const _handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			document.getElementById("archk-field-save-button").click();
		}
	}

    return (
        <Row>

            <Col lg={4}>
                <FormGroup>
                    <Input 
                        id={isNew ? 'archk-field-first-input-button' : undefined}
                        placeholder={isNew ? 'Client First Name' : ''}
                        type="text"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        onKeyDown={_handleKeyDown}
                    />
                </FormGroup>
            </Col>

            <Col lg={4}>
                <Input 
                    placeholder={isNew ? '{first_name}' : ''}
                    type="text"
                    value={prefill}
                    onChange={(e) => setPrefill(e.target.value)}
                    onKeyDown={_handleKeyDown}
                />
            </Col>

            <Col lg={2}>
                <div className="custom-control custom-checkbox text-center">
                    <input
                        checked={required}
                        onChange={() => setRequired(!required)}
                        className="custom-control-input"
                        id={`template_word_doc_required_${index}`}
                        type="checkbox"
                    />
                    <label className="custom-control-label" htmlFor={`template_word_doc_required_${index}`} />
                </div>
            </Col>

            {isNew ? (
                <Col lg={2}>
                    <button id="archk-field-save-button" style={styles.button} className="btn btn-success btn-block" onClick={onSave}>Save</button>
                </Col>
            ) : (
                <>
                    <Col lg={1}>
                        <button style={styles.button} className="btn btn-outline-danger btn-block" onClick={() => onRemove(index)}><i className="fas fa-trash " /></button>
                    </Col>
                    <Col lg={1}>
                        <button style={styles.button} className="btn btn-outline-success btn-block" onClick={onSave}>Save</button>
                    </Col>
                </>
            )}
        </Row>
    )
}

class WordDocsView extends React.Component {

    state = {
        showModal: false
    }

    toggleModal = () => this.setState({showModal: !this.state.showModal})

    onRemove = (index) => {
        this.props.onFieldRemoved(index)
    }

    onAdd = (index, field) => {

        this.props.onFieldChange(index, field)

    }

    render() {

        const { documentData, fields, onSave, name, onNameChange } = this.props
        const { showModal } = this.state

        return (


            <Card style={{display: documentData ? 'block' : 'none'}}>

                <CardHeader>
                    <CardTitle className="mb-0">Document Name</CardTitle>
                </CardHeader>

                <CardFooter>
                    <FormGroup>
                        <Input 
                            type="text"
                            value={name}
                            onChange={(e) => onNameChange(e.target.value)}
                        />
                    </FormGroup>
                </CardFooter>

                <CardHeader className="bg-secondary">
                    <CardTitle className="mb-0">Add Document Fields</CardTitle>
                </CardHeader>

                <CardHeader>
                    <Row>
                        <Col lg={4}>
                            <p className="text-sm mb-0">Question Text</p>
                        </Col>
                        <Col lg={4}>
                            <p className="text-sm mb-0">
                                Prefill Question From: 
                                <i  onClick={this.toggleModal} className="fas fa-info-circle text-info-original ml-2" />
                            </p>
                        </Col>
                        <Col lg={2}>
                            <p className="text-sm mb-0 text-center">
                                Required: 
                            </p>
                        </Col>
                    </Row>
                </CardHeader>

                <CardBody className="">

                    {fields.map((field, i) => (
                        <FieldSet 
                            key={i.modified}
                            field={field}
                            onAdd={this.onAdd}
                            onRemove={this.onRemove}
                            index={i}
                            isNew={false}
                        />
                    ))}
                    
                    <FieldSet 
                        onAdd={this.onAdd}
                        onRemove={this.onRemove}
                        index={fields.length}
                        field={{}}
                        isNew={true}
                    />
                    
                </CardBody>

                <InfoModal 
                    showModal={showModal}
                    toggleModal={this.toggleModal}
                />

                <CardFooter className="text-righ" >
                    <button className="btn btn-success" onClick={onSave}>
                        <i className="fas fa-save mr-2" />
                        Save Document
                    </button>
                </CardFooter>

            </Card>


        );
    }
}

const styles = {
    button: {
        height: 36
    }
}

WordDocsView.propTypes = {
    onSave            : PropTypes.func.isRequired,
    onFieldRemoved    : PropTypes.func.isRequired,
    onFieldChange     : PropTypes.func.isRequired,
    documentData      : PropTypes.object,
}

export default WordDocsView
