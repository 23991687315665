import React, {useEffect, useState, useCallback} from 'react';
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';

import { Card,  CardHeader, Container, CardTitle, CardBody, CardFooter, FormGroup, Input } from "reactstrap";
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import Circle from 'components/markup/loading/Circle';
import ReactQuill from "react-quill";


import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

import _wikis from '_functions/wikis';

const View = ({match}) => {

    const [err, setErr] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [redirect, setRedirect] = useState(false)

    const [state, setState] = useState({
        body: '',
        subject: '',
    });

    const onSave = useCallback(async () => {

        if(match.params._id === 'new') {

            toggleStandardLoader(true)
            const created = await _wikis.create(state);
            toggleStandardLoader(false)
            if(created.success) {
                toggleAlertBS(false, 'Wiki Created Successfully');
                setRedirect('/core/wikis')
            } else {
                setErr(created.message);
            }

        } else {

            toggleStandardLoader(true)
            const updated = await _wikis.update(match.params._id, state);
            toggleStandardLoader(false)
            if(updated.success) {
                toggleAlertBS(false, 'Wiki Updated Successfully');
            } else {
                setErr(updated.message);
            }

        }

    }, [state.subject, state.body, match.params._id])

    const onSetState = (field, e) => {
        const newState = JSON.parse(JSON.stringify(state));
        const value = e.target.value;
        newState[field] = value;
        setState(newState);
    }

    const fetchWiki = useCallback(async (_id) => {
        const data = await _wikis.findById(_id);
        if(data.data) {
            setState({
                body: data.data.body,
                subject: data.data.subject,
            })
            setLoaded(true);
        } else {
            setRedirect('/core/wikis');
        }
    }, [])

    useEffect(() => {
        if(match.params._id === 'new') return setLoaded(true)
        fetchWiki(match.params._id)
    }, [match.params._id, fetchWiki])

    if(redirect) return <Redirect to={redirect} />
    if(!loaded) return <Circle />

    return (
        <Container fluid>

            <Helmet>
                <title>Wiki Create</title>
                <meta name="description" content="Wiki Create" />
            </Helmet>

            <DashHeaderOpen
                title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
                breadcrumb_1="Courts"
                breadcrumb_2="Create"
                actionComponent={(
                    <Link to="/core/wikis" className="btn btn-outline-warning"><i className="fas fa-arrow-left" /> Back</Link>
                )}
            />

            <Card  className="card-color card-success">

                <CardHeader>
                    <CardTitle className="mb-0 float-left">Add / Update Wiki</CardTitle>
                </CardHeader>

                <CardBody className="ql-editor-border">

                    <FormGroup>
                        <label className="form-control-label">Subject</label>
                        <Input 
                            type="text"
                            value={state.subject}
                            onChange={(e) => onSetState('subject', e)}
                            />
                    </FormGroup>

                    <label className="form-control-label">Body</label>

                    <ReactQuill
                        value={state.body}
                        onChange={(value) => onSetState('body', {target: {value}})}
                        theme="snow"
                        modules={{
                            toolbar: [
                                ["bold", "italic"],
                                ["link",],
                                [
                                    { list: "ordered" },
                                    { list: "bullet" }
                                ]
                            ]
                        }}
                    />
                </CardBody>

                {err ? (
                    <CardFooter>
                        <p className="text-sm mb-0 text-warning">{err}</p>
                    </CardFooter>
                ) : null}

                <CardFooter className="text-right">
                    <button className="btn btn-success" onClick={onSave}>Save</button>
                </CardFooter>

            </Card>
        </Container>
    )
}

export default View;