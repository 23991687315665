import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import renderName from 'utils/renderName'

import { formatPhone } from 'utils/text'

import { Card, CardFooter, Badge, CardHeader, CardTitle, Row, Col, CardBody, FormGroup, Input } from 'reactstrap';

import { setUsers } from 'store/functions/users/users'

import moment from 'moment';

const OverviewOverview = ({data, users, startOfDay, setStartOfDay}) => {
    const hasOnCall = users.some(user => user.call_status === 'on call');

    useEffect(() => {
        const interval = setInterval(setUsers, 10000);
        return () => {
            clearInterval(interval)
        }
    }, [])

    return (
        <Card>
    
            <CardHeader>
                <Row>
                    <Col lg={6}>
                        <CardTitle className="mb-0">Monitoring Overview - {moment(startOfDay).format('MMM Do, YYYY')}</CardTitle>
                    </Col>
                    <Col lg={6}>
                        <FormGroup>
                            <Input 
                                type="date"
                                value={startOfDay}
                                onChange={(e) => setStartOfDay(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </CardHeader>
    
            <CardBody>
                <Row>
                    <Col xs={2}><p className="text-sm mb-0">Hires: {data.hiredLogs.length}</p></Col>
                    <Col xs={2}><p className="text-sm mb-0">Calls: {data.callLogs.docs.length}</p></Col>
                    <Col xs={2}><p className="text-sm mb-0">Queue: {data.queue.newContacts + data.queue.business + data.queue.appointments + data.queue.callQueue}</p></Col>
                    <Col xs={2}><p className="text-sm mb-0">On Hold: {data.contactsOnHold.length}</p></Col>
                    <Col xs={2}><p className="text-sm mb-0">Messenger: {data.messenger}</p></Col>
                    <Col xs={2}><p className="text-sm mb-0">Overdue: {data.tasks.length}</p></Col>
                </Row>
            </CardBody>
    
            <CardFooter>
                {hasOnCall ? users.map(user => user.call_status === 'on call' ? (
                    <Badge className="badge-info-original" color="info">
                        {user.given_name.slice(0,1)}{user.family_name.slice(0,1)}
    
                        {' - '} 
                        
                        {user.call_contact ? user.call_contact.given_name || user.call_contact.family_name ? renderName(user.call_contact) : formatPhone(user.call_contact.phone) : '-'}
                    </Badge>
                ) : null) : (
                    <Badge color="warning">No Users On Call</Badge>
                )}
            </CardFooter>
        
        </Card>
    )
}
// {row.call_contact ? renderName(row.call_contact) : '-'}
// {row.call_contact && row.call_contact.phone ? <span> - {formatPhone(row.call_contact.phone)}</span> : 'null'}

const mapStateToProps = state => {
    return {
        users     : state.users.users,
    };
};

export default connect(mapStateToProps, '')(OverviewOverview);