import moment from 'moment';
const timeByValue = (val) => {
    const day = 86400;

    let end = parseInt(moment().endOf('day').format('X'))
    let start;

    switch (val) {
        case "Today": {
            start = end - (day * 1);  
            break;
        }
        case "Yesterday": {
            start = end - (day * 2);
            end = end - (day * 1);
            break;
        }
        case "This Week": {
            start = parseInt(moment().startOf('week').format('X'))
            break;
        }
        case "Last Week": {
            start = parseInt(moment().subtract(1, 'week').startOf('week').format('X'))
            end = parseInt(moment().subtract(1, 'week').endOf('week').format('X'))
            break;
        }
        case "This Month": {
            start = parseInt(moment().startOf('month').format('X'))
            break;
        }
        case "Last Month": {
            start = parseInt(moment().subtract(1, 'month').startOf('month').format('X'))
            end = parseInt(moment().subtract(1, 'month').endOf('month').format('X'))
            break;
        }
        case "This Quarter": {
            start = parseInt(moment().startOf('quarter').format('X'))
            end = parseInt(moment().endOf('quarter').format('X'))
            break;
        }
        case "Last Quarter": {
            start = parseInt(moment().subtract(1, 'quarter').startOf('quarter').format('X'))
            end = parseInt(moment().subtract(1, 'quarter').endOf('quarter').format('X'))
            break;
        }
        case "Last 180 Days": start = end - (day * 180);  break;
        case "Last 365 Days": start = end - (day * 365);  break;


        default: 
            if(val.includes('-')) {
                const split = val.split('-');
                start = parseInt(split[0]);
                end = parseInt(split[1]);
            }
        ;
    }

    return {$gte: start, $lt: end}

}


export default timeByValue