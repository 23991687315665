import React from 'react';
import { StandAlone } from './_components';

const SidebarCommunication = (props) => (
    <ul className="mb-0">
        <StandAlone route="/communication/call_center_admin" name="Call Center" {...props} />
        <StandAlone route="/communication/overview" name="Overview" {...props} />
        {/* <StandAlone route="/communication/phone_numbers" name="Phone Numbers" {...props} /> */}
        {/* <StandAlone route="/communication/call_logs_incoming" name="Incoming Calls" {...props} />
        <StandAlone route="/communication/call_logs_outgoing" name="Outgoing Calls" {...props} /> */}
        <StandAlone route="/communication/text_opt_out" name="Text Opt Outs" {...props} />
        <StandAlone route="/communication/email_opt_out" name="Email Opt Outs" {...props} />
        <StandAlone route="/communication/blacklisted" name="Blacklisted" {...props} />
    </ul>
)

export default SidebarCommunication;
