import React, { memo } from 'react';
import classnames from 'classnames'
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import QuestionList from './QuestionList';

const ColFlow = ({questions, setMasterQuestions, onSelect, newQuestion, onBack, prevQuestionLength, onDragEnd, onDragStart, onQuestionAdded, page, onPageChange}) => {

    return (

        <Col lg={4} className="col col-flow">

            <Row className="archk-col-header">
                <Col lg={6} className="align-self-center">
                    <h3>Questionnaire Flow</h3>
                </Col>
                <Col lg={6} className="text-right align-self-center">
                    {prevQuestionLength ? (
                    <button className="btn btn-outline-warning" onClick={onBack}>
                        <i className="fas fa-arrow-left mr-2 " /> Back ({prevQuestionLength})
                    </button>
                    ) : null}
                </Col>
            </Row>

            <Nav className="nav-fill flex-column flex-sm-row my-0" id="tabs-text" pills role="tablist" >
                <NavItem>
                    <NavLink
                        aria-selected={page === 0}
                        className={classnames("mb-sm-3 mb-md-0 cursor-pointer", { active: page === 0 })}
                        onClick={e => onPageChange(0)} 
                        role="tab" 
                    >
                        P1
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        aria-selected={page === 1}
                        className={classnames("mb-sm-3 mb-md-0 cursor-pointer", { active: page === 1 })}
                        onClick={e => onPageChange(1)} 
                        role="tab" 
                    >
                        P2
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        aria-selected={page === 2}
                        className={classnames("mb-sm-3 mb-md-0 cursor-pointer", { active: page === 2 })}
                        onClick={e => onPageChange(2)} 
                        role="tab" 
                    >
                        P3
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        aria-selected={page === 3}
                        className={classnames("mb-sm-3 mb-md-0 cursor-pointer", { active: page === 3 })}
                        onClick={e => onPageChange(3)} 
                        role="tab" 
                    >
                        P4
                    </NavLink>
                </NavItem>
            </Nav>

            <hr className="my-3" />

            <DndProvider backend={HTML5Backend}>
                <QuestionList 
                    allQuestions={questions}
                    nestedLevel="1"
                    parentTree={[]}
                    setMasterQuestions={setMasterQuestions}
                    onSelect={onSelect}
                    newQuestion={newQuestion}
                    onDragEnd={onDragEnd}
                    onDragStart={onDragStart}
                    onQuestionAdded={onQuestionAdded}
                    page={page}
                />
            </DndProvider>

        </Col>
      
    )
};

export default memo(ColFlow)