import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardFooter, CardHeader, CardTitle, Col,  FormGroup, Input, Row, Badge } from 'reactstrap';
import renderName from 'utils/renderName';
import UserSearchInput from 'components/system/migrated/UserSearchInput';
import _analytics from '_functions/analytics';
import fetchAllWorkflowSteps from '_functions/workflow_steps/fetchAll';

const AppointmentsByStepQuery = ({groupings, tags, lead_sources, setData}) => {

    const [ stepData, setStepData ] = useState([]);

    const [ state, setState ] = useState({
        grouping: null,
        start: moment().startOf('day').format('YYYY-MM-DD'),
        user_1: {},
        user_2: {},
        user_3: {},
        step_id: null,
        tag: null,
        lead_source: null
    })

    const onStateChange = (obj) => setState({...state, ...obj});

    const onRemoveUser = (stateName) => {

        const newState = Object.assign({}, state);

        if(stateName === 'user_2') {
            newState.user_2 = newState.user_3;
        }
        if(stateName === 'user_1') {
            newState.user_1 = newState.user_2;
            newState.user_2 = newState.user_3;
        }

        newState.user_3 = {};

        setState(newState);

    }
    
    const onAddUser = (user) => {

        const newState = Object.assign({}, state);

        if(newState.user_3._id) {
            newState.user_1 = user;
        } else if(newState.user_2._id) {
            newState.user_3 = user;
        } else if(newState.user_1._id) {
            newState.user_2 = user;
        } else {
            newState.user_1 = user;
        }

        setState(newState);

    }

    const fetchWorkflowSteps = async () => {
        const data = await fetchAllWorkflowSteps();
        return setStepData(data);
    }

    const onQuery = async () => {

        const { start, user_1, grouping, tag, lead_source, step_id } = state;
        if(!start) return;

        const startDate = moment(start).startOf('day').format('X');

        let query = `?start=${startDate}`;
        
        if(user_1._id) {
            query += `&user_1=${user_1._id}`;
        }
        if(user_2._id) {
            query += `&user_2=${user_2._id}`;
        }
        if(user_3._id) {
            query += `&user_3=${user_3._id}`;
        }

        if(tag && tag !== 'null') {
            query += `&tag_id=${tag}`;
        }
        if(step_id && step_id !== 'null') {
            query += `&step_id=${step_id}`;
        }

        if(lead_source && lead_source !== 'null') {
            query += `&lead_source_id=${lead_source}`;
        }

        if(grouping && grouping !== 'null') {
            query += `&grouping_id=${grouping}`
        }

        setData({ loading: true })

        let data = await _analytics.appointments.byStep(query);

        setData({
            loading: false,
            error: data.message,
            data: data ? data.data : null,
            queryInfo: { start, user_1, user_2, user_3, grouping }
        })

    }

    useEffect(() => {
        fetchWorkflowSteps()
        onQuery()
    }, [])

    const { start, user_1, user_2, user_3, grouping, tag, lead_source } = state;

    return (

        <Card>

            <CardHeader>
                <CardTitle className="mb-0 float-left">Appointment Analytics</CardTitle>
            </CardHeader>

            <CardHeader>

                <Row>
                    <Col lg={6} md={6}>
                        <FormGroup>
                            <label className="form-control-label">Start</label>
                            <Input 
                                type="date"
                                value={start || ''}
                                onChange={(e) => onStateChange({start: e.target.value})}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={6} md={6}>
                        <FormGroup>

                            <label className="form-control-label">
                                {user_3._id ? '3/3' : user_2._id ? '2/3' : user_1._id ? '1/3' : ''}
                                {' '}
                                {user_1._id ? (
                                    <span className="text-capitalize">
                                        <Badge color="warning text-sm mr-2" style={{position: 'relative', top: -5}}>
                                            {renderName(user_1)}{' '}
                                            <i className="fas fa-times ml-2 text-danger cursor-pointer " onClick={() => onRemoveUser('user_1')} />
                                        </Badge>
                                    </span>
                                ): !user_1._id && !user_3._id && !user_3._id ? 'Select User' : ''}
                                {user_2._id ? (
                                    <span className="text-capitalize">
                                        <Badge color="warning text-sm mr-2" style={{position: 'relative', top: -5}}>
                                            {renderName(user_2)}{' '} 
                                            <i className="fas fa-times ml-2 text-danger cursor-pointer " onClick={() => onRemoveUser('user_2')} />
                                        </Badge>
                                        
                                    </span>
                                ): ''}
                                {user_3._id ? (
                                    <span className="text-capitalize">
                                        <Badge color="warning text-sm mr-2" style={{position: 'relative', top: -5}}>
                                            {renderName(user_3)}{' '}
                                            <i className="fas fa-times ml-2 text-danger cursor-pointer " onClick={() => onRemoveUser('user_3')} />
                                        </Badge>
                                    </span>
                                ): ''}
                            </label>

                            <UserSearchInput
                                hideTitle={true}
                                value={user_1 ? user_1 : {}}
                                onSelect={(user) => onAddUser(user)}
                            />

                        </FormGroup>
                    </Col>



                    <Col lg={3} md={6}>
                        <FormGroup>
                            <label className="form-control-label">Appointment Type</label>
                            <Input 
                                type="select"
                                value={grouping || ''}
                                onChange={(e) => onStateChange({grouping: e.target.value})}
                            >
                                <option value="null"></option>
                                {groupings.map((grouping, i) => (
                                    <option key={i} value={grouping._id}>{grouping.name}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>

                    <Col lg={3} md={6}>
                        <FormGroup>
                            <label className="form-control-label">Contact Tag</label>
                            <Input 
                                type="select"
                                value={tag || ''}
                                onChange={(e) => onStateChange({tag: e.target.value})}
                            >
                                <option value="null"></option>
                                {tags.map((tag, i) => (
                                    <option key={i} value={tag._id}>{tag.name}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>

                    <Col lg={3} md={6}>
                        <FormGroup>
                            <label className="form-control-label">Lead Source</label>
                            <Input 
                                type="select"
                                value={lead_source || ''}
                                onChange={(e) => onStateChange({lead_source: e.target.value})}
                            >
                                <option value="null"></option>
                                {lead_sources.map((lead_source, i) => (
                                    <option key={i} value={lead_source._id}>{lead_source.name}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>
                    
                    <Col lg={3} md={6}>
                        <FormGroup>
                            <label className="form-control-label">Workflow Step</label>
                            <Input 
                                type="select"
                                value={state.step_id || ''}
                                onChange={(e) => onStateChange({step_id: e.target.value})}
                                valid={ state.step_idState === "valid" }
                                invalid={ state.step_idState === "invalid" }
                            >
                                <option value="null"></option>
                                {stepData.length ? stepData.map(data => (
                                    <React.Fragment key={data.workflow._id}>
                                    <option  disabled>WORKFLOW: {data.workflow.type}</option>
                                    {data.steps.map(step => (
                                        <option key={step._id} value={step._id}>{step.name}</option>
                                    ))}
                                    </React.Fragment>
                                )): (
                                    <option>You have no steps to assign a move to.</option>
                                )}
                            </Input>
                        </FormGroup>
                    </Col>

                </Row>

            </CardHeader>

            <CardFooter className="text-right">
                <button className="btn btn-success" onClick={onQuery}>Get Stats</button>
            </CardFooter>

        </Card>

    )

}

const mapStateToProps = state => {
    return {
        lead_sources: state.lead_sources.lead_sources,
        tags: state.tags.tags,
        groupings: state.groupings.groupings.filter(g => g.collection_name === 'template_appointments')
    };
};

export default connect(mapStateToProps, '')(AppointmentsByStepQuery);   
