/*
Documentation

*/

import { connect } from 'react-redux'
import React, { Component } from 'react';
import * as footer from 'utils/footer';
import PropTypes from 'prop-types';

// import getElementOffset from 'utils/position/getElementOffset';
import _workflow_steps from '_functions/workflow_steps';

import Sidebar from './Sidebar';
import StepDrawer from './StepDrawer';
import Tree from './Tree';

import { toggleStandardLoader } from 'store/functions/system/system';

// TODO: moving without saving should give an alert
// TODO: on deleting a sidebar step it may clear the workflow without being able to go back

class Flow extends Component {

    state = {
        allSteps: [],
        deletedStep: {},
        showDrawer: false,
        selectOptions: [],
        templateAppointmentOptions: [],
        templateDocOptions: [],
    };

    // creates an array of options that ReactSelect can
    // recognize to be able to select as step
    setStepOptions = () => {

        const selectOptions = []
        let { allSteps } = this.state

        if(!allSteps) return

        allSteps = [...allSteps]
        allSteps.forEach(step => { selectOptions.push({label: step.name, value: step._id}) })

        this.setState({selectOptions})

    }

    // same as setStepOptions only it returns for template_appointments
    setTemplateAppointmentOptions = () => {

        const templateAppointmentOptions = []
        let { template_appointments } = this.props

        if(!template_appointments) return

        template_appointments = [...template_appointments]
        template_appointments.forEach(t => { templateAppointmentOptions.push({label: t.name, value: t._id}) })

        this.setState({templateAppointmentOptions})

    }

    // same as setStepOptions only it returns for template_appointments
    setTemplateDocOptions = () => {

        let templateDocOptions = []

        this.props.template_docs.forEach(doc => {
            templateDocOptions.push({label: doc.name, value: doc._id})
        })

        this.setState({templateDocOptions})

    }

    // toggle the right hand view of a step
    toggleSidebarDrawer = (stepToEdit) => {

        const { showDrawer } = this.state;

        if(showDrawer) {
            document.body.classList.remove('noScroll')
        } else {
            document.body.classList.add('noScroll')
        }

        this.setState({showDrawer: !showDrawer, stepToEdit})

    }

    onDeleteStep = (deletedStep) => {

        this.setState({deletedStep: deletedStep.data})

        if(!deletedStep.data) return

        let allSteps = this.state.allSteps ? [...this.state.allSteps] : []

        allSteps = allSteps.filter(a => a._id !== deletedStep.data._id)

        this.setState({allSteps})

        this.setStepOptions()

    }


    onStepSave = (result) => {

        if(!result.success) return

        let savedStep = result.data

        const allSteps = this.state.allSteps ? [...this.state.allSteps] : []

        let foundStep = allSteps.find(el => el._id === savedStep._id)

        if(foundStep) {
            allSteps[allSteps.findIndex(el => el._id === foundStep._id)] = savedStep
        } else {
            allSteps.push(savedStep)
        }

        this.setState({allSteps, steps: allSteps})
        this.setStepOptions()

    }

    // resets the css hover listeners for all li's on the page
    resetHovers = () => {

        this.removeLiHover()
        this.setLiHover()

    }

    setLiHover = () => {

        var allLi = document.querySelectorAll(".flow-li");
        allLi.forEach(el => { el.addEventListener("mouseover", this.onLiHover); })

    }

    removeLiHover = () => {

        var allLi = document.querySelectorAll(".flow-li");
        allLi.forEach(el => { el.removeEventListener("mouseover", this.onLiHover); })

    }

    onLiHover = (e) => {

        const dataTreeValues    = e.target.getAttribute('data-tree')
        const dataTreeContent   = document.getElementById('data-tree-content')
        const dataTree          = document.getElementById('data-tree')

        if(dataTreeValues) {

            dataTreeContent.innerText = dataTreeValues
            dataTree.style.display = 'block'

        } else {

            dataTree.style.display = 'none'

        }

    }

    componentWillUnmount = () => {

        footer.show()
        this.removeLiHover()

        // document.body.style.minHeight = '100vh'
        // document.body.style.paddingBottom = '20px'

        const header = document.querySelector('.header')
        if(header) header.style.display = 'block'

    }

    componentDidMount = async () => {

        toggleStandardLoader(true)

        const header = document.querySelector('.header')
        if(header) header.style.display = 'none'

        footer.hide();

        // document.body.style.minHeight = 0
        // document.body.style.paddingBottom = 0

        const steps = await _workflow_steps.findByWorkflow(this.props.workflow._id)

        if(steps.success) this.setState({allSteps: steps.data, steps: steps.data}, () => {
            this.setTemplateAppointmentOptions()
            this.setStepOptions();
            this.setTemplateDocOptions()
        })

        toggleStandardLoader(false)

    }

    render() {

        const { workflow, setView, flows } = this.props
        const { allSteps, deletedStep, steps, showDrawer, stepToEdit, selectOptions, templateAppointmentOptions, templateDocOptions } = this.state

        return (

            <div className="archk-flow">

                <Sidebar
                    toggleSidebarDrawer={this.toggleSidebarDrawer}
                    workflow={workflow}
                    onStepSave={this.onStepSave}
                    allSteps={allSteps}
                />

                {steps && (
                    <Tree
                        selectOptions={selectOptions}
                        workflow={workflow}
                        allSteps={allSteps}
                        steps={steps}
                        deletedStep={deletedStep}
                        resetHovers={this.resetHovers}
                        setView={(value) => setView(value)}
                        toggleSidebarDrawer={this.toggleSidebarDrawer}
                    />
                )}

                <StepDrawer
                    onStepSave={this.onStepSave}
                    selectOptions={selectOptions}
                    templateAppointmentOptions={templateAppointmentOptions}
                    templateDocOptions={templateDocOptions}
                    allSteps={allSteps}
                    workflow={workflow}
                    showDrawer={showDrawer}
                    toggleSidebarDrawer={this.toggleSidebarDrawer}
                    stepToEdit={stepToEdit}
                    onDeleteStep={this.onDeleteStep}
                    flows={flows}
                />

            </div>

        )

    }

}

Flow.propTypes = {
    workflow    : PropTypes.object.isRequired,
    setView     : PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        template_appointments: state.template_appointments.template_appointments,
        template_docs: state.template_docs.template_docs,
    };
};

export default connect(mapStateToProps, '')(Flow);
