/*
Documentation

this file shows the bottom buttons on the create user page

*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toggleAlertBS } from 'store/functions/system/system';

const required_fields = [
    ['given_name', 'family_name', 'email', 'phone'],
    ['address_line_1', 'city', 'state', 'postal_code', 'country'],
    ['hourly_rate', 'billable_rate'],
    ['privileges']
]

class Controller extends Component {

    validateFields = () => {

        let fields_to_validate = required_fields[this.props.step - 1];

        //copy state
        let newState = Object.assign({}, this.props.user);
        //assume we have no errors
        let errors = 0;

        fields_to_validate.forEach((field) => {
            //check each required field
            if(newState[field]) {
                newState[field + "State"] = 'valid';
            } else {
                newState[field + "State"] = 'invalid';
                errors++
            }

        })

        if(this.props.step === 1) {
            const percentIsValid = (percent) => {
                return percent !== undefined && percent !== null && percent !== '' ? true : false
            }

            if(!percentIsValid(newState.default_percent)) return toggleAlertBS('info', 'Make sure that the default percent is a number between 0 and 1. Example: 25% = .25');
            newState.default_percent = parseFloat(newState.default_percent)
        }

        this.props.setUserState(newState);

        if(!errors) this.props.onChangeStep(this.props.step + 1)

    }

    render() {

        const step = this.props.step

        return (

            <div className="text-right">

                {step !== 1 ? (
                    <button className="btn btn-warning" onClick={() => this.props.onChangeStep(this.props.step - 1) }>Back</button>
                ) : null}

                {step === 5 ? (
                    <button className="btn btn-success" onClick={this.props.onSave}>Finish</button>
                ) : (
                    <button className="btn btn-success" onClick={this.validateFields}>Next</button>
                )}

            </div>

        )

    }

}

Controller.propTypes = {
    user            : PropTypes.object.isRequired,
    step            : PropTypes.number.isRequired,
    onChangeStep    : PropTypes.func.isRequired,
    setUserState    : PropTypes.func.isRequired,
}

export default Controller;
