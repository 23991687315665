import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Row, Col, FormGroup, Input } from 'reactstrap';

import ButtonSave from './ButtonSave';
import ButtonDelete from './ButtonDelete';
import InputAnswers from './InputAnswers';

import state from '../state';

const MAX_DESCRIPTION_LENGTH = 1000;
const MAX_PLACEHOLDER_LENGTH = 30;
const MAX_HINT_LENGTH = 150;

const ColFlow = ({selected, onQuestionSaved, onQuestionDeleted, tags, missing_docs}) =>  {

    // array of possible validation errors
    const [errors, setErrors]                 = useState([]);
    // if true shows inputs for settings tag answer and question
    const [isTagQuestion, setIsTagQuestion]   = useState(false);
    // holds tag answer and id of tag to assign
    const [tagAnswer, setTagAnswer]           = useState('');
    const [tagTag, setTagTag]                 = useState('');
    // object representing the full question
    const [question, setQuestion]             = useState({});
    // if true displays orange warning message on the question editor
    const [unsavedChanges, setUnsavedChanges] = useState(false);

    // return true if type is select or check box
    const isOptionQuestion = useCallback(() => {
        if(question.type === 'select' || question.type === 'check box') return true;
    }, [question.type])
    
    // returns the description with a max length of 100 character
    const renderDescription = useCallback((description) => {
        return description && description.length > 100 ? 
        description.slice(0, 100) + '...' :
        description
    }, [])

    // return true if description is over the max length
    const invalidDescription = useCallback((description) => {
        return description && description.length > MAX_DESCRIPTION_LENGTH
    }, [])

    const hasErr = useCallback((err) => {
        return errors.includes(err)
    }, [errors])

    // renders yes or no input if type is yes - no or 
    // the answers input if type is check box or select
    const renderAnswerOptions = () => {
        return isOptionQuestion() || question.type === 'yes - no' ? (
            <>
                <option value="false"></option>
                {isOptionQuestion() ? question.answers.map((answer, i) => (
                    <option key={i} value={answer}>{answer}</option>
                )) : question.type === 'yes - no' ? (
                    <>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </>
                ) : null}
            </>
        ) : null
    }

    // sets the value of showing the tag inputs
    // if value is false it resets tag answer and tag id
    const onSetIsTagQuestion = (value) => {
        setIsTagQuestion(value)
        setUnsavedChanges(true)
        if(!value) {
            setTagAnswer('')
            setTagTag('')
        }
    }

    // sets the tag answer
    const onTagAnswerChange = (e) => {
        setUnsavedChanges(true);
        setTagAnswer(e.target.value)
    }

    // sets the tag id
    const onTagTagChange = (e) => {
        setUnsavedChanges(true);
        setTagTag(e.target.value)
    }

    // handles all standard input change
    const onInputChange = (e, field) => {
        const q = Object.assign({}, question);

        let value = e.target.value;
        if(value === 'false') value = false;
        if(value === 'true') value = true;
        if(value === '__FALSE__') value = undefined;

        if(field === 'placeholder' && value && value.length > MAX_PLACEHOLDER_LENGTH) {
            value = value.slice(0, MAX_PLACEHOLDER_LENGTH)   
        }

        if(field === 'hint' && value && value.length > MAX_HINT_LENGTH) {
            value = value.slice(0, MAX_HINT_LENGTH)   
        }


        q[field] = value;

        if(field === 'type') {
            q.alert_on_answer = '';
            q.tag_on_answer.tag = '';
        }

       
        setQuestion(q)
        setUnsavedChanges(true);
    }

    const questionIsValidated = () => {

        let err = [];

        // make sure description is between 5 and max length character
        if(!question.description || question.description.length < 5 || invalidDescription(question.description)) {
            err.push('description');
        }

        // if we have a yes - no or select input make sure we have 2 answers or more
        if(isOptionQuestion() && question.answers.length < 2) {
            err.push('answers')
        }

       // if we are a tag question make sure the tag inputs are valid
        if(isTagQuestion) {
            if(!tagAnswer || tagAnswer === 'false') {
                err.push('tag_on_answer_answer')
            }

            if(!tagTag || tagTag === 'false') {
                err.push('tag_on_answer_tag')
            }
        }

        setErrors(err);
        return err.length ? false : true;
    
    }

    // removes a question from state and passed it up to parent component to handle
    const onDelete = () => {
        const questions = [...selected.questionSet];
        let index = questions.findIndex(q => q.id === question.id);

        questions.splice(index, 1)

        onQuestionDeleted(index, questions, selected.parentTree)
        setUnsavedChanges(false);
    }

    // validates the question and sends it up to the parent component to handle
    // return true if validation succeeds or false if it does not
    const onSave = () => {
        // make sure question is ready to be saved
        if(questionIsValidated()) {

            const newQuestions = [...selected.questionSet];
            let index = newQuestions.findIndex(q => q.id === question.id);

            const newQuestion = Object.assign({}, question);
            newQuestion.tag_on_answer.answer = tagAnswer;
            newQuestion.tag_on_answer.tag = tagTag;
    
            newQuestions[index] = newQuestion;
    
            onQuestionSaved(newQuestion, newQuestions, selected.parentTree);
            setUnsavedChanges(false);
            return true;
        }
        return false;
    }

    useEffect(() => {

        // copy state
        const baseState = JSON.parse(JSON.stringify(state.question))
        
        const { tag_on_answer } = selected.question

        // set tag answers based on values passed in
        if(tag_on_answer && tag_on_answer.answer && tag_on_answer.tag) {
            setTagAnswer(tag_on_answer.answer);
            setTagTag(tag_on_answer.tag);
            setIsTagQuestion(true);
        } else {
            setTagAnswer('');
            setTagTag('');
            setIsTagQuestion(false);
        }

        // set errors and unsaved changes to base values, set question to state
        setQuestion({...baseState, ...selected.question});
        setUnsavedChanges(false);
        setErrors([])

    }, [selected.question.id])

    if(!question.id) return (
        <Col lg={4} className="col col-selected">

            <Row className="archk-col-header">
                <Col lg={6} className="align-self-center">
                    <h3>Edit Question</h3>
                </Col>
                {question.id ? (
                    <Col lg={6} className="text-right align-self-center">
                        <ButtonDelete onDelete={onDelete} />
                    </Col>
                ) : null}
            </Row>

            <p className="text-sm mb-0 ">No question selected for editing.</p>

        </Col>
    )

    return (

        <Col lg={4} className="col col-selected">

            <Row className="archk-col-header">
                <Col lg={6} className="align-self-center">
                    <h3>Edit Question</h3>
                </Col>
                {question.id ? (
                    <Col lg={6} className="text-right align-self-center">
                        <ButtonDelete onDelete={onDelete} />
                    </Col>
                ) : null}
            </Row>

            {unsavedChanges ? (
                <div className="alert alert-warning"><i className="fas fa-info mr-2 " /> This question has unsaved changes.</div>
            ) : null}

            <div className="bg-secondary">
                <p className="font-weight-bold bg-white p-3 border text-uppercase" style={{wordBreak: 'break-all'}}>
                    <i className="fas fa-question mr-2 text-success " /> 
                    {renderDescription(question.description)}
                </p>
            </div>

            <FormGroup>
                <label className="form-control-label">
                    <span className={hasErr('description') ? 'text-danger' : ''}>Question Description *</span>
                </label>
                <Input 
                    style={{maxHeight: 400}}
                    id="archk-question-description"
                    type="textarea"
                    valid={question.description && question.description.length > 5}
                    invalid={hasErr('description')}
                    value={question.description}
                    placeholder="New Question"
                    onChange={(e) => onInputChange(e, 'description')}
                />
                {invalidDescription(question.description) ? (
                    <p className="text-sm font-weight-bold text-danger mb-0">
                        {question.description.length}/{MAX_DESCRIPTION_LENGTH}
                    </p>
                ) : null}
            </FormGroup>

            <Row className="archk-row-normal">

                <Col lg={6}>
                    <FormGroup>
                        <label className="form-control-label">Type *</label>
                        <Input 
                            type="select"
                            value={question.type}
                            onChange={(e) => onInputChange(e, 'type')}
                        >
                            <option value="text">Text</option>
                            <option value="text area">Large Text</option>
                            <option value="yes - no">Yes or No</option>
                            <option value="select">One Of</option>
                            <option value="check box">Checkbox</option>
                            <option value="upload">Upload</option>
                            <option value="court">Select Court</option>
                        </Input>
                        {question.type === 'upload' ? (
                            <div className="alert alert-info mt-3">Upload questions are used for API integration and will not be shown inside system forms.</div> 
                        ) : null}
                    </FormGroup>
                </Col>

                <Col lg={6}>
                    <FormGroup>
                        <label className="form-control-label">
                            Is Required *  
                            {question.required === true || question.required === 'true' ? (
                                <i className="fas fa-check ml-2 text-success " />
                            ) : (
                                <i className="fas fa-times ml-2 text-warning " />
                            )}
                        </label>
                        <Input 
                            type="select"
                            value={question.required}
                            onChange={(e) => onInputChange(e, 'required')}
                        >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </Input>
                    </FormGroup>
                </Col>

            </Row>

            {isOptionQuestion() ? (
                <InputAnswers 
                    answers={question.answers}
                    onChange={answers => onInputChange({target: {value: answers}}, 'answers')}
                    hasErr={hasErr('answers')}
                />
            ): null}
            
            <hr />

            <FormGroup>
                <label className="form-control-label">
                    Placeholder {' '}
                    {question.placeholder && question.placeholder.length === MAX_PLACEHOLDER_LENGTH ? 
                        `(${MAX_PLACEHOLDER_LENGTH} character max)` : null}
                </label>
                <Input 
                    type="text"
                    value={question.placeholder}
                    onChange={(e) => onInputChange(e, 'placeholder')}
                />
            </FormGroup>

            <FormGroup>
                <label className="form-control-label">
                    Hint{' '}
                    {question.hint && question.hint.length === MAX_HINT_LENGTH ? 
                        `(${MAX_HINT_LENGTH} character max)` : null}

                </label>
                <Input 
                    type="text"
                    value={question.hint}
                    onChange={(e) => onInputChange(e, 'hint')}
                />
            </FormGroup>

            <hr />

            <FormGroup>
                <label className="form-control-label">Map Field</label>
                <Input 
                    type="select"
                    value={question.map_field}
                    onChange={(e) => onInputChange(e, 'map_field')}
                >
                    <option value="false">-</option>
                    <option value="given_name">First Name</option>
                    <option value="family_name">Family Name</option>
                    <option value="email">Email</option>
                    <option value="phone">Phone</option>
                    <option value="address_line_1">Address Line 1</option>
                    <option value="address_line_2">Address Line 2</option>
                    <option value="city">City</option>
                    <option value="state">State</option>
                    <option value="postal_code">Postal Code</option>
                    <option value="country">Country</option>
                </Input>
            </FormGroup>

            <FormGroup>
                <label className="form-control-label">Alert On Answer</label>
                <Input 
                    type={isOptionQuestion() || question.type === 'yes - no' ? 'select' : 'text'}
                    value={question.alert_on_answer}
                    onChange={(e) => onInputChange(e, 'alert_on_answer')}
                >
                    {renderAnswerOptions()}
                </Input>
            </FormGroup>

            <hr />

            {question.type === 'yes - no' ? (
                <FormGroup>
                    <label className="form-control-label">Mark Missing Doc NA If Answer Is No</label>
                    <Input 
                        type="select"
                        value={question.mark_doc_na_on_no}
                        onChange={(e) => onInputChange(e, 'mark_doc_na_on_no')}
                    >
                        <option value="__FALSE__"></option>
                        {missing_docs.map(doc => (
                            <option key={doc._id} value={doc._id}>{doc.name}</option>
                        ))}
                    </Input>
                </FormGroup>
            ) : null}

            <hr />

            <FormGroup>
                <label className="form-control-label">Tag On Answer</label>
                <Input 
                    type="select"
                    value={isTagQuestion ? 'yes' : 'no'}
                    onChange={(e) => onSetIsTagQuestion(e.target.value === 'yes' ? true : false)}
                >
                    <option value="no">No</option>
                    <option value="yes">Yes</option>
                </Input>
            </FormGroup>

            {isTagQuestion ? (
                <Row className="archk-row-normal">
                    <Col lg={6}>
                        <FormGroup>
                            <label className="form-control-label">Answer Triggering Tag</label>
                            <Input 
                                type={isOptionQuestion() || question.type === 'yes - no' ? 'select' : 'text'}
                                value={tagAnswer}
                                onChange={onTagAnswerChange}
                                valid={tagAnswer && tagAnswer !== 'false'}
                                invalid={hasErr('tag_on_answer_answer') && (!tagAnswer || tagAnswer === 'false')}
                            >
                                {renderAnswerOptions()}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col lg={6}>
                        <FormGroup>
                            <label className="form-control-label">Tag To Set</label>
                            <Input 
                                type="select"
                                value={tagTag}
                                onChange={onTagTagChange}
                                valid={tagTag && tagTag !== 'false'}
                                invalid={hasErr('tag_on_answer_tag') && (!tagTag || tagTag === 'false')}

                            >
                                <option value="false">-</option>
                                {tags.map(tag => (
                                    <option key={tag._id} value={tag._id}>{tag.name}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
            ) : null}

            {hasErr('description') ? (
                <p className="text-sm font-weight-bold text-danger">* Question description must be longer than 5 characters and less than {MAX_DESCRIPTION_LENGTH} characters.</p>
            ) : null}
            
            {hasErr('answers') ? (
                <p className="text-sm font-weight-bold text-danger">* Question must have at least 2 possible answers.</p>
            ) : null}

            <Row className="archk-col-footer border-top archk-row-normal">
                <Col lg={6} className="align-self-center">

                </Col>
                <Col lg={6} className="align-self-center text-right">
                    <ButtonSave onSave={onSave} />
                </Col>                        
            </Row>

        </Col>
       
    )
};

const mapStateToProps = state => {
    return {
        tags: state.tags.tags,
        missing_docs: state.missing_docs.missing_docs,
    };
};

export default connect(mapStateToProps, '')(React.memo(ColFlow));

