import { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux';

import { Modal, FormGroup, Input } from "reactstrap";
import { toggleAlertBS } from 'store/functions/system/system';

import { toggleStandardLoader } from 'store/functions/system/system';

import _flows from '_functions/flows'

// flow        : { type: Schema.Types.ObjectId, ref: 'flows', required: true },
// moves_to    : { type: Schema.Types.ObjectId, ref: 'flows', default: null },
// type        : { type: String, required: true },
// delay       : { type: Number },
// id          : { type: Schema.Types.ObjectId },


const FlowsEditAddTask = ({showModal, toggleModal, flow, setFlow}) => {

    const [name, setName] = useState(flow.name);
    const [active, setActive] = useState(flow.active);
    const [errs, setErrs] = useState('');

    const onSave = useCallback(async (state) => {

        const errs = [];
        if(!name) errs.push('name')

        setErrs(errs);

        if(errs.length) return;

        toggleStandardLoader(true);
        const data = await _flows.update(flow._id, {
            name,
            active: active === 'true' || active === true ? true : false,
        })
        toggleStandardLoader(false);

        if(data.success) {
            setFlow(data.data)
            toggleModal();
        } else {
            toggleAlertBS(true, 'Please try again.')
        }

    }, [name, active, setFlow, toggleModal, flow._id])


    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >

            <div className="modal-header">
                <h5 className="modal-title">Update Flow</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">

                <FormGroup>
                    <label className="form-control-label">Name</label>
                    <Input 
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        invalid={errs.includes('name')}
                   />
                </FormGroup>
                
                <FormGroup>
                    <label className="form-control-label">Active</label>
                    <Input 
                        type="select"
                        value={active === 'true' || active === true ? 'true' : 'false'}
                        onChange={(e) => setActive(e.target.value)}
                        invalid={errs.includes('name')}
                    >
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                    </Input>
                </FormGroup>

            </div>

            <div className="modal-footer text-right">
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            </div>

        </Modal>

    )

}

const mapStateToProps = state => {
    return {
        template_emails: state.template_emails.template_emails,
        template_texts: state.template_texts.template_texts,
    };
};

export default connect(mapStateToProps, '')(FlowsEditAddTask);