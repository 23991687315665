/*
Documentation

This page shows all of the systems cases

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import { architeckCall } from 'database';
import moment from 'moment';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import { Card, CardBody, CardHeader, CardTitle, Container } from "reactstrap";
import Circle from 'components/markup/loading/Circle'
import NotificationDelete from 'components/functional/notifications/Delete';
import renderName from 'utils/renderName';
import { Link } from 'react-router-dom'

class Logs extends React.Component {

    state = {
        logToDelete: null,
        loaded: false
    }

    onDelete = (log_id) => {

        architeckCall({
            method: 'delete',
            url: `/api/v1/dev_tickets/delete/${log_id}`
        })


        let logs = this.state.logs;
        logs = logs.filter(log => log._id !== log_id)

        this.setState({logs})


    }

    columns = [
        {
            dataField: "created_at",
            text: "Created_at",
            formatter: (cell, row) => moment.unix(row.created_at).format('MM/DD/YYYY - h:mm A')
        },
        {
            dataField: "user",
            text: "User",
            formatter: (cell, row) => (
                row.user ? <a target="_blank" rel="noopener noreferrer" href={`/core/users/view/${row.user._id}`} >{renderName(row.user)}</a> : renderName(row.user)
            )
        },
        {
            dataField: "page",
            text: "Page",
        },
        {
            dataField: "error_message",
            text: "Error Message",
        },
        {
            dataField: "action",
            text: "Action",
        },
        {
            dataField: "action_previous",
            text: "Previous Action",
        },
        {
            dataField: "notes",
            text: "Notes",
        },
        {
            dataField: "_id",
            text: "Actions",
            formatter: (cell, row) => (
                <div className="text-right"><i className="fas fa-trash mr-2 text-danger" onClick={() => this.onDelete(row._id)} /></div>
            )
        },



    ]

    pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: false,

        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    componentDidMount = async () => {

        const logs = await architeckCall({
            method: 'get',
            url: '/api/v1/dev_tickets/find'
        })

        if(logs.success) {

            this.setState({logs: logs.data})

        }

        this.setState({loaded: true})

    }

    render() {

        const { logToDelete, logs, loaded } = this.state

        if(!loaded) return <div className="pt-6"><Circle /></div>

        return (
            <>

            <Container  fluid>

                <Helmet>
                    <title>Logs</title>
                    <meta name="description" content="Cases" />
                </Helmet>

                <DashHeaderOpen
                    title={<span><i className="fas fa-code mr-2 text-success " /> Developers</span>}
                    breadcrumb_1="Router"
                    breadcrumb_2="Tickets"
                    actionComponent={(
                        <Link className="btn btn-outline-warning" to="/developers/router">
                            <i className="fas fa-arrow-left mr-2" /> Back
                        </Link>
                    )}
                />

                {logToDelete && (
                    <NotificationDelete
                        deletionURL={`/api/v1/logs/delete/${logToDelete._id}`}
                        confirmBtnText="Delete Case"
                        title={<span className="text-capitalize">{`Delete This Log?`}</span>}
                        text="Deleting this log will permanently remove it from the system. Make sure whatever caused the error has been resolved first!"
                        onClose={() => this.setState({logToDelete: null})}
                        onSuccess={(object) => {
                            let logs = [...this.state.logs]
                            logs = logs.filter(log =>log._id !== object.data._id )
                            this.setState({logs})
                        }}
                    />
                )}

                <Card>

                    <CardHeader>
                        <CardTitle className="mb-0">All Developer Logs</CardTitle>
                    </CardHeader>

                    {logs && logs.length ? (

                        <ToolkitProvider
                            data={logs}
                            keyField="_id"
                            columns={this.columns}
                        >
                            {props => (
                                <div className=" table-responsive table-parent-bordered table-vertical-align table-not-fixed">
                                    <BootstrapTable
                                        pagination={this.pagination}
                                        {...props.baseProps}
                                        bootstrap4={true}
                                        bordered={false}
                                    />
                                </div>
                            )}
                        </ToolkitProvider>

                    ) : (

                        <CardBody>
                            There are no active logged errors for this system
                        </CardBody>
                    )}

                </Card>

            </Container>

            </>
        );
    }
}

export default Logs
