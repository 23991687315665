/*
Documentation

This component shows the given_name, family_name, email, and phone fields for updating and creating a user

*/

import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import React from "react";
import { Card, CardHeader, CardBody, CardFooter, CardTitle } from "reactstrap";
import PropTypes from 'prop-types';
import { toggleAlertBS } from 'store/functions/system/system'
import _auth from   '_functions/auth';

class General extends React.Component {

    state = {
        error: null
    }

    onUpdatePassword = async () => {

        this.setState({error: null})

        const password = this.props.password

        const updated = await _auth.password.update({
            _id:                this.props.user_id,
            password:           password.password,
            password_confirm:   password.password_confirm
        })

        if(updated.success) {
            toggleAlertBS(false, 'User password updated successfully.')
        } else {
            this.setState({error: updated.message})
        }

    }

    render() {

        const password = this.props.password;

        return (
            <>

               <Card>

                    <CardHeader>
                        <CardTitle className="mb-0">Authentication</CardTitle>
                    </CardHeader>

                    <CardBody>

                        <StandardFormGroup
                            obj={password}
                            objName="password"
                            onChange={(o, f, v) => this.props.onChange(f, v)}
                            type="password"
                            field="password"
                            title="Password"
                        />

                        <StandardFormGroup
                            obj={password}
                            objName="password"
                            onChange={(o, f, v) => this.props.onChange(f, v)}
                            type="password"
                            field="password_confirm"
                            title="Confirm Password"
                        />

                        {this.state.error ? (
                            <div className="alert alert-danger mb-0">{this.state.error}</div>
                        ) : null}

                   </CardBody>

                    <CardFooter className="text-right" >
                        <button className="btn btn-success" onClick={this.onUpdatePassword} >Change Password</button>
                    </CardFooter>

               </Card>

            </>
        );
    }
}

General.propTypes = {
    user_id   : PropTypes.string.isRequired,
    password  : PropTypes.object.isRequired,
    onChange  : PropTypes.func.isRequired,
}

export default General
