
import { Modal } from "reactstrap";
import keys from 'keys';
import A from 'components/markup/links/A'
import { formatMoney } from "utils/currency";
import { useState, useEffect } from 'react';
import moment from "moment";

const ModalViewCases = ({showModal, toggleModal, cases}) => {

    const [sort, setSort] = useState('')
    const [direction, setDirection] = useState(1)
    const [objs, setObjs] = useState(JSON.parse(JSON.stringify(cases)));

    const onSetSort = (field) => {

        if(sort === field) {
            const newDirection = direction * -1;
            setSort(field)
            setDirection(newDirection)

            const _objs = objs.sort((a, b) => {
                if(direction === -1) {
                    return a[field] > b[field] ? -1 : 1
                } else {
                    return a[field] < b[field] ? -1 : 1
                }
            })
            console.log(_objs)
            setObjs(_objs)

        } else {
            setSort(field)
            setDirection(1)

            const _objs = objs.sort((a, b) => a[field] < b[field] ? 1 : -1)
            setObjs(_objs)
        }

    }

    useEffect(() => {
        setObjs(JSON.parse(JSON.stringify(cases)))
    }, [cases])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">Cases (Showing Most Recent 300)</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="cursor-pointer" onClick={() => onSetSort('name')}>Case</th>
                                <th className="cursor-pointer" onClick={() => onSetSort('company_size_estimated')}>Size</th>
                                <th className="cursor-pointer" onClick={() => onSetSort('percent_fee')}>Percent Fee</th>
                                <th className="cursor-pointer" onClick={() => onSetSort('total_ertc')}>Total ERTC</th>
                                <th className="cursor-pointer" onClick={() => onSetSort('created_at')}>Created</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {objs.length ? objs.map(_case => {
                                return (
                                    <tr key={_case._id}>
                                        <td className="text-capitalize">
                                            {_case.name ? _case.name.replace(' - ertc', '') : _case.name}
                                            <div>{_case.current_step ? _case.current_step.name : 'No Found Step'}</div>
                                        </td>
                                        <td>{_case.company_size ? _case.company_size : _case.company_size_estimated ? _case.company_size_estimated : '-'}</td>
                                        <td>{_case.percent_fee ? _case.percent_fee * 100 + '%' : '-'}</td>
                                        <td>{_case.total_ertc ? formatMoney(_case.total_ertc) : '-'}</td>
                                        <td>{moment.unix(_case.created_at).format('MM/DD/YYYY')}</td>
                                        <td className="text-right"><A className="btn btn-success" href={`${keys.APP_URL}/dashboard/cases/view/${_case._id}`}>View</A></td>
                                    </tr>
                                )
                            }) : (
                                <tr>
                                    <tr>
                                        <td>No Cases Found</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="modal-footer text-right">
                <button className="btn btn-outline-warning" onClick={toggleModal} >
                    <i className="fas fa-arrow-left mr-2" />
                    Close
                </button>
            </div>

        </Modal>

    )

}

export default ModalViewCases
