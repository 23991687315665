
// findUnmovedStepsByAppointments

import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, CardTitle, Container, FormGroup, Input } from 'reactstrap';
import _analytics from '_functions/analytics';
import Metrics from './Metrics';
import { toggleStandardLoader } from 'store/functions/system/system'
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import UserSearchInput from 'components/system/migrated/UserSearchInput';


class UserEvents extends Component {


    state = {
        data: null,
        user: {},
        groupings: [],
        loading: true,
        noData: false
    };

    onQuery = async () => {

        const { date, user } = this.state;
        if(!date) return;
        if(!user._id) return;

        const start = moment(date).startOf('day').format('X');
        const end = moment(date).endOf('day').format('X');

        toggleStandardLoader(true);

        const data = await _analytics.events.days30(`?start=${start}&end=${end}`);

        toggleStandardLoader(false);

        if(data.data) {
            this.setState({data: data.data, loading: false, noData: false})
        } else {
            this.setState({noData: date, data: null})
        }

    }

    getEventName = (event) => {

        let name = 'unknown';

        switch (event) {
            case 'APPOINTMENT_STEP_CHANGE': return name = 'Appts Step';
            case 'APPOINTMENT_MANUAL': return name = 'Appt Manual';
            case 'CASES_VIEWED_UNIQUE_BY_VALUE': return name = 'Cases Viewed';
            case 'CASES_CREATED': return name = 'Cases Created';
            case 'CONTACTS_VIEWED_UNIQUE_BY_VALUE': return name = 'Contacts Viewed';
            case 'CONTACTS_CREATED': return name = 'Contacts Created';
            
            case 'DIAL_MANUAL': return name = 'Dials';
            case 'DIAL_QUEUE': return name = 'Queue Calls';
            case 'DIAL_IN_PERSON': return name = 'In Person Appts';

            case 'EMAILS_SENT': return name = 'Emails Sent';
            case 'EMAILS_REPLIED': return name = 'Emails Replied';
            
            case 'DOCUMENTS_UPLOADED': return name = 'Docs Uploaded';
            case 'DOCUMENTS_SENT': return name = 'Docs Sent';

            case 'NOTES_CREATED': return name = 'Notes Made';
            case 'STEPS_MOVED': return name = 'Steps Moved';
            case 'STEPS_JUMPED': return name = 'Steps Jumped';
            case 'TASK_FINISHED': return name = 'Tasks Finished';

            case 'TEXTS_SENT': return name = 'Texts Sent';
            case 'TEXTS_REPLIED': return name = 'Texts Replied';
            
            case 'AVERAGE_TIME_BETWEEN_EVENTS': return name = 'Avg Time Between Events';
            case 'FIRST_EVENT': return name = 'First Event';
            case 'LAST_EVENT': return name = 'Last Event';
            case 'CALL_TIME_TOTAL': return name = 'Time On Call';
            case 'CALL_TIME_AVERAGE': return name = 'Avg Call Duration';
            case 'CALL_TIME_AVERAGE_BETWEEN': return name = 'Avg Time Between Calls';
            case 'NOTE_AVERAGE_LENGTH': return name = 'Avg Note Length';
            case 'NOTE_AVERAGE_BETWEEN': return name = 'Avg Time Between Notes';
                
            default:break;
        }

        return name;

    }

    getUser = (user_id) => {
        return this.state.users[user_id] || {};
    }

    componentDidMount = () => {
        const users = {};

        this.props.users.forEach(user => {
            users[user._id] = user;
        })

        this.setState({users})
    }

    render() {

        const { data, noData, user } = this.state

        return (

            <>
            <Container fluid>

                <DashHeaderOpen
                    title={<span><i className="fas fa-headset mr-2 text-success " />Analytics</span>}
                    breadcrumb_1="Users"
                    breadcrumb_2="Events"
                />

                <Card className="card-color card-primary">

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">Tracked Events</CardTitle>
                    </CardHeader>

                    <CardHeader>

                        <FormGroup>
                            <label className="form-control-label">Date</label>
                            <Input 
                                type="date"
                                value={this.state.date || ''}
                                onChange={(e) => this.setState({date: e.target.value})}
                            />
                        </FormGroup>

                        <UserSearchInput
                            onSelect={(user) => this.setState({user})}
                        />

                        <hr />
                        <div className="text-right">
                            <button className="btn btn-success" onClick={this.onQuery}>Get Stats</button>
                        </div>
                    </CardHeader>

                </Card>

                {noData ? (
                    <p className="text-sm text-center font-weight-bold text-warning">
                        <i className="fas fa-info-circle mr-2 " /> 
                        No metrics were found for the day given above: {moment(noData).format('MM/DD/YYYY')}
                    </p>
                 ) : null}

                {!data ? null : (
                    <Metrics 
                        data={data} 
                        getEventName={this.getEventName} 
                        user={user}
                    />
                )}

            </Container>
            </>

        )

    }

}

const mapStateToProps = state => {
    return {
        users: state.users.users
    };
};

export default connect(mapStateToProps, '')(UserEvents);