/*
Documentation

This is the master file for creating an appointment template

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';
import { Container } from "reactstrap";

import validator from 'utils/validator';
import { connect } from 'react-redux';

import _template_appointments from '_functions/template_appointments'
import { toggleAlertBS } from 'store/functions/system/system'
import { toggleStandardLoader } from 'store/functions/system/system'
import Circle from 'components/markup/loading/Circle';

import { Row, Col, Card, CardFooter } from 'reactstrap';

import QuestionnaireSelect from './components/QuestionnaireSelect';
import Settings from './components/Settings';
import Users from './components/Users';
import Notifications from './components/Notifications';

const required_fields = [
    'name',
    'type',
    'division'
]

class TemplatesCrud extends React.Component {

    state = {
        template_appointment: null
    }

    removeUser = (user_id) => {

        let assigned_to = [...this.state.template_appointment.assigned_to]
        assigned_to = assigned_to.filter(assigned_user => assigned_user._id !== user_id);

        this.setState({template_appointment: { ...this.state.template_appointment, assigned_to}})
        return assigned_to

    }

    onAddNotification = (notification) => {

        const template_appointment = Object.assign({}, this.state.template_appointment)
        const notifications = template_appointment.notifications ? [...template_appointment.notifications] : []

        notifications.push(notification)
        template_appointment.notifications = notifications;

        this.setState({template_appointment})

    }

    onRemoveNotification = (i) => {

        const template_appointment = Object.assign({}, this.state.template_appointment)
        const notifications = template_appointment.notifications ? [...template_appointment.notifications] : []

        notifications.splice(i, 1);

        template_appointment.notifications = notifications;

        this.setState({template_appointment})

    }

    addUser = (user) => {

        // first remove any duplicates
        let assigned_to = this.removeUser(user._id)
        //push the new selected workflow to the array of roles
        assigned_to.push(user);

        this.setState({template_appointment: { ...this.state.template_appointment, assigned_to}})

    }

    onSave = async () => {


        const validateFields = validator.requiredFields('template_appointment', required_fields, this)

        if(validateFields.state.division === 'false') {
            return this.setState({template_appointment: { ...validateFields.state, divisionState: 'invalid'}})
        }

        if(validateFields.success) {

            toggleStandardLoader(true)

            const template_appointment = validateFields.state
            let action;

            let expected_time = template_appointment.expected_time ? parseInt(template_appointment.expected_time) : 0

            // if the appointment has an id we should update it, else create a new template
            if(template_appointment._id) {

                action = await  _template_appointments.update(template_appointment._id, {
                    ...template_appointment,
                    expected_time,
                    // depreciated for questionnaires
                    form              : template_appointment.form ? template_appointment.form._id : undefined,
                    questionnaire     : template_appointment.questionnaire ? template_appointment.questionnaire._id : undefined,
                    default_office    : template_appointment.default_office ? template_appointment.default_office._id : undefined,
                    notifications     : template_appointment.notifications ? template_appointment.notifications : [],
                    automations       : this.state.automations,
                })

            } else {

                action = await  _template_appointments.create({
                    ...template_appointment,
                    expected_time,
                    // depreciated for questionnaire
                    form              : template_appointment.form ? template_appointment.form._id : undefined,
                    questionnaire     : template_appointment.questionnaire ? template_appointment.questionnaire._id : undefined,
                    default_office    : template_appointment.default_office ? template_appointment.default_office._id : undefined,
                    notifications     : template_appointment.notifications ? template_appointment.notifications : [],
                    automations       : this.state.automations,
                })

            }

            if(action.success) {
                toggleAlertBS(false, `Template Saved Successfully.`)
                this.setState({template_appointment: action.data})
            }

            toggleStandardLoader(false)

        }

    }

    onInputChange = (val, stateField) => validator.onInputChange(val, 'template_appointment', stateField, this)

    componentDidMount = () => {

        const template_appointments = this.props.template_appointments.template_appointments;
        const template_appointment_id = this.props.match.params._id

        const found_template_appointment = template_appointments.find(a => a._id === template_appointment_id);

        // find the template we are trying to update
        if(found_template_appointment) {

            this.setState({template_appointment: found_template_appointment, automations: found_template_appointment.automations})

        // else see if we are creating a new template
        } else {

            if(template_appointment_id === 'new') {

                // set an empty template_appointment
                this.setState({ template_appointment: {show: 'call center', notify_client: 'no', assigned_to: [] } })

            } else {

                // redirect if we are not creating a template and the template searched for is empty
                this.setState({shouldRedirect: '/core/appointments/templates'})

            }

        }

    }

    render() {

        const { template_appointment } = this.state


        if(this.state.shouldRedirect) return <Redirect to={this.state.shouldRedirect} />
        // show loading if template is not found yet
        if(!template_appointment) return <div className="py-6"><Circle /></div>

        return (
            <>

            <Container fluid>

                <Helmet>
                    <title>Appointment Templates</title>
                    <meta name="description" content="Cases Create" />
                </Helmet>

                <DashHeaderOpen
                    title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
                    breadcrumb_1="Appointment Templates"
                    breadcrumb_2={template_appointment && template_appointment._id ? "Update" : "Create"}
                    actionComponent={(
                        <Link to={"/core/appointments/templates"} className="btn btn-outline-warning">
                            <i className="fas fa-arrow-left" /> Back
                        </Link>
                    )}
                />

                <Card>

                    <Row>
                        <Col lg={6} className="pr-3 pr-lg-0">
                            <Settings
                                template_appointment={template_appointment}
                                onInputChange={(o, f, v) => this.onInputChange(v,f)}
                            />

                            <Users
                                template_appointment={template_appointment}
                                removeUser={this.removeUser}
                                addUser={this.addUser}
                            />

                            <QuestionnaireSelect
                                template_appointment={template_appointment}
                                onInputChange={(questionnaire) => this.onInputChange(questionnaire, 'questionnaire')}
                                onSave={this.onSave}
                            />

                        </Col>

                        <Col lg={6} className="pl-3 pl-lg-0">

                            <Notifications
                                template_appointment={template_appointment}
                                onAddNotification={this.onAddNotification}
                                onRemoveNotification={this.onRemoveNotification}
                            />


                        </Col>
                    </Row>

                    <CardFooter className="text-right bg-secondary">
                        <button style={{width: 162}} onClick={this.onSave} className="btn btn-success">Save Template</button>
                    </CardFooter>

                </Card>

            </Container>

            </>
        );
    }
}


const mapStateToProps = state => {

    return {
        template_appointments: state.template_appointments
    };
};

export default connect(mapStateToProps, '')(TemplatesCrud);
