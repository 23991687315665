import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import Circle from 'components/markup/loading/Circle';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardTitle, Container, Input, Row, Col } from "reactstrap";
import { toggleAlertBS } from 'store/functions/system/system';
import * as _ from 'underscore';
import { connect } from 'react-redux';
import _lead_sources from '_functions/lead_sources';
// import * as privileges from '_functions/users/privileges';
import CreateSourceModal from './CreateSourceModal';
import { formatPhone } from 'utils/text'
import { validatePhoneNumber } from 'utils/validation';

function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

class LeadSourcesAll extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            page: 1,
            sizePerPage: 25,
            total_documents: 0,
            data: null,
            showModal: false
        }
       this.queryTableDataThrottled = _.throttle(this.queryTableData, 1500)
    }

    columns = [
        {
            dataField: "name",
            text: "Name",
            formatter: (cell, row) => (
                <Link to={`/analytics/lead_sources/view/${row._id}`}>
                    {row.name}
                </Link>
            ),
        },
        {
            dataField: "source",
            text: "Lead Source",
            formatter: (cell, row) => (
                // show valid phone numbers in orange and formatted in readable form
                validatePhoneNumber(row.source) ? 
                    <span className="text-warning font-weight-bold">{formatPhone(row.source)}</span> : 
                    // show urls with hyperlinks in blue
                    validURL(row.source) ? (
                        <a 
                            className="text-info-original" 
                            href={row.source.includes('http') ? row.source : `https://${row.source}`} 
                            target="_blank" 
                            rel="noopener noreferrer" 
                        >
                            {row.source}
                        </a>
                    )
                    // we don't know what source this is, don't format it
                    : row.source
            )
        },
        // {
        //     dataField: "total_leads",
        //     text: "Total ENTRIES",
        // },
        // {
        //     dataField: "amount_spent",
        //     text: "Amount Spent",
        //     formatter: (cell, row) => formatMoney(row.amount_spent)
        // },
        {
            dataField: "active",
            text: "Active",
            formatter: (cell, row) => (
                row.active ? 
                    <i className="fas fa-check text-success font-weight-bold" /> : 
                    <i className="fas fa-times text-danger font-weight-bold " />
            )
        },

        {
            dataField: "x",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <Link to={`/analytics/lead_sources/view/${row._id}`}><i className="fas fa-edit text-success mr-2"></i></Link>
                    </div>
                )
            }
        },
    ]

    queryTableData = async (searchText, setPage1) => {

        this.setState({loading: true});

        let page = this.state.page
        if(setPage1) {
            this.setState({page: 1})
            page = 1
        }

        const lead_sources = await _lead_sources.search({
            search: searchText,
            limit: this.state.sizePerPage,
            skip: (page - 1) * this.state.sizePerPage
        })

        this.setState({
            data: lead_sources.data,
            total_documents: lead_sources.total_documents,
            canRun: false,
            loading: false,

        })

    }

    onTableChange = async (type, newState) => {

        const { searchText } = this.state

        if(type === 'search') {

            this.queryTableDataThrottled(searchText, true)


        } else {

            this.setState({ page: newState.page, sizePerPage: newState.sizePerPage }, () => {
                this.queryTableDataThrottled(searchText)
            })

        }

    }

    toggleModal = () => this.setState({showModal: !this.state.showModal})

    onLeadSourceAdded = async () => {

        this.toggleModal()
        toggleAlertBS(false, 'Lead source created successfully')

        const lead_sources = await _lead_sources.search({
            search: undefined,
            limit: this.state.sizePerPage
        })

        this.setState({data: lead_sources.data, total_documents: lead_sources.total_documents})

    }

    componentDidMount = async () => {

        const lead_sources = await _lead_sources.search({
            search: undefined,
            limit: this.state.sizePerPage
        })

        this.setState({data: lead_sources.data, total_documents: lead_sources.total_documents})

    }


    


    render() {

        const { data, loading, showModal } = this.state;
        const { viewing_user} = this.props

        return (
            <>

            <Container fluid>

                <Helmet>
                    <title>{`Lead Sources`}</title>
                    <meta name="description" content="Lead Sources" />
                </Helmet>

                <DashHeaderOpen
                    title={<span><i className="fas fa-headset mr-2 text-success " />Analytics</span>}
                    breadcrumb_1="Lead Sources"
                    breadcrumb_2="All"
                    actionComponent={(
                        <div>
                            {
                                viewing_user._id === '63bdb2412c7cc45284f3df07' || // ben
                                viewing_user._id === '63bc5f8ca9f7553ee2c82a9b' || // mike bane
                                viewing_user._id === '63b4961769bd1d7e6de1ba74' || // mike mc
                                viewing_user._id === '63b4961769bd1d7e6de1ba74' || // john
                                viewing_user._id === '63345497404415304760ff6b' || // kevin
                                viewing_user._id === '6300daa78f12344b4d635d8d' || // john
                                viewing_user._id === '635c30ac1493ec155970f248' // mark bacon
                                ? (
                                    <span>
                                        <Link className='btn btn-info' to={`/analytics/lead_source_analysis`}>Analysis</Link>
                                        <Link className='btn btn-warning mr-4' to={`/analytics/lead_source_value`}>Value</Link>
                                    </span>
                                ) : ''
                            }
                            
                            <button onClick={this.toggleModal} className="btn btn-success">Create</button>
                        </div>
                    )}
                />

                <Card>

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">All Lead Sources</CardTitle>
                    </CardHeader>

                    {data ? (
                        <ToolkitProvider
                            data={data.length ? data : []}
                            keyField="_id"
                            columns={this.columns}
                            search
                        >
                            {props => (
                                <>
                                    <div className="dataTables_filter px-4 pb pt-3 pb-2 position-relative" >
                                        <Row>
                                            <Col lg={6}>
                                                <label>
                                                    Search:
                                                    <Input
                                                        className="form-control-sm"
                                                        value={this.state.searchText}
                                                        placeholder="Name, ID"
                                                        style={{width: 190}}
                                                        onChange={(e) => {
                                                            this.setState({searchText: e.target.value})
                                                            this.queryTableDataThrottled(e.target.value, true)
                                                        }}
                                                    />
                                                    {loading ? (<Circle style={{display: 'inline-block', top: 1, position: 'absolute'}} /> ) : null}
                                                </label>
                                            </Col>
                                            <Col lg={6} className="text-right">
                                                <Link to="/analytics/lead_sources/comparison" className="btn btn-outline-warning">Create Comparisson</Link>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className="table-not-fixed table-responsive table-vertical-align">
                                        <BootstrapTable
                                            pagination={paginationFactory({
                                                totalSize: this.state.total_documents,
                                                page: this.state.page,
                                                sizePerPage: this.state.sizePerPage,
                                                alwaysShowAllBtns: true,
                                                showTotal: true,
                                                withFirstAndLast: true,
                                                sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                                                    <div className="dataTables_length" id="datatable-basic_length">
                                                        <label>
                                                            Show{" "}
                                                            {
                                                            <select
                                                                value={currSizePerPage}
                                                                name="datatable-basic_length"
                                                                aria-controls="datatable-basic"
                                                                className="form-control form-control-sm"
                                                                onChange={e => onSizePerPageChange(e.target.value)}
                                                            >
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                            }{" "}
                                                            entries.
                                                        </label>
                                                    </div>
                                                )
                                            })}
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            bordered={false}
                                            remote={{
                                                search: true,
                                                pagination: true,
                                                sort: false,
                                                cellEdit: false
                                            }}
                                            onTableChange={this.onTableChange}

                                        />
                                    </div>
                                </>
                            )}
                        </ToolkitProvider>
                    ) : (
                       <Circle />
                    )}

                </Card>

                <CreateSourceModal 
                    onSuccess={this.onLeadSourceAdded}
                    showModal={showModal}
                    toggleModal={this.toggleModal}
                />

            </Container>

            </>
        );
    }
}


const mapStateToProps = state => {
    return {
        viewing_user : state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(LeadSourcesAll);
