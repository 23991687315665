/*
Documentation

renders a div with a zoom in and out button. on clock it it zooms an element
props.element is an string matching and id on the page

*/

import React, { Component } from 'react';

class Zoom extends Component {

    // increase the css zoom on an element when clicked
    onZoom = () => {

        const el = document.getElementById(this.props.element)

        if(el) {

            const zoom = el.style.zoom

            if(!zoom || zoom > .1) {
                el.style.zoom = zoom ? zoom - .1 : .9
            }

        }

    }

    // decrease the css zoom on an element when clicked
    onUnZoom = () => {

        const el = document.getElementById(this.props.element)

        if(el) {

            const zoom = el.style.zoom

            if(!zoom || parseFloat(zoom) < 2) {
                el.style.zoom = zoom ? parseFloat(zoom) + .1 : 1.1
            }
        }

    }

    render() {

        return (

            <div
                style={{
                    display: 'inline-block',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    zIndex: 100,
                    background: 'rgba(255,255,255,.5)',
                    boxShadow: '0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3)',
                    cursor: 'pointer'
                }}
            >
                <i style={{fontSize: '1rem', borderRight: 'solid 1px #eee', padding: 8}} onClick={this.onZoom}  className="fas fa-search-minus" />
                <i style={{fontSize: '1rem', padding: 8}} onClick={this.onUnZoom} className="fas fa-search-plus" />
            </div>

        )

    }

}

export default Zoom;
