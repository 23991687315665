/*
Documentation

*/

import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Card, CardHeader, CardBody, CardTitle, CardFooter, FormGroup, Input } from "reactstrap";
import Circle from 'components/markup/loading/Circle'

import _compare from '_functions/compare'
import { capitalize} from 'utils/text'

import moment from 'moment'

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';

import timeByValue from 'utils/timeByValue';

import Line from '../../_components/charts/Line'
import Bar from '../../_components/charts/Bar'

import renderName from 'utils/renderName'
import renderNameAndInitial from 'utils/renderNameAndInitial'

import { formatMoney } from 'utils/currency'

import Cookies from 'universal-cookie';

import CompareAll from './CompareAll'
import CompareEach from './CompareEach'

const cookies = new Cookies();

const day = 86400;


const CreateStackedGraph = ({Comp, title, data, field, topLabel, topLabel2, users, type, description}) => {

    const [labels, setLabels] = useState(null)
    const [datasets, setDataSets] = useState(null)

    useEffect(() => {
        if(!data) return;
        const all = [];
        const _labels = [];
        const _datasets = [];
        const _datasets2 = [];
        const _datasets3 = [];
        const _datasets4 = [];

        // data.forEach(d => {
        //     all.push({
        //         user: d._id,
        //         value: d.metrics.consultation[field],
        //         compare: d.metrics.case[field === 'sent' ? 'signed' : field]

        //     })
        // })

        // all.sort((a, b) => parseFloat(a.value) <= parseFloat(b.value) ? 1 : -1)

        // all.forEach(a => {
        //     let label = capitalize(renderName(users.find(u => u._id === a.user)));
        //     if(type === 'percent') label = label + ' (' + a.value + ')'
        //     _labels.push(label)
        //     _datasets.push(parseFloat(a.value))
        //     // _datasets2.push(parseFloat(a.compare))
        // })

        data.forEach(d => {
            console.log(d)
            all.push({
                user: d.user,
                dead: d.data['dead'],
                noShow: d.data['noShow'],
                sentRetainer: d.data['sentRetainer'],
                signed: d.data['signedRetainer'] + d.data['irs'] + d.data['signature'] + d.data['submitted'] + d.data['payment'],
                
            })
        })

        all.sort((a, b) => parseFloat(a.value) <= parseFloat(b.value) ? 1 : -1)

        all.forEach(a => {
            let label = capitalize(renderName(users.find(u => u._id === a.user)));
            _labels.push(label)
            _datasets.push(parseFloat(a.dead))
            _datasets2.push(parseFloat(a.noShow))
            _datasets3.push(parseFloat(a.sentRetainer))
            _datasets4.push(parseFloat(a.signed))
        })


        setDataSets({
            1: _datasets, 
            2: _datasets2,
            3: _datasets3,
            4: _datasets4,
        })
        setLabels(_labels)

    }, [data, field, users, type])

    if(!labels) return ''

    return (
        <Comp
            title={title}      
            classNames="chart-time-series"    
            type={type}   
            stacked={true}         
            data={{
                labels: labels,
                datasets: [
                    {
                        backgroundColor: '#cf2e2e',
                        borderColor: '#cf2e2e',
                        data: datasets[1],
                        label: topLabel ? topLabel : 'Dead'
                    },
                    {
                        backgroundColor: '#fcb900',
                        borderColor: '#fcb900',
                        data: datasets[2],
                        label: topLabel2 ? topLabel2 : 'No Show'
                    },
                    {
                        backgroundColor: '#0693e3',
                        borderColor: '#0693e3',
                        data: datasets[3],
                        label: topLabel2 ? topLabel2 : 'Sent Retainer'
                    },
                    {
                        backgroundColor: '#a7c81b',
                        borderColor: '#a7c81b',
                        data: datasets[4],
                        label: topLabel2 ? topLabel2 : 'Signed'
                    }
                ]
            }}
            description={description}
        />
    )

}


const CreateGraph = ({Comp, title, data, field, topLabel, users, type, description, stacked}) => {

    const [labels, setLabels] = useState(null)
    const [datasets, setDataSets] = useState(null)

    useEffect(() => {
        if(!data) return;
        const all = [];
        const _labels = [];
        const _datasets = [];

        data.forEach(d => {
            all.push({
                user: d.user,
                value: d.data[field]

            })
        })

        all.sort((a, b) => parseFloat(a.value) <= parseFloat(b.value) ? 1 : -1)

        all.forEach(a => {
            let label = capitalize(renderName(users.find(u => u._id === a.user)));
            if(type !== 'percent' && type !== 'currency') label = label + ' (' + a.value + ')'
            if(type === 'percent') label = label + ' (' + a.value + ')'
            _labels.push(label)
            _datasets.push(parseFloat(a.value))
        })

        setDataSets(_datasets)
        setLabels(_labels)

    }, [data, field, users, type])

    if(!labels) return ''

    return (
        <Comp
            title={title}      
            classNames="chart-time-series"    
            type={type}    
            description={description}        
            data={{
                labels: labels,
                datasets: [
                    {
                        backgroundColor: '#06405C',
                        borderColor: '#06405C',
                        data: datasets,
                        fill: false,
                        label: topLabel ? topLabel : 'Cases'
                    }
                ]
            }}
        />
    )

}

class SigningOverview extends React.Component {

    state = {
        users: this.props.users.filter(u => u && u.privileges && u.privileges.includes('can_give_attorney_consultation')).map(u => {
            return u._id
        }),
        data          : null,
        lead_source   : null,
        showLine: cookies.get('analytics_showLine') === 'true' ? true : false,
        query         : {
            start: parseInt(moment().startOf('week').format('X')),
            end: parseInt(moment().endOf('week').format('X')),
            range: 'This Week',
        },
        breakdown: 'together'
    }

    
    onSetQuery = (start, end, range) => {
        this.setState({query: { start, end, range }}, () => {
            this.fetchLeadSource()
        })
    }
    onSetLeadSource = (lead_source) => {
        this.setState({lead_source: lead_source === 'false' ? undefined : lead_source}, () => {
            this.fetchLeadSource()
        })
    }

    fetchLeadSource = async () => {
        this.setState({loading: true})

        let start = this.state.query.start;
        let end = this.state.query.end;
        let label;
        let cLabel;

        let cStart;
        let cEnd;

        const now = Math.floor(new Date() / 1000)
        const daysInMonth = moment.unix(start).daysInMonth();

        const days90 = Math.floor(new Date() / 1000) - (90 * 86400)
        const days180 = Math.floor(new Date() / 1000) - (180 * 86400)
        const days365 = Math.floor(new Date() / 1000) - (365 * 86400)

        // <option value="Last 90 Days">Last 90 Days</option>
        // <option value="Last 180 Days">Last 180 Days</option>
        // <option value="Last 365 Days">Last 365 Days</option>

        switch (this.state.query.range) {
            case 'Today':
                label = 'Today'
                cLabel = 'Yesterday'
                cStart = start - day
                cEnd = end - day
                break;
            case 'Yesterday':
                label = 'Yesterday'
                cLabel = '2 Days Ago'
                cStart = start - (day * 2)
                cEnd = end - (day * 2)
                break;
            case 'This Week':
                label = 'This Week'
                cLabel = 'Last Week'
                cStart = start - (day * 7)
                cEnd = end - (day * 7)
                break;
            case 'Last Week':
                label = 'Last Week'
                cLabel = '2 Weeks ago'
                cStart = start - (day * 14)
                cEnd = end - (day * 14)
                break;
            case 'This Month':
                label = 'This Month'
                cLabel = 'Last Month'
                cStart = start - (day * daysInMonth)
                cEnd = end - (day * daysInMonth)
                break;
            case 'Last Month':
                label = 'Last Month'
                cLabel = 'Two Months Ago'
                cStart = start - (day * daysInMonth)
                cEnd = end - (day * daysInMonth)
                break;
            case 'This Quarter':
                label = 'This Quarter'
                cLabel = 'Last Quarter'
                cStart = start - (end - start)
                cEnd = end - (end - start)
                break;
            case 'Last Quarter':
                label = 'Last Quarter'
                cLabel = '2 Quarters Ago'
                cStart = start - (end - start)
                cEnd = end - (end - start)
                break;
            case 'Last 90 Days':
                label = 'Last Quarter'
                cLabel = '2 Quarters Ago'
                cStart = days90
                cEnd = now
                start = days90
                break;
            case 'Last 180 Days':
                label = 'Last Quarter'
                cLabel = '2 Quarters Ago'
                cStart = days180
                cEnd = now
                start = days180
                break;
            case 'Last 365 Days':
                label = 'Last Quarter'
                cLabel = '2 Quarters Ago'
                cStart = days365
                cEnd = now
                start = days365
                break;
        
            default:
                label = moment.unix(start).format("MMMM")
                cLabel = moment.unix(start).subtract(15, 'days').format("MMMM")
                cStart = parseInt(moment.unix(start).subtract(15, 'days').startOf('month').format('X'))
                cEnd = parseInt(moment.unix(start).subtract(15, 'days').startOf('month').format('X')) + (day * daysInMonth)
                break;
        }

        const data = await _compare.compare({
            users: this.state.users,
            start: start,
            end: end,
            lead_source: this.state.lead_source,
            cStart,
            cEnd,
            label,
            cLabel,
            range: this.state.query.range,
        })
        this.setState({loading: false})
        if(data.success) this.setState({data: data.data})
    }

    getMonthOptions = () => {
        let markup = []

        for (let i = 0; i < 36; i++) {
            const month = moment().subtract(i, 'months').startOf('month');
            markup.push(<option key={i} value={`${month.format('X')}-${month.endOf('month').format('X')}`}>{month.format('MMM - YYYY')}</option>)

        }
        return markup;
    }


    componentDidMount = async () => {

        const val = cookies.get('analytics_query_date');
        const val2 = cookies.get('analytics_query_users');
        if(val || val2) {

            let state = {}

            if(val) {
                const values = timeByValue(val)

                state = {
                    query: {
                        start: values.$gte,
                        end: values.$lt,
                        range: val
                    }
                }
            }

            if(val2) {
                state.users = val2.split('-')
            }

            this.setState(state, () => {
                this.fetchLeadSource()
            })
        } else {
            this.fetchLeadSource()
        }
        
    }

    render() {

        const { data, query, loading, users } = this.state;
        const allUsers = this.props.users

        if(!data || loading) return <div className="py-6"><Circle /></div>

        const hasQuery = Object.keys(query).length

        const Comp = this.state.showLine ? Line : Bar;

        return (
            <>

            <Container fluid>

                <Helmet>
                    <title>{`Documents`}</title>
                    <meta name="description" content="Documents" />
                </Helmet>

                <DashHeaderOpen
                    title={<span><i className="fas fa-headset mr-2 text-success " />Analytics</span>}
                    breadcrumb_1="Documents"
                />

                <Card className="">

                    <CardHeader>
                        <Row>
                            <Col md={8} className="align-self-center">
                                <CardTitle className="mb-0">Document Overview</CardTitle>
                            </Col>
                            <Col md={4} className="align-self-center text-right">
                                <button 
                                    onClick={() => {
                                        this.setState({showLine: !this.state.showLine})
                                        cookies.set('analytics_showLine', !this.state.showLine, { path: '/' });
                                    }} 
                                    className="btn btn-outline-info"
                                >
                                    {this.state.showLine ? 'Show As Bar Graph' : 'Show As Line Graph'}
                                </button>
                            </Col>
                        </Row>
                    </CardHeader>

                    <CardBody className="">
                        <Row>
                            <Col lg={12}>
                                <div className="date-picker-wrapper">
                                    <FormGroup>
                                        <label className="form-control-label">Select Time Frame</label>

                                        <Input
                                            type="select"
                                            value={query.range}
                                            onChange={(e) => {
                                                const val = e.target.value;
                                                const values = timeByValue(val)
                                                this.onSetQuery(values.$gte, values.$lt, val)
                                                cookies.set('analytics_query_date', val, { path: '/' });
                                            }}
                                        >
                                            <option></option>
                                            <option value="Today">Today</option>
                                            <option value="Yesterday">Yesterday</option>
                                            <option value="This Week">This Week</option>
                                            <option value="Last Week">Last Week</option>
                                            <option value="This Month">This Month</option>
                                            <option value="Last Month">Last Month</option>
                                            <option value="This Quarter">This Quarter</option>
                                            <option value="Last Quarter">Last Quarter</option>
                                            <option value="Last 90 Days">Last 90 Days</option>
                                            <option value="Last 180 Days">Last 180 Days</option>
                                            <option value="Last 365 Days">Last 365 Days</option>
                                            {this.getMonthOptions()}
                                        </Input>

                                    </FormGroup>
                                </div>
                            </Col>
                            <Col lg={12}>
                                <Row>
                                    {allUsers.map(u => (
                                        !u.privileges.includes('can_give_attorney_consultation') ? '' : 
                                        <Col md={2}>
                                            <FormGroup>
                                                <div className="custom-control custom-checkbox text-capitalize">
                                                    <input
                                                        checked={users.includes(u._id)}
                                                        onChange={() => {
                                                            let arr = this.state.users;
                                                            if(arr.includes(u._id)) {
                                                                arr = arr.filter(a => a !== u._id)
                                                            } else {
                                                                arr.push(u._id)
                                                            }
                                                            cookies.set('analytics_query_users', arr.join('-'), { path: '/' });

                                                            this.setState({users: arr}, () => {
                                                                this.fetchLeadSource()
                                                            })
                                                        }}
                                                        className="custom-control-input"
                                                        id={`user-select${u._id}`}
                                                        type="checkbox"
                                                    />
                                                    <label className="custom-control-label" htmlFor={`user-select${u._id}`}>{renderNameAndInitial(u)}</label>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    ))}
                                </Row>
                                  
                            </Col>
                        </Row>
                       
                    </CardBody>

                    <CardFooter>
                        <p className="text-sm mb-0"><i className="fas fa-info-circle text-info-original mr-2 " /> Numbers and percentages below are based of each individual users stats. For example, a 25% Close Rate means the user has closed 1 out of every 4 cases they are assigned to. It does NOT mean they've closed 25% of all cases in the system.</p>
                    </CardFooter>

                    <CardFooter className="text-right">
                        <button onClick={this.fetchLeadSource} disabled={!hasQuery} className="btn btn-success"><i className="fas fa-sync mr-2" /> Refresh </button>
                    </CardFooter>

                </Card>


                <CreateStackedGraph 
                    title="User Summary"
                    Comp={Comp}
                    data={data}
                    field='totalSigned'
                    users={allUsers}
                    description="This represents the progression of Dead, No Show, Retainer Sent, and Signed+ cases assigned to each user"
                />
                   

                <CreateGraph 
                    title="Total Signed"
                    Comp={Comp}
                    data={data}
                    field='totalSigned'
                    users={allUsers}
                    topLabel="# Of Signed Cases"
                    description="The total number of cases where the user is an attorney on the case and the case is on one of the following step categories: Signed Retainer, Submitted, With IRS, Awaiting Signature"
                />
                   
                <Row>
                    <Col lg={6}>
                        <CreateGraph 
                            title="Close Rate"
                            Comp={Comp}
                            data={data}
                            field='closeRate'
                            type="percent"
                            users={allUsers}
                            topLabel="Close Percentage"
                            description="The total number of cases signed in  of the following step categories: Signed Retainer, Submitted, With IRS, Awaiting Signature / the total number of cases not in these categories assigned to a user"

                        />
                    </Col>
                    <Col lg={6}>
                        <CreateGraph 
                            title="Total Cases"
                            Comp={Comp}
                            data={data}
                            field='total'
                            users={allUsers}
                            topLabel="Assigned Cases"
                            description="The total number of cases that are currently assigned to a user by looking up any cases where they have a role assigned to them as an Attorney"

                        />
                    </Col>
                  
                    <Col lg={6}>
                        <CreateGraph 
                            title="Avg ERTC"
                            Comp={Comp}
                            data={data}
                            field='avgERTC'
                            users={allUsers}
                            topLabel="Avg Amount"
                            type="currency"
                            description="The total ertc value for all cases assigned to a user / the total number of cases assigned to the user that have an ertc value."

                        />
                    </Col>
                    <Col lg={6}>
                        <CreateGraph 
                            title="Total ERTC"
                            Comp={Comp}
                            data={data}
                            field='totalERTC'
                            users={allUsers}
                            topLabel="Total Amount"
                            type="currency"
                            description="The total ertc value for all cases assigned to a user"

                        />
                    </Col>

                    <Col lg={6}>
                        <CreateGraph 
                            title="Dead Cases"
                            Comp={Comp}
                            data={data}
                            field='dead'
                            users={allUsers}
                            topLabel="# Of Dead Cases"
                            description="The number of cases on a step classified as 'Dead'"
                        />
                    </Col>
                    <Col lg={6}>
                        <CreateGraph 
                            title="With IRS"
                            Comp={Comp}
                            data={data}
                            field='irs'
                            users={allUsers}
                            topLabel="# Of Cases With The IRS"
                            description="The number of cases currently with the irs"

                        />
                    </Col>
                </Row>

                {this.state.breakdown === 'together' ? (
                    <CompareEach allData={data} onSetBreakdown={() => this.setState({breakdown: 'not together'})} />
                ) : (
                    <CompareAll allData={data} onSetBreakdown={() => this.setState({breakdown: 'together'})} />
                )}
                
                {/* <CompareAll allData={data} /> */}


            </Container>

            </>
        );
    }
}


const mapStateToProps = state => {
    return {
        lead_sources : state.lead_sources.lead_sources,
        users : state.users.users,
    };
};

export default connect(mapStateToProps, '')(SigningOverview);
