import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Modal, FormGroup, Input } from "reactstrap";

import { toggleStandardLoader } from 'store/functions/system/system';

import _consultations from '_functions/consultations';

import renderName from 'utils/renderName'

import Circle from 'components/markup/loading/Circle'
import moment from 'moment';

const ModalConsults = ({showModal, toggleModal, contact}) => {

    const [consults, setConsults] = useState(null)
    const [loading, setLoading] = useState(false)

    const fetchData = useCallback(async () => {
        setLoading(true)
        const consultations = await _consultations.find(contact._id);
        if(consultations.data) setConsults(consultations.data)
        setLoading(false)
    }, [contact])

    useEffect(() => {
        if(showModal) {
            fetchData()
        } else {
            setConsults(null)
        }
    }, [fetchData, showModal])
    
    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title text-capitalize">{renderName(contact)} - Consultations</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">
                {loading ? <Circle /> : (
                    <div>
                        {consults && consults.length ? consults.map(c => (
                            <div key={c._id}>
                                <span>Date: {moment.unix(c.start).format("MM/DD/YYYY h:mm A")} - Outcome: {c.outcome ? c.outcome : <span className="text-info-original">Pending Outcome</span>}</span>
                            </div>
                        )) : (
                            'No Consultations Found'
                        )}
                    </div>
                )}
            </div>

            <div className="modal-footer text-right">
                <button className="btn btn-success" onClick={toggleModal}>
                    Close
                </button>
            </div>

        </Modal>

    )

}

ModalConsults.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}

export default ModalConsults
