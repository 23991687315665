import apiWorker from '../apiWorker'

const call_centers = {

    find    : ()            => apiWorker('get',   `/api/v1/core/call_centers`),
    create  : (data)        => apiWorker('post',   `/api/v1/core/call_centers`, data),
    update  : (_id, data)   => apiWorker('patch',  `/api/v1/core/call_centers/${_id}`, data),
    delete  : (_id)         => apiWorker('delete',  `/api/v1/core/call_centers/${_id}`),

    

}

export default call_centers;