import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardTitle, Row, Col, CardFooter, FormGroup, Input, Badge } from "reactstrap";
import { connect } from 'react-redux';
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';

import _companies from '_functions/companies';
import EmailValidator from 'email-validator';

const CompanyCallCenter = ({company}) => {

    const [ trustAlertEmail, setTrustAlertEmail ] = useState('');
    const [ trustAlertEmails, setTrustAlertEmails ] = useState([]);
    const [ trustAlertThreshold, setTrustAlertThreshold ] = useState(0);
    const [ err, setErr ] = useState(0);
    
    const showErr = (err) => {
        setErr(err)
        return setTimeout(() => setErr(false), 2000)
    
    }

    const onAddEmail = () => {

        if(!trustAlertEmail) return;

        if(!EmailValidator.validate(trustAlertEmail)) {
            return showErr(`${trustAlertEmail} is not a valid email address.`)
        }

        const newEmails = [...trustAlertEmails];

        if(newEmails.includes(trustAlertEmail.toLowerCase())) return;

        newEmails.push(trustAlertEmail.toLowerCase());

        setTrustAlertEmails(newEmails);
        setTrustAlertEmail('')

    }

    const onRemoveEmail = (email) => {
        let newEmails = [...trustAlertEmails];
        newEmails = newEmails.filter(e => e !== email);

        setTrustAlertEmails(newEmails);
        setTrustAlertEmail('')
    }

     //on keypress of enter simulate the form being submitted for better UI
	const _handleKeyDown = (e) => {
		if (e.key === 'Enter') {
            onAddEmail()
		}
	}

    const onSave = async () => {

        const threshold = parseFloat(trustAlertThreshold)

        if(threshold !== 0 && !trustAlertEmails.length) {
            return showErr(`If trust alert threshold is greater 0 at least on email needs to be specified.`)
        }

        if(threshold < 0) {
            return showErr(`Trust alert threshold must be greater than 0.`)
        }

        toggleStandardLoader(true);

        const updated = await _companies.update(company._id, {
            trust_alert_threshold: threshold,
            trust_alert_emails: trustAlertEmails,
        })

        if(updated.success) {
            toggleAlertBS(false, `Company updated successfully.`)
        }


        toggleStandardLoader(false)
        
    }

    

    useEffect(() => {

        const newCompany = Object.assign({}, company);
        setTrustAlertThreshold(newCompany.trust_alert_threshold || 0 );
        setTrustAlertEmails(newCompany.trust_alert_emails || [] );


    }, [])

    return (

        <>

        <Card>

            <CardHeader>
                <Row>
                    <Col xs={6}>
                        <CardTitle className="mb-0">Payments</CardTitle>
                    </Col>
                    <Col xs={6} className="text-right">
                        <button onClick={onSave} className="btn btn-success"><i className="fas fa-save mr-2 " />Save</button>
                    </Col>
                </Row>

            </CardHeader>

            {err ? (
                <CardHeader>
                    <p className="text-sm text-danger font-weight-bold mb-0">{err}</p>
                </CardHeader>
            ) : null}

            <CardHeader>
                <FormGroup>
                    <label className="form-control-label">Amount of payments to trust bank account to trigger alert (Set to 0 to disable).</label>
                    <Input 
                        type="number"
                        value={trustAlertThreshold.toString()}
                        onChange={(e) => setTrustAlertThreshold(e.target.value)}
                    />
                </FormGroup>
            </CardHeader>

            <CardHeader>
                <FormGroup>
                    <label className="form-control-label">Send email notifications to:</label>
                    <Input 
                        type="text"
                        value={trustAlertEmail}
                        onChange={(e) => setTrustAlertEmail(e.target.value)}
                        onKeyDown={_handleKeyDown}
                    />
                </FormGroup>

                {trustAlertEmails && trustAlertEmails.length ? trustAlertEmails.map((email, i) => (
                    <Badge key={i} color="warning" className="text-sm">
                        {email}
                        <i onClick={() => onRemoveEmail(email)} className="fas fa-times ml-3 text-danger cursor-pointer" />
                    </Badge>
                )) : null}

            </CardHeader>

            <CardFooter className="text-right">
                <button onClick={onAddEmail} className="btn btn-success"><i className="fas fa-plus mr-2 " /> Add</button>
            </CardFooter>

        </Card>


        </>
    )

}


const mapStateToProps = state => {
    return {
        company: state.companies.company,
    };
};

export default connect(mapStateToProps, '')(CompanyCallCenter);



