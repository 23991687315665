import { connect } from 'react-redux';
import { Card, CardHeader, CardTitle, Col, Row, CardBody } from "reactstrap";

import renderName from 'utils/renderName';

const SigningOverview = ({allData, users, onSetBreakdown}) => {

    const data = JSON.parse(JSON.stringify(allData))
    data.sort((a, b) => a.total >= b.total ? -1 : 1)

    const renderRow = ({d, field, formatter, title}) => {
        return (
            <tr>
                <td className="text-capitalize">{title}</td>
                <td className="text-right">{formatter(d)}</td>
            </tr>
        )
    }

    return (
        <Row>

            {data.map(d => (
            <Col lg={6}>
                {console.log(d)}
                <Card>

                    <CardHeader>
                        <Row>
                            <Col md={6}>
                                <CardTitle className="text-capitalize">{renderName(users.find(u => u._id === d._id))}</CardTitle>
                            </Col>
                            <Col md={6} className="text-right">
                                <button onClick={onSetBreakdown} className="btn btn-success">View Together</button>
                            </Col>
                        </Row>
                    </CardHeader>


                    <div className="table-responsive">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td>Consultations</td>
                                    <td></td>
                                    <td>{d.metrics.consultation.total}</td>
                                </tr>
                                <tr>
                                    <td>Showed</td>
                                    <td>{d.metrics.consultation.total &&  d.metrics.consultation.total ? (((d.metrics.consultation.total - d.metrics.consultation.noShow) / d.metrics.consultation.total) * 100).toFixed(2) : '0'}%</td>
                                    <td>{d.metrics.consultation.total - d.metrics.consultation.noShow}</td>
                                </tr>
                                <tr>
                                    <td>Retainers Sent</td>
                                    <td>{d.metrics.consultation.sent && d.metrics.consultation.total ? (((d.metrics.consultation.sent) / (d.metrics.consultation.total - d.metrics.consultation.noShow)) * 100).toFixed(2) : '0'}%</td>
                                    <td>{d.metrics.consultation.sent}</td>
                                </tr>
                                <tr>
                                    <td>Signed Cases</td>
                                    <td>{d.metrics.case.signed && d.metrics.consultation.sent ? (((d.metrics.case.signed) / d.metrics.consultation.sent) * 100).toFixed(2) : '0'}%</td>
                                    <td>{d.metrics.case.signed}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                

                    {/* <CardBody className="bg-secondary">
                        <p className="text-sm mb-0">% Represents Case Number Over Corresponding Consultation Metric</p>
                    </CardBody> */}
                    
                    {/* <Row>

                        <Col lg={6}>

                            <div className="table-responsive">
                                <table className="table">
                                    <tbody>

                                        {renderRow({
                                            type: 'consultation',
                                            title: 'Total Consultations',
                                            d: d,
                                            field: 'total',
                                            formatter: (_data) => _data.metrics.consultation.total
                                        })}
                                        {renderRow({
                                            type: 'consultation',
                                            title: 'Sent Retainer',
                                            d: d,
                                            field: 'sent',
                                            formatter: (_data) => _data.metrics.consultation.sent
                                        })}
                                        {renderRow({
                                            type: 'consultation',
                                            title: 'No Show',
                                            d: d,
                                            field: 'noShow',
                                            formatter: (_data) => _data.metrics.consultation.noShow
                                        })}
                                        {renderRow({
                                            type: 'consultation',
                                            title: 'Dead',
                                            d: d,
                                            field: 'dead',
                                            formatter: (_data) => _data.metrics.consultation.dead
                                        })}
                                        
                                    </tbody>
                                </table>
                            </div>
                        
                        </Col>

                        <Col lg={6}>

                            <div className="table-responsive">
                                <table className="table">
                                    <tbody>
                                        {renderRow({
                                            type: 'case',
                                            title: 'Total Cases',
                                            d: d,
                                            field: 'total',
                                            formatter: (_data) => (
                                                <div>
                                                    <span> 
                                                        {_data.metrics.consultation.total ? ((_data.metrics.case.total / _data.metrics.consultation.total) * 100).toFixed(2) : '0'}%{' '}
                                                        <i className="fas fa-arrow-right ml-2 text-sm " />
                                                    </span>
                                                    <span style={{display: 'inline-block', width: 30}}>
                                                        {_data.metrics.case.total}
                                                    </span>
                                                </div>
                                            )
                                        })}
                                        {renderRow({
                                            type: 'case',
                                            title: 'Signed',
                                            d: d,
                                            field: 'signed',
                                            formatter: (_data) => (
                                                <div>
                                                    <span> 
                                                        {_data.metrics.consultation.sent ?((_data.metrics.case.signed / _data.metrics.consultation.sent) * 100).toFixed(2) : '0'}%{' '}
                                                        <i className="fas fa-arrow-right ml-2 text-sm " />
                                                    </span>
                                                    <span style={{display: 'inline-block', width: 30}}>
                                                        {_data.metrics.case.signed}
                                                    </span>
                                                </div>
                                            )
                                        })}
                                        {renderRow({
                                            type: 'case',
                                            title: 'No Show',
                                            d: d,
                                            field: 'noShow',
                                            formatter: (_data) => (
                                                <div>
                                                    <span> 
                                                        {_data.metrics.consultation.noShow ?((_data.metrics.case.noShow / _data.metrics.consultation.noShow) * 100).toFixed(2) : '0'}%{' '}
                                                        <i className="fas fa-arrow-right ml-2 text-sm " />
                                                    </span>
                                                    <span style={{display: 'inline-block', width: 30}}>
                                                        {_data.metrics.case.noShow}
                                                    </span>
                                                </div>
                                            )
                                        })}
                                        {renderRow({
                                            type: 'case',
                                            title: 'Dead',
                                            d: d,
                                            field: 'dead',
                                            formatter: (_data) => (
                                                <div>
                                                    <span> 
                                                        {_data.metrics.consultation.dead ?((_data.metrics.case.dead / _data.metrics.consultation.dead) * 100).toFixed(2) : '0'}%{' '}
                                                        <i className="fas fa-arrow-right ml-2 text-sm " />
                                                    </span>
                                                    <span style={{display: 'inline-block', width: 30}}>
                                                        {_data.metrics.case.dead}
                                                    </span>
                                                </div>
                                            )
                                        })}
                                        
                                    </tbody>
                                </table>
                            </div>
                        
                        </Col>

                        <Col lg={6}>


                        </Col>

                    </Row> */}

                </Card>
             </Col>
            ))}

        </Row>
    )
}


const mapStateToProps = state => {
    return {
        users : state.users.users,
    };
};

export default connect(mapStateToProps, '')(SigningOverview);
