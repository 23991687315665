import React from 'react';

import DesktopHeader from './Desktop';
import MobileHeader from './Mobile';

const Header = ({toggleSidebar}) => (
    <>
        <DesktopHeader toggleSidebar={toggleSidebar} />
        <MobileHeader toggleSidebar={toggleSidebar} />
    </>
)

export default Header;