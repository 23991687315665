/*
Documentation

this reducer holds all the actions we can dispatch for our global user object

*** make sure to declare all action types in actions.js one folder level back

*/

import * as actionTypes from '../../actions';
import store from 'store';


const initialState = {
    all_functions_run: false,
    function_total: 9,
    functions_run: 0,
    alerts: {
        show:     false,
        text:     null,
        type:     null,
    },
    standardLoader: false,
    alertBS: null,
    loader: false,
    helpCenter: null,
    devTickets: false,
    recentActivity: {
        show: false
    },
    browser_dialing_status: 'not loaded',
    documents_loading: [],
    documents_for_merge: [],
    online: true,

    stepsToPetitionPrep: {
        contact: '',
        case_id: ''
    },
    
    SETTINGS: {

        changes: {
            appointment_outcomes: 0
        },

        call_center: {
            outcomes: []
        }

    }
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.SET_STEPS_TO_PETITION_PREP:
            return {
                ...state,
                stepsToPetitionPrep: {
                    contact: action.payload.contact,
                    case_id: action.payload.case_id
                }
            }
        case actionTypes.TOGGLE_SYSTEM_ALERT:

            return {
                ...state,
                alerts: {
                    show: action.payload.boolean,
                    text: action.payload.text,
                    type: action.payload.type,
                }
            }

        case actionTypes.TOGGLE_RECENT_ACTIVITY:

            return {
                ...state,
                recentActivity: {
                    show: action.payload.show,
                }
            }

        case actionTypes.TOGGLE_HELP_CENTER:

            if(action.payload && action.payload.body) {

                return {
                    ...state,
                    helpCenter: {
                        body: action.payload.body,
                        footer: action.payload.footer,
                        back: action.payload.back,
                    }
                }

            } else {

                return {
                    ...state,
                    helpCenter: null
                }

            }

        case actionTypes.TOGGLE_SYSTEM_ALERT_BS:

            if(action.payload && action.payload.error !== undefined && action.payload.text) {

                return {
                    ...state,
                    alertBS: {
                        error: action.payload.error,
                        text: action.payload.text,
                    }
                }

            } else {

                return {
                    ...state,
                    alertBS: null
                }

            }

        case actionTypes.TOGGLE_SYSTEM_LOADER:

            return { ...state, loader: action.payload }

        case actionTypes.TOGGLE_DEV_TICKETS:

            return { ...state, devTickets: action.payload }

        case actionTypes.TOGGLE_STANDARD_LOADER:

            return {
                ...state,
                standardLoader: action.payload,
            }

        case actionTypes.FUNCTION_RAN:

            let functions_run = state.functions_run + 1

            return {
                ...state,
                functions_run,
                all_functions_run: functions_run >= state.function_total ? true : false
            }

        case actionTypes.SET_BROWSER_DIALING_STATUS:

            return {
                ...state,
                browser_dialing_status: action.payload.status
            }

        case actionTypes.SET_DOCUMENTS_FOR_MERGE:

            return {
                ...state,
                documents_for_merge: action.payload
            }

        case actionTypes.SET_ONLINE_STATUS:

            return {
                ...state,
                online: action.payload
            }

        case actionTypes.SET_SETTINGS:

            return {
                ...state,
                SETTINGS: action.payload
            }

        case actionTypes.SET_DOCUMENT_LOADING:

            const payload = action.payload
            const documents_loading = [...state.documents_loading]

            if(payload.status === 'starting') {

                // if fileId is already in state it means this is a chunk, we don't need to do anything with it
                if(!documents_loading.some(el => el.fileId === payload.fileId)) {
                    documents_loading.push({
                        status        : payload.status,
                        fileName      : payload.fileName,
                        fileId        : payload.fileId,
                        started       : payload.timestamp,
                        size          : payload.size,
                        finishToken   : payload.finishToken,
                        file          : payload.file,
                        responseText  : payload.responseText,
                        onUpload      : payload.onUpload,
                        onError       : payload.onError,
                        onFinalUpload : payload.onFinalUpload,
                        batchNumber   : payload.batchNumber,
                        batchTotal    : payload.batchTotal,
                    })
                }
            } else {

                const fileIndex = documents_loading.findIndex(el => el.fileId === payload.fileId)

                if(fileIndex !== -1) {

                    if(payload.status !== 'remove') {

                        documents_loading[fileIndex] = {
                            ...documents_loading[fileIndex],
                            status: payload.status,
                            ended: payload.timestamp
                        }

                        // in 5 seconds remove the file from the success or error category
                        setTimeout(() => {
                            store.dispatch({ type: actionTypes.SET_DOCUMENT_LOADING, payload : {fileId: payload.fileId, status: 'remove'} });
                        }, 10000)

                    } else {
                        documents_loading.splice(fileIndex, 1)
                    }

                }

            }

            return { ...state, documents_loading }


        default: return state;

    }

}

export default reducer;
