/*
Documentation

this file renders the right hand sidebar on all automations
here we can change that automation type and updated individual
tasks within the automation

*/

import ReactSelect from 'components/functional/inputs/ReactSelect';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input } from 'reactstrap';

class AutoSidebarTypeText extends Component {

    state = {
        task: {}
    };

    componentDidMount = () => {

        const { template_texts } = this.props
        const options = []

        template_texts.forEach(email => {
            options.push({value: email._id, label: email.name})
        })

        this.setState({options})

    }

    render() {

        const { task, onChange, template_texts } = this.props
        const { options } = this.state

        if(task.type !== 'text') return null

        const foundText = template_texts.find(e => e._id === task.template_text)

        return (

            <li>

                <ReactSelect
                    value={foundText ? {value: foundText._id, label: foundText.name} : {}}
                    onChange={values => onChange('task', 'template_text', values.value)}
                    options={options}
                />

                <hr />

                <FormGroup>
                    <label className="form-control-label">Send From Number</label>
                    <Input 
                        value={task.sending_phone_number || ''}
                        onChange={(e) => onChange('task', 'sending_phone_number', e.target.value)}
                    />
                </FormGroup>

            </li>

        )

    }

}

AutoSidebarTypeText.propTypes = {
    task    : PropTypes.object.isRequired,
    onChange         : PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        template_texts: state.template_texts.template_texts,
    };
};

export default connect(mapStateToProps, '')(AutoSidebarTypeText);
