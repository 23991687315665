import apiWorker from '../apiWorker'

const tags = {

    create: (data)            =>  apiWorker('post',  `/api/v1/core/tags/create`, data),
    update: (tag_id, data)  =>  apiWorker('patch', `/api/v1/core/tags/update/${tag_id}`, data),
    delete: (tag_id)        =>  apiWorker('patch', `/api/v1/core/tags/delete/${tag_id}`),
    find:   (query)           =>  apiWorker('get',    `/api/v1/core/tags/find${query}`),

}

export default tags;