import Circle from 'components/markup/loading/Circle';
import { useCallback, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Container } from "reactstrap";

import moment from 'moment';

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';

import _analytics from '_functions/analytics';

const Monitor = () => {

    const [data, setData] = useState(null)

    const fetchData = useCallback(async () => {
        const data = await _analytics.monitorEmails()
        if(data.data) setData(data.data)
    }, [])


    useEffect(() => {
        fetchData()
    }, [fetchData])

    useEffect(() => {
        const interval = setInterval(() => {
            fetchData()
        }, 10000)

        return () => {
            clearInterval(interval);
        }
    })

    if(!data) return <Circle />

    return (
        <Container fluid>

            <DashHeaderOpen
                title={<span><i className="fas fa-headset mr-2 text-success " />Analytics</span>}
                breadcrumb_1="Monitoring"
                breadcrumb_2="Overview"
            />

            <Card>
                <CardBody>
                    <span><i className="fas fa-info-circle text-info-original mr-2 " /> This page shows up to the last 20 unread email in a users inbox</span>
                    <div>Note it can take up to a minute after and email is marked as read before it may reflect here.</div>
                </CardBody>
            </Card>

            {data.map(d => (

                <Card className="card-color card-primary">

                    <CardHeader>
                        <CardTitle className="mb-0 text-capitalize">{d.name}'s Unread Emails ({d.messages.length >= 20 ? '20+' : d.messages.length})</CardTitle>
                    </CardHeader>


                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <td>Subject</td>
                                    <td>Preview</td>
                                    <td>Attachments</td>
                                    <td className="text-right">Date</td>
                                </tr>
                            </thead>
                            <tbody>
                                {d.messages ? d.messages.map((m, i) => (
                                    <tr key={i}>
                                        <td style={{maxWidth: '50%', whiteSpace: 'pre-line'}}>{m.subject && m.subject.length > 30 ? m.subject.slice(0, 30) + '...' : m.subject}</td>
                                        <td style={{maxWidth: '50%', whiteSpace: 'pre-line'}}>{m.snippet && m.snippet.length > 45 ? m.snippet.slice(0, 45) + '...' : m.snippet}</td>
                                        <td>{m.files.length}</td>
                                        <td className="text-right">{moment(m.date).format('MM/DD/YYYY h:mm A')}</td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td>No Unread Emails Found</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>   
                </Card>         
            ))}




        </Container>
    )
}

export default Monitor;
