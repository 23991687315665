/*
Documentation

This component renders markup to show all employee (user) privileges with the ability to add and remove them.

*/

import PropTypes from 'prop-types';
import React from "react";
import * as privileges from '_settings/privileges'

const PrivilegeCheckbox = (props) => {

    return (
        <>

        {props.title ? (
            <>
                {props.hideHR !== true ? <hr className="mt-3 mb-2"/> : null}
                <h5>{props.title}</h5>
            </>
        ) : null}

        <div className="custom-control custom-checkbox">
            <input
                className="custom-control-input"
                type="checkbox"
                id={props.privilege}
                value={props.user.privileges.includes(props.privilege)}
                checked={props.user.privileges.includes(props.privilege)}
                onChange={(e) => props.setPrivileges(e, props.privilege)}
            />
            <label className="custom-control-label" htmlFor={props.privilege}>
                {props.text}
            </label>
        </div>

        </>

    );

}


class Privilege extends React.Component {

    removeValueFromArray = (privileges, field) => privileges.filter(val => val !== field)

    setPrivileges = (e, field) => {

        const checked = e.target.value

        // const checked = val === 'on' ? true : false;
        let privileges = [...this.props.user.privileges]

        if(checked === 'false') {

            privileges.push(field)

        } else {

            privileges = this.removeValueFromArray(privileges, field);

        }

        this.props.onChange('privileges', privileges)

    }

    render() {

        const user = this.props.user;

        return (
            <>

                <PrivilegeCheckbox
                    hideHR={true}
                    title="ADMIN"
                    privilege={privileges.can_view_admin_dashboard}
                    text="Has access to open and view the admin dashboard"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />
                <PrivilegeCheckbox
                    hideHR={true}
                    privilege={privileges.can_moderate_divisions}
                    text="Can moderate divisions"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />

                <PrivilegeCheckbox
                    title="Analytics"
                    privilege={privileges.can_moderate_analytics}
                    text="Has full access to view analytics and update system reporting"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />

                <PrivilegeCheckbox
                    title="Archives"
                    privilege={privileges.can_moderate_archives}
                    text="Has full access to view and restore archived data"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />

                <PrivilegeCheckbox
                    title="Appointments"
                    privilege={privileges.can_moderate_appointments}
                    text="Can create update or delete appointments"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />

                <PrivilegeCheckbox
                    privilege={privileges.can_moderate_appointment_templates}
                    text="Can moderate appointment templates"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />

                <PrivilegeCheckbox
                    title="Automations"
                    privilege={privileges.can_moderate_automations}
                    text="Can moderate system automations"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />

                <PrivilegeCheckbox
                    title="Case Templates"
                    privilege={privileges.can_moderate_case_templates}
                    text="Can moderate case templates"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />

                <PrivilegeCheckbox
                    title="Case Alert Templates"
                    privilege={privileges.can_moderate_case_alert_templates}
                    text="Can moderate case alert templates"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />


                <PrivilegeCheckbox
                    title="Cases"
                    privilege={privileges.can_delete_cases}
                    text="Can Delete Cases"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />

                <PrivilegeCheckbox
                    privilege={privileges.can_view_cases}
                    text="Can View Cases"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />
                <PrivilegeCheckbox
                    privilege={privileges.can_view_all_cases}
                    text="Can View All Cases / Consultations Regardless Of Assignment"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />
                <PrivilegeCheckbox
                    privilege={privileges.can_update_cases}
                    text="Can update cases"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />

                <PrivilegeCheckbox
                    title="Company"
                    privilege={privileges.can_moderate_company_settings}
                    text="Can update company settings"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />

                <PrivilegeCheckbox
                    title="Contacts"
                    privilege={privileges.can_delete_contacts}
                    text="Can delete contacts"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />
                <PrivilegeCheckbox
                    privilege={privileges.can_update_contacts}
                    text="Can update contacts"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />

                <PrivilegeCheckbox
                    title="Locations"
                    privilege={privileges.can_moderate_locations}
                    text="Can moderate locations"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />

                <PrivilegeCheckbox
                    title="Forms"
                    privilege={privileges.can_moderate_forms}
                    text="Can moderate system forms"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />

                <PrivilegeCheckbox
                    title="Missing Documents"
                    privilege={privileges.can_moderate_missing_docs}
                    text="Can moderate and update missing documents"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />

                <PrivilegeCheckbox
                    title="Staff"
                    privilege={privileges.can_moderate_staff}
                    text="Can view and update other staff members"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />

                <PrivilegeCheckbox
                    privilege={privileges.can_screen_watch}
                    text="Can watch other users screens from the admin call center panel"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />

                <PrivilegeCheckbox
                    title="Tags"
                    privilege={privileges.can_moderate_tags}
                    text="Can view and update system tags and their automations"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />

                <PrivilegeCheckbox
                    title="Template Documents"
                    privilege={privileges.can_moderate_template_docs}
                    text="Can moderate template documents"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />

                <PrivilegeCheckbox
                    title="Offices"
                    privilege={privileges.can_moderate_offices}
                    text="Can moderate offices"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />

                <PrivilegeCheckbox
                    title="Workflows"
                    privilege={privileges.can_moderate_workflows}
                    text="Can moderate workflows"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />

                <PrivilegeCheckbox
                    title="Unfinished Tasks"
                    privilege={privileges.can_moderate_all_unfinished_tasks}
                    text="Can view all unfinished tasks in the system"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />

                <PrivilegeCheckbox
                    title="Call Center"
                    privilege={privileges.can_view_admin_communication}
                    text="Can view admin communication reports"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />

                <PrivilegeCheckbox
                    privilege={privileges.can_view_admin_call_center}
                    text="Can view the admin call center page"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />

                <PrivilegeCheckbox
                    privilege={privileges.calls_new_contacts}
                    text={<span>Can take calls by <b className="text-underline">NEW</b> contacts</span>}
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />
                  <PrivilegeCheckbox
                    privilege={privileges.calls_contacts}
                    text="Can take calls by contacts"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />
                <PrivilegeCheckbox
                    privilege={privileges.calls_business}
                    text={`Can take calls categorized as "business"`}
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />
                <PrivilegeCheckbox
                    privilege={privileges.calls_appointments}
                    text="Can take calls by appointment"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />
                <PrivilegeCheckbox
                    privilege={privileges.can_call_on_mobile}
                    text="Can create calls on a mobile device"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />
              
                <PrivilegeCheckbox
                    title="Emails"
                    privilege={privileges.can_view_all_email_addresses}
                    text="Can see other email address besides the staff email and their own"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />

                <PrivilegeCheckbox
                    title="Developer"
                    privilege={privileges.developer}
                    text="Has the ability to view development information such as logs"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />

                <PrivilegeCheckbox
                    title="Can Jump Steps"
                    privilege={privileges.can_jump_steps}
                    text="Has the ability to jump steps on a case"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />
                <PrivilegeCheckbox
                    title="Is Lead Source"
                    privilege={privileges.can_view_sources}
                    text="Has the ability to view admin lead sources"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />

                <PrivilegeCheckbox
                    title="Can See Lead Sources"
                    privilege={privileges.can_see_lead_sources}
                    text="Can see the lead source on a contact / case"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />

                <PrivilegeCheckbox
                    title="Attorney Consultations"
                    privilege={privileges.can_give_attorney_consultation}
                    text="Has the ability to give an attorney consultation (Must Be Selected To Be Eligable For A Consultation, If Not Selected, Any Checked Boxes Below Will Not Take Effect)"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />
                <PrivilegeCheckbox
                    title="Live Consultations"
                    privilege={privileges.can_take_live_consults}
                    text="Will receive texts regarding live consultations with the ability to take them."
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />
                <PrivilegeCheckbox
                    title="Not General Consultant"
                    privilege={privileges.not_general_consultant}
                    text="Will not be selected in the general booking way, clients can only book through their unique link"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />
                <PrivilegeCheckbox
                    privilege={privileges.can_consult_1_10}
                    text="Can Consult With Business Between 1 and 10 Employees"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />
                <PrivilegeCheckbox
                    privilege={privileges.can_consult_11_20}
                    text="Can Consult With Business Between 11 and 20 Employees"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />
                <PrivilegeCheckbox
                    privilege={privileges.can_consult_21_30}
                    text="Can Consult With Business Between 21 and 30 Employees"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />
                <PrivilegeCheckbox
                    privilege={privileges.can_consult_31_50}
                    text="Can Consult With Business Between 31 and 50 Employees"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />
                <PrivilegeCheckbox
                    privilege={privileges.can_consult_51_100}
                    text="Can Consult With Business Between 51 and 100 Employees"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />
                <PrivilegeCheckbox
                    privilege={privileges.can_consult_100_plus}
                    text="Can Consult With Business Over 100 Employees"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />

                
                <PrivilegeCheckbox
                    title="Can Edit Case Details"
                    privilege={privileges.can_edit_case_details}
                    text="Can edit details submitted by a user from the portal inside a case file"
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />
                <PrivilegeCheckbox
                    title="Can Edit Case Payments, Percentages, +"
                    privilege={privileges.can_edit_case_payments}
                    text="Can edit case payments, percentages, etc."
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />
                <PrivilegeCheckbox
                    title="Tax Attorney"
                    privilege={privileges.can_update_tax_attorney}
                    text="Can change the tax law firm on a case."
                    user={user}
                    setPrivileges={(e, privilege) => this.setPrivileges(e, privilege)}
                />

            </>
        );
    }
}

Privilege.propTypes = {
    user        : PropTypes.object.isRequired,
    onChange    : PropTypes.func.isRequired,
}

export default Privilege
