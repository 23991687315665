import moment from 'moment';
import React, { useState, useCallback, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { FormGroup, Input } from 'reactstrap';

import Cookies from 'universal-cookie';
import timeByValue from 'utils/timeByValue';

const day = 86400

const ArchkDates = ({onChange, label, defaultValue, isPrimary}) => {

    const cookies = new Cookies();

    const [range, setRange] = useState('interval');
    const [date, setDate] = useState('');
    const [dateEnd, setDateEnd] = useState('');
    const [group, setGroup] = useState('Today');
    const [hasRun, setHasRun] = useState(false);

    const onSetRange = useCallback((range) => {
        setRange(range);
        setDate('')
        setDateEnd('')
    }, [])

    useEffect(() => {
        const val = cookies.get('analytics_query_date');
        if(defaultValue && !date) {
            setDate(defaultValue)
        } else if(val && !hasRun) {
            if(isPrimary) {
                const values = timeByValue(val)
                setGroup(val)
                onChange(values)
                setHasRun(true)
            }
        }
        // eslint-disable-next-line
    }, [defaultValue, date, timeByValue, hasRun])

    const getMonthOptions = useCallback(() => {
        let markup = []

        for (let i = 0; i < 36; i++) {
            const month = moment().subtract(i, 'months').startOf('month');
            markup.push(<option key={i} value={`${month.format('X')}-${month.endOf('month').format('X')}`}>{month.format('MMM - YYYY')}</option>)

        }
        return markup;
    }, [])

    const renderDateRange = () => {

        if(range === 'day') return (
            <FormGroup className="pl-4">
                <label className="form-control-label">Select Day</label>
                <div className="date-picker-wrapper">
                    <DatePicker
                        withPortal={ true }
                        portalId="portal-root"
                        popperPlacement="bottom" 
                        selected={date}
                        onChange={date => {
                            const start = parseInt(moment(date).startOf('day').format('X'))
                            onChange({$gte: start, $lt: start + day})
                            setDate(date);
                        }}
                    />
                </div>
            </FormGroup>
        )
        
        if (range === 'range') return (
            <div className="pl-4">
                 <FormGroup>
                     <label className="form-control-label">Select Start</label>
                     <div className="date-picker-wrapper">
                         <DatePicker
                            withPortal={ true }
                            portalId="portal-root"
                            popperPlacement='bottom'
                            selected={date}
                            onChange={date => {
                                const start = parseInt(moment(date).startOf('day').format('X'))
                                const end = dateEnd ? parseInt(moment(dateEnd).startOf('day').format('X')) : start + day
                                onChange({$gte: start, $lt: end})
                                setDate(date);
                            }}
                         />
                     </div>
                 </FormGroup>

                 <FormGroup>
                     <label className="form-control-label">Select End</label>
                     <div className="date-picker-wrapper">
                         <DatePicker
                            withPortal={ true }
                            portalId="portal-root"
                            popperPlacement='bottom'
                            selected={dateEnd}
                            onChange={dateEnd => {
                                const end = parseInt(moment(dateEnd).endOf('day').format('X'))
                                const start = date ? parseInt(moment(date).startOf('day').format('X')) : end - day
                                onChange({$gte: start, $lt: end})
                                setDateEnd(dateEnd);
                            }}
                         />
                     </div>
                 </FormGroup>

            </div>
        )
        
        return (
            <FormGroup className="pl-4">
                <label className="form-control-label">Select Time Frame</label>

                <Input
                    type="select"
                    value={group}
                    onChange={(e) => {

                        const val = e.target.value;
                        const values = timeByValue(val)
                        cookies.set('analytics_query_date', val, { path: '/' });
                        setGroup(val)
                        onChange(values)
                    }}
                >
                    <option></option>
                    <option value="Today">Today</option>
                    <option value="Yesterday">Yesterday</option>
                    <option value="This Week">This Week</option>
                    <option value="Last Week">Last Week</option>
                    <option value="This Month">This Month</option>
                    <option value="Last Month">Last Month</option>
                    <option value="This Quarter">This Quarter</option>
                    <option value="Last Quarter">Last Quarter</option>
                    <option value="Last 90 Days">Last 90 Days</option>
                    <option value="Last 180 Days">Last 180 Days</option>
                    <option value="Last 365 Days">Last 365 Days</option>
                    {getMonthOptions()}
                </Input>

            </FormGroup>
        )

    }

    const formatRange = () => {

        if(range === 'day' && date) {
            return (
                <p className="text-sm mb-2">
                    <i className="fas fa-calendar mr-2 text-warning " />
                    {moment(date).format('MMM Do')}
                </p>
            )
        } else if(range === 'range' && date && dateEnd) {
            return (
                <p className="text-sm mb-2">
                    <i className="fas fa-calendar mr-2 text-warning " />
                    {moment(date).format('MMM Do')}{' - '}
                    {moment(dateEnd).format('MMM Do')}
                </p>

            )
        }

    }

    return (

        <div>

            {formatRange()}
            <FormGroup>
                <label className="form-control-label">{label ? label : 'Date Range'}</label>
                <Input
                    type="select"
                    value={range }
                    onChange={(e) => onSetRange(e.target.value)}
                >
                    <option value="day">Day</option>
                    <option value="range">Range</option>
                    <option value="interval">Time Group</option>
                </Input>

            </FormGroup>
            {renderDateRange()}
        </div>

    )

}

export default ArchkDates;
