import React, { useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Card, CardHeader, CardBody, CardTitle, CardFooter, FormGroup, Input } from "reactstrap";
import Circle from 'components/markup/loading/Circle'

import _signing from '_functions/signing'

import moment from 'moment'

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';

import _analytics from '_functions/analytics';
import keys from 'keys';
import A from 'components/markup/links/A'

import renderName from 'utils/renderName'
import {formatPhone} from 'utils/text'


const Monitor = () => {

    const [data, setData] = useState(null)

    const fetchData = useCallback(async () => {
        const data = await _analytics.monitor()
        if(data.data) setData(data.data)
    }, [])

    const renderCaseRow = (d) => (
        <tr key={d._id}>    
            <td>
                <div className="text-capitalize"><A href={`${keys.APP_URL}/dashboard/cases/view/${d._id}`}>{d.name}</A></div>
                <div className="text-capitalize"><A href={`${keys.APP_URL}/dashboard/cases/view/${d._id}`}>{d.company_dba}</A></div>
            </td>
            <td>
                {d.contact ? (
                    <div>
                        <div className="text-capitalize"><A href={`${keys.APP_URL}/dashboard/cases/view/${d._id}`}>{renderName(d.contact)}</A></div>
                        <div>{d.contact.phone ? formatPhone(d.contact.phone) : ''}</div>
                        <div>{d.contact.email ? d.contact.email : ''}</div>
                    </div>
                ) : 'No Contact Found'}
            </td>
            <td className="text-right">
                <A className="btn btn-success" href={`${keys.APP_URL}/dashboard/cases/view/${d._id}`}>View Case</A>
            </td>
        </tr>
    )

    useEffect(() => {
        fetchData()
    }, [fetchData])

    useEffect(() => {
        const interval = setInterval(() => {
            fetchData()
        }, 10000)

        return () => {
            clearInterval(interval);
        }
    })

    if(!data) return <Circle />

    return (
        <Container fluid>

            <DashHeaderOpen
                title={<span><i className="fas fa-headset mr-2 text-success " />Analytics</span>}
                breadcrumb_1="Monitoring"
                breadcrumb_2="Overview"
                actionComponent={(
                    <span><i className="fas fa-info-circle text-info-original mr-2 " /> This page refreshes every 10 seconds</span>
                )}
            />

            <Card className="card-color card-primary">

                <CardHeader>
                    <CardTitle className="mb-0">All Docs Finished - Awaiting Documents</CardTitle>
                    <p className="text-sm mb-0">This shows all cases in the Retainer Signed - Awaiting Documents step where all documents are marked as completed. This means docs should be submitted to patriot and the step moved</p>
                </CardHeader>

                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <td>Case</td>
                                <td>Contact</td>
                                <td className="text-right">View</td>
                            </tr>
                        </thead>
                        <tbody>
                            {data.finishedSignedRetainer.length ? data.finishedSignedRetainer.map(d => (
                                renderCaseRow(d)
                            )) : (
                                <tr>
                                    <td>No Cases Found</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>            
    
            </Card>
         
            <Card className="card-color card-primary">

                <CardHeader>
                    <CardTitle className="mb-0">All Docs Finished - Pending Signed Report</CardTitle>
                    <p className="text-sm mb-0">This shows all cases in the Pending Signed Report step where all documents are marked as completed. This means the final report should be uploaded to patriot and the step moved</p>
                </CardHeader>

                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <td>Case</td>
                                <td>Contact</td>
                                <td className="text-right">View</td>
                            </tr>
                        </thead>
                        <tbody>
                            {data.finishedPendingSignedReport.length ? data.finishedPendingSignedReport.map(d => (
                                renderCaseRow(d)
                            )) : (
                                <tr>
                                    <td>No Cases Found</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>            

            </Card>
        
            <Card className="card-color card-primary">

                <CardHeader>
                    <CardTitle className="mb-0">Cases With Pending Documents</CardTitle>
                    <p className="text-sm mb-0">This shows all cases where one or more document category is "Pending". These documents need someone to review them immediately.</p>
                </CardHeader>

                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <td>Case</td>
                                <td>Contact</td>
                                <td className="text-right">View</td>
                            </tr>
                        </thead>
                        <tbody>
                            {data.missingDocs.length ? data.missingDocs.map(d => (
                                renderCaseRow(d)
                            )) : (
                                <tr>
                                    <td>No Cases Found</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>            

            </Card>
           
            <Card className="card-color card-primary">

                <CardHeader>
                    <CardTitle className="mb-0">Pending Document Uploads Last 24 Hours</CardTitle>
                    <p className="text-sm mb-0">This shows all documents uploaded in the last 24 hours that have a pending status.</p>
                </CardHeader>

                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <td>Case</td>
                                <td>Docs Uploaded</td>
                                <td className="text-right">View</td>
                            </tr>
                        </thead>
                        <tbody>
                            {data.docsLast24Hours ? data.docsLast24Hours.map(d => (
                                d.case ? (
                                    <tr key={d._id}>    
                                    <td>
                                        <div className="text-capitalize"><A href={`${keys.APP_URL}/dashboard/cases/view/${d.case._id}`}>{d.case.name}</A></div>
                                        <div className="text-capitalize"><A href={`${keys.APP_URL}/dashboard/cases/view/${d.case._id}`}>{d.case.company_dba}</A></div>
                                    </td>
                                    <td>
                                        {d.number}
                                    </td>
                                    <td className="text-right">
                                        <A className="btn btn-success" href={`${keys.APP_URL}/dashboard/cases/view/${d.case._id}`}>View Case</A>
                                    </td>
                                </tr>
                                ) : (
                                    <tr>
                                        <td>{d.friendly_name}</td>
                                        <td>No Case Found</td>
                                        <td></td>
                                    </tr>
                                )
                            )) : (
                                <tr>
                                    <td>No Cases Found</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>            

            </Card>

            {/* <Card className="card-color card-primary">

                <CardHeader>
                    <CardTitle className="mb-0">Recent Final Reports</CardTitle>
                    <p className="text-sm mb-0">This shows a list of the last 30 Final Reports that have been generated by Patriot.</p>
                </CardHeader>

                <CardBody>
                    <p className="text-sm mb-0"><i className="fas fa-info-circle text-info-original mr-2" /> Not Live Yet</p>
                </CardBody>

            </Card> */}

        </Container>
    )
}

export default Monitor;
