
// findUnmovedStepsByAppointments

import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, Col, Container, FormGroup, Input, Row } from 'reactstrap';
import renderName from 'utils/renderName';
import _analytics from '_functions/analytics';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';

class StatsToday extends Component {


    state = {
        data: null,
        groupings: [],
    };

    onQuery = async () => {

        const { start, end, user } = this.state;
        if(!start || !end || !user) return;

        const data = await _analytics.users.statsToday({
            start: parseInt(moment(start).startOf('day').format('X')), 
            end: parseInt(moment(end).endOf('day').format('X')), 
            user_id: user
        });

        if(data.data) this.setState({data: data.data})

    }

    render() {

        const { data } = this.state
        const { users } = this.props;

        return (

            <>
            <Container fluid>

                <DashHeaderOpen
                    title={<span><i className="fas fa-headset mr-2 text-success " />Analytics</span>}
                    breadcrumb_1="Users"
                    breadcrumb_2="User Stats"
                />

                <Card className="card-color card-primary">

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">User Stats</CardTitle>
                    </CardHeader>

                    <CardHeader>
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <label className="form-control-label">User</label>
                                    <Input 
                                        type="select"
                                        value={this.state.user || ''}
                                        onChange={(e) => this.setState({user: e.target.value})}
                                    >
                                        <option></option>
                                        {users.map(user => (
                                            <option value={user._id} key={user._id}>{renderName(user)}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <label className="form-control-label">Start</label>
                                    <Input 
                                        type="date"
                                        value={this.state.start || ''}
                                        onChange={(e) => this.setState({start: e.target.value})}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <label className="form-control-label">End</label>
                                    <Input 
                                        type="date"
                                        value={this.state.end || ''}
                                        onChange={(e) => this.setState({end: e.target.value})}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <hr />
                        <div className="text-right">
                            <button className="btn btn-success" onClick={this.onQuery}>Get Stats</button>
                        </div>
                    </CardHeader>

                    <CardBody>
                        {!data ? 'There is no data to show.' : (
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Stat</th>
                                            <th>Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>User</td>
                                            <td>{renderName(this.props.users.find(u => u._id === data.user_id))}</td>
                                        </tr>
                                        <tr>
                                            <td>Appointments Created</td>
                                            <td>{data.appointments.length}</td>
                                        </tr>
                                        <tr>
                                            <td>Appointments Finished</td>
                                            <td>{data.finished_appointments.length}</td>
                                        </tr>
                                        <tr>
                                            <td>Call Logs Recorded</td>
                                            <td>{data.call_logs.length}</td>
                                        </tr>
                                        <tr>
                                            <td>Documents Uploaded</td>
                                            <td>{data.documents.length}</td>
                                        </tr>
                                        <tr>
                                            <td>Contact Notes Made</td>
                                            <td>{data.contact_notes.length}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </CardBody>

                </Card>

            </Container>
            </>

        )

    }

}




const mapStateToProps = state => {
    return {
        users: state.users.users
    };
};

export default connect(mapStateToProps, '')(StatsToday);