/*
Documentation

this file shows all template emails within the system with the
ability to delete them

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Card, CardHeader, CardTitle } from 'reactstrap';
import NotificationDelete from 'components/functional/notifications/Delete';

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import paginationFactory from "react-bootstrap-table2-paginator";

const { SearchBar } = Search;

class TemplateEmails extends React.Component {

    state = {
        email_to_delete: null,
    }

    columns = [
        {
            dataField: "name",
            text: "Name",
            headerStyle: {width: 150},
            formatter: (cell, row) => (
                <Link to={`/automations/template_emails/view/${row._id}`} className="text-capitalize font-weight-bold">
                    {row.name}
                </Link>
            )
        },
        {
            dataField: "division",
            text: "Division",
            formatter: (cell, row) => {
                const division = this.props.divisions.find(d => d._id === row.division);
                return division ? division.name : '';
            }
        },
        {
            dataField: "subject",
            text: "Subject",
        },

        {
            dataField: "_id",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <Link to={`/automations/template_emails/view/${row._id}`}><i className="fas fa-edit text-success mr-2"></i></Link>
                        <i onClick={() => this.setState({email_to_delete: row})} className="fas fa-trash text-danger"></i>
                    </div>
                )
            }
        },
    ]

    pagination = paginationFactory({
        page: 1,
        sizePerPage: 25,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: true,
        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        value={currSizePerPage}
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    render() {

        const { template_emails } = this.props
        const { email_to_delete } = this.state

        return (
        <>

            <Helmet>
                <title>{`Template Emails`}</title>
                <meta name="description" content="Tags" />
            </Helmet>

            {email_to_delete && (
                <NotificationDelete
                    deletionURL={`/api/v1/automations/template_emails/delete/${email_to_delete._id}`}
                    confirmBtnText="Delete Template"
                    title={<span className="text-capitalize">{`Delete Template: ${email_to_delete.name}`}</span>}
                    text="Deleting this email template will remove it from all automations!"
                    onClose={() => this.setState({email_to_delete: null})}
                    onSuccess={(object) => { } }
                />
            )}

            <Container fluid>

                <DashHeaderOpen
                    title={<span><i className="fas fa-magic mr-2 text-success " /> Automations</span>}
                    breadcrumb_1="Template Emails"
                    breadcrumb_2="All"
                    actionComponent={(
                        <Link to="/automations/template_emails/view/new" className="btn btn-success">
                            <i className="fas fa-plus mr-2" />Add New
                        </Link>
                    )}
                />

                <Card>

                    <CardHeader>
                        <CardTitle className="mb-0">All Template Emails</CardTitle>
                    </CardHeader>

                    <ToolkitProvider
                        data={template_emails}
                        keyField="_id"
                        columns={this.columns}
                        search
                    >
                        {props => (
                            <>
                            <div
                                id="datatable-basic_filter"
                                className="dataTables_filter px-4 pb pt-3 pb-2"
                                style={{width: '100%'}}
                            >
                                <label>
                                    Search:
                                    <SearchBar
                                        className="form-control-sm"
                                        placeholder="Template Name"
                                        {...props.searchProps}
                                    />
                                </label>
                            </div>
                            <div className=" table-responsive table-vertical-align table-not-fixed">
                                <BootstrapTable
                                    {...props.baseProps}
                                    pagination={this.pagination}
                                    bootstrap4={true}
                                    bordered={false}
                                />
                            </div>
                            </>
                        )}
                    </ToolkitProvider>

                </Card>

            </Container>

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        divisions: state.divisions.divisions,
        template_emails: state.template_emails.template_emails,
    };
};

export default connect(mapStateToProps, '')(TemplateEmails);
