import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';

import { Container } from 'reactstrap';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';

import moment from 'moment';
import Dots from 'components/markup/loading/Dots'

import _call_center from '_functions/call_center'

import App from './App'

const CallCenterAdmin = () => {

    const [loading, setLoading] = useState(null);
    const [data, setData] = useState(null);
    const [startOfDay, setStartOfDay] = useState(moment().startOf('day').format('YYYY-MM-DD'));

    const fetchData = useCallback(async () => {
        setLoading(true)
        const data = await _call_center.getAdminOverview(moment(startOfDay).format('X'));
        if(data.data) setData(data.data);
        setLoading(false)
    }, [startOfDay])

    useEffect(() => {
        fetchData();

        document.body.classList.add('noScroll');
        document.body.classList.add('analytics-sidebar-small');

        const interval = setInterval(fetchData, 5000);

        return () => {
            document.body.classList.remove('noScroll');
            document.body.classList.remove('analytics-sidebar-small')
            clearInterval(interval);
        }
    }, [startOfDay])


    return (
        <div>


            <Helmet>
                <title>{`Overview`}</title>
                <meta name="description" content="Overview" />
            </Helmet>


            <Container fluid>
                <DashHeaderOpen
                    title={<span><i className="fas fa-headset mr-2 text-success " /> Communication</span>}
                    breadcrumb_1="Overview"
                    breadcrumb_2="Monitoring"
                    breadcrumb_3={loading ? <span className="text-info-original">Syncing <Dots /></span> : null}
                />
            </Container>

            <App 
                data={data}
                startOfDay={startOfDay}
                setStartOfDay={setStartOfDay}
            />

        </div>
    )

}

export default CallCenterAdmin;