/*
Documentation

workflow
current step
tags
lead attorney
contact


*/


import React, { Component } from 'react';
import Graphs from '../../_components_main/Graphs';
import states from '../../_components/options/states';
import { connect } from 'react-redux'
import { setAnalyticsDataTypes } from 'store/functions/analytics_data_types/analytics_data_types'
import Circle from 'components/markup/loading/Circle';
import getDataFieldInputs from 'views/admin/pages/_functions/getDataFieldInputs'
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import { Container } from 'reactstrap';

class CasesGraphs extends Component {

    state = {
        loaded: false,
        collection: 'cases'
    };

    componentDidMount = async () => {

        // if we dont have data types and data_types length, run redux action to accomplish this
        if(!this.props.analytics_data_types.length) await setAnalyticsDataTypes()

        this.setState({loaded: true})

    }

    render() {

        if(!this.state.loaded) return <div className="py-6"><Circle /></div>
        const customInputs = getDataFieldInputs(this.props.analytics_data_types, this.state.collection)

        return (

            <>

            <Container fluid>
                <DashHeaderOpen         
                    title={<span><i className="fas fa-headset mr-2 text-success " />Analytics</span>}
                    breadcrumb_1="Cases"
                    breadcrumb_2="Graphs"
                />
            </Container>

            <Graphs

                titleMain="Case Graphs"
                titleFilter="Case Graph Filters"

                collection={this.state.collection}

                setup={[
                    { name: 'Time Series',                            type: 'line',   size: 12,   },
                    { name: 'Raw Data',                               type: 'bar',    size: 8,    },
                    { name: 'Filter Breakdown',                       type: 'pie',    size: 4,    },
                    { name: 'Average Contact Attempts By Time Frame', type: 'average',    size: 4,   groups: ['$times_contacted'],   label: 'Contacted ${} Times' },
                    { name: 'Total Times Contacted',                  type: 'group',      size: 8,   groups: ['$times_contacted'],   label: 'Contacted ${} Times' },
                    { name: 'Average Days To Convert',                type: 'group',      size: 8,   groups: ['$time_to_convert'],   label: 'Converted In ${} Days', format: 'days' },
                    { name: 'Average Days To Convert By Time Frame',  type: 'average',    size: 4,   groups: ['$time_to_convert'],   label: 'Contacted ${} Times', format: 'days' },
                ]}

                inputs={[

                    [ // column 1
                        {
                            type: 'ArchkSelect', field: 'gender', label: 'Gender',
                            data: [
                                {value: 'male', text: 'Male'},
                                {value: 'female', text: 'Female'},
                                {value: 'unknown', text: 'Unknown'},
                            ]
                        },
                        {
                            type: 'ArchkSelect', field: 'state', label: 'State',
                            data: states
                        },
                        {
                            type: 'ArchkString', field: 'postal_code', label: 'Postal Code',
                        }
                    ],

                    customInputs

                ]}
            />

            </>

        )

    }

}

const mapStateToProps = state => {
    return {
        analytics_data_types: state.analytics_data_types.analytics_data_types
    };
};

export default connect(mapStateToProps, '')(CasesGraphs);
