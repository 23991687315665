import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import { useCallback, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Badge, Card, CardFooter, CardHeader, CardTitle, Col, Container, FormGroup, Input, Row, CardBody } from "reactstrap";

import { toggleAlertBS } from 'store/functions/system/system';

import ReactSelect from 'components/functional/inputs/ReactSelect';
import _affiliates from '_functions/affiliates';

import Circle from 'components/markup/loading/Circle'

import DragAndDrop from 'components/functional/uploads/DragAndDrop';

import onDownloadDocument from "_functions/documents/download"

const Affiliates = ({lead_sources, match}) => {

    const [password, setPassword] = useState('')
    const [affiliate, setAffiliates] = useState('')
    const [redirect, setRedirect] = useState(null)

    const onSave = useCallback(async () => {
        if(!affiliate.given_name) return;
        if(!affiliate.family_name) return;
        if(!affiliate.phone) return;
        if(!affiliate.email) return;
        const saved = await _affiliates.update(affiliate._id, affiliate)
        if(!saved.success) return toggleAlertBS(true, 'Please try again')

        setRedirect('/analytics/affiliates')
    }, [affiliate])

    const setState = useCallback((field, value) => {
        const a = JSON.parse(JSON.stringify(affiliate))
        a[field] = value;
        setAffiliates(a)
    }, [affiliate])

    const addSource = useCallback((s) => {
        const a = JSON.parse(JSON.stringify(affiliate))
        if(!a.lead_sources.includes(a)) a.lead_sources.push(s)
        setAffiliates(a)
    }, [affiliate])

    const removeSource = useCallback((s) => {
        const a = JSON.parse(JSON.stringify(affiliate))
        a.lead_sources = a.lead_sources.filter(aa => aa !== s)
        setAffiliates(a)
    }, [affiliate])

    const updatePassword = async () => {
        if(password) {
            const updated = await _affiliates.updatePassword(affiliate._id, { password })
            if(updated.success) return toggleAlertBS(false, 'Password updated successfully')
            toggleAlertBS(true, 'Please try again')
        }
    }
   
    const addEBook = useCallback(async (upload, data) => {
        const updated = await _affiliates.update(affiliate._id, { ebook_link: data.data._id })
        if(!updated.success) return toggleAlertBS(true, 'Please try again')
        delete updated.data.password
        setAffiliates(updated.data)
        toggleAlertBS(false, 'EBook added successfully')
    }, [affiliate._id])

    const addQRCode = useCallback(async (upload, data) => {
        const updated = await _affiliates.update(affiliate._id, { qr_code_link: data.data._id })
        if(!updated.success) return toggleAlertBS(true, 'Please try again')
        delete updated.data.password
        setAffiliates(updated.data)
        toggleAlertBS(false, 'QR Code added successfully')
    }, [affiliate._id])

    const addBrochure = useCallback(async (upload, data) => {
        const updated = await _affiliates.update(affiliate._id, { brochure_link: data.data._id })
        if(!updated.success) return toggleAlertBS(true, 'Please try again')
        delete updated.data.password
        setAffiliates(updated.data)
        toggleAlertBS(false, 'Brochure added successfully')
    }, [affiliate._id])

    const addBusinessCard = useCallback(async (upload, data) => {
        const updated = await _affiliates.update(affiliate._id, { business_card_link: data.data._id })
        if(!updated.success) return toggleAlertBS(true, 'Please try again')
        delete updated.data.password
        setAffiliates(updated.data)
        toggleAlertBS(false, 'Business Card added successfully')
    }, [affiliate._id])
   
    const addPostCard = useCallback(async (upload, data) => {
        const updated = await _affiliates.update(affiliate._id, { post_card_link: data.data._id })
        if(!updated.success) return toggleAlertBS(true, 'Please try again')
        delete updated.data.password
        setAffiliates(updated.data)
        toggleAlertBS(false, 'Post Card added successfully')
    }, [affiliate._id])

    const getName = (a) => {
        const found = lead_sources.find(s => s._id === a)
        return found ? found.name : '----'
    }

    const fetchData = useCallback(async () => {
        const data = await _affiliates.find()
        if(data) {
            const found = data.find(d => d._id === match.params._id)
            if(!found) return setRedirect('/analytics/affiliates')
            delete found.password
            setAffiliates(found)
        }
    }, [match.params._id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(redirect) return <Redirect to={redirect} />
    if(!affiliate) return <div className='py-6'><Circle /></div>

    return (
        <Container fluid>

            <Helmet>
                <title>{`Affiliates`}</title>
                <meta name="description" content="Affiliates" />
            </Helmet>

            <DashHeaderOpen
                icon="fas fa-home"
                icon_link="/analytics"
                title={<span >Affiliates</span>} breadcrumb_1="All"
            />

            <Card className="card-color card-primary">

                <CardHeader>
                    <CardTitle className="mb-0">Update Affiliate</CardTitle>
                </CardHeader>

                <CardFooter>
                    <Row>
                        <Col lg={6}>
                            <FormGroup>
                                <label className='form-control-label'>First Name*</label>
                                <Input 
                                    type="text"
                                    value={affiliate.given_name}
                                    onChange={(e) => setState('given_name', e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={6}>
                            <FormGroup>
                                <label className='form-control-label'>Last Name*</label>
                                <Input 
                                    type="text"
                                    value={affiliate.family_name}
                                    onChange={(e) => setState('family_name', e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <FormGroup>
                        <label className='form-control-label'>Email*</label>
                        <Input 
                            type="text"
                            value={affiliate.email}
                            onChange={(e) => setState('email', e.target.value)}
                        />
                    </FormGroup>

                    <FormGroup>
                        <label className='form-control-label'>Phone*</label>
                        <Input 
                            type="text"
                            value={affiliate.phone}
                            onChange={(e) => setState('phone', e.target.value)}
                        />
                    </FormGroup>

                </CardFooter>

                <CardFooter>
                    <FormGroup>
                        <label className='form-control-label'>Lead Sources</label>
                        <ReactSelect
                            onChange={(values) => addSource(values.value)}
                            value={addSource}
                            options={lead_sources.map(l => {
                                return { value: l._id, label: l.name }
                            })}
                        />
                    </FormGroup>

                    {affiliate.lead_sources.map(s => (
                        <Badge 
                            key={s} 
                            color="success"
                        >
                            {getName(s)} 
                            <i className="fas fa-times pl-3 cursor-pointer" onClick={() => removeSource(s)} /> 
                        </Badge>
                    ))}
                </CardFooter>

                <CardFooter className="text-right">
                    <button onClick={onSave} className="btn btn-success">Update</button>
                </CardFooter>
            </Card>

            <Row>
                <Col md={4}>
                    <Card>
                        <CardBody>
                            <h4>
                                EBook{' '}
                                {affiliate.ebook_link ? (
                                    <span>
                                        <i className="fas fa-check ml-2 text-success" />
                                        <span onClick={() => onDownloadDocument(affiliate.ebook_link)} className='cursor-pointer text-underline ml-4' >DOWNLOAD</span>
                                    </span>
                                ) : <i className="fas fa-times ml-2 text-danger" />}
                            </h4>
                            <DragAndDrop
                                zone_id="documents_1"
                                url={`/api/v1/core/documents/create`}
                                onError={(e) => console.log(e)}
                                onUpload={addEBook}
                                defaultValue="Biz Head EBook" 
                                extraData={{bucket: '/affiliates'}}
                            />
                        </CardBody>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <CardBody>
                            <h4>
                                QR Code{' '}
                                {affiliate.qr_code_link ? (
                                    <span>
                                        <i className="fas fa-check ml-2 text-success" />
                                        <span onClick={() => onDownloadDocument(affiliate.qr_code_link)} className='cursor-pointer text-underline ml-4' >DOWNLOAD</span>
                                    </span>
                                ) : <i className="fas fa-times ml-2 text-danger" />}
                            </h4>
                            <DragAndDrop
                                zone_id="documents_2"
                                url={`/api/v1/core/documents/create`}
                                onError={(e) => console.log(e)}
                                onUpload={addQRCode}
                                defaultValue="Biz Head QR Cord" 
                                extraData={{bucket: '/affiliates'}}
                            />
                        </CardBody>
                    </Card>
                </Col>
                <Col md={4}>                        
                    <Card>
                        <CardBody>
                            <h4>
                                Brochure{' '}
                                {affiliate.brochure_link ? (
                                    <span>
                                        <i className="fas fa-check ml-2 text-success" />
                                        <span onClick={() => onDownloadDocument(affiliate.brochure_link)} className='cursor-pointer text-underline ml-4' >DOWNLOAD</span>
                                    </span>
                                ) : <i className="fas fa-times ml-2 text-danger" />}
                            </h4>
                            <DragAndDrop
                                zone_id="documents_3"
                                url={`/api/v1/core/documents/create`}
                                onError={(e) => console.log(e)}
                                onUpload={addBrochure}
                                defaultValue="Biz Head Brochure" 
                                extraData={{bucket: '/affiliates'}}
                            />
                        </CardBody>
                    </Card>
                </Col>
                <Col md={6}>                        
                    <Card>
                        <CardBody>
                            <h4>
                                Business Card{' '}
                                {affiliate.business_card_link ? (
                                    <span>
                                        <i className="fas fa-check ml-2 text-success" />
                                        <span onClick={() => onDownloadDocument(affiliate.business_card_link)} className='cursor-pointer text-underline ml-4' >DOWNLOAD</span>
                                    </span>
                                ) : <i className="fas fa-times ml-2 text-danger" />}
                            </h4>
                            <DragAndDrop
                                zone_id="documents_4"
                                url={`/api/v1/core/documents/create`}
                                onError={(e) => console.log(e)}
                                onUpload={addBusinessCard}
                                defaultValue="Biz Head Business Card" 
                                extraData={{bucket: '/affiliates'}}
                            />
                        </CardBody>
                    </Card>
                </Col>
                <Col md={6}>                        
                    <Card>
                        <CardBody>
                            <h4>
                                Post Card{' '}
                                {affiliate.post_card_link ? (
                                    <span>
                                        <i className="fas fa-check ml-2 text-success" />
                                        <span onClick={() => onDownloadDocument(affiliate.post_card_link)} className='cursor-pointer text-underline ml-4' >DOWNLOAD</span>
                                    </span>
                                ) : <i className="fas fa-times ml-2 text-danger" />}
                            </h4>
                            <DragAndDrop
                                zone_id="documents_5"
                                url={`/api/v1/core/documents/create`}
                                onError={(e) => console.log(e)}
                                onUpload={addPostCard}
                                defaultValue="Biz Head Post Card" 
                                extraData={{bucket: '/affiliates'}}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>

         

           
          

            
           
            <Card>
                <CardBody>
                    <FormGroup>
                        <label className='form-control-label'>Password*</label>
                        <Input 
                            type="text"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </FormGroup>
                </CardBody>
                <CardFooter className="text-right">
                    <button onClick={updatePassword} className='btn btn-success'>Update Password</button>
                </CardFooter>
            </Card>

           
       
        </Container>

    )
}


const mapStateToProps = state => {
    return {
        lead_sources: state.lead_sources.lead_sources,
    };
};

export default connect(mapStateToProps, '')(Affiliates);