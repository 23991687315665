/*
Documentation

This page shows all of the systems missing_docs

*/

import NotificationDelete from 'components/functional/notifications/Delete';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, Container, Row, Col, CardFooter } from "reactstrap";
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import _missing_docs from '_functions/missing_docs';

import QuestionnaireSearchInput from 'components/system/migrated/QuestionnaireSearchInput'

class MissingDocs extends React.Component {

    state = {
        missing_doc: {},
        missing_docToDelete: null,
    }

    columns = [
        {
            dataField: "name",
            text: "Name",
            headerStyle: {width: 150},
            formatter: (cell, row) => <span onClick={() => this.setState({missing_doc: row})} className="cursor-pointer text-capitalize font-weight-bold">{row.name}</span>
        },
        {
            dataField: "_id",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <span onClick={() => this.setState({missing_doc: row})}><i className="cursor-pointer fas fa-edit text-success mr-2"></i></span>
                        <i onClick={() => this.setState({missing_docToDelete: row})} className="cursor-pointer fas fa-trash text-danger"></i>
                    </div>
                )
            }
        },
    ]

    pagination = paginationFactory({
        page: 1,
        sizePerPage: 50,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: true,
        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        value={currSizePerPage}
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    onCreate = async () => {

        const { missing_doc } = this.state

        if(!missing_doc.name) {
            return this.setState({missing_doc: {nameState: 'invalid'}})
        } else {
            this.setState({loading: true})
        }

        await _missing_docs.create(missing_doc)

        this.setState({missing_doc: {}, loading: false})

    }

    onUpdate = async () => {

        const { missing_doc } = this.state

        if(!missing_doc.name) {
            return this.setState({missing_doc: {nameState: 'invalid'}})
        } else {
            this.setState({loading: true})
        }

        await _missing_docs.update(missing_doc._id, missing_doc)

        this.setState({missing_doc: {}, loading: false})

    }

    render() {

        const { missing_docs } = this.props;
        const missing_docLength = this.props.missing_docs.missing_docs.length;
        const missing_docToDelete = this.state.missing_docToDelete;

        const { missing_doc, loading } = this.state;

        const isUpdating = missing_doc._id;

        const foundQuestionnaire = missing_doc.questionnaire ? this.props.questionnaires.find(q => q._id === missing_doc.questionnaire) : null;

        return (
            <>

            <Helmet>
                <title>{`Missing Docs (${missing_docLength}) `}</title>
                <meta name="description" content="Missing Docs" />
            </Helmet>

            {missing_docToDelete && (
                <NotificationDelete
                    deletionURL={`/api/v1/core/missing_docs/delete/${missing_docToDelete._id}`}
                    confirmBtnText="Delete Missing Doc"
                    title={<span className="text-capitalize">{`Delete Missing Doc: ${missing_docToDelete.name}`}</span>}
                    text="Deleting this missing doc will not affect any current cases or workflows. You will however, no longer be able to assign the doc inside of any new workflows you create. If you wish to remove a missing doc from a workflow you may do so on the updated workflow documents screen."
                    onClose={() => this.setState({missing_docToDelete: null})}
                    onSuccess={(object) => { } }
                />
            )}

            <Container fluid>

                <DashHeaderOpen
                    title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
                    breadcrumb_1="Missing Docs"
                    breadcrumb_2="All"
                />

                <Card>

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">Missing Docs</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <p className="text-sm">Missing Docs allow you to create a list of possible documents that need to be collected within a workflow. You may assign a sub section of missing docs to a workflow from the list that is created below.</p>
                    </CardBody>

                </Card>

                <Row>

                    <Col lg={4}>
                        <Card>

                            <CardHeader>
                                <CardTitle className="mb-0 float-left">
                                    {isUpdating ? 'Update Missing Doc' : 'Create Missing Doc' }

                                </CardTitle>
                            </CardHeader>

                            <CardBody>

                                <StandardFormGroup
                                    obj={missing_doc}
                                    objName="missing_doc"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="name"
                                    title="Missing Doc Name"
                                />

                                <StandardFormGroup
                                    obj={missing_doc}
                                    objName="missing_doc"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="description"
                                    title="Description To Clients"
                                    type="textarea"
                                />


                                <QuestionnaireSearchInput
                                    value={missing_doc.questionnaire ? this.props.questionnaires.find(q => q._id === missing_doc.questionnaire) : {}}
                                    onSelect={(questionnaire) => this.setState({missing_doc: { ...this.state.missing_doc, questionnaire: questionnaire._id }})}
                                    titleFormatter={() => 'Select Questionnaire'}
                                />
                                {foundQuestionnaire ? <p className="text-sm mb-0 mt-0">Selected: <b className="text-info-original">{foundQuestionnaire.name}</b></p> : null }

                            </CardBody>

                            <CardFooter className="text-right">
                                {isUpdating ? (
                                    <button disabled={loading} className="btn btn-success" onClick={this.onUpdate}>Update Missing Doc</button>
                                ) : (
                                    <button disabled={loading} className="btn btn-success" onClick={this.onCreate}>Create New Missing Doc</button>
                                )}
                            </CardFooter>

                        </Card>
                    </Col>

                    <Col lg={8}>
                        <Card>

                            <CardHeader>
                                <CardTitle className="mb-0 float-left">All Missing Docs</CardTitle>
                            </CardHeader>

                            {missing_docs.total ? (

                                <ToolkitProvider
                                    data={missing_docs.missing_docs}
                                    keyField="_id"
                                    columns={this.columns}
                                    search
                                >
                                    {props => (
                                        <>
                                        <div className=" table-responsive table-vertical-align table-not-fixed">
                                            <BootstrapTable
                                                pagination={this.pagination}
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                bordered={false}
                                            />
                                        </div>
                                        </>
                                    )}
                                </ToolkitProvider>

                            ) : (

                                <CardBody>
                                    <p className="text-sm mb-0">No Missing Docs Have Been Created Yet.</p>
                                </CardBody>

                            )}

                        </Card>
                    </Col>

                </Row>

            </Container>

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        missing_docs: state.missing_docs,
        questionnaires: state.questionnaires.questionnaires,
    };
};

export default connect(mapStateToProps, '')(MissingDocs);

