import React from 'react';
import { Modal } from 'reactstrap';
import renderName from 'utils/renderName';

import EmailValidator from 'email-validator';
import { validatePhoneNumber } from 'utils/validation';

const Accent = ({children}) => (
    <b className="text-dark font-weight-bold text-underline">{children}</b>
)

const EmailParserModalConfirmation = ({showModal, toggleModal, parser, onSave}) => {

    // TODO: change
    const validDates = parser.fields.filter(date => date.name !== 'Unsaved Date');

    const confirmedSave = () => {
        parser.dates = validDates;
        onSave(parser);
    }

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={() => toggleModal(false)}
            size="lg"
        >
            <div className="modal-header">

                <h5 className="modal-title" id="exampleModalLabel">Confirm Parser Settings</h5>

                <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => toggleModal(false)} >
                    <span aria-hidden={true}>×</span>
                </button>

            </div>

            <div className="modal-body">

                {parser.active ? (
                    <div className="alert alert-success">
                        <i className="fas fa-check mr-2" /> This is active and ready to go.
                    </div>
                ) : (
                    <div className="alert alert-danger">
                        <i className="fas fa-times mr-2" /> This parser will be turned off and not process any emails.
                    </div>
                )}

                <h3>{parser.name}</h3>

                <p className="text-sm">
                    This parser will look for email subjects that include{' '}
                    <Accent>{parser.subject_includes}</Accent>{' '}
                    and that come from the email(s):{' '}
                    <Accent>
                        {parser.from_emails.map((email, index) => index === 0 ? email : ', ' + email )}
                    </Accent>
                </p>

                <p className="text-sm">
                    This parser will look to identifier the contact of the email by the field:{' '}
                    <Accent>"{parser.contact_identifier}"</Accent>{' '}
                    that sits between the following text and characters:{' '}
                    <Accent>{parser.contact_identifier_before} {`{${parser.contact_identifier}}`} {parser.contact_identifier_after}.</Accent>{' '}
                    The parser will look for this field in the <Accent>{parser.contact_identifier_search}</Accent> of the email.
                </p>

                <p className="text-sm">
                    This parser <Accent>{parser.download_documents === 'yes' ? <span className="text-success">WILL</span> : <span className="text-danger">WILL NOT</span>}</Accent>{' '}
                    try to download document attached on this email to the contacts case
                </p>
                
                <p className="text-sm">
                    Upon parsing this email it <Accent>{parser.move_to_step ? <span className="text-success">WILL</span> : <span className="text-danger">WILL NOT</span>}</Accent> try to move the associated contacts step.
                </p>

                <p className="text-sm">
                    {parser.tags.length ? (
                        <span>
                            This parser will try to attach the following tags to the contact identified in the email:{' '}
                            <Accent>{parser.tags.map((tag, i) => i === 0 ? tag.name : ', ' + tag.name)}</Accent>
                        </span>
                    ) : 'This parser will not attach any tags to the contact found.'}
                </p>

                <p className="text-sm">
                    {parser.failed_email_address ? (
                        <span>The parser will send failed attempts at parsing an email to <Accent>{parser.failed_email_address}</Accent></span>
                    ) : 'The parser will not send failed attempts to an email address.'}
                </p>

                {validDates.length ? (
                    <span>
                        The parser will try to attach the following actions to dates parsed in this email:{' '}
                        {validDates.map((date, i) => (
                            <div key={i} className="bg-secondary p-4 mb-4">
                                <h4>{date.name}</h4>

                                <div>Looks for <Accent>{date.text_before} {`{DATE}`} {date.text_after}</Accent></div>
                                
                                <div>
                                    {date.email_send === 'yes' ? ( `Will send an email to ${date.email_address}` ) : ( 'Will Not send an email upon parsing' )}
                                    {!EmailValidator.validate(date.email_address) ? <p className="text-warning">WARNING: {date.email_address} may not be a valid email address.</p> : null}
                                </div>

                                <div>
                                    {date.text_send === 'yes' ? ( `Will send a text to ${date.text_number}` ) : ( 'Will Not send a text upon parsing' )}
                                    {!validatePhoneNumber(date.text_number) ? <p className="text-warning">WARNING: {date.text_number} may not be a valid phone number.</p> : null}
                                </div>

                                <div>
                                    {date.appointment_create === 'yes' ? (
                                        <span>
                                            Will try to create the appointment {date.appointment_template.name}
                                            {date.appointment_users.length ? (
                                                <span>
                                                    {' '}and assign the following users:{' '}
                                                    <Accent>
                                                        <span className="text-capitalize">{date.appointment_users.map((user, i) => i === 0 ? renderName(user) : ', ' + renderName(user))}</span>
                                                    </Accent>
                                                </span>
                                            ) : '.'}
                                        </span>
                                    ) : 'Will not try to create an appointment'}
                                </div>

                            </div>
                        ))}
                    </span>
                ) : 'This parser will not check for any dates in this email.'}

            </div>

            <div className="modal-footer">
                <button onClick={() => toggleModal(false)} className="btn btn-warning"><i className="fas fa-arrow-left mr-2 " /> Back</button>
                <button onClick={confirmedSave} className="btn btn-success"><i className="fas fa-save mr-2 " /> Save</button>
            </div>

        </Modal>
    );

}

export default EmailParserModalConfirmation;
