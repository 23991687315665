
import { Modal } from "reactstrap";
import renderName from 'utils/renderName';
import keys from 'keys';
import A from 'components/markup/links/A'

const ModalViewContacts = ({showModal, toggleModal, contacts}) => {

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">Contacts (Showing Most Recent 300)</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {contacts.length ? contacts.map(contact => {
                                contact = contact.contact ? contact.contact : {}
                                return (
                                    <tr>
                                        <td>{renderName(contact)}</td>
                                        <td>{contact.email ? contact.email : '-'}</td>
                                        <td>{contact.phone ? contact.phone : '-'}</td>
                                        <td className="text-right"><A className="btn btn-success" href={`${keys.APP_URL}/dashboard/contacts/view/${contact._id}`}>View</A></td>
                                    </tr>
                                )
                            }) : (
                                <tr>
                                    <tr>
                                        <td>No Contacts Found</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="modal-footer text-right">
                <button className="btn btn-outline-warning" onClick={toggleModal} >
                    <i className="fas fa-arrow-left mr-2" />
                    Close
                </button>
            </div>

        </Modal>

    )

}

export default ModalViewContacts
