import React from "react";
import { CardHeader, CardTitle, FormGroup, Input } from "reactstrap";

const EmailParserIdentifierContact = ({parser, onChange}) => {

    return (

        <>

            <CardHeader className="text-uppercase">
                <CardTitle className="mb-0 float-left">Contact Identifiers</CardTitle>
            </CardHeader>

            <CardHeader>

                <FormGroup>
                    <label className="form-control-label">Contact Field? *</label>
                    <Input 
                        type="select"
                        value={parser.contact_identifier || ''}
                        onChange={(e) => onChange(e, 'contact_identifier')}
                        valid={ parser.contact_identifierState === "valid" }
                        invalid={ parser.contact_identifierState === "invalid" }
                    >
                        <option value="false"></option>
                        <option value="case_number">Case Number</option>
                        <option value="case_id">Case ID</option>
                        <option value="contact_id">Contact ID</option>
                        <option value="contact_email">Contact Email</option>
                        <option value="contact_phone">Contact Phone</option>
                    </Input>
                </FormGroup>

                <FormGroup>
                    <label className="form-control-label">Search For Identifier In:</label>
                    <Input 
                        type="select"
                        value={parser.contact_identifier_search || ''}
                        onChange={(e) => onChange(e, 'contact_identifier_search')}
                        valid={ parser.contact_identifier_searchState === "valid" }
                        invalid={ parser.contact_identifier_searchState === "invalid" }
                    >
                        <option value="subject">Email Subject</option>
                        <option value="body">Email Body</option>
                    </Input>
                </FormGroup>
                
                <FormGroup>
                    <label className="form-control-label">Text/Characters directly <b className="font-weight-bold text-underline">BEFORE</b> the contact identifier*</label>
                    <Input 
                        type="text"
                        value={parser.contact_identifier_before || ''}
                        onChange={(e) => onChange(e, 'contact_identifier_before')}
                        valid={ parser.contact_identifier_beforeState === "valid" }
                        invalid={ parser.contact_identifier_beforeState === "invalid" }
                    />
                </FormGroup>

                <FormGroup>
                    <label className="form-control-label">Text/Characters directly <b className="font-weight-bold text-underline">AFTER</b> the contact identifier*</label>
                    <Input 
                        type="text"
                        value={parser.contact_identifier_after || ''}
                        onChange={(e) => onChange(e, 'contact_identifier_after')}
                        valid={ parser.contact_identifier_afterState === "valid" }
                        invalid={ parser.contact_identifier_afterState === "invalid" }
                    />
                </FormGroup>

                <FormGroup>
                    <label className="form-control-label">
                        Use Regex On Match 
                        <span className="text-danger d-block"><i className="fas fa-exclamation-triangle" /> ADVANCED</span>
                    </label>
                    <Input 
                        type="text"
                        value={parser.contact_identifier_regex || ''}
                        onChange={(e) => onChange(e, 'contact_identifier_regex')}
                        valid={ parser.contact_identifier_regexState === "valid" }
                        invalid={ parser.contact_identifier_regexState === "invalid" }
                    />
                </FormGroup>
            </CardHeader>



        </>
        
    );
}

export default EmailParserIdentifierContact
