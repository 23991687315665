import React from 'react';
import AdminView from 'components/system/migrated/call_center_admin';
import { Container } from 'reactstrap';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';

const CallCenterAdmin = () => (

    <>

        <Container fluid>
            <DashHeaderOpen
                title={<span><i className="fas fa-headset mr-2 text-success " /> Communication</span>}
                breadcrumb_1="Call Center"
                breadcrumb_2="Admin"
            />
        </Container>

        <AdminView hideHeader={true} />

    </>

)

export default CallCenterAdmin;