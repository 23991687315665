import React, {useEffect} from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import renderName from 'utils/renderName';

import _analytics from '_functions/analytics'

import QueryCreator from './QueryCreator';

const QueryCallUnder90 = ({users}) => {

    useEffect(() => {
        document.body.classList.add('analytics-sidebar-small')
        return () => {
            document.body.classList.remove('analytics-sidebar-small')
        }

    }, [])

    return (
        <QueryCreator 
            title="CallsUnder90"
            hideSearch={true}
            runQuery={async (query, cb) => new Promise (async resolve => {
                const result = await _analytics.query.callsUnder90(query)
                return resolve(result.data)
            })}
            inputs={[
                { type: 'ArchkDates', field: 'date', label: '', },
                {
                    type: 'ArchkSelectSimple', field: 'user', label: 'User',
                    data: users.map(t => { return {value: t._id, text: renderName(t)} })
                },               
            ]}
            columns={[
                {
                    dataField: "date",
                    text: "Date",
                    formatter: (cell, row) => (
                        <div>
                            <div>{moment.unix(row.date).format("M/D/YYYY - h:mm A")}</div>
                        </div>
                    )
                },
                {
                    dataField: "date",
                    text: "Date",
                    formatter: (cell, row) => (
                        <div>
                            {row.user ? (
                                <div className="text-capitalize">{renderName(row.user)}</div>
                            ) : 'No User Found'}
                        </div>
                    )
                },
                {
                    dataField: "time",
                    text: "Seconds To Call",
                    headerStyle: {textAlign: 'right'},
                    formatter: (cell, row) => (
                        <div className="text-right ">
                            {row.time}
                        </div>
                    )
                },

            ]}
        
        />
    )

}



const mapStateToProps = state => {
    return {
        users: state.users.users,
    };
};

export default connect(mapStateToProps, '')(QueryCallUnder90);