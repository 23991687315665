

// findUnmovedStepsByAppointments

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardFooter, CardHeader, CardTitle, Col,  FormGroup, Input, Row } from 'reactstrap';
import renderName from 'utils/renderName';
import UserSearchInput from 'components/system/migrated/UserSearchInput';
import _analytics from '_functions/analytics';

const AppointmentsBreakdownQuery = ({groupings, tags, lead_sources, setData, divisions}) => {

    const [ state, setState ] = useState({
        grouping: null,
        division: null,
        start: moment().startOf('day').format('YYYY-MM-DD'),
        user: {},
        tag: null,
        lead_source: null
    })

    const onStateChange = (obj) => setState({...state, ...obj});

    const onQuery = async () => {

        const { start, user, grouping, tag, lead_source } = state;
        if(!start) return;

        const startDate = moment(start).startOf('day').format('X');

        let query = `?start=${startDate}`;
        
        if(user._id) {
            query += `&user_id=${user._id}`;
        }

        if(tag && tag !== 'null') {
            query += `&tag_id=${tag}`;
        }

        if(lead_source && lead_source !== 'null') {
            query += `&lead_source_id=${lead_source}`;
        }

        if(grouping && grouping !== 'null') {
            query += `&grouping_id=${grouping}`
        }

        if(division && division !== 'false') {
            query += `&division=${division}`
        }

        setData({ loading: true })

        let data = await _analytics.appointments.breakdown(query);

        setData({
            loading: false,
            error: data.message,
            data: data ? data.data : null,
            queryInfo: { start, user, grouping }
        })

    }

    useEffect(() => {
        onQuery()
    }, [])

    const { start, user, grouping, tag, lead_source, division } = state;

    return (

        <Card className="card-color card-primary">

            <CardHeader>
                <CardTitle className="mb-0 float-left">Appointment Analytics</CardTitle>
            </CardHeader>

            <CardHeader>

                <Row>
                    <Col lg={6}>
                        <FormGroup>
                            <label className="form-control-label">Start</label>
                            <Input 
                                type="date"
                                value={start || ''}
                                onChange={(e) => onStateChange({start: e.target.value})}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={6}>
                        <FormGroup>

                            <label className="form-control-label">
                                {user._id ? (
                                    <span className="text-capitalize">
                                        Selected User: {renderName(user)}{' '}
                                        <i className="fas fa-times ml-2 text-danger cursor-pointer " onClick={() => onStateChange({user: {}})} />
                                    </span>
                                ): 'Select User'}
                            </label>

                            <UserSearchInput
                                hideTitle={true}
                                value={user ? user : {}}
                                onSelect={(user) => onStateChange({user})}
                            />

                        </FormGroup>
                    </Col>

                </Row>

                <FormGroup>
                    <label className="form-control-label">Division</label>
                    <Input 
                        type="select"
                        value={division || 'false'}
                        onChange={(e) => onStateChange({division: e.target.value})}
                    >
                        <option value="false">All Divisions (No Filter)</option>
                        {divisions.map((division, i) => (
                            <option key={i} value={division._id}>{division.name}</option>
                        ))}
                    </Input>
                </FormGroup>
                
                <Row>
                
                    <Col lg={4}>
                        <FormGroup>
                            <label className="form-control-label">Appointment Type</label>
                            <Input 
                                type="select"
                                value={grouping || ''}
                                onChange={(e) => onStateChange({grouping: e.target.value})}
                            >
                                <option value="null"></option>
                                {groupings.map((grouping, i) => (
                                    <option key={i} value={grouping._id}>{grouping.name}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>

                    <Col lg={4}>
                        <FormGroup>
                            <label className="form-control-label">Contact Tag</label>
                            <Input 
                                type="select"
                                value={tag || ''}
                                onChange={(e) => onStateChange({tag: e.target.value})}
                            >
                                <option value="null"></option>
                                {tags.map((tag, i) => (
                                    <option key={i} value={tag._id}>{tag.name}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>

                    <Col lg={4}>
                        <FormGroup>
                            <label className="form-control-label">Lead Source</label>
                            <Input 
                                type="select"
                                value={lead_source || ''}
                                onChange={(e) => onStateChange({lead_source: e.target.value})}
                            >
                                <option value="null"></option>
                                {lead_sources.map((lead_source, i) => (
                                    <option key={i} value={lead_source._id}>{lead_source.name}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>

                </Row>


            </CardHeader>

            <CardFooter className="text-right">
                <button className="btn btn-success" onClick={onQuery}>Get Stats</button>
            </CardFooter>

        </Card>

    )

}

const mapStateToProps = state => {
    return {
        divisions: state.divisions.divisions,
        lead_sources: state.lead_sources.lead_sources,
        tags: state.tags.tags,
        groupings: state.groupings.groupings.filter(g => g.collection_name === 'template_appointments')
    };
};

export default connect(mapStateToProps, '')(AppointmentsBreakdownQuery);   
