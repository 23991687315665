import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col, Row, Container } from 'reactstrap';
import ArchkDates from '../inputs/ArchkDates';
import ArchkSelect from '../inputs/ArchkSelect';
import Filter from './Filter';


class SearchController extends Component {


    state = {

        editingFilter: 0,

        filters:  this.props.showOneFilter ? [ {__filter_name: 'Report Filter', __filter_timestamp: Math.floor(new Date())} ] : [],
        interval: null,
        range: null,
        settings: { labels: ['All Contacts', 'Males', 'Females', 'Unknown'] }
    };

    sendChangeToProps = () => {

        const filters = this.state.filters;
        const range =  this.state.range;
        const interval =  this.state.interval;
        const collection = this.props.collection

        const labels = ['All Data'];
        filters.forEach(f => labels.push(f.__filter_name))

        const data = {

            settings: {
                interval,
                labels,
                collection,
                range,
            },

            filters

        }

        this.props.onQueryChange(data)

    }

    onIntervalChange = (interval) => {

        if(!interval) interval = 'day'
        this.setState({interval}, () => this.sendChangeToProps())

    }

    onRangeChange = (range) => {

        let value = this.state.range

        if(value === undefined) {
            return this.setState({range: {created_at: undefined}}, () => this.sendChangeToProps())
        }

        if(range && range.toString().includes('{between}')) {

            try {

                value = {
                    $gt: parseInt(range.split(' ')[0]),
                    $lt: parseInt(range.split(' ')[1].split('{between}')[0]),
                }


            } catch(e) {


            }

        }

        this.setState({range: {created_at: value}}, () => this.sendChangeToProps())

    }

    onFilterChange = (field, value, index) => {

        const filters = [...this.state.filters]
        const filter = Object.assign({}, filters[index])

        filter[field] = value ? value : value === false ? false : undefined;
        filters[index] = filter

        this.setState({filters}, () => this.sendChangeToProps())

    }

    onAddFilter = () => {

        let filters = [...this.state.filters]

        const filterLength = filters.length;

        if(filterLength < 6) {

            filters.push({__filter_name: `Filter ${filterLength + 1}`, __filter_timestamp: Math.floor(new Date())})
            this.setState({filters, editingFilter: filterLength}, () => this.sendChangeToProps())

        }

    }

    onRemoveFilter = (filterToDelete) => {

        let filters = [...this.state.filters]
        filters = filters.filter(f => f.__filter_timestamp !== filterToDelete.__filter_timestamp)


        this.setState({filters, editingFilter: 0}, () => this.sendChangeToProps())

    }

    componentDidMount = () => {

        const { filters } = this.props
        let filtersToSet = []

        if(filters && filters.length) {

            filters.forEach((filter, i) => {

                filtersToSet.push({
                    status: filter.status,
                    __filter_name: filter.filter_name,
                    __filter_timestamp: (Math.floor(new Date()) + i.toString()),
                })

            })

            this.setState({filters: filtersToSet})

        }

    }

    render() {

        const { filters, editingFilter } = this.state

        const { inputs, dateError, queryError, showOneFilter, titleMain, titleFilter, hideInterval } = this.props

        return (

            <>

            <div className="query-container">

                <Container fluid>

                    <Card>

                        <CardHeader>
                            <Row>
                                <Col lg={2}>
                                    <CardTitle className="mb-0">
                                        {titleMain ? titleMain : 'Analytics'}
                                    </CardTitle>
                                </Col>
                                <Col lg={10}>
                                    <CardTitle className="mb-0">
                                        {titleFilter ? titleFilter : 'Filters'}
                                    </CardTitle>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>

                            <Row className="bg-secondary">

                                <Col lg={2} className="base-selector column bg-white">

                                    <ArchkDates
                                        onChange={this.onRangeChange}
                                    />

                                    {dateError ? (<div className="alert alert-danger">{dateError}</div>) : null}


                                    {hideInterval !== true ? (
                                        <ArchkSelect
                                            label="Interval"
                                            onSelect={this.onIntervalChange}
                                            data={[
                                                {value: 'day', text: 'Day'},
                                                {value: 'week', text: 'Week'},
                                                {value: 'month', text: 'Month'},
                                            ]}
                                        />
                                    ): null}

                                    <hr />

                                    {queryError ? ( <div className="alert alert-danger">{queryError}</div> ) : null}

                                    <button onClick={this.props.onRunQuery} className="btn btn-block btn-success">Run Query</button>

                                </Col>

                                <Col lg={10} className="filter-wrapper">

                                    {!showOneFilter ? (
                                        <Row>
                                            <Col xs={12} className="px-0">
                                                <ul className="filter-container">

                                                    <li onClick={this.onAddFilter}>
                                                        Add Filter  <i className="fas fa-plus ml-2" />
                                                    </li>

                                                    {filters && filters.length ? filters.map((f, i) => (
                                                        <li key={f.__filter_timestamp} className={editingFilter === i ? 'active' : null} onClick={() => this.setState({editingFilter: i})}>
                                                            {f.__filter_name} <i className="fas fa-edit ml-2" />
                                                        </li>
                                                    )) : null}

                                                </ul>
                                            </Col>
                                        </Row>
                                    ) : null}

                                    {filters && filters.length ? filters.map((f, i) => (
                                        <Filter
                                            key={f.__filter_timestamp}
                                            onRemoveFilter={() => this.onRemoveFilter(f)}
                                            show={editingFilter === i}
                                            filter={f}
                                            inputs={inputs}
                                            onChange={(field, value) => this.onFilterChange(field, value, i)}
                                            showOneFilter={showOneFilter}
                                        />
                                    )) : null}

                                </Col>

                            </Row>

                        </CardBody>

                    </Card>

                </Container>
            </div>

            </>

        )

    }

}

SearchController.propTypes = {

    collection    : PropTypes.string.isRequired,
    onQueryChange : PropTypes.func.isRequired,
    onRunQuery    : PropTypes.func.isRequired,
    inputs        : PropTypes.array.isRequired,
    showOneFilter : PropTypes.bool.isRequired,

    titleMain     : PropTypes.string,
    titleFilter   : PropTypes.string,
    dateError     : PropTypes.string,
    filters       : PropTypes.array,
    hideInterval  : PropTypes.bool,

}

export default SearchController;
