import Circle from 'components/markup/loading/Circle';
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle } from "reactstrap";
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';
import _companies from '_functions/companies';
import fetchAllWorkflowSteps from '_functions/workflow_steps/fetchAll';


const CompanyEmails = ({company}) => {

    const [ stepMonitoring, setStepMonitoring ] = useState({});
    const [ stepData, setStepData ] = useState([]);
    const [ loaded, setLoaded ] = useState(false);

    // load company data into the state
    useEffect(() => {

        async function fetchData() {

            const data = await fetchAllWorkflowSteps();
            
            setStepMonitoring(company.step_monitoring || []);
            setStepData(data);
            return setLoaded(true);

        }

        fetchData();
        
    }, [])

    // handling clicking of a check box
    const setChecked = ( user_id) => {

        let arr = [...stepMonitoring];

        if(arr.includes(user_id)) {
            arr = arr.filter(a => a !== user_id);
        } else {
            arr.push(user_id);
        }

        setStepMonitoring(arr);
    }
    
    // on button click send off current state to update the company settings
    const onSave = async () => {
        toggleStandardLoader(true);

        const updated = await _companies.update(company._id, {step_monitoring: stepMonitoring});

        if(updated.success) {
            toggleAlertBS(false, `Your report settings have been saved.`);
        } else {
            toggleAlertBS(true, `An error occurred updating your report settings.`);
        }

        toggleStandardLoader(false)
    }

    if(!loaded) return <Circle />;

    return (
        <>

        <Card>

            <CardHeader>
                <CardTitle className="mb-0">Step Monitoring</CardTitle>
            </CardHeader>

            <CardBody>
                <p className="text-sm mb-0">Step monitoring allows you to select specific steps that will be emailed in a report to monitor all cases that are on that step. We recommend you sparing use the number of steps you add to this list as the email report that will be sent to you is limit to the 500 most recent cases on the steps you have chosen.</p>
            </CardBody>

            <CardFooter className="text-right">
                <Button color="success" onClick={onSave} >Save Report Settings</Button>
            </CardFooter>
        
        </Card>

        {stepData.length ? stepData.map(data => (
            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">
                        Workflow: <b className="font-weight-bold text-dark">{data.workflow.type}</b>
                    </CardTitle>
                </CardHeader>

                <div className="table-responsive">
                    <table className="table">

                        <thead>
                            <tr>
                                <th style={{width: 75}} className="text-center">Active</th>
                                <th>Step Name</th>
                            
                            </tr>
                        </thead>

                        <tbody>
                            {data.steps.map(step => (
                                <tr>
                                    <td className="text-center">
                                        <div className="custom-control custom-checkbox">
                                            <input
                                                className="custom-control-input"
                                                id={`step_${step._id}`}
                                                type="checkbox"
                                                onChange={() => setChecked(step._id)}
                                                checked={stepMonitoring.includes(step._id) ? true : false}
                                            />
                                            <label className="custom-control-label" htmlFor={`step_${step._id}`} />
                                        </div>
                                    </td>
                                    <td>{step.name}</td>
                                </tr>
                            ))}                        
                        </tbody>

                    </table>
                </div>

            </Card>
        )) : (
            <Card>
                <CardBody>
                    <p className="text-sm mb-0">You have not created any workflows or steps.</p>
                </CardBody>
            </Card>
        )}
        </>
        
    )

}

const mapStateToProps = state => {
    return {
        company: state.companies.company,
        users: state.users.users,
    };
};

export default connect(mapStateToProps, '')(CompanyEmails);


