/*
Documentation

this file renders all offices in the system

*/

import NotificationDelete from 'components/functional/notifications/Delete';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Container, CardTitle, Badge} from "reactstrap";

const { SearchBar } = Search;

class OfficesAll extends React.Component {

    state = {}

    columns = [
        {
            dataField: "name",
            text: "Name",
            formatter: (cell, row) => (
                <Link to={`/core/locations/offices/view/${row._id}`} >{row.name}</Link>
            )
        },
        {
            dataField: "deleted",
            text: "Status",
            formatter: (cell, row) => (
                row.deleted ?
                    <Badge className="text-sm" color="danger">Deleted</Badge> :
                    <Badge className="text-sm" color="success">Active</Badge>
            )
        },
        {
            dataField: "address_line_1",
            text: "Address",
            formatter: (cell, row) => row.address_line_1 ? row.address_line_1 : '-'
        },
        {
            dataField: "city",
            text: "City",
            formatter: (cell, row) => row.city ? row.city : '-'
        },
        {
            dataField: "state",
            text: "State",
            formatter: (cell, row) => row.state ? row.state : '-'
        },
        {
            dataField: "postal_code",
            text: "Zip Code",
            formatter: (cell, row) => row.postal_code ? row.postal_code : '-'
        },
        {
            dataField: "_id",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <Link to={`/core/locations/offices/view/${row._id}`}><i className="fas fa-edit text-success mr-2"></i></Link>
                        <i onClick={() => this.setState({officeToDelete: row})} className="fas fa-trash text-danger"></i>
                    </div>
                )
            }
        },
    ]

    pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: false,
        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    render() {

        const offices = this.props.offices;
        const officeLength = this.props.offices.offices.length;
        const officeToDelete = this.state.officeToDelete;

        return (
            <>

            {officeToDelete && (
                <NotificationDelete
                    deletionURL={`/api/v1/core/offices/delete/${officeToDelete._id}`}
                    confirmBtnText="Delete Office"
                    title={`Delete ${officeToDelete.name}`}
                    text="Are you sure you wish to delete this office."
                    onClose={() => this.setState({officeToDelete: null})}
                    onSuccess={() => {} }
                />
            )}

            <Container fluid>

                <Helmet>
                    <title>{`Offices (${officeLength}) `}</title>
                    <meta name="description" content="Offices" />
                </Helmet>

                <DashHeaderOpen
                    title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
                    breadcrumb_1="Offices"
                    breadcrumb_2="All"
                    actionComponent={(
                        <Link  to="/core/locations/offices/view/new" className="btn btn-success"><i className="fas fa-plus mr-2 " /> Add New</Link>
                    )}
                />

                <Card className="card-color card-primary">

                    <CardHeader>
                        <CardTitle className="mb-0">All Offices</CardTitle>
                    </CardHeader>

                    {offices.total ? (

                        <ToolkitProvider
                            data={offices.offices}
                            keyField="_id"
                            columns={this.columns}
                            search
                        >
                            {props => (
                                <>
                                    <div className="dataTables_filter px-4 pb pt-3 pb-2" >
                                        <label>
                                            Search:
                                            <SearchBar
                                                className="form-control-sm"
                                                placeholder="Name, Email, Phone"
                                                {...props.searchProps}
                                            />
                                        </label>
                                    </div>

                                    <div className=" table-responsive table-vertical-align">
                                        <BootstrapTable
                                            pagination={this.pagination}
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            bordered={false}
                                        />
                                    </div>
                                </>
                            )}
                        </ToolkitProvider>

                    ) : (

                        <CardBody>

                            No offices have been created yet.

                            <Link to="/core/locations/offices/view/new">
                                <b className="text-warning"> Click here to create one.</b>
                            </Link>

                        </CardBody>

                    )}
                </Card>

            </Container>

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        offices: state.offices
    };
};

export default connect(mapStateToProps, '')(OfficesAll);

