import DragAndDrop from 'components/functional/uploads/DragAndDrop';
import React from "react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { setViewingUser } from 'store/functions/auth/auth';
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';
import _users from '_functions/users';

const ProfileAvatar = ({user}) => {

    const onUpload = async (file, call) => {

        toggleStandardLoader(true)

        const updated = await _users.update(user._id, {avatar: call.data._id})

        if(updated.success) {
            toggleAlertBS(false, `Your profile has been updated successfully.`)
            setViewingUser()
        }

        toggleStandardLoader(false)

    }

    return (

        <Card>

            <CardHeader>
                <CardTitle className="mb-0">Profile Picture</CardTitle>
            </CardHeader>

            <CardBody>

                <p className="text-sm">Drag in a picture here to change the avatar associated with this account.</p>

                <DragAndDrop
                    zone_id="documents_1"
                    url={`/api/v1/core/documents/create`}
                    onError={(e) => console.log(e)}
                    resizePixels={75}
                    onUpload={onUpload}
                    defaultValue="Profile Picture" 
                    extraData={{bucket: `/users/${user._id}`}}
                />

            </CardBody>

        </Card>
    );
}

export default ProfileAvatar;