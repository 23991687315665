import moment from 'moment';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { CardBody } from "reactstrap";
import renderName from 'utils/renderName';

import keys from 'keys';

const { SearchBar } = Search;

const UserHiredLogsEntryTable = ({data}) => {

    const columns = [
        {
            dataField: "case",
            text: "Case",
            formatter: (cell, row) => (
                row.case ? (
                    <a className="text-capitalize" target="_blank" rel="noopener noreferrer" href={`${keys.APP_URL}/dashboard/cases/view/${row.case._id}`}>
                        {row.case.name}
                    </a>
                ) : 'No Case Found'
            )
        },
        {
            dataField: "contact",
            text: "Contact",
            formatter: (cell, row) => (
                row.contact ? (
                    <a className="text-capitalize" target="_blank" rel="noopener noreferrer" href={`${keys.APP_URL}/dashboard/contacts/view/${row.contact._id}`}>
                        {renderName(row.contact)}
                    </a>
                ) : 'No Contact Found'
            )
        },
        {
            dataField: "has_recurring_on_day",
            text: "Has Payment Plan",
            formatter: (cell, row) => (
                row.has_recurring_on_day ? <i className="fas fa-check text-success " /> : '-'
            )
        },
        {
            dataField: "roles",
            text: "Roles On Case At Hire",
            formatter: (cell, row) => (
                <span className="text-capitalize">

                    {row.case_roles.map((role, i) => {
                        return (
                            <div key={i}>
                                <div><span style={{width: 150, display: 'inline-block'}}>{role.role}</span> {renderName(role.user)}</div>
                            </div>
                        )
                    })}
                </span>
            )

        },
        {
            dataField: "step_moved_by",
            text: "Step Moved By",
            formatter: (cell, row) => row.step_moved_by ? <span className="text-capitalize">{renderName(row.step_moved_by)}</span> : '-'
        },
        {
            dataField: "date",
            text: "Date",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => moment.unix(row.date).format("M/D/YYYY") + ' at ' + moment.unix(row.date).format("h:mm A")
        },
      
    ]

    const pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: false,
        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    return (
        data.length ? (

            <ToolkitProvider
                data={data}
                keyField="_id"
                columns={columns}
                search
            >
                {props => (
                    <>
                        <div className="dataTables_filter px-4 pb pt-3 pb-2" >
                            <label>
                                Search:
                                <SearchBar
                                    className="form-control-sm"
                                    placeholder="Name, Email, Phone"
                                    {...props.searchProps}
                                />
                            </label>
                        </div>

                        <div className=" table-responsive table-vertical-align">
                            <BootstrapTable
                                pagination={pagination}
                                {...props.baseProps}
                                bootstrap4={true}
                                bordered={false}
                            />
                        </div>
                    </>
                )}
            </ToolkitProvider>

        ) : (

            <CardBody>
                <p className="text-sm mb-0 text-warning font-weight-bold">No contacts were hired in this time frame for the current dataset.</p>
            </CardBody>

        )
    )

}

export default UserHiredLogsEntryTable;