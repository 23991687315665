/*
Documentation

returns <options></options> from 12 am to 11:45 pm with corresponding unix times as values in 15 minute intervals

*/

import React from 'react'
import moment from 'moment'

const MinuteOptions15 =  (props) => {

    let i = 0
    let date = props.date
    let times = []

    while (i < 96) {

        let val = date ? date.toString().replace(/900/g, '') : '';

        times.push(moment(val).add((i * 15).toString(), 'minutes').format('h:mm A'))

        i++
        date += 900

    }

    return times.map((t, x) => <option key={x} value={900 * (x)}>{t}</option>)

}

export default MinuteOptions15;