import React, { Component } from 'react';
import { FormGroup, Input } from 'reactstrap';
import PropTypes from 'prop-types';

class ArchkNumber extends Component {

    state = {
        queryType: 'Is Equal To'
    };

    setValueToSend = (valueToSend, queryType) => {

        if(queryType === 'Is Equal To') {
            valueToSend = `${valueToSend}{number}`
        } else if(queryType === 'Is Greater Than') {
            valueToSend = `${valueToSend}{gt}{number}`
        } else if (queryType === 'Is Greater Than Or Equal To') {
            valueToSend = `${valueToSend}{gte}{number}`
        } else if(queryType === 'Is Less Than') {
            valueToSend = `${valueToSend}{lt}{number}`
        } else if (queryType === 'Is Less Than Or Equal To') {
            valueToSend = `${valueToSend}{lte}{number}`
        }

        this.props.onChange(valueToSend)

    }

    onChangeQueryType = (value) => {

        this.setState({queryType: value})
        this.setValueToSend(this.state.value, value)

    }

    onChange = (value) => {


        this.setState({value})
        this.setValueToSend(value, this.state.queryType)
    }

    render() {

        const { label } = this.props;

        return (

            <>
                <FormGroup>

                    {label ? (
                        <label className="form-control-label mb-0">{label}</label>
                    ) :  null}

                    <Input
                        className="mb-2"
                        type="select"
                        value={this.state.queryType}
                        onChange={(e) => this.onChangeQueryType(e.target.value)}
                    >
                        <option value="Is Equal To">Is Equal To</option>
                        <option value="Is Greater Than">Is Greater Than</option>
                        <option value="Is Greater Than Or Equal To">Is Greater Than Or Equal To</option>
                        <option value="Is Less Than">Is Less Than</option>
                        <option value="Is Less Than Or Equal To">Is Less Than Or Equal To</option>
                    </Input>
                    
                    <Input
                        value={this.state.value || ''}
                        type="number"
                        onChange={(e) => this.onChange(e.target.value)}
                    />

                </FormGroup>

            </>

        )

    }

}


ArchkNumber.propTypes = {

    onChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,

}

export default ArchkNumber;
