import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import { architeckCall } from 'database';
import moment from 'moment';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import ReactJson from 'react-json-view';
import { Card, CardBody, CardHeader, CardTitle, Container } from "reactstrap";
import Circle from 'components/markup/loading/Circle'
import NotificationDelete from 'components/functional/notifications/Delete';
import renderName from 'utils/renderName';
import { Link } from 'react-router-dom';

class Logs extends React.Component {

    state = {
        logToDelete: null,
        loaded: false
    }

    columns = [

        {
            dataField: "error",
            text: "Error",

            formatter: (cell, row) => {

                let text = row.text

                try {

                    let parsed = JSON.parse(text)

                    if(typeof parsed === 'string') throw new Error()

                    return (
                        <div style={{overflow: 'auto', whiteSpace: 'pre-line'}}>
                            <h2 className="mb-4 text-danger">ERROR THROWN: </h2>

                            {parsed ? (
                                <ReactJson
                                    name={false}
                                    src={parsed.data ? parsed.data : parsed}
                                />
                            ) : null}
                        </div>
                    )

                } catch(e) {}

                return (
                    <div style={{overflow: 'auto', whiteSpace: 'pre-line'}}>
                        <pre style={{maxWidth: 500, whiteSpace: 'pre-line'}}>{text}</pre>
                    </div>
                )

            }
        },
        {
            dataField: "code",
            text: "Code",
            style: {verticalAlign: 'top'},
            headerStyle: {width: 500},
            formatter: (cell, row) => {

                return (
                    <>
                        <h2 className="mb-4">ADDITIONAL INFORMATION: </h2>

                        <h3  className="mb-0">User</h3>
                        <p className="text-sm mb-2 text-capitalize">
                            {renderName(row.user)}
                        </p>

                        {row.device ? (
                            <div>
                                <h3  className="mb-0">Device</h3>
                                <p className="text-sm mb-0 text-capitalize" style={{maxWidth: 500, whiteSpace: 'break-spaces'}}>
                                    <b style={{width: 50}} className="d-inline-block text-dark mr-2 font-weight-bold">Type:</b> 
                                    {row.device.type}
                                </p>

                                <p className="text-sm mb-0 text-capitalize">
                                    <b style={{width: 50}} className="d-inline-block text-dark mr-2 font-weight-bold">Mobile:</b> 
                                    {row.device.mobile ? 
                                        <i className="fas fa-check text-success " /> : 
                                        <i className="fas fa-times text-danger " />}
                                </p>

                                <p className="text-sm mb-2 text-capitalize">
                                    <b style={{width: 50}} className="d-inline-block text-dark mr-2 font-weight-bold">Screen: </b> 
                                    {row.device.width}px / {row.device.height}px
                                </p>

                            </div>
                        ) : null}

                        <h3  className="mb-0">Date</h3>
                        <p className="text-sm mb-2">
                            {moment.unix(row.created_at).format('MMMM, Do YYYY hh:mm:ss A')}
                        </p>

                        <h3 className="mb-0">Code</h3>
                        <p className="text-sm mb-2">{row.code}</p>

                        <h3 className="mb-0">URL</h3>
                        <p className="text-sm mb-2" style={{whiteSpace: 'pre-line'}}>{row.url}</p>

                        <h3  className="mb-0">File</h3>
                        <p className="text-sm mb-2" style={{maxWidth: 200, whiteSpace: 'pre-line'}}>{row.file}</p>

                        <button onClick={() => this.setState({logToDelete: row})} className="btn btn-danger mt-4">
                            <i  className="fas fa-trash text-white mr-2"></i>
                            Delete Log

                        </button>
                    </>

                )

            }
        },


    ]

    pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: false,

        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    componentDidMount = async () => {

        // architeckCall({
        //     method: 'get',
        //     url: '/api/v1/system/lssogs'
        // })

        const logs = await architeckCall({
            method: 'get',
            url: '/api/v1/system/logs'
        })

        if(logs.success) {

            this.setState({logs: logs.data})

        }

        this.setState({loaded: true})

    }

    render() {

        const { logToDelete, logs, loaded } = this.state




        if(!loaded) return <div className="pt-6"><Circle /></div>

        return (
            <>

            <Container  fluid>

                <Helmet>
                    <title>Logs</title>
                    <meta name="description" content="Logs" />
                </Helmet>

                <DashHeaderOpen
                    title={<span><i className="fas fa-code mr-2 text-success " /> Developers</span>}
                    breadcrumb_1="Router"
                    breadcrumb_2="Server Logs"
                    actionComponent={(
                        <Link className="btn btn-outline-warning" to="/developers/router">
                            <i className="fas fa-arrow-left mr-2" /> Back
                        </Link>
                    )}
                />

                {logToDelete && (
                    <NotificationDelete
                        deletionURL={`/api/v1/logs/delete/${logToDelete._id}`}
                        confirmBtnText="Delete Log"
                        title={<span className="text-capitalize">{`Delete This Log?`}</span>}
                        text="Deleting this log will permanently remove it from the system. Make sure whatever caused the error has been resolved first!"
                        onClose={() => this.setState({logToDelete: null})}
                        onSuccess={(object) => {
                            let logs = [...this.state.logs]
                            logs = logs.filter(log =>log._id !== object.data._id )
                            this.setState({logs})
                        }}
                    />
                )}

                <Card>

                    <CardHeader>
                        <CardTitle className="mb-0">All Developer Logs</CardTitle>
                    </CardHeader>

                    {logs && logs.length ? (

                        <ToolkitProvider
                            data={logs}
                            keyField="_id"
                            columns={this.columns}
                        >
                            {props => (
                                <div className=" table-responsive table-parent-bordered table-vertical-align table-not-fixed">
                                    <BootstrapTable
                                        pagination={this.pagination}
                                        {...props.baseProps}
                                        bootstrap4={true}
                                        bordered={false}
                                    />
                                </div>
                            )}
                        </ToolkitProvider>

                    ) : (

                        <CardBody>
                            There are no active logged errors for this system
                        </CardBody>
                    )}

                </Card>

            </Container>

            </>
        );
    }
}

export default Logs
