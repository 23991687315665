/*
Documentation

This component renders markup to show all employee (user) user_types with the ability to add and remove them.

*/

import PropTypes from 'prop-types';
import React from "react";
import * as user_types from '_settings/user_types'

const UserTypesCheckbox = (props) => {

    return (
        <>

        {props.title ? (
            <>
                {props.hideHR !== true ? <hr className="mt-3 mb-2"/> : null}
                <h5>{props.title}</h5>
            </>
        ) : null}

        <div className="custom-control custom-checkbox">
            <input
                className="custom-control-input"
                type="checkbox"
                id={props.user_type}
                value={props.user.user_types.includes(props.user_type)}
                checked={props.user.user_types.includes(props.user_type)}
                onChange={(e) => props.setUserTypes(e, props.user_type)}
            />
            <label className="custom-control-label" htmlFor={props.user_type}>
                {props.text}
            </label>
        </div>

        </>

    );

}


class UserTypes extends React.Component {

    removeValueFromArray = (user_types, field) => user_types.filter(val => val !== field)

    setUserTypes = (e, field) => {

        const checked = e.target.value

        // const checked = val === 'on' ? true : false;
        let user_types = [...this.props.user.user_types]

        if(checked === 'false') {

            user_types.push(field)

        } else {

            user_types = this.removeValueFromArray(user_types, field);

        }

        this.props.onChange('user_types', user_types)

    }

    render() {

        const user = this.props.user;

        return (
            <>

                <UserTypesCheckbox
                    hideHR={true}
                    title="Call Center Operator"
                    user_type={user_types.call_center_operator}
                    text="Will receive updates when off a call for more than 10 minutes"
                    user={user}
                    setUserTypes={(e, user_type) => this.setUserTypes(e, user_type)}
                />

                <UserTypesCheckbox
                    title="Status Independent"
                    user_type={user_types.status_independent}
                    text="Can manually set their status and mark themselves as AFK to show users they are offline but may receive calls."
                    user={user}
                    setUserTypes={(e, user_type) => this.setUserTypes(e, user_type)}
                />


            </>
        );
    }
}

UserTypes.propTypes = {
    user: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default UserTypes
