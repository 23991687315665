/*
Documentation

renders the footer when viewing an automation
Has an input to change the name of the automation as well as the button to
save the automation

*/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Container } from 'reactstrap';
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import PropTypes from 'prop-types';

class AutoFooter extends Component {

    render() {

        const { automation_tasks, onSave, onNameChange, onDivisionChange, automation, divisions } = this.props

        return (

            <div className="bg-white py-4 z-depth-5 mt-3 auto-footer">
                <Container fluid>
                    <Row>

                        <Col lg={3} className="align-self-center">
                            <p className="mb-0">
                                Automations: {' '}
                                <b>{automation_tasks && automation_tasks.length ? automation_tasks.length : 0 }</b>
                            </p>
                        </Col>

                        <Col lg={3} className="text-center align-self-center">

                            <StandardFormGroup
                                obj={automation}
                                objName='automation'
                                onChange={(o, f, v) => onDivisionChange(v)}
                                type="select"
                                field="division"
                                formGroupClasses="label-block"
                            >
                                <option value="false">SELECT DIVISION</option>
                                {divisions.map(division => (
                                    <option key={division._id} value={division._id}>{division.name}</option>
                                ))}
                            </StandardFormGroup>

                        </Col>
                        <Col lg={3} className="text-center align-self-center">

                            <StandardFormGroup
                                obj={automation}
                                objName='automation'
                                onChange={(o, f, v) => onNameChange(v)}
                                type="text"
                                field="name"
                                placeholder="Automation Name"
                                formGroupClasses="label-block"
                            />

                        </Col>

                        <Col lg={3} className="text-right align-self-center">
                            <button onClick={onSave} className="btn btn-success"><i className="fas fa-save mr-2" /> Save Automation </button>
                        </Col>

                    </Row>
                </Container>
            </div>

        )

    }

}

AutoFooter.propTypes = {
    automation_tasks    : PropTypes.array.isRequired,
    automation          : PropTypes.object.isRequired,
    onSave              : PropTypes.func.isRequired,
    onNameChange        : PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        divisions: state.divisions.divisions,
    };
};

export default connect(mapStateToProps, '')(AutoFooter);
