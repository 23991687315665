import React from 'react';
import { Link } from 'react-router-dom';


const Dropdown = ({toggleOpen, getOpen, getDropdownClass, setOpen, children, route, name, icon }) => (
    <li className="dropdown-container">

        <span onClick={() => toggleOpen(route)} className={getDropdownClass(route)}>
            <i className={icon} />{name} </span>

        <ul className={getOpen(route)} onClick={() => setOpen(route)} >
            {children}
        </ul>

    </li>        
    
)

const Item = ({onPage, toggleSidebar, route, name }) => (
    <li className={onPage(route)}>
        <Link onClick={() => toggleSidebar(false)} to={route}>
            <span className="inner-content">{name}</span>
        </Link>
    </li>
)

const StandAlone = ({onPage, toggleSidebar, route, name, icon}) => (
    <li className={onPage(route)}>
        <Link onClick={() => toggleSidebar(false)} to={route}>
            {icon ? <i className={icon} /> : null}
            <span className="inner-content">{name}</span>
        </Link>
    </li>
)

export {
    Dropdown,
    Item,
    StandAlone,
};