// findUnmovedStepsByAppointments

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardTitle, Col, Container, FormGroup, Input, Row } from 'reactstrap';
import { toggleAlertBS } from 'store/functions/system/system';
import _groupings from '_functions/groupings';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';

class GroupingsView extends Component {

    state = {
        loading: true,
        groupingData: [],
        allData: [],
        groupingName: '',
    };

    onSave = async () => {

        const { groupingName, groupingData } = this.state;
        const { grouping_id } = this.props.match.params;

        const isNew = grouping_id === 'new'

        let action;

        if(isNew) {

            action = await _groupings.create({
                name: groupingName,
                documents: groupingData,
                collection_name: this.props.match.params.type
            })

        } else {

            action = await _groupings.update(grouping_id, {
                name: groupingName,
                documents: groupingData,
                collection_name: this.props.match.params.type
            })

        }

        if(action.success) {
            toggleAlertBS(false, 'Grouping Updated Successfully')
            if(isNew) this.setState({shouldRedirect: '/analytics/groupings/all'})

        } else {
            toggleAlertBS(true, 'Error Updating Grouping')
        }

    }

    addEntry = (entry) => {

        let allData = [...this.state.allData];
        let groupingData = [...this.state.groupingData];

        allData = allData.filter(a => a._id !== entry._id)
        groupingData.push(entry);

        this.setState({allData, groupingData})

    }

    removeEntry = (entry) => {

        let allData = [...this.state.allData];
        let groupingData = [...this.state.groupingData];

        groupingData = groupingData.filter(a => a._id !== entry._id)
        allData.push(entry);

        this.setState({allData, groupingData})

    }

    componentDidMount = async () => {

        const input = document.getElementById('groupings-input-name')
        if(input) input.focus()

        const { type, grouping_id } = this.props.match.params;

        const allData = [];
        const groupingData = [];
        const propData = [...this.props[type]]


        if(grouping_id === 'new') {

            this.setState({allData: propData})

        } else {

            const foundGrouping = await _groupings.findById(grouping_id);

            if(foundGrouping.data) {

                propData.forEach(d => {
                    if(foundGrouping.data.documents.find(x => x === d._id)) {
                        groupingData.push(d)
                    } else {
                        allData.push(d)
                    }
                })

                this.setState({groupingData, allData, groupingName: foundGrouping.data.name})

            }

        }

    }

    render() {

        const type = this.props.match.params.type;
        const { allData, groupingData, groupingName, shouldRedirect } = this.state;

        if(shouldRedirect) return <Redirect to={shouldRedirect} />

        return (

            <Container fluid>

                <DashHeaderOpen
                    title={<span><i className="fas fa-headset mr-2 text-success " />Analytics</span>}
                    breadcrumb_1="Groupings"
                    breadcrumb_2="Create/Update"
                />

                <Card className="card-color card-success">

                    <CardHeader>
                        <Row>
                            <Col xs={6}>
                                <CardTitle className="mb-0">Grouping Name</CardTitle>
                            </Col>
                            <Col xs={6} className="text-right">
                                <button className="btn btn-success" onClick={this.onSave} >Save Grouping</button>
                            </Col>
                        </Row>
                    </CardHeader>

                    <CardBody>
                        <FormGroup>
                            <Input 
                                id="groupings-input-name"
                                placeholder="grouping name"
                                value={groupingName}
                                onChange={e => this.setState({groupingName: e.target.value})}
                            />
                        </FormGroup>
                    </CardBody>

                </Card>

                <Row>
                    <Col lg={6}>
                        <Card>

                            <CardHeader>
                                <CardTitle className="mb-0 float-left text-capitalize">{type.replace(/_/g,' ')} Grouping</CardTitle>
                            </CardHeader>

                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>name</th>
                                            <th className="text-right">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {groupingData.length ? groupingData.map(d => (
                                            <tr key={d._id}>
                                                <td>{d.name}</td>
                                                <td className="text-right">
                                                    <button onClick={() => this.removeEntry(d)} className="btn btn-outline-danger">
                                                        <i className="fas fa-trash " /> Remove
                                                    </button>
                                                </td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td>No Entries In Grouping</td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                        </Card>
                    </Col>
                    
                    <Col lg={6}>
                        <Card>

                            <CardHeader>
                                <CardTitle className="mb-0 float-left text-capitalize">Add To Grouping</CardTitle>
                            </CardHeader>

                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>name</th>
                                            <th className="text-right">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allData.length ? allData.map(d => (
                                            <tr key={d._id}>
                                                <td>{d.name}</td>
                                                <td className="text-right">
                                                    <button onClick={() => this.addEntry(d)} className="btn btn-success">
                                                        <i className="fas fa-plus " /> Add
                                                    </button>
                                                </td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td>No Data To Add</td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                        </Card>
                    </Col>

                </Row>

            </Container>

        )

    }

}


const mapStateToProps = state => {
    return {
        template_appointments: state.template_appointments.template_appointments
    };
};

export default connect(mapStateToProps, '')(GroupingsView);
