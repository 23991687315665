/*
Documentation

this file allows a template text to be created or updated

*/

import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Container, Card, CardBody, CardHeader, CardTitle, CardFooter, Row, Col } from 'reactstrap';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import { Helmet } from 'react-helmet';
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import NotificationDelete from 'components/functional/notifications/Delete';

import Info from './Info'

import { connect } from 'react-redux';

import { toggleStandardLoader } from 'store/functions/system/system';
import { toggleAlertBS } from 'store/functions/system/system'

import validator from 'utils/validator';

import _template_texts from '_functions/template_texts';

const required_fields = [ 'body', 'name', 'division']

class TemplateTextsView extends Component {


    state = {
        showModal: false,
        text: {
            name: 'The Text Template Name',
        },

    };

    toggleModal = () => this.setState({showModal: !this.state.showModal})

    onSave = async () => {

        const validateFields = validator.requiredFields('text', required_fields, this)
        const text = validateFields.state;

        if(text.division === 'false') return this.setState({text: {...text, divisionState: 'invalid'}})

        if(validateFields.success) {

            toggleStandardLoader(true)

            let saved;

            if(!text._id) {
                saved = await _template_texts.create(text)
            } else {
                saved = await _template_texts.update(text._id, text)
            }

            if(saved.success) {
                toggleAlertBS(false, 'Saved Successfully');
                this.setState({shouldRedirect: '/automations/template_texts'});
            }

            toggleStandardLoader(false)

        }

    }

    componentDidMount = () => {

        const text_id = this.props.match.params._id

        if(text_id !== 'new') {

            const foundText = this.props.template_texts.find(e => e._id === text_id);

            if(foundText) {
                this.setState({text: foundText})
            } else {
                this.setState({shouldRedirect: '/automations/template_texts'})
            }

        }

    }

    render() {

        const { text, text_to_delete, showModal} = this.state
        const { divisions } = this.props;
        if(this.state.shouldRedirect) return <Redirect to={this.state.shouldRedirect} />

        return (

            <>

            <Helmet>
                <title>{`Automations`}</title>
                <meta name="description" content="Tags" />
            </Helmet>

            {text_to_delete && (
                <NotificationDelete
                    deletionURL={`/api/v1/automations/template_texts/delete/${text_to_delete._id}`}
                    confirmBtnText="Delete Template"
                    title={<span className="text-capitalize">{`Delete Template: ${text_to_delete.name}`}</span>}
                    text="Deleting this text template will remove it from all automations!"
                    onClose={() => this.setState({text_to_delete: null})}
                    onSuccess={(object) => this.setState({shouldRedirect: '/automations/template_texts'}) }
                />
            )}

            <Container fluid className="template-texts">

                <DashHeaderOpen
                    title={<span><i className="fas fa-magic mr-2 text-success " /> Automations</span>}
                    breadcrumb_1="Template Texts"
                    breadcrumb_2={text && text._id ? text.name : 'Create'}
                    actionComponent={(
                        <Link to="/automations/template_texts" className="btn btn-outline-warning">
                            <i className="fas fa-arrow-left mr-2" /> Back
                        </Link>
                    )}
                />

                <Card>

                    <CardHeader>
                        <CardTitle className="mb-0">
                            <i className="fas fa-envelope mr-2 text-success" /> {text.name}
                        </CardTitle>
                    </CardHeader>

                    <CardHeader>

                        <StandardFormGroup
                            obj={text}
                            objName='text'
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                            type="text"
                            field="name"
                            title="Template Name"
                        />

                        <StandardFormGroup
                            obj={text}
                            objName='text'
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`fState`]: v ? 'valid' : 'invalid'}})}
                            type="select"
                            field="division"
                            title="Template Division"
                        >
                            <option value="false"></option>
                            {divisions.map(division => (
                                <option value={division._id} key={division._id}>{division.name}</option>
                            ))}
                        </StandardFormGroup>

                    </CardHeader>

                    <CardBody>

                        <CardTitle className="mb-">
                            <Row>

                                <Col md={6}>
                                    Text Body
                                </Col>
                                <Col md={6} className="text-right">
                                    <i
                                        onClick={this.toggleModal}
                                        style={{position: 'relative', top: 4}}
                                        className="fas fa-question-circle text-info-original"
                                    />
                                </Col>

                            </Row>
                        </CardTitle>

                        <StandardFormGroup
                            obj={text}
                            objName='text'
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                            style={{minHeight: 300}}
                            type="textarea"
                            field="body"
                        />

                    </CardBody>

                    <CardFooter>

                        <Row>

                            <Col xs={6}>
                                {text._id ? (
                                    <button onClick={() => this.setState({text_to_delete: text})} className="btn btn-danger" style={{width: 110}}>
                                        <i className="fas fa-trash mr-2" /> Delete
                                    </button>
                                ) : null}
                            </Col>

                            <Col xs={6} className="text-right">
                                <button onClick={this.onSave} className="btn btn-success" style={{width: 110}}>
                                    <i  className="fas fa-save mr-2" /> Save
                                </button>
                            </Col>

                        </Row>

                    </CardFooter>

                </Card>

                <Info
                    showModal={showModal}
                    toggleModal={this.toggleModal}
                />

            </Container>

            </>

        )

    }

}

const mapStateToProps = state => {

    return {
        template_texts: state.template_texts.template_texts,
        divisions: state.divisions.divisions,
    };
};

export default connect(mapStateToProps, '')(TemplateTextsView);
