import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, CardTitle, CardFooter, CardBody, Container, FormGroup, Input, Row, Col } from 'reactstrap';
import _analytics from '_functions/analytics';
import Circle from 'components/markup/loading/Circle';
import Line from '../_components/charts/Line';
import UserSearchInput from 'components/system/migrated/UserSearchInput';
import renderName from 'utils/renderName';

class CallCenterCallLogsOutgoing extends Component {


    state = {
        data: null,
        groupings: [],
        loading: true,
        noData: false,
        start: moment().startOf('day').format('YYYY-MM-DD'),
        end: moment().endOf('day').format('YYYY-MM-DD'),
        comparisonStart: null,
        comparisonEnd: null,
        showComparison: false,
        queryType: 'total',
        timeFilter: '9to5',
        leadStatus: null,
        error: null,
        user: {}
    };

    setTimeFilter = (timeFilter) => this.setState({timeFilter: timeFilter !== 'null' ? timeFilter : null});
    setLeadStatus = (leadStatus) => this.setState({leadStatus: leadStatus !== 'null' ? leadStatus : null});

    toggleComparison = () => this.setState({
        showComparison: !this.state.showComparison, 
        comparisonStart: null, 
        comparisonEnd: null
    })

    toggleQueryType = (queryType) => {
        this.setState({ queryType }, () => {
            this.onQuery()
        })
    }

    onQuery = async () => {

        const { start, end, comparisonStart, comparisonEnd, queryType, user, timeFilter } = this.state;
        if(!start || !end) return;

        const startDate = moment(start).startOf('day').format('X');
        const endDate = moment(end).endOf('day').format('X');
        
        const comparisonStartDate = moment(comparisonStart).startOf('day').format('X');
        const comparisonEndDate = moment(comparisonEnd).endOf('day').format('X');

        let query = `?start=${startDate}&end=${endDate}&queryType=${queryType}`;
        
        if(comparisonStart && comparisonEnd) {
            query += `&comparisonStart=${comparisonStartDate}&comparisonEnd=${comparisonEndDate}`;
        }

        if(user._id) {
            query += `&user=${user._id}`;
        }

        if(timeFilter) {
            query += `&timeFilter=${timeFilter}`;
        }

        this.setState({loading: true});

        let data = await _analytics.call_center.callLogsOutgoing(query);

        this.setState({loading: false})

        if(data.data) {
            this.setState({data: data, loading: false, noData: false, error: false})
        } else {
            this.setState({error: data.message, data: null})
        }

    }

   

    componentDidMount = () => {
        this.onQuery()
    }

    render() {

        const { data, noData, loading, showComparison, queryType, start, end, comparisonStart, comparisonEnd, timeFilter, error, user } = this.state

        return (

            <Container fluid>

                <DashHeaderOpen
                    title={<span><i className="fas fa-headset mr-2 text-success " /> Communication</span>}
                    breadcrumb_1="Call Logs"
                    breadcrumb_2="Outgoing"
                />

                <Card className="card-color card-primary">

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">User Call Logs</CardTitle>
                    </CardHeader>

                    <CardHeader>

                        <FormGroup>
                            <label className="form-control-label">Query Type</label>
                            <Input 
                                type="select"
                                value={queryType || 'total'}
                                onChange={(e) => this.toggleQueryType(e.target.value)}
                            >
                                <option value="total">Total User Calls</option>
                                <option value="duration">Average Call Duration</option>
                            </Input>
                        </FormGroup>

                        <Row>
                            <Col md={6}>
                                <FormGroup>

                                    <label className="form-control-label">
                                        {user._id ? (
                                            <span className="text-capitalize">
                                                Selected User: {renderName(user)}{' '}
                                                <i className="fas fa-times ml-2 text-danger cursor-pointer " onClick={() => this.setState({user: {}})} />
                                            </span>
                                        ): 'Select User'}
                                    </label>

                                    <UserSearchInput
                                        hideTitle={true}
                                        value={user ? user : {}}
                                        onSelect={(user) => this.setState({user})}
                                    />

                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <FormGroup>
                                    <label className="form-control-label">Time Filter</label>
                                    <Input 
                                        type="select"
                                        value={timeFilter || 'null'}
                                        onChange={(e) => this.setTimeFilter(e.target.value)}
                                    >
                                        <option value="null">All</option>
                                        <option value="9to5">9 To 5</option>
                                        <option value="9to6">9 To 6</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <label className="form-control-label">Start</label>
                                    <Input 
                                        type="date"
                                        value={start || ''}
                                        onChange={(e) => this.setState({start: e.target.value})}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <label className="form-control-label">End</label>
                                    <Input 
                                        type="date"
                                        value={end || ''}
                                        onChange={(e) => this.setState({end: e.target.value})}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        {showComparison ? (
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <label className="form-control-label">Comparison Start</label>
                                        <Input 
                                            type="date"
                                            value={comparisonStart || ''}
                                            onChange={(e) => this.setState({comparisonStart: e.target.value})}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <label className="form-control-label">Comparison End</label>
                                        <Input 
                                            type="date"
                                            value={comparisonEnd || ''}
                                            onChange={(e) => this.setState({comparisonEnd: e.target.value})}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
    
                        ) : null }

                    </CardHeader>


                    <CardFooter>
                        <Row>
                            <Col xs={6}>
                                <button style={styles.button} className="btn btn-outline-warning" onClick={this.toggleComparison}>Toggle Comparison</button>
                            </Col>
                            <Col xs={6} className="text-right">
                                <button style={styles.button} className="btn btn-success" onClick={this.onQuery}>Get Stats</button>
                            </Col>
                        </Row>
                    </CardFooter>

                </Card>

                {error ? <div className="alert alert-warning">{error}</div> : null}

                <Card className="card-color card-primary">

                    <CardHeader>
                        <CardTitle className="mb-0">
                            {queryType === 'total' ? ( 'Query: All User Calls' ) : ( 'Query: Average User Duration' )}
                        </CardTitle>
                    </CardHeader>

                    <CardBody>
                        <p className="text-sm mb-0">
                            Showing Analytics from {moment(start).startOf('day').format('MM/DD/YYYY h:mm A')} - {moment(end).endOf('day').format('MM/DD/YYYY h:mm A')}
                        </p>
                        {showComparison && comparisonStart && comparisonEnd ? (
                            <p className="text-sm mb-0">
                                Showing Analytics from {moment(comparisonStart).startOf('day').format('MM/DD/YYYY h:mm A')} - {moment(comparisonEnd).endOf('day').format('MM/DD/YYYY h:mm A')}
                            </p>
                        ) : null}
                    </CardBody>

                </Card>

                {noData ? (
                    <p className="text-sm text-center font-weight-bold text-warning">
                        <i className="fas fa-info-circle mr-2 " /> 
                        No metrics were found for the day given above: {moment(noData).format('MM/DD/YYYY')}
                    </p>
                 ) : null}

                {loading ? <Circle /> : !data ? null : (

                    <>
                  
                    <Line
                         title={'User Call Analytics'}
                         data={data.data}
                     />

                    </>

                )}

            </Container>

        )

    }

}

const styles = {
    button: {
        minWidth: 140
    }
}


const mapStateToProps = state => {
    return {
        users: state.users.users
    };
};

export default connect(mapStateToProps, '')(CallCenterCallLogsOutgoing);