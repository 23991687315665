import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, UncontrolledTooltip } from 'reactstrap';

import Analytics from './Analytics'
import Automations from './Automations'
import Payments from './Payments'
import Communication from './Communication'
import Core from './Core'
import SourceView from './SourceView'

import * as privileges from '_functions/users/privileges'
import getInitialRoute from '../../functions/getInitialRoute';

class Sidebar extends Component {


    state = {
        view: 'analytics',
        openList: [],
    };

    setView = (e, view) => {
        this.setState({view, openList: []})

        if(view === 'company' || view === 'developers') {
            document.body.classList.add('analytics-sidebar-small')
        } else {
            document.body.classList.remove('analytics-sidebar-small')
        }
    };

    onPage = (url) => {
        return window.location.href.includes(url) ? 'on-page' : '';
    }

    toggleOpen = (toInclude) => {
        let openList = this.state.openList

        if(openList.includes(toInclude)) {
            openList = openList.filter(x => x !== toInclude)
        } else {
            openList.push(toInclude)
        }

        this.setState({openList})
    }

    setOpen = (route) => this.setState({openList: [route]})

    getOpen = (toInclude) => {
        const href = window.location.href;

        if(href.includes(toInclude)) {
            return 'dropdown-list open'
        }

        if(this.state.openList.includes(toInclude)) {
            return 'dropdown-list open'
        }

        return 'dropdown-list'
    }

    getDropdownClass = (route) => window.location.href.includes(route) ? 'false-a open' : 'false-a'

    canViewSystem = () => {

        return privileges.canModerateStaff() ||
        privileges.canModerateCaseTemplates() ||
        privileges.canModerateAppointmentTemplates() ||
        privileges.canModerateForms() ||
        privileges.canModerateWorkflows() ||
        privileges.canModerateMissingDocs() || privileges.canModerateTemplateDocs() ||
        privileges.canModerateTags() ||
        privileges.canModerateLocations() || privileges.canModerateOffices() ||
        privileges.canModerateStaff() ||
        privileges.canModerateDivisions() ||
        privileges.canModerateArchives() ? true : false

    }

    componentDidMount = () => {
        const href = window.location.href;

        if(href.includes('/developers')) {
            this.setState({view: 'developers'})
            document.body.classList.add('analytics-sidebar-small')
        } else if(href.includes('/core/company')) {
            this.setState({view: 'company'})
            document.body.classList.add('analytics-sidebar-small')
        } else if(href.includes('/core')) {
            this.setState({view: 'core'})
        } else if(href.includes('/payments')) {
            this.setState({view: 'payments'})
        } else if(href.includes('/communication')) {
            this.setState({view: 'communication'})
        } else if(href.includes('/automations')) {
            this.setState({view: 'automations'})
        } else if( privileges.canViewSources()) {
            this.setState({view: 'sources'})
            if(!href.includes('/sources/')) {
                const el = document.getElementById('archk-analytics-sources')
                if(el) {
                    el.click();
                    el.click();
                }
            }
        }


    }

    render() {

        const { showSidebar, toggleSidebar } = this.props
        const { view } = this.state;

        return (

            <>

            {showSidebar ? <div className="blackout" onClick={() => toggleSidebar(false)} /> : null}

            <div id="analytics-sidebar" className={showSidebar ? 'sidebar z-depth-2 show' : 'sidebar z-depth-1'}>

                <Row>
                    <div className="col-auto sidebar-small z-depth-" style={{width: 50}}>

                        <ul>
                            <li>

                                {this.canViewSystem() ? (
                                    <Link 
                                        className={view === 'core' ? 'active' : ''} 
                                        to={getInitialRoute()} 
                                        onClick={(e) => this.setView(e, 'core')}
                                        id="archk-analytics-settings"
                                    >
                                        <i className="fas fa-database" />
                                        <UncontrolledTooltip delay={0} placement="right" target="archk-analytics-settings">System</UncontrolledTooltip>
                                    </Link>
                                ) : null}
                                

                                {privileges.canModerateAnalytics() ? (
                                    <Link 
                                        className={view === 'analytics' ? 'active' : ''} 
                                        to="/analytics/master" 
                                        onClick={(e) => this.setView(e, 'analytics') }
                                        id="archk-analytics-analytics"
                                    >
                                        <i className="fas fa-chart-pie " />
                                        <UncontrolledTooltip delay={0} placement="right" target="archk-analytics-analytics">Analytics</UncontrolledTooltip>
                                    </Link>
                                ) : null}

                                {privileges.canViewAdminCommunication() ? (
                                    <Link 
                                        className={view === 'communication' ? 'active' : ''} 
                                        to="/communication/call_center_admin" 
                                        onClick={(e) => this.setView(e, 'communication')}
                                        id="archk-analytics-communication"
                                    >
                                        <i className="fas fa-headset " />
                                        <UncontrolledTooltip delay={0} placement="right" target="archk-analytics-communication">Communication</UncontrolledTooltip>
                                    </Link>
                                ) : null }

                                {privileges.canModerateAutomations() ? (
                                    <Link 
                                        className={view === 'automations' ? 'active' : ''} 
                                        to="/automations/automations" 
                                        onClick={(e) => this.setView(e, 'automations') }
                                        id="archk-analytics-automations"
                                    >
                                        <i className="fas fa-magic " />
                                        <UncontrolledTooltip delay={0} placement="right" target="archk-analytics-automations">Automations</UncontrolledTooltip>
                                    </Link>
                                ) : null}

                                {/* {privileges.canViewAdminFinances() ? (
                                    <Link 
                                        className={view === 'payments' ? 'active' : ''} 
                                        to="/payments/projections" 
                                        onClick={(e) => this.setView(e, 'payments')}
                                        id="archk-analytics-payments"
                                    >
                                        <i className="fas fa-dollar-sign " />
                                        <UncontrolledTooltip delay={0} placement="right" target="archk-analytics-payments">Payments</UncontrolledTooltip>
                                    </Link>
                                ) : null } */}

                                {privileges.canModerateCompanySettings() ? (
                                    <Link 
                                        className={view === 'company' ? 'active' : ''} 
                                        to="/core/company" 
                                        onClick={(e) => this.setView(e, 'company')}
                                        id="archk-analytics-company"
                                    >
                                        <i className="fas fa-building " />
                                        <UncontrolledTooltip delay={0} placement="right" target="archk-analytics-company">Company</UncontrolledTooltip>
                                    </Link>
                                ) : null }

                                {privileges.developer() ? (
                                    <Link 
                                        className={view === 'developers' ? 'active' : ''} 
                                        to="/developers/router" 
                                        onClick={(e) => this.setView(e, 'developers')}
                                        id="archk-analytics-developers"
                                    >
                                        <i className="fas fa-code " />
                                        <UncontrolledTooltip delay={0} placement="right" target="archk-analytics-developers">Developers</UncontrolledTooltip>
                                    </Link>
                                ) : null }
                              
                                {privileges.canViewSources() ? (
                                    <Link 
                                        className={view === 'sources' ? 'active' : ''} 
                                        to="/sources/router" 
                                        onClick={(e) => this.setView(e, 'sources')}
                                        id="archk-analytics-sources"
                                    >
                                        <i className="fas fa-chart-line " />
                                    </Link>
                                ) : null }

                                {privileges.canModerateAnalytics() ? (
                                    <Link 
                                        className={view === 'high_level' ? 'active' : ''} 
                                        to="/high_level" 
                                        onClick={(e) => this.setView(e, 'high_level') }
                                        id="archk-analytics-high-level"
                                    >
                                        <i className="fas fa-envelope-open " />
                                        <UncontrolledTooltip delay={0} placement="right" target="archk-analytics-analytics">High Level</UncontrolledTooltip>
                                    </Link>
                                ) : null}


                            </li>
                        </ul>

                    </div>

                    <Col className="sidebar-large">
                    
                        {view === 'analytics' && (
                            <Analytics     
                                toggleOpen={this.toggleOpen} 
                                getDropdownClass={this.getDropdownClass}
                                getOpen={this.getOpen}  
                                setOpen={this.setOpen}
                                toggleSidebar={toggleSidebar} 
                                onPage={this.onPage} 
                            />
                        )}
                        {view === 'automations' && (
                            <Automations   
                                toggleOpen={this.toggleOpen} 
                                getDropdownClass={this.getDropdownClass}
                                getOpen={this.getOpen}  
                                setOpen={this.setOpen}
                                toggleSidebar={toggleSidebar} 
                                onPage={this.onPage} 
                            />
                        )}
                        {view === 'payments' && (
                            <Payments      
                                toggleOpen={this.toggleOpen} 
                                getDropdownClass={this.getDropdownClass}
                                getOpen={this.getOpen}  
                                setOpen={this.setOpen}
                                toggleSidebar={toggleSidebar} 
                                onPage={this.onPage} 
                            />
                        )}
                        {view === 'communication' && (
                            <Communication 
                                toggleOpen={this.toggleOpen} 
                                getDropdownClass={this.getDropdownClass}
                                getOpen={this.getOpen}  
                                setOpen={this.setOpen}
                                toggleSidebar={toggleSidebar} 
                                onPage={this.onPage} 
                            />
                        )}
                        {view === 'core' && (
                            <Core          
                                toggleOpen={this.toggleOpen} 
                                getDropdownClass={this.getDropdownClass}
                                getOpen={this.getOpen}  
                                setOpen={this.setOpen}
                                toggleSidebar={toggleSidebar} 
                                onPage={this.onPage} 
                            />
                        )}
                        {view === 'sources' && (
                            <SourceView          
                                toggleOpen={this.toggleOpen} 
                                getDropdownClass={this.getDropdownClass}
                                getOpen={this.getOpen}  
                                setOpen={this.setOpen}
                                toggleSidebar={toggleSidebar} 
                                onPage={this.onPage} 
                            />
                        )}

                   
                    </Col>
                </Row>

            </div>

            </>

        )

    }

}

export default Sidebar;
