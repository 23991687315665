/*
Documentation

This file renders the modal where a step can be inserted as a can_move_to_step or a
can_jump_to_step in the workflow

*/

import PropTypes from 'prop-types';
import ReactSelect from 'components/functional/inputs/ReactSelect';
import React, { Component } from 'react';
import { Modal, FormGroup, Input } from "reactstrap";

class FlowTreeInsertStep extends Component {

    state = {
        selectOptions: [],
        type: 'jump',
    }

    onSelect = () => {

        const values = this.state.selectedValues;

        if(!values) return;

        this.props.onSelect(values, this.state.type)
        this.props.toggle()

    }

    componentWillReceiveProps = (nextProps) => {
        if(!nextProps.showModal) this.setState({selectedValues: null, type: 'jump'})
    }

    componentDidUpdate = (prevProps) => {

        if(!prevProps.showModal && this.props.showModal) {

            setTimeout(() => {

                const input = document.querySelector('.react-select input')
                if(input) input.focus()

            }, 500)

        }

    }

    render() {

        const { type } = this.state
        const { selectOptions } = this.props

        return (

            <>

            <Modal
                className="modal-dialog-centered"
                isOpen={this.props.showModal}
                toggle={this.props.toggle}
                size="lg"
            >
                <div className="modal-header">
                    <h5 className="modal-title">Insert Step</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={this.props.toggle}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body" style={{minHeight: 465}}>

                    <FormGroup>
                        <label className="form-control-label">Is this step moving or jumping?</label>
                        <Input
                            type="select"
                            onChange={(e) => this.setState({type: e.target.value})}
                            value={type}
                        >
                            <option value="jump">Jumping</option>
                            <option value="move">Moving</option>
                        </Input>
                    </FormGroup>

                   <FormGroup>
                        <label className="form-control-label">Step to move / jump to?</label>
                        <ReactSelect
                            onChange={(values) => this.setState({selectedValues: values}) }
                            options={selectOptions}
                            menuIsOpen={true}
                        />
                   </FormGroup>

                </div>

                <div className="modal-footer text-right">
                    <button id="submit-insert-step" onClick={this.onSelect} className="btn btn-success">Insert Step</button>
                </div>

            </Modal>

            </>

        )

    }

}

FlowTreeInsertStep.propTypes = {
    showModal       : PropTypes.bool.isRequired,
    toggle          : PropTypes.func.isRequired,
    onSelect        : PropTypes.func.isRequired,
    allSteps        : PropTypes.array,
    selectOptions   : PropTypes.array,
}

export default FlowTreeInsertStep
