/*
Documentation

documents how user_types work

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';
import { Container } from "reactstrap";
import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';
import Circle from 'components/markup/loading/Circle';

import _template_word_docs from '_functions/template_word_docs'

import Upload from './Upload';
import Fields from './Fields';

const redirectURL = '/core/documents/word'

class TemplateWordDocs extends React.Component {

    state = {

        documentData: null,
        shouldFireUploads: false,
        name: '',
        fields: [],
        loaded: false
    }

    copyFields = () => [...this.state.fields];

    onFileAdded  = (files) => {

        const file = files[0];

        if(file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            return toggleAlertBS(true, `You may only upload word documents here.`)
        }

        this.setState({documentData: file, name: file.name.split('.docx')[0]})

    }

    onFieldRemoved = (index) => {

        const fields = this.copyFields();
        fields.splice(index, 1);

        this.setState({fields})

    }

    onFieldChange = (i, field) => {

        const fields = this.copyFields();
        fields[i] = {...field, modified: Math.floor(new Date() / 1)};
        
        this.setState({fields}, this.focusFirstInput);

    }

    onNameChange = (name) => this.setState({name});

    onSave = async () => {

        const fields = this.copyFields();
        const { _id, name } = this.state;

        if(!fields.length) return toggleAlertBS(true, `There must be at least one custom field for this document.`)

        toggleStandardLoader(true);

        if(_id) {
            
            const updated = await _template_word_docs.update(_id, { name, fields });

            toggleStandardLoader(false);

            if(updated.success) {
                toggleAlertBS(false, `Template Word Document Updated Successfully`);
                this.setState({shouldRedirect: redirectURL})
            } else {
                toggleAlertBS(true, 'An Error Occurred Saving This Document')
            }
    
           
        } else {

            this.setState({shouldFireUploads: true}, () => {
                this.setState({shouldFireUploads: false})
            })
        
        }
       
    }

    onUpload = async (response, apiResult) => {

        const created = await _template_word_docs.create({
            name: this.state.name,
            fields: this.state.fields,
            document: apiResult.data._id,
        })

        toggleStandardLoader(false);

        if(created.success) {
            toggleAlertBS(false, `Template Word Document Created Successfully`);
            this.setState({shouldRedirect: redirectURL})
        } else {
            toggleAlertBS(true, 'An Error Occurred Saving This Document')
        }

    }

    focusFirstInput = () => {

        const input = document.getElementById('archk-field-first-input-button');
        if(input) input.focus()

    }

    componentWillUnmount = () => {
        window.removeEventListener('focus', this.focusFirstInput)
    }

    componentDidMount = async () => {

        window.addEventListener('focus', this.focusFirstInput)

        const _id = this.props.match.params._id;

        if(_id === 'new') {
            
            return this.setState({
                loaded: true,
                fields: []
            })

        }

        const foundDoc = await _template_word_docs.findById(_id, true);
        if(!foundDoc.data) return this.setState({shouldRedirect: redirectURL});

        foundDoc.data.fields.forEach((field, i) => {
            field.modified = i
        })

        this.setState({
            _id: foundDoc.data._id,
            name: foundDoc.data.name,
            fields: foundDoc.data.fields,
            documentData: {},
            loaded: true
        })


    }

    render() {

        const { documentData, shouldFireUploads, fields, name, shouldRedirect, loaded, _id } = this.state;

        if(shouldRedirect) return <Redirect to={this.state.shouldRedirect} />
        if(!loaded) return <div className="py-6"><Circle /></div>

        return (
            <>

            <Helmet>
                <title>{`Template Word Docs`}</title>
                <meta name="description" content="Template Word Docs" />
            </Helmet>

            <Container fluid>

                <DashHeaderOpen
                    title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
                    breadcrumb_1="Template Word Docs"
                    breadcrumb_2={_id ? 'Update' : "Create"}
                    actionComponent={(
                        <Link to="/core/documents/word" className="btn btn-outline-warning">
                            <i className="fas fa-arrow-left " /> Back
                        </Link>
                    )}
                />

                <Upload 
                    onFileAdded={this.onFileAdded}
                    onUpload={this.onUpload}
                    shouldFireUploads={shouldFireUploads}
                    documentData={documentData}
                />

                <Fields 
                    documentData={documentData}
                    fields={fields}
                    onFieldChange={this.onFieldChange}
                    onFieldRemoved={this.onFieldRemoved}
                    onSave={this.onSave}
                    name={name}
                    onNameChange={this.onNameChange}
                />

            </Container>

            </>
        );
    }
}

export default TemplateWordDocs
