import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { Badge } from 'reactstrap';

import moment from 'moment';
import renderName from 'utils/renderName';

import _analytics from '_functions/analytics'

import A from 'components/markup/links/A'
import QueryCreator from './QueryCreator';

import { secondsToTime } from 'utils/time'

import keys from 'keys';

const QueryFinishedAppointments = ({offices, users, divisions, groupings}) => {

    useEffect(() => {
        document.body.classList.add('analytics-sidebar-small')
        return () => {
            document.body.classList.remove('analytics-sidebar-small')
        }

    }, [])

    return (
        <QueryCreator 
            title="Finished Appointments"
            searchPlaceholder="Contact ID"
            runQuery={async (query) => new Promise (async resolve => {
                if(!query.hasRun) {

                    const date = moment().startOf('day');
                    query.filter.date = {
                        $gte: parseInt(date.format('X')),
                        $lt: parseInt(date.format('X')) + 86400,
                    }
                }
                if(query.sortValue) query.sortValue = { delinquency:  query.sortValue}
                const result = await _analytics.query.finishedAppointments(query)
                return resolve(result.data)
            })}
            sortOptions={[
                { name: 'Greatest Delinquency', value: 'Greatest Delinquency' },
                { name: 'Least Delinquency', value: 'Least Delinquency' }
            ]}
            inputs={[
                { type: 'ArchkDates', field: 'date', label: '', defaultValue: new Date() },


                {
                    type: 'ArchkSelectSimple', field: 'division', label: 'Division',
                    data: divisions.map(i => { return {value: i._id, text: i.name} })
                },
                {
                    type: 'ArchkSelectSimple', field: 'office', label: 'Office',
                    data: offices.map(o => { return {value: o._id, text: o.name} })
                },
                {
                    type: 'ArchkInArray', field: 'assigned_to', label: 'Assigned To', query: 'in',
                    data: users.map(t => { return {value: t._id, text: renderName(t)} })
                },
                {
                    type: 'ArchkInArray', field: 'finished_by', label: 'Last Update By', query: 'in',
                    data: users.map(t => { return {value: t._id, text: renderName(t)} })
                },
                {
                    type: 'ArchkGroupings', field: 'template_appointment', label: 'Grouping', query: 'in',
                    data: groupings.map(t => { return {_id: t._id, value: t.documents, text: t.name} })
                },
                {
                    type: 'ArchkSelectSimple', field: 'type', label: 'Type',
                    data: [
                        {value: 'attorney', text: 'attorney'},
                        {value: 'client liaison', text: 'client liaison'},
                        {value: 'paralegal', text: 'paralegal'},
                    ]
                },
                { type: 'ArchkNull', field: 'confirmed', label: 'Confirmed', },
               
            ]}
            columns={[
                {
                    dataField: "date",
                    text: "Date",
                    formatter: (cell, row) => (
                        <div>
                            <div>{moment.unix(row.date).format("M/D/YYYY")}</div>
                            <div>{moment.unix(row.date).format("h:mm A")}</div>
                        </div>
                    )
                },
                {
                    dataField: "diff",
                    text: "Minutes Off",
                    formatter: (cell, row) => (
                        <div>
                            {secondsToTime(row.diff)}
                        </div>
                        
                    )
                },
                {
                    dataField: "finished_on",
                    text: "Finished",
                    formatter: (cell, row) => (
                        <div>
                            <div>{moment.unix(row.started_on ? row.started_on : row.finished_on).format("M/D/YYYY") + ' at ' + moment.unix(row.started_on ? row.started_on : row.finished_on).format("h:mm A")}</div>
                            <div className="text-capitalize">{row.finished_by ? renderName(row.finished_by) : '-'}</div>
                        </div>
                        
                    )
                },
                {
                    dataField: "name",
                    text: "Name",
                    formatter: (cell, row) => (
                        <div>
                            <div>{row.name}</div>
                            <div className="text-capitalize">{row.type}</div>
                        </div>
                    ),
                },
                {
                    dataField: "contact",
                    text: "Contact",
                    formatter: (cell, row) => (
                        row.contact ? <A href={`${keys.APP_URL}/dashboard/contacts/view/${row.contact._id}`} className="text-capitalize">{renderName(row.contact)}</A> : '-'
                    ),
                },
                {
                    dataField: "assigned_to",
                    text: "Assigned To",
                    formatter: (cell, row) => {
                        if(row.assigned_to && row.assigned_to.length) {

                            return row.assigned_to.map((u, i)=> (
                                <span className="text-capitalize" key={u._id} style={{display: 'block', width: 160,}}>
                
                                    <i className="fas fa-user mr-2 text-info-original" />
                                    {renderName(u)}
                                </span>
                            ))
                
                        }
                        return '-'

                    }
                },
                {
                    dataField: "confirmed",
                    text: "Confirmed",
                    formatter: (cell, row) => (

                        row.confirmed ?
                        <Badge style={{width: 60}} color="success" className="text-sm"><i className="fas fa-check mr-2" /> Yes</Badge> :
                        <Badge style={{width: 60}} color="warning" className="text-sm"><i className="fas fa-times mr-2" /> No</Badge>

                    )
                },
               

            ]}
        
        />
    )

}



const mapStateToProps = state => {
    return {
        offices: state.offices.offices,
        users: state.users.users,
        divisions: state.divisions.divisions,
        groupings: state.groupings.groupings.filter(g => g.collection_name === 'template_appointments')
    };
};

export default connect(mapStateToProps, '')(QueryFinishedAppointments);