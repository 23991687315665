/*
Documentation

This file controls the text inputs for creating an appointment template
as well as the offices that go along with them

*/

import PropTypes from 'prop-types'
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import React from "react";
import { Card, CardBody, CardHeader, CardTitle, UncontrolledTooltip } from "reactstrap";
import * as privileges from '_settings/privileges'
import { connect } from 'react-redux';
class TemplatesCrudSettings extends React.Component {

    render() {

        const { template_appointment, onInputChange, divisions } = this.props

        return (

            <Card className="label-block" style={{boxShadow: 'none'}}>

                <CardHeader className="bg-secondary">
                    <CardTitle className="mb-0 float-left">Template Settings</CardTitle>
                </CardHeader>

                <CardBody>

                    <StandardFormGroup
                        obj={template_appointment}
                        objName="template_appointment"
                        onChange={(o, f, v) => onInputChange(o, f, v)}
                        field="name"
                        title={(
                            <div>
                                Template Name *
                                <i id="template_appointment_name" className="float-right fas fa-info-circle ml-3 text-info-original" />
                                <UncontrolledTooltip delay={0} placement="top" target="template_appointment_name">
                                    This is the name of the appointment that gets shown to staff members when selecting the appointment.
                                </UncontrolledTooltip>
                            </div>
                        )}

                    />

                    <StandardFormGroup
                        obj={template_appointment}
                        objName='template_appointment'
                        onChange={(o, f, v) => onInputChange(o, f, v)}
                        type="select"
                        field="division"
                        title="Template Division"
                    >
                        <option value="false"></option>
                        {divisions.map(division => (
                            <option value={division._id} key={division._id}>{division.name}</option>
                        ))}
                    </StandardFormGroup>

                    <StandardFormGroup
                        obj={template_appointment}
                        objName="template_appointment"
                        onChange={(o, f, v) => onInputChange(o, f, v)}
                        type="select"
                        field="type"
                        title={(
                            <div>
                                Appointment Type *
                                <i id="template_appointment_type" className="float-right fas fa-info-circle ml-3 text-info-original" />
                                <UncontrolledTooltip delay={0} placement="top" target="template_appointment_type">
                                    The grouping this template falls under when staff select an appointment.
                                </UncontrolledTooltip>
                            </div>
                        )}
                    >
                        <option></option>
                        <option value="attorney">Attorney</option>
                        <option value="client liaison">Client Liaison</option>s
                        <option value="paralegal">Paralegal</option>s
                    </StandardFormGroup>

                    <hr />

                    <StandardFormGroup
                        obj={template_appointment}
                        objName="template_appointment"
                        onChange={(o, f, v) => onInputChange(o, f, v)}
                        type="select"
                        field="requires_privilege"
                        title={(
                            <div>
                                Requires Permission *
                                <i id="template_appointment_requires_privilege" className="float-right fas fa-info-circle ml-3 text-info-original" />
                                <UncontrolledTooltip delay={0} placement="top" target="template_appointment_requires_privilege">
                                    If set, only staff with the designated permissions on their profile can get these appointment in queue.
                                </UncontrolledTooltip>
                            </div>
                        )}
                    >
                        <option value="no">No</option>
                        <option value={privileges.calls_new_contacts}>Can take calls by NEW contacts</option>
                        <option value={privileges.calls_contacts}>Can take calls by contacts</option>
                        <option value={privileges.calls_business}>Can take calls categorized as "business"</option>
                        <option value={privileges.calls_appointments}>Can take calls by appointment</option>
                    </StandardFormGroup>

                    <hr />
                    
                    <StandardFormGroup
                        obj={template_appointment}
                        objName="template_appointment"
                        onChange={(o, f, v) => onInputChange(o, f, v)}
                        type="select"
                        field="show"
                        title={(
                            <div>
                                Assign Type *
                                <i id="template_appointment_show" className="float-right fas fa-info-circle ml-3 text-info-original" />
                                <UncontrolledTooltip delay={0} placement="top" target="template_appointment_show">
                                    How is the assignment of this appointment handled?
                                </UncontrolledTooltip>
                            </div>
                        )}
                    >
                        <option value="call center">Call Center (No Assigned User)</option>
                        <option value="user">Specific User(s)</option>
                        <option value="company">Company (Shows Up On Everyone's Calendar)</option>
                    </StandardFormGroup>

                    <hr />

                    <StandardFormGroup
                        obj={template_appointment}
                        objName="template_appointment"
                        onChange={(o, f, v) => onInputChange(o, f, parseInt(v))}
                        type="select"
                        field="expected_time"
                        formatter={e => e ? e.toString() : e}
                        title={(
                            <div>
                                Expected Time
                                <i id="template_appointment_expected_time" className="float-right fas fa-info-circle ml-3 text-info-original" />
                                <UncontrolledTooltip delay={0} placement="top" target="template_appointment_expected_time">
                                    If set this will determine the default appointment length when creating an appointment.
                                </UncontrolledTooltip>
                            </div>
                        )}
                    >
                        <option value="0">N/A</option>
                        <option value="15">15 Minutes</option>
                        <option value="30">30 Minutes</option>
                        <option value="45">45 Minutes</option>
                        <option value="60">1 Hour</option>
                        <option value="90">1.5 Hours</option>
                        <option value="120">2 Hours</option>
                        <option value="180">3 Hours</option>
                        <option value="240">4 Hours</option>
                        <option value="300">5 Hours</option>
                        <option value="360">6 Hours</option>
                        <option value="420">7 Hours</option>
                        <option value="480">8 Hours</option>
                        <option value="1440">24 Hours</option>
                    </StandardFormGroup>

                    <StandardFormGroup
                        disabled={!template_appointment.type}
                        obj={template_appointment}
                        objName="template_appointment"
                        onChange={(o, f, v) => onInputChange(o, f, v)}
                        type="select"
                        field="in_person"
                        title="Is this appointment in person or does not require a phone call?"
                    >
                        <option value={false}>No</option>
                        <option value={true}>Yes</option>
                    </StandardFormGroup>

                    <div className="custom-control custom-checkbox">
                        <input
                            className="custom-control-input"
                            id="officeRequiredCheckbox"
                            type="checkbox"
                            onChange={() => onInputChange('', 'office_required', template_appointment.office_required === 'yes' ? 'no' : 'yes')}
                            checked={template_appointment.office_required === 'yes' ? true : false}
                        />
                        <label className="custom-control-label" htmlFor="officeRequiredCheckbox">
                            Is an office required to create this appointment?
                        </label>
                    </div>

                    <div className="custom-control custom-checkbox">
                        <input
                            className="custom-control-input"
                            id="locationCourtRequiredCheckbox"
                            type="checkbox"
                            onChange={() => onInputChange('', 'location_court_required', template_appointment.location_court_required === 'yes' ? 'no' : 'yes')}
                            checked={template_appointment.location_court_required === 'yes' ? true : false}
                        />
                        <label className="custom-control-label" htmlFor="locationCourtRequiredCheckbox">
                            Is a court required to create this appointment?
                        </label>
                    </div>



                </CardBody>
            </Card>

        );
    }
}

TemplatesCrudSettings.propTypes = {
    template_appointment    : PropTypes.object.isRequired,
    onInputChange           : PropTypes.func.isRequired
}



const mapStateToProps = state => {
    return {
        divisions: state.divisions.divisions
    };
};

export default connect(mapStateToProps, '')(TemplatesCrudSettings);