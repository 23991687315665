import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';

import { Card, CardHeader, CardTitle, Row, Col, FormGroup, Input, Badge } from 'reactstrap';

import renderName from 'utils/renderName';

import reactHTMLParser from 'react-html-parser'

import Circle from 'components/markup/loading/Circle';

import _admin_alerts from '_functions/admin_alerts'
import moment from 'moment';

import AvatarImage from 'components/functional/images/AvatarImage';

const NewIcon = ({isNew}) => {

    const [show, setShow] = useState(isNew)

    useEffect(() => {
        if(isNew) {
            setTimeout(() => {
                setShow(false)
            }, 30000)
        }
    }, [isNew])

    return (
        show ? <Badge color="success">NEW</Badge> : null
    )
}

const OverviewOverview = () => {

      const listInnerRef = useRef();

    const [filter, setFilter] = useState(null);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [syncData, setSyncData] = useState([]);

    const runSyncInterval = useCallback(async () => {

        let _syncData = [];
        let _syncInterval = Math.floor(new Date() / 1000);

        const getNewAlerts = async () => {

            const alerts = await _admin_alerts.search({$gt: _syncData[0] ? _syncData[0].created_at : _syncInterval});
            if(alerts.data) {
                alerts.data.forEach(alert => alert.isNew = true);
                _syncData = alerts.data.concat(_syncData);
                setSyncData(_syncData);
            }
    
            setTimeout(() => {
                if(window.location.href.includes('/communication/overview')) getNewAlerts();
            }, 5000)
        }
        
        setTimeout(() => {
            if(window.location.href.includes('/communication/overview')) getNewAlerts();
        }, 5000)

    }, [])

    const fetchEvents = useCallback(async () => {
        if(data.length) {
            setLoading(true)
            const alerts = await _admin_alerts.search({$lt: data[data.length - 1].created_at});
            if(alerts.data) {
                setData(data.concat(alerts.data))
            }
            setLoading(false)
        }
       
    }, [data])

    const fetchInitialEvents = useCallback(async () => {
        const alerts = await _admin_alerts.search();
        if(alerts.data) setData(alerts.data);
        setLoading(false)
    }, [])

    const renderNotification = useCallback((alert, i, data) => {

        if(filter && filter !== 'Sort') {
            if(alert.type !== filter) return <></>;
        }

        const className = `archk-admin-alerts-feed-wrapper-${alert.type.replace(/ /g, '-')}`
        let dayDivider = <></>;


        if(data[i - 1]) {
            const lastDay = moment.unix(data[i - 1].created_at).format('MMM Do');
            const currentDay = moment.unix(alert.created_at).format('MMM Do');

            if(lastDay !== currentDay) {
                dayDivider = <div className="text-center font-weight-bold text-dark my-3"><Badge color="success">Start Of {lastDay}</Badge></div>
            }
        }

        return (
            <>
            <div key={alert._id} className={`archk-admin-alerts-feed-wrapper bg-secondary rounded mb-3 ${className}`}>
                <div className="archk-admin-alerts-feed-title px-3 py-2 pb-1 border-bottom">
                    <small className="text-dark">{alert.type}</small>
                    <small className="archk-admin-alerts-feed-date"><NewIcon isNew={alert.isNew} /></small>
                    <div className="mt--3"><small>{moment.unix(alert.created_at).format('MMM Do, h:mm A')}</small></div>
                </div>
                {alert.user ? (
                    <div className="archk-admin-alerts-feed-user text-capitalize px-3 py-2 mt--2">
                        <AvatarImage style={{width: 25, height: 25}} src={alert.user.avatar} className="mr-3 z-depth-1" />
                        <small>{renderName(alert.user)}</small>
                    </div>
                ) : null}
                <div className="archk-admin-alerts-feed-text px-3 py-2 mt--2 pb-3"><small>{reactHTMLParser(alert.text)}</small></div>
            </div>
            {dayDivider}
            </>
        )
    }, [filter])

    const onScroll = () => {

        if (listInnerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

            console.log(scrollTop + clientHeight)
            console.log(scrollHeight)
            if (scrollTop + clientHeight === scrollHeight) {
                fetchEvents();
            }
        }
  };

    useEffect(() => {
        fetchInitialEvents();
        runSyncInterval()
    }, [fetchInitialEvents])

    return (
        <Card style={{height: 'calc(100vh - 125px)',}}  >
    
            <CardHeader>
                <Row>
                    <Col lg={6} className="align-self-center">
                        <CardTitle className="mb-0">Admin Alerts</CardTitle>
                    </Col>
                    <Col lg={6} className="align-self-center text-right">
                        <FormGroup className="mb-0">
                            <Input 
                                type="select"
                                value={filter ? filter : 'Sort'}
                                onChange={(e) => setFilter(e.target.value === 'Sort' ? null : e.target.value)}
                            >
                                <option value="Sort">Select Sorting</option>
                                <option value="system">System</option>
                                <option value="sales">Sales</option>
                                <option value="call center">Call Center</option>
                                <option value="billing">Billing</option>

                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
            </CardHeader>
    
            <div ref={listInnerRef} style={{overflow: 'auto', maxHeigh: '100%'}} className="p-3 px-4 archk-admin-alerts-feed" onScroll={onScroll}>
                {syncData.map((alert, i) => renderNotification(alert, i, data))}
                {data.map((alert, i) => renderNotification(alert, i, data))}
                {loading ? <Circle /> : null}
            </div>
                    
        </Card>
    )
}


const mapStateToProps = state => {
    return {
        users     : state.users.users,
    };
};

export default connect(mapStateToProps, '')(OverviewOverview);
