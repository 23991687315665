import keys from 'keys';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const MobileHeaders = ({toggleSidebar}) => (
    <div  className="mobile-header bg-primary py-3">
        <Container fluid>

            <Row>
                <Col xs={8} className="align-self-center">
                    <img src={keys.LOGO} className="w-100" alt="Biz Head" />
                </Col>
                <Col xs={4} className="align-self-center text-right">
                    <i onClick={toggleSidebar} className="fas fa-bars display-4 text-white" />
                </Col>
            </Row>

        </Container>
    </div>
)

export default MobileHeaders;
