/*
Documentation

this file lets a user view an email template and update it


contact fields
first_name
middle_name
last_name
email
phone
_id

//static fields
date Jan 1st, 2020

case fields
case._id
case.name
case.case_number
case.chapter

custom data
cd.[custom field name]

custom data pulls first from the case, then from the contact
duplicate fields will be pulled from the contact


*/

import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Container, Card, CardBody, CardHeader, CardTitle, CardFooter, Row, Col } from 'reactstrap';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import { Helmet } from 'react-helmet';
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import ReactQuill from "react-quill";
import NotificationDelete from 'components/functional/notifications/Delete';
import DragAndDrop from 'components/functional/uploads/DragAndDrop';

import Info from './Info'

import { connect } from 'react-redux';

import { toggleStandardLoader } from 'store/functions/system/system';
import { toggleAlertBS } from 'store/functions/system/system'

import validator from 'utils/validator';

import _template_emails from '_functions/template_emails';

const required_fields = [ 'body', 'subject', 'name', 'division' ]

class TemplateEmailsView extends Component {

    state = {
        showModal: false,
        reactQuillText: "<p>Hello {first_name} {last_name}, this email is in regards to...</p>",
        email: {
            name: 'The Email Template Name',
            attachments: []
        },

    };

    toggleModal = () => this.setState({showModal: !this.state.showModal})

    onUpload = (file, call) => {

        const { friendly_name, _id } = call.data
        let attachments = [...this.state.email.attachments]

        attachments.push({friendly_name, document: _id})

        this.setState({email: { ...Object.assign({}, this.state.email), attachments } } )

    }

    removeDocument = (attachment) => {

        let attachments = [...this.state.email.attachments]
        attachments = attachments.filter(a => a.friendly_name !== attachment.friendly_name)

        this.setState({email: { ...Object.assign({}, this.state.email), attachments } } )

    }

    handleReactQuillChange = value => {
        this.setState({
            reactQuillText: value,
            email: { ...this.state.email, body: value }
        });
    };

    onSave = async () => {

        const validateFields = validator.requiredFields('email', required_fields, this)
        const email = validateFields.state;

        if(email.division === 'false') return this.setState({email: {...email, divisionState: 'invalid'}})

        if(validateFields.success) {

            toggleStandardLoader(true)

            let saved;

            if(!email._id) {
                saved = await _template_emails.create(email)
            } else {
                saved = await _template_emails.update(email._id, email)
            }

            if(saved.success) {
                toggleAlertBS(false, 'Saved Successfully');
                this.setState({shouldRedirect: '/automations/template_emails'})
            }

            toggleStandardLoader(false)

        }

    }

    componentDidMount = () => {

        const email_id = this.props.match.params._id

        if(email_id !== 'new') {

            const foundEmail = this.props.template_emails.find(e => e._id === email_id);

            if(foundEmail) {
                this.setState({email: foundEmail, reactQuillText: foundEmail.body})
            } else {
                this.setState({shouldRedirect: '/automations/template_emails'})
            }

        }

    }

    render() {

        const { email, email_to_delete, showModal } = this.state
        const { divisions } = this.props;

        if(this.state.shouldRedirect) return <Redirect to={this.state.shouldRedirect} />

        return (

            <>

            <Helmet>
                <title>{`Automations`}</title>
                <meta name="description" content="Tags" />
            </Helmet>

            {email_to_delete && (
                <NotificationDelete
                    deletionURL={`/api/v1/automations/template_emails/delete/${email_to_delete._id}`}
                    confirmBtnText="Delete Template"
                    title={<span className="text-capitalize">{`Delete Template: ${email_to_delete.name}`}</span>}
                    text="Deleting this email template will remove it from all automations!"
                    onClose={() => this.setState({email_to_delete: null})}
                    onSuccess={(object) => this.setState({shouldRedirect: '/automations/template_emails'}) }
                />
            )}

            <Container fluid className="template-emails">

                <DashHeaderOpen
                    title={<span><i className="fas fa-magic mr-2 text-success " /> Automations</span>}
                    breadcrumb_1="Template Emails"
                    breadcrumb_2={email && email._id ? email.name : 'Create'}
                    actionComponent={(
                        <Link to="/automations/template_emails" className="btn btn-outline-warning">
                            <i className="fas fa-arrow-left mr-2" /> Back
                        </Link>
                    )}
                />

                <Card>

                    <CardHeader>
                        <CardTitle className="mb-0">
                            <i className="fas fa-envelope mr-2 text-success" /> {email.name}
                        </CardTitle>
                    </CardHeader>

                    <CardHeader>

                        <StandardFormGroup
                            obj={email}
                            objName='email'
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                            type="text"
                            field="name"
                            title="Template Name"
                        />

                        <StandardFormGroup
                            obj={email}
                            objName='email'
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`fState`]: v ? 'valid' : 'invalid'}})}
                            type="select"
                            field="division"
                            title="Template Division"
                        >
                            <option value="false"></option>
                            {divisions.map(division => (
                                <option value={division._id} key={division._id}>{division.name}</option>
                            ))}
                        </StandardFormGroup>

                        <StandardFormGroup
                            obj={email}
                            objName='email'
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                            type="text"
                            field="subject"
                            title="Email Subject"
                        />

                    </CardHeader>

                    <CardHeader>

                        <CardTitle className="mb-0">Email Attachments</CardTitle>

                        <Row>

                            <Col lg={6}>

                            <div className="list-hover">
                                <ul className="mb-0">

                                    {email.attachments && email.attachments.length ? email.attachments.map((attachment, i) => (

                                        <li key={i} >
                                            <p className="text-sm font-weight-bold mb-0">
                                                <i onClick={() => this.removeDocument(attachment)} className="fas fa-trash mr-2 text-danger" />
                                                {attachment.friendly_name}
                                            </p>
                                        </li>

                                    )) : null}

                                </ul>

                                {email.attachments && !email.attachments.length ? (
                                    <p className="text-sm text-warning font-weight-bold">
                                        No attachments have been added for this email.
                                    </p>
                                ) : null}
                            </div>

                            </Col>

                            <Col lg={6}>
                                <DragAndDrop
                                    zone_id="documents_1"
                                    url={`/api/v1/core/documents/create`}
                                    onError={(e) => console.log(e)}
                                    onUpload={this.onUpload}
                                    extraData={{bucket: '/template emails'}}
                                />
                            </Col>

                        </Row>

                    </CardHeader>

                    <CardBody>

                        <CardTitle className="mb-">
                            <Row>

                                <Col md={6}>
                                    Email Body
                                </Col>
                                <Col md={6} className="text-right">
                                    <i onClick={this.toggleModal} className="fas fa-info-circle text-info-original cursor-pointer" />
                                </Col>

                            </Row>
                        </CardTitle>

                        <div className={email.bodyState === 'invalid' ? 'quill-invalid' : null}>
                            <ReactQuill
                                value={this.state.reactQuillText}
                                onChange={this.handleReactQuillChange}
                                theme="snow"
                                modules={{
                                    toolbar: [
                                        ["bold", "italic"],
                                        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme

                                        ["link",],
                                        [
                                            { list: "ordered" },
                                            { list: "bullet" }
                                        ]
                                    ]
                                }}
                            />
                        </div>

                    </CardBody>

                    <CardFooter>

                        <Row>

                            <Col xs={6}>
                                {email._id ? (
                                    <button onClick={() => this.setState({email_to_delete: email})} className="btn btn-danger" style={{width: 110}}>
                                        <i className="fas fa-trash mr-2" /> Delete
                                    </button>
                                ) : null}
                            </Col>

                            <Col xs={6} className="text-right">
                                <button onClick={this.onSave} className="btn btn-success" style={{width: 110}}>
                                    <i  className="fas fa-save mr-2" /> Save
                                </button>
                            </Col>

                        </Row>

                    </CardFooter>

                </Card>

                <Info
                    showModal={showModal}
                    toggleModal={this.toggleModal}
                />

            </Container>

            </>

        )

    }

}

const mapStateToProps = state => {

    return {
        template_emails: state.template_emails.template_emails,
        divisions: state.divisions.divisions,
    };
};

export default connect(mapStateToProps, '')(TemplateEmailsView);
