import PropTypes from 'prop-types';
import React from "react";
import { Col, FormGroup } from "reactstrap";
import ContactSearchInput from 'components/system/migrated/ContactSearchInput';
import renderName from 'utils/renderName';
import { formatPhone } from 'utils/text'

class AppointmentsCrudParty extends React.Component {

    hasError = () => {

        const { associationError, appointment, party } = this.props;

        if(associationError) {

            let foundParty = appointment.parties.find(p => p.title === party);
            return foundParty && foundParty.contact ? false : true

        }

        return false

    }

    render() {

        const { appointment, onAssignParty, onRemoveParty, party } = this.props
        let foundParty = appointment.parties ? appointment.parties.find(p => p.title === party) : null

        if(foundParty && foundParty.contact) foundParty = foundParty.contact

        return (

            <Col lg={6} className="mb-4">

                <div className="bg-secondary p-1">
                    <FormGroup className="p-2">
                        <h4>Assign <span className="text-capitalize">{party}</span></h4>
                        <ContactSearchInput
                            hideTitle={true}
                            value={{}}
                            onSelect={(contact) => onAssignParty(party, contact)}
                        />
                    </FormGroup>

                    {this.hasError() ? (
                        <div className="alert alert-danger">You must select a contact before proceeding.</div>
                    ) : null}

                </div>

                <div  className="table-responsive table-no-margin bg-secondary mt--4">
                    <table className="table">

                        <thead>
                            <tr className="font-weight-bold">
                                <th className="text-capitalize">{party}</th>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            {foundParty ?(
                                <tr key={foundParty._id}>
                                    <td>
                                        <div className="text-capitalize">{renderName(foundParty)}</div>
                                        {foundParty && foundParty.phone ? <div>{formatPhone(foundParty.phone)}</div> : null}
                                        {foundParty && foundParty.email ? <div>{foundParty.email}</div> : null}
                                    </td>
                                    <td className="text-right">
                                        <i className="fas fa-trash text-danger cursor-pointer" onClick={() => onRemoveParty(party)}/>
                                    </td>
                                </tr>
                            ): (
                                <tr>
                                    <td className="text-warning font-weight-bold"><i className="fas fa-exclamation-triangle mr-2 " /> No Party Assigned</td>
                                    <td></td>
                                </tr>

                            )}
                        </tbody>

                    </table>
                </div>

            </Col>

        )

    }
}

AppointmentsCrudParty.propTypes = {
    onAssignParty   : PropTypes.func.isRequired,
    onRemoveParty   : PropTypes.func.isRequired,
    appointment               : PropTypes.object.isRequired,
}

export default AppointmentsCrudParty
