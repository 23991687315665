import _nylas from '_functions/nylas';
// const Queue = ()


/*
Documentation

This page shows a list of all cases
via api cases can be searched by case name or _id

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import Circle from 'components/markup/loading/Circle';
import moment from 'moment';
import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { Card, CardHeader, CardTitle, Container } from "reactstrap";
import renderName from 'utils/renderName';


const Queue = () => {

    const [queue, setQueue] = useState(null)

    const fetchData = useCallback(async() => {
        const data = await _nylas.count()
        setQueue(data)
    }, [])

    useEffect(() => {
        fetchData()

        let interval = setInterval(() => {
            fetchData()
        }, 2000)

        return () => {
            clearInterval(interval)
        }
    }, [fetchData])

    console.log(queue)

    return (
        <>

        <Container fluid>
            
            <DashHeaderOpen         
                title={<span><i className="fas fa-headset mr-2 text-success " />Analytics</span>}
                breadcrumb_1="Live Queue"
                breadcrumb_2="Trust"
            />

            <Helmet>
                <title>{`Live Queue`}</title>
                <meta name="description" content="Live Queue" />
            </Helmet>

            <Card className="card-color card-primary">

                <CardHeader>
                    <CardTitle className="mb-0 float-left">All Queue Entries</CardTitle>
                </CardHeader>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Contact</th>
                                <th>Case</th>
                                <th>Size</th>
                                <th className='text-right'>Time Requested</th>
                            </tr>
                        </thead>

                        <tbody>
                        {queue && queue.length ? queue.map(q => (
                            <tr>
                                <td className='text-capitalize'>{renderName(q.contact)}</td>
                                <td>{q.company_name}{console.log(q)}</td>
                                <td>{q.company_size}</td>
                                <td className='text-right'>{moment.unix(q.time_requested).format("MM/DD/YYYY h:mm A")}</td>
                            </tr>
                        )) : queue ? (
                            <tr>
                                <td>There are no live queue entries to be taken</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        ) : (
                            <tr>
                                <td><Circle /></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}

                        </tbody>
                    </table>
                </div>

                
          
            </Card>

        </Container>

        </>
    );

}

export default Queue
