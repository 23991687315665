import React, { useState } from 'react';
import { FormGroup, Input } from 'reactstrap';

const InputEmails = ({emails, onChange}) =>  {

    const [emailText, setEmailText] = useState('');

    const _handleKeyDown = (e) => {
		if (e.key === 'Enter') onAddEmail()
	}

    const onAddEmail = () => {
        if(!emailText) return;
        if(emails.includes(emailText)) return;

        const newEmails = [...emails];
        newEmails.push(emailText);

        setEmailText('')
        onChange(newEmails)
    }

    const onRemoveEmail = (email) => {
        let newEmails = [...emails];
        newEmails = newEmails.filter(a => a !== email);

        onChange(newEmails)
    }

    return (

        <div className="bg-secondary p-3">
             <FormGroup>
                <label className="form-control-label">Email On Confirmation (Press Enter To Add)</label>
                <Input 
                    onKeyDown={_handleKeyDown}
                    type="text"
                    value={emailText}
                    onChange={(e) => setEmailText(e.target.value)}
                />
            </FormGroup>

            <div className="table-responsive">
                <table className="table mb-0">
                    <thead>
                        <tr>
                            <th>Confirmation Emails</th>
                            <th className="text-right" style={{width: 100}}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {emails.length ? emails.map((email, i) => (
                            <tr key={i}>
                                <td>{email}</td>
                                <td className="text-right">
                                    <i className="fas fa-trash text-danger cursor-pointer " onClick={() => onRemoveEmail(email)} />
                                </td>
                            </tr>
                        )) : (
                            <tr>
                                <td>No Emails Set</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
       
       
    )
};

export default React.memo(InputEmails);

