import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React, { useState } from "react";
import { Helmet } from 'react-helmet';

import { Card, CardHeader, CardTitle, CardBody, CardFooter, Container, Row, Col } from 'reactstrap';

import UserSearchInput from 'components/system/migrated/UserSearchInput';
import ConfirmationModal from 'components/functional/modals/Confirmation.js'

import renderName from 'utils/renderName';
import { capitalize } from 'utils/text';

import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';
import _cases from '_functions/cases';
 
const CompanySettings = () => {

    const [fromUser, setFromUser] = useState(null);
    const [toUser, setToUser] = useState(null);
    const [err, setErr] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const onStartConfirm = () => {

        setErr(false);

        if(fromUser && fromUser._id && toUser && toUser._id) {
            setShowConfirmation(true);
        } else {
            setErr(true);
        }

    }

    const runUpdate = async () => {

        toggleStandardLoader(true)

        const updated = await _cases.reAssignUser({
            toUser: toUser._id,
            fromUser: fromUser._id,
        })

        setToUser(null)
        setFromUser(null)

        if(updated.success) {
            toggleAlertBS(false, `Cases and tasks updated successfully.`)
        } else {
            toggleAlertBS(true, `An error occurred updating cases and tasks.`)
        }

        toggleStandardLoader(false)

    }

    return (
        <Container fluid>

            <Helmet>
                <title>Company Settings</title>
                <meta name="description" content="Settings" />
            </Helmet>

            <ConfirmationModal
                showModal={showConfirmation}
                toggleModal={() => setShowConfirmation(false)}
                title="Confirm Bulk Update"
                body={(
                    <span>
                        Are you sure you wish to proceed? This will move all assigned cases and tasks from{' '}
                        <span className="font-weight-bold text-warning">{capitalize(renderName(fromUser))}</span> 
                        {' to '} 
                        <span className="font-weight-bold text-success">{capitalize(renderName(toUser))}.</span>
                    </span>
                )}
                onConfirmation={runUpdate}
            />
    
            <DashHeaderOpen
                title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
                breadcrumb_1="Bulk Update"
                breadcrumb_2="Case Users"
            />
    
    
            <Card>
            
                <CardHeader>
                    <CardTitle className="mb-0"><i className="fas fa-exclamation-triangle mr-2 text-warning " /> Bulk Update Case Users</CardTitle>
                </CardHeader>
            
                <CardBody>
                    <p className="text-sm mb-0">Here you can change all case roles and assigned tasks from one user to another, use with caution. Once finished every task and case the "from" user is assigned to will move to the "to" user specified.</p>
                </CardBody>
            
            </Card>
    
            <Card>
            
                <CardBody>
                    <Row>
                        <Col lg={6}>
                            <p className="text-sm mb-0">
                                {fromUser ? <span>Move From {capitalize(renderName(fromUser))}</span> : 'Move From:'}
                            </p>
                            <UserSearchInput
                                hideTitle="true"
                                value={fromUser ? fromUser : {}}
                                onSelect={setFromUser}
                            />
                        </Col>
                        <Col lg={6}>
                            <p className="text-sm mb-0">
                                {fromUser ? <span>Move To {capitalize(renderName(toUser))}</span> : 'Move To:'}
                            </p>
                            <UserSearchInput
                                hideTitle={true}
                                value={toUser ? toUser : {}}
                                onSelect={setToUser}
                            />
                        </Col>
                    </Row>
                </CardBody>

                {err ? (
                    <CardFooter>
                        <p className="text-sm font-weight-bold text-danger mb-0">Both a from user and a to user must be set before running the update.</p>
                    </CardFooter>
                ) : null}
            
                <CardFooter className="text-right">
                    <button onClick={onStartConfirm} className="btn btn-success">
                        <i className="fas fa-save mr-2" /> Run Update
                    </button>
                </CardFooter>
            
            </Card>
    
        </Container>
    )

}

export default CompanySettings
