import React from 'react';
import { Dropdown, Item, StandAlone } from './_components';

const SidebarAutomations = (props) => (
    <ul className="mb-0">

        {/* <Dropdown route="/automations/email_parsers" name="Email Parsers" icon="fas fa-envelope" {...props}>
            <Item  route="/automations/email_parsers" name="View All" {...props}/>
            <Item route="/automations/email_parsers/logs" name="Logs" {...props} />
        </Dropdown> */}

        <StandAlone route="/automations/flows/all" name="Case Flows" icon="fas fa-route" {...props} />
        <StandAlone route="/automations/automations" name="Automations" icon="fas fa-route" {...props} />
        <StandAlone route="/automations/template_emails" name="Emails" icon="fas fa-envelope" {...props} />
        {/* <StandAlone route="/automations/email_senders" name="Senders" icon="fas fa-map-pin" {...props} /> */}
        <StandAlone route="/automations/template_texts" name="Texts" icon="fas fa-phone" {...props} />
        <StandAlone route="/automations/global_settings" name="Global Settings" icon="fas fa-cogs" {...props} />

    </ul>
)

export default SidebarAutomations;
