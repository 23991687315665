import React, { useCallback } from 'react'
import { memo } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const Question = memo(function Question({ id, question, moveQuestion, findQuestion, children, acceptNested, onSelect, onEnd, onStart, onAddBranch}) {

    const originalIndex = findQuestion(id).index;

    const [{ isDragging }, drag] = useDrag({
        type: acceptNested,
        accept: acceptNested,
        item: { id, originalIndex, type: acceptNested },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        begin: (z, b) => {
            onStart()
        },
        end: (item, monitor) => {
            const { id: droppedId, originalIndex } = item;
            const didDrop = monitor.didDrop();

            onEnd()
            if (!didDrop) {
                moveQuestion(droppedId, originalIndex);
            }
        },
    }, [id, originalIndex, moveQuestion]);

    //return true if this is a yes or no question with no current branch and nested 3 levels or less
    const canBranch = useCallback((question) => {
        const isYesNo = question.type === 'yes - no';
        const emptyChildren = !question.questions.length;
        const branchDepthAccepted = parseInt(acceptNested.slice(0,1)) < 4;

        return isYesNo && emptyChildren && branchDepthAccepted
    }, [])
    
    const [, drop] = useDrop({
        accept: acceptNested,
        canDrop: () => false,
        hover({ id: draggedId }) {
            if (draggedId !== id) {
                const { index: overIndex } = findQuestion(id);
                moveQuestion(draggedId, overIndex);
            }
        },
    }, [findQuestion, moveQuestion]);
    
    return (

        <>
        <div  ref={(node) => drag(drop(node))} data-id={question.id} className={isDragging ? 'question question-drag' : 'question'}>
			<p className="text-sm mb-0" onClick={onSelect}>
                <span style={{wordBreak: 'break-all', maxWidth: 'calc(100% - 30px)', display: 'inline-block'}}>
                {question.description ? 
                    question.description.length > 100 ? question.description.slice(0,100) + '...' : question.description
                : 'New Question'}
                </span>
            </p>

            <i className="fas fa-pen float-right text-success font-weight-bold edit-icon" onClick={onSelect} />
            {canBranch(question)  ? (
                <i className="fas fa-code-branch float-right text-warning font-weight-bold branch-icon" onClick={() => onAddBranch(question)} />
            ) : null}

		</div>
        {children}
        </>
    );
});


export default Question