/*
Documentation

This page shows all of the deleted system cases

*/

import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import _cases from '_functions/cases';
import Circle from 'components/markup/loading/Circle'

import _archives from '_functions/archives';

const { SearchBar } = Search;

class ArchivesCases extends React.Component {

    state = {
        cases: null
    }

    restore = async (case_id) => {

        const restored = await _archives.restore.case({case_id})

        if(restored.success) {

            let cases = [...this.state.cases];
            cases = cases.filter(u => u._id !== case_id)

            this.setState({cases})

        }

    }

    columns = [
        {
            dataField: "name",
            text: "Name",
        },
        {
            dataField: "contact",
            text: "Contact",
            formatter: (cell, row) => {
                if(row.contact) {
                    return row.contact.given_name + ' ' + row.contact.family_name
                }

                return 'Not Found'
            }
        },
        {
            dataField: "court",
            text: "Court",
            formatter: (cell, row) => row.location_court ? row.location_court.name : 'Not Found'

        },

        {
            dataField: "_id",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <button className="btn btn-outline-warning" onClick={() => this.restore(row._id)}>
                            <i className="fas fa-check mr-2" /> Restore Case
                        </button>
                    </div>
                )
            }
        },
    ]

    pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: false,

        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    componentDidMount = async () => {

        const cases = await _cases.find(`?filter=deleted__true{bool}&limit=500&sort=given_name__asc|family_name__asc`)
        if(cases.success) this.setState({cases: cases.data})

        this.setState({loaded: true})

    }

    render() {

        const { cases, loaded } = this.state

        if(!loaded) return <div className="py-4"><Circle /></div>

        return (

            <Card className="card-color card-success">

                <CardHeader>
                    <CardTitle className="mb-0 float-left">Deleted Cases</CardTitle>
                </CardHeader>

                {cases && cases.length ? (

                    <ToolkitProvider
                        data={cases}
                        keyField="_id"
                        columns={this.columns}
                        search
                    >
                        {props => (
                            <>
                                <div
                                    id="datatable-basic_filter"
                                    className="dataTables_filter px-4 pb pt-3 pb-2"
                                    style={{width: '100%'}}
                                >
                                    <label>
                                        Search:
                                        <SearchBar
                                            className="form-control-sm"
                                            placeholder="Template Name"
                                            {...props.searchProps}
                                        />
                                    </label>
                                </div>
                                <div className=" table-responsive table-vertical-align table-not-fixed">
                                    <BootstrapTable
                                        pagination={this.pagination}
                                        {...props.baseProps}
                                        bootstrap4={true}
                                        bordered={false}
                                    />
                                </div>

                            </>

                        )}
                    </ToolkitProvider>

                ) : (

                    <CardBody>
                        <p className="text-sm mb-0">There are no deleted cases within the system.</p>
                    </CardBody>

                )}

            </Card>

        );
    }
}

export default ArchivesCases

