/*
Documentation

This file create the map for the form templates, it is what renders the cards visually

*/

import PropTypes from 'prop-types'
import Zoom from 'components/functional/Zoom';
import React from "react";
import { Card, CardBody, CardFooter, CardHeader, CardTitle } from "reactstrap";

class TemplatesCrudMap extends React.Component {

    setQuestionToEdit = (depth, index) => {

        const questions = this.props.form.questions

        // check if we are searching for a nested child

        let foundBranch = null
        if(depth !== 0)  foundBranch = this.props.findBranch(questions, depth).branch

        this.props.setQuestionToEdit( foundBranch ? foundBranch.children[index] : questions[index], depth, index)

    }


    // this function maps all cards onto the page
    renderMap = (questions, depth = 0, ) => {

        const markup = []

        let indexForCreateCard = 0

        questions.forEach((q, i) => {

            indexForCreateCard++

            // forked is basically the class name to render and whether to show an upward line
            let forked = depth !== 0 ? (i === 0) ? depth: `${depth} dont-show` : null;
            // if(depth !== 0)

            let type = q.type

            // format type if its a yes/no or text area for a friendly front end text
            if(type) {
               type = type.replace('/', ' / ')
               type = type.replace('textarea', 'Text Large')
           }

           let hasError = !q.text || !q.type

           // for each question create a card for it
            markup.push((
                <Card key={depth.toString() + i.toString()} className={forked ? `question forked forked-depth-${forked}` : `question bg-secondary forked-depth-${forked}`}>

                    <span className="archk-forms-line" />
                    <span className="archk-forms-add-question">
                        <i className="fas fa-plus-circle" onClick={() => this.props.createQuestion(depth, i + 1)} />
                    </span>

                    {forked && (
                        <>
                            <span className="archk-forms-line-forked" />
                            <span className="archk-forms-add-question-forked">
                                <i className="fas fa-plus-circle" onClick={() => this.props.createQuestion(depth, i)} />
                            </span>
                        </>
                    )}

                    <CardHeader className={hasError ? 'bg-gradient-primary' : 'bg-secondary'}>
                        <CardTitle className={hasError ? 'text-white text-sm mb-0' : 'text-sm mb-0'}>
                            {q.text ? q.text : 'Add question text'}
                        </CardTitle>
                    </CardHeader>

                    <CardFooter className={hasError ? 'bg-gradient-primary' : 'bg-secondary'} style={{borderTop: hasError ? 'solid 1px white' : 'inherit'}}>

                        <p className={hasError ? "text-white text-sm mb-0 text-capitalize" : "text-sm mb-0 text-capitalize"}>
                            {type ? type : 'Select Question Type'}
                        </p>

                        {!q.children ? (
                            <i onClick={() => this.props.deleteQuestion(depth, i)} className="fas fa-trash text-danger ml-3 cursor-pointer" />
                        ) : (
                            <i className="fas fa-trash text-danger ml-3 text-muted" />
                        )}

                        <i onClick={() => this.setQuestionToEdit(depth, i)} className="fas fa-edit text-success cursor-pointer" />

                    </CardFooter>

                </Card>
            ))

            // if there is children call this function again to render them all
            if(q.children) markup.push(this.renderMap(q.children, depth + 1))

        })

        // at the end of every branch render a card to be able to add another question
        markup.push((
            <Card
                key={depth}
                className={depth ? `question forked forked-depth-${depth} bg-success text-white` : "question bg-success text-white"}
                onClick={() => this.props.createQuestion(depth, indexForCreateCard)}
            >

                <CardHeader className="bg-transparent">
                    <CardTitle className="text-sm mb-0 text-center">
                        {!questions.length ?
                            depth !== 0 ? 'Start Branch Questions If the Answer To The Above Question Is No'
                            : 'Start Form' : 'No Question Given, End Form Route'}
                    </CardTitle>
                </CardHeader>

                <CardFooter className="bg-transparent">
                    <button className="btn btn-block btn-neutral">Add Form Question</button>
                </CardFooter>

            </Card>
        ))

        return markup
    }

    // set the position of each card on the page through js
    arrangeForm = (render) => {

        const archkFormsMap = document.getElementById('archk-forms-map')
        const scrollTop = archkFormsMap.scrollTop;
        const scrollLeft = archkFormsMap.scrollLeft;

        const cardWidth = 325
        const cardHeight = 200

        const forked = document.querySelectorAll('.forked')

        const lastDistances = {};

        forked.forEach(f => {
            f.style.position = 'static';
        })


        forked.forEach((f, i) => {

            let depth = f.className.split('forked-depth-')
            depth = parseInt(depth[1].split(' '))


            const left = f.offsetLeft - ( cardWidth * depth ) + (cardWidth * (i))

            let leftPosition = left

            if(lastDistances[depth]) leftPosition = lastDistances[depth] + cardWidth

            lastDistances[depth] = leftPosition

            if(render) {
                f.style.position = 'absolute';
                f.style.top = cardHeight * depth + 'px';
                f.style.left = leftPosition + 'px'

            }
        })

        // keep scroll position
        archkFormsMap.scrollTo({
            top: scrollTop,
            left: scrollLeft
        })

    }

    componentDidMount = () => this.arrangeForm(true)

    componentDidUpdate = () => this.arrangeForm(true)

    render() {

        const questions = this.props.form.questions

        return (
            <Card className="archk-forms-map-card">

                <Zoom element="archk-forms-map-wrapper"/>

                <div id="archk-forms-map" className="archk-forms-map"  style={{paddingRight: 20}}>
                   <div id="archk-forms-map-wrapper">
                        <CardBody>
                            {this.renderMap(questions)}
                        </CardBody>
                   </div>
                </div>

            </Card>
        );
    }
}

TemplatesCrudMap.propTypes = {

    form:               PropTypes.object.isRequired,

    toggleController:   PropTypes.func.isRequired,
    findBranch:         PropTypes.func.isRequired,
    setQuestionToEdit:  PropTypes.func.isRequired,
    createQuestion:     PropTypes.func.isRequired,
    deleteQuestion:     PropTypes.func.isRequired,

}

export default TemplatesCrudMap
