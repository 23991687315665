/*
Documentation

@required params

analytics_data_types (array) | this is an array of documents queried from the analytics_data_types collection
collection_name (string) | this is a collection name to filter the above param for .-active

__ In essence analytics_data_types gets filtered by the field collection name. (example, contacts, users, etc)

returns an array of objects that can be recognized by Graphs or Reports main component as inputs that can be queried;



*/

export default (analytics_data_types, collection_name, shouldSplitInputs) => {

    if(!collection_name) { console.error('Collection name (2nd param), must be passed to getDataFieldInptus'); return [] }
    if(!analytics_data_types) { return [] }

    analytics_data_types = analytics_data_types.filter(a => a.collection_name === collection_name)

    let inputs = []

    analytics_data_types.forEach(data_type => {

        let input = {
            field: data_type.key,
            label: data_type.key,
            isCustomData: true
        }

        if(data_type.type === 'text') {

            input.type = 'ArchkString'

        } else if(data_type.type === 'number') {

            input.type = 'ArchkNumber'

        } if(data_type.type === 'yes/no') {

            input.type = 'ArchkYesNo'

        } else if(data_type.type === 'one of') {

            let data = [];

            if(data_type.values && data_type.values.length) {
                data_type.values.forEach(d => data.push({value: d, text: d}))
            }

            input.type = 'ArchkSelect'
            input.data = data

        } else if(data_type.type === 'date') {

            input.type = 'ArchkDatePicker'

        }

        inputs.push(input)

    })

    if(shouldSplitInputs) {

        let arr1 = [];
        let arr2 = [];

        if(inputs && inputs.length) {

            if(inputs.length < 2) {

                arr1 = inputs
                arr2 = []

            } else {

                const half = Math.ceil(inputs.length / 2)

                arr1 = inputs.slice(0, half )
                arr2 = inputs.slice(half , inputs.length)

            }

        }

        return [ arr1, arr2]

    }

    return inputs

}
