/*
Documentation

This file shows all created workflows anf lets add more via a modal

*/

import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { FormGroup, Input, Modal } from "reactstrap";
import _phone_numbers from '_functions/phone_numbers';

const PhoneNumberModalCreate = ({showModal, toggleModal, onSuccess, divisions, questionnaires}) => {

    const [questionnaire, setQuestionnaire] = useState('');
    const [division, setDivision] = useState('');
    const [number, setNumber] = useState('');
    const [err, setErr] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const onSave = async () => {

        if(!division) return setErr('Division cannot be blank');
        if(division === 'false') return setErr('Division cannot be blank');
        
        if(!number) return setErr('Number cannot be blank');
        

        setIsSaving(true)

        const created = await _phone_numbers.create({
            division, 
            number, 
            questionnaire: questionnaire === 'false' || !questionnaire ? undefined : questionnaire
        })

        if(created.success) {
            onSuccess(created.data);
            setNumber('');
            setDivision('');
            setQuestionnaire('');
        } else {
            setErr(created.message)
        }

        setIsSaving(false)

    }

    const onKeyDown = (e) => {
		if (e.key === 'Enter') {
			document.getElementById("create-number-button").click();
		}
	}

    const focusInput = (tries = 0) => {

        if(tries > 10) return;

        const input = document.getElementById('create-number-number');

        if(input) {
            input.focus();
        } else {
            setTimeout(() => focusInput(tries +1), 100)
        }
    }

    useEffect(() => {
        if(showModal === true) focusInput();
    }, [showModal])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Create Phone Number</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            {divisions.length ? (
                <div className="modal-body">

                    <FormGroup>
                        <label className="form-control-label">Phone Number*</label>
                        <p className="text-sm mb-0">Number should match the format: +19876543211</p>
                        <Input 
                            id="create-number-number"
                            type="text"
                            value={number}
                            onChange={(e) => setNumber(e.target.value)}
                            onKeyDown={onKeyDown}
                        />
                    </FormGroup>
                    <FormGroup>
                        <label className="form-control-label">Division*</label>
                        <Input 
                            type="select"
                            value={division}
                            onChange={(e) => setDivision(e.target.value)}
                            onKeyDown={onKeyDown}
                        >
                            <option value="false"></option>
                            {divisions.map(d => (
                                <option key={d._id} value={d._id}>{d.name}</option>
                            ))}
                        </Input>
                    </FormGroup>
                    
                    <FormGroup>
                        <label className="form-control-label">Questionnaire</label>
                        <Input 
                            type="select"
                            value={questionnaire}
                            onChange={(e) => setQuestionnaire(e.target.value)}
                            onKeyDown={onKeyDown}
                        >
                            <option value="false"></option>
                            {questionnaires.map(d => (
                                <option key={d._id} value={d._id}>{d.name}</option>
                            ))}
                        </Input>
                    </FormGroup>

                </div>
            ) : (
                <div className="modal-body">At least one division must be made before creating a phone number</div>
            )}

            {err && (
                <div className="modal-body bg-secondary">
                    <p className="text-sm text-danger font-weight-bold mt-0 mb-0">* {err}</p>
                </div>
            )}

            {divisions.length ? (
                <div className="modal-footer">
                    <button id="create-number-button"  disabled={isSaving}  onClick={onSave}  className="btn  btn-success">
                        Create Number
                    </button>
                </div>
            ) : null}
            

        </Modal>
    )

}


const mapStateToProps = state => {
    return {
    	divisions: state.divisions.divisions,
        questionnaires: state.questionnaires.questionnaires.filter(q => q.active === true),
    };
};

export default connect(mapStateToProps, '')(PhoneNumberModalCreate);