import React, { useState } from "react";
import { connect } from 'react-redux';
import { Modal, FormGroup, Input } from "reactstrap";

import api_keys from '_functions/api_keys';
import { CopyToClipboard } from "react-copy-to-clipboard";

import { toggleStandardLoader } from 'store/functions/system/system';

const ModalCreate = ({showModal, toggleModal, onCreate, SETTINGS}) => {

    const [name, setName] = useState('');
    const [privileges, setPrivileges] = useState([]);
    const [err, setErr] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [key, setKey] = useState(false)

    const onSetPrivilege = (priv) => {
        let newPrivileges = [...privileges];

        if(newPrivileges.includes(priv)) {
            newPrivileges = newPrivileges.filter(p => p !== priv);
        } else {
            newPrivileges.push(priv)
        }

        setPrivileges(newPrivileges)
    }

    const onSave = async () => {
        setErr(false);

        if(!name) return setErr('A name must be given to this API Key');
        if(!privileges.length) return setErr('At least one privilege must be set on this API Key');

        toggleStandardLoader(true);
        const created = await api_keys.create({name, privileges});
        toggleStandardLoader(false);

        if(created.success) {
            setKey(created.data.key)
            setShowConfirmation(true);
            onCreate(created.data);
        } else {
            setErr('Something went wrong')
        }
    }

    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={() => toggleModal(false)}
            size="md"
        >

            <div className="modal-header">
                <h5 className="modal-title">Add An Api Key</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => toggleModal(false)}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            {showConfirmation ? (
                <div className="modal-body">

                    <p className="text-sm mb-0">Your API key is:</p>
                    <h4 className="my-4">
                        {key}
                        <CopyToClipboard
                            text={key}
                            onCopy={() => {
                                const text = document.getElementById(`key-copy`)
                                text.innerHTML = '<span class="text-success ml-4">Copied!</span>'
                                setTimeout(() => text.innerHTML = '<i class="fas fa-copy ml-4 cursor-pointer" />', 1500)
                            }}
                        >
                            <span className="cursor-pointer text-success" id={`key-copy`}>
                                <i className="fas fa-copy ml-4 cursor-pointer" />
                            </span>
                        </CopyToClipboard>
                    </h4>
                    
                    <p className="text-sm mb-0">Make sure you copy this key as it wil NEVER be shown again.</p>
                    <p className="text-sm mb-0">Keep it secret, keep it safe!</p>

                </div>
            ) : (
                <div className="modal-body">

                    <FormGroup>
                        <label className="form-control-label">Api Key Name</label>
                        <Input 
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </FormGroup>

                    <FormGroup>
                        {SETTINGS.api_privileges.map((priv, i) => (
                            <div key={i} className="custom-control custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    id={`api-key-privilege-${priv}`}
                                    type="checkbox"
                                    checked={privileges.includes(priv)}
                                    onChange={() => onSetPrivilege(priv)}
                                />
                                <label className="custom-control-label" htmlFor={`api-key-privilege-${priv}`}>
                                    Can interact with the resource: <b className="font-weight-bold text-underline">{priv}</b>
                                </label>
                            </div>
                        ))}
                    </FormGroup>

                </div>
            )}
            
            {err ? (
                <div className="modal-body bg-secondary">
                    <p className="text-danger font-weight-bold mb-0">{err}</p>
                </div>
            ) : null}

            {showConfirmation ? (
                <div className="modal-footer text-right">
                    <button className="btn btn-outline-warning" onClick={() => toggleModal(false)}>
                        Close
                    </button>
                </div>
            ) : (
                <div className="modal-footer text-right">
                    <button className="btn btn-success" onClick={onSave}>
                        <i className="fas fa-save mr-2" />
                        Save
                    </button>
                </div>
                
            )}
            
        </Modal>

    )

}



const mapStateToProps = state => {
    return {
        SETTINGS: state.system.SETTINGS,
    };
};

export default connect(mapStateToProps, '')(ModalCreate);