/*
Documentation

This page shows a list of all questionnaires
can search by name, email, phone, or _id

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import Circle from 'components/markup/loading/Circle';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Badge, Card, CardHeader, CardTitle, Container, Input } from "reactstrap";
import * as _ from 'underscore';
import _questionnaires from '_functions/questionnaires';

class QuestionnairesAll extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            page: 1,
            sizePerPage: 25,
            total_documents: 0,
            data: null,
        }
       this.queryTableDataThrottled = _.throttle(this.queryTableData, 750)
    }

    columns = [
        {
            dataField: "name",
            text: "Name",
            formatter: (cell, row) => (
                <Link className="text-capitalize" to={`/core/questionnaires/${row._id}`}>
                    {row.name}
                </Link>
            ),
        },
        {
            dataField: "active",
            text: "Active",
            formatter: (cell, row) => (
                row.active ? (
                    <Badge color="success" className="text-sm">
                        <i className="fas fa-check mr-2 " /> Yes
                    </Badge>
                ) : (
                    <Badge color="danger" className="text-sm">
                        <i className="fas fa-times mr-2 " /> No
                    </Badge>
                )

            )
        },
        {
            dataField: "create_pdf",
            text: "Create PDF",
            formatter: (cell, row) => (
                row.create_pdf ? (
                    <Badge color="success" className="text-sm">
                        <i className="fas fa-check mr-2 " /> Yes
                    </Badge>
                ) : (
                    <Badge color="danger" className="text-sm">
                        <i className="fas fa-times mr-2 " /> No
                    </Badge>
                )
            )
        },
        {
            dataField: "send_emails_to",
            text: "Notify",
            formatter: (cell, row) => (
                row.send_emails_to.length ? row.send_emails_to.map((email, i) => (
                    i === 0 ? email : ', ' + email
                )) : '-'
            )
        },

        {
            dataField: "_id",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <Link to={`/core/questionnaires/${row._id}`} className="btn btn-outline-success">
                            <i className="fas fa-edit mr-2" /> Edit
                        </Link>
                    </div>
                )
            }
        },
    ]

    onTableChange = async (type, newState) => {

        const { searchText } = this.state

        if(type === 'search') {
            this.queryTableDataThrottled(searchText, true)
        } else {

            this.setState({ page: newState.page, sizePerPage: newState.sizePerPage }, () => {
                this.queryTableDataThrottled(searchText)
            })

        }

    }

    getQueryParams = (search, skip) => {
        return {
            search          : search,
            skip            : skip,
            limit           : this.state.sizePerPage,
        }
    }

    queryTableData = async (searchText, setPage1) => {

        this.setState({loading: true});

        let page = this.state.page
        if(setPage1) {
            this.setState({page: 1})
            page = 1
        }

        const skip = (page - 1) * this.state.sizePerPage;

        const questionnaires = await _questionnaires.search(this.getQueryParams(searchText, skip))
        this.setState({ data: questionnaires.data, total_documents: questionnaires.total_documents, loading: false })

    }


    componentDidMount = async () => {

        const questionnaires = await _questionnaires.search(this.getQueryParams(undefined, 0))
        this.setState({data: questionnaires.data, total_documents: questionnaires.total_documents})

    }

    render() {

        const { data, loading } = this.state;

        return (
            <>

            <Container fluid>

                <Helmet>
                    <title>{`Questionnaires (${this.state.total_documents}) `}</title>
                    <meta name="description" content="Questionnaires" />
                </Helmet>

                <DashHeaderOpen
                    title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
                    breadcrumb_1="Questionnaires"
                    breadcrumb_2="All"  
                    actionComponent={(
                        <Link  to="/core/questionnaires/new" className="btn btn-success">
                            <i className="fas fa-plus mr-2 " /> Add
                        </Link>
                    )}
                />
               
                <Card className="card-color card-primary">

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">All Questionnaires</CardTitle>
                    </CardHeader>

                    {data ? (
                        <ToolkitProvider
                            data={data.length ? data : []}
                            keyField="_id"
                            columns={this.columns}
                            search
                        >
                            {props => (
                                <>
                                    <div className="dataTables_filter px-4 pb pt-3 pb-2 position-relative" >
                                        <label>
                                            Search:
                                            <Input
                                                className="form-control-sm"
                                                value={this.state.searchText}
                                                placeholder="Name, Email, Phone, or ID"
                                                style={{width: 190}}
                                                onChange={(e) => {
                                                    this.setState({searchText: e.target.value})
                                                    this.queryTableDataThrottled(e.target.value, true)
                                                }}
                                            />
                                                {loading ? (<Circle style={{display: 'inline-block', top: 1, position: 'absolute'}} /> ) : null}

                                        </label>
                                    </div>

                                    <div className="table-not-fixed table-responsive table-vertical-align">
                                        <BootstrapTable
                                            pagination={paginationFactory({
                                                totalSize: this.state.total_documents,
                                                page: this.state.page,
                                                sizePerPage: this.state.sizePerPage,
                                                alwaysShowAllBtns: true,
                                                showTotal: true,
                                                withFirstAndLast: true,
                                                sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                                                    <div className="dataTables_length" id="datatable-basic_length">
                                                        <label>
                                                            Show{" "}
                                                            {
                                                            <select
                                                                value={currSizePerPage}
                                                                name="datatable-basic_length"
                                                                aria-controls="datatable-basic"
                                                                className="form-control form-control-sm"
                                                                onChange={e => onSizePerPageChange(e.target.value)}
                                                            >
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                            }{" "}
                                                            entries.
                                                        </label>
                                                    </div>
                                                )
                                            })}
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            bordered={false}
                                            remote={{
                                                search: true,
                                                pagination: true,
                                                sort: false,
                                                cellEdit: false
                                            }}
                                            onTableChange={this.onTableChange}
                                            rowClasses={(row, rowIndex) => {
                                                if(row.blacklisted) {
                                                    return 'bg-secondary' 
                                                }
                                                return '';
                                            }}
                                        />
                                    </div>
                                </>
                            )}
                        </ToolkitProvider>
                    ) : (
                       <Circle />
                    )}

                </Card>

            </Container>

            </>
        );
    }
}

export default QuestionnairesAll
