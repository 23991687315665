import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Badge, Card, CardFooter, CardHeader, CardTitle, Col, Container, FormGroup, Input, Row } from "reactstrap";

import { toggleAlertBS } from 'store/functions/system/system';

import ReactSelect from 'components/functional/inputs/ReactSelect';
import _affiliates from '_functions/affiliates';

const Affiliates = ({lead_sources}) => {

    const [affiliate, setAffiliates] = useState({ lead_sources: [] })
    const [redirect, setRedirect] = useState(null)

    const onSave = useCallback(async () => {
        if(!affiliate.given_name) return;
        if(!affiliate.family_name) return;
        if(!affiliate.phone) return;
        if(!affiliate.email) return;
        if(!affiliate.password) return;

        const saved = await _affiliates.create(affiliate)
        if(!saved.success) return toggleAlertBS(true, 'Please try again')

        setRedirect('/analytics/affiliates')
    }, [affiliate])

    const setState = useCallback((field, value) => {
        const a = JSON.parse(JSON.stringify(affiliate))
        a[field] = value;
        setAffiliates(a)
    }, [affiliate])

    const addSource = useCallback((s) => {
        const a = JSON.parse(JSON.stringify(affiliate))
        if(!a.lead_sources.includes(a)) a.lead_sources.push(s)
        setAffiliates(a)
    }, [affiliate])

    const removeSource = useCallback((s) => {
        const a = JSON.parse(JSON.stringify(affiliate))
        a.lead_sources = a.lead_sources.filter(aa => aa !== s)
        setAffiliates(a)
    }, [affiliate])

    const getName = (a) => {
        const found = lead_sources.find(s => s._id === a)
        return found ? found.name : '----'
    }

    if(redirect) return <Redirect to={redirect} />

    return (
        <Container fluid>

            <Helmet>
                <title>{`Affiliates`}</title>
                <meta name="description" content="Affiliates" />
            </Helmet>

            <DashHeaderOpen
                icon="fas fa-home"
                icon_link="/analytics"
                title={<span >Affiliates</span>} breadcrumb_1="All"
            />

            <Card className="card-color card-primary">

                <CardHeader>
                    <CardTitle className="mb-0">Create Affiliate</CardTitle>
                </CardHeader>

                <CardFooter>
                    <Row>
                        <Col lg={6}>
                            <FormGroup>
                                <label className='form-control-label'>First Name*</label>
                                <Input 
                                    type="text"
                                    value={affiliate.given_name}
                                    onChange={(e) => setState('given_name', e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={6}>
                            <FormGroup>
                                <label className='form-control-label'>Last Name*</label>
                                <Input 
                                    type="text"
                                    value={affiliate.family_name}
                                    onChange={(e) => setState('family_name', e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <FormGroup>
                        <label className='form-control-label'>Email*</label>
                        <Input 
                            type="text"
                            value={affiliate.email}
                            onChange={(e) => setState('email', e.target.value)}
                        />
                    </FormGroup>

                    <FormGroup>
                        <label className='form-control-label'>Phone*</label>
                        <Input 
                            type="text"
                            value={affiliate.phone}
                            onChange={(e) => setState('phone', e.target.value)}
                        />
                    </FormGroup>

                    <FormGroup>
                        <label className='form-control-label'>Password*</label>
                        <Input 
                            type="text"
                            value={affiliate.password}
                            onChange={(e) => setState('password', e.target.value)}
                        />
                    </FormGroup>

                </CardFooter>

                <CardFooter>
                    <FormGroup>
                        <label className='form-control-label'>Lead Sources</label>
                        <ReactSelect
                            onChange={(values) => addSource(values.value)}
                            value={addSource}
                            options={lead_sources.map(l => {
                                return { value: l._id, label: l.name }
                            })}
                        />
                    </FormGroup>

                    {affiliate.lead_sources.map(s => (
                        <Badge 
                            key={s} 
                            color="success"
                        >
                            {getName(s)} 
                            <i className="fas fa-times pl-3 cursor-pointer" onClick={() => removeSource(s)} /> 
                        </Badge>
                    ))}
                </CardFooter>

                <CardFooter className="text-right">
                    <button onClick={onSave} className="btn btn-success">Create!</button>
                </CardFooter>
            </Card>

           
       
        </Container>

    )
}


const mapStateToProps = state => {
    return {
        lead_sources: state.lead_sources.lead_sources,
    };
};

export default connect(mapStateToProps, '')(Affiliates);