import { connect } from 'react-redux';
import { Card, CardHeader, CardTitle, Col, Row, CardBody } from "reactstrap";

import renderName from 'utils/renderName';

const SigningOverview = ({allData, users, onSetBreakdown}) => {

    const data = JSON.parse(JSON.stringify(allData))
    data.sort((a, b) => a.total >= b.total ? -1 : 1)

    const renderRow = ({field, formatter, type}) => {
        const d = JSON.parse(JSON.stringify(data))
        d.sort((a, b) => {
            let aField = a.metrics[type][field] && typeof a.metrics[type][field] === 'string' ? a.metrics[type][field].replace('%', '') : a.metrics[type][field]
            let bField = b.metrics[type][field] && typeof b.metrics[type][field] === 'string' ? b.metrics[type][field].replace('%', '') : b.metrics.consultation[field]
            return parseFloat(aField) >= parseFloat(bField) ? -1 : 1
        })
        let markup = [];
        d.forEach(dd => {
            markup.push((
                <>
                <tr>
                    <td className="text-capitalize">{renderName(users.find(u => u._id === dd._id))}</td>
                    <td className="text-right">Percent</td>
                    <td className="text-right">Number</td>
                </tr>
                 <tr>
                    <td className="pl-6">Consultations</td>
                    <td className="pl-6 text-right"></td>
                    <td className="pl-6 text-right">{dd.metrics.consultation.total}</td>
                </tr>
                <tr>
                    <td className="pl-6">Showed</td>
                    <td className="pl-6 text-right">{dd.metrics.consultation.total &&  dd.metrics.consultation.total ? (((dd.metrics.consultation.total - dd.metrics.consultation.noShow) / dd.metrics.consultation.total) * 100).toFixed(2) : '0'}%</td>
                    <td className="pl-6 text-right">{dd.metrics.consultation.total - dd.metrics.consultation.noShow}</td>
                </tr>
                <tr>
                    <td className="pl-6">Retainers Sent</td>
                    <td className="pl-6 text-right">{dd.metrics.consultation.sent && dd.metrics.consultation.total ? (((dd.metrics.consultation.sent) / (dd.metrics.consultation.total - dd.metrics.consultation.noShow)) * 100).toFixed(2) : '0'}%</td>
                    <td className="pl-6 text-right">{dd.metrics.consultation.sent}</td>
                </tr>
                <tr>
                    <td className="pl-6">Signed Cases</td>
                    <td className="pl-6 text-right">{dd.metrics.case.signed && dd.metrics.consultation.sent ? (((dd.metrics.case.signed) / dd.metrics.consultation.sent) * 100).toFixed(2) : '0'}%</td>
                    <td className="pl-6 text-right">{dd.metrics.case.signed}</td>
                </tr>
             </>
                
            ))
        })

        return markup

    }

    return (
        <div>

                <Card>

                    <CardHeader>
                        <Row>
                            <Col md={6}>
                                <CardTitle className="text-capitalize">User Stats</CardTitle>
                            </Col>
                            <Col md={6} className="text-right">
                                <button onClick={onSetBreakdown} className="btn btn-success">View Individually</button>
                            </Col>
                        </Row>
                    </CardHeader>

                    <div className="table-responsive">
                        <table className="table">
                            <tbody>
                                {renderRow({
                                    type: 'consultation',
                                    field: 'total',
                                    formatter: (_data) => _data.metrics.consultation.total
                                })}
                            </tbody>
                        </table>
                    </div>
                        

                </Card>

        </div>
    )
}


const mapStateToProps = state => {
    return {
        users : state.users.users,
    };
};

export default connect(mapStateToProps, '')(SigningOverview);
