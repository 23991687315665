import PropTypes from 'prop-types';
import React, { Component } from 'react';

class AutoWait extends Component {

    render() {

        const { task, onAddTask, order, wait_time, selected_task, toggleSidebar  } = this.props

        return (

            <div className={selected_task === task._id ? "selected auto wait" : "auto wait"} >

                <div className="wrapper" onClick={() => toggleSidebar(true, task._id)}>
                    <div className="title">
                        <b><i className="fas fa-clock mr-2 text-warning" /> Wait: </b>
                        {wait_time}
                    </div>
                </div>

                <span onClick={() => onAddTask(order )} className="add before"><i className="fas fa-plus" /> </span>
                <span onClick={() => onAddTask(order + 1)} className="add after"><i className="fas fa-plus" /> </span>

            </div>

        )

    }

}

AutoWait.propTypes = {
    task            : PropTypes.object.isRequired,
    toggleSidebar   : PropTypes.func.isRequired,
    selected_task   : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default AutoWait;
