/*
Documentation

This file shows all case_alerts in the system. Has simple functionality to create, and delete them

*/

import NotificationDelete from 'components/functional/notifications/Delete';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, Container, Row, Col, CardFooter } from "reactstrap";
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import _case_alert_templates from '_functions/case_alert_templates';

const { SearchBar } = Search;

class CaseAlertTemplates extends React.Component {

    state = {
        case_alert_template: { notify_contact: 'no' },
        caseAlertTemplateToDelete: null,
    }

    columns = [
        {
            dataField: "value",
            text: "Value",
        },
        {
            dataField: "notify_contact",
            text: "Notify Client",
            formatter: (cell, row) => (
                row.notify_contact === 'no' ?
                    <i className="fas fa-times text-warning font-weight-bold" /> :
                    <i className="fas fa-check text-success font-weight-bold" />
            )
        },
        {
            dataField: "_id",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <i onClick={() => this.setState({caseAlertTemplateToDelete: row})} className="fas fa-trash text-danger"></i>
                    </div>
                )
            }
        },
    ]

    pagination = paginationFactory({
        page: 1,
        sizePerPage: 5,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: true,
        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        value={currSizePerPage}
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    onCreate = async () => {

        const { case_alert_template } = this.state

        if(!case_alert_template.value) {
            return this.setState({case_alert_template: {valueState: 'invalid'}})
        } else {
            this.setState({loading: true})
        }

        await _case_alert_templates.create(case_alert_template)

        this.setState({case_alert_template: { notify_contact: 'no' }, loading: false})

    }

    render() {

        const case_alert_templates = this.props.case_alert_templates
        const caseAlertTemplateToDelete = this.state.caseAlertTemplateToDelete;

        const { case_alert_template, loading } = this.state;


        return (
            <>

            <Helmet>
                <title>{`Case Alert Templates`}</title>
                <meta name="description" content="Case Alert Templates" />
            </Helmet>

            {caseAlertTemplateToDelete && (
                <NotificationDelete
                    deletionURL={`/api/v1/core/case_alert_templates/delete/${caseAlertTemplateToDelete._id}`}
                    confirmBtnText="Delete"
                    title={<span className="text-capitalize">{`Delete Case Alert Template`}</span>}
                    text="Are you sure you wish to delete this case alert template?"
                    onClose={() => this.setState({caseAlertTemplateToDelete: null})}
                    onSuccess={(object) => { } }
                />
            )}

            <Container fluid>

                <DashHeaderOpen
                    title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
                    breadcrumb_1="Case Alert Templates"
                    breadcrumb_2="All"
                />

                <Card>

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">Case Alerts</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <p className="text-sm mb-0">Case Alerts allow an system admin to create pre-formatted case alerts to be used when updating a case.</p>
                    </CardBody>

                </Card>

                <Row>

                    <Col lg={4}>
                        <Card>

                            <CardHeader>
                                <CardTitle className="mb-0 float-left">Create Case Alert Template</CardTitle>
                            </CardHeader>

                            <CardBody>
                                <StandardFormGroup
                                    obj={case_alert_template}
                                    objName="case_alert_template"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="value"
                                    type="textarea"
                                    title="Case Alert Text"
                                />
                                <StandardFormGroup
                                    obj={case_alert_template}
                                    objName="case_alert_template"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="notify_contact"
                                    type="select"
                                    description="If set to yes this will create an alert for the contact associated on the case when this template is selected"
                                    title="Notify Client"
                                >
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                </StandardFormGroup>
                            </CardBody>

                            <CardFooter className="text-right">
                                <button disabled={loading} className="btn btn-success" onClick={this.onCreate}>Save</button>
                            </CardFooter>

                        </Card>
                    </Col>

                    <Col lg={8}>
                        <Card>

                            <CardHeader>
                                <CardTitle className="mb-0 float-left">All Case Alerts</CardTitle>
                            </CardHeader>

                            {case_alert_templates && case_alert_templates.length ? (

                                <ToolkitProvider
                                    data={case_alert_templates}
                                    keyField="_id"
                                    columns={this.columns}
                                    search
                                >
                                    {props => (
                                        <>
                                        <div
                                            id="datatable-basic_filter"
                                            className="dataTables_filter px-4 pb pt-3 pb-2"
                                            style={{width: '100%'}}
                                        >
                                            <label>
                                                Search:
                                                <SearchBar
                                                    className="form-control-sm"
                                                    placeholder="Case Name"
                                                    {...props.searchProps}
                                                />
                                            </label>
                                        </div>
                                        <div className=" table-responsive table-vertical-align table-not-fixed">
                                            <BootstrapTable
                                                pagination={this.pagination}
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                bordered={false}
                                            />
                                        </div>
                                        </>
                                    )}
                                </ToolkitProvider>

                            ) : (

                                <CardBody>
                                    <p className="text-sm mb-0">No Case Alerts Have Been Created Yet.</p>
                                </CardBody>

                            )}

                        </Card>
                    </Col>

                </Row>

            </Container>

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        case_alert_templates: state.case_alert_templates.case_alert_templates,
    };
};

export default connect(mapStateToProps, '')(CaseAlertTemplates);
