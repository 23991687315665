/*
Documentation

This page shows all of the systems brokers

*/

import NotificationDelete from 'components/functional/notifications/Delete';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, Container, Row, Col, CardFooter } from "reactstrap";
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import _brokers from '_functions/brokers';
import Circle from 'components/markup/loading/Circle'
import { toggleAlertBS } from 'store/functions/system/system';

class Brokers extends React.Component {

    state = {
        broker: {},
        brokerToDelete: null,
        brokers: null,
    }

    columns = [
        {
            dataField: "name",
            text: "Name",
            headerStyle: {width: 150},
            formatter: (cell, row) => <span onClick={() => this.setState({broker: row})} className="cursor-pointer text-capitalize font-weight-bold">{row.name}</span>
        },
        {
            dataField: "_id",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <span onClick={() => this.setState({broker: row})}><i className="cursor-pointer fas fa-edit text-success mr-2"></i></span>
                        <i onClick={() => this.setState({brokerToDelete: row})} className="cursor-pointer fas fa-trash text-danger"></i>
                    </div>
                )
            }
        },
    ]

    pagination = paginationFactory({
        page: 1,
        sizePerPage: 25,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: true,
        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        value={currSizePerPage}
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    onSwitchToCreate = () => {
        this.setState({broker: {}})
    }

    onCreate = async () => {

        const { broker } = this.state
        let default_percent = 0;

        const percentIsValid = (percent) => {
            return percent !== undefined && percent !== null && percent !== '' ? true : false
        }

        if(!percentIsValid(broker.default_percent)) return toggleAlertBS('info', 'Make sure that the default percent is a number between 0 and 1. Example: 25% = .25');

        default_percent = parseFloat(broker.default_percent)

        if(!broker.name) {
            return this.setState({broker: {nameState: 'invalid'}})
        } else {
            this.setState({loading: true})
        }

        await _brokers.create({...broker, default_percent})
        this.fetchBrokers();
        this.setState({broker: {}, loading: false})

    }

    onUpdate = async () => {

        const { broker } = this.state
        let default_percent = 0;

        const percentIsValid = (percent) => {
            return percent !== undefined && percent !== null && percent !== '' ? true : false
        }

        if(!percentIsValid(broker.default_percent)) return toggleAlertBS('info', 'Make sure that the default percent is a number between 0 and 1. Example: 25% = .25');

        default_percent = parseFloat(broker.default_percent)

        if(!broker.name) {
            return this.setState({broker: {nameState: 'invalid'}})
        } else {
            this.setState({loading: true})
        }

        await _brokers.update(broker._id, {name: broker.name, default_percent})
        this.fetchBrokers();
        this.setState({broker: {}, loading: false})

    }

    fetchBrokers = async () => {
        const { data } = await _brokers.find();
        this.setState({brokers: data ? data : []})
    }

    componentDidMount = () => {
        this.fetchBrokers();
    }

    render() {

        const { broker, loading, brokers, brokerToDelete } = this.state;
        if(!brokers) return <div className="py-6"><Circle /></div>

        const isUpdating = broker._id;

        return (
            <>

            <Helmet>
                <title>{`Brokers (${brokers.length}) `}</title>
                <meta name="description" content="Brokers" />
            </Helmet>

            {brokerToDelete && (
                <NotificationDelete
                    deletionURL={`/api/v1/core/brokers/${brokerToDelete._id}`}
                    confirmBtnText="Delete Broker"
                    title={<span className="text-capitalize">{`Delete Broker: ${brokerToDelete.name}`}</span>}
                    text="Are you sure you wish to proceed."
                    onClose={() => this.setState({brokerToDelete: null})}
                    onSuccess={(object) => {
                        this.fetchBrokers()
                    } }
                />
            )}

            <Container fluid>

                <DashHeaderOpen
                    title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
                    breadcrumb_1="Brokers"
                    breadcrumb_2="All"
                />

                <Card className="card-color card-success">

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">Brokers</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <p className="text-sm">Brokers allow you to create relationships to leads that have come in.</p>
                    </CardBody>

                </Card>

                <Row>

                    <Col lg={4}>
                        <Card className={isUpdating ?"card-color card-warning" : "card-color card-success"}>

                            <CardHeader>
                                <CardTitle className="mb-0 float-left">
                                    {isUpdating ? 'Update Broker' : 'Create Broker' }

                                </CardTitle>
                            </CardHeader>

                            <CardBody>

                                <StandardFormGroup
                                    obj={broker}
                                    objName="broker"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="name"
                                    title="Broker Name"
                                />

                                <StandardFormGroup
                                    obj={broker}
                                    objName='broker'
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    type="number"
                                    field="default_percent"
                                    title={`Default Percent: ${!Number.isNaN(parseFloat(broker.default_percent)) ? (parseFloat(broker.default_percent) * 100).toFixed(2) + '%' : '0%'}`}    
                                />
                        

                            </CardBody>

                            <CardFooter className="text-right">
                                {isUpdating ? (
                                    <>
                                    <button disabled={loading} className="btn btn-outline-success" onClick={this.onSwitchToCreate}>Back</button>
                                    <button disabled={loading} className="btn btn-success" onClick={this.onUpdate}>Update Broker</button>
                                    </>
                                ) : (
                                    <button disabled={loading} className="btn btn-success" onClick={this.onCreate}>Create New Broker</button>
                                )}
                            </CardFooter>

                        </Card>
                    </Col>

                    <Col lg={8}>
                        <Card className="card-color card-primary">

                            <CardHeader>
                                <CardTitle className="mb-0 float-left">All Brokers</CardTitle>
                            </CardHeader>
                                    {console.log(brokers)}
                            {brokers.length ? (

                                <ToolkitProvider
                                    data={brokers}
                                    keyField="_id"
                                    columns={this.columns}
                                    search
                                >
                                    {props => (
                                        <>
                                        <div className=" table-responsive table-vertical-align table-not-fixed">
                                            <BootstrapTable
                                                pagination={this.pagination}
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                bordered={false}
                                            />
                                        </div>
                                        </>
                                    )}
                                </ToolkitProvider>

                            ) : (

                                <CardBody>
                                    <p className="text-sm mb-0">No Brokers Have Been Created Yet.</p>
                                </CardBody>

                            )}

                        </Card>
                    </Col>

                </Row>

            </Container>

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        brokers: state.brokers,
    };
};

export default connect(mapStateToProps, '')(Brokers);

