import { connect } from 'react-redux';
import ReactSelect from 'components/functional/inputs/ReactSelect';
import React, { Component } from 'react';
import { Card, CardBody, FormGroup, CardFooter, Input } from 'reactstrap';
import _template_docs from '_functions/template_docs';

class TemplateDocs extends Component {

    state = {
        template_doc_markers: []
    }

    getTemplateDoc = (template) => {
        return this.props.templateDocOptions.find(d => d.value === template) || {};
    }

    onTemplateDocSelect = async (values) => {

        const template = await _template_docs.findById(values.value);

        if(template.data) {
            console.log(template.data.markers)
            this.props.onTemplateDocMarkerSelect(template.data.markers);
            this.props.onTemplateDocSelect(values.value);
        }

    }

    onMarkerChange = (e, i) => {

        const template_doc_markers = [...this.props.step.template_doc_markers];
        template_doc_markers[i].answer = e.target.value;

        this.props.onTemplateDocMarkerSelect(template_doc_markers);

    }

    onRemoveDoc = async () => {
        await this.props.onTemplateDocSelect(null);
        this.props.onTemplateDocMarkerSelect();
    }

    render() {

        const { templateDocOptions, analytics_data_types, step } = this.props

        return (

            <div>
                <h4>Document Signing</h4>

                <Card>
                    <CardBody>

                    <FormGroup>
                        <label className="form-control-label">Template Doc*</label>
                        <ReactSelect
                            value={this.getTemplateDoc(step.template_doc)}
                            onChange={this.onTemplateDocSelect}
                            options={templateDocOptions}
                        />
                    </FormGroup>

                    {step.template_doc_markers && step.template_doc_markers.length ? (
                        <div className="bg-secondary p-4">
                            {step.template_doc_markers.map((marker, i) => (
                                marker.type !== 'text' ? '' : 
                                <FormGroup>
                                    <label className="form-control-label">PRE-FILL ANSWER: {marker.name}</label>
                                    <Input 
                                        type="select"
                                        value={marker.answer}
                                        onChange={(e) => this.onMarkerChange(e, i)}
                                    >
                                        <option></option>

                                        <option disabled>Contact Fields</option>
                                        <option value="contact|_id">Contact ID</option>
                                        <option value="contact|name">Contact Name</option>
                                        <option value="contact|email">Contact Email</option>
                                        <option value="contact|phone">Contact Phone</option>

                                        <option disabled>Case Fields</option>
                                        <option value="case|_id">Case ID</option>
                                        <option value="case|name">Case Name</option>
                                        <option value="case|case_number">Case Number</option>
                                        
                                        <option disabled>Custom Data Fields</option>
                                        {analytics_data_types && analytics_data_types.length ? analytics_data_types.map(a => (
                                                a.deleted === false &&  a.collection_name === 'cases'  ?
                                                    <option key={a._id} value={`cd|${a.key}`}>{a.key}</option> : null
                                        )) : null}
                                    </Input>
                                </FormGroup>
                            ))}
                        </div>
                    ) : null }

                    </CardBody>

                    {step.template_doc ? (
                        <CardFooter className="text-right">
                            <button onClick={this.onRemoveDoc} className="btn btn-warning">Remove</button>
                        </CardFooter>
                    ) : null}

                 
                </Card>

            </div>

        )

    }

}

TemplateDocs.propTypes = {

}


const mapStateToProps = state => {
    return {
        analytics_data_types: state.analytics_data_types.analytics_data_types,
    };
};

export default connect(mapStateToProps, '')(TemplateDocs);