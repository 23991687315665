/*
Documentation

this file renders the main body of an automation with all the
ability to change how the automation functions



*/

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import _automations from '_functions/automations'

class AutoMain extends Component {


    state = {

    };

    componentDidMount = () => this.setState({running: this.props.automation.running})

    toggleRunning = () => {

        let value = this.state.running ? false : true
        _automations.update(this.props.automation._id, {running: value})

        this.setState({running: value})

    }

    render() {

        const { onAddTask, automation_tasks, showSidebar } = this.props
        const { running } = this.state

        return (

            <>

            <Helmet>
                <title>{`Automations`}</title>
                <meta name="description" content="Tags" />
            </Helmet>

            <Container fluid>

                <DashHeaderOpen
                    title={<span>Automations</span>}
                    breadcrumb_1={<span>Edit</span>}
                    actionComponent={(
                        <>
                        {running  ? (
                            <button onClick={this.toggleRunning} className="btn btn-success">
                                <i className="fas fa-toggle-off mr-2" /> Running
                            </button>
                        ) : (
                            <button onClick={this.toggleRunning} className="btn btn-danger">
                                <i className="fas fa-toggle-on mr-2" /> Stopped
                            </button>
                        )}

                        <Link to="/automations/automations" className="btn btn-success">
                            <i className="fas fa-arrow-left mr-2" /> All Automations
                        </Link>
                        </>
                    )}
                />

                <div className={showSidebar ? "auto-creator sidebar-open " : "auto-creator"}>

                    <div className="auto start">
                        <div className="wrapper bg-success">
                            <div className="title">
                                <b className="text-white"><i className="fas fa-play mr-2" /> START AUTOMATION </b>
                            </div>
                        </div>
                        <span onClick={() => onAddTask(1)} className="add after"><i className="fas fa-plus" /> </span>
                    </div>

                    {this.props.children}

                    <div className="auto end">
                        <div className="wrapper bg-danger">
                            <div className="title">
                                <b className="text-white"><i className="fas fa-pause mr-2" /> END AUTOMATION </b>
                            </div>
                        </div>
                        {automation_tasks && automation_tasks.length ? (
                            <span onClick={() => onAddTask(automation_tasks.length + 1)} className="add before"><i className="fas fa-plus" /> </span>
                        ) : null }
                    </div>

                </div>

            </Container>

            </>

        )

    }

}

AutoMain.propTypes = {
    onAddTask           : PropTypes.func.isRequired,
    automation_tasks    : PropTypes.array.isRequired,
    showSidebar         : PropTypes.bool.isRequired,
    automation          : PropTypes.object.isRequired
}

export default AutoMain;
