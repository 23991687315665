/*
Documentation

This shows the modal that asks for the form name and estimated time

*/

import PropTypes from 'prop-types'
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import React from "react";
import { Modal, PopoverBody, UncontrolledPopover } from "reactstrap";

class TemplatesCrudSaveModal extends React.Component {

    state = {
    }

    render() {

        const { form, showSaveModal, toggleSaveModal, onFormInputChange, errors, onSave } = this.props;

        return (
            <>

            <Modal
                className="modal-dialog-centered"
                isOpen={showSaveModal}
                toggle={() => toggleSaveModal()}
                size="sm"
                fade={false}
            >

                <div className="modal-header">
                    <h5 className="modal-title">
                        {form._id ? 'Update Form' : "Create Form"}
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => toggleSaveModal()}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">

                    <StandardFormGroup
                        obj={form}
                        objName="form"
                        onChange={(o, f, v) => onFormInputChange(f, v)}
                        field="name"
                        title="Form Name *"
                    />
                    <StandardFormGroup
                        obj={form}
                        objName="form"
                        onChange={(o, f, v) => onFormInputChange(f, v)}
                        type="number"
                        field="estimated_time"
                        title={(
                            <>
                                Estimated Minutes To Complete
                                <i className="fas fa-info-circle text-info-original ml-2 cursor-pointer" id="tooltipFormSaveModal" />
                                <UncontrolledPopover placement="top" target="tooltipFormSaveModal" >
                                    <PopoverBody>
                                        Don't over think this. The estimated time to complete the form has been proven to increase submission rates when shown to contacts upon the start of an online form.
                                    </PopoverBody>
                                </UncontrolledPopover>
                            </>
                        )}
                    />

                    {errors ? (
                        <div className="alert alert-danger">You must add a form name before continuing.</div>
                    ): null}

                </div>

                <div className="modal-footer text-right">
                    <button className="btn btn-success" onClick={onSave}>
                        Save Form
                    </button>
                </div>

            </Modal>

            </>
        );
    }
}

TemplatesCrudSaveModal.propTypes = {

    form:               PropTypes.object.isRequired,
    errors:             PropTypes.bool.isRequired,

    toggleSaveModal:    PropTypes.func.isRequired,
    onFormInputChange:  PropTypes.func.isRequired,
    onSave:             PropTypes.func.isRequired,

}

export default TemplatesCrudSaveModal
