import React from 'react';
import { Dropdown, Item, StandAlone } from './_components';

import * as privileges from '_functions/users/privileges'
import store from 'store'

const kev = '63345497404415304760ff6b'
const mceldowney = '63b4961769bd1d7e6de1ba74'
const john = '6300daa78f12344b4d635d8d'



const SidebarCore = (props) => {
    const viewing_user = store.getState().auth.viewing_user

    return (
        <ul className="mb-0">
    
            {privileges.canModerateStaff() ? (
                <Dropdown route="/core/users" name="Staff" icon="fas fa-users" {...props}>
                    <Item  route="/core/users/all" name="View All" {...props}/>
                    <Item route="/core/users/create" name="Create" {...props} />
                    <Item route="/core/users/times" name="Times" {...props} />
                </Dropdown>
            ) : null}
    
            {privileges.canModerateCaseTemplates() ? (
                <Dropdown route="/core/cases" name="Cases" icon="fas fa-gavel" {...props}>
                    <Item  route="/core/cases/alert_templates/all" name="Alert Templates" {...props}/>
                </Dropdown>
            ) : null }
    
            {privileges.canModerateAppointmentTemplates() ? (
                <StandAlone route="/core/appointments/templates" name="Appointments" icon="fas fa-calendar" {...props} />
            ) : null }
    
            {/* {privileges.canModerateForms() ? (
                <StandAlone route="/core/questionnaires" name="Questionnaires" icon="fab fa-wpforms" {...props} />
            ) : null } */}
    
            {privileges.canModerateWorkflows() ? (
                <StandAlone route="/core/workflows/all" name="Workflows" icon="fas fa-code-branch" {...props} />
            ) : null }
    
            {privileges.canModerateMissingDocs() || privileges.canModerateTemplateDocs() ? (
                <Dropdown route="/core/documents" name="Documents" icon="fas fa-file" {...props}>
                    {privileges.canModerateMissingDocs() ? (
                        <Item  route="/core/documents/missing" name="Missing" {...props}/>
                    ) : null }
                    {privileges.canModerateTemplateDocs() ? (
                        <>
                        {/* <Item route="/core/documents/word" name="Word Docs" {...props} /> */}
                        {/* <Item route="/core/documents/folders" name="Folders" {...props} /> */}
                        <Item route="/core/documents/signing" name="Signing" {...props} />
                        </>
                    ) : null }
                </Dropdown>
            ) : null }
    
            {privileges.canModerateTags() ? (
                <StandAlone route="/core/tags/all" name="Tags" icon="fas fa-tags" {...props} />
            ) : null }
            {privileges.canModerateTags() ? (
                <StandAlone route="/core/call_centers/all" name="Call Centers" icon="fas fa-headset" {...props} />
            ) : null }
            {privileges.canModerateTags() ? (
                <StandAlone route="/core/brokers/all" name="Brokers" icon="fas fa-user" {...props} />
            ) : null }
    
            {/* {privileges.canModerateUserStatuses() ? (
                <StandAlone route="/core/user_statuses/all" name="User Statuses" icon="fas fa-battery-full" {...props} />
            ) : null } */}
    
            {privileges.canModerateLocations() || privileges.canModerateOffices() ? (
                <Dropdown route="/core/locations" name="Locations" icon="fas fa-home" {...props}>
                    {privileges.canModerateLocations() ? (
                        <>
                        <Item route="/core/locations/courts" name="Courts" {...props} />
                        </>
                    ) : null }
                    {privileges.canModerateOffices() ? (
                        <Item route="/core/locations/offices" name="Offices" {...props} />
                    ) : null }
                </Dropdown>
            ) : null}
    
            {privileges.canModerateStaff() ? (
                <Dropdown route="/core/bulk_update" name="Bulk Update" icon="fas fa-edit" {...props}>
                    <Item route="/core/bulk_update/case_users" name="Case Users" {...props} />
                </Dropdown>
            ) : null}
        
            {privileges.canModerateDivisions() ? (
                <StandAlone route="/core/divisions/all" name="Divisions" icon="fas fa-divide" {...props} />
            ) : null }
    
            {privileges.canModerateArchives() ? (
                <StandAlone route="/core/archives" name="Archives" icon="fas fa-archive" {...props} />
            ) : null }
            {privileges.canModerateForms() ? (
                <StandAlone route="/core/wikis" name="Wikis" icon="fas fa-edit" {...props} />
            ) : null }
    
            {viewing_user._id === kev || viewing_user._id === john || viewing_user._id === mceldowney ? (
                <StandAlone route="/core/admin" name="Admin" icon="fas fa-user-secret" {...props} />
            ) : ''}
    
        </ul>
    )
}

export default SidebarCore;
