import { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Modal, FormGroup, Input } from "reactstrap";
import ReactQuill from "react-quill";

import Circle from 'components/markup/loading/Circle'
import { toggleAlertBS } from 'store/functions/system/system';

const MassEmail = ({showModal, toggleModal, sendMassEmail, previewMassEmail, filter}) => {

    const [subject, setSubject] = useState('');
    const [body, setBody] = useState('');
    const [loading, setLoading] = useState(false)
    const [count, setCount] = useState(false)
    const [shouldConfirm, setShouldConfirm] = useState(false)

    const onSend = useCallback(async (state) => {
        if(!subject) return;
        if(!body) return;
        toggleAlertBS('info', 'This email has been queued up and will start sending shortly.')
        sendMassEmail(subject, body)
        toggleModal();
    }, [subject, body, sendMassEmail, toggleModal])

    useEffect(() => {
        if(showModal) {
            setLoading(true)
            previewMassEmail(filter, (result) => {
                setCount(typeof result.data === 'number' ? result.data : 'err')
                setLoading(false)
            })
        } else {
            setShouldConfirm(false)
            setBody('')
            setSubject('')
        }
    }, [previewMassEmail, showModal, filter])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">Send Mass Email</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            {!loading && count !== 'err' ? (
                <div className="modal-body bg-secondary border-bottom">
                    <p className="text-sm mb-0">This email will be sent out to {count} cases. Large lists may take a few minutes to finish sending.</p>
                </div>
            ) : null}

            <div className="modal-body">
                {loading ? <Circle /> : count === 'err' ? <p>This email cannot be sent, invalid query parameters</p> : (
                    <div>
                        <FormGroup>
                            <label className="form-control-label">Subject*</label>
                            <Input 
                                type="text"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                            />
                        </FormGroup>

                        <div className="border-bottom">
                        <ReactQuill
                                value={body}
                                onChange={(value) => setBody(value)}
                                theme="snow"
                                modules={{
                                    toolbar: [
                                        ["bold", "italic"],
                                        ["link",],
                                        [
                                            { list: "ordered" },
                                            { list: "bullet" }
                                        ]
                                    ]
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>

            <div className="modal-footer text-right">
                <button disabled={loading} className="btn btn-success" onClick={!shouldConfirm ? () => {
                    setShouldConfirm(true)
                    setTimeout(()=> {
                        setShouldConfirm(false)
                    }, 2500)
                } : onSend}>
                    <i className="fas fa-envelope mr-2" />
                    {shouldConfirm ? 'Click To CONFIRM SEND' : 'Send Email'}
                </button>
            </div>

        </Modal>

    )

}

MassEmail.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}

export default MassEmail
