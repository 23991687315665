import React from "react";
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "./routes";
import stackLogger from 'database/stackLogger';

import { toggleLoader }              from 'store/functions/system/system';

import Sidebar from "./components/Sidebar/index";
import Header from "./components/Header";

import * as privileges from '_functions/users/privileges'
import getInitialRoute from './functions/getInitialRoute';

import load from 'store/functions/load'

class AnalyticsLayout extends React.Component {
    state = {
        loaded: false,
        showSidebar: false,
        lastLocation: null,
    };

    //get routes for this layout
    getRoutes = routes => routes().map((prop, key) => {

        return (
            <Route
                exact path={`${prop.path}`}
                component={prop.component}
                key={key}
            />
        )
    })

    toggleSidebar = (bool) => {

        const value = bool !== undefined ? bool : !this.state.showSidebar;

        this.setState({showSidebar: value})

        if(value) {
            document.body.classList.add('noScroll');
        } else {
            document.body.classList.remove('noScroll');
        }

    }

    componentWillReceiveProps = (nextProps) => {

        // any time the location changes base url scroll to top of the page
        const lastLocation = this.state.lastLocation
        const currentLocation = nextProps.location.pathname

        if(currentLocation !== lastLocation) {
            window.scrollTo({top: 0, left: 0})
        }

        this.setState({lastLocation: currentLocation})
    }

    componentDidCatch = (e) => {

        console.log(e);
        stackLogger(e);

        //don't redirect if we are developing
        if(window.location.hostname !== 'localhost') {
            this.setState({shouldRedirect: `/errors/standard?error=${e}&referrerurl=${window.location.href}`})
        }

    }

    componentWillUnmount = () => {
        document.body.style.minHeight = '100vh';
    }

    componentDidMount = async () => {

        if(window.innerWidth < 1000) {
            document.body.classList.remove('g-sidenav-show')
            document.body.classList.remove('g-sidenav-pinned')
            document.body.classList.add('g-sidenav-hidden')
        }

        document.body.style.minHeight = 0;

        toggleLoader(true);
        
        const { viewing_user } = this.props
        await load.dashboard(`?user_id=${viewing_user._id}&company_id=${viewing_user.company}`);

        toggleLoader(false);
        this.setState({loaded: true})

    }

    render() {

        const { showSidebar } = this.state;

        if(this.state.shouldRedirect) return <Redirect to={this.state.shouldRedirect} />;
        if(!this.state.loaded) return <div/>;

        if(!privileges.canViewAdminDashboard()) return <div className="alert alert-danger m-3 text-center">You are not authorized to view this page.</div>

        return (
            <div className="analytics-layout">

                <Header  toggleSidebar={this.toggleSidebar} />
                <Sidebar toggleSidebar={this.toggleSidebar} showSidebar={showSidebar} />

                <div className="content"  onClick={this.closeSidenav} >

                    <Switch>
                        {this.getRoutes(routes)}
                        <Redirect from="*" to={getInitialRoute()} />
                    </Switch>

                </div>

            </div>

        );
    }
}

const mapStateToProps = state => {
	return {
	    device: state.device,
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(AnalyticsLayout);
