import React from "react";

import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import PropTypes from 'prop-types';

import { Modal, UncontrolledTooltip } from "reactstrap";
import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';
import validator from 'utils/validator';

import _lead_sources from '_functions/lead_sources';
import _brokers from '_functions/brokers';

import { connect } from 'react-redux';
import { validatePhoneNumber } from 'utils/validation';

const required_fields = ['name', 'source', 'division']

class CreateSourceModal extends React.Component {

    state = {
        lead_source: {},
        brokers: []
    }

    onSave = async () => {

        this.setState({duplicateError: false})

        const validateFields = validator.requiredFields('lead_source', required_fields, this)
        const newState = validateFields.state

        if(newState.division === 'false') {
            return this.setState({lead_source: {...newState, divisionState: 'invalid'}})
        }

        let formattedPhone = validatePhoneNumber(newState.source)
        if(formattedPhone) newState.source = formattedPhone

        if(this.props.lead_sources.some(ls => newState.source && ls.active && ls.source.toLowerCase() === newState.source.toLowerCase())) {
            return this.setState({duplicateError: true})
        }

        let default_percent = 0;

        const percentIsValid = (percent) => {
            return percent !== undefined && percent !== null && percent !== '' ? true : false
        }

        if(!percentIsValid(newState.default_percent)) {
            console.log('asdf')
            return toggleAlertBS('info', 'Make sure that the default percent is a number between 0 and 1. Example: 25% = .25');
        }

        default_percent = parseFloat(newState.default_percent)

        if(validateFields.success) {

            toggleStandardLoader(true)

            const created = await _lead_sources.create({...newState, default_percent})

            if(created.data) {
                this.props.onSuccess()
                this.setState({lead_source: {}})
            }

            toggleStandardLoader(false)

        }

    }

    componentWillReceiveProps = (nextProps) => {
        if(this.props.showModal && !nextProps.showModal) {
            this.setState({lead_source: {}})
        }
    }

    componentDidMount = async () => {
        const { data } = await _brokers.find();
        this.setState({brokers: data ? data : []})
    }

    render() {

        const { showModal, toggleModal, divisions } = this.props;
        const { lead_source, duplicateError, brokers } = this.state

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={() => toggleModal()}
                size="md"
            >

                <div className="modal-header">
                    <h5 className="modal-title">Create Lead Source</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => toggleModal()}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">

                    {duplicateError ? 
                        <div className="alert alert-danger">An active lead source currently exists with the same source.</div> 
                    : null}

                    <StandardFormGroup
                        obj={lead_source}
                        objName="lead_source"
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`fState`]: v ? 'valid' : 'invalid'}})}
                        field="name"
                        title="Name"
                    />

                    <StandardFormGroup
                        obj={lead_source}
                        objName='lead_source'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`fState`]: v ? 'valid' : 'invalid'}})}
                        type="select"
                        field="division"
                        title="Source Division"
                    >
                        <option value="false"></option>
                        {divisions.map(division => (
                            <option value={division._id} key={division._id}>{division.name}</option>
                        ))}
                    </StandardFormGroup>

                    <StandardFormGroup
                        obj={lead_source}
                        objName='lead_source'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`fState`]: v ? 'valid' : 'invalid'}})}
                        type="select"
                        field="broker"
                        title="Associated Broker"
                    >
                        <option value="false"></option>
                        {brokers.map(broker => (
                            <option value={broker._id} key={broker._id}>{broker.name}</option>
                        ))}
                    </StandardFormGroup>
                  
                    <StandardFormGroup
                        obj={lead_source}
                        objName='lead_source'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`fState`]: v ? 'valid' : 'invalid'}})}
                        type="select"
                        field="call_center"
                        title="Associated Call Center"
                    >
                        <option value="false"></option>
                        {this.props.call_centers.map(call_center => (
                            <option value={call_center._id} key={call_center._id}>{call_center.name}</option>
                        ))}
                    </StandardFormGroup>

                    <StandardFormGroup
                        obj={lead_source}
                        objName='lead_source'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        type="number"
                        field="default_percent"
                        title={`Default Percent: ${!Number.isNaN(parseFloat(lead_source.default_percent)) ? (parseFloat(lead_source.default_percent) * 100).toFixed(2) + '%' : '0%'}`}    
                    />

                    {/* <StandardFormGroup
                        obj={lead_source}
                        objName="lead_source"
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`fState`]: v ? 'valid' : 'invalid'}})}
                        field="lead_cost"
                        title={(
                            <span>
                                Cost Per Lead
                                <i id="tooltipAmountSpent" className="fas fa-info-circle text-info-original ml-2" />
                                <UncontrolledTooltip delay={0} placement="top" target="tooltipAmountSpent">
                                    This is the amount spent for each lead that gets put into this source.
                                </UncontrolledTooltip>

                            </span>
                        )}
                        type="number"
                    /> */}

                    <StandardFormGroup
                        obj={lead_source}
                        objName="lead_source"
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`fState`]: v ? 'valid' : 'invalid'}})}
                        field="source"
                        title={(
                            <span>
                                Lead Source
                                <i id="tooltipLeadSource" className="fas fa-info-circle text-info-original ml-2" />
                                <UncontrolledTooltip delay={0} placement="top" target="tooltipLeadSource">
                                    This is usually a phone number or a web page URL.
                                </UncontrolledTooltip>

                            </span>
                        )}                    />

                </div>

                <div className="modal-footer text-right">
                    <button className="btn btn-success" onClick={this.onSave}>
                        <i className="fas fa-save mr-2" />
                        Create Lead Source
                    </button>
                </div>

            </Modal>

        );
    }
}

CreateSourceModal.propTypes = {
    onSuccess     : PropTypes.func.isRequired,
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}

const mapStateToProps = state => {
    return {
        lead_sources: state.lead_sources.lead_sources,
        divisions: state.divisions.divisions,
        call_centers: state.call_centers.call_centers,
    };
};

export default connect(mapStateToProps, '')(CreateSourceModal);
