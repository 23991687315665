import React, {useEffect} from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import renderName from 'utils/renderName';

import _analytics from '_functions/analytics'

import A from 'components/markup/links/A'
import QueryCreator from './QueryCreator';

import { secondsToTime } from 'utils/time'

import keys from 'keys';

const QueryLeadSourceEntries = ({lead_sources, system, users, divisions, groupings}) => {

    useEffect(() => {
        document.body.classList.add('analytics-sidebar-small')
        return () => {
            document.body.classList.remove('analytics-sidebar-small')
        }

    }, [])

    return (
        <QueryCreator 
            title="Lead Source Entries"
            // searchPlaceholder="Contact ID"
            hideSearch={true}
            runQuery={async (query) => new Promise (async resolve => {
                if(!query.hasRun) {

                    const date = moment().startOf('day');
                    query.filter.created_at = {
                        $gte: parseInt(date.format('X')),
                        $lt: parseInt(date.format('X')) + 86400,
                    }
                }
                if(query.sortValue) query.sortValue = { time_to_call:  query.sortValue}
                const result = await _analytics.query.leadSourceEntries(query)
                return resolve(result.data)
            })}
            sortOptions={[
                { name: 'Longest Time To Call', value: 'Longest Time To Call' },
                { name: 'Shortest Time To Call', value: 'Shortest Time To Call' }
            ]}
            inputs={[
                { type: 'ArchkDates', field: 'created_at', label: '', defaultValue: new Date() },


                {
                    type: 'ArchkSelectSimple', field: 'lead_source', label: 'Lead Source',
                    data: lead_sources.map(i => { return {value: i._id, text: i.name} })
                },
                {
                    type: 'ArchkSelectSimple', field: 'status_first_call', label: 'Outcome',
                    data: system.SETTINGS.call_center.outcomes.map(i => { return {value: i, text: i} })
                },
                {
                    type: 'ArchkSelectSimple', field: 'first_called_by', label: 'Called By',
                    data: users.map(o => { return {value: o._id, text: renderName(o)} })
                },
                { type: 'ArchkTruthy', subType: 'boolean', field: 'hour_of_day_called', label: 'Called In Business Hours', },
                { type: 'ArchkTruthy', subType: 'boolean', field: 'hour_of_day_created', label: 'Created In Business Hours', },
                { type: 'ArchkTruthy', subType: 'boolean', field: 'remove_other_lead_source', label: 'Hide "Other Lead Source"', },

               
            ]}
            columns={[
                {
                    dataField: "contact",
                    text: "Contact",
                    formatter: (cell, row) => (
                        !row.contact ? 'Contact Not Found' : 
                        <div>
                            <A href={`${keys.APP_URL}/dashboard/contacts/view/${row.contact._id}?nav=6`}>
                                {renderName(row.contact)}
                            </A>
                            <div>{moment.unix(row.created_at).format('MMM Do, YYYY h:mm A')}</div>
                        </div>
                    )
                },
                {
                    dataField: "lead_source",
                    text: "Source",
                    formatter: (cell, row) => (
                        <div>
                            {row.lead_source ? row.lead_source.name : '-'}
                        </div>
                    )
                },
                {
                    dataField: "first_called_by",
                    text: "Called By",
                    formatter: (cell, row) => (
                        <div>
                            {row.first_called_by ? (
                                renderName(row.first_called_by)
                            ) : '-'}
                        </div>
                        
                    )
                },
                {
                    dataField: "status_first_call",
                    text: "Outcome",
                    formatter: (cell, row) => (
                        row.status_first_call
                    ),
                },
                {
                    dataField: "time_till_first_call",
                    text: "Time To Call",
                    formatter: (cell, row) => (
                        row.time_till_first_call ? <div> {secondsToTime(row.time_till_first_call)}</div> : '-'
                    )
                },               

            ]}
        
        />
    )

}



const mapStateToProps = state => {
    return {
        system: state.system,
        users: state.users.users,
        lead_sources: state.lead_sources.lead_sources,
        groupings: state.groupings.groupings.filter(g => g.collection_name === 'template_appointments')
    };
};

export default connect(mapStateToProps, '')(QueryLeadSourceEntries)