import { connect } from 'react-redux';
import { useCallback, useState } from 'react'

import { Modal, FormGroup, Input } from "reactstrap";

import { toggleStandardLoader } from 'store/functions/system/system';
import _flows from '_functions/flows';

const FlowsCreate = ({showModal, toggleModal, onSuccess, divisions}) => {

    const [name, setName] = useState('');
    const [division, setDivision] = useState('');
    const [err, setErr] = useState(false);

    const onCreate = useCallback(async () => {
        if(!name) return setErr('You must name this Flow before it can be created');
        if(!division || division === 'false') return setErr('You must assign this flow to a division before it can be created');
        setErr(false)

        toggleStandardLoader(true);
        const created = await _flows.create({name, division, active: false})
        toggleStandardLoader(false);

        if(created.success) {
            onSuccess(created.data);
            setDivision('')
            setName('')
            toggleModal();
        } else {
            setErr("Something's not right, please try again.")
        }

    }, [name, division, onSuccess, toggleModal])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="sm"
        >

            <div className="modal-header">
                <h5 className="modal-title">Create A Flow</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">

                <FormGroup>
                    <label className="form-control-label">Flow Name*</label>
                    <Input 
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        invalid={err === 'You must name this Flow before it can be created'}
                    />
                </FormGroup>
                
                <FormGroup>
                    <label className="form-control-label">Flow Division*</label>
                    <Input 
                        type="select"
                        value={division}
                        onChange={(e) => setDivision(e.target.value)}
                        invalid={err === 'You must assign this flow to a division before it can be created'}
                    >
                        <option value="false"></option>
                        {divisions.map(d => <option key={d._id} value={d._id}>{d.name}</option>)}
                    </Input>
                </FormGroup>

            </div>

            {err ? (
                <div className="modal-body bg-secondary">
                    <p className="text-sm mb-0 text-warning">{err}</p>
                </div>
            ) : null}

            <div className="modal-footer text-right">
                <button className="btn btn-success" onClick={onCreate}>
                    <i className="fas fa-save mr-2" />
                    Create
                </button>
            </div>

        </Modal>

    )

}

const mapStateToProps = state => {
    return {
        divisions: state.divisions.divisions,
    };
};

export default connect(mapStateToProps, '')(FlowsCreate);
