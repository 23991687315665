import React from 'react';
import { StandAlone } from './_components';

const SidebarPayments = (props) => (
    <ul className="mb-0">
        <StandAlone route="/payments/projections" name="Projections"  {...props} />
        <StandAlone route="/payments/overdue" name="Overdue"  {...props} />
        <StandAlone route="/payments/graphs" name="Graphs"  {...props} />
        <StandAlone route="/payments/daily_reports" name="Daily Reports"  {...props} />
        <StandAlone route="/payments/reports" name="Reports"  {...props} />
    </ul>
)

export default SidebarPayments;
