
// import { formatMoney } from 'utils/currency'


import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const QueryPayments = ({viewing_user}) => {

    if(!viewing_user.lead_source) return <div className="alert alert-warning text-center m-6 mt-8">Account Configuration Not Set Up, Contact Support.</div>

    return (
      <div className='p-3'>
        <Link to="/sources/cases" className="btn btn-success">View Cases</Link>
        <Link to="/sources/consultations" className="btn btn-success">View Consultations</Link>
        <Link to="/sources/leads" className="btn btn-success">View Leads</Link>
      </div>
    )

}



const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};
export default connect(mapStateToProps, '')(QueryPayments);