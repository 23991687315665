// findUnmovedStepsByAppointments

import Dots from 'components/markup/loading/Dots';
import React, { Component } from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Card, CardBody, CardHeader, CardTitle, Container, Row, Col, FormGroup, Input } from 'reactstrap';
import { formatPhone } from 'utils/text';
import _analytics from '_functions/analytics';
import renderName from 'utils/renderName';
import moment from 'moment'
import { connect } from 'react-redux';
import _groupings from '_functions/groupings';
import keys from 'keys';
import A from 'components/markup/links/A';

class AppointmentByCase extends Component {


    state = {
        data: null,
        groupings: [],
        loading: true
    };


    columns = [
        {
            dataField: "contact",
            text: "contact",
            filterValue: (cell, row) => row.contact.given_name + ' ' + row.contact.family_name,
            formatter: (cell, row) => (
                <div>
                    <div className="text-capitalize font-weight-bold text-dark">{renderName(row.contact)}</div>
                    {row.contact.phone ? <div>{formatPhone(row.contact.phone)}</div> : null}
                    {row.contact.email ? <div>{row.contact.email}</div> : null}
                </div>
            )

        },
        {
            dataField: "case",
            text: "Case",
            formatter: (cell, row) => (
                <a href={`${keys.APP_URL}/dashboard/cases/view/${row.case._id}`} target="_blank" rel="noopener noreferrer">
                    <div className="text-capitalize font-weight-bold text-dark">{row.case.name}</div>
                    <div>Step: {row.case.step}</div>
                    <div>Moved On: {row.case.step_moved_date ? moment.unix(row.case.step_moved_date).format('MM/DD/YYYY h:mm A') : '-'}</div>
                </a>
            )
        },
        {
            dataField: "finished_appointment",
            text: "Appointment",
            formatter: (cell, row) => (
                <div>
                    <div className="text-capitalize font-weight-bold text-dark">{row.finished_appointment.name}</div>
                    <div>Scheduled Date: {moment.unix(row.finished_appointment.date).format('MM/DD/YYYY h:mm A')}</div>
                    <div>Finished Date: {moment.unix(row.finished_appointment.finished_on).format('MM/DD/YYYY h:mm A')}</div>
                </div>
            )
        },

    ]

    pagination = paginationFactory({
        page: 1,
        sizePerPage: 25,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: true,
        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        value={currSizePerPage}
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    onFilterChange = (e) => {

        const foundFilter = this.state.groupings.find(g => g._id === e.target.value);

        if(foundFilter) {
            this.fetchData(foundFilter.documents)
        }


    }
    
    fetchData = async (filter) => {
        
        if(filter) {
            filter = { template_appointments: filter}
        }

        const data = await _analytics.cases.findUnmovedStepsByAppointments(filter);
        if(data.data) this.setState({data: data.data, loading: false})

    }

    fetchGroupings = async () => {

        const groupings = await _groupings.find();
        this.setState({groupings: groupings.data})

    }

    componentDidMount = async () => {

        this.fetchData()
        this.fetchGroupings()

    }

    render() {

        const { data, loading, groupings } = this.state

        if(loading) return (
            <div className="py-6 text-center"><h2>Loading Payment Data<Dots /></h2></div>
        )

        return (

            <>
            <Container className="pt-4" fluid>

            <Card className="card-color card-primary">

                <CardHeader>
                    <Row>
                        <Col lg={6}>
                            <CardTitle className="mb-0 float-left">Appointments By Case</CardTitle>
                        </Col>
                        <Col lg={6}>
                            <FormGroup>

                                <Input
                                    type="select"
                                    onChange={this.onFilterChange}
                                >
                                    <option></option>
                                    {groupings.map(g => (
                                        <option value={g._id} key={g._id} >{g.name}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                </CardHeader>

                {data.length ? (

                    <ToolkitProvider
                        data={data}
                        keyField="_id"
                        columns={this.columns}
                        search
                    >
                        {props => (
                            <div className=" table-responsive table-vertical-align">
                                <BootstrapTable
                                    pagination={this.pagination}
                                    {...props.baseProps}
                                    bootstrap4={true}
                                    bordered={false}
                                />
                            </div>
                        )}
                    </ToolkitProvider>

                ) : (

                    <CardBody>
                        <A to={`${keys.APP_URL}/dashboard/users/create`}>There is no data to show.</A>
                    </CardBody>

                )}
                </Card>

            </Container>
            </>

        )

    }

}


const mapStateToProps = state => {
    return {
        template_appointments: state.template_appointments.template_appointments
    };
};

export default connect(mapStateToProps, '')(AppointmentByCase);
