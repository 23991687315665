import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import { connect } from 'react-redux';
import Circle from 'components/markup/loading/Circle';
import { architeckCall } from 'database';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Row, FormGroup, Input } from 'reactstrap';
import { toggleStandardLoader } from 'store/functions/system/system';
import validator from 'utils/validator';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';

class Update extends Component {

    state = {
        loading: true,
        data_types: [],
        data_type: {},
        action: 'create',
        showDivision: this.props.divisions && this.props.divisions.length ? this.props.divisions[0]._id : 'false'
    };

    renderColName = (name) => name.replace(/_/g, ' ')

    onInputChange = (field, value) =>  validator.onInputChange(value, 'data_type', field, this)

    renderHelperMessage = () => {

        const type = this.state.data_type.type;
        let markup;

        if(!type) return null

        if(type === 'text') {
            markup = 'Text fields are great for storing data that has no consistent structure. This type of data field should be used only for data that is to be read by a human. Querying this data in reports and graphs is not recommended and will usually produce un-usable data sets.'
        } else if(type === 'number') {
            markup = 'Number fields are used for storing numerical data that will later be used to query for exact numbers, greater than comparisons and less than comparisons. Examples of a custom data field using a number would be average income. This data can then be used to return documents that have less than X income, more than Y income, and exactly Z income.'
        } else if(type === 'yes/no') {
            markup = 'Yes / No fields are used for excluding documents from a data set. It is a hard answer that can be used in both demographic and psychographic comparisons.'
        } else if(type === 'one of') {
            markup = '"One Of" fields allow you to add a data set with exact answers for staff members to select. This is the preferred method to text data fields when possible as "One Of" CAN be used to very effectively to query data sets. An example of "One Of" fields would family size. Answers to this would be something like: "1", "2, "3-4", "5-6", "7 or more".'
        } else if(type === 'date') {
            markup = 'Date fields allow you to timestamp an entry and then search by filters such as before, after, and exactly on that date.'
        }

        return markup;

    }

    onRemoveAnswer = (value) => {

        let values = [...this.state.data_type.values];
        values = values.filter(val => val !== value);


        this.setState({data_type: {...this.state.data_type, values, error: false}})

    }

    onAddAnswer = () => {

        let { answer, values } = this.state.data_type

        // if no answer do nothing
        if(!answer) return;

        // set values and push the answer
        values = values && values.length ? [...values] : [];
        values.push(answer);

        // set answers to state and clear answer input
        this.setState({data_type: {...this.state.data_type, values, answer: null, error: false}})

    }

    onDelete = async (_id) => {

        const deleted = await architeckCall({
            method: 'delete',
            url: `/api/v1/core/analytics_data_types/delete/${_id}`,
        })

        if(deleted.success) {

            let data_types = [...this.state.data_types]
            data_types[data_types.findIndex(el => el._id === deleted.data._id)] = deleted.data

            this.setState({data_types, data_type: {}, error: false})

        }

    }

    onRestore = async () => {

        const { data_type } = this.state;


        toggleStandardLoader(true)

        const data_types = [...this.state.data_types]

        if(data_type._id) {

            const updated = await architeckCall({
                method: 'patch',
                url: `/api/v1/core/analytics_data_types/update/${data_type._id}`,
                data: {
                    ...data_type,
                    deleted: false
                }
            })


            if(updated.success) {

                data_types[data_types.findIndex(el => el._id === updated.data._id)] = updated.data
                this.setState({data_types, data_type: {}})

            }

        }

        toggleStandardLoader(false)

    }

    onSave = async () => {

        this.setState({error: false})
        const { data_type } = this.state;

        if(!data_type.key || !data_type.type) return this.setState({error: true})
        if(!data_type.division || data_type.division === 'false') return this.setState({error: true})

        if(data_type.type === 'one of') {

            if(data_type.values && !data_type.values.length)  return this.setState({error: true})
            if(!data_type.values) return this.setState({error: true})

        }

        toggleStandardLoader(true)

        const data_types = [...this.state.data_types]

        if(data_type._id) {

            const updated = await architeckCall({
                method: 'patch',
                url: `/api/v1/core/analytics_data_types/update/${data_type._id}`,
                data: {
                    ...data_type,
                    collection_name: this.props.match.params.collection
                }
            })


            if(updated.success) {

                data_types[data_types.findIndex(el => el._id === updated.data._id)] = updated.data
                this.setState({data_types})

            }

        } else {

            const created = await architeckCall({
                method: 'post',
                url: `/api/v1/core/analytics_data_types/create`,
                data: {
                    ...data_type,
                    collection_name: this.props.match.params.collection
                }
            })

            if(created.success) {
                data_types.push(created.data)
                this.setState({data_types, data_type: {}, error: false})
            }

        }

        toggleStandardLoader(false)

    }

    fetchData = async (props) => {

        const data_types = await architeckCall({
            method: 'get',
            url: `/api/v1/core/analytics_data_types/query/?filter=collection_name__${props.match.params.collection}&sort=key__asc`
        })

        if(data_types.success) {
            this.setState({data_types: data_types.data, loading: false})
        }

    }

    shouldShowEntry = (type) => {
        const { showDivision } = this.state;

        // if we are not deleted show only division that match the current filter
        // if deleted is set to 'false' show entries that do not have a division
        if(type.deleted === false) {
            if(type.division === showDivision) return true;
            if(showDivision === 'false' && !type.division) return true;
        }

        return false;
    }

    componentDidMount = async () => {

        this.fetchData(this.props)

    }

    componentWillReceiveProps = (nextProps) => {

        if(this.props.match.params.collection !== nextProps.match.params.collection) {
            this.setState({loading: true})
            this.fetchData(nextProps)
        }

    }

    render() {

        const { divisions } = this.props;
        const { data_types, data_type, loading, showDivision } = this.state

        const collectionName = this.renderColName(this.props.match.params.collection)

        const hasDeletedFields = data_types.find(d => d.deleted);

        const filteredActiveTypes = data_types && data_types.length ? data_types.filter(type => this.shouldShowEntry(type)) : [];

        return (

            <Container fluid>

                <DashHeaderOpen         
                    title={<span><i className="fas fa-headset mr-2 text-success " />Analytics</span>}
                    breadcrumb_1="Data Types"
                    breadcrumb_2={collectionName}
                    actionComponent={(
                        <Link className="btn btn-outline-warning" to="/analytics/data_types/router"><i className="fas fa-arrow-left" /> Back</Link>
                    )}
                />

                <Card>

                    <CardHeader>
                        <CardTitle className="mb-0">Custom Data Types</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <p className="text-sm mb-0">Custom data types allow you to add an unlimted amount of tracking metrics and data fields to contacts, users, cases, etc. By creating a custom data type staff members are able to add values to the fields created and these can then be used in queries during graph creating and report generation.</p>
                    </CardBody>

                </Card>

                <Row>

                    <Col lg={6}>

                        <Card className="card-color card-primary list-hover" >

                            <CardHeader>
                                <CardTitle className="mb-0 text-capitalize">
                                    All {collectionName} Data Types
                                </CardTitle>
                            </CardHeader>

                            <CardHeader>
                                <FormGroup>
                                    <Input
                                        type="select"
                                        value={showDivision}
                                        onChange={(e) => this.setState({showDivision: e.target.value})}
                                    >
                                        <option value="false"></option>
                                        {divisions.map(division => (
                                            <option key={division._id} value={division._id}>Division - {division.name}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </CardHeader>

                            <CardBody className="">

                                {loading ? <Circle /> : (
                                    <ul className="mb-0">

                                        {filteredActiveTypes && filteredActiveTypes.length ? filteredActiveTypes.map(type => (
                                            <li onClick={() => this.setState({data_type: type})} key={type._id} className={data_type._id === type._id ? 'active' : null}>
                                                <p className="text-sm font-weight-bold mb-0">{type.key}</p>
                                                <p className="text-sm mb-0">{type.type}</p>
                                            </li>
                                        )) : (
                                            <li>
                                                <p className="text-sm text-warning font-weight-bold mb-0">No custom fields have been created for this collection and division</p>
                                            </li>
                                        )}

                                    </ul>
                                )}
                            </CardBody>

                        </Card>

                        {hasDeletedFields ? (
                        <Card className="card-color card-danger list-hover" >

                            <CardHeader>
                                <CardTitle className="mb-0 text-capitalize">
                                    Deleted {collectionName} Data Fields
                                </CardTitle>
                            </CardHeader>

                            <CardBody className="">


                                    <ul className="mb-0">

                                        {data_types && data_types.length ? data_types.map(type => (
                                            type.deleted === true ? (
                                            <li onClick={() => this.setState({data_type: type})} key={type._id} className={data_type._id === type._id ? 'active' : null}>
                                                <p className="text-sm font-weight-bold mb-0">{type.key}</p>
                                                <p className="text-sm mb-0">{type.type}</p>
                                            </li>
                                            ) : null
                                        )) : (
                                            <li>
                                                <p className="text-sm text-warning font-weight-bold mb-0">No custom fields have been created for this collection</p>
                                            </li>
                                        )}

                                    </ul>

                            </CardBody>

                        </Card>
                        ) : null}

                    </Col>

                    <Col lg={6} >

                        <Card className={!data_type._id ? "card-color card-success" : "card-color card-warning"}>

                            <CardHeader>
                                <Row>

                                    <Col md={6} >
                                        <CardTitle className="mb-0 text-capitalize">
                                            {!data_type._id ? `Add A New ${collectionName} Data Type` : `Update ${collectionName} Data Type`}
                                        </CardTitle>
                                    </Col>

                                    <Col md={6} className="text-right">
                                        {data_type._id ? (
                                            <button onClick={() => this.setState({data_type: {}})} className="btn btn-success"><i className="fas fa-plus" /> Add New</button>
                                        ) : null}
                                    </Col>

                                </Row>

                            </CardHeader>

                            <CardBody>

                                {data_type.deleted ? <div className="alert alert-danger">This field has previously been deleted.</div> : null}

                                <StandardFormGroup
                                    obj={data_type}
                                    objName="data_type"
                                    onChange={(o, f, v) => this.onInputChange(f, v)}
                                    type="text"
                                    field={'key'}
                                    title={'Date Field Name'}
                                />

                                <StandardFormGroup
                                    obj={data_type}
                                    objName="data_type"
                                    onChange={(o, f, v) => this.onInputChange(f, v)}
                                    type="select"
                                    field={'division'}
                                    title={'Division'}
                                >
                                    <option value="false"></option>
                                    {divisions.map(division => (
                                        <option key={division._id} value={division._id}>{division.name}</option>
                                    ))}
                                </StandardFormGroup>


                                <StandardFormGroup
                                    obj={data_type}
                                    objName="data_type"
                                    onChange={(o, f, v) => this.onInputChange(f, v)}
                                    type="select"
                                    field={'type'}
                                    title={'Type Of Data To Be Collected'}
                                >
                                    <option></option>
                                    <option value="text">Text</option>
                                    <option value="number">Number</option>
                                    <option value="yes/no">Yes / No</option>
                                    <option value="one of">One Of</option>
                                    <option value="date">Date</option>
                                </StandardFormGroup>

                                {data_type.type === 'one of' ? (

                                    <>

                                    <StandardFormGroup
                                        obj={data_type}
                                        objName="data_type"
                                        onChange={(o, f, v) => this.onInputChange(f, v)}
                                        type="text"
                                        field={'answer'}
                                        title={'Possible Answer'}
                                    />

                                    <div className="py-3 text-right">
                                        <button
                                            className={data_type.answer ? "btn btn-success" : "btn btn-outline-success"}
                                            disabled={!data_type.answer}
                                            onClick={this.onAddAnswer}
                                        >
                                            Add Answer
                                        </button>
                                    </div>

                                    <div className="table-responsive bg-secondary">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Answers</th>
                                                    <th className="text-right">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data_type.values && data_type.values.length ? data_type.values.map(val => (
                                                    <tr key={val}>
                                                        <td style={{whiteSpace: 'pre-line'}}>{val}</td>
                                                        <td className="text-right">
                                                            <Badge onClick={() => this.onRemoveAnswer(val)} className="cursor-pointer" color="danger">Remove</Badge>
                                                        </td>
                                                    </tr>
                                                )) : (
                                                    <tr>
                                                        <td className="text-warning font-weight-bold">There are no current answers for this datatype</td>
                                                        <td className="text-right"></td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>

                                    </>

                                ) : null}

                                {data_type.type ? ( <> <hr className="mt-"/>{this.renderHelperMessage()} </> ): null}

                            </CardBody>

                            <CardFooter>

                                {this.state.error ?
                                    <div className="alert alert-danger">
                                        Before saving a data field you must specify the fields name, division, type and have at least 1 answer if the type is "One Of"
                                    </div>
                                : null}

                                {data_type.deleted === true ? (
                                     <div className="text-right">
                                        <button onClick={this.onRestore} className="btn btn-warning">
                                            <i className="fas fa-save mr-2" />
                                            Restore Field
                                        </button>
                                    </div>

                                ) : (
                                    <Row>

                                        <Col xs={6}>

                                            {data_type._id ? (
                                                <button onClick={() => this.onDelete(data_type._id)} className="btn btn-danger">
                                                    <i className="fas fa-exclamation-triangle" /> Delete Field
                                                </button>
                                            ): null}

                                        </Col>

                                        <Col xs={6} className="text-right">
                                            <button onClick={this.onSave} className="btn btn-success">
                                                <i className="fas fa-save mr-2" />
                                                {data_type._id ? 'Update Field' : 'Save Field'}
                                            </button>
                                        </Col>

                                    </Row>
                                )}
                            </CardFooter>

                        </Card>

                    </Col>
                </Row>

            </Container>

        )

    }

}


const mapStateToProps = state => {
    return {
        divisions: state.divisions.divisions
    };
};

export default connect(mapStateToProps, '')(Update);
