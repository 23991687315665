import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardTitle, Col, Container, Row } from 'reactstrap';
import custom_data_collections from '_settings/custom_data_collections';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';

class DataTypesRouter extends Component {

    state = {
        height: 0
    }

    renderColName = (name) => name.replace(/_/g, ' ')

    getCardHeight = () => {

        const windowWidth = window.innerWidth
        const sidebarOffset = 225;

        let height = ((windowWidth - sidebarOffset ) / 3) * (2 / 3)

        if(height > 250) height = 250;
        if(height < 100) height = 100;

        this.setState({height})

    }

    componentDidMount = () => {

        this.getCardHeight()
        window.addEventListener('resize', this.getCardHeight)

    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.getCardHeight)
    }

    render() {

        return (

            <Container fluid>

                <DashHeaderOpen         
                    title={<span><i className="fas fa-headset mr-2 text-success " />Analytics</span>}
                    breadcrumb_1="Data Types"
                    breadcrumb_2="all"
                />

                <Row>
                    {custom_data_collections.map((col, i) => (
                        <Col key={i}  xl={4} lg={4} md={6}>
                            <Link to={`/analytics/${col}/data_types/update`}>
                                <Card className="text-center">

                                    <CardHeader>
                                        <CardTitle className="mb-0">Data Collections</CardTitle>
                                    </CardHeader>

                                    <CardBody style={{display: 'flex', height: this.state.height}}>
                                        <div style={{alignSelf: 'center', width: '100%'}}>
                                        <h2 className="display-3 text-capitalize w-100">{this.renderColName(col)}</h2>
                                        </div>
                                    </CardBody>

                                </Card>
                            </Link>
                        </Col>
                    ))}
                </Row>

            </Container>

        )

    }

}

export default DataTypesRouter;
