

// findUnmovedStepsByAppointments

import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, CardTitle, CardFooter, CardBody, Container, FormGroup, Input, Row, Col } from 'reactstrap';
import _analytics from '_functions/analytics';
import Circle from 'components/markup/loading/Circle';
import Line from '../../../_components/charts/Line';
import UserSearchInput from 'components/system/migrated/UserSearchInput';
import renderName from 'utils/renderName';

import EntryTable from './EntryTable'

import role_types from '_settings/role_types';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';

class UsersHiredLogs extends Component {


    state = {
        data: null,
        groupings: [],
        loading: true,
        noData: false,
        division: null,
        start: moment().startOf('day').format('YYYY-MM-DD'),
        end: moment().endOf('day').format('YYYY-MM-DD'),
        comparisonStart: null,
        comparisonEnd: null,
        showComparison: false,
        queryType: 'total',
        timeFilter: '9to5',
        leadStatus: null,
        error: null,
        user: {},
        entries: {},
        stepMovedBy: {},
        has_recurring_on_day: 'xxx'
    };

    setTimeFilter   = (timeFilter)  => this.setState({timeFilter: timeFilter !== 'null' ? timeFilter : null});
    setLeadStatus   = (leadStatus)  => this.setState({leadStatus: leadStatus !== 'null' ? leadStatus : null});
    setRole         = (role)        => this.setState({role: role !== 'null' ? role : null});

    toggleComparison = () => this.setState({
        showComparison: !this.state.showComparison, 
        comparisonStart: null, 
        comparisonEnd: null
    })

    toggleQueryType = (queryType) => {
        this.setState({ queryType }, () => {
            this.onQuery()
        })
    }

    onQuery = async () => {

        const { start, end, comparisonStart, comparisonEnd, queryType, user, timeFilter, role, stepMovedBy, division, has_recurring_on_day } = this.state;
        if(!start || !end) return;

        const startDate = moment(start).startOf('day').format('X');
        const endDate = moment(end).endOf('day').format('X');
        
        const comparisonStartDate = moment(comparisonStart).startOf('day').format('X');
        const comparisonEndDate = moment(comparisonEnd).endOf('day').format('X');

        let query = `?start=${startDate}&end=${endDate}&queryType=${queryType}`;
        
        if(comparisonStart && comparisonEnd) {
            query += `&comparisonStart=${comparisonStartDate}&comparisonEnd=${comparisonEndDate}`;
        }

        if(user._id) {
            query += `&user=${user._id}`;
        }

        if(timeFilter) {
            query += `&timeFilter=${timeFilter}`;
        }

        if(role) {
            query += `&role=${role}`;
        }
        
        if(stepMovedBy._id) {
            query += `&stepMovedBy=${stepMovedBy._id}`;
        }

        if(division && division !== 'false') {
            query += `&division=${division}`
        }

        if(has_recurring_on_day === 'true') {
            query += `&has_recurring_on_day=true`
        } 

        if(has_recurring_on_day === 'false') {
            query += `&has_recurring_on_day=false`
        } 

        console.log(has_recurring_on_day)
        console.log(query)

        this.setState({loading: true});

        let data = await _analytics.hired_logs.search(query);

        this.setState({loading: false})

        if(data.data) {
            this.setState({data: data, entries: data.entries, loading: false, noData: false, error: false})
        } else {
            this.setState({error: data.message, entries: {}, data: null})
        }

    }

   

    componentDidMount = () => {
        this.onQuery()
    }

    render() {

        const { data, noData, loading, showComparison, start, end, comparisonStart, comparisonEnd, entries, timeFilter, error, user, role, stepMovedBy, division, has_recurring_on_day } = this.state
        const { divisions } = this.props;

        return (

            <Container  fluid>

                <DashHeaderOpen
                    title={<span><i className="fas fa-headset mr-2 text-success " />Analytics</span>}
                    breadcrumb_1="Users"
                    breadcrumb_2="Hired Logs"
                />

                <Card className="card-color card-primary">

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">Client Hires</CardTitle>
                    </CardHeader>

                    <CardHeader>    

                        <FormGroup>
                            <label className="form-control-label">Division</label>
                            <Input 
                                type="select"
                                value={division || 'false'}
                                onChange={(e) => this.setState({division: e.target.value})}
                            >
                                <option value="false">All Divisions (No Filter)</option>
                                {divisions.map((division, i) => (
                                    <option key={i} value={division._id}>{division.name}</option>
                                ))}
                            </Input>
                        </FormGroup>  

                        <FormGroup>
                            <label className="form-control-label">Has Recurring On Day</label>
                            <Input 
                                type="select"
                                value={has_recurring_on_day || 'xxx'}
                                onChange={(e) => this.setState({has_recurring_on_day: e.target.value})}
                            >
                                <option value="xxx">No Filter</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </Input>
                        </FormGroup>                   

                        <Row>
                            <Col md={6}>
                                <FormGroup>

                                    <label className="form-control-label">
                                        {user._id ? (
                                            <span className="text-capitalize">
                                                Selected User: {renderName(user)}{' '}
                                                <i className="fas fa-times ml-2 text-danger cursor-pointer " onClick={() => this.setState({user: {}})} />
                                            </span>
                                        ): 'Select User'}
                                    </label>

                                    <UserSearchInput
                                        hideTitle={true}
                                        value={user ? user : {}}
                                        onSelect={(user) => this.setState({user})}
                                    />

                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <FormGroup>
                                    <label className="form-control-label">Time Filter</label>
                                    <Input 
                                        type="select"
                                        value={timeFilter || 'null'}
                                        onChange={(e) => this.setTimeFilter(e.target.value)}
                                    >
                                        <option value="null">All</option>
                                        <option value="9to5">9 To 5</option>
                                        <option value="9to6">9 To 6</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>

                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Role Type</label>
                                    <Input 
                                        type="select"
                                        value={role || 'null'}
                                        onChange={(e) => this.setRole(e.target.value)}
                                    >
                                        <option value="null">All (No Filter)</option>
                                        {role_types.map((role, i) => (
                                            <option key={i} value={role}>{role}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </Col>

                            <Col lg={6}>
                                <label className="form-control-label">
                                    {stepMovedBy._id ? (
                                        <span className="text-capitalize">
                                            Step Moved By: {renderName(stepMovedBy)}{' '}
                                            <i className="fas fa-times ml-2 text-danger cursor-pointer " onClick={() => this.setState({stepMovedBy: {}})} />
                                        </span>
                                    ): 'Step Moved By'}
                                </label>

                                <UserSearchInput
                                    hideTitle={true}
                                    value={stepMovedBy ? stepMovedBy : {}}
                                    onSelect={(user) => this.setState({stepMovedBy: user})}
                                />
                            </Col>

                        </Row>

                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <label className="form-control-label">Start</label>
                                    <Input 
                                        type="date"
                                        value={start || ''}
                                        onChange={(e) => this.setState({start: e.target.value})}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <label className="form-control-label">End</label>
                                    <Input 
                                        type="date"
                                        value={end || ''}
                                        onChange={(e) => this.setState({end: e.target.value})}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        {showComparison ? (
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <label className="form-control-label">Comparison Start</label>
                                        <Input 
                                            type="date"
                                            value={comparisonStart || ''}
                                            onChange={(e) => this.setState({comparisonStart: e.target.value})}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <label className="form-control-label">Comparison End</label>
                                        <Input 
                                            type="date"
                                            value={comparisonEnd || ''}
                                            onChange={(e) => this.setState({comparisonEnd: e.target.value})}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
    
                        ) : null }

                    </CardHeader>


                    <CardFooter>
                        <Row>
                            <Col xs={6}>
                                <button style={styles.button} className="btn btn-outline-warning" onClick={this.toggleComparison}>Toggle Comparison</button>
                            </Col>
                            <Col xs={6} className="text-right">
                                <button style={styles.button} className="btn btn-success" onClick={this.onQuery}>Get Stats</button>
                            </Col>
                        </Row>
                    </CardFooter>

                </Card>

                {error ? <div className="alert alert-warning">{error}</div> : null}

                <Card className="card-color card-primary">

                    <CardHeader>
                        <CardTitle className="mb-0">
                            Query: Total Hires By Time Frame
                        </CardTitle>
                    </CardHeader>

                    <CardBody>
                        <p className="text-sm mb-0">
                            Showing Analytics from {moment(start).startOf('day').format('MM/DD/YYYY h:mm A')} - {moment(end).endOf('day').format('MM/DD/YYYY h:mm A')}
                        </p>
                        {showComparison && comparisonStart && comparisonEnd ? (
                            <p className="text-sm mb-0">
                                Showing Analytics from {moment(comparisonStart).startOf('day').format('MM/DD/YYYY h:mm A')} - {moment(comparisonEnd).endOf('day').format('MM/DD/YYYY h:mm A')}
                            </p>
                        ) : null}
                    </CardBody>

                </Card>

                {noData ? (
                    <p className="text-sm text-center font-weight-bold text-warning">
                        <i className="fas fa-info-circle mr-2 " /> 
                        No metrics were found for the day given above: {moment(noData).format('MM/DD/YYYY')}
                    </p>
                 ) : null}

                <Row>
                    <Col md={6}>
                        <Card>
                            <CardHeader>
                                <CardTitle className="mb-0">Main Set Total</CardTitle>
                            </CardHeader>
                            <CardBody className="text-center">
                            <h2 className="display-1">{entries.mainSet ? entries.mainSet.length : 0}</h2>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col md={6}>
                        <Card>
                            <CardHeader>
                                <CardTitle className="mb-0">Comparison Set Total</CardTitle>
                            </CardHeader>
                            <CardBody className="text-center">
                            <h2 className="display-1">{entries.comparisonSet ? entries.comparisonSet.length : 0}</h2>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {loading ? <Circle /> : !data ? null : (

                    <>
                  
                    <Line
                         title={'Hired Clients'}
                         data={data.data}
                     />

                    </>

                )}


                <Card>
                    <CardHeader>
                        <CardTitle className="mb-0">Main Set Entries</CardTitle>
                    </CardHeader>
                    <EntryTable data={entries.mainSet ? entries.mainSet : []} />
                </Card>

                <Card>
                    <CardHeader>
                        <CardTitle className="mb-0">Comparison Set Entries</CardTitle>
                    </CardHeader>
                    <EntryTable data={entries.comparisonSet ? entries.comparisonSet : []} />
                </Card>

            </Container>

        )

    }

}

const styles = {
    button: {
        minWidth: 140
    }
}


const mapStateToProps = state => {
    return {
        users: state.users.users,
        divisions: state.divisions.divisions,
    };
};

export default connect(mapStateToProps, '')(UsersHiredLogs);