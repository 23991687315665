/*
Documentation

This file shows all created workflows anf lets add more via a modal

*/

import React, { useEffect, useState } from "react";
import { FormGroup, Input, Modal } from "reactstrap";
import _divisions from '_functions/divisions';

const DivisionsModalCreate = ({showModal, toggleModal, onSuccess}) => {

    const [name, setName] = useState('');
    const [err, setErr] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const onSave = async () => {

        if(!name) return setErr('Division name cannot be blank');

        setIsSaving(true)

        const created = await _divisions.create({name})
        if(created.success) {
            onSuccess(created.data);
            setName('');
        } else {
            setErr('Something went wrong, please try again later.')
        }

        setIsSaving(false)

    }

    const onKeyDown = (e) => {
		if (e.key === 'Enter') {
			document.getElementById("create-division-button").click();
		}
	}

    const focusInput = (tries = 0) => {

        if(tries > 10) return;

        const input = document.getElementById('create-division-name');

        if(input) {
            input.focus();
        } else {
            setTimeout(() => focusInput(tries +1), 100)
        }
    }

    useEffect(() => {
        if(showModal === true) focusInput();
    }, [showModal])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Create Division</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body bg-secondary">
                <p className="text-sm mb-0">Divisions cannot be deleted after they are created. Make sure you intend to create the division and have given it a unique name before proceeding.</p>
            </div>

            <div className="modal-body">

                <FormGroup>
                    <label className="form-control-label">Division Name*</label>
                    <Input 
                        id="create-division-name"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        onKeyDown={onKeyDown}
                    />
                </FormGroup>

            </div>

            {err && (
                <div className="modal-body bg-secondary">
                    <p className="text-sm text-danger font-weight-bold mt-0 mb-0">* {err}</p>
                </div>
            )}


            <div className="modal-footer">
                <button id="create-division-button"  disabled={isSaving}  onClick={onSave}  className="btn  btn-success">
                    Create Division
                </button>
            </div>

        </Modal>
    )

}

export default DivisionsModalCreate;