export default (range) => {

    if(!range) return true

    const start = range.created_at ? range.created_at.$gt : undefined;
    const end = range.created_at ? range.created_at.$lt : undefined;

    if(start === 'undefined') return 'You must specify a start date or time group for your query.'
    if(end === 'undefined') return 'You must specify an end date for your query.'
    if(end < start) return 'The end date selected cannot be before the start date selected.'

    return true

}
