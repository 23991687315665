import PropTypes from 'prop-types';
import React from "react";
import { connect } from 'react-redux';
import ObjectSearchInput from 'components/functional/inputs/ObjectSearchInput'

class QuestionnaireSearchInput extends React.Component {

    state = {
        filter: null,
        showResults: false,
        selected: {},
        dataToShow: []
    }

    onSelect = (selected) => {

        this.setState({selected, filter: '', showResults: false})
        this.props.onSelect(selected)

    }

    onChange = (value) => {

        let data = this.props.questionnaires || [];
        data = [...data]

        if(value && value !== null) {

            let filter = value.toLowerCase()
            this.setState({dataToShow: data.filter(c =>  c.name.toLowerCase().includes(filter))})

        } else {

            this.setState({dataToShow: data })

        }

    }

    componentDidMount = () => {
        const { questionnaires, value } = this.props
        this.setState({ dataToShow: questionnaires, selected: value ? value : {} })
    }

    render() {

        const { dataToShow, selected } = this.state
        const { titleFormatter } = this.props

        return (
            <ObjectSearchInput 
                data={dataToShow}
                onChange={this.onChange}
                onSelect={this.onSelect}
                placeholderFormatter={selected ? selected.name : 'Type To Search'}
                titleFormatter={
                    titleFormatter ? titleFormatter(selected) : 
                        selected ? <span>Selected Questionnaire: <b className="text-success">{selected.name}</b></span> : 
                        'Select Questionnaire'
                }
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        questionnaires: state.questionnaires.questionnaires,
    };
};

QuestionnaireSearchInput.propTypes = {
    onSelect        : PropTypes.func.isRequired,
    titleFormatter  : PropTypes.func,
    wrapperStyles   : PropTypes.object,
    formGroupStyles : PropTypes.object,
    value           : PropTypes.object,
    hideTitle       : PropTypes.bool,
    title           : PropTypes.string,
}

export default connect(mapStateToProps, '')(QuestionnaireSearchInput);
