/*
Documentation

This page shows all of the systems call_centers

*/

import NotificationDelete from 'components/functional/notifications/Delete';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, Container, Row, Col, CardFooter } from "reactstrap";
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import _call_centers from '_functions/call_centers';
import Circle from 'components/markup/loading/Circle'
import { toggleAlertBS } from 'store/functions/system/system';

class CallCenters extends React.Component {

    state = {
        call_center: {},
        callCenterToDelete: null,
        call_centers: null,
    }

    columns = [
        {
            dataField: "name",
            text: "Name",
            headerStyle: {width: 150},
            formatter: (cell, row) => <span onClick={() => this.setState({call_center: row})} className="cursor-pointer text-capitalize font-weight-bold">{row.name}</span>
        },
        {
            dataField: "_id",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <span onClick={() => this.setState({call_center: row})}><i className="cursor-pointer fas fa-edit text-success mr-2"></i></span>
                        <i onClick={() => this.setState({callCenterToDelete: row})} className="cursor-pointer fas fa-trash text-danger"></i>
                    </div>
                )
            }
        },
    ]

    pagination = paginationFactory({
        page: 1,
        sizePerPage: 25,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: true,
        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        value={currSizePerPage}
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    onSwitchToCreate = () => {
        this.setState({call_center: {}})
    }

    onCreate = async () => {

        const { call_center } = this.state
        let default_percent = 0;

        const percentIsValid = (percent) => {
            return percent !== undefined && percent !== null && percent !== '' ? true : false
        }

        if(!percentIsValid(call_center.default_percent)) return toggleAlertBS('info', 'Make sure that the default percent is a number between 0 and 1. Example: 25% = .25');

        default_percent = parseFloat(call_center.default_percent)

        if(!call_center.name) {
            return this.setState({call_center: {nameState: 'invalid'}})
        } else {
            this.setState({loading: true})
        }

        await _call_centers.create({...call_center, default_percent})
        this.fetchCallCenters();
        this.setState({call_center: {}, loading: false})

    }

    onUpdate = async () => {

        const { call_center } = this.state
        let default_percent = 0;

        const percentIsValid = (percent) => {
            return percent !== undefined && percent !== null && percent !== '' ? true : false
        }

        if(!percentIsValid(call_center.default_percent)) return toggleAlertBS('info', 'Make sure that the default percent is a number between 0 and 1. Example: 25% = .25');

        default_percent = parseFloat(call_center.default_percent)

        if(!call_center.name) {
            return this.setState({call_center: {nameState: 'invalid'}})
        } else {
            this.setState({loading: true})
        }

        await _call_centers.update(call_center._id, {name: call_center.name, default_percent})
        this.fetchCallCenters();
        this.setState({call_center: {}, loading: false})

    }

    fetchCallCenters = async () => {
        const { data } = await _call_centers.find();
        this.setState({call_centers: data ? data : []})
    }

    componentDidMount = () => {
        this.fetchCallCenters();
    }

    render() {

        const { call_center, loading, call_centers, callCenterToDelete } = this.state;
        if(!call_centers) return <div className="py-6"><Circle /></div>

        const isUpdating = call_center._id;

        return (
            <>

            <Helmet>
                <title>{`Call Centers (${call_centers.length}) `}</title>
                <meta name="description" content="Call Centers" />
            </Helmet>

            {callCenterToDelete && (
                <NotificationDelete
                    deletionURL={`/api/v1/core/call_centers/${callCenterToDelete._id}`}
                    confirmBtnText="Delete Call Center"
                    title={<span className="text-capitalize">{`Delete Call Center: ${callCenterToDelete.name}`}</span>}
                    text="Are you sure you wish to proceed."
                    onClose={() => this.setState({callCenterToDelete: null})}
                    onSuccess={(object) => {
                        this.fetchCallCenters()
                    } }
                />
            )}

            <Container fluid>

                <DashHeaderOpen
                    title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
                    breadcrumb_1="Call Centers"
                    breadcrumb_2="All"
                />

                <Card className="card-color card-success">

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">Call Centers</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <p className="text-sm">Call Centers allow you to create relationships to leads that have come in.</p>
                    </CardBody>

                </Card>

                <Row>

                    <Col lg={4}>
                        <Card className={isUpdating ?"card-color card-warning" : "card-color card-success"}>

                            <CardHeader>
                                <CardTitle className="mb-0 float-left">
                                    {isUpdating ? 'Update Call Center' : 'Create Call Center' }

                                </CardTitle>
                            </CardHeader>

                            <CardBody>

                                <StandardFormGroup
                                    obj={call_center}
                                    objName="call_center"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="name"
                                    title="Call Center Name"
                                />

                                <StandardFormGroup
                                    obj={call_center}
                                    objName='call_center'
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    type="number"
                                    field="default_percent"
                                    title={`Default Percent: ${!Number.isNaN(parseFloat(call_center.default_percent)) ? (parseFloat(call_center.default_percent) * 100).toFixed(2) + '%' : '0%'}`}    
                                />

                            </CardBody>

                            <CardFooter className="text-right">
                                {isUpdating ? (
                                    <>
                                    <button disabled={loading} className="btn btn-outline-success" onClick={this.onSwitchToCreate}>Back</button>
                                    <button disabled={loading} className="btn btn-success" onClick={this.onUpdate}>Update Call Center</button>
                                    </>
                                ) : (
                                    <button disabled={loading} className="btn btn-success" onClick={this.onCreate}>Create New Call Center</button>
                                )}
                            </CardFooter>

                        </Card>
                    </Col>

                    <Col lg={8}>
                        <Card className="card-color card-primary">

                            <CardHeader>
                                <CardTitle className="mb-0 float-left">All Call Centers</CardTitle>
                            </CardHeader>

                            {call_centers.length ? (

                                <ToolkitProvider
                                    data={call_centers}
                                    keyField="_id"
                                    columns={this.columns}
                                    search
                                >
                                    {props => (
                                        <>
                                        <div className=" table-responsive table-vertical-align table-not-fixed">
                                            <BootstrapTable
                                                pagination={this.pagination}
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                bordered={false}
                                            />
                                        </div>
                                        </>
                                    )}
                                </ToolkitProvider>

                            ) : (

                                <CardBody>
                                    <p className="text-sm mb-0">No Call Centers Have Been Created Yet.</p>
                                </CardBody>

                            )}

                        </Card>
                    </Col>

                </Row>

            </Container>

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        call_centers: state.call_centers,
    };
};

export default connect(mapStateToProps, '')(CallCenters);

