/*
Documentation

example usage:

stateAutomations is an array of populated automations
onChange should update the master object list of automations

keyValue is anything that is unique on the page, cant have two
of these components with the same key value

<AssignAutomations
    keyValue={tag._id}
    onChange={(values) => this.setState({automations: values.automations_ids})}
    stateAutomations={tag.automations ? tag.automations : []}
/>

*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactSelect from 'components/functional/inputs/ReactSelect';
import { FormGroup } from 'reactstrap';

class AssignAutomations extends Component {

    state = {
        automations_ids: [],
        automations: []
    }

    setOptions = () => {

        const selectOptions = []
        let allAutomations = this.props.automations

        if(!allAutomations) return

        allAutomations = [...allAutomations]
        allAutomations.forEach(automation => {

            // dont add in values used in the dropdown
            if(!this.state.automations_ids.includes(automation._id)) {
                selectOptions.push({label:  automation.name, value: automation._id})
            }

        })

        this.setState({selectOptions})

    }

    setAutomationIds = (stateAutomations) => {

        let automations_ids = [];

        if(stateAutomations && stateAutomations.length) {
            stateAutomations.forEach(auto => {
                automations_ids.push(auto._id);
            })
        }

        this.setState({automations_ids})

        return automations_ids

    }

    onRemoveAutomation = (automation_id) => {

        let stateAutomations = this.state.stateAutomations ? this.state.stateAutomations : [];

        stateAutomations = stateAutomations.filter(auto => auto._id !== automation_id)

        const automations_ids = this.setAutomationIds(stateAutomations)

        this.props.onChange({stateAutomations, automations_ids})

        this.setState({stateAutomations}, this.setOptions)

    }

    onAddAutomation = (automation_id) => {

        let stateAutomations = this.state.stateAutomations ? this.state.stateAutomations : [];

        // dont push duplicates
        if(!stateAutomations.find(auto => auto._id === automation_id)) {

            let foundAutomation = this.props.automations.find(auto => auto._id === automation_id)

            if(foundAutomation) stateAutomations.push({_id: foundAutomation._id, name: foundAutomation.name})

            const automations_ids = this.setAutomationIds(stateAutomations)

            this.props.onChange({stateAutomations, automations_ids})

            this.setState({stateAutomations}, this.setOptions)

        }

    }

    componentWillReceiveProps = (nextProps) => {

        if(nextProps.keyValue !== this.props.keyValue) {

            const stateAutomations = nextProps.stateAutomations;

            if(stateAutomations && stateAutomations.length) {

                const automations_ids = this.setAutomationIds(stateAutomations)

                this.props.onChange({stateAutomations, automations_ids})
                this.setState({stateAutomations, automations_ids}, this.setOptions)

            } else {

                this.setState({stateAutomations: [], automations_ids: []}, this.setOptions)

            }

        }

    }

    componentDidMount = () => {

        const stateAutomations = this.props.stateAutomations;

        if(stateAutomations && stateAutomations.length) {

            const automations_ids = this.setAutomationIds(stateAutomations)

            this.props.onChange({stateAutomations, automations_ids})
            this.setState({stateAutomations, automations_ids}, this.setOptions)

        } else {

            this.setState({stateAutomations: [], automations_ids: []}, this.setOptions)

        }

    }


    render() {

        const {  title, descriptionEmpty } = this.props
        const { stateAutomations, selectOptions } = this.state

        return (

            <>

                <FormGroup>
                    <label className="form-control-label">{title ? title : 'Automations'} </label>
                    <ReactSelect
                        value={{}}
                        onChange={(values) => this.onAddAutomation(values.value)}
                        options={selectOptions}
                    />
                </FormGroup>

                {stateAutomations && stateAutomations.length ? stateAutomations.map(auto => (
                    <p className="text-sm p-2 px-3 bg-primary text-white border mb-0" key={auto._id}>
                        <i onClick={() => this.onRemoveAutomation(auto._id)} className="fas fa-trash mr-2 cursor-pointer text-danger" />
                        {auto.name}
                    </p>
                )) : (
                    <p className="text-sm font-weight-bold">{descriptionEmpty ? descriptionEmpty : `No automations have been set for this task.`}</p>
                )}

            </>

        )

    }

}

AssignAutomations.propTypes = {
    keyValue            : PropTypes.string,
    automations         : PropTypes.array,
    onChange            : PropTypes.func.isRequired,
    title               : PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    descriptionEmpty    : PropTypes.string,
}

const mapStateToProps = state => {

    return {
        automations: state.automations.automations,
        analytics_data_types: state.analytics_data_types.analytics_data_types
    };
};

export default connect(mapStateToProps, '')(AssignAutomations);
