
import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';

class ChartsAverage extends Component {

    state ={
        noData: false,
        hidden: false
    }

    render() {

        const { noData, hidden, fullscreen } = this.state
        const { data, title } = this.props

        if(!data) return null

        return (

            <div className={fullscreen ? 'fullscreen graph' : 'graph'}>

                {noData ? ( <div className="no-data"><div className="no-data-content">No Data To Show</div></div> ) : null}

                <Card>

                    <CardHeader>
                        <Row>

                            <Col xs={8} className="align-self-center">
                                <h5 className="h3 mb-0">{title ? title : 'Bar Graph'}</h5>
                            </Col>

                            <Col xs={4} className="text-right align-self-center">
                                {!fullscreen ? (
                                    <span className="toggler" onClick={() => this.setState({hidden: !hidden})}>
                                        {hidden ? ( <i className="fas fa-eye" /> ) : ( <i className="fas fa-eye-slash" />)}
                                    </span>
                                ) : null}

                                <span className="toggler" onClick={() => this.setState({fullscreen: !fullscreen, hidden: false})}>
                                    {fullscreen ? ( <i className="fas fa-compress" /> ) : ( <i className="fas fa-expand" />)}
                                </span>
                            </Col>

                        </Row>
                    </CardHeader>

                    <CardBody className={hidden ? 'hidden' : null}>
                        <div className="chart" style={{display: 'flex'}}>
                            <h2 className="text-center w-100 display-1" style={{alignSelf: 'center'}}>{data}</h2>
                        </div>
                    </CardBody>

                </Card>

            </div>

        )

    }

}

ChartsAverage.propTypes = {
    title: PropTypes.string,
    data: PropTypes.string,
}

export default ChartsAverage;
