/*
Documentation

This file creates the router for the archives page

*/

import React from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import PropTypes from 'prop-types';

class ArchivesRouter extends React.Component {

    state = {
        view: 'router'
    }

    render() {

        const { onChangeView } = this.props

        return (
            <>

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">System Archives</CardTitle>
                </CardHeader>

                <CardBody>
                    <p className="text-sm mb-0">Archives are a record of deleted users, contacts, cases, and courts within the system. By viewing one of these collections you may restore them to an active state at any time.</p>
                </CardBody>

            </Card>

            <Row>

                <Col lg={3}>
                    <Card className="text-center cursor-pointer" onClick={() => onChangeView('users')}>

                        <CardHeader>
                            <CardTitle className="mb-0">Users</CardTitle>
                        </CardHeader>

                        <CardBody>
                            <i className="fas fa-users text-success" style={{fontSize: '3rem'}} />
                        </CardBody>

                    </Card>
                </Col>

                <Col lg={3}>
                    <Card className="text-center cursor-pointer" onClick={() => onChangeView('contacts')}>

                        <CardHeader>
                            <CardTitle className="mb-0">Contacts</CardTitle>
                        </CardHeader>

                        <CardBody>
                            <i className="fas fa-user-friends text-success" style={{fontSize: '3rem'}} />
                        </CardBody>

                    </Card>
                </Col>

                <Col lg={3}>
                    <Card className="text-center cursor-pointer" onClick={() => onChangeView('cases')}>

                        <CardHeader>
                            <CardTitle className="mb-0">Cases</CardTitle>
                        </CardHeader>

                        <CardBody>
                            <i className="fas fa-gavel text-success" style={{fontSize: '3rem'}} />
                        </CardBody>

                    </Card>
                </Col>

                <Col lg={3}>
                    <Card className="text-center cursor-pointer" onClick={() => onChangeView('courts')}>

                        <CardHeader>
                            <CardTitle className="mb-0">Courts</CardTitle>
                        </CardHeader>

                        <CardBody>
                            <i className="fas fa-home text-success" style={{fontSize: '3rem'}} />
                        </CardBody>

                    </Card>
                </Col>

            </Row>

            </>
        );
    }
}

ArchivesRouter.propTypes = {
    onChangeView: PropTypes.func.isRequired
}

export default ArchivesRouter

