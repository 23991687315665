/*
Documentation

This file renders the component to be able to update case_alerts on a case task
these fields are just strings that get created as a case alert when a case moves
into this step

*/

import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import React, { Component } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import PropTypes from 'prop-types'

class StepDrawerCaseAlerts extends Component {

    state = {
        editing_case_alert: {},
        case_alerts: [],
    };

    onAdd = () => {

        const editing_case_alert = this.state.editing_case_alert
        if(!editing_case_alert.value) return;

        const case_alerts = [...this.state.case_alerts]

        case_alerts.push(editing_case_alert)

        this.setState({case_alerts, editing_case_alert: {} })
        this.props.onChange(case_alerts)

    }

    onRemove = (i) => {

        let case_alerts = [...this.state.case_alerts]
        case_alerts.splice(i, 1)

        this.setState({case_alerts})
        this.props.onChange(case_alerts)

    }

    componentWillReceiveProps = (nextProps) => {

        const stepId = this.props.step ? this.props.step._id : null
        const nextStepId = nextProps.step ? nextProps.step._id : null

        if(nextStepId && nextStepId !== stepId) {
            this.setState({case_alerts: nextProps.step.case_alerts, editing_case_alert: {}})
        }

    }

    render() {

        const { editing_case_alert, case_alerts } = this.state

        return (

            <div>

                <StandardFormGroup
                    obj={editing_case_alert}
                    objName='editing_case_alert'
                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                    type="textarea"
                    field="value"
                    title={(
                        <span>
                            <span id="tooltip-case-alerts-info">Step Case Alerts <i className="fas fa-info-circle text-info-original" /></span>
                            <UncontrolledTooltip
                                delay={0}
                                placement="bottom"
                                target="tooltip-case-alerts-info"
                            >
                                Any case alerts created below will automatically be added to a case when this step is moved to.
                            </UncontrolledTooltip>

                        </span>
                    )}
                />

                <div className="text-right mb-3">
                    <button onClick={this.onAdd} className="btn btn-outline-success"><i className="fas fa-plus mr-2" /> Add</button>
                </div>

                {case_alerts.length ? case_alerts.map((alert, i) => (
                    <p className="text-sm p-2 px-3 bg-primary text-white border mb-0" key={i}>
                        <i onClick={() => this.onRemove(i)} className="fas fa-trash mr-2 cursor-pointer text-danger" />
                        {alert.value}
                    </p>
                )) : (
                    <p className="text-sm mb-0">No Case Alerts Have Been Assigned To This Step</p>
                )}

            </div>

        )

    }

}

StepDrawerCaseAlerts.propTypes = {
    onChange    : PropTypes.func.isRequired,
    step        : PropTypes.object,
}

export default StepDrawerCaseAlerts;
