import React, { useState, useCallback, useEffect } from 'react';

import { Card, CardHeader, Col, Container, Row, CardTitle, CardBody, FormGroup, Input } from 'reactstrap';

import { Link } from 'react-router-dom';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import Circle from 'components/markup/loading/Circle';

import _analytics from '_functions/analytics';

import moment from 'moment';


import OverviewBoxes from './OverviewBoxes';

import { formatMoney } from 'utils/currency';

const MasterReport = () => {

    const [loading, setLoading] = useState(false);
    const [allData, setAllData] = useState(null);
    const [start, setStart] = useState(moment().startOf('day').toDate());
    const [err, setErr] = useState(null);
    const [source, setSource] = useState(null);
    const [sources, setSources] = useState(null);
    
    // const [caseTypes, setCaseTypes] = useState({});
    // const [hiredTypes, setHiredTypes] = useState({});
    // const [callLogTypes, setCallLogTypes] = useState({});
    // const [leadSourceTypes, setLeadSourceTypes] = useState({});

    // const onSetHiredTypes = useCallback((logs) => {
    //     let outcomes = {};
    //     logs.forEach(_case => {
    //         if(_case.workflow) {
    //             if(outcomes[_case.workflow.type]) {
    //                 outcomes[_case.workflow.type] += 1;
    //             } else {
    //                 outcomes[_case.workflow.type] = 1;
    //             }
    //         }
          
    //     })

    //     setHiredTypes(outcomes)
    // }, [])
   

    // const onSetCallLogs = useCallback((callLogs) => {
    //     let outcomes = {};
    //     callLogs.forEach(callLog => {
    //         if(callLog.user) {
    //             if(outcomes[callLog.user]) {
    //                 outcomes[callLog.user] += 1;
    //             } else {
    //                 outcomes[callLog.user] = 1;
    //             }
    //         }
    //     })

    //     // sort by most call first
    //     let userArray = []
    //     let finalObj = {}
    //     Object.keys(outcomes).forEach((a, b) => {
    //         if(outcomes[a] < outcomes[b]) {
    //             userArray.push(a)
    //         } else {
    //             userArray.unshift(a)
    //         }
    //     })

    //     userArray.forEach((user) => {
    //         finalObj[user] = outcomes[user]
    //     })
    //     setCallLogTypes(finalObj);
    // }, [])

    // const onSetLeadSourceTypes = useCallback((lead_sources) => {
    //     let outcomes = {};
    //     lead_sources.forEach(source => {
    //         if(source.status) {
    //             if(outcomes[source.status]) {
    //                 outcomes[source.status] += 1;
    //             } else {
    //                 outcomes[source.status] = 1;
    //             }
    //         }
          
    //     })

    //     setLeadSourceTypes(outcomes)
    // }, [])

    const fetchData = useCallback(async (hideLoad) => {

        const startDate = moment(start).format('X')
        const endDate = moment(start).add(1, 'days').format('X')

        if(!hideLoad) setLoading(true)
        const data = await _analytics.leadSourceValue(`?start=${startDate}&end=${endDate}`);
        if(!hideLoad) setLoading(false);

        if(data.data) {
            console.log(data.data)
            setSources(data.data.sources);
            setAllData(data.data.data);
        } else {
            setErr(true)
        }
    }, [start])


    useEffect(() => {
        fetchData()

        const refreshInterval = setInterval(() => {
            fetchData(true);
        }, 30 * 1000)
        return () => {
            clearInterval(refreshInterval)
        }
    }, [fetchData])

    if(err) return (
        <div className="alert alert-danger">Something's not right, please try again.</div>
    )

    if(!allData) return <div className="py-6"><Circle /></div>
    if(loading) return <div className="py-6"><Circle /></div>

    console.log(allData)
    console.log(source)

    const data = source && source !== 'false' ? allData.find(d => d._id === source) : ''

    return (

        <Container className="collections" fluid>

            <DashHeaderOpen
                title={<span><i className="fas fa-dollar-sign mr-2 text-success " /> Master</span>}
                breadcrumb_1="Reports"
                breadcrumb_2="View"

            />

            {sources ? (
                <Card>
                <CardBody>
                    <FormGroup>
                        <label className='form-control-label'>Select Source</label>

                        <Input
                            type="select"
                            value={source}
                            onChange={(e) => setSource(e.target.value)}
                        >
                            <option value="false"></option>
                            {sources.map(s => (
                                <option value={s._id} key={s._id}>{s.name}</option>
                            ))}
                        </Input>
                    </FormGroup>
                </CardBody>
            </Card>
            ) : ''}
    {console.log(data)}
            {!data ? '' : (
                <div>
                      <Row>

<Col lg={6}>

    <Card>
        <CardHeader>
            <CardTitle className="mb-0">Data</CardTitle>       
        </CardHeader>
        <div className="table-responsive">
            <table className="table">
                <thead>
                    <tr>
                        <th>Field</th>
                        <th className="text-right">Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Total Cases / Signed</td>
                        <td className="text-right">
                            {data.total} / {data.totalSigned}
                        </td>
                    </tr>
                    <tr>
                        <td>Close Rate</td>
                        <td className="text-right">{data.closeRate}</td>
                    </tr>
                    <tr>
                        <td>Emailed</td>
                        <td className="text-right">
                            {((data.emailed / data.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                            <span style={{display: 'inline-block', width: 30}}>
                            {data.emailed}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>Now Show</td>
                        <td className="text-right">
                            {((data.noShow / data.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                            <span style={{display: 'inline-block', width: 30}}>
                            {data.noShow}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>Call Scheduled</td>
                        <td className="text-right">
                            {((data.callScheduled / data.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                            <span style={{display: 'inline-block', width: 30}}>
                            {data.callScheduled}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>Call Conducted</td>
                        <td className="text-right">
                            {((data.callConducted / data.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                            <span style={{display: 'inline-block', width: 30}}>
                            {data.callConducted}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>Sent Retainer</td>
                        <td className="text-right">
                            {((data.sentRetainer / data.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                            <span style={{display: 'inline-block', width: 30}}>
                            {data.sentRetainer}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>Signed Retainer</td>
                        <td className="text-right">
                            {((data.signedRetainer / data.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                            <span style={{display: 'inline-block', width: 30}}>
                            {data.signedRetainer}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>Submitted</td>
                        <td className="text-right">
                            {((data.submitted / data.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                            <span style={{display: 'inline-block', width: 30}}>
                            {data.submitted}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>Signature</td>
                        <td className="text-right">
                            {((data.signature / data.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                            <span style={{display: 'inline-block', width: 30}}>
                            {data.signature}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>IRS</td>
                        <td className="text-right">
                            {((data.irs / data.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                            <span style={{display: 'inline-block', width: 30}}>
                            {data.irs}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>Payment</td>
                        <td className="text-right">
                            {((data.payment / data.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                            <span style={{display: 'inline-block', width: 30}}>
                            {data.payment}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>Dead</td>
                        <td className="text-right">
                            {((data.dead / data.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                            <span style={{display: 'inline-block', width: 30}}>
                            {data.dead}
                            </span>
                        </td>
                    </tr>

                   
                </tbody>
            </table>
        </div>
      
    </Card>



   

    {/* <UserCallMetrics 
        startOfDay={start}
        metrics={data.metrics}
    />

    <HiredLogs 
        data={data.hired_logs}
        hiredTypes={hiredTypes}
    /> */}

</Col>

<Col lg={6}>

<Card>
        <CardHeader>
            <CardTitle className="mb-0">Aggregations</CardTitle>       
        </CardHeader>
        <div className="table-responsive">
            <table className="table">
                <thead>
                    <tr>
                        <th>Field</th>
                        <th className="text-right">Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Current Net-Net Fees</td>
                        <td className="text-right">{formatMoney(data.netFees)}</td>
                    </tr>
                    <tr>
                        <td>Anticipated Net-Net Fees</td>
                        <td className="text-right">{formatMoney(data.anticipatedNetFees)}</td>
                    </tr>
                    <tr>
                        <td>Avg Net Fee Per Case</td>
                        <td className="text-right">{formatMoney(data.avgNetPerCase)}</td>
                    </tr>
                    <tr>
                        <td>Avg ERTC</td>
                        <td className="text-right">{formatMoney(data.avgERTC)}</td>
                    </tr>
                    <tr>
                        <td>Acceptable Price / Lead</td>
                        <td className="text-right">{formatMoney(data.acceptablePricePerLead)}</td>
                    </tr>
                    <tr>
                        <td>Marketing Fees</td>
                        <td className="text-right">{formatMoney(data.calculatedTotalFeeMarketing)}</td>
                    </tr>
                    <tr>
                        <td>Gross Fees</td>
                        <td className="text-right">{formatMoney(data.calculatedTotalFeeBiz)}</td>
                    </tr>
                    <tr>
                        <td>Anticipated Gross Fees</td>
                        <td className="text-right">{formatMoney(data.anticipatedGrossFees)}</td>
                    </tr>
                    <tr>
                        <td>Gross Margin</td>
                        <td className="text-right">{data.grossMargin}</td>
                    </tr>
                    <tr>
                        <td>Avg Marketing Fee / Case</td>
                        <td className="text-right">{formatMoney(data.avgMarketingPerCase)}</td>
                    </tr>
                    <tr>
                        <td>Anticipated Marketing Fees</td>
                        <td className="text-right">{formatMoney(data.anticipatedMarketingFees)}</td>
                    </tr>
                    <tr>
                        <td>Avg Gross Fee</td>
                        <td className="text-right">{formatMoney(data.avgGrossFee)}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td className="text-right text-white">-</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </Card>

  

</Col>

</Row>


<OverviewBoxes 
data={data}
/>
                </div>
            )}
          
        </Container>

    )

}

export default MasterReport;