import React from 'react';
import { StandAlone } from './_components';

const SidebarAnalytics = (props) => (
              
    <ul className="mb-0">
        <StandAlone route="/sources/cases" name="Cases" icon="fas fa-gavel" {...props} />
        <StandAlone route="/sources/leads" name="Leads" icon="fas fa-users" {...props} />
    </ul>
)

export default SidebarAnalytics;
