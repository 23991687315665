import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import renderName from 'utils/renderName';

import _analytics from '_functions/analytics'

import A from 'components/markup/links/A'
import QueryCreator from './QueryCreator';

import keys from 'keys';
import Cookies from 'universal-cookie';
import timeByValue from 'utils/timeByValue';
import { secondsToTime } from 'utils/time';
const QueryCalls = ({users, lead_sources, viewing_user}) => {

    useEffect(() => {
        document.body.classList.add('analytics-sidebar-small')
        return () => {
            document.body.classList.remove('analytics-sidebar-small')
        }

    }, [])

    return (
        <QueryCreator 
            title="Consultation Calls"
            hideSearch={true}
            runQuery={async (query, cb) => new Promise (async resolve => {
                // set payments to default to today only at first

                const cookies = new Cookies();
                const val = cookies.get('analytics_query_date');

                if(!query.hasRun) {

                    const date = moment().startOf('day');
                    query.filter.created_at = {
                        $gte: parseInt(date.format('X')),
                        $lt: parseInt(date.format('X')) + 86400,
                    }

                    if(val) {
                        const values = timeByValue(val);
                        if(values.$gte) {
                            query.filter.created_at = values
                        }
                    }
                }
                const result = await _analytics.queryCalls(query)
                return resolve(result.data)
            })}
            inputs={[
                { type: 'ArchkDates', field: 'created_at', label: '', defaultValue: new Date(), isPrimary: true },

                {
                    type: 'ArchkSelectSimple', field: 'user', label: 'Consulting User',
                    data: users.map(u => {
                        return { value: u._id, text: renderName(u) }
                    })
                },
            ]}
            columns={[
                {
                    dataField: "_id",
                    text: "User",
                    formatter: (cell, row) => (
                        <div>
                            <div className="text-capitalize">{renderName(row.user)}</div>
                            <div>{moment.unix(row.created_at).format("MM/DD/YYYY h:mm A")}</div>
                        </div>
                    ),
                },
                {
                    dataField: "contact",
                    text: "Contact",
                    formatter: (cell, row) => (
                        row.contact ? (
                            <div>
                                <div className="text-capitalize"><A href={`${keys.APP_URL}/dashboard/contacts/view/${row.contact._id}`}>{renderName(row.contact)}</A></div>
                                <div className="text-capitalize">{row.contact.phone}</div>
                            </div>
                        ) : '-'
                    ),
                },
                {
                    dataField: "CallDuration",
                    text: "Duraction",
                    formatter: (cell, row) => (
                        <div>
                            <div className="text-capitalize">{secondsToTime(row.CallDuration)}</div>
                        </div>
                    ),
                },
                {
                    dataField: "RecordingUrl",
                    text: "Download",
                    formatter: (cell, row) => (
                        <a className="btn btn-success py-4" href={row.RecordingUrl+ '.mp3?Download=true'}>Download</a>
                    ),
                },
                {
                    dataField: "created_at",
                    text: "Listen",
                    headerStyle: {width: 200},
                    formatter: (cell, row) => (
                        <div>
                            <audio style={{width: '100%'}} controls controlsList="nodownload" src={row.RecordingUrl+ '.mp3?Download=false'}>
                                        Your browser does not support the
                            <code>audio</code> element.
                            </audio>
                        </div>
                    ),
                },


            ]}
        
        />
    )

}



const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
        users: state.users.users,
        lead_sources: state.lead_sources.lead_sources,
    };
};

export default connect(mapStateToProps, '')(QueryCalls);