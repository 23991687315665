import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardTitle, CardBody, Row, Col, CardFooter, FormGroup, Input, Badge } from "reactstrap";
import { connect } from 'react-redux';
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';

import _companies from '_functions/companies';
import EmailValidator from 'email-validator';

const CompanyCallCenter = ({company}) => {

    const [ emailAddress, setEmailAddress ] = useState('');
    const [ emails, setEmails ] = useState([]);
    const [ threshold, setThreshold ] = useState(0);
    const [ err, setErr ] = useState(0);
    
    const [ recordingPurgeDays, setRecordingPurgeDays ] = useState(company.recording_purge_days !== undefined ? company.recording_purge_days : 30);

    const showErr = (err) => {
        setErr(err)
        return setTimeout(() => setErr(false), 2000)
    
    }

    const onAddEmail = () => {

        if(!emailAddress) return;

        if(!EmailValidator.validate(emailAddress)) {
            return showErr(`${emailAddress} is not a valid email address.`)
        }

        const newEmails = [...emails];

        if(newEmails.includes(emailAddress.toLowerCase())) return;

        newEmails.push(emailAddress.toLowerCase());

        setEmails(newEmails);
        setEmailAddress('')

    }

    const onRemoveEmail = (email) => {
        let newEmails = [...emails];
        newEmails = newEmails.filter(e => e !== email);

        setEmails(newEmails);
        setEmailAddress('')
    }

     //on keypress of enter simulate the form being submitted for better UI
	const _handleKeyDown = (e) => {
		if (e.key === 'Enter') {
            onAddEmail()
		}
	}

    const onSave = async () => {

        const incoming_call_alert_threshold = parseInt(threshold);

        if(incoming_call_alert_threshold !== 0 && !emails.length) {
            return showErr(`If incoming call threshold is greater 0 at least on email needs to be specified.`)
        }

        toggleStandardLoader(true);

        const updated = await _companies.update(company._id, {
            incoming_call_alert_threshold: threshold,
            incoming_call_alert_emails: emails,
            recording_purge_days: recordingPurgeDays
        })

        if(updated.success) {
            toggleAlertBS(false, `Your profile has been updated successfully.`)
        }


        toggleStandardLoader(false)
        
    }

    

    useEffect(() => {

        const newCompany = Object.assign({}, company);
        setThreshold(newCompany.incoming_call_alert_threshold || 0 );
        setEmails(newCompany.incoming_call_alert_emails || [] );


    }, [])

    return (

        <>

        <Card>

            <CardHeader>
                <Row>
                    <Col xs={6}>
                        <CardTitle className="mb-0">Call Center</CardTitle>
                    </Col>
                    <Col xs={6} className="text-right">
                        <button onClick={onSave} className="btn btn-success"><i className="fas fa-save mr-2 " />Save</button>
                    </Col>
                </Row>

            </CardHeader>

            {err ? (
                <CardHeader>
                    <p className="text-sm text-danger font-weight-bold mb-0">{err}</p>
                </CardHeader>
            ) : null}

            <CardHeader>
                <FormGroup>
                    <label className="form-control-label">Number of incoming calls from a number per day before an email alert is triggered.</label>
                    <Input 
                        type="select"
                        value={threshold.toString()}
                        onChange={(e) => setThreshold(e.target.value)}
                    >
                        <option value="0">0 (Disabled)</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </Input>
                </FormGroup>
            </CardHeader>

            <CardHeader>
                <FormGroup>
                    <label className="form-control-label">Send email notifications to:</label>
                    <Input 
                        type="text"
                        value={emailAddress}
                        onChange={(e) => setEmailAddress(e.target.value)}
                        onKeyDown={_handleKeyDown}
                    />
                </FormGroup>

                {emails && emails.length ? emails.map((email, i) => (
                    <Badge key={i} color="warning" className="text-sm">
                        {email}
                        <i onClick={() => onRemoveEmail(email)} className="fas fa-times ml-3 text-danger cursor-pointer" />
                    </Badge>
                )) : null}

            </CardHeader>

            <CardFooter className="text-right">
                <button onClick={onAddEmail} className="btn btn-success"><i className="fas fa-plus mr-2 " /> Add</button>
            </CardFooter>

        </Card>

        <Card>
        
            <CardHeader>
                <CardTitle className="mb-0">Call Recordings</CardTitle>
            </CardHeader>
        
            <CardBody>
                <FormGroup>
                    <label className="form-control-label">Amount of time to keep call logs before deleting them.</label>
                    <Input 
                        type="select"
                        value={recordingPurgeDays.toString()}
                        onChange={(e) => setRecordingPurgeDays(parseInt(e.target.value))}
                    >
                        <option value="0">0 (Call Logs Remain Forever)</option>
                        <option value="1">1 Day</option>
                        <option value="2">2 Days</option>
                        <option value="3">3 Days</option>
                        <option value="4">4 Days</option>
                        <option value="5">5 Days</option>
                        <option value="6">6 Days</option>
                        <option value="7">7 Days</option>
                        <option value="14">14 Days</option>
                        <option value="21">21 Days</option>
                        <option value="28">28 Days</option>
                        <option value="30">30 Days</option>
                        <option value="45">45 Days</option>
                        <option value="60">60 Days</option>
                        <option value="90">90 Days</option>
                        <option value="180">180 Days</option>
                        <option value="365">1 Year</option>
                        <option value="730">2 Years</option>
                        <option value="1095">3 Years</option>
                        <option value="1460">4 Years</option>
                        <option value="1825">5 Years</option>
                        <option value="2190">6 Years</option>
                        <option value="2555">7 Years</option>
                    </Input>
                </FormGroup>
            </CardBody>
        
        </Card>

        </>
    )

}


const mapStateToProps = state => {
    return {
        company: state.companies.company,
    };
};

export default connect(mapStateToProps, '')(CompanyCallCenter);



