/*
Documentation

this page shows all users within the system

*/

import NotificationDelete from 'components/functional/notifications/Delete';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ColorCircle from 'components/markup/misc/ColorCircle';
import { formatPhone } from 'utils/text'

import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge, Card, CardBody, CardHeader, CardTitle, Container } from "reactstrap";
import renderName from 'utils/renderName'
import AvatarImage from 'components/functional/images/AvatarImage';
import moment from 'moment';

const { SearchBar } = Search;

class Users extends React.Component {

    state = {
        toDelete: null
    }

    renderDate = (date) => {

        if(!date) return '-'

        if(date > this.state.startOfToday) {
            return moment.unix(date).format('h:mm A')
        } else {
            return moment.unix(date).format('MM/DD/YYYY - h:mm A')
        }

    }

    columns = [
        {
            dataField: "given_name",
            text: "Name",
            filterValue: (cell, row) => row.given_name + ' ' + row.family_name,
            formatter: (cell, row) => (
                <Link  to={`/core/users/view/${row._id}`} className="text-capitalize font-weight-bold">
                    <AvatarImage src={row.avatar} className="mr-2" />
                    {renderName(row)}
                    <ColorCircle color={row.color} />
                </Link>
            )
        },
        {
            dataField: "status",
            text: "Status",
            formatter: (cell, row) => {

                if(row.status === 'online') {
                    return <Badge className="text-sm" color="success">Online</Badge>
                } else {
                    return <Badge className="text-sm" color="danger">Offline</Badge>

                }

            }
        },
        {
            dataField: "email",
            text: "Email",
        },
        {
            dataField: "phone",
            text: "Phone",
            filterValue: (cell, row) => row.phone || formatPhone(row.phone),
            formatter: (cell, row) => formatPhone(row.phone)
        },
        {
            dataField: "call_phone_default",
            text: "Call Setting",
            formatter: (cell, row) => (
                row.call_phone_default === 'browser' ? 
                <span className="font-weight-bold text-success"><i className="display-inline-block fas fa-headset mr-2" style={{width: 20}} />Browser</span> :
                <span className="font-weight-bold text-warning"><i className="display-inline-block fas fa-mobile mr-2"  style={{width: 20}}/>Phone</span>
            )

        },

        {
            dataField: "call_ended_last",
            text: "Call Ended Last",
            formatter: (cell, row) => this.renderDate(row.call_ended_last)
        },
        {
            dataField: "x",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <Link to={`/core/users/view/${row._id}`}><i className="fas fa-edit text-success mr-2"></i></Link>
                        <i onClick={() => this.setState({toDelete: row})} className="fas fa-trash text-danger"></i>
                    </div>
                )
            }
        },
    ]

    pagination = paginationFactory({
        page: 1,
        sizePerPage: 25,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: true,
        totalSize: this.props.users.total,
        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        value={currSizePerPage}
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    render() {

        const users = this.props.users;
        const toDelete = this.state.toDelete;

        return (

            <Container fluid>

                {toDelete && (
                    <NotificationDelete
                        deletionURL={`/api/v1/core/users/delete/${toDelete._id}`}
                        confirmBtnText="Delete Staff"
                        title={`Delete ${toDelete.given_name} ${toDelete.family_name}`}
                        text="Deleting this staff member will remove all data associated with them. Proceed With Caution!"
                        onClose={() => this.setState({toDelete: null})}
                        onSuccess={() => {} }
                    />
                )}

                <Helmet>
                    <title>Staff</title>
                    <meta name="description" content="Staff" />
                </Helmet>

                <DashHeaderOpen
                    title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
                    breadcrumb_1="Staff"
                    breadcrumb_2="All"
                    actionComponent={(
                        <Link  to="/core/users/create" className="btn btn-success"><i className="fas fa-plus mr-2 " />Add New</Link>
                    )}
                />

                <Card >

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">View All</CardTitle>
                    </CardHeader>

                    {users.total ? (

                        <ToolkitProvider
                            data={users.users}
                            keyField="_id"
                            columns={this.columns}
                            search
                        >
                            {props => (
                                <>
                                 <div
                                    id="datatable-basic_filter"
                                    className="dataTables_filter px-4 pb pt-3 pb-2"
                                    style={{width: '100%'}}
                                >
                                    <label>
                                        Search:
                                        <SearchBar
                                            className="form-control-sm"
                                            placeholder="Name, Email, Phone"
                                            {...props.searchProps}
                                        />
                                    </label>
                                </div>
                                <div className=" table-responsive table-vertical-align">
                                    <BootstrapTable
                                        pagination={this.pagination}
                                        {...props.baseProps}
                                        bootstrap4={true}
                                        bordered={false}
                                    />
                                </div>

                                </>
                            )}
                        </ToolkitProvider>

                    ) : (

                        <CardBody>
                            <Link to="/core/users/create">
                                No staff members have been created yet.
                                <b className="text-warning"> Click here to create one.</b>
                            </Link>
                        </CardBody>

                    )}
                </Card>

            </Container>

        );
    }
}

const mapStateToProps = state => {
    return {
        users: state.users
    };
};

export default connect(mapStateToProps, '')(Users);

