
import NotificationDelete from 'components/functional/notifications/Delete';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';

import { Link } from 'react-router-dom';
import { Card, CardHeader, CardBody, CardTitle, Container, Badge  } from "reactstrap";
import Circle from 'components/markup/loading/Circle'

import _emails_parsers from '_functions/email_parsers';


import paginationFactory from "react-bootstrap-table2-paginator";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

const EmailParsersAll = () => {

    const [ parsers, setParsers ] = useState(null);
    const [ parserToDelete, setParserToDelete ] = useState(null);

    useEffect(() => {

        _emails_parsers.find(`?sort=name__asc`).then((data) => {
            if(data.data) setParsers(data.data)
        })

    }, [])

    const onDelete = (deleted) => {

        let state = [...parsers];
        state = state.filter(parser => parser._id !== deleted.data._id);

        setParsers(state);
    }

    const columns = [

        {
            dataField: "name",
            text: "Name",
            formatter: (cell, row) => (
                <Link to={`/automations/email_parsers/view/${row._id}`}>{row.name}</Link>

            )
        },
        {
            dataField: "subject_includes",
            text: "Subject",
            formatter: (cell, row) => (
                row.subject_includes && row.subject_includes.length > 25 ? row.subject_includes.slice(0, 25) + "..." : row.subject_includes
            )
        },
        {
            dataField: "status",
            text: "Status",
            headerStyle: {textAlign: 'center'},
            formatter: (cell, row) => (
                <div className="text-center">
                    {row.active ? (
                        <Badge color="success" className="text-sm" style={{width: 100}}>Active</Badge>
                    ) : (
                        <Badge color="warning" className="text-sm" style={{width: 100}}>Turned OFF</Badge>
                    )}
                </div>
            )
        },
        {
            dataField: "fields",
            text: "Fields",
            headerStyle: {textAlign: 'center'},
            formatter: (cell, row) => (
                <div className="text-center">{row.fields ? row.fields.length : 0}</div>
            )
        },
        {
            dataField: "actions",
            text: "Actions",
            headerStyle: {textAlign: 'center'},
            formatter: (cell, row) => (
                <div className="text-center">{row.actions ? row.actions.length : 0}</div>
            )
        },
        {
            dataField: "logs",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => (
                <div className="text-right">
                    <Link to={`/automations/email_parsers/view/${row._id}`}>
                        <i className="fas fa-edit text-success mr-2 cursor-pointer" />
                    </Link>
                    <i onClick={() => setParserToDelete(row)} className="fas fa-trash text-danger cursor-pointer" />
                </div>
            )
        },
       
    ]

    const pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: false,

        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });


    return (

        <Container fluid>

            <Helmet>
                <title>{`Email Parsers`}</title>
                <meta name="description" content="Email Parsers" />
            </Helmet>

            {parserToDelete && (
                <NotificationDelete
                    deletionURL={`/api/v1/core/email_parsers/delete/${parserToDelete._id}`}
                    confirmBtnText="Delete Contact"
                    title={<span className="text-capitalize">{`Delete ${parserToDelete.name}`}</span>}
                    text="Deleting this document will prevent any one from sending it for signing in the future."
                    onClose={() => setParserToDelete(null) }
                    onSuccess={(deleted) => onDelete(deleted)}
                />
            )}

            <DashHeaderOpen
                title={<span><i className="fas fa-magic mr-2 text-success " /> Automations</span>}
                breadcrumb_1="Email Parsers"
                breadcrumb_2="All"
                actionComponent={(
                    <Link to="/automations/email_parsers/view/new" className="btn btn-success"><i className="fas fa-plus mr-2 " />Create New</Link>
                )}
            />

            <Card className="card-color card-primary">

                <CardHeader>
                    <CardTitle className="mb-0 float-left">All Email Parsers</CardTitle>
                </CardHeader>

                {parsers && parsers.length ? (

                    <ToolkitProvider
                        data={parsers}
                        keyField="_id"
                        columns={columns}
                    >
                        {props => (
                            <div className=" table-responsive table-vertical-align table-not-fixed">
                                <BootstrapTable
                                    pagination={pagination}
                                    {...props.baseProps}
                                    bootstrap4={true}
                                    bordered={false}
                                />
                            </div>
                        )}
                    </ToolkitProvider>

                ) : (
                    parsers ? (
                        <CardBody>
                            No Email Parsers Have Been Created
                        </CardBody>
                    ) : (
                        <Circle />
                    )
                )}

            </Card>
            
        </Container>

    );
}

export default EmailParsersAll