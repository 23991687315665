import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import { architeckCall } from 'database';
import moment from 'moment';
import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import ReactJson from 'react-json-view';
import { Card, CardBody, CardHeader, CardTitle, Container } from "reactstrap";
import Circle from 'components/markup/loading/Circle'

import renderName from 'utils/renderName';
import { Link } from 'react-router-dom';

const Entry = ({row}) => { 

    const [show, setShow ] = useState(false)

    return (
        <div>
            
            <div onClick={() => setShow(!show)} className="cursor-pointer">
                {moment.unix(row.date).format('MM/DD/YY - h:mm A')} <b className="font-weight-bold text-dark">{row.request.url}</b> 
                {' - '}
                {row.user ? renderName(row.user) : 'Unknown User'}
            </div>

            {show ? (
                <div>
                    <ReactJson
                        name={false}
                        src={row}
                    />
                </div>
            ) : false}
            
        </div>
    )
}

class Logs extends React.Component {

    state = {
        logToDelete: null,
        loaded: false
    }

    columns = [

        {
            dataField: "date",
            text: "Logs",

            formatter: (cell, row) => <Entry row={row} />
        },
       
    ]

    pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: false,

        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    fetchData = async () => {

        const logs = await architeckCall({
            method: 'get',
            url: '/api/v1/system/request_logs'
        })

        if(logs.success) {
            this.setState({logs: logs.data, loaded: true})
        }

    }

    componentDidMount = async () => {
        this.fetchData();
    }

    render() {

        const { logs, loaded } = this.state


        if(!loaded) return <div className="pt-6"><Circle /></div>

        return (
            <>

            <Container  fluid>

                <Helmet>
                    <title>Request Logs</title>
                    <meta name="description" content="Request Logs" />
                </Helmet>

                <DashHeaderOpen
                    title={<span><i className="fas fa-code mr-2 text-success " /> Developers</span>}
                    breadcrumb_1="Router"
                    breadcrumb_2="Requests"
                    actionComponent={(
                        <>
                        <Link className="btn btn-outline-warning" to="/developers/router">
                            <i className="fas fa-arrow-left mr-2" /> Back
                        </Link>
                        <button onClick={this.fetchData} className="btn btn-success">Refresh</button>
                        </>
                    )}
                />

                <Card>

                    <CardHeader>
                        <CardTitle className="mb-0">All Request Logs</CardTitle>
                    </CardHeader>

                    {logs && logs.length ? (

                        <ToolkitProvider
                            data={logs}
                            keyField="_id"
                            columns={this.columns}
                        >
                            {props => (
                                <div className=" table-responsive table-parent-bordered table-vertical-align table-not-fixed">
                                    <BootstrapTable
                                        pagination={this.pagination}
                                        {...props.baseProps}
                                        bootstrap4={true}
                                        bordered={false}
                                    />
                                </div>
                            )}
                        </ToolkitProvider>

                    ) : (

                        <CardBody>
                            There are no current request logs
                        </CardBody>
                    )}

                </Card>

            </Container>

            </>
        );
    }
}

export default Logs
