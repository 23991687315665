import React, { useCallback } from 'react';

import { Card, CardHeader, CardTitle, Col, Row, CardFooter } from 'reactstrap';

import A from 'components/markup/links/A'

const Box = ({value, title, text, link, classFunction}) => (
    <Col xl={3} lg={6}>
        <A href={link}>
            <Card className={classFunction ? classFunction(value) : ''}>
                <CardHeader className={classFunction ? classFunction(value) : ''}>
                    <CardTitle className="mb-0">{title}</CardTitle>
                </CardHeader>
                <CardFooter className={classFunction ? classFunction(value) : ''}>
                    {text ? text : value}
                </CardFooter>
            </Card>
        </A>
    </Col>
)

const MasterReport = ({data}) => {


    const getQueueCardClasses = useCallback((num, type) => {
        if(type === 'new') {
            if(num >= 3)  return 'bg-gradient-danger text-white';
            if(num >= 1)  return 'bg-gradient-warning text-white';
        } else if (type === 'queue') {
            if(num >= 5)  return 'bg-gradient-danger text-white';
            if(num >= 1)  return 'bg-gradient-warning text-white';
        } else if (type === 'appointments') {
            if(num >= 20)  return 'bg-gradient-danger text-white';
            if(num >= 10)  return 'bg-gradient-warning text-white';
        } else if (type === 'predicted') {
            if(num >= 30)  return 'bg-gradient-danger text-white';
            if(num >= 15)  return 'bg-gradient-warning text-white';
        }

        return 'bg-gradient-success text-white'
    }, [])

    const getHiredClassNames = useCallback((num) => {
        if(num >= 10)  return 'bg-gradient-success text-white';
        if(num >= 5)  return 'bg-gradient-warning text-white';
        return 'bg-gradient-danger text-white'
    }, [])
    
    const getSentToPrepClassNames = useCallback((num) => {
        if(num >= 7)  return 'bg-gradient-success text-white';
        if(num >= 3)  return 'bg-gradient-warning text-white';
        return 'bg-gradient-danger text-white'
    }, [])
   
    const getFiledClassNames = useCallback((num) => {
        if(num >= 5)  return 'bg-gradient-success text-white';
        if(num >= 2)  return 'bg-gradient-warning text-white';
        return 'bg-gradient-danger text-white'
    }, [])
   
    const getNewCasesClassNames = useCallback((num) => {
        if(num >= 30)  return 'bg-gradient-success text-white';
        if(num >= 15)  return 'bg-gradient-warning text-white';
        return 'bg-gradient-danger text-white'
    }, [])
 
    const getSyncClassNames = useCallback((num) => {
        if(num >= 15)  return 'bg-gradient-danger text-white';
        if(num >= 5)  return 'bg-gradient-warning text-white';
        return 'bg-gradient-success text-white'
    }, [])

    return (
        <>
            {/* <h3>Daily Metrics</h3>
            <Row className="text-center">
                <Box 
                    title="New Cases"
                    link="/analytics/cases/reports"
                    classFunction={getNewCasesClassNames}
                    value={data.cases.cases}
                />
                <Box 
                    title="Hires"
                    link="/analytics/users/hired_logs"
                    classFunction={getHiredClassNames}
                    value={data.hired_logs.length}
                />
                <Box 
                    title="Sent To Prep"
                    link="/analytics/cases/reports?type=sent_for_prep_on"
                    classFunction={getSentToPrepClassNames}
                    value={data.cases.sentForPrep}
                />
                <Box 
                    title="Filed"
                    link="/analytics/cases/reports?type=filed_on"
                    classFunction={getFiledClassNames}
                    value={data.cases.filed}
                />

            </Row> */}

            {/* <h3>Call Center</h3>
            <Row className="text-center">
                <Box 
                    title="New"
                    link="/communication/overview"
                    classFunction={() => getQueueCardClasses(data.queue.newContacts, 'new')}
                    value={data.queue.newContacts}
                /> 
                <Box 
                    title="Queue"
                    link="/communication/overview"
                    classFunction={() => getQueueCardClasses(data.queue.business + data.queue.callQueue, 'queue')}
                    value={data.queue.business + data.queue.callQueue}
                /> 
                <Box 
                    title="Open"
                    link="/communication/overview"
                    classFunction={() => getQueueCardClasses(data.queue.appointments, 'appointments')}
                    value={data.queue.appointments}
                /> 
                <Box 
                    title="Influx"
                    link="/communication/overview"
                    classFunction={() => getQueueCardClasses(data.queue.predicted, 'predicted')}
                    value={data.queue.predicted}
                    text={'+' + data.queue.predicted}
                /> 
            </Row> */}

            {/* <h3>Firm Wide Metrics</h3>
            <Row className="text-center">
                <Box 
                    title="Mistakes"
                    link="/analytics/cases/sync_case_appointment"
                    classFunction={getSyncClassNames}
                    value={data.case_appointment_sync.length}
                /> 
                <Box 
                    title="Overdue"
                    link="/analytics/cases/sync_step_times"
                    classFunction={getSyncClassNames}
                    value={data.sync_case_step_times.length}
                /> 
                <Box 
                    title="Needs Review"
                    link="/analytics/cases/sync_step_times"
                    classFunction={getSyncClassNames}
                    value={data.consultations_overdue}
                /> 
            </Row> */}


            <h3>Pipeline</h3>
            <Row className="text-center">
                <Box 
                    title="Emailed"
                    link="/analytics/cases/step_overview"
                    value={data.cases.emailed}
                /> 
                <Box 
                    title="Call Scheduled"
                    link="/analytics/cases/step_overview"
                    value={data.cases.callScheduled}
                /> 
                <Box 
                    title="Call Conducted"
                    link="/analytics/cases/step_overview"
                    value={data.cases.callConducted}
                /> 
                <Box 
                    title="Sent Retainer"
                    link="/analytics/cases/step_overview"
                    value={data.cases.sentRetainer}
                /> 
                <Box 
                    title="Signed Retainer"
                    link="/analytics/cases/step_overview"
                    value={data.cases.signedRetainer}
                /> 
                 <Box 
                    title="Submitted"
                    link="/analytics/cases/step_overview"
                    value={data.cases.submitted}
                /> 
                <Box 
                    title="Signature"
                    link="/analytics/cases/step_overview"
                    value={data.cases.signature}
                /> 
               
                <Box 
                    title="IRS"
                    link="/analytics/cases/step_overview"
                    value={data.cases.irs}
                /> 
                <Box 
                    title="Payment"
                    link="/analytics/cases/step_overview"
                    value={data.cases.payment}
                /> 
              
            </Row>



        </>
    )

}

export default MasterReport;