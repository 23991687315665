
/*
Documentation

This file shows the hourly_rate and billing_rate inputs for user creation and updating

*/

import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import React from "react";
import PropTypes from 'prop-types';

class UserBilling extends React.Component {

    state = {
        show: false
    }

    toggleShow = () => this.setState({show: !this.state.show});

    render() {

        
        const user = this.props.user;

        if(!this.state.show) {
            return  (
                <div>
                    <button className="btn btn-warning" onClick={this.toggleShow}>Show</button>
                </div>
            )
        }

        return (
            <>

                <StandardFormGroup
                    obj={user}
                    objName="user"
                    onChange={(o, f, v) => this.props.onChange(f, v * 100)}
                    field="hourly_rate"
                    title="Hourly Rate * (Internal)"
                    formatter={(value) => value / 100}
                    type="number"
                />

                <p className="text-sm text-muted mb-0">This is the hourly rate the system uses to calculate this staff members payroll.</p>

                <hr />

                <StandardFormGroup
                    obj={user}
                    objName="user"
                    onChange={(o, f, v) => this.props.onChange(f, v * 100)}
                    field="billable_rate"
                    title="Billable Rate * (External)"
                    formatter={(value) => value / 100}
                    type="number"
                />

                <p className="text-sm text-muted mb-0">This is the hourly rate the system will use to bill clients at for work done by this staff member.</p>

            </>
        );
    }
}

UserBilling.propTypes = {
    user: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default UserBilling
