
import apiWorker from '../apiWorker'

const questionnaires = {
    create: (data)      => apiWorker('post',   `/api/v1/core/questionnaires`, data, true),
    search: (data)      => apiWorker('post',   `/api/v1/core/questionnaires/search`, data, true),
    findById: (_id)     => apiWorker('get',   `/api/v1/core/questionnaires/${_id}`, null, true),
    findActive: ()      => apiWorker('get',   `/api/v1/core/questionnaires/active`, null, true),
    update: (_id, data) => apiWorker('patch',   `/api/v1/core/questionnaires/${_id}`, data, true),
}

export default questionnaires;