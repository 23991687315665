import apiWorker from '../apiWorker'

const workflow_tasks = {

    create: (data)                          => apiWorker('post',   `/api/v1/core/workflow_tasks/create`, data),
    update: (task_id, data)                 => apiWorker('patch',  `/api/v1/core/workflow_tasks/update/${task_id}`, data),
    delete: (task_id,)                      => apiWorker('delete', `/api/v1/core/workflow_tasks/delete/${task_id}`),
    findByWorkflowStep: (workflow_step_id) => apiWorker('get', `/api/v1/core/workflow_tasks/find/step/${workflow_step_id}`),

}

export default workflow_tasks;