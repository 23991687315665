/*
Documentation

This page shows all of the systems tags

*/

import NotificationDelete from 'components/functional/notifications/Delete';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, Container, Row, Col, CardFooter } from "reactstrap";
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import _tags from '_functions/tags';
import AssignAutomations from 'views/admin/pages/automations/exports/AssignAutomations'

class Tags extends React.Component {

    state = {
        tag: {},
        tagToDelete: null,
    }

    columns = [
        {
            dataField: "name",
            text: "Name",
            headerStyle: {width: 150},
            formatter: (cell, row) => <span onClick={() => this.setState({tag: row})} className="cursor-pointer text-capitalize font-weight-bold">{row.name}</span>
        },
        {
            dataField: "_id",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <span onClick={() => this.setState({tag: row})}><i className="cursor-pointer fas fa-edit text-success mr-2"></i></span>
                        <i onClick={() => this.setState({tagToDelete: row})} className="cursor-pointer fas fa-trash text-danger"></i>
                    </div>
                )
            }
        },
    ]

    pagination = paginationFactory({
        page: 1,
        sizePerPage: 25,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: true,
        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        value={currSizePerPage}
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    onSwitchToCreate = () => {
        this.setState({tag: {}})
    }

    onCreate = async () => {

        const { tag } = this.state

        if(!tag.name) {
            return this.setState({tag: {nameState: 'invalid'}})
        } else {
            this.setState({loading: true})
        }

        await _tags.create({...tag, automations: this.state.automations})

        this.setState({tag: {}, loading: false})

    }

    onUpdate = async () => {

        const { tag } = this.state

        if(!tag.name) {
            return this.setState({tag: {nameState: 'invalid'}})
        } else {
            this.setState({loading: true})
        }

        await _tags.update(tag._id, {name: tag.name, automations: this.state.automations})

        this.setState({tag: {}, loading: false})

    }

    render() {

        const { tags } = this.props;
        const tagLength = this.props.tags.tags.length;
        const tagToDelete = this.state.tagToDelete;

        const { tag, loading } = this.state;

        const isUpdating = tag._id;

        return (
            <>

            <Helmet>
                <title>{`Tags (${tagLength}) `}</title>
                <meta name="description" content="Tags" />
            </Helmet>

            {tagToDelete && (
                <NotificationDelete
                    deletionURL={`/api/v1/core/tags/delete/${tagToDelete._id}`}
                    confirmBtnText="Delete Tag"
                    title={<span className="text-capitalize">{`Delete Tag: ${tagToDelete.name}`}</span>}
                    text="Deleting this tag will remove it from all current and future cases and will destroy the ability to search for cases by the tag's name. Note that creating a tag with the same name will NOT hold the same data as the old tag."
                    onClose={() => this.setState({tagToDelete: null})}
                    onSuccess={(object) => { } }
                />
            )}

            <Container fluid>

                <DashHeaderOpen
                    title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
                    breadcrumb_1="Tags"
                    breadcrumb_2="All"
                />

                <Card className="card-color card-success">

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">Tags</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <p className="text-sm">Tags allow you to tag cases with important information and give advanced flexibility when searching cases. Tags appear at the top of any case when viewed allowing staff members to quickly view critical information on that case. Note that changing a case tag name will change it across all cases that the tag is associated to and deleting one will remove it from all active cases and destroy they ability to query cases by that tag in the future.</p>
                    </CardBody>

                </Card>

                <Row>

                    <Col lg={4}>
                        <Card className={isUpdating ?"card-color card-warning" : "card-color card-success"}>

                            <CardHeader>
                                <CardTitle className="mb-0 float-left">
                                    {isUpdating ? 'Update Tag' : 'Create Tag' }

                                </CardTitle>
                            </CardHeader>

                            <CardBody>

                                <StandardFormGroup
                                    obj={tag}
                                    objName="tag"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="name"
                                    title="Tag Name"
                                />

                                <AssignAutomations
                                    keyValue={tag._id}
                                    onChange={(values) => this.setState({automations: values.automations_ids})}
                                    stateAutomations={tag.automations ? tag.automations : []}
                                />

                            </CardBody>

                            <CardFooter className="text-right">
                                {isUpdating ? (
                                    <>
                                    <button disabled={loading} className="btn btn-outline-success" onClick={this.onSwitchToCreate}>Create New Tag</button>
                                    <button disabled={loading} className="btn btn-success" onClick={this.onUpdate}>Update Tag</button>
                                    </>
                                ) : (
                                    <button disabled={loading} className="btn btn-success" onClick={this.onCreate}>Create New Tag</button>
                                )}
                            </CardFooter>

                        </Card>
                    </Col>

                    <Col lg={8}>
                        <Card className="card-color card-primary">

                            <CardHeader>
                                <CardTitle className="mb-0 float-left">All Tags</CardTitle>
                            </CardHeader>

                            {tags.total ? (

                                <ToolkitProvider
                                    data={tags.tags}
                                    keyField="_id"
                                    columns={this.columns}
                                    search
                                >
                                    {props => (
                                        <>
                                        <div className=" table-responsive table-vertical-align table-not-fixed">
                                            <BootstrapTable
                                                pagination={this.pagination}
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                bordered={false}
                                            />
                                        </div>
                                        </>
                                    )}
                                </ToolkitProvider>

                            ) : (

                                <CardBody>
                                    <p className="text-sm mb-0">No Tags Have Been Created Yet.</p>
                                </CardBody>

                            )}

                        </Card>
                    </Col>

                </Row>

            </Container>

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        tags: state.tags,
    };
};

export default connect(mapStateToProps, '')(Tags);

