
// findUnmovedStepsByAppointments

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, Container, FormGroup, Input } from 'reactstrap';
import renderName from 'utils/renderName';
import _analytics from '_functions/analytics';

class HiredToday extends Component {


    state = {
        data: null,
        groupings: [],
        loading: true
    };

    onQuery = async () => {

        const { date } = this.state;
        if(!date) return;

        const data = await _analytics.users.allStatsToday({
            start: parseInt(moment(date).startOf('day').format('X')), 
            end: parseInt(moment(date).endOf('day').format('X')), 
        });

        if(data.data) this.setState({data: data.data, loading: false})

    }

    render() {

        const { data } = this.state

        return (

            <>
            <Container fluid>

                <DashHeaderOpen
                    title={<span><i className="fas fa-headset mr-2 text-success " />Analytics</span>}
                    breadcrumb_1="Users"
                    breadcrumb_2="All Stats"
                />

                <Card className="card-color card-primary">

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">User Stats</CardTitle>
                    </CardHeader>

                    <CardHeader>

                        <FormGroup>
                            <label className="form-control-label">Date</label>
                            <Input 
                                type="date"
                                value={this.state.date || ''}
                                onChange={(e) => this.setState({date: e.target.value})}
                            />
                        </FormGroup>

                        <hr />
                        <div className="text-right">
                            <button className="btn btn-success" onClick={this.onQuery}>Get Stats</button>
                        </div>
                    </CardHeader>

                    <CardBody>
                        {!data ? 'There is no data to show.' : (
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>User</th>
                                            <th>Appointments Created</th>
                                            <th>Appointments Finished</th>
                                            <th>Call Logs Recorded</th>
                                            <th>Documents Uploaded</th>
                                            <th>Contact Notes Made</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map(d => (
                                            <tr>
                                                <td className="text-capitalize">{renderName(this.props.users.find(u => u._id === d.user_id))}</td>
                                                <td>{d.appointments.length}</td>
                                                <td>{d.finished_appointments.length}</td>
                                                <td>{d.call_logs.length}</td>
                                                <td>{d.documents.length}</td>
                                                <td>{d.contact_notes.length}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </CardBody>

                </Card>

            </Container>
            </>

        )

    }

}




const mapStateToProps = state => {
    return {
        users: state.users.users
    };
};

export default connect(mapStateToProps, '')(HiredToday);