import PropTypes from 'prop-types';
import React from "react";
import { Col, FormGroup } from "reactstrap";
import ContactSearchInput from 'components/system/migrated/ContactSearchInput';
import renderName from 'utils/renderName';
import { formatPhone } from 'utils/text'

class AppointmentsCrudContact extends React.Component {

    hasError = () => {

        const { associationError, appointment } = this.props;

        if(associationError) {

            if(!appointment.contact) return true
            if(appointment.contact && !appointment.contact._id) return true;

        }

        return false

    }

    render() {

        const { appointment, contactRequired, onAssignContact, onRemoveContact, contactNoPhoneError } = this.props
        const contact = appointment.contact;

        if(!contactRequired()) return null

        return (

            <Col lg={6} className="mb-4">

                <div className="bg-secondary p-1">
                    <FormGroup className="p-2">
                        <h4>Associate Contact</h4>
                        <ContactSearchInput
                            value={appointment.contact ? appointment.contact : {}}
                            onSelect={(contact) => onAssignContact(contact)}
                        />
                    </FormGroup>

                    {this.hasError() ? (
                        <div className="alert alert-danger">You must select a contact before proceeding.</div>
                    ) : null}

                    {contactNoPhoneError ? (
                        <div className="alert alert-danger">The contact you have selected does not have a valid phone number and cannot be selected for a call center appointment.</div>
                    ) : null}

                </div>

                <div  className="table-responsive table-no-margin bg-secondary mt--4">
                    <table className="table">

                        <thead>
                            <tr className="font-weight-bold">
                                <th>Contacts</th>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            {contact ?(
                                <tr key={contact._id}>
                                    <td>
                                        <div className="text-capitalize">{renderName(contact)}</div>
                                        {contact && contact.phone ? <div>{formatPhone(contact.phone)}</div> : null}
                                        {contact && contact.email ? <div>{contact.email}</div> : null}
                                    </td>
                                    <td className="text-right">
                                        <i className="fas fa-trash text-danger cursor-pointer" onClick={() => onRemoveContact()} />
                                    </td>
                                </tr>
                            ): (
                                <tr>
                                    <td className="text-warning font-weight-bold"><i className="fas fa-exclamation-triangle mr-2 " /> No Contact Assigned</td>
                                    <td></td>
                                </tr>

                            )}
                        </tbody>

                    </table>
                </div>

            </Col>

        )

    }
}

AppointmentsCrudContact.propTypes = {
    contactRequired   : PropTypes.func.isRequired,
    onAssignContact   : PropTypes.func.isRequired,
    onRemoveContact   : PropTypes.func.isRequired,
    appointment               : PropTypes.object.isRequired,
}

export default AppointmentsCrudContact
