/*
Documentation

*/

import React from "react";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Card, CardHeader, CardBody, CardTitle, CardFooter, FormGroup, Input } from "reactstrap";
import Circle from 'components/markup/loading/Circle'

import _signing from '_functions/signing'

import moment from 'moment'

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';

import timeByValue from 'utils/timeByValue';

import Pie from '../../_components/charts/Pie'
import Line from '../../_components/charts/Line'
import Bar from '../../_components/charts/Bar'

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const day = 86400;

class SigningOverview extends React.Component {

    state = {
        data          : null,
        lead_source   : null,
        showLine: cookies.get('analytics_showLine') === 'true' ? true : false,
        query         : {
            start: parseInt(moment().startOf('week').format('X')),
            end: parseInt(moment().endOf('week').format('X')),
            range: 'This Week',
        },
    }

    onSetQuery = (start, end, range) => {
        this.setState({query: { start, end, range }}, () => {
            this.fetchLeadSource()
        })
    }
    onSetLeadSource = (lead_source) => {
        this.setState({lead_source: lead_source === 'false' ? undefined : lead_source}, () => {
            this.fetchLeadSource()
        })
    }

    fetchLeadSource = async () => {
        this.setState({loading: true})

        const start = this.state.query.start;
        const end = this.state.query.end;
        let label;
        let cLabel;

        let cStart;
        let cEnd;

        const daysInMonth = moment.unix(start).daysInMonth();

        switch (this.state.query.range) {
            case 'Today':
                label = 'Today'
                cLabel = 'Yesterday'
                cStart = start - day
                cEnd = end - day
                break;
            case 'Yesterday':
                label = 'Yesterday'
                cLabel = '2 Days Ago'
                cStart = start - (day * 2)
                cEnd = end - (day * 2)
                break;
            case 'This Week':
                label = 'This Week'
                cLabel = 'Last Week'
                cStart = start - (day * 7)
                cEnd = end - (day * 7)
                break;
            case 'Last Week':
                label = 'Last Week'
                cLabel = '2 Weeks ago'
                cStart = start - (day * 14)
                cEnd = end - (day * 14)
                break;
            case 'This Month':
                label = 'This Month'
                cLabel = 'Last Month'
                cStart = start - (day * daysInMonth)
                cEnd = end - (day * daysInMonth)
                break;
            case 'Last Month':
                label = 'Last Month'
                cLabel = 'Two Months Ago'
                cStart = start - (day * daysInMonth)
                cEnd = end - (day * daysInMonth)
                break;
            case 'This Quarter':
                label = 'This Quarter'
                cLabel = 'Last Quarter'
                cStart = start - (end - start)
                cEnd = end - (end - start)
                break;
            case 'Last Quarter':
                label = 'Last Quarter'
                cLabel = '2 Quarters Ago'
                cStart = start - (end - start)
                cEnd = end - (end - start)
                break;
        
            default:
                label = moment.unix(start).format("MMMM")
                cLabel = moment.unix(start).subtract(15, 'days').format("MMMM")
                cStart = parseInt(moment.unix(start).subtract(15, 'days').startOf('month').format('X'))
                cEnd = parseInt(moment.unix(start).subtract(15, 'days').startOf('month').format('X')) + (day * daysInMonth)
                break;
        }

        const data = await _signing.overview({
            start: this.state.query.start,
            end: this.state.query.end,
            lead_source: this.state.lead_source,
            cStart,
            cEnd,
            label,
            cLabel,
            range: this.state.query.range,
        })
        this.setState({loading: false})
        if(data.success) this.setState({data: data.data})
    }

    getMonthOptions = () => {
        let markup = []

        for (let i = 0; i < 36; i++) {
            const month = moment().subtract(i, 'months').startOf('month');
            markup.push(<option key={i} value={`${month.format('X')}-${month.endOf('month').format('X')}`}>{month.format('MMM - YYYY')}</option>)

        }
        return markup;
    }

    fetchColorsAndBackground = () => {

        const background = [];
        const data = [];
        const labels = [];
        const docs = this.state.data.docs

        const d = [
            { background: '#fcb900', value: docs.opened, label: `Opened (${((docs.opened / docs.total) * 100).toFixed(2)}%)` },
            { background: '#0693e3', value: docs.sigConfirmed, label: `Signature Confirmed (${((docs.sigConfirmed / docs.total) * 100).toFixed(2)}%)`, },
            { background: '#cf2e2e', value: docs.unopened, label: `Unopened (${((docs.unopened / docs.total) * 100).toFixed(2)}%)`, },
            { background: '#A7C81B', value: docs.signed, label: `Signed (${((docs.signed / docs.total) * 100).toFixed(2)}%)`, },
        ]
        d.sort((a, b) => a.value <= b.value ? -1 : 1)

        d.forEach(v => {
            background.push(v.background)
            data.push(v.value)
            labels.push(v.label)
        })

        return { background, data, labels }
    }

    componentDidMount = async () => {
        const val = cookies.get('analytics_query_date');
        if(val) {

            let state = {}

            if(val) {
                const values = timeByValue(val)

                state = {
                    query: {
                        start: values.$gte,
                        end: values.$lt,
                        range: val
                    }
                }
            }


            this.setState(state, () => {
                this.fetchLeadSource()
            })
        } else {
            this.fetchLeadSource()
        }
    }

    render() {

        const { data, query, loading } = this.state;

        if(!data || loading) return <div className="py-6"><Circle /></div>

        const hasQuery = Object.keys(query).length

        const Comp = this.state.showLine ? Line : Bar;

        let breakdown = this.fetchColorsAndBackground()

        return (
            <>

            <Container fluid>

                <Helmet>
                    <title>{`Documents`}</title>
                    <meta name="description" content="Documents" />
                </Helmet>

                <DashHeaderOpen
                    title={<span><i className="fas fa-headset mr-2 text-success " />Analytics</span>}
                    breadcrumb_1="Documents"
                />

                <Card className="">

                    <CardHeader>
                        <Row>
                            <Col md={8} className="align-self-center">
                                <CardTitle className="mb-0">Document Overview</CardTitle>
                            </Col>
                            <Col md={4} className="align-self-center text-right">
                                <button 
                                    onClick={() => {
                                        this.setState({showLine: !this.state.showLine})
                                        cookies.set('analytics_showLine', !this.state.showLine, { path: '/' });
                                    }} 
                                    className="btn btn-outline-info"
                                >
                                    {this.state.showLine ? 'Show As Bar Graph' : 'Show As Line Graph'}
                                </button>
                            </Col>
                        </Row>
                    </CardHeader>

                    <CardBody className="">
                        <Row>
                            <Col lg={6}>
                                <div className="date-picker-wrapper">
                                    <FormGroup>
                                        <label className="form-control-label">Select Time Frame</label>

                                        <Input
                                            type="select"
                                            value={query.range}
                                            onChange={(e) => {
                                                const val = e.target.value;
                                                const values = timeByValue(val)
                                                this.onSetQuery(values.$gte, values.$lt, val)
                                                cookies.set('analytics_query_date', val, { path: '/' });
                                            }}
                                        >
                                            <option></option>
                                            <option value="Today">Today</option>
                                            <option value="Yesterday">Yesterday</option>
                                            <option value="This Week">This Week</option>
                                            <option value="Last Week">Last Week</option>
                                            <option value="This Month">This Month</option>
                                            <option value="Last Month">Last Month</option>
                                            <option value="This Quarter">This Quarter</option>
                                            <option value="Last Quarter">Last Quarter</option>
                                            <option value="Last 90 Days">Last 90 Days</option>
                                            <option value="Last 180 Days">Last 180 Days</option>
                                            <option value="Last 365 Days">Last 365 Days</option>
                                            {this.getMonthOptions()}
                                        </Input>

                                    </FormGroup>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Lead Source</label>
                                    <Input
                                        type="select"
                                        value={this.state.lead_source}
                                        onChange={(e => this.onSetLeadSource(e.target.value)) }
                                    >
                                        <option value="false"></option>
                                        {this.props.lead_sources.map(l => (
                                            <option key={l._id} value={l._id}>{l.name}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                       
                    </CardBody>

                    <CardFooter className="text-right">
                        <button onClick={this.fetchLeadSource} disabled={!hasQuery} className="btn btn-success"><i className="fas fa-sync mr-2" /> Refresh </button>
                    </CardFooter>

                </Card>

                <Row>
                    <Col md={12}>
                        <Pie
                            title={`Document Breakdown`}      
                            classNames="chart-large"                
                            data={{
                                labels: breakdown.labels,
                                datasets: [
                                    { 

                                        backgroundColor: breakdown.background,
                                        data: breakdown.data
                                    },
                                    
                                ]
                            }}
                        />
                    </Col>

                    <Col lg={12}>
                        {console.log(data.all)}
                        <Comp
                            title={`All Docs Sent`}      
                            classNames="chart-time-series"                
                            data={data.all}
                        />
                    </Col>

                    <Col lg={6}>
                        <Comp
                            title={`Signed`}      
                            classNames="chart-time-series"                
                            data={data.signed}
                        />
                    </Col>

                    <Col lg={6}>
                        <Comp
                            title={`Signature Confirmed`}      
                            classNames="chart-time-series"                
                            data={data.sigConfirmed}
                        />
                    </Col>

                    <Col lg={6}>
                        <Comp
                            title={`Opened`}      
                            classNames="chart-time-series"                
                            data={data.opened}
                        />
                    </Col>
                  
                    <Col lg={6}>
                        <Comp
                            title={`Unopened`}      
                            classNames="chart-time-series"                
                            data={data.unopened}
                        />
                    </Col>
                 
                  

                </Row>

            </Container>

            </>
        );
    }
}


const mapStateToProps = state => {
    return {
        lead_sources : state.lead_sources.lead_sources,
    };
};

export default connect(mapStateToProps, '')(SigningOverview);
