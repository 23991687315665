import React from 'react';
import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import { Modal, FormGroup, Input } from "reactstrap";

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

import _analytics from '_functions/analytics';

const ByStepText = ({showModal, toggleModal, data, workflow_step, total_documents}) => {

    const [state, setState] = useState({
        body: ''
    });
    const [errors, setErrors] = useState([]);

    const onSetState = (field, e) => {
        const newState = JSON.parse(JSON.stringify(state));
        const value = e.target.value;
        newState[field] = value;
        setState(newState);
    }

    const onSend = useCallback(async (state) => {

        const errs = [];

        if(!state.body) errs.push('body');
        if(state.body.length > 319) return toggleAlertBS(true, 'Text must be under 320 characters for best deliverability')

        setErrors(errs);

        if(errs.length) return;

        toggleStandardLoader(true);

        const sent = await _analytics.cases.text({workflow_step, body: state.body})
        if(!sent.success) {
            toggleAlertBS(true, `Internal Error`)
        } else {
            toggleAlertBS(false, `Mass Text Success, Sent: ${sent.data.passed}, Failed: ${sent.data.failed}, No Phone: ${sent.data.noPhone}`)
        }

        setState({body: ''})
        toggleModal()

        toggleStandardLoader(false);


    }, [workflow_step, state.body])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">Mass Text</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body bg-secondary">
                <p className="text-sm mb-0">This text will go out to {total_documents ? total_documents : 0} contacts. ***Note: user filters will not affect Mass Texts</p>
            </div>
            <div className="modal-body">

                <FormGroup>
                    <label className="form-control-label">Body*</label>
                    <Input 
                        style={{minHeight: 200}}
                        type="textarea"
                        value={state.body}
                        onChange={(e) => onSetState('body', e)}
                        invalid={errors.includes('body')}
                    />
                </FormGroup>

            </div>

            <div className="modal-footer text-right">
                <button className="btn btn-success" onClick={() => onSend(state)}>
                    <i className="far fa-paper-plane mr-2" />
                    Send
                </button>
            </div>

        </Modal>

    )

}

ByStepText.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}

export default ByStepText
