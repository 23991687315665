/*
Documentation

Creates a router to access system archives

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import { Helmet } from 'react-helmet';
import { Container } from "reactstrap";
import Contacts from './Contacts';
import Router from './Router';
import Users from './Users';
import Cases from './Cases';
import Courts from './Courts';

class Archives extends React.Component {

    state = {
        view: 'router'
    }

    render() {

        const { view } = this.state;

        return (
            <>

            <Container fluid>

                <Helmet>
                    <title>Archives</title>
                    <meta name="description" content="Archives" />
                </Helmet>

                <DashHeaderOpen
                    title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
                    breadcrumb_1="Archives"
                    breadcrumb_2="All"
                    actionComponent={
                        view !== 'router' ? (
                            <button onClick={() => this.setState({view: 'router'})} className="btn btn-outline-warning">
                                <i className="fas fa-arrow-left mr-2" />
                                Back
                            </button>
                        ) : null
                    }
                />

                {view === 'router'   ? ( <Router  onChangeView={(view) =>     this.setState({view})} />) : null}
                {view === 'users'    ? ( <Users/>) : null}
                {view === 'contacts' ? ( <Contacts/>) : null}
                {view === 'cases'    ? ( <Cases/>) : null}
                {view === 'courts'   ? ( <Courts/>) : null}

            </Container>

            </>
        );
    }
}

export default Archives
