/*
Documentation

This page shows a list of all contacts
can search by name, email, phone, or _id

*/

import AvatarImage from 'components/functional/images/AvatarImage';
import NotificationDelete from 'components/functional/notifications/Delete';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import Circle from 'components/markup/loading/Circle';
import NameCircle from 'components/markup/misc/NameCircle';
import moment from 'moment';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import { Card, CardHeader, CardTitle, Container, Input } from "reactstrap";
import * as _ from 'underscore';
import renderName from 'utils/renderName';
import { formatPhone } from 'utils/text';
import _contact_submissions from '_functions/contact_submissions';

class ContactsSubmissionsAll extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            page: 1,
            sizePerPage: 25,
            total_documents: 0,
            data: null,
            contactToDelete: null
        }
       this.queryTableDataThrottled = _.throttle(this.queryTableData, 750)
    }

    componentDidMount = async () => {

        const contacts = await _contact_submissions.search({
            start_date: this.props.start_date ? this.props.start_date : undefined,
            end_date: this.props.end_date ? this.props.end_date : undefined,
            lead_source: this.props.lead_source ? this.props.lead_source : undefined,
            search: undefined,
            limit: this.state.sizePerPage
        })

        this.setState({data: contacts.data, total_documents: contacts.total_documents})

    }

    queryTableData = async (searchText, setPage1) => {

        this.setState({loading: true});

        let page = this.state.page
        if(setPage1) {
            this.setState({page: 1})
            page = 1
        }

        const contacts = await _contact_submissions.search({
            start_date: this.props.start_date ? this.props.start_date : undefined,
            end_date: this.props.end_date ? this.props.end_date : undefined,
            lead_source: this.props.lead_source ? this.props.lead_source : undefined,
            search: searchText,
            limit: this.state.sizePerPage,
            skip: (page - 1) * this.state.sizePerPage
        })

        this.setState({
            data: contacts.data,
            total_documents: contacts.total_documents,
            canRun: false,
            loading: false,

        })

    }

    onTableChange = async (type, newState) => {

        const { searchText } = this.state

        if(type === 'search') {

            this.queryTableDataThrottled(searchText, true)


        } else {

            this.setState({ page: newState.page, sizePerPage: newState.sizePerPage }, () => {
                this.queryTableDataThrottled(searchText)
            })

        }

    }

    onDeleteContact = (contact) => {

        this.onTableChange(null, this.state)

    }


    columns = [
        {
            dataField: "given_name",
            text: "Name",
            formatter: (cell, row) => (
                <div className="text-capitalize">
                    <NameCircle width={27} contact={row} style={{marginRight: 5}} />{' '}
                    {renderName(row)}
                </div>
            ),
        },
        {
            dataField: "email",
            text: "Contact Info",
            formatter: (cell, row) => {

                const phone = formatPhone(row.phone)

                let markup = []

                if(row.email) {
                    markup.push((
                        <div key={row._id}><i className="text-muted fas fa-envelope mr-2" /> {row.email}</div>
                    ))
                }

                if(row.phone) {
                    markup.push((
                        <div key={row._id + row.created_at} >
                            {phone}
                        </div>
                    ))
                }

                return markup.length ? markup : ''

            }
        },
        {
            dataField: "assigned_user",
            text: "Assigned User",
            formatter: (cell, row) => (
                row.assigned_user ? (
                <div className="text-capitalize">
                    <AvatarImage src={row.assigned_user.avatar} className="mr-2" />
                    {renderName(row.assigned_user)}
                </div>
                ) : (
                    '-'
                )
            )
        },
        {
            dataField: "lead_source",
            text: "Lead Source",
            headerStyle: {textAlign: 'center'},
            formatter: (cell, row) => (
                <div className="text-center">
                    {row.lead_source_name ? row.lead_source_name : 'Other'}
                </div>
            )
        },
       
        {
            dataField: "created_at",
            text: "Created At",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {

                const date = moment.unix(row.created_at); 

                return (
                    <div className="text-right">
                        <div>{date.format('MMM Do, YYYY')}</div>
                        <div>{date.format('h:mm A')}</div>
                    </div>
                )
            },
        },
       
    ]


    render() {

        const contactToDelete = this.state.contactToDelete;
        const { data, loading } = this.state;

        return (
            <>

            {contactToDelete && (
                <NotificationDelete
                    deletionURL={`/api/v1/core/contacts/delete/${contactToDelete._id}`}
                    confirmBtnText="Delete Contact"
                    title={<span className="text-capitalize">{`Delete ${renderName(contactToDelete)}`}</span>}
                    text="Deleting this contact will remove all cases associated with them. Proceed With Caution!"
                    onClose={() => { this.setState({contactToDelete: null}) }}
                    onSuccess={this.onDeleteContact}
                />
            )}

            <Container fluid>

                <Helmet>
                    <title>{`Submissions`}</title>
                    <meta name="description" content="Submissions" />
                </Helmet>

                <DashHeaderOpen
                    icon="fas fa-home"
                    icon_link="/dashboard"
                    title={<span >Submissions</span>} breadcrumb_1="All"
                />

                <Card className="card-color card-primary">

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">All Submissions</CardTitle>
                    </CardHeader>

                    {data ? (
                            <ToolkitProvider
                                data={data.length ? data : []}
                                keyField="_id"
                                columns={this.columns}
                                search
                            >
                                {props => (
                                    <>
                                        <div className="dataTables_filter px-4 pb pt-3 pb-2 position-relative" >
                                            <label>
                                                Search:
                                                <Input
                                                    className="form-control-sm"
                                                    value={this.state.searchText}
                                                    placeholder="Name, Email, Phone, or ID"
                                                    style={{width: 190}}
                                                    onChange={(e) => {
                                                        this.setState({searchText: e.target.value})
                                                        this.queryTableDataThrottled(e.target.value, true)
                                                    }}
                                                />
                                                 {loading ? (<Circle style={{display: 'inline-block', top: 1, position: 'absolute'}} /> ) : null}

                                            </label>
                                        </div>

                                        <div className="table-not-fixed table-responsive table-vertical-align">
                                            <BootstrapTable
                                                pagination={paginationFactory({
                                                    totalSize: this.state.total_documents,
                                                    page: this.state.page,
                                                    sizePerPage: this.state.sizePerPage,
                                                    alwaysShowAllBtns: true,
                                                    showTotal: true,
                                                    withFirstAndLast: true,
                                                    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                                                        <div className="dataTables_length" id="datatable-basic_length">
                                                            <label>
                                                                Show{" "}
                                                                {
                                                                <select
                                                                    value={currSizePerPage}
                                                                    name="datatable-basic_length"
                                                                    aria-controls="datatable-basic"
                                                                    className="form-control form-control-sm"
                                                                    onChange={e => onSizePerPageChange(e.target.value)}
                                                                >
                                                                    <option value="10">10</option>
                                                                    <option value="25">25</option>
                                                                    <option value="50">50</option>
                                                                    <option value="100">100</option>
                                                                </select>
                                                                }{" "}
                                                                entries.
                                                            </label>
                                                        </div>
                                                    )
                                                })}
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                bordered={false}
                                                remote={{
                                                    search: true,
                                                    pagination: true,
                                                    sort: false,
                                                    cellEdit: false
                                                }}
                                                onTableChange={this.onTableChange}

                                            />
                                        </div>
                                    </>
                                )}
                            </ToolkitProvider>
                    ) : (
                       <Circle />
                    )}

                </Card>

            </Container>

            </>
        );
    }
}

export default ContactsSubmissionsAll
