/*
Documentation

this file renders all location_courts within the system

*/

import Circle from 'components/markup/loading/Circle'
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import moment from 'moment';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import { Card, CardBody, CardHeader, Container, CardTitle } from "reactstrap";
import _documents from '_functions/documents'
import renderName from 'utils/renderName'
import A from 'components/markup/links/A'
import keys from 'keys';


class DocumentsUser extends React.Component {

    state = {
        sizePerPage: 25
    }

    columns = [
        {
            dataField: "roles",
            text: "Assigned",
            formatter: (cell, row) => (
                row.roles && row.roles.length ? (
                    row.roles.map(role => (
                        <div className="text-capitalize">{renderName(role.user)}</div>
                    ))
                ) : (
                    'No Users Found'
                )
            )
        },
        {
            dataField: "missing_docs",
            text: "Docs",
            formatter: (cell, row) => (
                row.missing_docs && row.missing_docs.length ? (
                    row.missing_docs.map(d => (
                        d.status !== 'pending' ? null : 
                        <div>
                            <A href={`${keys.APP_URL}/dashboard/cases/view/${row._id}?nav=3&doc=${row._id}`}>
                                {d.name}
                            </A>
                        </div>
                    ))
                ) : (
                    'No Docs Found'
                )
            )
        },
        {
            dataField: "contact",
            text: "Case",
            filterValue: (cell,row) => renderName(row.contact),

            formatter: (cell, row) => (
                row.contact ? (
                    <div>
                        <div><A className="text-capitalize"  href={`${keys.APP_URL}/dashboard/cases/view/${row._id}?nav=3&doc=${row._id}`}>{row.name}</A></div>
                        <div><A className="text-capitalize" href={`${keys.APP_URL}/dashboard/contacts/view/${row.contact._id}`}>{renderName(row.contact)}</A></div>
                    </div>
                ) : (
                    <div>{renderName(row.contact)}</div>
                )
            )
        },
        {
            dataField: "created_at",
            text: "Created",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => (
                <div className="text-right">
                    {moment.unix(row.created_at).format("M/D/YYYY")}
                    <div>{moment.unix(row.created_at).format("h:mm A")}</div>
                </div>
            )
        },
        {
            dataField: "x",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <A className="btn btn-success" href={`${keys.APP_URL}/dashboard/cases/view/${row._id}?nav=3`}>
                            View
                        </A>
                    </div>
                )
            }
        },
    ]

    pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: false,
        sizePerPage: this.state.sizePerPage,
        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    componentDidMount = async () => {
        const documents = await _documents.findAdmin();
        if(documents.success) this.setState({documents: documents.data})
    }

    render() {

        const { documents } = this.state
        if(!documents) return <div className="py-6"><Circle /></div>

        return (

            <Container fluid>

                <Helmet>
                    <title>{`Uploaded Documents (${documents.length})`}</title>
                    <meta name="description" content="Documents" />
                </Helmet>

                <DashHeaderOpen
                    icon="fas fa-home"
                    icon_link="/dashboard"
                    title={<span >Documents</span>} breadcrumb_1="All"
                />

                <Card className="card-color card-primary">

                    <CardHeader>
                        <CardTitle className="mb-0">Pending Documents (Most Recent 300)</CardTitle>
                    </CardHeader>

                    {documents ? (

                        <ToolkitProvider
                            data={documents}
                            keyField="_id"
                            columns={this.columns}
                            search
                        >
                            {props => (
                                <>

                                    <div className=" table-responsive table-vertical-align">
                                        <BootstrapTable
                                            pagination={this.pagination}
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            bordered={false}
                                        />
                                    </div>
                                </>
                            )}
                        </ToolkitProvider>

                    ) : (

                        <CardBody>

                            <p className="text-sm mb-0">There are no unassigned documents to view</p>
                        </CardBody>

                    )}
                </Card>

            </Container>

        );
    }
}

export default DocumentsUser

