import React from 'react';

import { Col, FormGroup, Input, Row } from 'reactstrap';

import InputEmails from './InputEmails';
import ButtonBack from './ButtonBack';

const ColOverview = ({questionnaire, onQuestionnaireChange, onSave}) =>  {

    return (

        <Col lg={4} className="col col-overview">

            <Row className="archk-col-header">
                <Col lg={3} className="align-self-center">
                    <h3 className="text-white">O</h3>
                </Col>
                <Col lg={9} className="align-self-center text-right">
                    <ButtonBack />
                    <button onClick={onSave} className="btn btn-success"><i className="fas fa-save " /> Save</button>
                </Col>

            </Row>

            <FormGroup>
                <label className="form-control-label">Questionnaire Name</label>
                <Input 
                    type="text"
                    value={questionnaire.name}
                    onChange={(e) => onQuestionnaireChange(e, 'name')}
                />
            </FormGroup>

            <FormGroup>
                <label className="form-control-label">Is Active</label>
                <Input 
                    type="select"
                    value={questionnaire.active ? 'yes' : 'no'}
                    onChange={(e) => onQuestionnaireChange(e, 'active')}
                >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </Input>
            </FormGroup>

            <FormGroup>
                <label className="form-control-label">Create PDF</label>
                <Input 
                    type="select"
                    value={questionnaire.create_pdf ? 'yes' : 'no'}
                    onChange={(e) => onQuestionnaireChange(e, 'create_pdf')}
                >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </Input>
            </FormGroup>

            <InputEmails 
                emails={questionnaire.send_emails_to}
                onChange={send_emails_to => onQuestionnaireChange({target: {value: send_emails_to}}, 'send_emails_to')}
            />

        </Col>
              
    )
};

export default React.memo(ColOverview);

