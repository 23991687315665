import React from 'react';
import { Dropdown, Item, StandAlone } from './_components';

import store from 'store';

const kev = '63345497404415304760ff6b'
const bane = '63bc5f8ca9f7553ee2c82a9b'
const mceldowney = '63b4961769bd1d7e6de1ba74'
const john = '6300daa78f12344b4d635d8d'
const diana = '6334544d99cda1303a430f1d'
const shirley = '63bec5901d83ec158af4e855'
const ben = '63bdb2412c7cc45284f3df07'

const SidebarAnalytics = (props) => {
    const viewing_user = store.getState().auth.viewing_user
    return (
              
        <ul className="mb-0">
    
            <StandAlone route="/analytics/master" name="Master" icon="fas fa-chart-line" {...props} />
            <StandAlone route="/analytics/compare" name="Compare" icon="fas fa-chart-bar" {...props} />
    
            {viewing_user._id === kev || viewing_user._id === john || viewing_user._id === mceldowney || viewing_user._id === diana || viewing_user._id === shirley || viewing_user._id === bane || viewing_user._id === ben ? (
            <Dropdown route="/analytics/monitor" name="Monitor" icon="fas fa-eye" {...props}> 
                <Item  route="/analytics/monitor/overview" name="Documents" {...props}/>
                {viewing_user._id !== mceldowney && viewing_user !== diana && viewing_user !== shirley && viewing_user !== bane && viewing_user !== ben ? (
                    <Item  route="/analytics/monitor/emails" name="Emails" {...props}/>
                ) : ''}
                <Item  route="/analytics/monitor/client_logins" name="Client Logins" {...props}/>
                {/* <Item  route="/analytics/monitor/users" name="Users" {...props}/> */}
            </Dropdown>
            ) : ''}
         
    
    
            <StandAlone route="/analytics/lead_sources" name="Lead Sources" icon="fas fa-project-diagram" {...props} />
    
           
    
            <Dropdown route="/analytics/cases" name="Cases" icon="fas fa-gavel" {...props}>
                {/* <Item  route="/analytics/cases/graphs" name="Graphs" {...props}/> */}
                <Item  route="/analytics/cases/overview" name="Overview" {...props}/>
                <Item  route="/analytics/cases/reports" name="Reports" {...props}/>
                {/* <Item  route="/analytics/cases/filing_times" name="Filing Times" {...props}/> */}
                {/* <Item  route="/analytics/cases/sync_case_appointment" name="Appt Sync" {...props}/> */}
                {/* <Item  route="/analytics/cases/sync_step_times" name="Step Time Sync" {...props}/> */}
                {/* <Item  route="/analytics/cases/trust" name="Trust" {...props}/> */}
                <Item  route="/analytics/cases/by_step" name="By Step" {...props}/>
                {/* <Item  route="/analytics/cases/step_overview" name="Step Overview" {...props}/> */}
                {/* <Item  route="/analytics/cases/data_types/update" name="Custom Data" {...props}/> */}
            </Dropdown>
    
            <Dropdown route="/analytics/consultations" name="Consultations" icon="fas fa-calendar-check" {...props}>
                <Item  route="/analytics/consultations/compare" name="Overview" {...props}/>
                <Item  route="/analytics/consultations/query" name="Reports" {...props}/>
                <Item  route="/analytics/consultations/create" name="Create" {...props}/>
            </Dropdown>
    
            <Dropdown route="/analytics/signing_docs" name="Signing" icon="fas fa-signature" {...props}>
                <Item  route="/analytics/signing_docs/overview" name="Overview" {...props}/>
                <Item  route="/analytics/signing_docs/reports" name="Reports" {...props}/>
            </Dropdown>
    
    
            <Dropdown route="/analytics/users" name="Users" icon="fas fa-users" {...props}>
                {/* <Item  route="/analytics/users/hired_logs" name="Contacts Hired" {...props}/> */}
                <Item  route="/analytics/users/step_logs" name="Step Logs" {...props}/>
                <Item  route="/analytics/users/stats_today" name="Users Stats" {...props}/>
                <Item  route="/analytics/users/all_stats_today" name="All Stats" {...props}/>
                <Item  route="/analytics/users/events" name="Events" {...props}/>
                <Item  route="/analytics/users/call_logs" name="Call Logs" {...props}/>
                {/* <Item  route="/analytics/calls_under_90/query" name="Calls Under 90" {...props}/> */}
            </Dropdown>
    
            {/* <StandAlone route="/analytics/alerts" name="Alerts" icon="fas fa-exclamation-triangle" {...props} /> */}
    
            <Dropdown route="/analytics/appointments" name="Reminders   " icon="fas fa-calendar" {...props}>
                <Item  route="/analytics/appointments/query" name="Reports" {...props}/>
                <Item  route="/analytics/appointments/finished/query" name="Finished" {...props}/>
                <Item  route="/analytics/appointments/breakdown" name="Breakdown" {...props}/>
                <Item  route="/analytics/appointments/by_step" name="By Step" {...props}/>
            </Dropdown>
    
            <Dropdown route="/analytics/contacts" name="Contacts" icon="fas fa-users" {...props}>
                {/* <Item  route="/analytics/contacts/graphs" name="Graphs" {...props}/> */}
                <Item  route="/analytics/contacts/reports" name="Reports" {...props}/>
                {/* <Item  route="/analytics/contacts/hired" name="Hired" {...props}/> */}
                {/* <Item  route="/analytics/contacts/data_types/update" name="Custom Data" {...props}/> */}
            </Dropdown>
          
    
    
    
            {/* <Dropdown route="/documents/signed" name="Documents" icon="fas fa-file" {...props}>
                <Item  route="/analytics/documents/signed" name="Signed" {...props}/>
                <Item  route="/analytics/documents/pending" name="Pending" {...props}/>
            </Dropdown> */}
    
            {/* <StandAlone route="/analytics/contact_submissions/all" name="Submissions" icon="fab fa-wpforms" {...props} /> */}
    
            <StandAlone route="/analytics/calls/query" name="Calls" icon="fas fa-headset" {...props} />

            {/* <StandAlone route="/analytics/lead_source_entries/query" name="Time To Contact" icon="fas fa-mobile" {...props} /> */}
            <StandAlone route="/analytics/directory" name="Directory" icon="fas fa-search" {...props} />
            <StandAlone route="/analytics/live_queue" name="Live Queue" icon="fas fa-mobile" {...props} />
            <StandAlone route="/analytics/affiliates" name="Affiliates" icon="fas fa-dollar-sign" {...props} />
            {/* <StandAlone route="/analytics/groupings/all" name="Groupings" icon="fas fa-layer-group" {...props} /> */}
            {/* <StandAlone route="/analytics/data_types/router" name="Custom Data" icon="fas fa-server" {...props} /> */}
    
        </ul>
    )
}

export default SidebarAnalytics;