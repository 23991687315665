import React from "react";
import { CardTitle, CardHeader, Col, Row } from "reactstrap";

const EmailParsersAll = ({parser, onChange}) => {

    return (

        <>
            <CardHeader className="text-uppercase">
                <CardTitle className="mb-0">Parser Status</CardTitle>
            </CardHeader>

            <CardHeader>
                <Row>
                    <Col lg={6} className="align-self-center">
                        <p className="text-sm mb-0">
                            {parser.active ? 
                                `Active` : 
                                <span>
                                    <i className="fas fa-exclamation-triangle mr-2 text-danger " /> 
                                    Turned Off
                                </span>
                            }
                        </p>
                    </Col>
                    <Col lg={6} className="text-right align-self-center">
                        <label className="custom-toggle" style={{position: 'relative', top: 4}}>
                            <input onChange={() => onChange({target: {value: !parser.active}}, 'active')} checked={parser.active} type="checkbox" />
                            <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="OFF"
                                data-label-on="ON"

                            />
                        </label>
                    </Col>
                </Row>
            </CardHeader>
        </>

    );
}

export default EmailParsersAll