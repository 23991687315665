
import { Modal, Badge } from "reactstrap";
import keys from 'keys';
import A from 'components/markup/links/A'
import { useState, useEffect } from 'react';
import moment from "moment";

const ModalViewCases = ({showModal, toggleModal, docs}) => {

    const [sort, setSort] = useState('')
    const [direction, setDirection] = useState(1)
    const [objs, setObjs] = useState(JSON.parse(JSON.stringify(docs)));

    const onSetSort = (field) => {

        if(sort === field) {
            const newDirection = direction * -1;
            setSort(field)
            setDirection(newDirection)

            const _objs = objs.sort((a, b) => {

                if(direction === -1) {
                    return b[field] && !a[field] ? -1 : a[field] > b[field] ? -1 : 1
                } else {
                    return a[field] && !b[field] ? -1 :  a[field] < b[field] ? -1 : 1
                }
            })
            setObjs(_objs)

        } else {
            setSort(field)
            setDirection(1)

            const _objs = objs.sort((a, b) => a[field] < b[field] ? 1 : -1)
            setObjs(_objs)
        }

    }

    useEffect(() => {
        setObjs(JSON.parse(JSON.stringify(docs)))
    }, [docs])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">eSignatures (Showing Most Recent 300)</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Case</th>
                                <th className="cursor-pointer" onClick={() => onSetSort('name')}>Document</th>
                                <th className="cursor-pointer" onClick={() => onSetSort('status')}>Signed</th>
                                <th className="cursor-pointer" onClick={() => onSetSort('finalized_on')}>Finalized On</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {objs.length ? objs.map(doc => {
                                return (
                                    <tr key={doc._id}>
                                        <td className="text-capitalize">
                                            {doc.case && doc.case.name ? doc.case.name.replace(' - ertc', '') : 'Case Name'}
                                        </td>
                                        <td>{doc.name ? doc.name.replace('Document For Step Call Completed - ', '') : doc.name}</td>
                                        <td>{doc.finalized_on ? <Badge color="success"><i className="fas fa-check mr-2 " /> YES</Badge> :  <Badge color="danger"><i className="fas fa-times mr-2 " /> NO</Badge> }</td>
                                        <td>{doc.finalized_on ? moment.unix(doc.finalized_on).format('MMM Do, YYYY h:mm A') : ''}</td>
                                        <td className="text-right"><A className="btn btn-success" href={`${keys.APP_URL}/dashboard/cases/view/${doc.case._id}`}>View</A></td>
                                    </tr>
                                )
                            }) : (
                                <tr>
                                    <tr>
                                        <td>No Documents Found</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="modal-footer text-right">
                <button className="btn btn-outline-warning" onClick={toggleModal} >
                    <i className="fas fa-arrow-left mr-2" />
                    Close
                </button>
            </div>

        </Modal>

    )

}

export default ModalViewCases
