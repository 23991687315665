
import apiWorker from '../apiWorker'

const call_center = {

    getFinishOptions: (contact_id) =>  apiWorker('get',   `/api/v1/call_center/getFinishOptions/${contact_id}`),
    getAdminOverview: (startOfDay) =>  apiWorker('get',   `/api/v1/analytics/custom_reports/call_center/overview?startOfDay=${startOfDay}`),
    downloadUser: (data) =>  apiWorker('post',   `/api/v1/analytics/custom_reports/call_center/downloadUser`, data),

}

export default call_center;