/*
Documentation

example usage:

same as ./ AssignAutomations however it works for tags not automations

<AssignTags
    keyValue={tag._id}
    onChange={(values) => this.setState({tags: values.tag_ids})}
    stateTags={tag.tags ? tag.tags : []}
/>

*/


import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactSelect from 'components/functional/inputs/ReactSelect';
import { FormGroup } from 'reactstrap';

class AssignTags extends Component {

    state = {
        tag_ids: [],
        tags: [],
        selectOptions: [],
        stateTags: []
    }

    setOptions = () => {

        const selectOptions = []
        let allTags = this.props.tags

        if(!allTags) return

        allTags = [...allTags]
        allTags.forEach(tag => {

            // dont add in values used in the dropdown
            if(!this.state.tag_ids.includes(tag._id)) {
                selectOptions.push({label:  tag.name, value: tag._id})
            }

        })

        this.setState({selectOptions})

    }

    setTagIds = (stateTags) => {

        let tag_ids = [];

        if(stateTags && stateTags.length) {
            stateTags.forEach(tag => {
                tag_ids.push(tag._id);
            })
        }

        this.setState({tag_ids})

        return tag_ids

    }

    onRemoveTag = (tag_id) => {

        let stateTags = this.state.stateTags ? this.state.stateTags : [];

        stateTags = stateTags.filter(tag => tag._id !== tag_id)

        const tag_ids = this.setTagIds(stateTags)

        this.props.onChange({stateTags, tag_ids})

        this.setState({stateTags}, this.setOptions)

    }

    onAddTag = (tag_id) => {

        let stateTags = this.state.stateTags ? this.state.stateTags : [];

        // dont push duplicates
        if(!stateTags.find(tag => tag._id === tag_id)) {

            let foundTag = this.props.tags.find(tag => tag._id === tag_id)

            if(foundTag) stateTags.push({_id: foundTag._id, name: foundTag.name})

            const tag_ids = this.setTagIds(stateTags)

            this.props.onChange({stateTags, tag_ids})

            this.setState({stateTags}, this.setOptions)

        }

    }

    componentWillReceiveProps = (nextProps) => {

        if(nextProps.keyValue !== this.props.keyValue) {

            const stateTags = nextProps.stateTags;

            if(stateTags && stateTags.length) {

                const tag_ids = this.setTagIds(stateTags)

                this.props.onChange({stateTags, tag_ids})
                this.setState({stateTags, tag_ids}, this.setOptions )

            } else {

                this.setState({stateTags: [], tag_ids: []})

            }

        }

    }

    componentDidMount = () => {

        const stateTags = this.props.stateTags;

        if(stateTags && stateTags.length) {

            const tag_ids = this.setTagIds(stateTags)

            this.props.onChange({stateTags, tag_ids})
            this.setState({stateTags, tag_ids}, this.setOptions)

        } else {

            this.setState({stateTags: [], tag_ids: []}, this.setOptions)

        }

    }

    render() {

        const { descriptionEmpty, title } = this.props
        const { stateTags, selectOptions } = this.state

        return (

            <>

                <FormGroup>
                    <label className="form-control-label">{title ? title : 'Tags'} </label>
                    <ReactSelect
                        value={{}}
                        onChange={(values) => this.onAddTag(values.value)}
                        options={selectOptions}
                    />
                </FormGroup>

                {stateTags && stateTags.length ? stateTags.map(tag => (
                    <p className="text-sm p-2 px-3 bg-primary text-white border mb-0" key={tag._id}>
                        <i onClick={() => this.onRemoveTag(tag._id)} className="fas fa-trash mr-2 cursor-pointer text-danger" />
                        {tag.name}
                    </p>
                )) : (
                    <p className="text-sm font-weight-bold">{descriptionEmpty ? descriptionEmpty : `No tags have been set for this task.`}</p>
                )}

            </>

        )

    }

}

AssignTags.propTypes = {
    keyValue            : PropTypes.string,
    tags                : PropTypes.array,
    onChange            : PropTypes.func.isRequired,
    title               : PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    descriptionEmpty    : PropTypes.string,
}

const mapStateToProps = state => {
    return {
        tags: state.tags.tags,
    };
};

export default connect(mapStateToProps, '')(AssignTags);
