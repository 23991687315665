import apiWorker from '../apiWorker'

const archives = {

    restore: {

        user: (data)        => apiWorker('post', `/api/v1/core/archives/restore/user`, data),
        court: (data)       => apiWorker('post', `/api/v1/core/archives/restore/court`, data),
        case: (data)        => apiWorker('post', `/api/v1/core/archives/restore/case`, data),
        contact: (data)     => apiWorker('post', `/api/v1/core/archives/restore/contact`, data),

    }


}

export default archives;