import apiWorker from '../apiWorker'

const forms = {

    find:       (query)         => apiWorker('get',  `/api/v1/core/forms/find${query}`),
    findById:   (form_id)       => apiWorker('get',  `/api/v1/core/forms/find/${form_id}`),

    create:     (data)          => apiWorker('post',  `/api/v1/core/forms/create`, data),
    update:     (form_id, data) => apiWorker('patch', `/api/v1/core/forms/update/${form_id}`, data),

}

export default forms;