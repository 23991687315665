
// findUnmovedStepsByAppointments

import AvatarImage from 'components/functional/images/AvatarImage';
import React from 'react';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import renderName from 'utils/renderName';

const LeaderBoard = ({data, getEventName, getUser}) => (

    <Card>

        <CardHeader>
            <CardTitle className="mb-0">Leader Board</CardTitle>
        </CardHeader>
        
        <CardBody>
            <Row>
                {Object.keys(data.leaders).map(key => {

                    const event = key;
                    const user = getUser(data.leaders[key].user);
                    const quantity = data.leaders[key].quantity

                    return (
                        <Col xl={3} key={key}>

                            <Card className="text-center text-capitalize">

                                <CardHeader>
                                    <CardTitle className="mb-0">{getEventName(event)}</CardTitle>
                                </CardHeader>

                                <CardBody>
                                    {quantity ? (
                                        <Row>
                                            <Col lg={6} className="align-self-auto text-right">
                                                <AvatarImage style={{width: 75, height: 75}} src={user.avatar} className="mr-4" />
                                            </Col>

                                            <Col lg={6} className="text-left">
                                                <h3 className="display-2 mt-4 ml-3">{quantity}</h3>
                                            </Col>
                                        </Row>
                                    ) : (
                                        <h3 className="display-2 mt-4 ml-3">{quantity}</h3>
                                    )}
                                 

                                </CardBody>

                                <CardFooter>
                                    {renderName(user)}
                                </CardFooter>
                            </Card>
                            
                        </Col>
                    )

                })}
            </Row>
        </CardBody>

    </Card>

)

export default LeaderBoard