/*
Documentation

This page shows all of the deleted system contacts

*/

import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import _contacts from '_functions/contacts';
import Circle from 'components/markup/loading/Circle'

import _archives from '_functions/archives';

const { SearchBar } = Search;

class ArchiveContacts extends React.Component {

    state = {
        contacts: null
    }

    restore = async (contact_id) => {

        const restored = await _archives.restore.contact({contact_id})

        if(restored.success) {

            let contacts = [...this.state.contacts];
            contacts = contacts.filter(u => u._id !== contact_id)

            this.setState({contacts})

        }

    }

    columns = [
        {
            dataField: "given_name",
            text: "Name",
            formatter: (cell, row) => row.given_name + ' ' + row.family_name
        },
        {
            dataField: "email",
            text: "Email",

        },

        {
            dataField: "phone",
            text: "Phone",
        },

        {
            dataField: "address_line_1",
            text: "Address",
            formatter: (cell, row) => (
                <span>
                    {row.address_line_1}  {row.address_line_2} <br />
                    {row.city}, {row.state} {row.postal_code} {row.country}
                </span>
            )
        },

        {
            dataField: "_id",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <button className="btn btn-outline-warning" onClick={() => this.restore(row._id)}>
                            <i className="fas fa-check mr-2" /> Restore Contact
                        </button>
                    </div>
                )
            }
        },
    ]

    pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: false,

        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    componentDidMount = async () => {

        const contacts = await _contacts.find(`?filter=deleted__true{bool}&limit=500&sort=given_name__asc|family_name__asc`)
        if(contacts.success) this.setState({contacts: contacts.data})

        this.setState({loaded: true})

    }

    render() {

        const { contacts, loaded } = this.state

        if(!loaded) return <div className="py-4"><Circle /></div>

        return (

            <Card className="card-color card-success">

                <CardHeader>
                    <CardTitle className="mb-0 float-left">Deleted Contacts</CardTitle>
                </CardHeader>

                {contacts && contacts.length ? (

                    <ToolkitProvider
                        data={contacts}
                        keyField="_id"
                        columns={this.columns}
                        search
                    >
                        {props => (
                            <>
                            <div
                                id="datatable-basic_filter"
                                className="dataTables_filter px-4 pb pt-3 pb-2"
                                style={{width: '100%'}}
                            >
                                <label>
                                    Search:
                                    <SearchBar
                                        className="form-control-sm"
                                        placeholder="Template Name"
                                        {...props.searchProps}
                                    />
                                </label>
                            </div>
                            <div className=" table-responsive table-vertical-align table-not-fixed">
                                <BootstrapTable
                                    pagination={this.pagination}
                                    {...props.baseProps}
                                    bootstrap4={true}
                                    bordered={false}
                                />
                            </div>

                            </>

                        )}
                    </ToolkitProvider>

                ) : (

                    <CardBody>
                        <p className="text-sm mb-0">There are no deleted contacts within the system.</p>
                    </CardBody>

                )}

            </Card>

        );
    }
}

export default ArchiveContacts
