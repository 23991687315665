/*
Documentation

this page is used to update a user within the system

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardHeader, Container, CardTitle, Row, Col } from "reactstrap";
import { formatPhone } from 'utils/text'

import renderName from 'utils/renderName';
import { capitalize } from 'utils/text'

import _users from '_functions/users';

import { toggleAlertBS } from 'store/functions/system/system'
import { validatePhoneNumber } from 'utils/validation';
import { toggleStandardLoader } from 'store/functions/system/system'
import { toggleAlert } from 'store/functions/system/system';

import LeadSource from './components/LeadSource';
import Address from './components/Address';
import Billing from './components/Billing';
import General from './components/General';
import Privileges from './components/Privileges';
import UserTypes from './components/UserTypes';
import Password from './components/Password';
import ColorPicker from './components/ColorPicker';
import ProfileAvatar from './components/ProfileAvatar';
import Circle from 'components/markup/loading/Circle'

import validator from 'utils/validator';
import keys from 'keys';

const required_fields = [
    'given_name',
    'family_name',
    'email',
    'phone',
    'address_line_1',
    'city',
    'state',
    'postal_code',
    'country',
    'hourly_rate',
    'billable_rate'
]

class UsersCrud extends React.Component {

    state = {
        user: null,
        password: {}
    }

    onColorChange = (color) => {

        const user = Object.assign({}, this.state.user)
        user.color = color.hex;

        this.setState({user})

    }

    onUpdate = async () => {

        this.setState({phoneExists: false, emailExists: false})
        const user = this.state.user

        const validateFields = validator.requiredFields('user', required_fields, this, true)

        let default_percent = 0;

        const percentIsValid = (percent) => {
            return percent !== undefined && percent !== null && percent !== '' ? true : false
        }

        if(!percentIsValid(user.default_percent)) return toggleAlertBS('info', 'Make sure that the default percent is a number between 0 and 1. Example: 25% = .25');

        default_percent = parseFloat(user.default_percent)

        if(validateFields.success) {

            toggleStandardLoader(true)

            const validatedEmailAndPhone = await this.validateEmailAndPhone()
            if(!validatedEmailAndPhone) return toggleStandardLoader(false)

            let formattedPhone = validatePhoneNumber(user.phone)
            user.phone = formattedPhone !== false ? formattedPhone : undefined

            //validate user fields here
            _users.update(this.props.match.params._id, {...user, default_percent})
            toggleAlertBS(false, 'User has been updated successfully.')

            toggleStandardLoader(false)

        }

    }

    // give an error if the email or phone that have been given are in the system
    validateEmailAndPhone = () => {
        return new Promise (async resolve => {

            const user = this.state.user;
            let email = user.email

            let phone = validatePhoneNumber(user.phone)

            if(!phone) {
                toggleAlert(true, 'warning', `${user.phone} is not a valid phone number`, 4000)
                return resolve(false)
            }

            Promise.all([
                _users.findByPhone(phone),
                _users.findByEmail(email),
            ]).then(values => {

                const phoneExists = values[0]
                const emailExists = values[1]

                let errors = 0

                if(!phoneExists.success) {
                    this.setState({phoneExists: true}); errors++;
                }

                if(phoneExists.data) {
                    if(phoneExists.data._id !== user._id) {
                        this.setState({phoneExists: true}); errors++;
                    }
                }

                if(!emailExists.success) {
                    this.setState({emailExists: true}); errors++;
                }

                if(emailExists.data) {
                    if(emailExists.data._id !== user._id) {
                        this.setState({emailExists: true}); errors++;
                    }
                }

                // all is good, go to next step
                resolve(errors ? false : true)
            })

        })
    }


    componentDidMount = async () => {

        const user_id = this.props.match.params._id
        const foundUser = await _users.findById(user_id, true)

        if(foundUser.success) {
            this.setState({user: foundUser.data})
        } else {
            this.setState({shouldRedirect: '/core/users/all'})
        }

    }

    render() {

        const { user, emailExists, phoneExists, password, shouldRedirect } = this.state;

        if(shouldRedirect) return <Redirect to={shouldRedirect} />
        if(!user) return <div className="py-6"><Circle /></div>

        return (

            <Container fluid>

                <Helmet>
                    <title>Staff Update</title>
                    <meta name="description" content="Staff Update" />
                </Helmet>

                <DashHeaderOpen
                    title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
                    breadcrumb_1="Staff"
                    breadcrumb_2={capitalize(renderName(user))}
                    actionComponent={(
                        <Link  to="/core/users/all" className="btn btn-outline-warning"><i className="fas fa-arrow-left" /> All Staff</Link>
                    )}
                />

                <Card className="card-color card-warning">

                    {/* --------------------------------------------------------------------------------- */}
                    <CardHeader className="bg-secondary">
                        <CardTitle className="mb-0 float-left">Update {capitalize(renderName(user))}</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <General
                            user={user}
                            onChange={(field, val) => this.setState({user: {...this.state.user, [field]: val}})}
                        />
                    </CardBody>

                    {/* --------------------------------------------------------------------------------- */}
                    <CardHeader className="bg-secondary">
                        <CardTitle className="mb-0">Address</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <Address
                            user={user}
                            onChange={(field, val) => this.setState({user: {...this.state.user, [field]: val}})}
                        />
                    </CardBody>
                    {/* --------------------------------------------------------------------------------- */}
                    <CardHeader className="bg-secondary">
                        <CardTitle className="mb-0">Lead Source</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <LeadSource
                            user={user}
                            onChange={(field, val) => this.setState({user: {...this.state.user, [field]: val}})}
                        />
                    </CardBody>

                    {/* --------------------------------------------------------------------------------- */}
                    <CardHeader className="bg-secondary">
                        <CardTitle className="mb-0">Billing</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <Billing
                            user={user}
                            onChange={(field, val) => this.setState({user: {...this.state.user, [field]: !val || val === 'false' ? '' : val}})}
                        />
                    </CardBody>

                    {/* --------------------------------------------------------------------------------- */}
                    <CardHeader className="bg-secondary">
                        <Row>
                            <Col md={6}>
                                <CardTitle className="mb-0">Privileges</CardTitle>
                            </Col>
                            <Col md={6}>
                                <CardTitle className="mb-0">User Types & Links</CardTitle>
                            </Col>
                        </Row>
                    </CardHeader>

                    <CardBody>
                        <Row>
                            <Col md={6}>
                                <Privileges
                                    user={user}
                                    onChange={(field, val) => this.setState({user: {...this.state.user, [field]: val}})}
                                />

                            </Col>
                            <Col md={6}>
                                <UserTypes
                                    user={user}
                                    onChange={(field, val) => this.setState({user: {...this.state.user, [field]: val}})}
                                />

                                <h4 className="mb-0 mt-4">Booking URL</h4>
                                <p className="text-sm mb-0">{keys.BOOK_URL}?u={user._id}</p> 
                            </Col>
                        </Row>
                    </CardBody>

                    <CardFooter className="text-right">

                        {emailExists ? (<div className="alert alert-danger">An staff member already exists with this email address: {user.email}.</div> ) : null}
                        {phoneExists ? (<div className="alert alert-danger">An staff member already exists with this phone number: {formatPhone(user.phone)}.</div> ) : null}
                        <button className="btn btn-success" onClick={this.onUpdate}>Update User</button>
                    </CardFooter>

                </Card>

                <Row>

                    <Col lg={4}>

                        <ColorPicker
                            user_id={this.props.match.params._id}
                            color={this.state.user.color || '#fff'}
                            onColorChange={this.onColorChange}
                        />
                    </Col>

                    <Col lg={8}>
                        <Password
                            user_id={this.props.match.params._id}
                            password={password}
                            onChange={(field, val) => this.setState({password: {...this.state.password, [field]: val}})}
                        />

                        <ProfileAvatar 
                            user={user}
                        />


                    </Col>

                </Row>

            </Container>

        );
    }
}



const mapStateToProps = state => {
    return {
        users: state.users.users,
    };
};

export default connect(mapStateToProps, '')(UsersCrud);
