import PropTypes from 'prop-types';
import React from "react";
import { Col, FormGroup } from "reactstrap";
import UserSearchInput from 'components/system/migrated/UserSearchInput';
import renderName from 'utils/renderName';
import { formatPhone } from 'utils/text'

class AppointmentsCrudUsers extends React.Component {

    hasError = () => {

        const { associationError, appointment } = this.props;

        if(associationError) {

            if(!appointment.assigned_to) return true
            if(appointment.assigned_to && !appointment.assigned_to.length) return true;

        }

        return false

    }

    render() {

        const { appointment, userRequired, onRemoveUser, onAssignUser } = this.props

        if(!userRequired()) return null

        return (

            <Col lg={6} className="mb-4">

                <div className="bg-secondary p-1">
                    <FormGroup className="p-2">
                        <h4>Assign Users *</h4>
                        <UserSearchInput
                            hideTitle={true}
                            value={{}}
                            onSelect={(user) => onAssignUser(user)}
                        />
                    </FormGroup>

                    {this.hasError() ? (
                        <div className="alert alert-danger">You must select at least 1 staff member before proceeding.</div>
                    ) : null}
                </div>

                <div  className="table-responsive table-no-margin bg-secondary mt--4">
                    <table className="table">

                        <thead>
                            <tr className="font-weight-bold">
                                <th>Assigned To</th>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            {appointment.assigned_to && appointment.assigned_to.length ? appointment.assigned_to.map(user => (
                                <tr key={user._id}>
                                    <td>
                                        <div className="text-capitalize"> {renderName(user)}</div>
                                        {user.phone ? (
                                            <div className="text-capitalize">{formatPhone(user.phone)}</div>
                                        ) : null}
                                        {user.email ? (
                                            <div>{user.email}</div>
                                        ) : null}
                                    </td>
                                    <td className="text-right">
                                        <i className="fas fa-trash text-danger cursor-pointer" onClick={() => onRemoveUser(user)} />
                                    </td>
                                </tr>
                            )): (
                                <tr>
                                    <td className="text-warning font-weight-bold"><i className="fas fa-exclamation-triangle mr-2 " /> No User Assigned</td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>

                    </table>
                </div>

            </Col>

        )

    }
}

AppointmentsCrudUsers.propTypes = {
    userRequired    : PropTypes.func.isRequired,
    onRemoveUser    : PropTypes.func.isRequired,
    onAssignUser    : PropTypes.func.isRequired,
    appointment     : PropTypes.object.isRequired,
}

export default AppointmentsCrudUsers
