import { connect } from 'react-redux';
import { Card, CardHeader, CardTitle, Col, Row } from "reactstrap";

import renderName from 'utils/renderName';

import { formatMoney } from 'utils/currency';

const SigningOverview = ({allData, users, onSetBreakdown}) => {

    const data = JSON.parse(JSON.stringify(allData))
    data.sort((a, b) => a.data.totalSigned >= b.data.totalSigned ? -1 : 1)

    return (
        <div>
            {data.map(d => (
                <Card key={d._id}>
                    <CardHeader>
                        <Row>
                            <Col md={6}>
                                <CardTitle className="text-capitalize">{renderName(users.find(u => u._id === d.user))}</CardTitle>
                            </Col>
                            <Col md={6} className="text-right">
                                <button onClick={onSetBreakdown} className="btn btn-success">View Together</button>
                            </Col>
                        </Row>
                    </CardHeader>
                    
                    <Row>

                        <Col lg={6}>

                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th className="text-right">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Total Cases / Signed</td>
                                            <td className="text-right">
                                                {d.data.total} / {d.data.totalSigned}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Close Rate</td>
                                            <td className="text-right">{d.data.closeRate}</td>
                                        </tr>
                                        <tr>
                                            <td>Emailed</td>
                                            <td className="text-right">
                                                {((d.data.emailed / d.data.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                                <span style={{display: 'inline-block', width: 30}}>
                                                {d.data.emailed}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Now Show</td>
                                            <td className="text-right">
                                                {((d.data.noShow / d.data.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                                <span style={{display: 'inline-block', width: 30}}>
                                                {d.data.noShow}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Call Scheduled</td>
                                            <td className="text-right">
                                                {((d.data.callScheduled / d.data.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                                <span style={{display: 'inline-block', width: 30}}>
                                                {d.data.callScheduled}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Call Conducted</td>
                                            <td className="text-right">
                                                {((d.data.callConducted / d.data.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                                <span style={{display: 'inline-block', width: 30}}>
                                                {d.data.callConducted}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Sent Retainer</td>
                                            <td className="text-right">
                                                {((d.data.sentRetainer / d.data.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                                <span style={{display: 'inline-block', width: 30}}>
                                                {d.data.sentRetainer}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Signed Retainer</td>
                                            <td className="text-right">
                                                {((d.data.signedRetainer / d.data.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                                <span style={{display: 'inline-block', width: 30}}>
                                                {d.data.signedRetainer}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Submitted</td>
                                            <td className="text-right">
                                                {((d.data.submitted / d.data.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                                <span style={{display: 'inline-block', width: 30}}>
                                                {d.data.submitted}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Signature</td>
                                            <td className="text-right">
                                                {((d.data.signature / d.data.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                                <span style={{display: 'inline-block', width: 30}}>
                                                {d.data.signature}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>IRS</td>
                                            <td className="text-right">
                                                {((d.data.irs / d.data.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                                <span style={{display: 'inline-block', width: 30}}>
                                                {d.data.irs}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Dead</td>
                                            <td className="text-right">
                                                {((d.data.dead / d.data.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                                <span style={{display: 'inline-block', width: 30}}>
                                                {d.data.dead}
                                                </span>
                                            </td>
                                        </tr>

                                    
                                    </tbody>
                                </table>
                            </div>
                        
                        </Col>

                        <Col lg={6}>

                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th className="text-right">Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Current Net-Net Fees</td>
                                            <td className="text-right">{formatMoney(d.data.netFees)}</td>
                                        </tr>
                                        <tr>
                                            <td>Anticipated Net-Net Fees</td>
                                            <td className="text-right">{formatMoney(d.data.anticipatedNetFees)}</td>
                                        </tr>
                                        <tr>
                                            <td>Avg Net Fee Per Case</td>
                                            <td className="text-right">{formatMoney(d.data.avgNetPerCase)}</td>
                                        </tr>
                                        <tr>
                                            <td>Avg ERTC</td>
                                            <td className="text-right">{formatMoney(d.data.avgERTC)}</td>
                                        </tr>
                                        <tr>
                                            <td>Acceptable Price / Lead</td>
                                            <td className="text-right">{formatMoney(d.data.acceptablePricePerLead)}</td>
                                        </tr>
                                        <tr>
                                            <td>Marketing Fees</td>
                                            <td className="text-right">{formatMoney(d.data.calculatedTotalFeeMarketing)}</td>
                                        </tr>
                                        <tr>
                                            <td>Gross Fees</td>
                                            <td className="text-right">{formatMoney(d.data.calculatedTotalFeeBiz)}</td>
                                        </tr>
                                        <tr>
                                            <td>Anticipated Gross Fees</td>
                                            <td className="text-right">{formatMoney(d.data.anticipatedGrossFees)}</td>
                                        </tr>
                                        <tr>
                                            <td>Gross Margin</td>
                                            <td className="text-right">{d.data.grossMargin}</td>
                                        </tr>
                                        <tr>
                                            <td>Avg Marketing Fee / Case</td>
                                            <td className="text-right">{formatMoney(d.data.avgMarketingPerCase)}</td>
                                        </tr>
                                        <tr>
                                            <td>Anticipated Marketing Fees</td>
                                            <td className="text-right">{formatMoney(d.data.anticipatedMarketingFees)}</td>
                                        </tr>
                                        <tr>
                                            <td>Avg Gross Fee</td>
                                            <td className="text-right">{formatMoney(d.data.avgGrossFee)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </Col>

                    </Row>

                </Card>
            ))}
        </div>
    )
}


const mapStateToProps = state => {
    return {
        users : state.users.users,
    };
};

export default connect(mapStateToProps, '')(SigningOverview);
