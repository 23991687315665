import { architeckCall } from 'database';
import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { toggleStandardLoader } from 'store/functions/system/system';
import Bar from '../../_components/charts/Bar';
import Line from '../../_components/charts/Line';
import Pie from '../../_components/charts/Pie';
import Average from '../../_components/charts/Average';

import Controller from '../../_components/search/Controller'
import compileFilters from 'views/admin/pages/_functions/compileFilters'
import isValidRange from 'views/admin/pages/_functions/isValidRange'

import PropTypes from 'prop-types'

class GraphsMain extends Component {


    state = {
        interval: 'day'
    };

    onQueryChange = (queryData) => this.setState({queryData})

    onRunQuery = async () => {

        this.setState({dateError: undefined, queryError: undefined})

        // make sure data is set
        const queryData = this.state.queryData
        if(!queryData) return this.setState({queryError: 'You must add at least 1 data point to filter your search by.'})

        // make sure dates are valid
        const validRange = isValidRange(queryData.settings.range)
        if(validRange !== true) return this.setState({dateError: validRange})


        toggleStandardLoader(true)

        const analytics = await architeckCall({
            method: 'post',
            url: `/api/v1/analytics/graphs/query`,
            data: {
                settings: queryData.settings,
                filters: compileFilters(queryData.filters),
                setup: this.props.setup
            }
        })

        if(analytics.success) this.setState({ data: analytics.data, setup: this.props.setup})

        toggleStandardLoader(false)

    }

    renderGraph = (graph, i) => {

        let hasData = false;

        try {

            if(graph.type === 'average') {
                hasData = true
            } else {
                hasData = graph.data.datasets.find(d => d.data.find(v => v !== 0))
            }


        } catch(e) {}

        if(!hasData) return

        if(graph.type === 'bar' || graph.type === 'group') {

            return (
               <Col key={i} lg={graph.size}>
                    <Bar
                        title={graph.name ? graph.name : 'Bar Chart'}
                        data={graph.data}
                    />
                </Col>
            )

        } else if (graph.type === 'line') {

            return (
                <Col key={i} lg={graph.size}>
                     <Line
                         title={graph.name ? graph.name : 'Bar Chart'}
                         data={graph.data}
                     />
                 </Col>
             )

        } else if (graph.type === 'pie') {

            return (
                <Col key={i} lg={graph.size}>
                    <Pie
                        title={graph.name ? graph.name : 'Pie Chart'}
                        data={graph.data}
                    />
                 </Col>
             )

        } else if (graph.type === 'average') {

            return (
                <Col key={i} lg={graph.size}>
                     <Average
                         title={graph.name ? graph.name : 'Bar Chart'}
                         data={graph.data}
                     />
                 </Col>
             )

        }

    }

    render() {

        const { data, dateError, queryError } = this.state
        const { inputs, titleMain, titleFilter, filters } = this.props

        return (

            <>

            <Controller
                dateError={dateError}
                queryError={queryError}
                collection={this.props.collection}
                onQueryChange={this.onQueryChange}
                onRunQuery={this.onRunQuery}
                inputs={inputs}
                showOneFilter={false}
                titleMain={titleMain}
                titleFilter={titleFilter}
                filters={filters}
            />

            <Container fluid className="bg-secondary">

                <Row>
                    {data && data.length ? data.map((graph, i) => this.renderGraph(graph, i)) : null}
                </Row>

                {!data ? (
                    <h1 className="py-6 text-center">
                        <i className="fas fa-hand-point-up mr-2" />
                        Create A Query Above To View A Data Set
                        <i className="fas fa-hand-point-up  ml-2" />
                    </h1>
                ) : null}

            </Container>

            </>

        )

    }

}

GraphsMain.propTypes = {

    setup: PropTypes.array.isRequired,
    inputs: PropTypes.array.isRequired,

    collection: PropTypes.string.isRequired,
    titleFilter: PropTypes.string,
    titleMain: PropTypes.string,
    filters: PropTypes.array,

}

export default GraphsMain;
