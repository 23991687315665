import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input, Card, CardBody } from 'reactstrap';

import moment from 'moment';
import renderName from 'utils/renderName';
// import { formatMoney } from 'utils/currency'

import _analytics from '_functions/analytics'

import A from 'components/markup/links/A'
import QueryCreator from './QueryCreator';

import { getUrlParameter } from 'utils/urls'

import keys from 'keys';
import { formatMoney } from 'utils/currency';
import timeByValue from 'utils/timeByValue';
import Cookies from 'universal-cookie';

const QueryPayments = ({lead_sources, offices, workflows, divisions, brokers, call_centers, users}) => {

    const type = getUrlParameter('type') || 'created_at'
    const [total, setTotal] = useState(0)

    useEffect(() => {
        document.body.classList.add('analytics-sidebar-small')
        return () => {
            document.body.classList.remove('analytics-sidebar-small')
        }

    }, [])

    return (

        <QueryCreator 
            title="Case Reports"
            showCSV={true}
            previewMassEmail={async (query, cb) => {
                const cookies = new Cookies();
                const val = cookies.get('analytics_query_date');

                if(!query.hasRun) {
                    query.filter[type] = {
                        $gt: 0
                    }

                    if(val) {
                        const values = timeByValue(val);
                        console.log(values)
                        if(values.$gte) {
                            query.filter[type] = values
                        }
                    }
                }

                if(!query.hasRun) {
                    query.filter.deleted = false;
                }

                query.sort = type;
                const result = await _analytics.query.cases({...query, sendEmail: true, preview: true})
                return cb(result);
            }}
            sendMassEmail={async (query, subject, body) => {
                const cookies = new Cookies();
                const val = cookies.get('analytics_query_date');

                if(!query.hasRun) {
                    query.filter[type] = {
                        $gt: 0
                    }

                    if(val) {
                        const values = timeByValue(val);
                        console.log(values)
                        if(values.$gte) {
                            query.filter[type] = values
                        }
                    }
                }

                if(!query.hasRun) {
                    query.filter.deleted = false;
                }

                query.sort = type;
                const result = await _analytics.query.cases({...query, subject, body, sendEmail: true})
                console.log(result);
            }}
            searchPlaceholder="Name or ID"
            actionComponent={(
                <FormGroup className="mb-0 mr-3" style={{display: 'inline-block'}}>
                    <Input
                        value={type}
                        onChange={e => window.location.href = `/analytics/cases/reports?type=${e.target.value}`}
                        style={{maxWidth: 300, marginLeft: 'auto',}}
                        type="select"
                    >
                        <option value="created_at">Sort By Creation Date</option>
                        <option value="email_sent_at">Email Sent At</option>
                        <option value="call_scheduled_at">Call Scheduled At</option>
                        <option value="services_agreement_sent_at">Services Agreement Sent At</option>
                        <option value="retainer_signed_at">Retainer Signed At</option>
                        <option value="documents_submitted_at">Documents Submitted At</option>
                        <option value="report_sent_for_signature_at">Report Sent For Signature At</option>
                        <option value="submitted_to_irs_at">Submitted To IRS At</option>
                        <option value="payment_received_at">Payment Received At</option>
                    </Input>

                </FormGroup>
            )}
             
            runQuery={async (query, cb, download) => new Promise (async resolve => {

                const cookies = new Cookies();
                const val = cookies.get('analytics_query_date');

                if(!query.hasRun) {
                    query.filter[type] = {
                        $gt: 0
                    }

                    if(val) {
                        const values = timeByValue(val);
                        console.log(values)
                        if(values.$gte) {
                            query.filter[type] = values
                        }
                    }
                }

                if(!query.hasRun) {
                    query.filter.deleted = false;
                }

                query.sort = type;
                if(download) {

                    _analytics.query.caseReports(query)
                    return resolve(null)
                } else {
                    const result = await _analytics.query.cases(query)
                    if(result.data.total) setTotal(result.data.total)
                    return resolve(result.data)
                }
               
            })}
            inputs={[
                { type: 'ArchkDates', field: type, label: '', isPrimary: true, rangeTypes: [
                    {name: 'Creation Date', value: 'created_at'},
                    {name: 'Email Sent At', value: 'email_sent_at'},
                    {name: 'Call Scheduled At', value: 'call_scheduled_at'},
                    {name: 'Services Agreement Sent At', value: 'services_agreement_sent_at'},
                    {name: 'Retainer Signed At', value: 'retainer_signed_at'},
                    {name: 'Documents Submitted At', value: 'documents_submitted_at'},
                    {name: 'Report Sent For Signature At', value: 'report_sent_for_signature_at'},
                    {name: 'Submitted To IRS At', value: 'submitted_to_irs_at'},
                    {name: 'Payment Received At', value: 'payment_received_at'},
                ] },
                // {
                //     type: 'ArchkSelectSimple', field: 'original_workflow', label: 'Workflow',
                //     data: workflows.map(w => { return {value: w._id, text: w.type} })
                // },
                // {
                //     type: 'ArchkSelectSimple', field: 'division', label: 'Division',
                //     data: divisions.map(i => { return {value: i._id, text: i.name} })
                // },
                {
                    type: 'ArchkSelectSimple', field: 'original_current_step', label: 'Step Category',
                    data: [
                        { value: 'emailed', text: 'Emailed' },
                        { value: 'callScheduled', text: 'Call Scheduled' },
                        { value: 'callConducted', text: 'Call Conducted' },
                        { value: 'sentRetainer', text: 'Sent Retainer' },
                        { value: 'signedRetainer', text: 'Signed Retainer' },
                        { value: 'onHold', text: 'On Hold' },
                        { value: 'submitted', text: 'Submitted' },
                        { value: 'signature', text: 'Signature' },
                        { value: 'irs', text: 'IRS' },
                        { value: 'payment', text: 'Payment' },
                        { value: 'paymentFinished', text: 'Payment - Finished' },
                        { value: 'paymentReceipt', text: 'Payment - Receipt' },
                        { value: 'dead', text: 'Dead' },
                        { value: 'noShow', text: 'No Show' },
                    ]
                },

                // {
                //     type: 'ArchkSelectSimple', field: 'data_provider', label: 'Data Provider**',
                //     data: lead_sources.map(t => { return {value: t._id, text: t.name} })
                // },
                {
                    type: 'ArchkInArray', field: 'data_provider', label: 'Data Provider', query: 'in',
                    data: lead_sources.map(o => { return {value: o._id, text: o.name} })
                },
                {
                    type: 'ArchkSelectSimple', field: 'broker', label: 'Broker**',
                    data: brokers.map(t => { return {value: t._id, text: t.name} })
                },
                {
                    type: 'ArchkSelectSimple', field: 'attorney', label: 'Attorney**',
                    data: users.map(t => { return {value: t._id, text: renderName(t)} })
                },
                {
                    type: 'ArchkSelectSimple', field: 'call_center', label: 'Call Center**',
                    data: call_centers.map(t => { return {value: t._id, text: t.name} })
                },


                // {
                //     type: 'ArchkInArray', field: 'office', label: 'Offices', query: 'in',
                //     data: offices.map(o => { return {value: o._id, text: o.name} })
                // },
                // {
                //     type: 'ArchkInArray', field: 'tags', label: 'Tags', query: 'in',
                //     data: tags.map(t => { return {value: t._id, text: t.name} })
                // },
                // {
                //     type: 'ArchkSelectSimple', field: 'chapter', label: 'Chapter',
                //     data: [
                //         {value: 'Chapter 7 Full', text: 'Chapter 7 Upfront'},
                //         {value: 'Chapter 7 Emergency', text: 'Chapter 7 Zero Down'},
                //         {value: 'Chapter 13', text: 'Chapter 13'},
                //     ]
                // },
                { type: 'ArchkGreaterThan0', field: 'logged_in_at', label: 'Logged In' },
                // { type: 'ArchkGreaterThan0', field: 'sent_for_prep_on', label: 'Sent For Prep', defaultValue: type === 'sent_for_prep_on' ? 'yes' : '' },
                // { type: 'ArchkGreaterThan0', field: 'ch_13_post_filing_on', label: 'CH13 Post Filing', defaultValue: type === 'ch_13_post_filing_on' ? 'yes' : '' },
                // { type: 'ArchkGreaterThan0', field: 'ch_13_post_confirmation_on', label: 'CH13 Post Confirmation', defaultValue: type === 'ch_13_post_confirmation_on' ? 'yes' : '' },
                // { type: 'ArchkNull', field: 'finished_by', label: 'Finished', },

                { type: 'ArchkDates', field: 'last_viewed', label: 'Last Viewed'},

                // { type: 'ArchkNumber',  field: 'current_step_start', label: 'Step Start (UNIX Time)', },
                { type: 'ArchkNumber',  field: 'document_percentage', label: 'Document Percentage', },
                { type: 'ArchkNumber',  field: 'company_size', label: 'Company Size', },
                { type: 'ArchkNumber',  field: 'total_ertc', label: 'Total ERTC', },
                { type: 'ArchkNull',  field: 'calculated_total_fee', label: 'Total Fees', },
                { type: 'ArchkTruthy', subType: 'boolean', field: 'deleted', label: 'Deleted'},


    //             "last_note_preview": 1,
    // "last_viewed": 1,
    // "total_ertc": 1,
               
            ]}
            columns={[
                {
                    dataField: "name",
                    text: "Name",
                    formatter: (cell, row) => (
                        <div>
                            <A 
                                className="text-capitalize text-primary font-weight-bold" 
                                href={`${keys.APP_URL}/dashboard/cases/view/${row._id}`}
                            >
                                {row.name} 
                            </A>
                            <div className="text-capitalize">{row.last_note_preview}</div>
                        </div>
                    ),
                },
                {
                    dataField: "roles",
                    text: "Roles",
                    formatter: (cell, row) => (
                        <div>
                            {row.allRoles ? row.allRoles.map(role => {
                                const foundRole = row.roles.find(r => r.user === role._id)
                                return (
                                    <div className="text-capitalize">
                                        <div>{foundRole ? foundRole.role + ' - ' : ''} {renderName(role)}</div>
                                    </div>
                                )
                            }) : '-'}
                        </div>
                    ),
                },
                {
                    dataField: "contact",
                    text: "Contact",
                    formatter: (cell, row) => (
                        <div>
                            {row.contact ? (
                                 <A 
                                    className="text-capitalize text-primary font-weight-bold" 
                                    HREF={`${keys.APP_URL}/dashboard/contacts/view/${row.contact._id}`}
                                > 
                                    {renderName(row.contact)} 
                                </A>
                            ) : 'NOT FOUND'}
                            <div className="text-capitalize">Type: {row.type}</div>
                        </div>
                    ),
                },
                {
                    dataField: "current_step",
                    text: "Step",
                    formatter: (cell, row) => {

                        const step = row.finished_by ? 'FINISHED' : row.current_step ? row.current_step.name : 'No Assigned Step';
                        return (
                            <div>
                                <div className="font-weight-bold">{step}</div>
                                <div><b className="text-dark">Started:</b> {moment.unix(row.current_step_start).format('M/DD/YYYY - h:mm A')}</div>
                            </div>

                        )

                    }
                },
                {
                    dataField: "document_percentage",
                    text: "Doc %",
                    formatter: (cell, row) => (
                        <div className="font-weight-bold">
                            {row.document_percentage ? (
                                <span className={row.document_percentage >= 100 ? 'text-success' : row.document_percentage >= 0 ? 'text-info-original' : 'text-warning'}>
                                    {row.document_percentage.toFixed(2)}%
                                </span>
                            )
                            :  <span className="text-danger">0.00%</span>}
                            <div>{row.total_ertc ? formatMoney(row.total_ertc) : '$0.00'}</div>
                        </div>
                    )
                },
                {
                    dataField: "lead_sources",
                    text: "Sources",
                    formatter: (cell, row) => {
                        const provider1 = row.data_provider_1 ? lead_sources.find(l => l._id === row.data_provider_1) : ''
                        const provider2 = row.data_provider_2 ? lead_sources.find(l => l._id === row.data_provider_2) : ''
                        const provider3 = row.data_provider_3 ? lead_sources.find(l => l._id === row.data_provider_3) : ''

                        return (
                            <div>
                                {provider1 ? <div>{provider1.name}</div> : ''}
                                {provider2 ? <div>{provider2.name}</div> : ''}
                                {provider3 ? <div>{provider3.name}</div> : ''}
                            </div>
                        )


                    }
                },
                {
                    dataField: "company_name",
                    text: "Company DBA",
                    formatter: (cell, row) => row.company_name
                },
                {
                    dataField: "logged_in_at",
                    text: "Has Logged In",
                    formatter: (cell, row) => row.logged_in_at ? 'Yes' : 'No'
                },
                {
                    dataField: "updated_at",
                    text: "Size",
                    formatter: (cell, row) => row.company_size ? row.company_size : row.company_size_estimated
                },
                {
                    dataField: "_id",
                    text: 
                        type === 'email_sent_at' ? "Email Sent At / Viewed At" : 
                        type === 'call_scheduled_at' ? "Call Scheduled At / Viewed At" : 
                        type === 'services_agreement_sent_at' ? "Services Agreement Sent At / Viewed At" : 
                        type === 'retainer_signed_at' ? "Retainer Signed At / Viewed At" : 
                        type === 'documents_submitted_at' ? "Documents Submitted At / Viewed At" : 
                        type === 'report_sent_for_signature_at' ? "Report Sent For Signature At / Viewed At" : 
                        type === 'submitted_to_irs_at' ? "Submitted To IRS At / Viewed At" : 
                        type === 'payment_received_at' ? "Payment Received At / Viewed At" : 
                        "Created / Viewed At",
                    headerStyle: {textAlign: 'right'},

                    formatter: (cell, row) => (
                        <div>
                            <div className="text-right">{
                            moment.unix(
                                type === 'email_sent_at' ? row.email_sent_at : 
                                type === 'call_scheduled_at' ? row.call_scheduled_at : 
                                type === 'services_agreement_sent_at' ? row.services_agreement_sent_at : 
                                type === 'retainer_signed_at' ? row.retainer_signed_at : 
                                type === 'documents_submitted_at' ? row.documents_submitted_at : 
                                type === 'report_sent_for_signature_at' ? row.report_sent_for_signature_at : 
                                type === 'submitted_to_irs_at' ? row.submitted_to_irs_at : 
                                type === 'payment_received_at' ? row.payment_received_at :
                                row.created_at
                                ).format('MMM Do, YYYY')}</div>
                            <div className="text-right">{row.last_viewed ? moment.unix(row.last_viewed).format('M/D/YY h:mm A') : '-'}</div>
                        </div>
                    )
                },

            ]}
            addon={(
                <Card>
                    <CardBody>
                        Total ERTC: {formatMoney(total)}
                    </CardBody>
                </Card>
            )}
        
        />
    )

}



const mapStateToProps = state => {
    return {
        users: state.users.users,
        brokers: state.brokers.brokers,
        call_centers: state.call_centers.call_centers,
        offices: state.offices.offices,
        workflows: state.workflows.workflows,
        divisions: state.divisions.divisions,
        tags: state.tags.tags,
        lead_sources: state.lead_sources.lead_sources,
    };
};

export default connect(mapStateToProps, '')(QueryPayments);