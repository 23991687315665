import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Button, Card, CardFooter, CardHeader, CardTitle } from "reactstrap";
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';
import renderName from 'utils/renderName';
import _companies from '_functions/companies';

const settings = [
    'email_tasks_user',
    'email_tasks_admin',
    'email_communication_user',
    'email_communication_admin', 
    'email_contacts_created',
    'email_cases_created',
    'email_appointments_finished', 
    'email_appointments_missed',
    'email_documents_uploaded',
    'email_step_monitoring',
    'email_recurring_monitoring',
]

const CompanyEmails = ({users, company}) => {

    const [ state, setState ] = useState({});
    const [ loaded, setLoaded ] = useState(false);

    // load company data into the state
    useEffect(() => {
        const initialState = {};
        settings.forEach(setting => initialState[setting] = company[setting] ? company[setting] : []);
        setState(initialState);
        setLoaded(true);
    }, [])

    // handling clicking of a check box
    const setChecked = (stateName, user_id) => {
        let newState = Object.assign({}, state);
        let arr = [...newState[stateName]];

        if(arr.includes(user_id)) {
            arr = arr.filter(a => a !== user_id);
        } else {
            arr.push(user_id);
        }

        setState({...newState, [stateName]: arr});
    }
    
    // on button click send off current state to update the company settings
    const onSave = async () => {
        toggleStandardLoader(true)

        const updated = await _companies.update(company._id, state)

        if(updated.success) {
            toggleAlertBS(false, `Your report settings have been saved.`)
        } else {
            toggleAlertBS(true, `An error occurred updating your report settings.`)
        }

        toggleStandardLoader(false)
    }

    if(!loaded) return <div />;

    return (
        <Card>

            <CardHeader>
                <CardTitle className="mb-0">Email Reports</CardTitle>
            </CardHeader>

            <div className="table-responsive">
                <table className="table table-sticky-first-col">

                    <thead>
                        <tr>
                            <th>Name</th>
                            {settings.map((setting) => (
                                <th className="text-center text-capitalize">{setting.replace(/_/g, ' ').replace('email', '')}</th>
                            ))}
                        </tr>
                    </thead>

                    <tbody>
                        {users.map(user => (
                            <tr key={user._id}>

                                <td>
                                    <div className="text-capitalize"> {renderName(user)}</div>
                                    <div>{user.email ? user.email : 'no email found'}</div>
                                </td>

                                {settings.map((setting) => (
                                     <td key={setting} className="text-center">
                                        <div className="custom-control custom-checkbox">
                                            <input
                                                className="custom-control-input"
                                                id={`${setting}_${user._id}`}
                                                type="checkbox"
                                                onChange={() => setChecked(setting, user._id)}
                                                checked={state[setting].includes(user._id) ? true : false}
                                            />
                                            <label className="custom-control-label" htmlFor={`${setting}_${user._id}`} />
                                        </div>
                                    </td>
                                ))}

                            </tr>
                        ))}
                    </tbody>

                </table>
            </div>

            <CardFooter className="text-right">
                <Button color="success" onClick={onSave} >Save Report Settings</Button>
            </CardFooter>

        </Card>
    )

}

const mapStateToProps = state => {
    return {
        company: state.companies.company,
        users: state.users.users,
    };
};

export default connect(mapStateToProps, '')(CompanyEmails);


