import PropTypes from 'prop-types';
import React, { Component } from 'react';

class AutoEnd extends Component {

    render() {

        const { task, selected_task, toggleSidebar } = this.props;
        const title = task.automation_end ? task.automation_end.name : <span className="text-danger"><i className="fas fa-exclamation-triangle mr-2" /> Automation Not Found</span>

        return (

            <div className={selected_task === task._id ? "selected auto" : "auto"} >
                <div className="wrapper" onClick={() => toggleSidebar(true, task._id)}>
                    <div className="title">
                        <b><i className="fas fa-pause mr-3 text-danger" />End Automation: </b>
                        {title}
                    </div>
                </div>
            </div>

        )

    }

}

AutoEnd.propTypes = {
    task            : PropTypes.object.isRequired,
    toggleSidebar   : PropTypes.func.isRequired,
    selected_task   : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default AutoEnd;
