import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardTitle, FormGroup, Input, CardFooter, CardBody, Row, Col } from "reactstrap";
import { connect } from 'react-redux';

import role_types from '_settings/role_types'

const taskState = {
    name: 'Unsaved Task',
    type: 'text',
    default_billable_minutes: null,

}

const oneHour = 3600;

const EmailParserTasks = ({parser, setParser, tags, analytics_data_types, template_appointments}) => {

    const [ taskIndex, setTaskIndex ] = useState(0);
    const [ taskToEdit, setTaskToEdit ] = useState({});

    const setTaskToState = (task, taskIndex) => {
        setTaskToEdit(task);
        setTaskIndex(taskIndex);
    }

    const addTask = () => {
        const newState = Object.assign({}, parser);
        const newTask = Object.assign({}, {...taskState, to: [], appointment_users: []});

        const taskLength = parser.tasks.length;
        parser.tasks.push(newTask);

        setParser(newState);
        setTaskToState(newTask, taskLength)
    }

    const removeTask = (taskIndex) => {
        const newState = Object.assign({}, parser);
        const tasks = newState.tasks;

        tasks.splice(taskIndex, 1);

        if(tasks.length === 0) tasks.push(Object.assign({}, taskState))

        newState.tasks = tasks;

        setParser(newState);
        setTaskToState(tasks[0], 0)
    }

    const renderTaskRow = (task, index) => {

        const isActive = index === taskIndex;
        const unsaved = task.name === 'Unsaved Task';

        return (
            <tr key={index + task.name} className={isActive ? 'bg-secondary' : ''}>
                <td style={{width: '70%', wordBreak: 'break-all', whiteSpace: 'pre-line'}}>
                    {isActive ? (
                        unsaved ? (
                            <i className="fas fa-save text-warning mr-2 " /> 
                        ) : (
                            <i className="fas fa-check text-success mr-2 " /> 
                        )
                    ): null }
                    <span className="cursor-pointer" onClick={() => setTaskToState(task, index)}>
                        {task.name}
                    </span>
                </td>
                <td className="text-right">
                    <i onClick={() => setTaskToState(task, index)} className="fas fa-edit text-success mr-2 cursor-pointer" />
                    <i onClick={() => removeTask(index)} className="fas fa-trash text-danger cursor-pointer " />
                </td>
            </tr>
        )

    }

    const onChange = (e, stateName) => {

        let value = e.target.value;
        const newState = Object.assign({}, taskToEdit);

        if(stateName === 'default_billable_minutes') value = parseInt(value)

        newState[stateName] = value;

        if (value === "") {
            newState[stateName + "State"] = "invalid";
        } else {
            newState[stateName + "State"] = "valid";
        }

      
        setTaskToEdit(newState);

    }

  

    const isValid = () => {
        const state = Object.assign({}, taskToEdit);

        let errors = 0;

        const setError = (stateName) => {
            errors++;
            state[stateName + 'State'] = 'invalid'
        }

        if(!state.name || state.name === 'Unsaved Task') setError('name');
        if(!state.type) setError('type');

        if(state.type === 'custom data') {
            if(!state.analytics_data_type) setError('analytics_data_type');
        } else if(state.type === 'tag question') {
            if(!state.tag) setError('tag');
        } else if(state.type === 'appointment') {
            if(!state.template_appointment) setError('template_appointment');
        } else if(state.type === 'set case role') {
            if(!state.role) setError('role');
        } 
    
        if(errors) {
            setTaskToEdit(state);
            return false;
        }

        return true;
    }


    const saveTask = (task, index) => {
        const tasks = [...parser.tasks];
        tasks[index] = task;

        setParser({...parser, tasks});
    }
    
    const onsaveTask = () => {
        if(isValid()) saveTask(taskToEdit, taskIndex)
    }

    useEffect(() => {

        let taskToSet = parser.tasks[0];

        if(!taskToSet) {
            taskToSet = Object.assign({}, taskState);
            setParser({...Object.assign({}, parser), tasks: [Object.assign({}, taskState)]});
        }

        setTaskToState(taskToSet, 0);

    }, [])


    return (

        <Card>

            <CardHeader>
                <CardTitle className="mb-0">Create Tasks</CardTitle> 
            </CardHeader>

            <CardBody>

                <Row>
                    
                    <Col md={4} className="border-right">
                        <Row className="mb-3">
                            <Col xs={6} className="align-self-center">
                                <FormGroup className="mb-0">
                                    <label className="form-control-label mb-0">All Tasks</label>
                                </FormGroup>
                            </Col>
                            <Col xs={6} className="align-self-center text-right">
                                <button className="btn btn-outline-success" onClick={addTask}><i className="fas fa-plus mr-2 " /> Add</button>
                            </Col>
                        </Row>

                        <div className="table-responsive">
                            <table className="table table-bordered" style={{tableLayout: 'fixed'}}>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th className="text-right" style={{width: 75}}>Tasks</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {parser.tasks.map((task, index) => renderTaskRow(task, index))}
                                </tbody>
                            </table>
                        </div>

                    </Col>

                    <Col md={8}>
                        
                        <Row>

                            <Col lg={7}>
                                <FormGroup>
                                    <label className="form-control-label">{taskToEdit.type === 'party association' ? 'Name Of Party To Set *' : 'Task Name *'}</label>
                                    <Input 
                                        type="text"
                                        value={taskToEdit.name || ''}
                                        onChange={(e) => onChange(e, 'name')}
                                        valid={ taskToEdit.nameState === "valid" }
                                        invalid={ taskToEdit.nameState === "invalid" }
                                    />
                                </FormGroup>
                            </Col>

                            <Col lg={5}>
                                <FormGroup>
                                    <label className="form-control-label">Default Billable Minutes*</label>
                                    <Input 
                                        type="number"
                                        value={taskToEdit.default_billable_minutes ? parseInt(taskToEdit.default_billable_minutes) : '' }
                                        onChange={(e) => onChange(e, 'default_billable_minutes')}
                                        valid={ taskToEdit.default_billable_minutesState === "valid" }
                                        invalid={ taskToEdit.default_billable_minutesState === "invalid" }
                                    />
                                </FormGroup>
                            </Col>

                        </Row>

                        <FormGroup>
                            <label className="form-control-label">Type*</label>
                            <Input 
                                type="select"
                                value={taskToEdit.type || ''}
                                onChange={(e) => onChange(e, 'type')}
                                valid={ taskToEdit.typeState === "valid" }
                                invalid={ taskToEdit.typeState === "invalid" }
                            >
                                <option></option>
                                <option value="text">Text Entry</option>
                                <option value="upload">Document Upload</option>
                                <option value="appointment">Appointment</option>
                                <option value="tag question">Tag Question</option>
                                <option value="party association">Party Association</option>
                                <option value="custom data">Custom Data Entry</option>
                                <option value="set chapter">Set Chapter</option>
                                <option value="set case number">Set Case Number</option>
                                <option value="set case role">Set Case Role</option>
                                <option value="set assigned user">Set Assigned User</option>
                            </Input>

                        </FormGroup>

                   
                        {taskToEdit.type === 'set case role' ? (
                            <FormGroup>
                                <label className="form-control-label">Case Role Name*</label>
                                <Input 
                                    type="select"
                                    value={taskToEdit.role || ''}
                                    onChange={(e) => onChange(e, 'role')}
                                    valid={ taskToEdit.roleState === "valid" }
                                    invalid={ taskToEdit.roleState === "invalid" }
                                >
                                    <option value={null}></option>
                                    {role_types.map((role, i) => (
                                        <option key={i} value={role}>{role}</option>
                                    ))}
                                </Input>
    
                            </FormGroup>
                        ) : null}



                        {taskToEdit.type === 'appointment' ? (
                            <FormGroup>
                                <label className="form-control-label">Template Appointment*</label>
                                <Input 
                                    type="select"
                                    value={taskToEdit.template_appointment || ''}
                                    onChange={(e) => onChange(e, 'template_appointment')}
                                    valid={ taskToEdit.template_appointmentState === "valid" }
                                    invalid={ taskToEdit.template_appointmentState === "invalid" }
                                >
                                    <option value={null}></option>
                                    {template_appointments.map(appointment => (
                                        <option value={appointment._id} key={appointment._id}>{appointment.name}</option>
                                    ))}
                                </Input>

                            </FormGroup>
                        ) : null}

                        {taskToEdit.type === 'tag question' ? (
                            <FormGroup>
                                <label className="form-control-label">Tag To Set On Positive Question Answer*</label>
                                <Input 
                                    type="select"
                                    value={taskToEdit.tag || ''}
                                    onChange={(e) => onChange(e, 'tag')}
                                    valid={ taskToEdit.tagState === "valid" }
                                    invalid={ taskToEdit.tagState === "invalid" }
                                >
                                    <option value={null}></option>
                                    {tags && tags.length ? tags.map(a => (
                                        <option key={a._id} value={a._id}>{a.name} </option>
                                    )) : null}
                                </Input>

                            </FormGroup>
                        ) : null}

                        {taskToEdit.type === 'custom data' ? (
                             <FormGroup>
                                <label className="form-control-label">Custom Data Field*</label>
                                <Input 
                                    type="select"
                                    value={taskToEdit.analytics_data_type || ''}
                                    onChange={(e) => onChange(e, 'analytics_data_type')}
                                    valid={ taskToEdit.analytics_data_typeState === "valid" }
                                    invalid={ taskToEdit.analytics_data_typeState === "invalid" }
                                >
                                    <option value={null}></option>
                                    {analytics_data_types && analytics_data_types.length ? analytics_data_types.map(a => (
                                            a.deleted === false &&  a.collection_name === 'cases'  ?
                                                <option key={a._id} value={a._id}>{a.key}</option> : null
                                    )) : null}
                                </Input>
                            </FormGroup>
                        ) : null}

                        <FormGroup>
                            <label className="form-control-label">Required*</label>
                            <Input 
                                type="select"
                                value={taskToEdit.required || ''}
                                onChange={(e) => onChange(e, 'required')}
                                valid={ taskToEdit.requiredState === "valid" }
                                invalid={ taskToEdit.requiredState === "invalid" }
                            >
                                <option value={true}>True</option>
                                <option value={false}>False</option>
                            </Input>
                        </FormGroup>

                        <FormGroup>
                            <label className="form-control-label">Description</label>
                            <Input 
                                type="textarea"
                                value={taskToEdit.description || ''}
                                onChange={(e) => onChange(e, 'description')}
                                valid={ taskToEdit.descriptionState === "valid" }
                                invalid={ taskToEdit.descriptionState === "invalid" }
                            />
                        </FormGroup>

                        <FormGroup>
                            <label className="form-control-label">Assigned to (User Role)*</label>
                            <Input 
                                type="select"
                                value={taskToEdit.assigned_to || ''}
                                onChange={(e) => onChange(e, 'assigned_to')}
                                valid={ taskToEdit.assigned_toState === "valid" }
                                invalid={ taskToEdit.assigned_toState === "invalid" }
                            >
                                <option value={null}></option>
                                {role_types.map((role, i) => (
                                    <option key={i} value={role}>{role}</option>
                                ))}
                            </Input>
                        </FormGroup>

                        <FormGroup>
                            <label className="form-control-label">Due After Assignment</label>
                            <Input 
                                type="select"
                                value={taskToEdit.due_after_assignment || ''}
                                onChange={(e) => onChange(e, 'due_after_assignment')}
                                valid={ taskToEdit.due_after_assignmentState === "valid" }
                                invalid={ taskToEdit.due_after_assignmentState === "invalid" }
                            >
                                <option value={null}></option>
                                <option value={null}></option>
                                <option value={oneHour / 60}>1 Minute</option>
                                <option value={oneHour}>1 Hour</option>
                                <option value={oneHour * 2}>2 Hours</option>
                                <option value={oneHour * 3}>3 Hours</option>
                                <option value={oneHour * 6}>6 Hours</option>
                                <option value={oneHour * 12}>12 Hours</option>
                                <option value={oneHour * 24 * 1}>1 Day</option>
                                <option value={oneHour * 24 * 2}>2 Days</option>
                                <option value={oneHour * 24 * 3}>3 Days</option>
                                <option value={oneHour * 24 * 4}>4 Days</option>
                                <option value={oneHour * 24 * 5}>5 Days</option>
                                <option value={oneHour * 24 * 6}>6 Days</option>
                                <option value={oneHour * 24 * 7 * 1}>1 Week</option>
                                <option value={oneHour * 24 * 7 * 2}>2 Weeks</option>
                                <option value={oneHour * 24 * 7 * 3}>3 Weeks</option>
                                <option value={oneHour * 24 * 7 * 4}>4 Weeks</option>
                            </Input>
                        </FormGroup>


                    </Col>

                </Row>

            </CardBody>

            <CardFooter className="text-right">
                <button onClick={onsaveTask} className="btn btn-success"><i className="fas fa-save mr-2 " /> Save Task</button>
            </CardFooter>
            
        </Card>

    );
}

const mapStateToProps = state => {
    return {
    	users: state.users.users,
    	template_appointments: state.template_appointments.template_appointments,
    	tags: state.tags.tags,
        analytics_data_types: state.analytics_data_types.analytics_data_types,

    };
};

export default connect(mapStateToProps, '')(EmailParserTasks);