import ErrorUnknown from 'components/markup/errors/Unknown.js';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import Circle from 'components/markup/loading/Circle';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Badge, Card, CardBody, CardHeader, CardTitle, Container } from "reactstrap";
import _divisions from '_functions/divisions';
import ModalCreate from './ModalCreate';

const DivisionsUpdate = () => {

    const [divisions, setDivisions] = useState(null);
    const [err, setErr] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const onToggleModal = () => setShowModal(!showModal);

    const onSuccess = () => {
        onToggleModal();
        fetchDivisions()
    }

    const fetchDivisions = async () => {

        const divisions = await _divisions.find();
        if(divisions.data) {
            setDivisions(divisions.data)
        } else {
            setErr(true);
        }

    }

    useEffect(() => {
        fetchDivisions();
    }, [])

    if(err) return <ErrorUnknown />
    if(!divisions) return <Circle />


    return (
        <>
            <Container fluid>

                <Helmet>
                    <title>{`Divisions (${divisions.length}) `}</title>
                    <meta name="description" content="Divisions" />
                </Helmet>

                <DashHeaderOpen
                    title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
                    breadcrumb_1="Divisions"
                    breadcrumb_2="All"
                    actionComponent={(
                        <button onClick={onToggleModal} className="btn btn-success">
                            <i className="fas fa-plus mr-2 " />Add New
                        </button>
                    )}
                />

                <Card>

                    <CardHeader>
                        <CardTitle className="mb-0">All Divisions</CardTitle>
                    </CardHeader>

                    {divisions.length ? (

                        <div className="table-responsive">
                            <table className="table">
                                
                                <thead>
                                    <tr>
                                        <th>Division</th>
                                        <th>Active</th>
                                        <th className="text-right">Division</th>
                                        <th className="text-right">Edit</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {divisions.map(division => (
                                        <tr key={division._id}>
                                            <td>
                                                <Link to={`/core/divisions/view/${division._id}`}>
                                                    {division.name}
                                                </Link>
                                            </td>
                                            <td>
                                                {division.active ? (
                                                    <Badge color="success" className="text-sm"><i className="fas fa-check mr-2 " /> YES</Badge>
                                                ) : (
                                                    <Badge color="danger" className="text-sm"><i className="fas fa-times mr-2 " /> NO</Badge>
                                                )}
                                            </td>
                                            <td className="text-right">
                                                {moment.unix(division.created_at).format('MM/DD/YYYY h:MM A')}
                                            </td>
                                            <td className="text-right">
                                                <Link to={`/core/divisions/view/${division._id}`}>
                                                    <i className="fas fa-edit text-success cursor-pointer " />
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>

                            </table>
                        </div>

                    ) : (
                        <CardBody>
                            <p className="text-sm mb-0">
                                No divisions have been created yet.{' '}
                                <b className="text-warning font-weight-bold cursor-pointer" onClick={onToggleModal}> 
                                    Click here to create one.
                                </b>
                            </p>
                        </CardBody>
                    )}

                </Card>

                <ModalCreate 
                    showModal={showModal}
                    toggleModal={onToggleModal}
                    onSuccess={onSuccess}
                />

            </Container>

        </>
    )

}

export default DivisionsUpdate