/*
Documentation

this file creates a custom select based on data passed in

*/

import React, { Component } from 'react';
import { Input, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';

class ArchkTruthy extends Component {

    state = {

    };

    onChange = (e) => {

        let value = e.target.value

        this.setState({value})

        if(this.props.onChange) {
            this.props.onChange(value)
        }

    }

    render() {

        const { label,  type } = this.props

        return (

            <FormGroup>

                {label ? ( <label className="form-control-label mb-0">{label}</label> ) : null}

                <div className="archk-select">
                    <Input
                        // value={this.state.value || ''}
                        onChange={this.onChange}
                        type="select"
                    >

                        <option></option>

                        {type === 'yesno' ? (
                            <>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </>
                        ) : null}

                        {type === 'boolean' ? (
                            <>
                                <option value="true{bool}">True</option>
                                <option value="false{bool}">False</option>
                            </>
                        ) : null}

                    </Input>

                </div>

            </FormGroup>

        )

    }

}

ArchkTruthy.propTypes = {

    onChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['yesno', 'boolean']).isRequired,

}

export default ArchkTruthy;
