import { connect } from 'react-redux';
import { Card, CardHeader, CardTitle, Col, Row } from "reactstrap";

import renderName from 'utils/renderName';

import { formatMoney } from 'utils/currency';

const SigningOverview = ({allData, users, onSetBreakdown}) => {

    const data = JSON.parse(JSON.stringify(allData))
    data.sort((a, b) => a.data.totalSigned >= b.data.totalSigned ? -1 : 1)

    const renderRow = ({field, formatter}) => {
        const d = JSON.parse(JSON.stringify(data))
        d.sort((a, b) => {
            let aField = a.data[field] && typeof a.data[field] === 'string' ? a.data[field].replace('%', '') : a.data[field]
            let bField = b.data[field] && typeof b.data[field] === 'string' ? b.data[field].replace('%', '') : b.data[field]
            return parseFloat(aField) >= parseFloat(bField) ? -1 : 1
        })
        let markup = [];
        d.forEach(dd => {
            markup.push((
                <tr>
                    <td className="pl-6 text-capitalize">{renderName(users.find(u => u._id === dd.user))}</td>
                    <td className="text-right">{formatter(dd.data)}</td>
                </tr>
            ))
        })

        return markup

    }

    return (
        <div>

                <Card>

                    <CardHeader>
                        <Row>
                            <Col md={6}>
                                <CardTitle className="text-capitalize">User Stats</CardTitle>
                            </Col>
                            <Col md={6} className="text-right">
                                <button onClick={onSetBreakdown} className="btn btn-success">View Individually</button>
                            </Col>
                        </Row>
                    </CardHeader>
                    
                    <Row>

                        <Col lg={6}>

                            <div className="table-responsive">
                                <table className="table">
                                    {/* <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th className="text-right">Total</th>
                                        </tr>
                                    </thead> */}
                                    <tbody>
                                        <tr>
                                            <td className="text-dark font-weight-bold">Total Cases / Signed</td>
                                            <td></td>
                                        </tr>
                                        {renderRow({
                                            field: 'total',
                                            formatter: (_data) => _data.total + ' / ' + _data.totalSigned
                                        })}
                                        <tr>
                                            <td className="text-dark font-weight-bold">Close Rate</td>
                                            <td></td>
                                        </tr>
                                        {renderRow({
                                            field: 'closeRate',
                                            formatter: (_data) => _data.closeRate
                                        })}
                                       
                                        <tr>
                                            <td className="text-dark font-weight-bold">Emailed</td>
                                            <td></td>
                                        </tr>
                                        {renderRow({
                                            field: 'emailed',
                                            formatter: (_data) => (
                                                <div>
                                                    {((_data.emailed / _data.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                                    <span style={{display: 'inline-block', width: 30}}>
                                                    {_data.emailed}
                                                    </span>
                                                </div>
                                            )
                                        })}

                                        <tr>
                                            <td className="text-dark font-weight-bold">No Show</td>
                                            <td></td>
                                        </tr>
                                        {renderRow({
                                            field: 'noShow',
                                            formatter: (_data) => (
                                                <div>
                                                    {((_data.noShow / _data.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                                    <span style={{display: 'inline-block', width: 30}}>
                                                    {_data.noShow}
                                                    </span>
                                                </div>
                                            )
                                        })}

                                        <tr>
                                            <td className="text-dark font-weight-bold">Call Scheduled</td>
                                            <td></td>
                                        </tr>
                                        {renderRow({
                                            field: 'callScheduled',
                                            formatter: (_data) => (
                                                <div>
                                                    {((_data.callScheduled / _data.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                                    <span style={{display: 'inline-block', width: 30}}>
                                                    {_data.callScheduled}
                                                    </span>
                                                </div>
                                            )
                                        })}
                                       
                                        <tr>
                                            <td className="text-dark font-weight-bold">Call Conducted</td>
                                            <td></td>
                                        </tr>
                                        {renderRow({
                                            field: 'callConducted',
                                            formatter: (_data) => (
                                                <div>
                                                    {((_data.callConducted / _data.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                                    <span style={{display: 'inline-block', width: 30}}>
                                                    {_data.callConducted}
                                                    </span>
                                                </div>
                                            )
                                        })}
                                      
                                        <tr>
                                            <td className="text-dark font-weight-bold">Sent Retainer</td>
                                            <td></td>
                                        </tr>
                                        {renderRow({
                                            field: 'sentRetainer',
                                            formatter: (_data) => (
                                                <div>
                                                    {((_data.sentRetainer / _data.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                                    <span style={{display: 'inline-block', width: 30}}>
                                                    {_data.sentRetainer}
                                                    </span>
                                                </div>
                                            )
                                        })}
                                      
                                        <tr>
                                            <td className="text-dark font-weight-bold">Signed Retainer</td>
                                            <td></td>
                                        </tr>
                                        {renderRow({
                                            field: 'signedRetainer',
                                            formatter: (_data) => (
                                                <div>
                                                    {((_data.signedRetainer / _data.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                                    <span style={{display: 'inline-block', width: 30}}>
                                                    {_data.signedRetainer}
                                                    </span>
                                                </div>
                                            )
                                        })}
                                       
                                        <tr>
                                            <td className="text-dark font-weight-bold">Submitted</td>
                                            <td></td>
                                        </tr>
                                        {renderRow({
                                            field: 'submitted',
                                            formatter: (_data) => (
                                                <div>
                                                    {((_data.submitted / _data.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                                    <span style={{display: 'inline-block', width: 30}}>
                                                    {_data.submitted}
                                                    </span>
                                                </div>
                                            )
                                        })}
                                       
                                        <tr>
                                            <td className="text-dark font-weight-bold">Signature</td>
                                            <td></td>
                                        </tr>
                                        {renderRow({
                                            field: 'signature',
                                            formatter: (_data) => (
                                                <div>
                                                    {((_data.signature / _data.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                                    <span style={{display: 'inline-block', width: 30}}>
                                                    {_data.signature}
                                                    </span>
                                                </div>
                                            )
                                        })}
                                       
                                        <tr>
                                            <td className="text-dark font-weight-bold">IRS</td>
                                            <td></td>
                                        </tr>
                                        {renderRow({
                                            field: 'irs',
                                            formatter: (_data) => (
                                                <div>
                                                    {((_data.irs / _data.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                                    <span style={{display: 'inline-block', width: 30}}>
                                                    {_data.irs}
                                                    </span>
                                                </div>
                                            )
                                        })}
                                        <tr>
                                            <td className="text-dark font-weight-bold">Dead</td>
                                            <td></td>
                                        </tr>
                                        {renderRow({
                                            field: 'dead',
                                            formatter: (_data) => (
                                                <div>
                                                    {((_data.dead / _data.total) * 100).toFixed(2)}% <i className="fas fa-arrow-right ml-2 text-sm " />
                                                    <span style={{display: 'inline-block', width: 30}}>
                                                    {_data.dead}
                                                    </span>
                                                </div>
                                            )
                                        })}
                                    
                                    </tbody>
                                </table>
                            </div>
                        
                        </Col>

                        <Col lg={6}>

                            <div className="table-responsive">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td className="text-dark font-weight-bold">Current Net-Net Fees</td>
                                            <td></td>
                                        </tr>
                                        {renderRow({
                                            field: 'netFees',
                                            formatter: (_data) => formatMoney(_data.netFees)
                                        })}
                                       
                                        <tr>
                                            <td className="text-dark font-weight-bold">Anticipated Net-Net Fees</td>
                                            <td></td>
                                        </tr>
                                        {renderRow({
                                            field: 'anticipatedNetFees',
                                            formatter: (_data) => formatMoney(_data.anticipatedNetFees)
                                        })}

                                        <tr>
                                            <td className="text-dark font-weight-bold">Avg Net Fee Per Case</td>
                                            <td></td>
                                        </tr>
                                        {renderRow({
                                            field: 'avgNetPerCase',
                                            formatter: (_data) => formatMoney(_data.avgNetPerCase)
                                        })}

                                        <tr>
                                            <td className="text-dark font-weight-bold">Avg ERTC</td>
                                            <td></td>
                                        </tr>
                                        {renderRow({
                                            field: 'avgERTC',
                                            formatter: (_data) => formatMoney(_data.avgERTC)
                                        })}
                                       
                                        <tr>
                                            <td className="text-dark font-weight-bold">Acceptable Price / Lead</td>
                                            <td></td>
                                        </tr>
                                        {renderRow({
                                            field: 'acceptablePricePerLead',
                                            formatter: (_data) => formatMoney(_data.acceptablePricePerLead)
                                        })}

                                        <tr>
                                            <td className="text-dark font-weight-bold">Marketing Fees</td>
                                            <td></td>
                                        </tr>
                                        {renderRow({
                                            field: 'calculatedTotalFeeMarketing',
                                            formatter: (_data) => formatMoney(_data.calculatedTotalFeeMarketing)
                                        })}

                                        <tr>
                                            <td className="text-dark font-weight-bold">Gross Fees</td>
                                            <td></td>
                                        </tr>
                                        {renderRow({
                                            field: 'calculatedTotalFeeBiz',
                                            formatter: (_data) => formatMoney(_data.calculatedTotalFeeBiz)
                                        })}

                                        <tr>
                                            <td className="text-dark font-weight-bold">Anticipated Gross Fees</td>
                                            <td></td>
                                        </tr>
                                        {renderRow({
                                            field: 'anticipatedGrossFees',
                                            formatter: (_data) => formatMoney(_data.anticipatedGrossFees)
                                        })}

                                        <tr>
                                            <td className="text-dark font-weight-bold">Gross Margin</td>
                                            <td></td>
                                        </tr>
                                        {renderRow({
                                            field: 'grossMargin',
                                            formatter: (_data) => _data.grossMargin === 'NaN%' ? '-' : _data.grossMargin
                                        })}

                                        <tr>
                                            <td className="text-dark font-weight-bold">Avg Marketing Fee / Case</td>
                                            <td></td>
                                        </tr>
                                        {renderRow({
                                            field: 'avgMarketingPerCase',
                                            formatter: (_data) => formatMoney(_data.avgMarketingPerCase)
                                        })}
                                      
                                        <tr>
                                            <td className="text-dark font-weight-bold">Anticipated Marketing Fees</td>
                                            <td></td>
                                        </tr>
                                        {renderRow({
                                            field: 'anticipatedMarketingFees',
                                            formatter: (_data) => formatMoney(_data.anticipatedMarketingFees)
                                        })}

                                        <tr>
                                            <td className="text-dark font-weight-bold">Avg Gross Fee</td>
                                            <td></td>
                                        </tr>
                                        {renderRow({
                                            field: 'avgGrossFee',
                                            formatter: (_data) => formatMoney(_data.avgGrossFee)
                                        })}

                                    </tbody>
                                </table>
                            </div>

                        </Col>

                    </Row>

                </Card>

        </div>
    )
}


const mapStateToProps = state => {
    return {
        users : state.users.users,
    };
};

export default connect(mapStateToProps, '')(SigningOverview);
