/*
Documentation

this file creates a custom select based on data passed in

*/

import React, { Component } from 'react';
import { FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import ReactSelect from 'components/functional/inputs/ReactSelect';


class ArchkSelect extends Component {

    state = {
        filter: null,
        isSelected: false,
        lastSelectedText: null
    };

    onChange = (e) => {

        this.setState({ filter: e.target.value, isSelected: false })

        if(this.props.onInputChange) {
            this.props.onInputChange(e.target.value)
        }

    }

    onSelect = (data) => {

        this.props.onSelect(data.value)
        this.setState({filter: data.label, isSelected: true, lastSelectedText: data.label})

    }

    formatString = (string) => {

        return string.toLowerCase().trim()
    }

    filterData = (data) => {

        if(data && data.length) {

            const filter = this.state.filter;

            if(!filter) return data;

            return data.filter(d => this.formatString(d.text).includes(this.formatString(filter)))


        }

        return []

    }

    componentDidMount = () => {

        const data = this.filterData(this.props.data);
        let options = []


        data.forEach((d, i) => (
            options.push({
                label: d.text,
                value: d.value
            })
            // <li key={i} onClick={() => this.onSelect(d)}  >{d.text}</li>
        ))

        this.setState({options})

    }

    render() {

        const { label } = this.props
        const {options} = this.state

        return (

            <FormGroup>

                {label ? ( <label className="form-control-label mb-0">{label}</label> ) : null}

                <ReactSelect
                    placeholder={this.props.filter ? this.props.filter[this.props.field] : null}
                    onChange={(values) => this.onSelect(values) }
                    options={options ? options : []}
                />

            </FormGroup>

        )

    }

}

ArchkSelect.propTypes = {

    onSelect: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,

    onInputChange: PropTypes.func,

}

export default ArchkSelect;
