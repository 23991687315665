/*
Documentation

this page creates a user view a tab editor

*/

import StepTracker from 'components/functional/progress/StepTracker';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardHeader, Container, CardTitle, Row, Col } from "reactstrap";

import UserTypes from './components/UserTypes';
import Privileges from './components/Privileges';
import Review from './components/Review';
import Address from './components/Address';
import Billing from './components/Billing';
import Controller from './components/Controller';
import General from './components/General';

import _users from '_functions/users';
import { toggleStandardLoader } from 'store/functions/system/system'
import { validatePhoneNumber } from 'utils/validation';
import { toggleAlert } from 'store/functions/system/system';
import { connect } from 'react-redux';
import { formatPhone } from 'utils/text'

class UsersCreate extends React.Component {

    state = {
        user: {
            country: 'USA',
            privileges: [],
            user_types: [],
        },
        canMoveToStep: 1,
        step: 1,
        stepError: false
    }

    onChangeStep = async (step) => {

        this.setState({phoneExists: false, emailExists: false})

        let canMoveToStep = this.state.canMoveToStep
        if(step > canMoveToStep) { canMoveToStep = step }

        if(step === 2 && this.state.step === 1) {

            this.validateEmailAndPhone(step, canMoveToStep)

        } else {

            this.setState({step, canMoveToStep})

        }

    }

    // give an error if the email or phone that have been given are in the system
    validateEmailAndPhone = (step, canMoveToStep) => {


        const user = this.state.user;
        const phone = validatePhoneNumber(user.phone)

        // if the phone is not a valid number return
        if(!phone) return toggleAlert(true, 'warning', `${user.phone} is not a valid phone number`, 4000)

        // set loading
        toggleStandardLoader(true)

        // validate email and phone
        Promise.all([
            _users.findByPhone(phone),
            _users.findByEmail(user.email),
        ]).then(values => {

            toggleStandardLoader(false)

            const phoneExists = values[0]
            const emailExists = values[1]

            let errors = 0

            if(!phoneExists.success || phoneExists.data){
                this.setState({phoneExists: true});
                errors++;
            }

            if(!emailExists.success || emailExists.data) {
                this.setState({emailExists: true});
                errors++
            }

            // all is good, go to next step
            if(!errors) this.setState({step, canMoveToStep})

        })
    }

    onChangeByControllerStep = (step) => {

        if(step <= this.state.canMoveToStep) {

            this.setState({step})

        } else {

            this.setState({stepError: true}, () => {

                setTimeout(() => {
                    this.setState({stepError: false})
                }, 3000)

            })

        }

    }

    onSave = async () => {

        const user = this.state.user

        let formattedPhone = validatePhoneNumber(user.phone)
        user.phone = formattedPhone !== false ? formattedPhone : undefined
        user.created_by = this.props.viewing_user._id

        const created = await _users.create(user)

        if(created.success) {

            //add case to redux
            this.setState({shouldRedirect: '/core/users/all'})

        }

    }

    render() {

        if(this.state.shouldRedirect) { return <Redirect to={this.state.shouldRedirect} /> }

        const { step, user, stepError, showStepViewAlert,  emailExists, phoneExists } = this.state;

        return (

            <Container fluid>

                <Helmet>
                    <title>Staff Create</title>
                    <meta name="description" content="Staff Create" />
                </Helmet>

                <DashHeaderOpen
                    title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
                    breadcrumb_1="Staff"
                    breadcrumb_2="Create"
                    actionComponent={(
                        <Link  to="/core/users/all" className="btn btn-outline-warning"><i className="fas fa-arrow-left" /> All Staff</Link>
                    )}
                />

                {showStepViewAlert ? (<div className="alert alert-warning">You must finish your current step before moving on.</div> ) : null}

                <Card className="">

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">Create A Staff Member</CardTitle>
                    </CardHeader>

                    <CardBody>

                        <StepTracker
                            current={step}
                            onChangeStep={(step) => {this.onChangeByControllerStep(step)}}
                            steps={[
                                {name: 'General'},
                                {name: 'Address'},
                                {name: 'Billing'},
                                {name: 'Privileges'},
                                {name: 'Review'},
                            ]}
                        />

                    </CardBody>

                </Card>

                {stepError ? ( <div className="alert alert-danger">Please finish the current step before proceeding.</div> ) : null }
                {emailExists ? (<div className="alert alert-danger">An staff member already exists with this email address: {user.email}.</div> ) : null}
                {phoneExists ? (<div className="alert alert-danger">An staff member already exists with this phone number: {formatPhone(user.phone)}.</div> ) : null}

                <Card>

                    <CardHeader>
                        <CardTitle className="mb-0">
                            {step === 1 && 'General'}
                            {step === 2 && 'Address'}
                            {step === 3 && 'Billing'}
                            {step === 4 && 'Privileges'}
                            {step === 5 && 'Review'}
                        </CardTitle>
                    </CardHeader>

                    <CardBody>

                        {step === 1 ? (
                            <General
                                user={user}
                                onChange={(field, val) => this.setState({user: {...this.state.user, [field]: val}})}
                            />
                        ) : null}

                        {step === 2 ? (
                            <Address
                                user={user}
                                onChange={(field, val) => this.setState({user: {...this.state.user, [field]: val}})}
                            />
                        ) : null}

                        {step === 3 ? (
                            <Billing
                                user={user}
                                onChange={(field, val) => this.setState({user: {...this.state.user, [field]: val}})}
                            />
                        ) : null}

                        {step === 4 ? (
                            <Row>
                                <Col lg={6}>
                                    <Privileges
                                        user={user}
                                        onChange={(field, val) => this.setState({user: {...this.state.user, [field]: val}})}
                                    />
                                </Col>
                                <Col lg={6}>
                                    <UserTypes
                                        user={user}
                                        onChange={(field, val) => this.setState({user: {...this.state.user, [field]: val}})}
                                    />
                                </Col>
                            </Row>

                        ) : null}

                        {step === 5 ? (
                            <Review
                                user={user}
                                onChange={(field, val) => this.setState({user: {...this.state.user, [field]: val}})}
                            />
                        ) : null}

                    </CardBody>

                    <CardFooter>
                        <Controller
                            step={step}
                            user={user}
                            setUserState={(user) => this.setState({user})}
                            onChangeStep={(step) => {this.onChangeStep(step)}}
                            onSave={() => this.onSave()}
                            onChange={(field, val) => this.setState({user: {...this.state.user, [field]: val}})}
                        />
                    </CardFooter>

                </Card>

            </Container>

        );
    }
}


const mapStateToProps = state => {

    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(UsersCreate);
