import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import Circle from 'components/markup/loading/Circle';
import { architeckCall } from 'database';
import moment from 'moment';
import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import { Badge, Card, CardBody, CardHeader, CardTitle, Container } from "reactstrap";


import { io } from 'sockets';
import * as socketEvents from 'sockets/events';

const Entry = ({row}) => { 

    const [show, setShow ] = useState(false)

    return (
        <div>
            
            <div onClick={() => setShow(!show)} className="cursor-pointer">
               
              
                <div className="bg-secondary p-3 z-depth-1 rounded  font-weight-bold border" style={{whiteSpace: 'pre-line'}}>{row.logs[0]}</div>
            </div>

            {show ? (
                row.logs.map((log, index) => (
                    index > 0 ? <div className={index === 1 ? 'mt-3' : ''} style={{whiteSpace: 'pre-line'}}>{log}</div> : null
                ))
            ) : false}
            
        </div>
    )
}

class Logs extends React.Component {

    state = {
        loaded: false
    }

    columns = [

        {
            dataField: "created_at",
            text: "Time/Status",
            

            formatter: (cell, row) => (
                <div>
                     <b className="font-weight-bold text-dark text-capitalize">
                    <Badge className="text-sm mr-3" color={row.status === 'success' ? 'success' : row.status === 'error' ? 'danger' : 'warning'}>{row.status}</Badge>
                    </b>
                    {moment.unix(row.created_at).format('MM/DD/YY - h:mm A')} 
                </div>

            )
        },
        {
            dataField: "logs",
            text: "Request Logs",

            formatter: (cell, row) => {

                return <Entry row={row} />

            }
        },
       
    ]

    pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: false,

        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    listenForLogs = (data) => {
        const logs = [...this.state.logs];
        logs.unshift(data.data);
        this.setState({logs})
    }

    componentWillUnmount = () => {
        io.off(socketEvents.email_parser_logs_added, this.listenForLogs)
    }

    componentDidMount = async () => {


        const logs = await architeckCall({
            method: 'get',
            url: '/api/v1/core/email_parser_logs/find?sort=created_at__desc'
        })

        if(logs.success) this.setState({logs: logs.data})
        this.setState({loaded: true})

        io.on(socketEvents.email_parser_logs_added, this.listenForLogs)

    }

    render() {

        const { logs, loaded } = this.state

        if(!loaded) return <div className="pt-6"><Circle /></div>

        return (
            <>

            <Container  fluid>

                <Helmet>
                    <title>Email Parse Logs</title>
                    <meta name="description" content="Email Parse Logs" />
                </Helmet>

                <DashHeaderOpen
                    title={<span><i className="fas fa-magic mr-2 text-success " /> Automations</span>}
                    breadcrumb_1="Email Parsers"
                    breadcrumb_2="Logs"
                />

                <Card>
                    <CardBody>
                        <p className="text-sm mb-0">
                            <i className="fas fa-info text-info-original mr-2" /> 
                            This page shows up to the most recent 1,000 email parse logs.
                        </p>
                    </CardBody>
                </Card>

                <Card>

                    <CardHeader>
                        <CardTitle className="mb-0">All Email Parse Logs</CardTitle>
                    </CardHeader>

                    {logs && logs.length ? (

                        <ToolkitProvider
                            data={logs}
                            keyField="_id"
                            columns={this.columns}
                        >
                            {props => (
                                <div className=" table-responsive table-parent-bordered table-vertical-align table-not-fixed">
                                    <BootstrapTable
                                        pagination={this.pagination}
                                        {...props.baseProps}
                                        bootstrap4={true}
                                        bordered={false}
                                    />
                                </div>
                            )}
                        </ToolkitProvider>

                    ) : (

                        <CardBody>
                            There are no current request logs
                        </CardBody>
                    )}

                </Card>

            </Container>

            </>
        );
    }
}

export default Logs
