import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Card, CardFooter, Container } from 'reactstrap';
import ModalEditFolder from './ModalEditFolder';
import Circle from 'components/markup/loading/Circle';

import _document_folders from '_functions/document_folders';
import { toggleStandardLoader } from 'store/functions/system/system';
import { toggleAlertBS } from 'store/functions/system/system'

class DocumentFolders extends React.Component {

    state = {
        loaded            : false,
        showModal         : false,
        error             : null,
        editingFolderId   : null,
        foldersToShow     : [],
        deletedFolders    : [],
        documentFolders   : [],
        tree              : [{name: 'Root Folder'}],
    }

    toggleModal = (editingFolderId) => this.setState({showModal: !this.state.showModal, editingFolderId})

    onSave = async  () => {

        let documentFolders = this.state.documentFolders ? [...this.state.documentFolders] : []
        let deletedFolders = this.state.deletedFolders ? [...this.state.deletedFolders] : []

        const data = {
            documentFolders,
            deletedFolders,
        }

        toggleStandardLoader(true)

        const updated = await _document_folders.save(data)
        await this.setData()

        if(updated.success) {
            toggleAlertBS(false, 'Document folders updated successfully.')
        } else {
            toggleAlertBS(true, 'Document folders were not fully updated, contact support.')
        }

        toggleStandardLoader(false)

    }

    onDeleteFolder = (folder_id) => {

        let documentFolders = this.state.documentFolders ? [...this.state.documentFolders] : []
        const foundFolder = documentFolders.find(f => f._id === folder_id)

        if(!foundFolder) {

            this.setState({error: 'An unexpected folder tree error occurred, please contact support.'}, () => {
                setTimeout(() => this.setState({error: null}), 3000)
            })

        } else {

            const folderHasChildren = documentFolders.find(f => f.parent === foundFolder._id)

            if(folderHasChildren) {
                this.setState({error: 'You may not delete a folder with children. If you wish to delete this folder delete all its sub folders first.'}, () => {
                    setTimeout(() => this.setState({error: null}), 5000)
                })
            } else {

                let deletedFolders = this.state.deletedFolders ? [...this.state.deletedFolders] : []
                deletedFolders.push(foundFolder)

                documentFolders = documentFolders.filter(f => f._id !== folder_id)
                this.setState({deletedFolders, documentFolders}, () => this.setFoldersToShow(foundFolder.parent ? foundFolder.parent : null))

            }

        }

    }

    onUpdateName = (folderToEdit) => {

        // update the folder with the new name
        let documentFolders = this.state.documentFolders ? [...this.state.documentFolders] : []
        documentFolders[documentFolders.findIndex((f => f._id === folderToEdit._id))] = folderToEdit

        // set state and rerender the folders to reflect the new name
        this.setState({documentFolders}, () => this.setFoldersToShow(this.state.parent_folder_id))

    }

    onAddFolder = () => {

        let parent_id = this.state.parent_folder_id

        const documentFolders = this.state.documentFolders ? [...this.state.documentFolders] : [];

        // push folder to tree with correct parent and name, set id to be an string passed on second it was created plus document_folder length to prevent duplicate keys
        // all id's that are strings should be deleted when sent as they will be created. Mongo id's are counted as numbers so we store fake ids as strings
        const _id = ( Math.floor(new Date() / 1).toString() + documentFolders.length)


        documentFolders.push({
            _id,
            name: 'New Folder',
            parent : parent_id ? parent_id : null
        })

        this.setState({documentFolders}, () => {
            this.setFoldersToShow(parent_id)
            this.toggleModal(_id)
        })

    }

    setFoldersToShow = (parent_folder_id) => {

        const documentFolders = this.state.documentFolders ? [...this.state.documentFolders] : []
        let foldersToShow = []

        // if not folder id set the state to show the base folder, aka no folder
        if(!parent_folder_id) {

            foldersToShow = documentFolders.filter(f => !f.parent)
            foldersToShow.forEach(f => {
                f.hasSubFolders = documentFolders.find(otherFolders => otherFolders.parent === f._id) ? true : false
            })

            return this.setState({
                parent_folder_id,
                foldersToShow,
                tree: [{name: 'Root Folder'}],
            })

        }

        let tree = [...this.state.tree]

        // see if the branch passed in is found, if not return as this is an error
        let foundBranch = documentFolders.find(f => f._id === parent_folder_id);
        if(!foundBranch) return

        const isLastFolder = tree[tree.length -1]._id === parent_folder_id
        const folderIsInTree = tree.find(t => t._id === parent_folder_id)

        foldersToShow = documentFolders.filter(f => f.parent === parent_folder_id)

        foldersToShow.forEach(f => {
            f.hasSubFolders = documentFolders.find(otherFolders => otherFolders.parent === f._id) ? true : false
        })

        // if we clicked on the last folder or added a folder just set the documents to show
        if(isLastFolder) return  this.setState({foldersToShow})

        // if folder is in the tree, remove everything after the tree, else add it to the folder tree
        if(folderIsInTree) {
            tree = tree.slice(0, tree.findIndex(t => t => t._id === parent_folder_id) - 1)
        } else {
            tree.push(foundBranch)
        }

        // set the folder true to show the folder of the parent folder passed in
        return this.setState({parent_folder_id, foldersToShow, tree })

    }

    setData = async () => {

        const documentFolders = await _document_folders.find();

        if(documentFolders.data) {

            this.setState({documentFolders: documentFolders.data, loaded: true}, this.setFoldersToShow)

        } else {

            this.setFoldersToShow()
            this.setState({loaded: true})

        }

    }

    componentDidMount = async () => this.setData()

    render() {

        const { documentFolders, foldersToShow, tree, showModal, editingFolderId, error, loaded } = this.state

        if(!loaded) return <div className="py-6"><Circle /></div>

        return (
        <>

            <Helmet>
                <title>{`Document Folders`}</title>
                <meta name="description" content="Tags" />
            </Helmet>

            <Container className="document-tree" fluid>

                <DashHeaderOpen
                    title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
                    breadcrumb_1="Document Folders"
                    breadcrumb_2='All'
                />

                <div className="alert alert-warning z-depth-1">
                    <b className="font-weight-bold"><i className="fas fa-exclamation-triangle mr-2 " /> KNOWN ISSUE: </b> 
                    When adding folders make sure you save the document tree before you add a nested folder inside it or the folders will not link together correctly.
                </div>

                <Card>
                    <div className="table-responsive">
                        <table className="table">

                            <thead>
                                <tr>
                                    <td className="font-weight-bold">
                                        {tree.map((t, i) => (
                                            <span key={i} className="mr-3">
                                                {i === 0 ? null : <i className='text-success fas fa-arrow-right mr-3' /> }
                                                <span className="cursor-pointer" onClick={() => this.setFoldersToShow(t._id)}>{t.name}</span>

                                            </span>
                                        ))}
                                    </td>
                                    <td></td>
                                </tr>
                            </thead>

                            <tbody>
                                {foldersToShow && foldersToShow.length ? foldersToShow.map((folder, i) => (
                                     <tr key={i}>
                                        <td onClick={() => this.setFoldersToShow(folder._id)} >
                                            <i className={folder.hasSubFolders ? "fas fa-folder-open mr-2 text-warning"  : "far fa-folder mr-2" } />
                                            <span className="text-muted">{folder.name}</span>
                                        </td>
                                        <td className="text-right">
                                            <i onClick={() => this.toggleModal(folder._id)} className="cursor-pointer fas fa-edit text-success mr-2" />
                                            <i onClick={() => this.onDeleteFolder(folder._id)} className="cursor-pointer fas fa-trash text-danger mr-2" />
                                        </td>
                                    </tr>
                                )) : null }

                                <tr>
                                    <td className="cursor-pointer" onClick={this.onAddFolder}><i className="fas fa-folder-plus mr-2 text-success" /> Add A Folder</td>
                                    <td></td>
                                </tr>

                            </tbody>

                        </table>
                    </div>

                    <CardFooter className="text-right">
                        <button onClick={this.onSave} className="btn btn-success"><i className="fas fa-save mr-2" /> Save Document Tree</button>
                    </CardFooter>
                </Card>

                {error ? <div className="alert alert-danger">{error}</div> : null}

                <ModalEditFolder
                    showModal={showModal}
                    toggleModal={() =>  this.toggleModal()}
                    documentFolders={documentFolders}
                    editingFolderId={editingFolderId}
                    onUpdateName={(folderToEdit) => this.onUpdateName(folderToEdit)}
                />

            </Container>

            </>
        );
    }
}

export default DocumentFolders
