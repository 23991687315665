/*
Documentation

This page renders a table showing all open call queues with
the ability for the viewing user to assign a user the queue or
update the appointment if it is an appointment call queue

*/

import PropTypes from 'prop-types';
import Circle from 'components/markup/loading/Circle';
import moment from 'moment';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Card, CardBody, CardHeader, CardTitle, Row, Col, UncontrolledTooltip } from "reactstrap";
import { io } from 'sockets';
import * as socketEvents from 'sockets/events';
import * as _ from 'underscore';
import renderName from 'utils/renderName';
import _call_queue from '_functions/call_queue';
import _companies from '_functions/companies';
import { formatPhone } from 'utils/text'
import { connect } from 'react-redux';

import CountUp from 'components/markup/time/CountUp'

import NotificationDelete from 'components/functional/notifications/Delete';

import AppointmentsCrud from 'components/system/migrated/AppointmentsCrud';
import ModalAssign from './ModalAssign'

import keys from 'keys';
import A from 'components/markup/links/A';

class CallCenterAdminCallQueue extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            now: Math.floor(new Date() / 1000),
            startOfDay: parseInt(moment().startOf('day').format('X')),
            page: 1,
            sizePerPage: 10,
            total_documents: 0,
            data: null,
            queueToDelete: null,

            showModalAssignUser: false,
            showAppointmentsCrud: false,

            call_queue: null,
            appointment: null,
        }
       this.queryTableDataThrottled = _.throttle(this.queryTableData, 1500)
    }

    toggleModalAssignUser = (call_queue) => this.setState({showModalAssignUser: !this.state.showModalAssignUser, call_queue})
    toggleAppointmentsCrud = (call_queue) => this.setState({showAppointmentsCrud: !this.state.showAppointmentsCrud, call_queue})

    listenForQueueDeleted = (data) => {

        const deletedIds = data.data._ids
        let queues = this.state.data ? [...this.state.data] : []

        queues = queues.filter(q => deletedIds.includes(q._id) ? false : true)
        this.setState({data: queues})

    }

    listenForQueueAdded = (data) => {

        const now = Math.floor(new Date() / 1000)
        let queues = this.state.data ? [...this.state.data] : []

        const call_queue = data.data;

        const showBasedOnTime = call_queue.date <= now

        let foundIndex = queues.findIndex(q => q._id === data.data._id )

        if(foundIndex !== -1) {

            if(showBasedOnTime) {
                queues[foundIndex] = data.data
                queues.sort((a, b) => a.priority < b.priority ? -1 : 1)
            } else {
                queues.splice(foundIndex, 1)
            }


        } else {

            if(showBasedOnTime) {
                queues.push(data.data)
                queues.sort((a, b) => a.priority < b.priority ? -1 : 1)
            }

        }

        this.setState({data: queues})

    }

    componentDidMount = async () => {

        const queues = await _call_queue.search({
            search: undefined,
            limit: this.state.sizePerPage
        })

        this.setState({data: queues.data, total_documents: queues.total_documents})

        io.on(socketEvents.call_queue_deleted, this.listenForQueueDeleted)
        io.on(socketEvents.call_queue_added,   this.listenForQueueAdded)

    }

    componentWillUnmount = () => {

        io.off(socketEvents.call_queue_deleted, this.listenForQueueDeleted)
        io.off(socketEvents.call_queue_added,   this.listenForQueueAdded)

    }

    queryTableData = async (searchText, setPage1) => {

        this.setState({loading: true});
        if(setPage1) this.setState({page: 1})

        const cases = await _call_queue.search({
            search: searchText,
            limit: this.state.sizePerPage,
            skip: (this.state.page - 1) * this.state.sizePerPage
        })


        this.setState({
            data: cases.data,
            total_documents: cases.total_documents,
            canRun: false,
            loading: false,

        })

    }

    onTableChange = async (type, newState) => {

        const { searchText } = newState

        if(type === 'search') {

            this.queryTableDataThrottled(searchText, true)

        } else {

            this.setState({ page: newState.page, sizePerPage: newState.sizePerPage }, () => {
                this.queryTableDataThrottled(searchText)
            })

        }

    }

    onDeleteQueue = (_case) => this.onTableChange(null, this.state)

    onChangeCallRouting = () => {

        const { company } = this.props;

        let call_routing = company.call_routing === 'queue' ? 'all' : 'queue'

        _companies.update(this.props.company._id, { call_routing })

    }

    matchDivision = (division) => {
        return this.props.divisions.find(d => d._id === division)
    }

    columns = [
        {
            dataField: "contact",
            text: "Contact",
            formatter: (cell, row) => (
                <div>
                     {row.contact ? (
                        <A className="text-capitalize" href={`${keys.APP_URL}/dashboard/contacts/view/${row.contact._id}`}>
                            {row.contact.given_name || row.contact.family_name ?  (
                                <span>
                                    {row.contact.phone ? (
                                        <i className="fas fa-mobile text-success mr-2" />
                                    ) : (
                                        <>
                                            <i className="fas fa-exclamation-triangle text-danger mr-1" />
                                            <i className="fas fa-mobile text-danger mr-2" />
                                        </>
                                    )}
                                    {renderName(row.contact)}
                                </span>
                            ) : (
                                <span>
                                    <i className="fas fa-mobile mr-2 text-warning" />
                                    {formatPhone(row.contact.phone)}
                                </span>
                            )}
                        </A>
                    ) : 'NOT FOUND'}
                    {row.contact.blacklisted ? (
                        <span>
                            <i className="fas fa-exclamation-triangle text-danger ml-2" id={`contact-blacklist-${row.contact._id}`} />
                            <UncontrolledTooltip  key={row.created_at} delay={0} placement="top" target={`contact-blacklist-${row.contact._id}`}>
                                Blacklisted As Spam
                            </UncontrolledTooltip>
                        </span>
                    ) : null}
                    <div className="ml--2"> Priority: {row.priority}</div>
                </div>
            ),
        },
        {
            dataField: "contact.lead_source",
            text: "Source",

            formatter: (cell, row) =>  row.contact && row.contact.lead_source_name ? row.contact.lead_source_name : 'Other'

        },
        {
            dataField: "appointment",
            text: "Type",

            formatter: (cell, row) =>  {

                const matchedDivision = this.matchDivision(row.division);

                const division = matchedDivision ? (
                    <div><i className="fas fa-divide mr-1 text-info-original " /> {matchedDivision.name}</div>
                ) : null;

                return (
                    <div style={{width: 180, whiteSpace: 'pre-line'}}>
                        {row.appointment ? (
                            <div>
                                <A className="text-capitalize" to={`${keys.APP_URL}/dashboard/appointments/view/${row.appointment._id}`}> 
                                    {row.appointment.name} 
                                    {division}
                                </A>
                            </div>
                        ) : (
                            <div>
                                <div className="text-capitalize">{row.type}</div>
                                {division}
                           </div>
                        )}
                    </div>
                )
            }

        },
        {
            dataField: "date",
            text: "Time",
            formatter: (cell, row) =>  (
                <div className={row.date < this.state.startOfDay ? 'text-danger font-weight-bold' : row.date < this.state.now ? 'text-warning font-weight-bold' : 'null'}>
                    <div>{moment.unix(row.date).format('M/DD/YYYY')}</div>
                    <div>{moment.unix(row.date).format('h:mm A')}</div>
                    <div><CountUp timestamp={row.date} /></div>
                </div>
            )
        },
        {
            dataField: "created_at",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <i
                            onClick={() => this.toggleModalAssignUser(row)}
                            className={row.assigned_user ? 'cursor-pointer fas fa-user text-success mr-2' : 'cursor-pointer fas fa-user text-muted mr-2'}
                        />
                        {row.appointment ? (
                            <i onClick={() => this.toggleAppointmentsCrud(row)} className="cursor-pointer fas fa-calendar text-success" />
                        ): (
                            <i onClick={() => this.setState({queueToDelete: row})} className="cursor-pointer fas fa-trash text-danger" />
                        )}
                    </div>
                )
            }
        },

    ]

    render() {

        const { company  } = this.props
        let { data, searchText, call_queue, showModalAssignUser, showAppointmentsCrud, queueToDelete } = this.state;

        return (
            <>

                {queueToDelete && (
                    <NotificationDelete
                        deletionURL={`/api/v1/call_center/call_queue/delete/${queueToDelete._id}`}
                        confirmBtnText="Delete Queue Entry"
                        title={<span className="text-capitalize">{`${renderName(queueToDelete.contact)}`}</span>}
                        text="Deleting this queue entry will permanently remove it with no way to recover the entry in the future. Proceed with caution."
                        onClose={() => { this.setState({queueToDelete: null}) }}
                        onSuccess={this.onDeleteQueue}
                    />
                )}

                <Card className="card-color card-primary">

                    <CardHeader>

                        <Row>
                            <Col xs={6}>
                                <CardTitle className="mb-0 float-left">Call Queue</CardTitle>
                            </Col>
                            <Col xs={6} className="text-right">
                                {company.call_routing === 'queue' ? (
                                    <button onClick={this.onChangeCallRouting} className="btn btn-success" > Route Calls Normally </button>
                                ) : (
                                    <button onClick={this.onChangeCallRouting} className="btn btn-warning" > Route Calls To Queue </button>
                                )}
                            </Col>
                        </Row>
                    </CardHeader>

                    {data ? (
                        data.length || searchText ? (
                            <ToolkitProvider
                                data={data}
                                keyField="_id"
                                columns={this.columns}
                                search
                            >
                                {props => (
                                    <div className="table-not-fixed table-responsive table-vertical-align">
                                        <BootstrapTable
                                            pagination={paginationFactory({
                                                totalSize: this.state.total_documents,
                                                page: this.state.page,
                                                sizePerPage: this.state.sizePerPage,
                                                alwaysShowAllBtns: true,
                                                showTotal: false,
                                                withFirstAndLast: true,
                                                sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => false

                                            })}
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            bordered={false}
                                            remote={{
                                                search: true,
                                                pagination: true,
                                                sort: false,
                                                cellEdit: false
                                            }}
                                            onTableChange={this.onTableChange}

                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        ): (
                            <CardBody>
                                <p className="text-sm mb-0"> There is nothing to show in the call queue at this time. </p>
                            </CardBody>
                        )
                    ) : (
                       <Circle />
                    )}

                </Card>

                <ModalAssign
                    showModal={showModalAssignUser}
                    toggleModal={this.toggleModalAssignUser}
                    call_queue={call_queue}
                />

                {showAppointmentsCrud ? (
                    <AppointmentsCrud
                        toggleAppointmentsCrud={this.toggleAppointmentsCrud}
                        showAppointmentsCrud={showAppointmentsCrud}
                        appointment_id={call_queue && call_queue.appointment ? call_queue.appointment._id : null}
                        title={"Update Appointment"}
                        onSuccess={() => this.toggleAppointmentsCrud()}
                        isModal={true}
                    />
                ) : null }


            </>
        );
    }
}

CallCenterAdminCallQueue.propTypes = {
    company: PropTypes.object.isRequired
}

const mapStateToProps = state => {
    return {
        divisions : state.divisions.divisions,
    };
};

export default connect(mapStateToProps, '')(CallCenterAdminCallQueue);