// api/v1/analytics/query/consultations

import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import renderName from 'utils/renderName';

import _analytics from '_functions/analytics'

import A from 'components/markup/links/A'
import QueryCreator from './QueryCreator';

import keys from 'keys';

const QueryConsultations = ({users, lead_sources, viewing_user}) => {

    const getLeadSourceName = useCallback((_id) => {
        const source = lead_sources.find(l => l._id === _id);
        return source ? source.name : '-'
    }, [lead_sources])

    useEffect(() => {
        document.body.classList.add('analytics-sidebar-small')
        return () => {
            document.body.classList.remove('analytics-sidebar-small')
        }

    }, [])

    return (
        <QueryCreator 
            title="Consultations"
            hideSearch={true}
            runQuery={async (query, cb) => new Promise (async resolve => {
                // set payments to default to today only at first
                if(!query.hasRun) {

                    const date = moment().startOf('day');
                    query.filter.start = {
                        $gte: parseInt(date.format('X')),
                        $lt: parseInt(date.format('X')) + 86400,
                    }
                }
                query.filter.lead_source = viewing_user.lead_source

                const result = await _analytics.query.consultations(query)
                return resolve(result.data)
            })}
            inputs={[
                { type: 'ArchkDates', field: 'start', label: '', defaultValue: new Date() },

                {
                    type: 'ArchkSelectSimple', field: 'user', label: 'Consulting User',
                    data: users.map(u => {
                        return { value: u._id, text: renderName(u) }
                    })
                },
                {
                    type: 'ArchkSelectSimple', field: 'booking_user', label: 'Booking User',
                    data: users.map(u => {
                        return { value: u._id, text: renderName(u) }
                    })
                },
                {
                    type: 'ArchkSelectSimple', field: 'lead_source', label: 'Lead Source',
                    data: lead_sources.map(u => {
                        return { value: u._id, text: u.name }
                    })
                },
                {
                    type: 'ArchkSelectSimple', field: 'company_size', label: 'Company Size',
                    data: [
                        {value: '1 - 10', text: '1 - 10'},
                        {value: '11 - 20', text: '11 - 20'},
                        {value: '21 - 30', text: '21 - 30'},
                        {value: '31 - 50', text: '31 - 50'},
                        {value: '51 - 100', text: '51 -100'},
                        {value: '100+', text: '100+'},
                    ]
                },
                {
                    type: 'ArchkInArray', field: 'outcome', label: 'Outcomes', query: 'in',
                    data: [
                        {value: 'rescheduled', text: 'Rescheduled'},
                        {value: 'No Show - follow up needed', text: 'No Show - follow up needed'},
                        {value: 'No Show - cancelled - no follow up needed', text: 'No Show - cancelled - no follow up needed'},
                        {value: 'Showed - DNH - needs for information', text: 'Showed - DNH - needs for information'},
                        {value: 'Showed - Needs Agreement 20%', text: 'Showed - Needs Agreement 20%'},
                        {value: 'Showed - Needs Agreement 18%', text: 'Showed - Needs Agreement 18%'},
                        {value: 'Showed - Needs Agreement 17.5%', text: 'Showed - Needs Agreement 17.5%'},
                        {value: 'Showed - Needs Agreement 15%', text: 'Showed - Needs Agreement 2015'},
                        {value: 'Showed - needs follow up call with senior lawyer', text: 'Showed - needs follow up call with senior lawyer'},
                        {value: 'Showed - DNQ (not impacted)', text: 'Showed - DNQ (not impacted)'},
                        {value: 'Showed - DNQ (only W2 is owner/family member)', text: 'Showed - DNQ (only W2 is owner/family member)'},
                        {value: 'Showed - DNQ (not interested)', text: 'Showed - DNQ (not interested)'},
                        {value: 'Showed - DNQ (already applied)', text: 'Showed - DNQ (already applied)'},
                        {value: 'Manually Cancelled', text: 'Manually Cancelled'},
                    ]
                },
                { type: 'ArchkGreaterThan0', field: 'finished_on', label: 'Finished', defaultValue: '' },

            ]}
            columns={[
                {
                    dataField: "_id",
                    text: "Booking User / Lead Source",
                    formatter: (cell, row) => (
                        row.booking_user ? (
                            <div>
                                <div className="text-capitalize">{renderName(users.find(u => u._id === row.booking_user))}</div>
                                <div className="text-capitalize">{getLeadSourceName(row.lead_source)}</div>
                            </div>
                        ) : (
                            <div className="text-capitalize">{getLeadSourceName(row.lead_source)}</div>
                        )
                       
                    ),
                },
                {
                    dataField: "company_name",
                    text: 'Company Name',
                    formatter: (cell, row) => (
                        <div>{row.company_name}</div>
                    ),
                },
                {
                    dataField: "user",
                    text: "Contact / User",
                    formatter: (cell, row) => (
                        row.user && row.contact ? (
                            <div>
                                <span className="text-capitalize">{renderName(row.user)}</span>
                                 <div>
                                    <A href={`/dashboard/contacts/view/${row._id}`}>
                                        <span className="text-capitalize">{renderName(row.contact)}</span>
                                    </A>
                                 </div>
                            </div>
                        ) : '-'
                       
                    ),
                },

                {
                    dataField: "outcome",
                    text: "Outcome",
                    formatter: (cell, row) => (
                        <span className="text-capitalize">{row.outcome ? row.outcome : '-'}</span>
                    )
                },
                {
                    dataField: "company_size",
                    text: "Company Size",
                },
                {
                    dataField: "created_at",
                    text: "Created / Consultation Date",
                    formatter: (cell, row) => (
                        <div>
                            <div>{moment.unix(row.start).format("MM/DD/YYYY") + ' at ' + moment.unix(row.start).format("hh:mm A")}</div>
                            <div>
                                {moment.unix(row.created_at).format("MM/DD/YYYY") + ' at ' + moment.unix(row.created_at).format("h:mm A")}{' '}
                                {!row.outcome ? (
                                    <A href={`${keys.BOOK_URL}?reschedule=${row._id}&u=${viewing_user._id}&l=${row.lead_source ? row.lead_source : ''}`}>
                                        <i className="fas fa-calendar ml-2 text-info-original " />
                                    </A>
                                ) : null}
                            </div>
                        </div>
                    )
                },
                {
                    dataField: "finished_on",
                    text: "Status Marked AT",
                    formatter: (cell, row) => row.finished_on ? moment.unix(row.finished_on).format("M/D/YYYY") + ' at ' + moment.unix(row.finished_on).format("h:mm A") : '-'
                },

            ]}
        
        />
    )

}



const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
        users: state.users.users,
        lead_sources: state.lead_sources.lead_sources,
    };
};

export default connect(mapStateToProps, '')(QueryConsultations);