/*
Documentation

This page shows a list of all cases
via api cases can be searched by case name or _id

*/

import Circle from 'components/markup/loading/Circle';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import A from 'components/markup/links/A';
import { Card, CardBody, CardHeader, CardTitle, Col, Container, FormGroup, Input, Row } from "reactstrap";
import * as _ from 'underscore';
import { formatMoney } from 'utils/currency';
import renderName from 'utils/renderName';
import _cases from '_functions/cases';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';

import keys from 'keys';

class CasesTrust extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            sizePerPage: 25,
            total_documents: 0,
            data: null,
            caseToDelete: null,
            sort: '-1',
        }
       this.queryTableDataThrottled = _.throttle(this.queryTableData, 750)
    }

    columns = [
        {
            dataField: "case name",
            text: "Name",
            formatter: (cell, row) => (
                <div>
                    <a 
                        className="text-capitalize text-primary font-weight-bold" 
                        href={`${keys.APP_URL}/dashboard/cases/view/${row._id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {row.name} 
                    </a>
                </div>
            ),
        },
        {
            dataField: "contact",
            text: "Contact",
            formatter: (cell, row) => (
                <div>
                    {row.contact ? (
                         <A 
                            className="text-capitalize text-primary font-weight-bold" 
                            to={`${keys.APP_URL}/dashboard/contacts/view/${row.contact._id}`}
                        > 
                            {renderName(row.contact)} 
                        </A>
                    ) : 'NOT FOUND'}
                </div>
            ),
        },
        {
            dataField: "type",
            text: "Workflow",
            formatter: (cell, row) =>  <span className="text-capitalize">{row.type}</span>

        },
        {
            dataField: "current_step",
            text: "Step",
            formatter: (cell, row) => {

                if(row.finished_by) return <span className="text-success font-weight-bold">FINISHED</span>
                return row.current_step ? row.current_step.name : <span className="text-warning font-weight-bold">No Assigned Step</span>

            }
        },
        {
            dataField: "_id",
            text: "CASE ID",
        },
        {
            dataField: "case_number",
            text: "Case NUMBER",
            formatter: (cell, row) => row.case_number ? row.case_number : '-'
        },
        {
            dataField: "billing_in_trust",
            text: "Case Trust Amount",
            formatter: (cell, row) => formatMoney(row.billing_in_trust)
        },


    ]

    onChangeSort = (sort) => {

        this.setState({sort, searchText: ''}, () => {
            this.queryTableData('', true)
        })

    }

    queryTableData = async (searchText, setPage1) => {

        this.setState({loading: true});
        if(setPage1) this.setState({page: 1})

        const cases = await _cases.search({
            search: searchText,
            limit: this.state.sizePerPage,
            skip: (this.state.page - 1) * this.state.sizePerPage,
            sort: { billing_in_trust: parseInt(this.state.sort) },
            require_trust: true
        })

        this.setState({
            data: cases.data,
            total_documents: cases.total_documents,
            canRun: false,
            loading: false,
        })

    }

    onTableChange = async (type, newState) => {

        const { searchText } = newState

        if(type === 'search') {

            this.queryTableDataThrottled(searchText, true)

        } else {

            this.setState({ page: newState.page, sizePerPage: newState.sizePerPage }, () => {
                this.queryTableDataThrottled(searchText)
            })

        }

    }

    onDeleteCase = (_case) => this.onTableChange(null, this.state)

    // load first set of cases on load
    componentDidMount = async () => {

        const cases = await _cases.search({
            search: undefined,
            limit: this.state.sizePerPage,
            sort: { billing_in_trust: -1 },
            require_trust: true
        })

        this.setState({data: cases.data, total_documents: cases.total_documents})

    }

    render() {

        let { data, loading, searchText} = this.state;

        return (
            <>

            <Container fluid>
                
                <DashHeaderOpen         
                    title={<span><i className="fas fa-headset mr-2 text-success " />Analytics</span>}
                    breadcrumb_1="Cases"
                    breadcrumb_2="Trust"
                />

                <Helmet>
                    <title>{`Cases Trust`}</title>
                    <meta name="description" content="Cases" />
                </Helmet>

                <Card className="card-color card-primary">

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">All Cases</CardTitle>
                    </CardHeader>

                    {data ? (
                        data.length || searchText !== undefined ? (
                            <ToolkitProvider
                                data={data}
                                keyField="_id"
                                columns={this.columns}
                                search
                            >
                                {props => (
                                    <>
                                        <Row>
                                            <Col lg={6} className="align-self-center">
                                                <div className="dataTables_filter px-4 pb pt-3 pb-2 position-relative" >
                                                    <label>
                                                        Search:
                                                        <Input
                                                            className="form-control-sm"
                                                            value={this.state.searchText || ''}
                                                            placeholder="Name, ID, Number"
                                                            onChange={(e) => {
                                                                this.setState({searchText: e.target.value})
                                                                this.queryTableDataThrottled(e.target.value)
                                                            }}
                                                        />
                                                        {loading ? (<Circle style={{display: 'inline-block', top: 1, position: 'absolute'}} /> ) : null}

                                                    </label>
                                                </div>
                                            </Col>
                                            <Col lg={6} className="align-self-center text-right">
                                                <FormGroup className="d-inline">
                                                    <Input 
                                                        style={{width: 'auto'}}
                                                        className="d-inline mr-4"
                                                        type="select"
                                                        onChange={(e) => this.onChangeSort(e.target.value)}
                                                    >
                                                        <option value="-1">Sort By Highest Balance</option>
                                                        <option value="1">Sort By Lowest Balance</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <div className="table-not-fixed table-responsive table-vertical-align">
                                            <BootstrapTable
                                                pagination={paginationFactory({
                                                    totalSize: this.state.total_documents,
                                                    page: this.state.page,
                                                    sizePerPage: this.state.sizePerPage,
                                                    alwaysShowAllBtns: true,
                                                    showTotal: true,
                                                    withFirstAndLast: true,
                                                    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                                                        <div className="dataTables_length" id="datatable-basic_length">
                                                            <label>
                                                                Show{" "}
                                                                {
                                                                <select
                                                                    value={currSizePerPage}
                                                                    name="datatable-basic_length"
                                                                    aria-controls="datatable-basic"
                                                                    className="form-control form-control-sm"
                                                                    onChange={e => onSizePerPageChange(e.target.value)}
                                                                >
                                                                    <option value="10">10</option>
                                                                    <option value="25">25</option>
                                                                    <option value="50">50</option>
                                                                    <option value="100">100</option>
                                                                </select>
                                                                }{" "}
                                                                entries.
                                                            </label>
                                                        </div>
                                                    )
                                                })}
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                bordered={false}
                                                remote={{
                                                    search: true,
                                                    pagination: true,
                                                    sort: false,
                                                    cellEdit: false
                                                }}
                                                onTableChange={this.onTableChange}

                                            />
                                        </div>
                                    </>
                                )}
                            </ToolkitProvider>
                        ): (
                            <CardBody>
                                <p className="text-sm mb-0">There are no cases with a value in trust</p>
                            </CardBody>
                        )
                    ) : (
                       <Circle />
                    )}

                </Card>

            </Container>

            </>
        );
    }
}

export default CasesTrust
