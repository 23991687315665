import React, { Component } from 'react';
import { FormGroup, Input } from 'reactstrap';
import PropTypes from 'prop-types';

class ArchkString extends Component {


    state = {

    };

    onChange = (value) => {

        this.setState({value})
        this.props.onChange(value)
    }

    render() {

        const { label } = this.props;

        return (

            <>
                <FormGroup>

                    {label ? ( <label className="form-control-label mb-0">{label}</label> ) : null}

                    <Input
                        value={this.state.value || ''}
                        type="text"
                        onChange={(e) => this.onChange(e.target.value)}
                    />

                </FormGroup>

            </>

        )

    }

}


ArchkString.propTypes = {

    onChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,

}

export default ArchkString;
