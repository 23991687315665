import PropTypes from 'prop-types';
import React, { Component, useState, useCallback } from 'react';

import PreviewImage from './PreviewImage';
import PreviewPDF from './PreviewPDF';

import { toggleAlertBS } from 'store/functions/system/system';
import onDownloadDocument, { getImageAsBase64 } from '_functions/documents/download';

import keys from 'keys';

import Axios from 'axios';
import Circle from 'components/markup/loading/Circle'


const imageEndings = ['jpeg', 'jpg', 'png', 'tif', 'tiff', 'svg', 'gif', 'heic']

class Documents extends Component {

    state = {
        id: 'image-renderer-' + this.props.doc._id,
        showPreviewImageModal: false,
        showPreviewPDFModal: false,
        showHover: false,
    };

    togglePreviewImageModal = () => this.setState({showPreviewImageModal: !this.state.showPreviewImageModal})
    togglePreviewPDFModal = () => this.setState({showPreviewPDFModal: !this.state.showPreviewPDFModal})

    getIcon = (ending) => {

        if(imageEndings.includes(ending)) {
            return <i className="mr-3 fas fa-image text-warning" />
        }

        if(ending === 'pdf') {
            return <i className="mr-3 fas fa-file-pdf text-success" />
        }

        if(ending === 'docx') {
            return <i className="mr-3 fas fa-file-word text-primary" />
        }

        if(ending === 'xlsx') {
            return <i className="mr-3 fas fa-file-excel text-primary" />
        }

        if(ending === 'csv') {
            return <i className="mr-3 fas fa-file-csv text-primary" />
        }

        return <i className="mr-3 fas fa-file text-danger" />

    }

    getName = (name, ending) => {
        const maxNameLength = this.props.maxNameLength ? this.props.maxNameLength : 30
        return name ? name.length > maxNameLength ? name.slice(0,maxNameLength) + '...' + ending : name : 'unknown document'
    }

    onPreview = (doc, ending) => {

        if(imageEndings.includes(ending)) {
            this.togglePreviewImageModal()
            if(!this.props.src) this.props.getDoc('image');
        } else if(doc.url && doc.url.includes('.pdf')) {
            this.togglePreviewPDFModal()
            if(!this.props.src) this.props.getDoc('pdf');
        } else {
            toggleAlertBS('info', 'No preview is available for this document.')
        }
        

    }

    onHoverEnd = () => {
        this.setState({showHover: false})
    }
 
    onHover = () => {
        if(!this.props.preview) this.props.getPreview();
        this.setState({showHover: true})
    }
 
    componentWillUnmount = () => {
        document.getElementById(this.state.id).removeEventListener('mouseenter', this.onHover)
        document.getElementById(this.state.id).removeEventListener('mouseleave', this.onHoverEnd)

    }
  
    componentDidMount = () => {
        document.getElementById(this.state.id).addEventListener('mouseenter', this.onHover)
        document.getElementById(this.state.id).addEventListener('mouseleave', this.onHoverEnd)
    }

    render() {

        const { showPreviewImageModal, showPreviewPDFModal, showHover } = this.state
        const { doc, src, preview } = this.props

        const split = doc.friendly_name ? doc.friendly_name.split('.') : 'unknown.unknown'
        const ending = split[split.length - 1]

        const docName = this.getName(doc.friendly_name, ending)

        return (

            <>
                <span className="archk-image-renderer position-relative" id={this.state.id}>

                    {showHover ? (
                        <div className="pop-out z-depth-3">

                            <p className="bg-secondary">{docName}</p>

                            <div className="preview bg-secondary">
                                {imageEndings.includes(ending) || doc.thumbnail_url ? (
                                    preview ? (
                                        <div className="w-100 image-background" style={{backgroundImage: "url('"+preview+"')"}} />

                                    ) : <Circle />
                                    ) : (
                                    <div className="text-sm no-preview-available">No Preview Available</div>
                                )}
                            </div>

                            <div className="pop-out-toolbar">

                                <ul className="cursor-pointer">
                                    <span onClick={() => onDownloadDocument(doc)}>
                                        <li>Download File</li>
                                    </span>
                                    <li onClick={() => this.onPreview(doc, ending)}>Preview</li>

                                </ul>

                            </div>
                        </div>
                    ) : null}

                    <span>
                        {this.getIcon(ending)}
                        {docName}
                    </span>
                </span>

                <PreviewImage 
                    showModal={showPreviewImageModal}
                    toggleModal={this.togglePreviewImageModal}
                    doc={doc}
                    src={src}
                />

                <PreviewPDF 
                    showModal={showPreviewPDFModal}
                    toggleModal={this.togglePreviewPDFModal}
                    doc={doc}
                    src={src}
                />

            </>

        )

    }

}

const Wrapper = (props) => {

    const [preview, setPreview] = useState('')
    const [src, setSrc] = useState('')

    const fetchDocument = useCallback(async (doc_id, preview, cb) => {
        let url = keys.API_URL + `/api/v2/core/documents/${doc_id}/download`;
        if(preview) url += '?preview=true'
        const result = await Axios({
            method: 'get',
            url,
            responseType:'arraybuffer',
            headers: {
                authorization: `Bearer ${keys.SYSTEM_API_KEY}`
            },
            withCredentials: true,
        })

        if(result.headers && result.headers['content-type'].includes('application/json')) {
            try {
                var decodedString = String.fromCharCode.apply(null, new Uint8Array(result.data));
                var body = JSON.parse(decodedString);
                if(body.message && body.message.length) {
                    console.log(body.message)
                }
                return cb()
            } catch(e) {
                console.log(e)
                return cb();
            }

        }

        cb(result.data);
    }, [])

    const getPreview = useCallback(async () => {
        fetchDocument(props.doc._id, true, (data) => {
            setPreview(getImageAsBase64(data))
        })
    }, [props.doc._id])

    const getDoc = useCallback(async (type) => {
        fetchDocument(props.doc._id, false, (data) => {
            if(type === 'image') {
                return setSrc(getImageAsBase64(data))
            }
            setSrc(data)
        })
    }, [props.doc._id])

    return <Documents {...props} src={src} preview={preview} getPreview={getPreview} getDoc={getDoc} />
}

Documents.propTypes = {
    doc: PropTypes.object.isRequired,
    maxNameLength: PropTypes.number,
}

export default Wrapper
