import React, { useState, useCallback } from 'react';
import { Input, FormGroup, Badge } from 'reactstrap';

const ArchkInArray = ({label, data, onChange}) => {

    const [selected, setSelected] = useState([]);

    const onSetSelected = useCallback((newState) => {
        setSelected(newState)
        if(!newState.length) return onChange(undefined);
        onChange({$in: newState})
    }, [onChange])

    const onInputChange = useCallback((e) => {
        const value = e.target.value;
        if(value === '__false__') return;

        const newState = JSON.parse(JSON.stringify(selected));
        newState.push(value);
        onSetSelected(newState)
    }, [selected, onChange])

    const onRemove = useCallback((value) => {
        let newState = JSON.parse(JSON.stringify(selected));
        newState = selected.filter(s => s !== value)
        onSetSelected(newState)
    }, [selected, onChange])

    return (
        <FormGroup>
            <label className="form-control-label mb-0">{label}</label>
            <Input
                value={''}
                onChange={onInputChange}
                type="select"
            >
                <option value="__false__"></option>
                {data.map((d, i) => <option key={i} value={d.value}>{d.text}</option>)}
            </Input>

            <div className="mt-3">
                {selected.map((s, i) => (
                <Badge  key={i} color="success" className="d-block ml-0 text-left mt-2" style={{whiteSpace: 'pre-line'}}>
                    <span style={{maxWidth: '75%', display: 'inline-block'}}>{data.find(d => d.value === s).text} </span>
                    <i onClick={() => onRemove(s)} className="float-right cursor-pointer fas fa-times ml-2 px-3 " />
                </Badge>
                ))}
            </div>
        </FormGroup>
    )

}

export default ArchkInArray;
