import React, { useState, useEffect, useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import { Row } from 'reactstrap';

import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';

import ColFlow from './ColFlow'
import ColEdit from './ColEdit'
import ColOverview from './ColOverview'

import { v4 as uuidv4 } from 'uuid';

// change this out for state to induce a simulated stress test with 800 questions
// import Items from './Items'

import state from './state'

import _questionnaires from '_functions/questionnaires'

const MAX_SAVES = 50;
const REDIRECT_URL = '/core/questionnaires';

const QuestionnaireEdit = ({match}) => {

    const [redirect, setRedirect] = useState(0)
    // the current page we are viewing questions for
    const [page, setPage] = useState(0)
    // if we have loaded yet
    const [loaded, setLoaded] = useState(false)
    // full questionnaire without questions property
    const [questionnaire, setQuestionnaire] = useState({})
    // saves state to use for back button
    const [prevQuestions, setPrevQuestions] = useState([])
    // all questions of the questionnaire
    const [questions, setQuestions] = useState([]);
    // const [questions, setQuestions] = useState(Items);
    // the selected question
    const [selected, setSelected] = useState({
        question: {},
        parentTree: []
    });

    // saves current question to prevQuestions array
    const saveState = (newQuestions) => {
        let prevQuestionsCopy = [...prevQuestions];

        prevQuestionsCopy.splice(0, 0, newQuestions ? newQuestions : questions)
        if(prevQuestionsCopy.length > MAX_SAVES) prevQuestionsCopy.splice(prevQuestionsCopy.length -1, 1);

        setPrevQuestions(prevQuestionsCopy)
    }

    const onPageChange = (page) => {
        removeUnsavedQuestions();
        setPage(page)
    }

    // handles changes of value on the questionnaire
    const onQuestionnaireChange = (e, field) => {
        let value = e.target.value;

        if(value === 'yes') value = true;
        if(value === 'no')  value = false;

        const q = Object.assign({}, questionnaire);
        q[field] = value;

        setQuestionnaire(q)
    }

    // try to highlight the current selected question
    const tryHighlight = useCallback((question) => {

        if(!question) question = selected.question

        const lastSelected = document.querySelector(`.archk-selected-question`);
        const nextSelected = document.querySelector(`[data-id="${question.id}"]`);
        const input = document.getElementById('archk-question-description')

        if(lastSelected) {
            lastSelected.classList.remove('archk-selected-question')
        }

        if(nextSelected) {
            nextSelected.classList.add('archk-selected-question');
            if(input) input.focus();
        } else if(question.id) {
            setTimeout(() => {
                tryHighlight(question)
            }, 300)
        }
    }, [selected.question._id])

    // add a new question to the branch we are on
    const onQuestionAdded = (newQuestions, parentTree) => {

        saveState();
        const q = [...newQuestions];

        const newQuestion = {
            ...JSON.parse(JSON.stringify(state.question)),
            id: uuidv4(),
            page
        }

        q.push(newQuestion);
        
        onSelect(newQuestion, q, parentTree)
        setMasterQuestions(q, parentTree);
    }

    // remove question from state
    const onQuestionDeleted = useCallback((index, questions, parentTree) => {
        saveState();
        onSelect();
        setMasterQuestions(questions, parentTree);
    }, [questions]);

    // updates a question
    const onQuestionSaved = useCallback((question, newQuestions, parentTree) => {
        saveState()
        setMasterQuestions(newQuestions, parentTree);
        tryHighlight(question)
    }, [questions])

    // fired when drag ends
    const onDragEnd = useCallback((newQuestions, parentTree) => {
        onSelect();
        // setMasterQuestions(newQuestions, parentTree)
    },[])

    // fired when drag start
    const onDragStart = (newQuestions, parentTree) => {
        saveState()
    }

    // saves a question to state with the question as an object and 
    // the map of the questions parent tree
    const onSelect = useCallback((question, questionSet, parentTree) => {

        question = question ? question : {};

        tryHighlight(question);

        setSelected({
            question: question,
            parentTree: parentTree ? parentTree : [],
            questionSet: questionSet ? questionSet : [],
        })
    }, [])

    // set state to last saved state when back button clicked
    const onBack = useCallback(() => {
        let prevQuestionsCopy = [...prevQuestions];
        
        if(prevQuestionsCopy[0]) {
            setMasterQuestions(prevQuestionsCopy[0], []);

            prevQuestionsCopy.splice(0, 1);
            setPrevQuestions(prevQuestionsCopy);

            onSelect();


        }
    }, [prevQuestions])

    // copies over any changes made to new master state of the questions list
    const setMasterQuestions = useCallback((newQuestions, parentTree) => {

        // if we've changed the master questions branch we can set it right away
        if(!parentTree.length) {
            return setQuestions(newQuestions)
        }

        // let questionsCopy = [...questions];
        let questionsCopy = JSON.parse(JSON.stringify(questions));

        // the the full question set and the found question in the tree
        let questionSet = questionsCopy;
        let parentQuestion = {};

        // for each parent go down the tree setting the question set and parent question
        for (let i = 0; i < parentTree.length; i++) {
            const parentId = parentTree[i];

            parentQuestion = questionSet.find(set => set.id === parentId);
            questionSet = parentQuestion.questions
        }

        // set the questions of the deepest parent to be the new questions
        parentQuestion.questions = newQuestions

        return setQuestions(questionsCopy)

    }, [questions]);

    // removes any questions that have not been saved. If a user hits add 
    // question it will go to state as a new question which can affect the form if left in.
    // A question cannot be saved without a description so checking for that value
    // works as a way to remove any bad question left in state
    const removeUnsavedQuestions = () => {

        const allQuestions = JSON.parse(JSON.stringify(questions));

        const remove = (questions) => {
            // for each question
            questions.forEach((question, i) => {
                if(!question.description) {
                    // if we dont have a description remove it from the array
                    questions.splice(i, 1);
                }

                // if questions has nested questions run the same function for them
                if(question.questions.length) {
                    remove(question.questions);
                }

            })
        }

        // run removal and set filtered questions to state
        remove(allQuestions);
        setQuestions(allQuestions);
        return allQuestions;
    }

    // make sure that any page that has a question also has at least one question
    // on the page before it.
    const pagesLink = useCallback((questions) => {

        const hasPage4 = questions.some(question => question.page === 3);
        const hasPage3 = questions.some(question => question.page === 2);
        const hasPage2 = questions.some(question => question.page === 1);
        const hasPage1 = questions.some(question => question.page === 0);

        if(hasPage4 && !hasPage3) {
            toggleAlertBS('info', 'At least one question exists on page 4 but no questions exist on page 3')
            return false;
        }
        if(hasPage3 && !hasPage2) {
            toggleAlertBS('info', 'At least one question exists on page 3 but no questions exist on page 2')
            return false;
        }
        if(hasPage2 && !hasPage1) {
            toggleAlertBS('info', 'At least one question exists on page 2 but no questions exist on page 1')
            return false;
        }

        return true;

    }, [])

    // require the default properties of a questionnaire
    const questionnaireIsValid = useCallback((questionnaire, questions) => {

      
        if(!questionnaire.name) {
            toggleAlertBS('info', 'This questionnaire must have a name.');
            return false;
        }

        if(questionnaire.active && !questions.length) {
            toggleAlertBS('info', 'To set this questionnaire live add at least one question.');
            return false;
        }

        return true;

    }, [])

    // 1. save state as question will be removed
    // 2. check fields are valid and all pages link
    // 3. either update or create the form
    // 4. if creating a form redirect to the all questionnaires page
    const onSave = async () => {

        saveState();

        const sanitizedQuestions = removeUnsavedQuestions();

        if(
            questionnaireIsValid(questionnaire, sanitizedQuestions) &&
            pagesLink(sanitizedQuestions)
        ) {

            toggleStandardLoader(true);

            const data = { ...questionnaire, questions: sanitizedQuestions }

            // update existing
            if(questionnaire._id) {
                const saved =  await _questionnaires.update(questionnaire._id, data);
                if(saved.success) {
                    toggleAlertBS(false, 'Questionnaire saved successfully.');
                } else {
                    toggleAlertBS(true, saved.message)
                }
            // save new
            } else {
                const saved =  await _questionnaires.create(data);
                if(saved.success) {
                    toggleAlertBS(false, 'Questionnaire saved successfully.');
                    setRedirect(REDIRECT_URL);
                } else {
                    toggleAlertBS(true, saved.message)
                }
            }

            toggleStandardLoader(false);

        }

    }

    // fake an id property for every question recursively
    const setIds = useCallback((allQuestions) => {
        const setIds = (questions) => {
            questions.forEach(question => {
                question.id = uuidv4();
                // run recursively if branch questions exist
                if(question.questions) {
                    setIds(question.questions)
                }
            })
        }

        setIds(allQuestions)
        return allQuestions;
    }, [])

    // return questionnaire from database if _id is set
    const fetchQuestionnaire = useCallback(async(_id) => {
        const questionnaire = await _questionnaires.findById(_id);
        if(!questionnaire.success || !questionnaire.data) return setRedirect(REDIRECT_URL);

        setQuestionnaire({
            _id               : questionnaire.data._id,
            name              : questionnaire.data.name,
            active            : questionnaire.data.active,
            create_pdf        : questionnaire.data.create_pdf,
            send_emails_to    : questionnaire.data.send_emails_to,
        });

        setQuestions(setIds(questionnaire.data.questions))
        setLoaded(true);
    }, [])

    useEffect(() => {

        document.body.classList.add('analytics-sidebar-small');
        document.body.classList.add('bg-white');

        const _id = match.params._id

        // if _id is new we are creating a new questionnaire
        if(_id === 'new') {
            setQuestionnaire(JSON.parse(JSON.stringify(state.questionnaire)));
            setLoaded(true);
        } else {
            fetchQuestionnaire(_id);
        }
        
        return () => {
            document.body.classList.remove('analytics-sidebar-small');
            document.body.classList.remove('bg-white');
        }

    }, [])

    if(redirect) return <Redirect to={redirect} />
    if(!loaded) return <div />

    return (
        <div id="page-questionnaire-edit">
            <Row>
                <ColFlow 
                    setMasterQuestions={setMasterQuestions}
                    questions={questions} 
                    onSelect={onSelect} 
                    onBack={onBack}
                    onDragEnd={onDragEnd}
                    onDragStart={onDragStart}
                    onQuestionAdded={onQuestionAdded}
                    prevQuestionLength={prevQuestions.length}
                    page={page}
                    onPageChange={onPageChange}
                />
                
                <ColEdit 
                    selected={selected}
                    onQuestionSaved={onQuestionSaved}
                    onQuestionDeleted={onQuestionDeleted}
                />

                <ColOverview 
                    questionnaire={questionnaire}
                    onQuestionnaireChange={onQuestionnaireChange}
                    onSave={onSave}
                />
                
            </Row>
        </div>
    )

}

export default QuestionnaireEdit