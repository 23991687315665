/*
Documentation

*/

import { useEffect, useCallback, useState } from "react";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Card, CardHeader, CardBody, CardTitle, CardFooter, FormGroup, Input } from "reactstrap";
import Circle from 'components/markup/loading/Circle'

import _signing from '_functions/signing'

import moment from 'moment'

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';


const Ident = () => {

    const [data, setData] = useState(null);

    const fetchData = useCallback(async () => {
        const _data = await _signing.ident();
        console.log(_data)
        if(_data.data) {
            const sigConfirmed = _data.data.filter(d => d.signature_confirmed)
            const opened = _data.data.filter(d => d.opened)
            const unopened = _data.data.filter(d => !d.opened)
            setData({sigConfirmed, unopened, opened})
        }

    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(!data) return <Circle />

    return (
        <Container fluid>

            <Helmet>
                <title>{`Documents`}</title>
                <meta name="description" content="Documents" />
            </Helmet>

            <DashHeaderOpen
                title={<span><i className="fas fa-headset mr-2 text-success " />Analytics</span>}
                breadcrumb_1="Documents"
            />

            <Card>
                <CardBody>
                    All unsigned documents related to cases where iDent is a data source
                </CardBody>
            </Card>

            <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Signature confirmed & Document Not Finalized</CardTitle>
                </CardHeader>

                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Case</th>
                                <th>Status</th>
                                <th className="text-right">Sent</th>
                            </tr>
                        </thead>
                        <tbody>
                        {data.sigConfirmed.length ? data.sigConfirmed.map(d => (
                                <tr>
                                    <td>
                                        <div className="text-capitalize">{d.case.name}</div>
                                        {d.case.company_dba ? <div>{d.case.company_dba }</div> : ''}
                                    </td>
                                    <td>Signature Confirmed</td>
                                    <td>{moment.unix(d.created_at).format('MM/DD/YYYY h:mm A')}</td>
                                </tr>

                            )) : (
                                <tr>
                                    <td>No Data</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </Card>

            

            <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Opened Agreements</CardTitle>
                </CardHeader>

                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Case</th>
                                <th>Status</th>
                                <th className="text-right">Sent</th>
                            </tr>
                        </thead>
                        <tbody>
                        {data.opened.length ? data.opened.map(d => (
                                <tr>
                                    <td>
                                        <div className="text-capitalize">{d.case.name}</div>
                                        {d.case.company_dba ? <div>{d.case.company_dba }</div> : ''}
                                    </td>
                                    <td>Opened</td>
                                    <td>{moment.unix(d.created_at).format('MM/DD/YYYY h:mm A')}</td>
                                </tr>

                            )) : (
                                <tr>
                                <td>No Data</td>
                                <td></td>
                                <td></td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                </div>

            </Card>

            

            <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Unopened Agreements</CardTitle>
                </CardHeader>

                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Case</th>
                                <th>Status</th>
                                <th className="text-right">Sent</th>
                            </tr>
                        </thead>
                        <tbody>
                        {data.unopened.length ? data.unopened.map(d => (
                                <tr>
                                    <td>
                                        <div className="text-capitalize">{d.case.name}</div>
                                        {d.case.company_dba ? <div>{d.case.company_dba }</div> : ''}
                                    </td>
                                    <td>Unopened</td>
                                    <td>{moment.unix(d.created_at).format('MM/DD/YYYY h:mm A')}</td>
                                </tr>

                            )) : (
                                <tr>
                                <td>No Data</td>
                                <td></td>
                                <td></td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                </div>

            </Card>


        </Container>

    )
}

const mapStateToProps = state => {
    return {
        lead_sources : state.lead_sources.lead_sources,
    };
};

export default connect(mapStateToProps, '')(Ident);
