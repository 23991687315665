import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { Card, CardBody, CardHeader, CardTitle, Col, Container, FormGroup, Input, Row } from "reactstrap";

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import A from 'components/markup/links/A.js';
import Circle from 'components/markup/loading/Circle';

import Cookies from 'universal-cookie';

import _analytics from '_functions/analytics';
import fetchAllWorkflowSteps from '_functions/workflow_steps/fetchAll';

import { addSpaceBeforeCapital } from 'utils/text';

const StepOverview = () => {

    const [overview, setOverview] = useState(null)
    const [groupings, setGroupings] = useState(null)
    const [steps, setSteps] = useState([])
    const [allSteps, setAllSteps] = useState(null)
    const [err, setErr] = useState(false)

    const fetchOverview = useCallback(async (steps) => {
        const data = await _analytics.cases.stepOverview({steps});
        if(data.data) {
            setOverview(data.data);
            setGroupings(data.groupings);
        } else {
            setErr(true)
        }
    }, [])

    const onSetSteps = useCallback((_id) => {
        let _steps = JSON.parse(JSON.stringify(steps));
        if(_steps.includes(_id)) {
            _steps = steps.filter(s => s !== _id);
        } else {
            _steps.push(_id)
        }

        const cookies = new Cookies();
        let string = '';

        if(_steps.length) {
            _steps.forEach((s, i) => {
                if(i === 0) {
                    string = s
                }else {
                    string += ',' + s
                }
            })
        }

        cookies.set('stepOverviewSteps', string)
        setSteps(_steps)
        fetchOverview(_steps)
    }, [steps])

    const fetchAllSteps = useCallback(async () => {
        const allSteps = await fetchAllWorkflowSteps()

        if(allSteps) {
            setAllSteps(allSteps)
        } else {
            setErr(true);
        }
    }, [])

    const getName = useCallback((_id) => {
        let name = ''
        allSteps.forEach(s => {
            const foundStep = s.steps.find(step => step._id === _id);
            if(foundStep) {
                name = foundStep.name;
            }
        })
        return name;
    }, [allSteps])

    useEffect(() => {
        fetchAllSteps();
    }, [fetchAllSteps])

    useEffect(() => {

        const cookies = new Cookies();
        const cookie = cookies.get('stepOverviewSteps')

        if(cookie) {
            const split = cookie.split(',');
            setSteps(split);
            fetchOverview(split)
        } else {
            fetchOverview([])
        }
    }, [])

    if(err) {
        return (
            <div className="alert alert-danger">Something's not right, please try refreshing your page.</div>
        )
    }

    if(!allSteps) return <div className="py-6"><Circle /></div>

    return (
        <Container fluid>

            <Helmet>
                <title>{`Step Overview`}</title>
                <meta name="description" content="Step Overview" />
            </Helmet>

            <DashHeaderOpen         
                title={<span><i className="fas fa-chart-pie mr-2 text-success " />Analytics</span>}
                breadcrumb_1="Cases"
                breadcrumb_2="Step Overview"
            />

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">Select Step</CardTitle>
                </CardHeader>

                <CardBody>
                    <FormGroup className="mb-0">
                        <Input 
                            type="select"
                            value={''}
                            onChange={(e) => onSetSteps(e.target.value)}
                        >
                            <option value="null"></option>
                            {allSteps.map((workflow, i) => (
                                <React.Fragment key={workflow.workflow._id}>
                                <option value="null" disabled>Workflow: {workflow.workflow.type}</option>
                                {workflow.steps.map((s, ii) => (
                                    <option key={s._id} value={s._id}>{s.name}</option>
                                ))}
                                </React.Fragment>
                            ))}
                        </Input>
                    </FormGroup>
                </CardBody>
            </Card>

            <Row>
                {groupings ? Object.keys(groupings).map((grouping, i) => (
                     <Col lg={4} md={6} key={i}>

                        <Card>
                            <CardHeader className="text-center">
                                <CardTitle className="mb-0 text-capitalize">{grouping === 'ics' ? 'ICs' : addSpaceBeforeCapital(grouping)}</CardTitle>
                            </CardHeader>
                            <CardBody className="text-center">
                                <h1>{groupings[grouping]}</h1>
                            </CardBody>
                        </Card>

                    </Col>
                )) : null}
            </Row>

            {overview && Object.keys(overview).length ? (
                <div>
                    <hr />
                    <Row>
                        {Object.keys(overview).map(stepID => (
                    <Col lg={4} md={6} key={stepID}>
                        <A href={`/analytics/cases/by_step?step=${stepID}`}>
                            <Card>
                                <CardHeader className="text-center">
                                    <CardTitle className="mb-0">{getName(stepID)}</CardTitle>
                                </CardHeader>
                                <CardBody className="text-center">
                                    <h1>{overview[stepID]}</h1>
                                </CardBody>
                            </Card>
                        </A>
                    </Col>
                ))}
                    </Row>
                </div>
            ) : null}
        
       </Container>
    )
}


export default StepOverview

