import { connect } from 'react-redux';
import React, {useEffect} from 'react';
import { Badge, UncontrolledTooltip } from 'reactstrap';

import moment from 'moment';
import renderName from 'utils/renderName';
import { formatPhone } from 'utils/text'
import AvatarImage from 'components/functional/images/AvatarImage';

import _analytics from '_functions/analytics'
import NameCircle from 'components/markup/misc/NameCircle';

import QueryCreator from './QueryCreator';

import A from 'components/markup/links/A';
import keys from 'keys';
import Cookies from 'universal-cookie';
import timeByValue from 'utils/timeByValue';

const QueryContacts = ({tags}) => {

    useEffect(() => {
        document.body.classList.add('analytics-sidebar-small')
        return () => {
            document.body.classList.remove('analytics-sidebar-small')
        }

    }, [])

    return (
        <QueryCreator 
            title="Contacts Reports"
            searchPlaceholder="Name, Email, Phone, ID"
            runQuery={async (query, cb) => new Promise (async resolve => {

                const cookies = new Cookies();
                const val = cookies.get('analytics_query_date');

                if(!query.hasRun) {
                    query.filter.created_at = {
                        $gt: 0
                    }

                    if(val) {
                        const values = timeByValue(val);
                        console.log(values)
                        if(values.$gte) {
                            query.filter.created_at = values
                        }
                    }
                }

                const result = await _analytics.query.contacts(query)
                return resolve(result.data)
            })}
            inputs={[
                { type: 'ArchkDates', field: 'created_at', label: '', isPrimary: true },
                { type: 'ArchkTruthy', subType: 'boolean', field: 'deleted', label: 'Deleted', },
                {
                    type: 'ArchkInArray', field: 'tags', label: 'Tags', query: 'in',
                    data: tags.map(t => { return {value: t._id, text: t.name} })
                },
            ]}
            columns={[
                {
                    dataField: "given_name",
                    text: "Name",
                    formatter: (cell, row) => (
                        <div>
                            <A href={`${keys.APP_URL}/dashboard/contacts/view/${row._id}`}>
                                <NameCircle width={27} contact={row} style={{marginRight: 5}} />{' '}
                                {renderName(row)} 
                            </A>       
                        </div>
                    ),
                },
                {
                    dataField: "email",
                    text: "Contact Info",
                    formatter: (cell, row) => {
        
                        let markup = []
                        const blacklistID = `blacklist-tooltip-${row._id}`;
        
                        if(row.email) {
                            markup.push((
                                <div key={row._id}><i className="text-muted fas fa-envelope mr-2" /> {row.email}</div>
                            ))
                        }
        
                        if(row.phone) {
                            markup.push((
                                <div>
                                    <span 
                                        key={row._id + row.phone}
                                        className={row.blacklisted ? 'text-danger cursor-pointer' : 'cursor-pointer'}
                                        id={blacklistID}
                                    >
                                        <i   className={row.blacklisted ? 'text-danger fas fa-exclamation-triangle mr-3' : 'text-muted fas fa-phone mr-3'} /> 
                                        {formatPhone(row.phone)}
                                    </span>
                                    {row.blacklisted ? (
                                        <UncontrolledTooltip  key={row.created_at} delay={0} placement="bottom" target={blacklistID}>
                                            Blacklisted As Spam
                                        </UncontrolledTooltip>
                                    ) : null}
                                    
                                </div>
                            ))
                        }
                        
                        return markup.length ? markup : ''
        
                    }
                },
                {
                    dataField: "assigned_user",
                    text: "Assigned User",
                    formatter: (cell, row) => (
                        row.assigned_user ? (
                        <div className="text-capitalize">
                            <AvatarImage src={row.assigned_user.avatar} className="mr-2" />
                            {renderName(row.assigned_user)}
                        </div>
                        ) : (
                            '-'
                        )
                    )
                },
                {
                    dataField: "lead_source_name",
                    text: "Lead Status",
                    formatter: (cell, row) => {
        
                        let status = '-';
                        if(row.lead_source_entry && row.lead_source_entry.status) {
                            if(row.lead_source_entry.status === 'not contacted') {
                                status = 'No Case Setup'
                            } else {
                                status = row.lead_source_entry.status
                            }
                        }
        
                        return (
                            <div className="text-capitalize">{status}</div>
                        )
                    }
                },
                {
                    dataField: "text_opt_out",
                    text: "Communication",
                    formatter: (cell, row) => (
                        <div>
        
                            <Badge style={{width: 45, display: 'inline-block', }} color={row.opt_out_text ? 'danger': 'success'}>
                                <i className="fas fa-mobile mr-2" />
                            </Badge>
                            <Badge style={{width: 45, display: 'inline-block', }} color={row.opt_out_email ? 'danger': 'success'}>
                                <i className="fas fa-envelope mr-2" />
                            </Badge>
        
                        </div>
                    ),
                },
                {
                    dataField: "creted_at",
                    text: "Created",
                    formatter: (cell, row) => {
        
                        const date = moment.unix(row.created_at); 
        
                        return (
                            <div>
                                <div>{date.format('MMM Do, YYYY')}</div>
                                <div>{date.format('h:mm A')}</div>
                            </div>
                        )
                    },
                },
            ]}
        
        />
    )

}


const mapStateToProps = state => {
    return {
        tags: state.tags.tags,
    };
};

export default connect(mapStateToProps, '')(QueryContacts);