import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

const ButtonBack = () =>  {

    const [confirmed, setConfirmed] = useState(false);
    const [redirect, setRedirect] = useState(false);

    const onConfirm = () => {
        setConfirmed(true);
        setTimeout(() => {
            setConfirmed(false)
        }, 2000)
    }

    if(redirect) {
        return <Redirect to="/core/questionnaires" />
    }

    if(confirmed) return (
        <button className="btn btn-warning" onClick={() => setRedirect(true)}>
            Leave Page?
        </button>
    )

    return (
        <button className="btn btn-outline-warning" onClick={onConfirm}>
            <i className="fas fa-arrow-left mr-2 " /> Back
        </button>
    )
};

export default React.memo(ButtonBack);

