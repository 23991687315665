import React, { Component } from 'react';
import { Modal } from "reactstrap";
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import PropTypes from 'prop-types';

class ModalEditFolder extends Component {

    state = {
        folderToEdit: null
    }

    onUpdateName = () => {

        this.props.toggleModal()
        this.props.onUpdateName(this.state.folderToEdit)

    }

    //on keypress of enter simulate button click of updating the name
	_handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			document.getElementById("modal-edit-folder-name-save").click();
		}
	}

    focusInput = () => {

        try {
            document.getElementById('modal-edit-folder-name-input').focus()
        } catch(e) {
            console.log(e)
        }

    }

    componentWillReceiveProps = (nextProps) => {

        if(this.props.editingFolderId !== nextProps.editingFolderId) {

            const documentFolders = nextProps.documentFolders ? [...nextProps.documentFolders] : []

            // set folder to edit
            let folderToEdit = documentFolders.find(f => f._id === nextProps.editingFolderId)
            this.setState({folderToEdit: Object.assign({}, folderToEdit)}, () => {

                // if we have a folder to edit focus the input after .3 seconds
                if(nextProps.editingFolderId) setTimeout(() => this.focusInput(), 300)

            })

        }

    }

    render() {

        const { folderToEdit } = this.state
        const { showModal, toggleModal } = this.props

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={toggleModal}
                size="lg"
                fade={false}
            >
                {!folderToEdit ? null : (
                    <>
                     <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Edit Folder</h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={toggleModal}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <StandardFormGroup
                            onKeyDown={(e) => this._handleKeyDown(e)}
                            inputId="modal-edit-folder-name-input"
                            placeholder="folder name"
                            formatter={(v) => v === 'New Folder' ? '' : v}
                            obj={folderToEdit}
                            objName="folderToEdit"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                            field="name"
                            title="Name *"
                        />
                    </div>

                    <div className="modal-footer">
                        <button id="modal-edit-folder-name-save" onClick={this.onUpdateName} className="btn btn-success">Update Folder Name</button>
                    </div>

                    </>
                )}
            </Modal>

        )

    }

}

ModalEditFolder.propTypes = {
    toggleModal       : PropTypes.func.isRequired,
    showModal         : PropTypes.bool.isRequired,
    onUpdateName      : PropTypes.func.isRequired,

    editingFolderId   : PropTypes.string,
    documentFolders   : PropTypes.array,
}

export default ModalEditFolder
