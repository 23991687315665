import Circle from 'components/markup/loading/Circle';
import moment from 'moment';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import reactHTMLParser from 'react-html-parser';
import { Card, CardBody, CardHeader, CardTitle, Container } from "reactstrap";
import _analytics from '_functions/analytics';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';

const { SearchBar } = Search;

class ShowEntries extends React.Component {

    state = {
        sizePerPage: 25
    }

    columns = [
        {
            dataField: "created_at",
            text: "Date",
            formatter: (cell, row) => moment.unix(row.created_at).format('MM/DD/YYYY h:mm A')
        },
        {
            dataField: "type",
            text: "Type",
            formatter: (cell, row) => <span className="text-capitalize">{row.type}</span>
        },
        {
            dataField: "html",
            text: "Alert",
            formatter: (cell, row) => <div style={{whiteSpace: 'pre-line'}}>{reactHTMLParser(row.html)}</div>
        },
    ]

    pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: false,
        sizePerPage: this.state.sizePerPage,
        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    componentDidMount = async () => {

        const documents = await _analytics.analytic_alerts.find(`?sort=created_at__desc`)
        if(documents.success) this.setState({documents: documents.data})

    }

    render() {

        const { documents } = this.state
        if(!documents) return <div className="py-6"><Circle /></div>

        return (

            <Container fluid>

                <DashHeaderOpen
                    title={<span><i className="fas fa-headset mr-2 text-success " />Analytics</span>}
                    breadcrumb_1="Alerts"
                    breadcrumb_2="All"
                />

                <Card className="card-color card-primary">

                    <CardHeader>
                        <CardTitle className="mb-0">Analytic Alerts</CardTitle>
                    </CardHeader>

                    {documents ? (

                        <ToolkitProvider
                            data={documents}
                            keyField="_id"
                            columns={this.columns}
                            search
                        >
                            {props => (
                                <>
                                    <div className="dataTables_filter px-4 pb pt-3 pb-2" >
                                        <label>
                                            Search:
                                            <SearchBar
                                                className="form-control-sm"
                                                placeholder="Name, Email, Phone"
                                                {...props.searchProps}
                                            />
                                        </label>
                                    </div>

                                    <div className=" table-responsive table-vertical-align">
                                        <BootstrapTable
                                            pagination={this.pagination}
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            bordered={false}
                                        />
                                    </div>
                                </>
                            )}
                        </ToolkitProvider>

                    ) : (

                        <CardBody>

                            <p className="text-sm mb-0">There are alerts to view</p>
                        </CardBody>

                    )}
                </Card>

            </Container>

        );
    }
}

export default ShowEntries

