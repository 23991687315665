
// findUnmovedStepsByAppointments

import AvatarImage from 'components/functional/images/AvatarImage';
import moment from 'moment';
import React from 'react';
import { Card } from 'reactstrap';
import renderName from 'utils/renderName';
import { secondsToTime } from 'utils/time';

const Metrics = ({data, getEventName, getUser}) => {

    return (
        <Card>
            <div className="table-responsive">
                <table className="table table-striped table-sticky-first-col">
                    <thead>
                        <tr>
                            <th>User</th>
                            <th>Events</th>
                            {data.metrics[0].events.map((event, i) => (
                                <th key={i} style={{height: 160, whiteSpace: 'nowrap', position: 'relative' }}>
                                    <div style={{transform: 'translate(25px, 51px) rotate(300deg)', width: 10, maxWidth: 10, position: 'relative', top: -40, left: -25 }}>
                                        <span className="border-bottom">{getEventName(event.event)}</span>
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.metrics.map((metric, i) => {

                            const user = getUser(metric.user);

                            const isLowest = data.metrics.some(m => parseInt(m.total_events) < parseInt(metric.total_events)) ? false : true
                            const isHighest = data.metrics.some(m => parseInt(m.total_events) > parseInt(metric.total_events)) ? false : true

                            return (
                                <tr key={i}>

                                    <td className="text-capitalize" style={{position: 'sticky', left: 0, background: '#fff'}} ><AvatarImage src={user.avatar} className="mr-2" />{renderName(user)}</td>
                                    <td className={isLowest ? 'text-danger font-weight-bold' : isHighest ? 'text-success font-weight-bold' : ''}>
                                        {isLowest ? (
                                            <i className="fas fa-times font-weight-bold mr-2 text-danger " />
                                        ) : null}
                                        {isHighest ? (
                                            <i className="fas fa-check font-weight-bold mr-2 text-success " />
                                        ) : null}
                                        {metric.total_events} <span className="text-muted">({metric.total_percent_events}%)</span>
                                    </td>
                                    {metric.events.map((event, x) => {

                                        let isLowestEvent;
                                        let isHighestEvent;

                                        let value = event.quantity;

                                        if(event.event === 'FIRST_EVENT' || event.event === 'LAST_EVENT') {
                                            const time = parseInt(event.value) ? parseInt(event.value) : null;
                                            value = time ? moment.unix(time).format('h:mm A') : '-'
                                        } else if(
                                            event.event === 'AVERAGE_TIME_BETWEEN_EVENTS' ||
                                            event.event === 'CALL_TIME_TOTAL' ||
                                            event.event === 'CALL_TIME_AVERAGE' ||
                                            event.event === 'CALL_TIME_AVERAGE_BETWEEN' ||
                                            event.event === 'NOTE_AVERAGE_BETWEEN'
                                        ) {
                                            const seconds = parseInt(event.value) ? parseInt(event.value) : null;
                                            value = seconds ? secondsToTime(seconds) : '-'
                                        } else if(event.event === 'NOTE_AVERAGE_LENGTH') {
                                            value = event.value
                                        } else {

                                            isHighestEvent = !data.metrics.some(m => {

                                                return m.events.some(e => {
                                                    if(e.event !== event.event) return false;
                                                    return parseInt(e.quantity) > parseInt(event.quantity)
                                                })

                                            })
                                            isLowestEvent = !data.metrics.some(m => {

                                                return m.events.some(e => {
                                                    if(e.event !== event.event) return false;
                                                    return parseInt(e.quantity) < parseInt(event.quantity)
                                                })

                                            })

                                        }

                                        return (
                                            <td key={x}>
                                                 {isLowestEvent ? (
                                            <i className="fas fa-times font-weight-bold mr-2 text-danger " />
                                            ) : null}
                                            {isHighestEvent ? (
                                                <i className="fas fa-check font-weight-bold mr-2 text-success " />
                                            ) : null}
                                                    {value} {event.daily_percent ? <span className="text-muted">({event.daily_percent}%)</span> : null}
                                                </td>
                                        )
                                    })}

                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </Card>
    )
}

export default Metrics