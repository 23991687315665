/*
Documentation

this page shows all users within the system

*/

import NotificationDelete from 'components/functional/notifications/Delete';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import { useEffect, useState, useCallback } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ColorCircle from 'components/markup/misc/ColorCircle';
import { formatPhone } from 'utils/text'

import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge, Card, CardBody, CardHeader, CardTitle, Container } from "reactstrap";
import renderName from 'utils/renderName'
import AvatarImage from 'components/functional/images/AvatarImage';
import moment from 'moment';
import Circle from 'components/markup/loading/Circle'

import _users from '_functions/users'

const { SearchBar } = Search;

const Users = () => {

    const [all, setAll] = useState(null)

    const fetchData = useCallback(async () => {
        const data = await _users.find();
        if(data.data) {
            const all = data.data.filter(d => d.privileges && d.privileges.includes("can_give_attorney_consultation"))
            setAll(all)
        }
        console.log(data)
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (

        <Container fluid>


            <Helmet>
                <title>Staff</title>
                <meta name="description" content="Staff" />
            </Helmet>

            <DashHeaderOpen
                title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
                breadcrumb_1="Staff"
                breadcrumb_2="All"
                actionComponent={(
                    <Link  to="/core/users/create" className="btn btn-success"><i className="fas fa-plus mr-2 " />Add New</Link>
                )}
            />

            <Card >

                <CardHeader>
                    <CardTitle className="mb-0 float-left">View All</CardTitle>
                </CardHeader>

               <CardBody>
                 {!all ? <Circle /> : all.map(u => {

                    return (
                        <div key={u._id} className="text-capitalize bg-secondary rounded p-4 mb-4">
                            {u.given_name} {u.family_name} - {u.timezone}
                            {Object.keys(u.available_times).map(t => (
                                <div>
                                    <span style={{display: 'inline-block', width: 100}}>  {t}</span>
                                
                                {u.available_times[t].start === 0 && u.available_times[t].end === 0 ? (
                                    '-'
                                ) : (
                                    <span>
                                        <span style={{display: 'inline-block', width: 100}}>{moment.unix(u.available_times[t].start).utc().format('h:mm A')}</span>{' - '}
                                        <span style={{display: 'inline-block', paddingLeft: 20, width: 100}}>{moment.unix(u.available_times[t].end).utc().format('h:mm A')}</span>
                                    </span>
                                )}
                                  
                                </div>
                            ))}
                        </div>
                    )
                    })}

               </CardBody>

            </Card>

        </Container>

    );
}

const mapStateToProps = state => {
    return {
        users: state.users
    };
};

export default connect(mapStateToProps, '')(Users);

