import React, { useState } from 'react';

const ButtonSave = ({onSave}) =>  {

    const [saved, setSaved] = useState(false);

    const onClick = () => {

        const saved = onSave();

        if(saved) {
            setSaved(true);
            setTimeout(() => {
                setSaved(false)
            }, 1000)
        }

    }

    if(saved) return (
        <button disabled={true} className="btn btn-success">
            <i className="fas fa-check mr-2 " /> Saved!
        </button>
    )

    return (
        <button className="btn btn-outline-success" onClick={onClick}>
            <i className="fas fa-save mr-2 " /> Save
        </button>
    )
};

export default React.memo(ButtonSave);

