import React from "react";
import { connect } from 'react-redux';
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, FormGroup, Input } from "reactstrap";
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';
import validator from 'utils/validator';
import _companies from '_functions/companies';

const required_fields = [
    'name',
]

class CompanyDetails extends React.Component {

    state = {
        company: Object.assign({}, this.props.company),
        emailToAdd: null,
        hiddenEmailToAdd: null,
        canSave: true
    }


    onSave = async () => {

        toggleStandardLoader(true)

        const validateFields = validator.requiredFields('company', required_fields, this)

        const newState = validateFields.state

        if(validateFields.success) {

            const updated = await _companies.update(this.props.company._id, newState)

            if(updated.success) {
                toggleAlertBS(false, `Your profile has been updated successfully.`)
            }

        }

        toggleStandardLoader(false)

    }

    onInputChange = (e, stateField) => {
        validator.onInputChange(e.target.value, 'company', stateField, this)
    }

    render() {

        const { email_senders } = this.props;
        const { company } = this.state

        return (

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">Company Details</CardTitle>
                </CardHeader>

                <CardBody>

                    <FormGroup>
                        <label className="form-control-label" htmlFor="name" >
                            Name
                        </label>
                        <Input
                            id="name"
                            value={company.name || ''}
                            placeholder="Company Name"
                            type="text"
                            valid={ company.nameState === "valid" }
                            invalid={ company.nameState === "invalid" }
                            onChange={e => this.onInputChange(e, "name") }
                        />
                        <div className="valid-feedback">Looks good!</div>
                    </FormGroup>

                    <FormGroup>
                        <label className="form-control-label">Email Sender</label>
                        <Input 
                            type="select"
                            value={company.default_email_sender || ''}
                            onChange={(e) => this.onInputChange(e, 'default_email_sender')}
                        >
                            {!company.default_email_sender ? (
                                <option value="false"></option>
                            ) : null}
                            {email_senders.map(sender => (
                                <option key={sender._id} value={sender._id}>{sender.name} - {sender.email}</option>
                            ))}
                        </Input>
                    </FormGroup>
                 
                </CardBody>

                <CardFooter className="text-right">
                    <Button color="success" onClick={this.onSave} disabled={this.state.canSave ? false : true} >
                        Save Company
                    </Button>
                </CardFooter>

            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        company: state.companies.company,
        email_senders: state.email_senders.email_senders,
    };
};

export default connect(mapStateToProps, '')(CompanyDetails);
