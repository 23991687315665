/*
Documentation

Every email parse will include a log that goes with it and is kept for 1 month

1) subject includes
2) from email is one of
3) contact identifier
    a) contact identifier: contact email / contact phone / contact id / case number / case id / 
    b) search subject or email body - subject is preferred when possible
    c) words/characters before identifier
    d) words/characters after identifier
4) Date Extraction (can be multiple)
    a) words before date
    b) words after date
    c) include time
    e) should this trigger an appointment in the calendar?
        i) template appointment to schedule
        i) users to assign to appointment
    f) should this trigger an email?
        i) send email body
        i) send email to addresses
    g) should this trigger a text?
        i) send email body
        i) send email to addresses
5) Download Documents From Email To Contacts Case?
6) Move case to new step? (this forces the move and any incomplete tasks will be skipped)
7) Add to automation
8) Add a tag to contact


*/


import Circle from 'components/markup/loading/Circle';
import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';
import _emails_parsers from '_functions/email_parsers';
import Main from './Main';
import Sidebar from './Sidebar';






const baseParserState = {
    name: '',
    failed_email_address: '',
    subject_includes: '',
    from_emails_text: '',
    from_emails: [],
    contact_identifier: '',
    contact_identifier_search: 'subject',
    contact_identifier_before: '',
    contact_identifier_after: '',
    
    active: true,
    download_documents: 'no',
    move_to_step: '',
    tags: [],

    actions: [],
    fields: [],
    tasks: [],
}

const EmailParsersAll = (props) => {

    const parser_id = props.match.params.email_parser_id;
    const redirectURL = '/automations/email_parsers';

    const [ loaded, setLoaded ] = useState(false);
    const [ parser, setParser ] = useState(baseParserState);
    const [ showModal, toggleModal ] = useState(false);
    const [ redirect, setRedirect ] = useState(false);

    useEffect(() => {

        if(parser_id !== 'new') {
            _emails_parsers.findById(parser_id).then(data => {

                if(data.data) {
                    setParser(data.data);
                    setLoaded(true)
                } else {
                    setRedirect(redirectURL);
                }

            })
        } else {

            setLoaded(true)

        }

        document.body.classList.add('analytics-sidebar-small')

        return () => {
            document.body.classList.remove('analytics-sidebar-small')
        }

    }, [])

    const onChange = (e, stateName) => {

        const value = e.target.value;
        const newState = Object.assign({}, parser);

        newState[stateName] = value;

        if (value === "") {
            newState[stateName + "State"] = "invalid";
        } else {
            newState[stateName + "State"] = "valid";
        }

        setParser(newState);

    }

    const onConfirmSave = () => {

        const state = Object.assign({}, parser);

        let errors = 0;

        const setError = (stateName) => {
            errors++;
            state[stateName + 'State'] = 'invalid'
        }

        if(!parser.name) setError('name');
        if(!parser.subject_includes) setError('subject_includes');
        if(!parser.from_emails.length) setError('from_emails');
        if(!parser.contact_identifier) setError('contact_identifier');
        if(!parser.contact_identifier_before) setError('contact_identifier_before');
        if(!parser.contact_identifier_after) setError('contact_identifier_after');

        console.log(errors);
        if(errors) return setParser(state);

        return toggleModal(true)

    }

    const onSave = async (parser) => {

        const data = Object.assign({}, parser)

        toggleStandardLoader(true)

        let action;

        if(data.actions.length) {
            data.actions = data.actions.filter(a => a.name !== 'Unsaved Action');
        }

        if(data.fields.length) {
            data.fields = data.fields.filter(a => a.name !== 'Unsaved Field');
        }
        
        if(data.tasks.length) {
            data.tasks = data.tasks.filter(a => a.name !== 'Unsaved Task');
        }

        if(parser._id) {
            action = await _emails_parsers.update(data._id, data);
        } else {
            action = await _emails_parsers.create(data);
        }

        toggleStandardLoader(false)

        if(action.success) {
            setRedirect(redirectURL);
            toggleAlertBS(false, 'Email Parser Saved Successfully');
        } else {
            toggleAlertBS(true, 'Something went wrong saving this email parser.');
        }

    }

    if(redirect) return <Redirect to={redirect} />
    if(!loaded) return <Circle />

    return (

        <div className="archk-email-parser">

            <Helmet>
                <title>{`Email Parsers`}</title>
                <meta name="description" content="Email Parsers" />
            </Helmet>

            <Sidebar 
                parser={parser}
                setParser={setParser}
                onChange={onChange}
            />

            <Main 
                parser={parser}
                setParser={setParser}
                onChange={onChange}
                onConfirmSave={onConfirmSave}
                showModal={showModal}
                onSave={onSave}
                toggleModal={toggleModal}
            />


        </div>

    );
}

const mapStateToProps = state => {
    return {
        company: state.companies.company,
        email_parsers: state.email_parsers.email_parsers,
    };
};

export default connect(mapStateToProps, '')(EmailParsersAll);
