import apiWorker from '../apiWorker'

const location_courts = {

    create:   (data)            =>  apiWorker('post',  `/api/v1/core/location_courts/create`, data),
    update:   (court_id, data)  =>  apiWorker('patch', `/api/v1/core/location_courts/update/${court_id}`, data),
    find:     (query)           =>  apiWorker('get',    `/api/v1/core/location_courts/find${query}`),

}

export default location_courts;