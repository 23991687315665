import React, { useState, useCallback, useEffect } from 'react';

import { Card, CardHeader, CardBody, Container, Row, Col, FormGroup, CardFooter,  CardTitle, Input } from 'reactstrap';

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import Circle from 'components/markup/loading/Circle';

import _lead_sources from '_functions/lead_sources';
import { formatMoney } from 'utils/currency';

import moment from 'moment';


const MasterReport = () => {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [err, setErr] = useState(null);
    const [sort, setSort] = useState('name');
    const [type, setType] = useState('percent');

    const sortData = (field => {
        let _data = JSON.parse(JSON.stringify(data))
        
        if(field === sort) {
            _data.reverse();
        } else {
            _data.sort((a, b) => {
                return a[field] > b[field] ? -1 : 1
            })
            // _data.reverse()
        }

        setData(_data)
        setSort(field)
    })

    const fetchData = useCallback(async (hideLoad, _start, _end) => {

        const startDate = _start ? moment(_start).format('X') : ''
        const endDate = _end ? moment(_end).endOf('day').format('X') : ''
        if(!hideLoad) setLoading(true)
        const data = await _lead_sources.analysis(`?start=${startDate}&end=${endDate}`);
        if(!hideLoad) setLoading(false);

        if(data.data) {
            data.data = data.data.sort(((a, b) => a.name <= b.name ? -1 : 1))
            setData(data.data);
            setSort('name')
        } else {
            setErr(true)
        }
    }, [])


    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(err) return (
        <div className="alert alert-danger">Something's not right, please try again.</div>
    )

    if(!data) return <div className="py-6"><Circle /></div>
    if(loading) return <div className="py-6"><Circle /></div>

    const all = data.find(d => d.name === 'All')
    

    return (

        <Container className="collections" fluid>

            <DashHeaderOpen
                title={<span><i className="fas fa-chart-pie mr-2 text-success " /> Lead Sources</span>}
                breadcrumb_1="Reports"
                breadcrumb_2="Analysis"
            />
            
            <Card>

                <CardBody>
                    <p className='text-sm mb-0'><i className="fas fa-info-circle text-info-original mr-2 " /> This table shows a compiled aggregation of all cases associated where the lead source below is the main data provider on the case. The "Success" column is the percentage of cases on or passed the signed retainer step and the "Complete" column is the percentage of cases on either IRS or payment steps. NOTE that the leads tab is the number of leads into the systems matched against the number of cases that have hit the call scheduled step. Leads are created when a consultation is scheduled WITHOUT a match of a phone number or email already in the system OR automatically by every POST API call made to create a lead</p>
                </CardBody>

                <CardFooter>
                    <Row>
                        <Col lg={6}>
                            <FormGroup>
                                <Input
                                    type="date"
                                    value={start}
                                    onChange={(e) => setStart(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={6}>
                            <FormGroup>
                                <Input
                                    type="date"
                                    value={end}
                                    onChange={(e) => setEnd(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </CardFooter>
                <CardFooter className="text-right">
                    <button onClick={() => setType(type === 'percent' ? 'total' : 'percent')} className="btn btn-info">{type === 'percent' ? 'Sort By Total' : "Sort By Percent"}</button>
                    <button onClick={() => fetchData(false, start, end)} className="btn btn-success">Run Search</button>
                </CardFooter>

            </Card>

            <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Aggregations</CardTitle>       
                </CardHeader>
                <div className="table-responsive table-sticky-headers">
                    <table className="table">
                        <thead>
                            <tr>
                                <th className={sort === 'name' ? 'text-success cursor-pointer' : 'cursor-pointer'} onClick={() => sortData('name')}>
                                    Source<div>{all.name}</div>
                                </th>
                                <th className={sort === 'total' ? 'text-success text-right cursor-pointer' : 'text-right cursor-pointer'} onClick={() => sortData('size')} >
                                    Avg ERC By <div>Employees * $15,000</div>
                                </th>
                                <th className={sort === 'total' ? 'text-success text-right cursor-pointer' : 'text-right cursor-pointer'} onClick={() => sortData('total')} >
                                    Total Cases<div>{all.total}</div>
                                </th>
                                <th className={sort === 'percent_success' ? 'text-success text-right cursor-pointer' : 'text-right cursor-pointer'} onClick={() => sortData(type === 'percent' ? 'percent_success' : 'success')} >
                                    Success<div> {all.percent_success}% ({all.success})</div>
                                </th>
                                <th className={sort === 'percent_complete' ? 'text-success text-right cursor-pointer' : 'text-right cursor-pointer'} onClick={() => sortData(type === 'percent' ? 'percent_complete' : 'complete')} >
                                    Complete<div>{all.percent_complete}% ({all.irs + all.payment})</div>
                                </th>
                                <th className={sort === 'leadConversion' ? 'text-success text-right cursor-pointer' : 'text-right cursor-pointer'} onClick={() => sortData(type === 'percent' ? 'leadConversion' : 'leads')} >
                                    Leads<div>{all.leadConversion}% ({all.leads})</div>
                                </th>
                                <th className={sort === 'percent_emailed' ? 'text-success text-right cursor-pointer' : 'text-right cursor-pointer'} onClick={() => sortData(type === 'percent' ? 'percent_emailed' : 'emailed')} >
                                    Emailed<div>{all.percent_emailed}% ({all.emailed})</div>
                                </th>
                                <th className={sort === 'percent_callScheduled' ? 'text-success text-right cursor-pointer' : 'text-right cursor-pointer'} onClick={() => sortData(type === 'percent' ? 'percent_callScheduled' : 'callScheduled')} >
                                    Call Scheduled<div>{all.percent_callScheduled}% ({all.callScheduled})</div>
                                </th>
                                <th className={sort === 'percent_sentRetainer' ? 'text-success text-right cursor-pointer' : 'text-right cursor-pointer'} onClick={() => sortData(type === 'percent' ? 'percent_sentRetainer' : 'sentRetainer')} >
                                    Sent Retainer<div>{all.percent_sentRetainer}% ({all.sentRetainer})</div>
                                </th>
                                <th className={sort === 'percent_signedRetainer' ? 'text-success text-right cursor-pointer' : 'text-right cursor-pointer'} onClick={() => sortData(type === 'percent' ? 'percent_signedRetainer' : 'signedRetainer')} >
                                    Signed Retainer<div>{all.percent_signedRetainer}% ({all.signedRetainer})</div>
                                </th>
                                <th className={sort === 'percent_submitted' ? 'text-success text-right cursor-pointer' : 'text-right cursor-pointer'} onClick={() => sortData(type === 'percent' ? 'percent_submitted' : 'submitted')} >
                                    Submitted<div>{all.percent_submitted}% ({all.submitted})</div>
                                </th>
                                <th className={sort === 'percent_signature' ? 'text-success text-right cursor-pointer' : 'text-right cursor-pointer'} onClick={() => sortData(type === 'percent' ? 'percent_signature' : 'signature')} >
                                    Signature<div>{all.percent_signature}% ({all.signature})</div>
                                </th>
                                <th className={sort === 'percent_irs' ? 'text-success text-right cursor-pointer' : 'text-right cursor-pointer'} onClick={() => sortData(type === 'percent' ? 'percent_irs' : 'irs')} >
                                    Irs<div>{all.percent_irs}% ({all.irs})</div>
                                </th>
                                <th className={sort === 'percent_payment' ? 'text-success text-right cursor-pointer' : 'text-right cursor-pointer'} onClick={() => sortData(type === 'percent' ? 'percent_payment' : 'payment')} >
                                    Payment<div>{all.percent_payment}% ({all.payment})</div>
                                </th>
                                <th className={sort === 'percent_dead' ? 'text-success text-right cursor-pointer' : 'text-right cursor-pointer'} onClick={() => sortData(type === 'percent' ? 'percent_dead' : 'dead')} >
                                    Dead<div>{all.percent_dead}% ({all.dead})</div>
                                </th>
                                <th className={sort === 'percent_noShow' ? 'text-success text-right cursor-pointer' : 'text-right cursor-pointer'} onClick={() => sortData(type === 'percent' ? 'percent_noShow' : 'noShow')} >
                                    No Show<div>{all.percent_noShow}% ({all.noShow})</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>

                           
                            {data.map((d, i) => {

                                const colorSuccess = d.percent_success >= all.percent_success? 'text-success' : 'text-danger';
                                const colorComplete = d.percent_complete >= all.percent_complete? 'text-success' : 'text-danger';
                                const colorLeads = d.leadConversion >= all.leadConversion? 'text-success' : 'text-danger';
                                const colorEmailed = d.percent_emailed >= all.percent_emailed? 'text-success' : 'text-danger';
                                const colorCallScheduled = d.percent_callScheduled >= all.percent_callScheduled? 'text-success' : 'text-danger';
                                const colorSentRetainer = d.percent_sentRetainer >= all.percent_sentRetainer? 'text-success' : 'text-danger';
                                const colorSignedRetainer = d.percent_signedRetainer >= all.percent_signedRetainer? 'text-success' : 'text-danger';
                                const colorSubmitted = d.percent_submitted >= all.percent_submitted? 'text-success' : 'text-danger';
                                const colorSignature = d.percent_signature >= all.percent_signature? 'text-success' : 'text-danger';
                                const colorIRS = d.percent_irs >= all.percent_irs? 'text-success' : 'text-danger';
                                const colorPayment = d.percent_payment >= all.percent_payment? 'text-success' : 'text-danger';
                                const colorDead = d.percent_dead <= all.percent_dead? 'text-success' : 'text-danger';
                                const colorNoShow = d.percent_noShow <= all.percent_noShow? 'text-success' : 'text-danger';

                                return d.name === 'All' ? <></> : (
                                    <tr key={i}>
                                        <td>{d.name}</td>
                                        <td className={`text-right ${colorSuccess}`}>{formatMoney(d.size &&  d.success ? (d.size / d.success) * 15000 : 0)}</td>
                                        <td className={`text-right `}>{d.total}</td>
                                        <td className={`text-right ${colorSuccess}`}>{d.percent_success}% ({d.success})</td>
                                        <td className={`text-right ${colorComplete}`}>{d.percent_complete}% ({d.irs + d.payment})</td>
                                        <td className={`text-right ${colorLeads}`}>{d.leadConversion}% ({d.leads})</td>
                                        <td className={`text-right ${colorEmailed}`}>{d.percent_emailed}% ({d.emailed})</td>
                                        <td className={`text-right ${colorCallScheduled}`}>{d.percent_callScheduled}% ({d.callScheduled})</td>
                                        <td className={`text-right ${colorSentRetainer}`}>{d.percent_sentRetainer}% ({d.sentRetainer})</td>
                                        <td className={`text-right ${colorSignedRetainer}`}>{d.percent_signedRetainer}% ({d.signedRetainer})</td>
                                        <td className={`text-right ${colorSubmitted}`}>{d.percent_submitted}% ({d.submitted})</td>
                                        <td className={`text-right ${colorSignature}`}>{d.percent_signature}% ({d.signature})</td>
                                        <td className={`text-right ${colorIRS}`}>{d.percent_irs}% ({d.irs})</td>
                                        <td className={`text-right ${colorPayment}`}>{d.percent_payment}% ({d.payment})</td>
                                        <td className={`text-right ${colorDead}`}>{d.percent_dead}% ({d.dead})</td>
                                        <td className={`text-right ${colorNoShow}`}>{d.percent_noShow}% ({d.noShow})</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </Card>

        </Container>

    )

}

export default MasterReport;