import React, { useState } from 'react';
import { Container } from 'reactstrap';
import Query from './Query';
import Results from './Results';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';


const AppointmentsByStep = () => {

    const [ state, setData ] = useState({
        data: null,
        error: false,
        loading: true,
        queryInfo: {}
    })

    const onStateChange = (obj) => setData({...state, ...obj});

    const { data, queryInfo, loading } = state;

    return (

        <Container fluid>

            <DashHeaderOpen
                title={<span><i className="fas fa-headset mr-2 text-success " />Analytics</span>}
                breadcrumb_1="Appointments"
                breadcrumb_2="By Step"
            />

            <Query 
                setData={onStateChange}
            />

            <Results 
                data={data}
                loading={loading}
                queryInfo={queryInfo}
            />

        </Container>

    )

}

export default AppointmentsByStep; 
