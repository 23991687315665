import moment from 'moment';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { FormGroup, Input } from 'reactstrap';
import PropTypes from 'prop-types';

class ArchkDates extends Component {

    state = {
        range: null,
        date: null,
        date_end: null
    };

    renderDateRange = () => {

        const { range, date, date_end } = this.state
        const { onChange } = this.props

        if(range === 'day') {

            return (
                <FormGroup>
                    <label className="form-control-label">Select Day</label>
                    <div className="date-picker-wrapper">
                        <DatePicker
                            popperPlacement="right" 
                            selected={date}
                            onChange={date => {

                                this.setState({date});

                                const start = parseInt(moment.unix((parseInt(moment.unix(date).format('X')) / 1000)).startOf('day').format('X'))
                                const end = start + 83999

                                onChange(`${start} ${end}{between}`)

                            }}
                        />
                    </div>
                </FormGroup>
            )

        } else if (range === 'range') {

           return (
               <>
                    <FormGroup>
                        <label className="form-control-label">Select Start</label>
                        <div className="date-picker-wrapper">
                            <DatePicker
                                popperPlacement='right'
                                selected={date}
                                onChange={date => {

                                    this.setState({date});

                                    const start = !date ? undefined : parseInt(moment.unix((parseInt(moment.unix(date).format('X')) / 1000)).startOf('day').format('X'))
                                    const end = !date_end ? undefined : parseInt(moment.unix((parseInt(moment.unix(date).format('X')) / 1000)).endOf('day').format('X'))

                                    onChange(`${start} ${end}{between}`)


                                }}
                            />
                        </div>
                    </FormGroup>

                    <FormGroup>
                        <label className="form-control-label">Select End</label>
                        <div className="date-picker-wrapper">
                            <DatePicker
                                popperPlacement='right'
                                selected={date_end}
                                onChange={date_end => {

                                    this.setState({date_end});

                                    const start = !date ? undefined : parseInt(moment.unix((parseInt(moment.unix(date).format('X')) / 1000)).startOf('day').format('X'))
                                    const end = !date_end ? undefined : parseInt(moment.unix((parseInt(moment.unix(date_end).format('X')) / 1000)).endOf('day').format('X'))

                                    onChange(`${start} ${end}{between}`)

                                }}
                            />
                        </div>
                    </FormGroup>

               </>
           )

        } else if (range === 'interval') {

            return (
                <FormGroup>
                    <label className="form-control-label">Select Time Frame</label>

                    <Input
                        type="select"
                        onChange={(e) => {

                            // set date_end to now
                            const end = parseInt(moment().startOf('day').valueOf() / 1000)

                            // set one day in unix time
                            const day = 86400;

                            let start;

                            switch (e.target.value) {
                                case "Last 24 Hours": start = end - (day * 1);  break;
                                case "Last 48 Hours": start = end - (day * 2);  break;
                                case "Last 3 Days": start = end - (day * 3);  break;
                                case "Last 7 Days": start = end - (day * 7);  break;
                                case "Last 14 Days": start = end - (day * 14);  break;
                                case "Last 21 Days": start = end - (day * 21);  break;
                                case "Last 30 Days": start = end - (day * 30);  break;
                                case "Last 60 Days": start = end - (day * 60);  break;
                                case "Last 90 Days": start = end - (day * 90);  break;
                                case "Last 120 Days": start = end - (day * 120);  break;
                                case "Last 180 Days": start = end - (day * 180);  break;

                                default: break;
                            }

                            onChange(`${start} ${end}{between}`)

                        }}
                    >
                        <option></option>
                        <option value="Last 24 Hours">Last 24 Hours</option>
                        <option value="Last 48 Hours">Last 48 Hours</option>
                        <option value="Last 3 Days">Last 3 Days</option>
                        <option value="Last 7 Days">Last 7 Days</option>
                        <option value="Last 14 Days">Last 14 Days</option>
                        <option value="Last 21 Days">Last 21 Days</option>
                        <option value="Last 30 Days">Last 30 Days</option>
                        <option value="Last 60 Days">Last 60 Days</option>
                        <option value="Last 90 Days">Last 90 Days</option>
                        <option value="Last 120 Days">Last 120 Days</option>
                        <option value="Last 180 Days">Last 180 Days</option>
                    </Input>

                </FormGroup>
            )

        }

    }

    render() {

        const { range } = this.state;
        const { onChange } = this.props

        return (

            <>
                <FormGroup>

                    <label className="form-control-label">Date Range Type</label>

                    <Input
                        type="select"
                        value={range || ''}
                        onChange={(e) => {
                            onChange()
                            this.setState({...this.state, range: e.target.value, date: null, date_end: null})
                        }}
                    >
                        <option></option>
                        <option value="day">Day</option>
                        <option value="range">Range</option>
                        <option value="interval">Time Group</option>
                    </Input>

                </FormGroup>

                {this.renderDateRange()}

            </>

        )

    }

}

ArchkDates.propTypes = {

    onChange: PropTypes.func.isRequired,

}

export default ArchkDates;
