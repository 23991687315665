/*
Documentation

This file is confusing but seems to work without error
!! Do not touch this file unless it gives an error


*/


import React, { Component } from 'react';
import { Modal, Row, Col, Card, CardBody, CardHeader, CardFooter } from 'reactstrap';
import Circle from 'components/markup/loading/Circle'
import _workflows from '_functions/workflows';
import PropTypes from 'prop-types'

class ExpandingCard extends Component {

    state = {
        open: false,
    }

    toggleHeight = () => this.setState({open: !this.state.open})

    render () {

        const { title, body } = this.props

        return (

            <Card>

                <CardHeader>{title}</CardHeader>

                <CardBody style={{height: this.state.open ? 'auto' : 150, minHeight: 150, overflow: 'hidden'}}>
                    {body}
                </CardBody>

                <CardFooter className="text-right">
                    <button onClick={this.toggleHeight} className="btn btn-outline-success">Toggle Expansion</button>
                </CardFooter>

            </Card>

        )

    }

}

class AnalysisModal extends Component {

    state = {
        showModalInsertStep: false,
        steps: [],
        treeMarkup: null,
        loading: true

    };

    componentWillReceiveProps = async (nextProps) => {

        if(!this.props.showAnalysis && nextProps.showAnalysis) {

            this.setState({loading: true})
            const data = await _workflows.analyze(this.props.workflow_id)

            this.setState({loading: false, analysis: data.data ? data.data : null})

        }

    }

    render() {

        const { showAnalysis, toggleAnalysis } = this.props
        const { analysis, loading} = this.state

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showAnalysis}
                toggle={toggleAnalysis}
                size="lg"
            >
                <div className="modal-header">
                    <h5 className="modal-title">
                        Workflow Analysis {analysis ? <span>({analysis.stepLength} Total Steps)</span> : null}
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleAnalysis}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                {loading ? <Circle /> : (
                    <div className="modal-body">

                        <Row>
                            <Col lg={6}>

                                <ExpandingCard
                                    title="Step Errors"
                                    body={(
                                        <div>
                                            {analysis.errStepsWithNoTasks.length ? (
                                                <div>
                                                    <p className="font-weight-bold mb-0">The following steps have no tasks to be done.</p>
                                                        {analysis.errStepsWithNoTasks.map((step, i) =>  <p key={i} className="text-sm mb-0 text-warning">{i + 1}) {step}</p> )}
                                                </div>
                                            ) : (
                                                <p><i className="fas fa-check text-success mr-2" />There are no workflow step errors</p>
                                            )}
                                        </div>
                                    )}
                                />

                                <ExpandingCard
                                    title="Steps That End Workflow"
                                    body={(
                                        <div>
                                            {analysis.stepsThatEndWorkflow.map((step, i) =>  <p key={i} className="text-sm mb-0">{i + 1}) {step}</p>)}
                                        </div>
                                    )}
                                />

                                <ExpandingCard
                                    title="Steps Setting Client Status"
                                    body={(
                                        <div>
                                            {analysis.stepsSettingClientStatus.length ? (
                                                analysis.stepsSettingClientStatus.map((step, i) =>  <p key={i} className="text-sm mb-0">{i + 1}) {step}</p>)
                                            ) : (
                                                <p>There are no steps setting client status</p>
                                            )}
                                        </div>
                                    )}
                                />

                                <ExpandingCard
                                    title="Steps With Automations"
                                    body={(
                                        <div>
                                            {analysis.stepsWithAutomations.length ? (
                                                analysis.stepsWithAutomations.map((step, i) =>  <p key={i} className="text-sm mb-0">{i + 1}) {step}</p>)
                                            ) : (
                                                <p>There are no steps with automations</p>
                                            )}
                                        </div>
                                    )}
                                />

                            </Col>

                            <Col lg={6}>

                                <ExpandingCard
                                    title="Initial Errors"
                                    body={(
                                        <div>
                                            {analysis.stepsToStart.length > 2 ? (
                                                analysis.stepsToStart.map((step, i) =>  <p key={i} className="text-sm mb-0 text-warning">{i + 1}) {step}</p>)
                                            ) : (
                                                <p><i className="fas fa-check text-success mr-2" />There are no errors starting this workflow</p>
                                            )}
                                        </div>
                                    )}
                                />

                                <ExpandingCard
                                    title="Steps Creating Case Alerts"
                                    body={(
                                        <div>
                                            {analysis.stepsCreatingCaseAlerts.length ? (
                                                analysis.stepsCreatingCaseAlerts.map((step, i) =>  <p key={i} className="text-sm mb-0">{i + 1}) {step}</p>)
                                            ) : (
                                                <p>There are no steps creating case alerts</p>
                                            )}
                                        </div>
                                    )}
                                />

                                <ExpandingCard
                                    title="Steps With Custom Call Center Options"
                                    body={(
                                        <div>
                                            {analysis.stepsWithCustomCallCenterOptions.length ? (
                                                analysis.stepsWithCustomCallCenterOptions.map((step, i) =>  <p key={i} className="text-sm mb-0">{i + 1}) {step}</p>)
                                            ) : (
                                                <p>There are no steps with custom call center options</p>
                                            )}
                                        </div>
                                    )}
                                />

                                <ExpandingCard
                                    title="Steps With Tags"
                                    body={(
                                        <div>
                                            {analysis.stepsWithTags.length ? (
                                                analysis.stepsWithTags.map((step, i) =>  <p key={i} className="text-sm mb-0">{i + 1}) {step}</p>)
                                            ) : (
                                                <p>There are no steps with tags</p>
                                            )}
                                        </div>
                                    )}
                                />

                            </Col>
                      </Row>

                    </div>
                )}

            </Modal>

        )

    }

}

AnalysisModal.propTypes = {
    showAnalysis    : PropTypes.bool.isRequired,
    toggleAnalysis  : PropTypes.func.isRequired,
    workflow_id     : PropTypes.string.isRequired,
}

export default AnalysisModal;
