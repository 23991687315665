// api/v1/analytics/query/consultations

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Badge } from 'reactstrap';

import moment from 'moment';

import _analytics from '_functions/analytics'

import A from 'components/markup/links/A'
import QueryCreator from './QueryCreator';

import keys from 'keys';

import Cookies from 'universal-cookie';
import timeByValue from 'utils/timeByValue';

const QueryConsultations = () => {

    useEffect(() => {
        document.body.classList.add('analytics-sidebar-small')
        return () => {
            document.body.classList.remove('analytics-sidebar-small')
        }
    }, [])

    return (
        <>
        <QueryCreator 
            title="E-Sign Docs"
            hideSearch={true}
            runQuery={async (query, cb) => new Promise (async resolve => {
                // set payments to default to today only at first
                if(!query.hasRun) {

                    const cookies = new Cookies();
                    const val = cookies.get('analytics_query_date');

                    const date = moment().startOf('day');
                    query.filter.created_at = {
                        $gte: parseInt(date.format('X')),
                        $lt: parseInt(date.format('X')) + 86400,
                    }

                    if(val) {
                        const values = timeByValue(val);
                        console.log(values)
                        if(values.$gte) {
                            query.filter.created_at = values
                        }
                    }
                }
                const result = await _analytics.query.templateDocsSent(query)
                return resolve(result.data)
            })}
            inputs={[
                { type: 'ArchkDates', field: 'created_at', label: '', defaultValue: new Date(), isPrimary: true },
                { type: 'ArchkNull', field: 'finalized_on', label: 'Signed', defaultValue: '' },
                { type: 'ArchkNull', field: 'signature_confirmed', label: 'Signature Confirmed', defaultValue: '' },
                { type: 'ArchkNull', field: 'opened', label: 'Opened', defaultValue: '' },

            ]}
            columns={[
                {
                    dataField: "case",
                    text: "Case",
                    formatter: (cell, row) => (
                        row.case ? (
                            <div className="text-capitalize">
                                <A href={`${keys.APP_URL}/dashboard/cases/view/${row._id}?nav=3`}>{row.case.name}</A>
                            </div>
                        ) : (
                            <div className="text-capitalize">{'-'}</div>
                        )
                       
                    ),
                },
                // signature_confirmed: 1,
                // opened: 1,
                {
                    dataField: "_id",
                    text: 'Doc Name',
                    formatter: (cell, row) => (
                        <div>
                            {row.finalized_on ? (
                                <Badge color="success">Finalized</Badge>
                            ) : row.signature_confirmed ? (
                                <Badge color="info text-white">Signature Confirmed</Badge>
                            ) : row.opened ? (
                                <Badge color="warning">Opened</Badge>
                            ) : (
                                <Badge color="danger">Document Sent</Badge>
                            )}
                        </div>
                    ),
                },
                {
                    dataField: "name",
                    text: 'Doc Name',
                    formatter: (cell, row) => (
                        <div>{row.name}</div>
                    ),
                },
                {
                    dataField: "created_at",
                    text: "Sent",
                    formatter: (cell, row) => moment.unix(row.created_at).format("MM/DD/YYYY") + ' at ' + moment.unix(row.created_at).format("hh:mm A")
                },

            ]}

        />
        </>
    )

}



const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
        users: state.users.users,
        lead_sources: state.lead_sources.lead_sources,
    };
};

export default connect(mapStateToProps, '')(QueryConsultations);