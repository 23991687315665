import PropTypes from 'prop-types';
import React, { Component } from 'react';

class AutoNew extends Component {


    state = {

    };

    render() {

        const {  task, selected_task, toggleSidebar  } = this.props;

        return (

            <div className={selected_task === task._id ? "selected auto" : "auto"} >
                <div className="wrapper" onClick={() => toggleSidebar(true, task._id)}>
                    <div className="title">
                        <b><i className="fas fa-plus mr-3 text-warning" />New Automation Task </b>
                    </div>
                </div>
            </div>

        )

    }

}

AutoNew.propTypes = {
    task            : PropTypes.object.isRequired,
    toggleSidebar   : PropTypes.func.isRequired,
    selected_task   : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default AutoNew;
