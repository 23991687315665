/*
Documentation

this file holds the component that allows users to add and delete roles within a case
the options here come from the user roles found in the folder '_settings/role_types

*/

import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardFooter, CardTitle, Col, Row, FormGroup, Input } from "reactstrap";
import Dots from 'components/markup/loading/Dots'
import PropTypes from 'prop-types'
import _workflows from '_functions/workflows';
import { toggleAlertBS } from 'store/functions/system/system'
import role_types from '_settings/role_types';

class Roles extends Component {

    state = {
        roles: [],
        role_to_add: null,
        saving: false,
        noRoles: false,
    }

    onSave = async () => {

        this.setState({saving: true, noRoles: false})

        if(!this.state.roles.length) return this.setState({noRoles: true, saving: false})

        const updated = await _workflows.update(this.props.workflow._id, { roles: this.state.roles  })

        toggleAlertBS(false, updated.success ? 'Workflow Roles Updated Successfully.' : 'An error occurred updating this workflow\'s roles.')
        this.setState({saving: false,  was_updated: false})

    }

    onAddRole = () => {

        if(this.state.role_to_add) {

            let roles = [...this.state.roles];
            roles.push(this.state.role_to_add)

            this.setState({roles, role_to_add: null, roleState: null, was_updated: true})

        } else {

            //show red error around the input
            this.setState({roleState: 'invalid'})

        }

    }

    onRemoveRole = (role) => {

        this.setState({
            roles: [...this.state.roles].filter(r => r !== role),
            was_updated: true
        })

    }

    componentWillReceiveProps = (nextProps) => this.setState({roles: nextProps.workflow.roles || []})
    componentDidMount = () => this.setState({roles: this.props.workflow.roles || []})

    render() {

        const workflow = this.props.workflow
        const { roles } = this.state

        return (

        <>

            <Card className="card-color card-warning">

                <CardHeader>
                    <CardTitle className="mb-0 mt-2 float-left">Workflow: {workflow.type}</CardTitle>
                </CardHeader>

                <CardBody>
                    <p className="text-sm mb-0">This view controls the <b>user roles</b> that can be assigned to the workflow: "<b>{workflow.type}</b>"</p>
                </CardBody>

            </Card>

            <Card className="card-color card-warning">

                <CardHeader>
                    <CardTitle className="mb-0">Add A Role</CardTitle>
                </CardHeader>

                <CardBody>
                    <Row>

                        <Col md={10} className="align-self-center">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="role" >Role Name</label>
                                <Input
                                    id="role"
                                    value={this.state.role_to_add || ''}
                                    valid={ this.state.roleState === "valid" }
                                    invalid={ this.state.roleState === "invalid" }
                                    type="select"
                                    onChange={e => this.setState({role_to_add: e.target.value}) }
                                >
                                    <option value=""></option>
                                    {role_types.map((role, i) => (
                                        !roles.includes(role) ?
                                        <option key={i} value={role}>{role}</option>
                                        : null
                                    ))}
                                </Input>
                                <div className="valid-feedback">Looks good!</div>
                            </FormGroup>
                        </Col>

                        <Col md={2} className="align-self-center">
                            <button
                                style={{position: 'relative', top: 2}}
                                disabled={this.state.role_to_add ? false : true}
                                onClick={() => this.onAddRole()}
                                className="btn-block btn btn-success mt-3"
                            >
                                Add Role
                            </button>
                        </Col>

                    </Row>

                </CardBody>

                    <div className="table-responsive">
                        <table className="table">

                            <thead>
                                <tr>
                                    <th>Workflow Roles</th>
                                    <th className="text-right">Actions</th>
                                </tr>
                            </thead>

                            <tbody>
                                {roles && roles.length ? roles.map((r, i) => (
                                    <tr key={i}>
                                        <td>{r}</td>
                                        <td  className="text-right">
                                            <button
                                                style={{width: 190}}
                                                onClick={() => this.onRemoveRole(r)}
                                                className="btn btn-danger"
                                            >
                                                <i className="fas fa-trash mr-2" /> Remove Role
                                            </button>
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td>There are no user roles for this workflow yet.</td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>

                        </table>
                    </div>

                <CardFooter className="text-right">

                    {this.state.noRoles ? (
                        <div className="alert alert-danger">This workflow must have at least 1 assigned role</div>
                    ) : null}

                    <button
                        style={{width: 190}}
                        disabled={this.state.was_updated ? false : true}
                        onClick={this.onSave}
                        className="btn btn-success"
                    >

                        {!this.state.saving ? (
                            <>
                                <i className="fas fa-save mr-2" />
                                {this.state.was_updated ? 'Save Workflow Roles' : 'No Changes To Save'}
                            </>
                        ): (
                            <>Saving <Dots /></>
                        )}
                    </button>

                </CardFooter>

            </Card>

        </>

        )

    }

}

Roles.propTypes = {
    workflow: PropTypes.object.isRequired,
}

export default Roles
