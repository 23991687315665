/*
Documentation

This page shows a list of all cases
via api cases can be searched by case name or _id

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardTitle, Container, Row, Col } from "reactstrap";

import renderName from 'utils/renderName';

import Circle from 'components/markup/loading/Circle';

import { formatPhone } from 'utils/text';

import _affiliates from '_functions/affiliates'
import ConfirmationModal from 'components/functional/modals/Confirmation.js'

const Affiliates = ({lead_sources, users}) => {

    const [affiliates, setAffiliates] = useState(null)
    const [toDelete, setToDelete] = useState(null)

    const fetchData = useCallback(async () => {
        const data = await _affiliates.find();
        if(data) setAffiliates(data)
    }, [])

    const onDelete = useCallback(async () => {
        setToDelete(null)
        await _affiliates.delete(toDelete._id)
        fetchData()
    }, [toDelete, fetchData])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(!affiliates) return <div className='py-6'><Circle /></div>

    return (
        <Container fluid>

            <Helmet>
                <title>{`Affiliates`}</title>
                <meta name="description" content="Affiliates" />
            </Helmet>

            <DashHeaderOpen
                icon="fas fa-home"
                icon_link="/analytics"
                title={<span >Affiliates</span>} breadcrumb_1="All"
            />

            <Card className="card-color card-primary">

                <CardHeader>
                    <Row>
                        <Col md={6}>
                            <CardTitle className="mb-0">All Affiliates</CardTitle>
                        </Col>
                        <Col md={6} className="text-right">
                            <Link className='btn btn-success' to="/analytics/affiliates/create"><i className="fas fa-plus mr-2" /> Add New</Link>
                        </Col>
                    </Row>
                </CardHeader>

                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Affiliate</th>
                                <th className="text-right">Email</th>
                                <th className="text-right">Phone</th>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {affiliates.map(a => (
                                <tr key={a._id}>
                                    <td className="text-capitalize">{renderName(a)}</td>
                                    <td className="text-right">{a.email}</td>
                                    <td className="text-right">{formatPhone(a.phone)}</td>
                                    <td className="text-right">
                                        <Link to={`/analytics/affiliates/${a._id}`}>
                                            <i className="fas fa-edit text-success" />
                                        </Link>
                                        <i className="fas fa-trash ml-2 text-danger cursor-pointer" onClick={() => setToDelete(a)} />
                                        
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>    
            </Card>

            <ConfirmationModal
                showModal={toDelete ? true : false}
                toggleModal={() => setToDelete(null)}
                title="Delete Affiliate"
                body={<span>Are you sure you wish to delete the affiliate <span className='text-capitalize'>{renderName(toDelete ? toDelete : {})}</span>?</span>}
                onConfirmation={onDelete}
            />
           
       
        </Container>

    )
}

export default Affiliates