

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';
import { Container } from "reactstrap";
import validator from 'utils/validator';

import _forms from '_functions/forms';
import { toggleAlertBS } from 'store/functions/system/system'
import { toggleStandardLoader } from 'store/functions/system/system'

import Circle from 'components/markup/loading/Circle';

import Controller from './components/Controller'
import Map from './components/Map'
import SaveModal from './components/SaveModal'

import keys from 'keys';

class FormsCrud extends React.Component {

    state = {
        showController: false,
        question: {},
        depth: null,
        index: null,
        errors: false,
    }

    toggleController = () => {

        const { depth, index, showController} = this.state
        this.setState({ showController: !showController, depth, index })
    }

    setQuestionToEdit = (question, depth, index) => {

        this.setState({question, depth, index}, () => { this.toggleController() })
    }

    findBranch = (questions, depth) => {

        let foundBranch
        let x = 0

        // find the deepest branch based on the depth given
        while(x < depth) {
            foundBranch = foundBranch ? foundBranch.children.find(q => q.children) : questions.find(q => q.children);
            x++;
        }

        return {branch: foundBranch ? foundBranch : questions, depth: x}

    }

    onQuestionInputChange = (e, name) => {

        const val = e.target.value

        let question = Object.assign({}, this.state.question)
        question[name] = val

        this.setState({question})

    }

    onFormInputChange = (field, val) => {

        validator.onInputChange(val, 'form', field, this)
    }

    createQuestion = (depth, index) => {

        const questions = [...this.state.form.questions]
        const foundBranch = this.findBranch(questions, depth)

        // insert into the first line
        if(foundBranch.depth === 0) {

            questions.splice(index, 0, {type: 'text'});

        } else {

            foundBranch.branch.children.splice(index, 0, {type: 'text'});

        }

        this.setState({form: {...this.state.form, questions}}, () => {

            // scroll window right on adding a question
            const archkFormsMap = document.getElementById('archk-forms-map')
            const scrollLeft = archkFormsMap.scrollLeft;

            archkFormsMap.scrollTo({
                left: scrollLeft + 225,
                behavior: 'smooth'
            })

        })

    }

    updateQuestion = () => {

        const { depth, index, question } = this.state

        const questions = [...this.state.form.questions]
        const foundBranch = this.findBranch(questions, depth)

        let shouldScroll = false
         // remove all children if we should not create a branch
         if(question.create_branch === 'no') {
            question.children = undefined
        } else if(question.create_branch === 'yes' && !question.children) {
            question.children = [{type: 'text'}]
            shouldScroll = true
        }

        // insert into the first line
        if(foundBranch.depth === 0) {

            questions.splice(index, 1, question);

        } else {

            foundBranch.branch.children.splice(index, 1, question);

        }

        this.setState({form: {...this.state.form, questions}}, () => {

            // scroll window down if we are making a new branch
            if(shouldScroll) {
                const archkFormsMap = document.getElementById('archk-forms-map')
                const scrollTop = archkFormsMap.scrollTop;

                archkFormsMap.scrollTo({
                    top: scrollTop + 225,
                    behavior: 'smooth'
                })
            }

        })
        this.toggleController()

    }

    deleteQuestion = (depth, index) => {

        const questions = [...this.state.form.questions]
        const foundBranch = this.findBranch(questions, depth)

        // insert into the first line
        if(foundBranch.depth === 0) {

            questions.splice(index, 1);

        } else {

            foundBranch.branch.children.splice(index, 1);

        }

        this.setState({form: {...this.state.form, questions}}, () => {

            // scroll window left on adding a question
            const archkFormsMap = document.getElementById('archk-forms-map')
            const scrollLeft = archkFormsMap.scrollLeft;

            archkFormsMap.scrollTo({
                left: scrollLeft - 225,
                behavior: 'smooth'
            })

        })


    }

    toggleSaveModal = () => this.setState({showSaveModal : !this.state.showSaveModal})

    onSave = async () => {

        this.setState({errors: false})

        if(!this.state.form.name) return this.setState({errors: true})

        const form = this.state.form;
        // parse out the estimated time
        form.estimated_time = form.estimated_time ? parseInt(form.estimated_time) : 0

        toggleStandardLoader(true)
        let action;

        if(form._id) {

            action = await _forms.update(form._id, form)

        } else {

            action = await _forms.create(form)
            this.setState({form: action.data})

        }

        if(action.success) {
            toggleAlertBS(false, 'Form Saved Successfully');
            this.toggleSaveModal();
        }


        toggleStandardLoader(false)



    }

    componentDidMount = async  () => {

        const form_id = this.props.match.params._id

        if(form_id === 'new') {

            // set an empty form
            this.setState({
                form: { name: null, estimated_time: 0, questions: [] }
            })

        } else {

            const foundForm = await _forms.findById(form_id)

            if(foundForm.success && foundForm.data) {

                this.setState({form: foundForm.data})

            } else {

                this.setState({shouldRedirect: '/core/forms/all'})

            }

        }


    }

    render() {

        const { form, showController, question, depth, index, showSaveModal, errors } = this.state

        // redirect if form not found
        if(this.state.shouldRedirect) return <Redirect to={this.state.shouldRedirect} />
        // show loading if we haven't fetch the form yet
        if(!form) return <div className="py-6"><Circle /></div>


        return (
            <>

            <Container fluid>

                <Helmet>
                    <title>Create A Form</title>
                    <meta name="description" content="Cases Create" />
                </Helmet>

                <DashHeaderOpen
                    title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
                    breadcrumb_1="Forms"
                    breadcrumb_2="Create"
                    actionComponent={(
                        <>
                        <Link  to="/core/forms/all" className="btn btn-outline-warning"><i className="fas fa-arrow-left" /> Back</Link>
                        <button onClick={this.toggleSaveModal} className="btn btn-success"><i className="fas fa-save mr-2" /> Save</button>
                        </>
                    )}

                />

                <div className="archk-forms">

                    <Map
                        form={form}

                        toggleController={this.toggleController}
                        findBranch={this.findBranch}
                        setQuestionToEdit={this.setQuestionToEdit}
                        createQuestion={this.createQuestion}
                        deleteQuestion={this.deleteQuestion}
                    />

                    <Controller
                        question={question}
                        questions={form.questions}

                        showController={showController}

                        depth={depth}
                        index={index}

                        findBranch={this.findBranch}
                        toggleController={this.toggleController}
                        onQuestionInputChange={this.onQuestionInputChange}
                        updateQuestion={this.updateQuestion}
                    />

                    <SaveModal
                        form={form}
                        showSaveModal={showSaveModal}
                        errors={errors}

                        toggleSaveModal={this.toggleSaveModal}
                        onFormInputChange={this.onFormInputChange}
                        onSave={this.onSave}
                    />

                </div>

            </Container>

            </>
        );
    }
}

export default FormsCrud
