import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';
import { Container, Card, CardHeader, CardTitle, Badge } from 'reactstrap';
import NotificationDelete from 'components/functional/notifications/Delete';

import paginationFactory from "react-bootstrap-table2-paginator";
import Circle from 'components/markup/loading/Circle'

import _flows from '_functions/flows'

import Create from './Create'

import { toggleAlertBS } from 'store/functions/system/system';

const { SearchBar } = Search;

class FlowsAll extends React.Component {

    state = {
        flows: null,
        flow_to_delete: null,
        showModal: false
    }

    columns = [
        {
            dataField: "name",
            text: "Name",
            headerStyle: {width: 150},
            formatter: (cell, row) => <Link to={`/automations/flows/${row._id}`} className="text-capitalize font-weight-bold">{row.name}</Link>
        },
        {
            dataField: "active",
            text: "Active",
            headerStyle: {width: 150},
            formatter: (cell, row) => (
                row.active ? (
                    <Badge color="success" className="text-sm"><i className="fas fa-check mr-2" /> Active</Badge>
                ) : (
                    <Badge color="danger" className="text-sm"><i className="fas fa-times mr-2" /> Stopped</Badge>
                )
            )
        },

        {
            dataField: "_id",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <Link to={`/automations/flows/${row._id}`}><i className="fas fa-edit text-success mr-2 cursor-pointer"></i></Link>
                        <i onClick={() => this.setState({flow_to_delete: row})} className="fas fa-trash text-danger mr-3 cursor-pointer" />
                    </div>
                )
            }
        },
    ]

    pagination = paginationFactory({
        page: 1,
        sizePerPage: 25,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: true,
        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        value={currSizePerPage}
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    toggleModal = () => this.setState({showModal: !this.state.showModal})

    fetchData = async () => {
        const data = await _flows.find();
        if(data.data) this.setState({flows: data.data})
    }

    onSuccess = () => {
        this.fetchData()
        toggleAlertBS(false, 'Flow Created Successfully')
    }

    componentDidMount = async () => {
        this.fetchData()
    }

    render() {

        const { flow_to_delete, flows, shouldRedirect, showModal } = this.state;
        if(shouldRedirect) return <Redirect to={shouldRedirect} />

        if(!flows) return <div className="py-6"><Circle /></div>

        return (
        <>

            <Helmet>
                <title>{`Flows`}</title>
                <meta name="description" content="Tags" />
            </Helmet>

            {flow_to_delete && (
                <NotificationDelete
                    deletionURL={`/v1/flows/${flow_to_delete._id}`}
                    confirmBtnText="Delete flow"
                    title={<span className="text-capitalize">{`Delete flow: ${flow_to_delete.name}`}</span>}
                    text="Deleting this flow will immediately stop all actions from happening. Contacts assigned to this flow will NOTE finish it's sequence."
                    onClose={() => this.setState({flow_to_delete: null})}
                    onSuccess={this.fetchData}
                />
            )}

            <Container fluid>

                <DashHeaderOpen
                    title={<span><i className="fas fa-magic mr-2 text-success " /> Flows</span>}
                    breadcrumb_1="Flows"
                    breadcrumb_2="All"
                    actionComponent={(
                        <button className="btn btn-success" onClick={this.toggleModal}>
                            <i className="fas fa-plus mr-2" /> Create
                        </button>                       
                    )}
                />

                <Card>

                    <CardHeader>
                        <CardTitle className="mb-0">All Flows</CardTitle>
                    </CardHeader>

                    <ToolkitProvider
                        data={flows}
                        keyField="_id"
                        columns={this.columns}
                        search
                    >
                        {props => (
                            <>
                            <div
                                id="datatable-basic_filter"
                                className="dataTables_filter px-4 pb pt-3 pb-2"
                                style={{width: '100%'}}
                            >
                                <label>
                                    Search:
                                    <SearchBar
                                        className="form-control-sm"
                                        placeholder="Flow Name"
                                        {...props.searchProps}
                                    />
                                </label>
                            </div>
                            <div className=" table-responsive table-vertical-align table-not-fixed">
                                <BootstrapTable
                                    pagination={this.pagination}
                                    {...props.baseProps}
                                    bootstrap4={true}
                                    bordered={false}
                                />
                            </div>
                            </>
                        )}
                    </ToolkitProvider>

                </Card>

            </Container>

            <Create 
                showModal={showModal}
                toggleModal={this.toggleModal}
                onSuccess={this.onSuccess}
            />

            </>
        );
    }
}

export default FlowsAll;