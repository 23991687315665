/*
Documentation


contact fields
first_name
middle_name
last_name
email
phone
_id

//static fields
date Jan 1st, 2020

case fields
case._id
case.name
case.case_number
case.chapter

custom data
cd.[custom field name]

custom data pulls first from the case, then from the contact
duplicate fields will be pulled from the contact

*/

import React, { Component } from 'react';
import { Modal, UncontrolledTooltip } from 'reactstrap';
import { CopyToClipboard } from "react-copy-to-clipboard";



class Copy extends Component {

    state = {
        copiedText: false
    };

    render() {

        const { field } = this.props

        return (
            <>
                <CopyToClipboard
                    text={`{${field}}`}
                    onCopy={() => {
                        this.setState({ copiedText: true }, () => {
                            setTimeout(() => { this.setState({ copiedText: false }) }, 1800)
                        })
                    }}
                >
                    <i className="far fa-clipboard text-success mr-2" id={field.replace(/\./g, '')} />
                </CopyToClipboard>
                <UncontrolledTooltip
                    delay={0}
                    trigger="hover focus"
                    target={field.replace(/\./g, '')}
                >
                    {this.state.copiedText ? "Copied": "Copy To Clipboard"}
                </UncontrolledTooltip>
            </>
        )

    }

}


class Info extends Component {


    state = {

    };

    render() {

        const { showModal, toggleModal } = this.props

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={toggleModal}
                size="lg"
            >
                <div className="modal-header">

                    <h5 className="modal-title">Template Emails</h5>

                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>

                </div>

                <div className="modal-body bg-secondary">

                    <p className="text-sm mb-1">Template emails can be sent in two ways: 1) Programmatically via automations and 2) manually by staff members</p>
                    <p className="text-sm mb-1">When sending Programmatically all merge fields will be pulled in and if a field is not found inside the email it is left blank</p>
                    <p className="text-sm mb-1">All merge fields are a keyword wrapped inside of a set of braces: {'{}'}, below you will find a full list of all fields that can be pulled into template emails.</p>

                    <div className="table-responsive bg-white mb-4">
                        <table className="table mb-0">

                            <thead>
                                <tr>
                                    <td style={{width: 150}} className="text-dark font-weight-bold">Contact Fields</td>
                                    <td className="text-dark font-weight-bold">Description</td>
                                    <td style={{width: 180}} className="text-dark font-weight-bold">Usage</td>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>first_name</td>
                                    <td>Contact's first name.</td>
                                    <td><Copy field="first_name" /> {"{first_name}"}</td>
                                </tr>
                                <tr>
                                    <td>middle_name</td>
                                    <td>Contact's middle name.</td>
                                    <td><Copy field="middle_name" />{"{middle_name}"}</td>
                                </tr>
                                <tr>
                                    <td>last_name</td>
                                    <td>Contact's last name.</td>
                                    <td><Copy field="last_name" />{"{last_name}"}</td>
                                </tr>
                                <tr>
                                    <td>email</td>
                                    <td>The primary email of the contact.</td>
                                    <td><Copy field="email" />{"{email}"}</td>
                                </tr>
                                <tr>
                                    <td>phone</td>
                                    <td>The primary phone of the contact.</td>
                                    <td><Copy field="phone" />{"{phone}"}</td>
                                </tr>
                                <tr>
                                    <td>_id</td>
                                    <td>The system _id of the contact.</td>
                                    <td><Copy field="_id" />{"{_id}"}</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>

                    <div className="table-responsive bg-white mb-4">
                        <table className="table mb-0" style={{border: 'solid 1px #ddd'}}>

                            <thead>
                                <tr>
                                    <td style={{width: 150}} className="text-dark font-weight-bold">Case Fields</td>
                                    <td className="text-dark font-weight-bold">Description</td>
                                    <td style={{width: 180}} className="text-dark font-weight-bold">Usage</td>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>case.name</td>
                                    <td>The name of the contacts most recently created case.</td>
                                    <td><Copy field="case.name" />{"{case.name}"}</td>
                                </tr>
                                <tr>
                                    <td>case.case_number</td>
                                    <td>The case_number of the contacts most recently created case.</td>
                                    <td><Copy field="case.case_number" />{"{case.case_number}"}</td>
                                </tr>
                                <tr>
                                    <td>case.chapter</td>
                                    <td>The chapter of the contacts most recently created case.</td>
                                    <td><Copy field="case.chapter" />{"{case.chapter}"}</td>
                                </tr>
                                <tr>
                                    <td>case._id</td>
                                    <td>The system _id of the contacts most recently created case.</td>
                                    <td><Copy field="case._id" />{"{case._id}"}</td>
                                </tr>
                                <tr>
                                    <td>case.missing_docs</td>
                                    <td>The list of outstanding documents for the clients most recent case.</td>
                                    <td><Copy field="case._id" />{"{case._id}"}</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>

                    <div className="table-responsive bg-white mb-4">
                        <table className="table mb-0" style={{border: 'solid 1px #ddd'}}>

                            <thead>
                                <tr>
                                    <td style={{width: 150}} className="text-dark font-weight-bold">Static Fields</td>
                                    <td className="text-dark font-weight-bold">Description</td>
                                    <td style={{width: 180}} className="text-dark font-weight-bold">Usage</td>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>date</td>
                                    <td>The pre-field date when the email is sent. Formatted as MM/DD/YYYY</td>
                                    <td><Copy field="date" />{"{date}"}</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>

                    <div className="table-responsive bg-white mb-4">
                        <table className="table mb-0" style={{border: 'solid 1px #ddd'}}>

                            <thead>
                                <tr>
                                    <td style={{width: 150}} className="text-dark font-weight-bold">Custom Data</td>
                                    <td className="text-dark font-weight-bold">Description</td>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>cd.[field]</td>
                                    <td style={{whiteSpace: 'pre-line'}}>Custom data fields can be pulled in by using the keyword cd.[the custom data field]. Fields are pulled from from custom data on the contact and then from custom data on their most recent case. For example if the contact has assigned a custom data field called Plan Amount you can pull this field by adding {"{cd.Plan Amount}"} into the body of the email. </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>

                    <div className="p-2 px-3 bg-white" style={{border: 'solid 1px #ddd'}}>
                        <h4>Example email with custom fields:</h4>
                        <p className="text-sm mb-0">Hi {"{first_name}"} your case has been filed on {"{date}"}. You may reference this by the case number: {"{case.case_number}"}. Your payment plan is about to begin processing for the amount of {"{cd.Payment Plan Amount}"}</p>
                    </div>

                </div>

            </Modal>

        )

    }

}

export default Info;
